import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

class SelectSection extends Component {
  handleChange = e => {
    const next = e.target.value;
    if (next === 'cities') {
      this.props.history.push('/reference');
    } else {
      this.props.history.push(`/reference/${e.target.value}`);
    }
  };

  render() {
    const {classes, section} = this.props;

    return (
      <Select
        value={section || ''}
        input={<Input/>}
        onChange={this.handleChange}
        className={classes.selectSection}
      >
        <MenuItem value="cities">Города</MenuItem>
        <MenuItem value="activities">Сферы деятельности</MenuItem>
        <MenuItem value="activitiesDetails">Детали деятельности</MenuItem>
        <MenuItem value="semantics">Семантика для листингов</MenuItem>
      </Select>
    );
  }
}

const styles = () => ({
  selectSection: {
    marginRight: 20,
  },
});

export default connect(
  null,
  {}
)(withStyles(styles)(SelectSection));
