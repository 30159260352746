import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import SnackbarContent from '@material-ui/core/SnackbarContent';

function Warning(props) {
  const {classes, message, color} = props;

  return (
    <SnackbarContent
      className={classes.warning} style={{backgroundColor: color}}
      elevation={0}
      message={
        <span className={classes.message}>
          <icons.Warning className={classes.icon}/>
          {message}
        </span>
      }
    />
  );
}

const styles = theme => ({
  warning: {
    backgroundColor: colors.amber[600],
    marginBottom: theme.spacing.unit,
    padding: '4px 16px',
    maxWidth: 'unset',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
  },
  icon: {
    opacity: 0.9,
    marginRight: theme.spacing.unit * 2,
    width: 16,
    height: 16,
  },
});

export default withStyles(styles)(Warning);
