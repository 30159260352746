import React, {Component} from 'react';
import {connect} from 'react-redux';
import {queryNetRenterList} from '../../queries';
import base64url from '../../utils/base64url';
import NetRenterList from './NetRenterList';
import NetRenterListItems from './NetRenterListItems';
import {setTitle} from '../../actions';

class NetRenterListWrapper extends Component {
  state = {
    netRenters: [],
    loading: false,
    error: false,
    totalCount: 0,
    query: {},
  };

  componentDidMount() {
    this.props.setTitle('Сетевые арендаторы');
    this.search(this.props.location);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.search(nextProps.location);
    }
  }

  search(location) {
    let query = {
      offset: 0,
      limit: 30,
    };
    const qs = location.search.substr(1);
    if (qs.length > 0) {
      try {
        query = JSON.parse(base64url.decode(qs));
      } catch (_) {
      }
    }
    this.setState({loading: true, query});
    queryNetRenterList(query)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            loading: false,
            error: false,
            netRenters: resp.data.data.netRenters.items,
            totalCount: resp.data.data.netRenters.totalCount,
          });
        } else {
          this.setState({loading: false, error: true});
        }
      })
      .catch(() => {
        this.setState({loading: false, error: true});
      });
  }

  render() {
    const {netRenters, loading, error, totalCount, query} = this.state;

    return (
      <NetRenterList
        query={query}
        deals={netRenters}
        loading={loading}
        error={error}
        totalCount={totalCount}
        location={this.props.location}
        match={this.props.match}
        history={this.props.history}
        perPage={30}
      >
        {netRenters ? (
          <NetRenterListItems/>
        ) : null}
      </NetRenterList>
    );
  }
}

export default connect(null, {setTitle})(NetRenterListWrapper);
