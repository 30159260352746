import React, {Component} from 'react';
import {connect} from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import * as icons from '@material-ui/icons';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors/index';
import MobilePaginationToolbar from '../../components/MobilePaginationToolbar';
import {
  mapLabels,
  formatDateWithoutTime,
  createAreaRangeFormatter,
} from '../../utils';
import AccessComponent from '../../components/AccessComponent';
import TablePaginationActions from '../../components/TablePaginationActions';
import {downloadCsvContracts} from '../../utils/csvFunc';
import TableLink from '../../components/TableLink';

class ContractListItems extends Component {
  state = {
    anchorEl: null,
    popoverContent: null,
    openExportMenu: null,
    loading: false,
  };

  render() {
    const {
      classes,
      query,
      contracts,
      selectedItems,
      action,
      history,
      totalCount,
      handleChangePage,
      favList,
      contractTypesLabels,
    } = this.props;

    const {anchorEl, popoverContent, openExportMenu, loading} = this.state;

    return (
      <React.Fragment>
        <Hidden xsDown>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Тип договора</TableCell>
                <TableCell>Номер договора</TableCell>
                <TableCell>Дата заключения</TableCell>
                <TableCell>Дата окончания</TableCell>
                <TableCell>Комиссия</TableCell>
                <TableCell>Ответственные</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contracts.map(contract => {
                const selected =
                  selectedItems && selectedItems.indexOf(contract.id) >= 0;
                return (
                  <TableLink
                    className={classes.tableRow}
                    key={contract.id}
                    href={`/contracts/${contract.id}`}
                    hover
                    selected={selected}
                    onClick={e => {
                      e.preventDefault();
                      history.push(`/contracts/${contract.id}`);
                    }}
                  >
                    <TableCell>{contractTypesLabels[contract.type]}</TableCell>
                    <TableCell>{contract.no}</TableCell>
                    <TableCell>
                      {formatDateWithoutTime(contract.startAt)}
                    </TableCell>
                    <TableCell>
                      {formatDateWithoutTime(contract.endAt)}
                    </TableCell>
                    <TableCell>{contract.commission}</TableCell>
                    <TableCell
                      title={contract.assignees
                        .map(assignee => assignee.name)
                        .join('\n')}
                    >
                      <div className={classes.flexWrapper}>
                        <div>
                          {contract.assignees && contract.assignees.length > 0
                            ? contract.assignees[0].name
                            : null}
                        </div>
                        {contract.assignees && contract.assignees.length > 1 ? (
                          <icons.MoreHoriz
                            onClick={event =>
                              this.handlePopoverOpen(
                                event,
                                contract.assignees
                                  .map(assignee => assignee.name)
                                  .join('\n')
                              )
                            }
                            className={classes.popoverIcon}
                          />
                        ) : null}
                        <div className={classes.flex} />
                        {action ? (
                          <div className={classes.action}>
                            {action(contract, selected, 'contractId', favList)}
                          </div>
                        ) : null}
                      </div>
                    </TableCell>
                  </TableLink>
                );
              })}
            </TableBody>
          </Table>
          <div className={classes.pagination}>
            <AccessComponent manager>
              <Button
                size="small"
                className={classes.button}
                onClick={e =>
                  this.setState({openExportMenu: e.currentTarget})
                }
                disabled={loading}
              >
                <icons.ArrowDownward className={classes.leftIcon} />
                      Выгрузить
              </Button>
            </AccessComponent>
            <TablePagination
              rowsPerPage={30}
              component={'div'}
              rowsPerPageOptions={[30]}
              count={totalCount}
              page={query.offset ? Math.floor(query.offset / 30) : 0}
              onChangePage={handleChangePage}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} из ${count}`
              }
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </Hidden>
        <Hidden smUp>
          {contracts.map((contract, i) => {
            const selected = selectedItems && selectedItems.indexOf(contract.id) >= 0;
            return (<Paper
              key={i}
              elevation={0}
              onClick={() => history.push(`/contracts/${contract.id}`)}
              square
            >
              <CardContent style={{position: 'relative'}}>
                <Typography>
                  {contract.no}
                </Typography>
                <Typography variant="subtitle1">
                  {contractTypesLabels[contract.type]}
                </Typography>
                {action ? (
                  <div className={classes.action}>
                    {action(contract, selected, 'contractId', favList)}
                  </div>
                ) : null}
              </CardContent>
              <Divider/>
            </Paper>);
          })}
          <MobilePaginationToolbar
            totalCount={totalCount}
            page={query.offset ? Math.ceil(query.offset / 30) : 0}
            handleOnChangePage={handleChangePage}
          />
        </Hidden>
        <Popover
          classes={{paper: classes.requirementsPopover}}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
        >
          <Typography>{popoverContent}</Typography>
        </Popover>
        {openExportMenu ? (
          <Menu
            anchorEl={openExportMenu}
            open={true}
            onClose={() => this.setState({openExportMenu: null})}
          >
            <MenuItem value="xlsx" onClick={() => this.onDownloadCsv('xlsx')}>
              Выгрузить в XLSX
            </MenuItem>
            <MenuItem value="csv" onClick={() => this.onDownloadCsv('csv')}>
              Выгрузить в CSV
            </MenuItem>
          </Menu>
        ) : null}
      </React.Fragment>
    );
  }

  handlePopoverOpen = (e, text) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({anchorEl: e.currentTarget.parentNode, popoverContent: text});
  };

  handlePopoverClose = () => {
    this.setState({anchorEl: null, popoverContent: null});
  };

  onDownloadCsv = async exportFormat => {
    const {query} = this.props;
    try {
      this.setState({openExportMenu: null, loading: true});
      await downloadCsvContracts(query, exportFormat);
      this.setState({loading: false});
    } catch (err) {
      this.setState({openExportMenu: null, loading: false});
      alert('Произошла ошибка');
    }
  };
}

const styles = theme => ({
  table: {
    background: 'white',
  },
  tableRow: {
    display:'table-row',
    color: 'inherit',
    height: 48,
    outline: 'none',
    verticalAlign: 'middle',
    textDecoration: 'none',
    alignItems: 'center',
    cursor: 'default',
    '&:hover': {
      backgroundColor:'rgba(0,0,0,0.07)',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 56,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    backgroundColor: 'white',
    marginBottom: 56 + 48,
  },
  action: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 8,
    },
    top: 0,
  },
  sortableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  flex: {
    flex: 1,
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
  requirementsCell: {
    whiteSpace: 'normal',
    minWidth: 200,
  },
  requirementsWrapper: {
    overflow: 'hidden',
    maxHeight: 48,
    lineClamp: 3,
    fontSize: '0.75rem',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
  status: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 6,
    height: '100%',
  },
  malformedIcon: {
    color: colors.orange[400],
    width: 16,
    height: 16,
    marginRight: 8,
    float: 'left',
    verticalAlign: 'middle',
  },
  mobilePagination: {
    marginRight: 16,
  },
  rightIcon: {
    marginRight: -12,
  },
});

export default connect(({root}) => ({
  formatAreaRange: createAreaRangeFormatter(root.classification.areaUnits),
  contractTypesLabels: mapLabels(root.classification.contractTypes, 'value', 'label'),
  selfUser: root.selfUser,
}))(withStyles(styles)(ContractListItems));
