import axios from 'axios';
import Downshift from 'downshift';
import debounce from 'lodash/debounce';
import Typography from '@material-ui/core/Typography/Typography';
import React, {Component} from 'react';
import InputMask from 'react-input-mask';
import {connect} from 'react-redux';
import ContactChip from '../../../components/ContactChip';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import MuiTextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import qs from 'qs';
import {mapLabels} from '../../../utils';
import {hasRole} from '../../../utils/roleFunc';
import * as userRoles from '../../../constants/userRoles';
import classNames from 'classnames';

const renderInput = ({InputProps, ref, ...other}) => {
  return (
    <MuiTextField
      {...other}
      label="Номер телефона"
      required
      InputProps={{
        inputRef: ref,
        ...InputProps,
      }}
      fullWidth
      margin="normal"
    />
  );
};

class ContactField extends Component {
  state = {
    contact: null,
    contacts: null,
    canPost: false,
    loading: 0,
    selectContactEl: null,
  };

  handleSelect = contact => {
    this.props.onContactSelect(contact);

    if (!contact.id) {
      this.props.onContactCreate(contact);
    }
  };

  handleInputValueChange = debounce(value => {
    if (!/^\+7 \d\d\d \d\d\d-\d\d-\d\d$/.test(value)) {
      return;
    }

    this.setState({loading: this.state.loading + 1});
    axios
      .get(
        '/api/contacts?' +
        qs.stringify({
          _source: 'team',
          statuses: ['opened', 'closed', 'archived'],
          phone: value,
        })
      )
      .then(resp => {
        this.setState({
          contacts: resp.data.items,
          canPost: resp.data.canPost,
        });
      })
      .catch(() => {})
      .then(() => {
        this.setState({loading: this.state.loading - 1});
      });
  }, 500);

  render() {
    const {classes, contact, contactTypeLabels, onContactUnassign} = this.props;
    const {canPost, contacts, loading, selectContactEl} = this.state;

    if (contact) {
      return (
        <Grid item xs={12}>
          <ContactChip contact={contact} onDelete={onContactUnassign} />
        </Grid>
      );
    }

    return (
      <div className={classNames(classes.contactFieldWrapper)}>
        <Grid item xs={12}>
          <Downshift
            onSelect={this.handleSelect}
            onInputValueChange={this.handleInputValueChange}
            onOuterClick={() => this.setState({contacts: null})}
            itemToString={contact => (contact ? contact.phone : '')}
          >
            {({getInputProps, getItemProps, inputValue, isOpen}) => (
              <div className={classes.container}>
                {renderInput({
                  InputProps: getInputProps({
                    inputComponent: InputMask,
                    endAdornment:
                      loading > 0 ? (
                        <InputAdornment position="end">
                          <CircularProgress size={20} thickness={5} />
                        </InputAdornment>
                      ) : null,
                  }),
                  inputProps: {
                    mask: '+7 999 999-99-99',
                    maskChar: '_',
                  },
                })}
                {isOpen ? (
                  <Paper
                    square
                    style={{
                      position: 'absolute',
                      zIndex: 1,
                      background: 'white',
                    }}
                  >
                    {contacts ? (
                      <List>
                        {contacts.map((contact, index) => (
                          <MenuItem
                            key={index}
                            {...getItemProps({item: contact, key: index})}
                          >
                            <ListItemText
                              primary={contact.name}
                              secondary={
                                contactTypeLabels[contact.type] || contact.type
                              }
                            />
                          </MenuItem>
                        ))}
                        {canPost ? (
                          <MenuItem
                            {...getItemProps({item: {phone: inputValue}})}
                          >
                            <ListItemText
                              primary="Новый контакт"
                              secondary={
                                !contacts.length
                                  ? 'Создать контакт с указанным номером'
                                  : 'Создать контакт с таким же номером'
                              }
                            />
                          </MenuItem>
                        ) : null}
                      </List>
                    ) : null}
                  </Paper>
                ) : null}
              </div>
            )}
          </Downshift>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{marginRight: 12}}
            onClick={this.handleSelectContact}
          >
            Выбрать контакт
          </Button>
        </Grid>
        <SearchPopover
          classes={classes}
          anchorEl={selectContactEl}
          onClose={() => this.setState({selectContactEl: null})}
          onSelect={contact => {
            this.setState({selectContactEl: null});
            this.handleSelect(contact);
          }}
        />
      </div>
    );
  }

  handleSelectContact = e => {
    this.setState({selectContactEl: e.currentTarget});
  };
}

class SearchPopover extends Component {
  PER_PAGE = 5;

  state = {
    query: '',
    loading: 0,
    contacts: null,
    page: 0,
    source: 'assigned',
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleOnChangeSource = event => {
    const {query} = this.state;
    this.setState({page: 0, source: event.target.value});
    this.search(query, 0, event.target.value);
  };

  handleChange = event => {
    const {source} = this.state;
    this.setState({query: event.target.value, page: 0});
    this.handleSearch(event.target.value, 0, source);
  };

  handleSearch = debounce((query, page, source) => {
    this.search(query, page, source);
  }, 500);

  search = (query, page, source) => {
    let url = `/api/contacts?_source=${source}&_perPage=${
      this.PER_PAGE
    }&_page=${page}`;
    if (query.length >= 3) {
      url += `&query=${encodeURIComponent(query)}`;
    }

    this.setState({loading: this.state.loading + 1});
    axios
      .get(url)
      .then(resp => {
        this.setState({contacts: resp.data});
      })
      .catch(() => {})
      .then(() => {
        this.setState({loading: this.state.loading - 1});
      });
  };

  handlePrevPage = () => {
    const {query, page, source} = this.state;
    this.setState({page: page - 1});
    this.search(query, page - 1, source);
  };

  handleNextPage = () => {
    const {query, page, source} = this.state;
    this.setState({page: page + 1});
    this.search(query, page + 1, source);
  };

  handleSelect(contact) {
    this.props.onSelect(contact);
  }

  componentDidMount() {
    const {query, page, source} = this.state;
    this.search(query, page, source);
  }

  render() {
    const {anchorEl, classes, contactTypeLabels, selfUser} = this.props;
    const {contacts, loading, page, query, source} = this.state;

    return (
      <Popover
        PaperProps={{square: true}}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        classes={{paper: classes.contactListPaper}}
      >
        <Toolbar>
          <Select
            input={<Input />}
            value={source}
            disabled={
              selfUser &&
              !hasRole(selfUser.role, userRoles.MANAGER) &&
              !hasRole(selfUser.role, userRoles.ADMIN)
            }
            onChange={this.handleOnChangeSource}
          >
            <MenuItem value="assigned">Мои контакты</MenuItem>
            <MenuItem value="team">Контакты команды</MenuItem>
          </Select>
          <div style={{flex: 1}} />
          {loading > 0 ? (
            <CircularProgress size={20} thickness={5} />
          ) : (
            <IconButton style={{marginRight: -12}} onClick={this.handleClose}>
              <icons.Close />
            </IconButton>
          )}
        </Toolbar>
        <Toolbar>
          <MuiTextField
            placeholder="Поиск контакта"
            fullWidth
            margin="none"
            value={query}
            onChange={this.handleChange}
          />
        </Toolbar>
        <Divider />
        <List style={{flex: 1, overflow: 'auto'}}>
          {contacts
            ? contacts.items.map((contact, index) => (
              <MenuItem
                key={contact.id}
                classes={{container: classes.listItem}}
                onClick={() => this.handleSelect(contact)}
              >
                <ListItemText
                  key={index}
                  primary={contact.name}
                  secondary={contactTypeLabels[contact.type] || contact.type}
                  classes={{secondary: classes.listItemSecondary}}
                />
                <ListItemSecondaryAction
                  className={classes.listItemSecondaryAction}
                >
                  <icons.Done />
                </ListItemSecondaryAction>
              </MenuItem>
            ))
            : null}
        </List>
        <Divider />
        {contacts && contacts.totalCount > 0 ? (
          <Toolbar>
            <IconButton
              disabled={page < 1}
              onClick={this.handlePrevPage}
              style={{marginLeft: -12}}
            >
              <icons.ChevronLeft />
            </IconButton>
            <IconButton
              disabled={page >= Math.floor(contacts.totalCount / this.PER_PAGE)}
              onClick={this.handleNextPage}
            >
              <icons.ChevronRight />
            </IconButton>
            <Typography variant="caption">
              {`${page + 1} / ${Math.ceil(
                contacts.totalCount / this.PER_PAGE
              )}`}
            </Typography>
          </Toolbar>
        ) : null}
      </Popover>
    );
  }
}

SearchPopover = connect(state => ({
  contactTypeLabels: mapLabels(
    state.root.classification.contactTypes,
    'value',
    'label'
  ),
  selfUser: state.root.selfUser,
}))(SearchPopover);

const styles = {
  contactFieldWrapper: {
    width: '100%',
    position: 'relative',
    display: 'block',
    padding: '12px',
  },
  contactListPaper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 272,
    maxWidth: 400,
    width: 'calc(100% - 48px)',
  },
  listItemSecondaryAction: {
    visibility: 'hidden',
  },
  listItem: {
    '&:hover $listItemSecondaryAction': {
      visibility: 'inherit',
    },
  },
  listItemSecondary: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default connect(state => ({
  contactTypeLabels: mapLabels(
    state.root.classification.contactTypes,
    'value',
    'label'
  ),
}))(withStyles(styles)(ContactField));
