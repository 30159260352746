import {executeQuery} from '../utils';

export const queryDealOfferList = (query, conf = {}) => {
  return executeQuery(
    `query dealOfferList($filter: DealOfferFilter, $source: Source, $offset: Int, $limit: Int, $order: OrderBy, $assignees: [ID], $team: ID) {
      dealOfferList(filter: $filter, source: $source, offset: $offset, limit: $limit, order: $order, assignees: $assignees, team: $team){
        items {
          id
          deal {
            id
            status
            source
            contact {
              id
              name
            }
            offerType
            propertyPurpose
            propertyType
          }
          offer {
            id
            property {
              address {
                value
                street
                attributes {
                  city_with_type
                  region_with_type
                }
                building {
                  number
                  block
                  letter
                }
              }
            }
          }
          status
          dealStartAt
          dealEndAt
          comment
          assignees {
            id
            name
          }
        }
        totalCount
      }
    }`,
    {...query},
    conf,
  );
};

export const queryDealOffer = query => {
  return executeQuery(
    `query dealOffer($id: ID!) {
      dealOffer(id: $id){
        id
        assignees {
          id
        }
        deal {
          id
          source
          status
          propertyPurpose
          propertyType
          offerType
          contact {
            id
            name
          }
        }
        offer {
          id
          name
          revenue
          price {
            value {
              amount
              currency
            }
            type
          }
          property {
            normalizedArea
            type {
              value
            }
            area {
              amount
              unit
            }
            photos
            address {
              value
              street
              attributes {
                city_with_type
                region_with_type
              }
              building {
                number
                block
                letter
              }
            }
          }
        }
        comment
        status
        additional
        dealStartAt
        updatedAt
        createdAt
        incoming
        potentialEndAt
        dealEndAt
        commission
        area
        declineReason
        declineComment
      }
    }`,
    {...query}
  );
};

export const queryDealOfferOffer = query => {
  return executeQuery(
    `query dealOffer($id: ID!) {
      dealOffer(id: $id){
        id
        offer {
          id
          name
          property {
            type {
              value
            }
            area {
              amount
              unit
            }

          }
          status
          type
          price {
            value {
              amount
              currency
            }
            type
          }
        }
      }
    }`,
    {...query}
  );
};

export const queryDealOfferContact = query => {
  return executeQuery(
    `query dealOffer($id: ID!) {
      dealOffer(id: $id){
        id
        deal {
          id
          contact {
            id
            name
            phone
          }
        }
      }
    }`,
    {...query}
  );
};

export const queryDealOfferDeal = id => executeQuery(
  `query deal($id: ID!) {
    deal(id: $id) {
      id
      status
      offerType
      propertyType
      propertyPurpose
    }
  }`,
  {id}
);

export const queryDealOfferAssignees = id => {
  return executeQuery(
    `query dealOffer($id: ID!) {
      dealOffer(id: $id) {
        assignees {
          id
          name
          email
          phone
        }
      }
    }`,
    {id}
  );
};

export const queryDealOfferDocuments = id => {
  return executeQuery(
    `query dealOffer($id: ID!) {
      dealOffer(id: $id) {
        documents {
          name
          fileName
          fileSize
          mimeType
          name
          path
        }
      }
    }`,
    {id}
  );
};

export const queryDealOffersPreview = query => {
  return executeQuery(
    `query dealOfferList($filter: DealOfferFilter, $source: Source, $offset: Int, $limit: Int, $order: OrderBy, $assignees: [ID], $team: ID) {
      dealOfferList(filter: $filter, source: $source, offset: $offset, limit: $limit, order: $order, assignees: $assignees, team: $team) {
        items {
          id
          incoming
          dealEndAt
          createdBy {
            id
            name
          }
          assignees {
            id
          }
          offer {
            id
            property {
              normalizedArea
              type {
                value
              }
              area {
                amount
                unit
              }
              address {
                value
                street
                attributes {
                  city_with_type
                  region_with_type
                }
                building {
                  number
                  block
                  letter
                }
              }
            }
          }
          status
          dealStartAt

        }
        totalCount
      }
    }`,
    {...query}
  );
};

export const queryOfferDealOffersPreview = query => {
  return executeQuery(
    `query dealOfferList($filter: DealOfferFilter, $source: Source, $offset: Int, $limit: Int, $order: OrderBy, $assignees: [ID], $team: ID) {
      dealOfferList(filter: $filter, source: $source, offset: $offset, limit: $limit, order: $order, assignees: $assignees, team: $team) {
        items {
          id
          incoming
          status
          dealStartAt
          createdBy {
            id
            name
          }
          offer {
            property {
              normalizedArea
            }
          }
          deal {
            id
            propertyType
          }
          createdAt
          dealStartAt
        }
        totalCount
      }
    }`,
    {...query}
  );
};
