import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import OfferListItem from '../../../components/relations/OfferListItem';

class Siblings extends Component {
  render() {
    const {
      offers,
      offer,
      handleDialogOpen,
    } = this.props;

    if (!offer || !offer.siblings) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Альтернативные листинги
        </ListSubheader>
      }>
        {offer.siblings.map(id => {
          const offer = offers[id];
          return offer ? (
            <OfferListItem 
              key={id} 
              offer={offer}
              handleDialogOpen={e => handleDialogOpen(e)}
            />
          ) : null;
        })}
      </List>
    );
  }
}

export default withRouter(connect(
  state => ({
    offers: state.root.offer.offers,
  })
)(Siblings));
