import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import DatePicker from '../../../components/Input/DatePicker';

class Sidebar extends Component {
  handleReset = () => {
    this.props.reset();
    this.props.onSubmit({dateFrom: null, dateTo: null});
  };

  render() {
    const {
      classes,
      submitting,
      initialValues,
      pristine,
      handleSubmit,
      onSubmit,
      open,
      chipAppeals,
      entitySource,
    } = this.props;

    const {dateFrom, dateTo, createdAtFrom, createdAtTo, updatedAtFrom, updatedAtTo} = initialValues;

    return (
      <div className={classNames(classes.root, {[classes.opened]: open})}>
        <Divider/>
        <div className={classes.selectWrap}>
          <div className={classes.container}>
            <Typography variant="subtitle1">За период</Typography>
            <Field
              name="dateFrom"
              label="с"
              format={null}
              className={classes.input}
              component={props => (
                <DatePicker
                  className={classes.input}
                  format="yyyy-MM-dd"
                  {...props}
                />
              )}
              fullWidth
            />
            <Field
              name="dateTo"
              label="по"
              format={null}
              className={classes.input}
              component={props => (
                <DatePicker
                  className={classes.input}
                  format="yyyy-MM-dd"
                  {...props}
                />
              )}
              fullWidth
            />
          </div>
          {(entitySource === 'appeals' || entitySource === 'request_offers') && chipAppeals ?
            <div className={classes.container}>
              <Typography variant="subtitle1">Дата создания листинга</Typography>
              <Field
                name="createdAtFrom"
                label="от"
                format={null}
                className={classes.input}
                component={props => (
                  <DatePicker
                    className={classes.input}
                    format="yyyy-MM-dd"
                    {...props}
                  />
                )}
                fullWidth
              />
              <Field
                name="createdAtTo"
                label="до"
                format={null}
                className={classes.input}
                component={props => (
                  <DatePicker
                    className={classes.input}
                    format="yyyy-MM-dd"
                    {...props}
                  />
                )}
                fullWidth
              />
              <div className={classes.container}>
              </div>
              <Typography variant="subtitle1">Дата обновления листинга</Typography>
              <Field
                name="updatedAtFrom"
                label="от"
                format={null}
                className={classes.input}
                component={props => (
                  <DatePicker
                    className={classes.input}
                    format="yyyy-MM-dd"
                    {...props}
                  />
                )}
                fullWidth
              />
              <Field
                name="updatedAtTo"
                label="до"
                format={null}
                className={classes.input}
                component={props => (
                  <DatePicker
                    className={classes.input}
                    format="yyyy-MM-dd"
                    {...props}
                  />
                )}
                fullWidth
              />
            </div>
            : null}
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(onSubmit)}
          >
            Применить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || (!(dateFrom || dateTo || createdAtFrom || createdAtTo || updatedAtFrom || updatedAtTo) && pristine)}
            onClick={this.handleReset}
          >
            Сбросить
          </Button>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.divider}`,
    background: 'white',
    overflowY: 'auto',
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: 320,
    },
  },
  container: {
    marginBottom: 20,
  },
  input: {
    marginBottom: 4,
    marginTop: 4,
  },
  opened: {
    display: 'block',
  },
  selectWrap: {
    padding: theme.spacing.unit * 3,
  },
  itemLabel: {
    fontWeight: 'inherit',
  },
  selectedItem: {
    fontWeight: 500,
  },
});

export default reduxForm({
  form: 'filter_deals',
  enableReinitialize: true,
})(withRouter(withStyles(styles)(Sidebar)));
