import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, FormSection} from 'redux-form';
import {Checkbox, Select} from 'redux-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PhoneInput from '../../../components/Input/PhoneInput';
import TextField from '../../../components/Input/TextField';
import Variant from '../../../components/Input/Variant';
import {getLabelsForValue} from '../../../utils';
import {hasRole} from '../../../utils/roleFunc';
import * as userRoles from '../../../constants/userRoles';
import CityField from './City';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

class TeamForm extends Component {
  state = {
    timezones: [
      {value: 0, label: 'GMT+00 WET, Западноевропейское время'},
      {value: 1, label: 'GMT+01 CET, Центральноевропейское время'},
      {value: 2, label: 'GMT+02 EET, Восточноевропейское время'},
      {value: 3, label: 'GMT+03 MSK, Московское время'},
      {value: 4, label: 'GMT+04 Самарское время'},
      {value: 5, label: 'GMT+05 Екатеринбургское время'},
      {value: 6, label: 'GMT+06 Омское время'},
      {value: 7, label: 'GMT+07 Красноярское время'},
      {value: 8, label: 'GMT+08 Иркутское время'},
      {value: 9, label: 'GMT+09 Якутское время'},
      {value: 10, label: 'GMT+10 Владивостокское время'},
      {value: 11, label: 'GMT+11 Среднеколымское время'},
      {value: 12, label: 'GMT+12 Камчатское время'},
    ],
    workingHoursStart: [],
    workingHoursEnd: [],
  };

  componentDidMount() {
    let workingHoursStart = [], workingHoursEnd = [], h = null;
    for (h = 7; h <= 12; h++) {
      let value = h * 60,
        hour = ('0' + h.toString()).slice(-2),
        minute = ((value + 30) % 60);

      workingHoursStart.push({value: value, label: hour + ':00'});
      workingHoursStart.push({value: value + 30, label: hour + ':' + minute.toString()});
    }
    for (h = 15; h <= 21; h++) {
      let value = h * 60,
        hour = ('0' + h.toString()).slice(-2),
        minute = ((value + 30) % 60);

      workingHoursEnd.push({value: value, label: hour + ':00'});
      workingHoursEnd.push({value: value + 30, label: hour + ':' + minute.toString()});
    }

    this.setState({workingHoursStart, workingHoursEnd});
  }

  render() {
    const {
      teamSpecializations,
      selfUser,
      array: {push, remove},
      cities,
      teamId,
      classes,
      integrations,
      onOpenIntegrationUrl,
      onDisableIntegration,
    } = this.props;
    const {timezones, workingHoursStart, workingHoursEnd} = this.state;
    const editPermissions = hasRole(selfUser.role, userRoles.ADMIN) || hasRole(selfUser.role, userRoles.MANAGER);

    return (
      <FormSection name="team">
        <FormControl margin="none">
          <FormControlLabel
            control={<Field name="disabled" disabled={!hasRole(selfUser.role, userRoles.ADMIN)} component={Checkbox}/>}
            label="Неактивная"
          />
        </FormControl>

        <Field
          name="name"
          label="Название"
          required
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="cities"
          label="Название"
          component={CityField}
          cities={cities}
          removeMethod={remove}
          addMethod={push}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Тип партнера</InputLabel>
          <Field
            name="type"
            component={Select}
            disabled={!hasRole(selfUser.role, userRoles.ADMIN)}
            required
          >
            <MenuItem value="0">АРР</MenuItem>
            <MenuItem value="1">Партнер АРР</MenuItem>
          </Field>
        </FormControl>
        <Field
          name="specializations"
          label="Специализации"
          component={TextField}
          select
          SelectProps={{
            multiple: true,
            renderValue: value => getLabelsForValue(teamSpecializations, value).join(', '),
          }}
          format={value => value || []}
          fullWidth
          margin="normal"
        >
          {teamSpecializations.map(({label, value}, i) => <Variant value={value} label={label} key={i}/>)}
        </Field>
        <Field
          name="address"
          label="Адрес офиса"
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="principalName"
          label="Имя руководителя"
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="principalPhone"
          label="Телефон руководителя"
          component={TextField}
          InputProps={{inputComponent: PhoneInput}}
          fullWidth
          margin="normal"
        />
        <Field
          name="principalEmail"
          type="email"
          label="Адрес эл. почты руководителя"
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="contactName"
          label="Контактное лицо для объявлений"
          required
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="phone"
          label="Телефон для объявлений"
          required
          component={TextField}
          InputProps={{inputComponent: PhoneInput}}
          fullWidth
          margin="normal"
        />
        <Field
          name="email"
          type="email"
          label="Адрес эл. почты для объявлений"
          required
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="avgCommissionMember"
          label="Средняя комиссия консультанта, %"
          component={TextField}
          normalize={value => value ? value.replace(',', '.') : value}
          fullWidth
          required
          margin="normal"
        />
        <Field
          name="avgCommissionRent"
          label="Средняя комиссия по аренде, %"
          component={TextField}
          normalize={value => value ? value.replace(',', '.') : value}
          fullWidth
          required
          margin="normal"
        />
        <Field
          name="avgCommissionSell"
          label="Средняя комиссия по продаже, %"
          component={TextField}
          normalize={value => value ? value.replace(',', '.') : value}
          fullWidth
          required
          margin="normal"
        />
        <FormControl margin="none">
          <FormControlLabel
            control={<Field name="blockPaidActions" component={Checkbox} />}
            label="Запретить изменять ключевые параметры листинга после публикации на платные площадки (Авито и ЦИАН)"
            secondary={'123'}
          />
        </FormControl>
        {
          editPermissions ? (
            <>
              <Typography variant="h6" className={classes.integrationTitle}>Время работы</Typography>

              <Grid container spacing={8}>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <Field
                      name="timezone"
                      label="Часовой пояс"
                      required
                      component={TextField}
                      select
                      fullWidth
                      margin="normal"
                    >
                      {timezones.map(({value, label}, i) => (
                        <MenuItem value={value} key={i}>{label}</MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <Field
                      name="workingHoursStart"
                      label="Начало рабочего дня"
                      required
                      component={TextField}
                      select
                      fullWidth
                      margin="normal"
                    >
                      {workingHoursStart.map(({value, label}, i) => (
                        <MenuItem value={value} key={i}>{label}</MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <Field
                      name="workingHoursEnd"
                      label="Конец рабочего дня"
                      required
                      component={TextField}
                      select
                      fullWidth
                      margin="normal"
                    >
                      {workingHoursEnd.map(({value, label}, i) => (
                        <MenuItem value={value} key={i}>{label}</MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          ) : null
        }

        {
          teamId && editPermissions ? (
            <>
              <Typography variant="h6" className={classes.integrationTitle}>Интеграции</Typography>

              {integrations && Object.keys(integrations).map(integrationId => {
                const integration = integrations[integrationId];

                return (
                  <Typography key={integrationId} variant="subtitle2" className={classes.integrationItem}>
                    <span className={classes.integrationItemName}>{integration.name}</span>
                    <span>{integration.description || ''}</span>
                    {
                      integration.access ? (
                        integration.enabled ? (
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            className={classes.integrationBtn}
                            onClick={() => onDisableIntegration(teamId, integrationId)}
                          >
                            Отключить
                          </Button>
                        ) : (
                          integration.url ? (
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              className={classes.integrationBtn}
                              onClick={() => onOpenIntegrationUrl(integration.url)}
                            >
                              Подключить
                            </Button>
                          ) : null
                        )
                      ) : null
                    }
                  </Typography>
                );
              })}
            </>
          ) : null
        }
        {
          editPermissions ? (
            <>
              <Typography variant="h6" className={classes.integrationTitle}>Видимость разделов</Typography>
              <FormControl margin="none" className={classes.menuVisibility}>
                <FormControlLabel
                  control={<Field name="menu.deals" component={Checkbox}/>}
                  label="Заявки"
                />
                <FormControlLabel
                  control={<Field name="menu.requests" component={Checkbox}/>}
                  label="Заявки beta"
                />
              </FormControl>
            </>
          ) : null
        }
      </FormSection>
    );
  }
}

const styles = theme => ({
  integrationTitle: {
    margin: '40px 0 5px 0',
    textAlign: 'center',
  },
  integrationItemName: {
    fontSize: '1rem',
    marginRight: 20,
  },
  integrationItem: {
    padding: 16,
    position: 'relative',
    backgroundColor: 'rgb(187, 222, 251)',
    borderRadius: 10,
    marginBottom: 10,
  },
  integrationBtn: {
    margin: '0 10px',
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
      display: 'block',
      float: 'none',
    },
  },
  menuVisibility: {
    flexFlow: 'row',
  },
});


TeamForm = withStyles(styles)(TeamForm);

export default connect(
  state => ({
    teamSpecializations: state.root.classification.teamSpecializations,
    selfUser: state.root.selfUser,
  })
)(TeamForm);
