import {find} from 'lodash';
import React, {Component} from 'react';
import {Field} from 'redux-form';
import {Checkbox} from 'redux-form-material-ui';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import TextField from '../../../components/Input/TextField';
import {getLabelsForValue} from '../../../utils';

class Variant extends Component {
  render() {
    const {label, selected, ...props} = this.props;

    return (
      <MenuItem selected={selected} {...props}>
        <MuiCheckbox checked={selected} />
        <ListItemText primary={label} />
      </MenuItem>
    );
  }
}


class PropertyType extends Component {

  normalizeInt = value => {

    if(value === '' || value === '0')
      return null;
    if(value === '-')
      return value;
    let newValue = parseInt(value);
    if(!Number.isNaN(newValue))
      return newValue;
    return null;
  };

  render() {
    const {onTypeChange, value, variants, classes, buildingTypes} = this.props;
    const type = find(variants, type => type.value === value) || {};
    return (
      <div>
        <Field
          name="type.value"
          label="Основная категория"
          component={TextField}
          onChange={e => {
            if (onTypeChange) {
              onTypeChange(e);
            }
          }}
          select
          required
          fullWidth
          margin="normal"
        >
          {variants.map((type, i) => {
            return (
              <MenuItem value={type.value} key={i}>
                {type.label}
              </MenuItem>
            );
          })}
        </Field>
        <Field
          name="buildingType"
          label="Тип здания"
          component={TextField}
          select
          required
          fullWidth
          margin="normal"
        >
          {Object.keys(buildingTypes).map((value, i) =>
            <MenuItem value={value} key={i}>
              {buildingTypes[value]}
            </MenuItem>
          )}
        </Field>
        {type.attributes
          ? type.attributes.map(({name, label, type, required, ...props}, i) => {
            if (type === 'variant') {
              return (
                <Field
                  key={i}
                  name={`type.attributes.${value}.${name}`}
                  label={label}
                  component={TextField}
                  select
                  fullWidth
                  margin="normal"
                  required={required}
                >
                  {!required ? <MenuItem value=""/> : null }
                  {props.variants.map(({value, label}, i) => {
                    return (
                      <MenuItem value={value} key={i}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Field>
              );
            } else if (type === 'option') {
              return (
                <FormControl fullWidth margin="dense" key={i}>
                  <FormControlLabel
                    control={
                      <Field
                        name={`type.attributes.${value}.${name}`}
                        component={Checkbox}
                      />
                    }
                    label={label}
                  />
                </FormControl>
              );
            } else if (type === 'text') {
              if(name === 'floor')
                return(
                  <div key={i} style={{position: 'relative', display:'inline-flex', width: 'calc(50% - 8px)', marginRight: '8px'}}>
                    <Field
                      name={`type.attributes.${value}.${name}`}
                      label={label}
                      component={TextField}
                      normalize={this.normalizeInt}
                      fullWidth
                      required
                      margin="normal"
                    />
                    <Tooltip
                      enterTouchDelay={0}
                      title={
                        <div>
                          <p>
                            Для подвала и цоколя указывать -1. Значение 0 не принимается.
                            Нельзя указать диапазон или перечислить несколько этажей в одном листинге.
                            Указывайте самый нижний этаж из предлагаемых, подробности указывайте в описании.
                          </p>
                        </div>
                      }
                      style={{position: 'absolute', display: 'block', top: 20, right: 5}}
                    >
                      <icons.Help className={classes.help}/>
                    </Tooltip>
                  </div>
                );
              if(name === 'floors')
                return(
                  <Field
                    key={i}
                    name={`type.attributes.${value}.${name}`}
                    label={label}
                    component={TextField}
                    normalize={this.normalizeInt}
                    style={{width: '50%'}}
                    required
                    margin="normal"
                  />
                );
              return (
                <Field
                  key={i}
                  name={`type.attributes.${value}.${name}`}
                  label={label}
                  component={TextField}
                  fullWidth
                  margin="normal"
                />
              );
            }
            return null;
          })
          : null}
        {value ? (
          <Field
            name="additionalTypes"
            label="Дополнительные категории"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value =>
                getLabelsForValue(variants, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {variants
              .filter(variant => value !== variant.value)
              .map(({label, value}, i) => (
                <Variant value={value} label={label} key={i} />
              ))}
          </Field>
        ) : null}
      </div>
    );
  }
}

const styles = {
  help: {
    color: colors.grey[700],
    marginLeft: 12,
  },
};

export default withStyles(styles)(PropertyType);
