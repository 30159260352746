import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import DealOfferAssignees from './DealOfferAssignees';
import DealOfferContact from './DealOfferContact';
import DealOfferListWrapper from './DealOfferListWrapper';
import DealOfferView from './DealOfferView';
import {setTitle} from '../../actions';

class DealOffers extends Component {
  componentDidMount() {
    this.props.setTitle('Сделки');
  }

  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route path={`${match.url}/`} exact component={DealOfferListWrapper}/>
        <Route path={`${match.url}/:dealOfferId/assignees`} exact component={DealOfferAssignees}/>
        <Route path={`${match.url}/:dealOfferId/contact`} exact component={DealOfferContact}/>
        <Route path={`${match.url}/:dealOfferId`} component={DealOfferView}/>
      </Switch>
    );
  }
}

export default connect(null, {setTitle})(DealOffers);
