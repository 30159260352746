import {executeQuery} from '../utils';

export const queryWebPushList = query => {
  return executeQuery(
    `query webPushSubList($offset: Int, $limit: Int, $publicKey: String) {
      webPushSubList(offset: $offset, limit: $limit, publicKey: $publicKey) {
        items {
          createdAt
          deviceName
          publicKey
        }
        current
        totalCount
      }
    }
`,
    {...query},
  );
};

