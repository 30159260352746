const defaultState = {
  publicDeals: {
    status: {
      isLoad: false,
      totalCount: 0,
    },
  },
};

const publicDeals = (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'LOAD_PUBLIC_DEAL_LIST_STARTED':
      return {
        ...state,
        publicDeals: {
          ...state.publicDeals,
          status: {
            isLoad: true,
          },
        },
      };
    case 'LOAD_PUBLIC_DEAL_STARTED':
      return {
        ...state,
        publicDeals: {
          ...state.publicDeals,
          status: {
            isLoad: true,
          },
        },
      };
    case 'LOAD_PUBLIC_DEAL_LIST':
      return {
        ...state,
        publicDeals: {
          ...state.publicDeals,
          publicDeals: {
            ...payload.publicDealList.items,
          },
          status: {
            isLoad: false,
            totalCount: payload.publicDealList.totalCount,
          },
        },
      };
    case 'LOAD_PUBLIC_DEAL':
      return {
        ...state,
        publicDeals: {
          ...state.publicDeals.publicDeals,
          publicDeals: {
            ...state.publicDeals,
            [payload.item.id]: {
              ...payload.item,
            },
          },
          status: {
            isLoad: false,
          },
        },
      };
    case 'UPDATE_PUBLIC_DEAL':
      return {
        ...state,
        publicDeals: {
          ...state.publicDeals.publicDeals,
          publicDeals: {
            ...state.publicDeals,
            [payload.publicDeal.id]: {
              ...payload.publicDeal,
            },
          },
          status: {
            isLoad: false,
          },
        },
      };
    case 'PUBLIC_DEAL_REMOVED': {
      const deals = state.publicDeals.publicDeals.publicDeals;

      if(deals[payload.id]){
        delete deals[payload.id];
      }

      return {
        ...state,
        publicDeals: {
          ...state.publicDeals,
          publicDeals: deals,
        },
      };
    }
    default:
      return state;
  }
};

export default publicDeals;
