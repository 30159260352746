import React, {Component} from 'react';
// import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import {set} from 'lodash';
import axios from 'axios';

import TextField from '../../../components/Input/TextField';
import DatePicker from '../../../components/Input/DatePicker';

class CloseDealOfferForm extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      open,
      pristine,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Закрытие сделки</DialogTitle>
        <Divider/>
        <DialogContent>
          <Field
            name="endAt"
            label="Фактическая дата оплаты"
            format={null}
            component={props => (
              <DatePicker
                format="yyyy-MM-dd"
                clearable
                {...props}
              />
            )}
            fullWidth
            margin="normal"
          />
          <Field
            name="commission"
            label="Фактическая комиссия, руб"
            component={TextField}
            fullWidth
            margin="normal"
          />
          <Field
            name="area"
            label="Сданная площадь, м2"
            component={TextField}
            fullWidth
            margin="normal"
          /> 
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(this.submit)}
          >
            Закрыть сделку
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = data => {
    const {endAt, commission, area} = data;

    return axios.post('/api/v1/dealOfferClose', {id: this.props.dealOffer.id, endAt, commission, area})
      .then(res => {
        if (res.status === 200) {
          this.props.onSubmit(data);
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(error.response.data.error.errors.reduce((errors, {propertyPath, message}) => {
            return set(errors, propertyPath, message);
          }, {}));
        } else {
          alert('Ошибка при сохранении');
        }
      });
  };
}

// CloseDealOfferForm = connect(
//   state => ({
//     dealOfferActiveStatuses: state.root.classification.dealOfferActiveStatuses,
//     offerTypes: state.root.classification.offerTypes,
//     propertyTypes: state.root.classification.propertyTypes,
//     propertyPurposes: state.root.classification.propertyPurposes,
//     teamMembers: state.root.selfUser.teamMembers,
//   })
// )(CloseDealOfferForm);

export default reduxForm({
  form: 'close_form',
  enableReinitialize: true,
})(CloseDealOfferForm);
