import React, {Component} from 'react';
import {connect} from 'react-redux';

import {Route, Switch} from 'react-router-dom';
import {setTitle} from '../../actions';
import ContractListWrapper from './ContractListWrapper';
import ContractView from './ContractView';
import ContractAssignees from './ContractAssignees';
import ContractProperties from './ContractProperties';
import ContractContacts from './ContractContacts';

class Contract extends Component {
  componentDidMount() {
    this.props.setTitle('Договоры');
  }

  render() {
    const {match} = this.props;
    return (
      <Switch>
        <Route path={`${match.url}/:contractId/assignees`} exact component={ContractAssignees}/>
        <Route path={`${match.url}/:contractId/properties`} exact component={ContractProperties}/>
        <Route path={`${match.url}/:contractId/contact`} exact component={ContractContacts}/>
        <Route path={`${match.url}/:contractId`} exact component={ContractView} />
        <Route path={`${match.url}/`} exact component={ContractListWrapper} />
      </Switch>
    );
  }
}

export default connect(
  null,
  {setTitle}
)(Contract);
