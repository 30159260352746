import React, {Component} from 'react';
import {connect} from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import qs from 'qs';
import axios from 'axios';

import OfferList from '../offer/OfferList';
import OfferListItems from '../offer/OfferListItems';
import AddOffer from './form/AddOffer';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DealSelectionDialog from './DealSelectionDialog';
import {queryDealContact, queryInDealOffersSelection} from '../../queries';
import {setTitle, loadOffer} from '../../actions';
import SnackbarError from '../../components/SnackbarError';
import AlertIcon from '../../components/IconWarning';

class DealOffer extends Component {
  state = {
    selectedItems: [],
    attachedItems: [],
    selectItemIds: [],
    selectionOpen: false,
    contact: null,
    error: false,
    addOpen: false,
    errorMessage: '',
  };

  componentDidMount() {
    this.props.setTitle('Выбор листингов');
    this.load();
    let selectItemIds = localStorage.getItem(`deal_offers_selected_${this.props.match.params.dealId}`);
    if(selectItemIds){
      this.setState({
        selectItemIds: JSON.parse(selectItemIds),
      });
    }
  }

  componentWillReceiveProps(newProps){
    if(newProps.offers !== this.props.offers){
      this.checkedDealSelection(newProps);
    }
  }

  checkedDealSelection = props => {
    if(Object.keys(props.offers).length !== 0){
      const {selectedItems, selectItemIds} = this.state;
      selectItemIds.forEach(element => {
        if(!selectedItems.find(item => item.id === element))
          if(props.offers[element]){
            selectedItems.push(props.offers[element]);
          }
      });

      queryInDealOffersSelection({deal: props.match.params.dealId, offers: Object.keys(props.offers)})
        .then(resp => this.setState({
          attachedItems: resp.data.data.checkDealOffersStatus.map(item => item.id),
          attachedItemsStatus: resp.data.data.checkDealOffersStatus.reduce((prev, cur) => ({...prev, [cur.id]: {...cur}}), {}),
          selectedItems: selectedItems,
        }));
    }
  };

  load() {
    const {match} = this.props;
    this.checkedDealSelection(this.props);
    queryDealContact(match.params.dealId)
      .then(resp => {
        if(resp && resp.status === 200 && !resp.data.errors){
          this.setState({
            contact :resp.data.data.deal.contact,
          });
        }
      });
  }

  deleteSelectionItem = item => {
    let {selectedItems, selectItemIds} = this.state;
    let newSelect = selectedItems.filter(selectItem => selectItem.id !== item.id);
    let newSelectItemIds = selectItemIds.filter(selectItem => selectItem !== item.id);
    localStorage.setItem(`deal_offers_selected_${this.props.match.params.dealId}`, JSON.stringify(newSelectItemIds));
    this.setState({
      selectedItems: newSelect,
      selectItemIds: newSelectItemIds,
    });
  };

  createSelectionOpen = () => {
    this.downloadNotFoundOffers(() => {
      this.setState({
        selectionOpen: true,
      });
    });
  };

  attacherOffersOpen = () => {
    this.downloadNotFoundOffers(() => {
      this.setState({
        addOpen: true,
      });
    });
  };

  downloadNotFoundOffers = callback => {
    const {selectItemIds, selectedItems} = this.state;
    if(selectItemIds.length !== selectedItems.length){
      let downloadOffers = [];
      selectItemIds.forEach(element => {
        if(!selectedItems.find(offer => offer.id === element))
          downloadOffers.push(element);
      });

      if(downloadOffers.length !== 0)
        return axios
          .get('/api/offers?' + qs.stringify({ids: downloadOffers}))
          .then(resp => {
            let notFoundOffers = resp.data.items;
            let newOffers = notFoundOffers.concat(selectedItems);
            queryInDealOffersSelection({deal: this.props.match.params.dealId, offers: newOffers.filter(offer => !offer.hasOwnProperty('selectionAttacher')).map(elem => elem.id)})
              .then(res => {
                let attach = res.data.data.checkDealOffersStatus.reduce((prev, cur) => ({...prev, [cur.id]: {...cur}}), {});
                newOffers.forEach(element => {
                  if(!element.hasOwnProperty('selectionAttacher'))
                    element.selectionAttacher = attach[element.id] ? attach[element.id].attach : false;
                });
                this.setState({
                  selectedItems: newOffers,
                }, callback);
              });
          });
    } else {
      if(selectedItems.find(item => !item.hasOwnProperty('selectionAttacher')))
        queryInDealOffersSelection({deal: this.props.match.params.dealId, offers: selectedItems.filter(offer => !offer.hasOwnProperty('selectionAttacher')).map(elem => elem.id)})
          .then(res => {
            let attach = res.data.data.checkDealOffersStatus.reduce((prev, cur) => ({...prev, [cur.id]: {...cur}}), {});
            selectedItems.forEach(element => {
              if(!element.hasOwnProperty('selectionAttacher'))
                element.selectionAttacher = attach[element.id] ? attach[element.id].attach : false;
            });
            this.setState({
              selectedItems: selectedItems,
            }, callback);
          });
      else{
        callback();
      }
    }
  };

  openAddSubmit = () => {
    this.setState({selectionOpen: false});
    this.props.history.push(`/deals/${this.props.match.params.dealId}`);
  };

  render() {
    const {
      history,
      location,
      match,
      classes,
    } = this.props;

    const {
      selectedItems,
      attachedItems,
      attachedItemsStatus,
      selectionOpen,
      contact,
      error,
      errorMessage,
      addOpen,
      selectItemIds,
    } = this.state;

    return (
      <React.Fragment>
        <OfferList
          selectedItems={attachedItems}
          history={history}
          match={match}
          location={location}
          actionRight
          actionReset={
            selectItemIds.length > 0 ?
              <React.Fragment>
                <Checkbox
                  className={classes.resetAll}
                  checked={true}
                  title={'Сбросить выбранные'}
                  onClick={e => {
                    this.setState({
                      selectedItems: [],
                      selectItemIds: [],
                    });
                    localStorage.removeItem(`deal_offers_selected_${match.params.dealId}`);
                    e.stopPropagation();
                  }}
                />
              </React.Fragment>
              : null
          }
          actionResetMobile={
            selectItemIds.length > 0 ?
              <React.Fragment>
                <Checkbox
                  className={classes.resetAllMobile}
                  checked={true}
                  title={'Сбросить выбранные'}
                  onClick={e => {
                    this.setState({
                      selectedItems: [],
                      selectItemIds: [],
                    });
                    localStorage.removeItem(`deal_offers_selected_${match.params.dealId}`);
                    e.stopPropagation();
                  }}
                />
              </React.Fragment>
              : null
          }
          action={(item, selected) => {
            let icon = null;
            let attachIcon = null;
            if(selected){
              let status = attachedItemsStatus[item.id];
              if(status && status.selection)
                icon = attachedItemsStatus[item.id].selection === 'selected'
                  ? <icons.PlaylistAddCheck titleAccess={'Был добавлен в подборку'} style={{fontSize: '1rem', color: colors.green[300]}}/>
                  : <icons.Email titleAccess={'Был отправлен на почту клиенту'} style={{fontSize: '1rem', color: colors.green[300]}}/>;
              if(status && status.attach){
                attachIcon = <AlertIcon message={'Листинг прикреплен'} withoutMargin/>;
              }
            }
            return(
              <React.Fragment>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{flex: 1, marginTop: 2}}>
                    {icon}
                    {attachIcon}
                  </div>
                  <Checkbox
                    style={{padding: 0, flex: 1}}
                    checked={Boolean(selectItemIds.find(selectItem => selectItem === item.id))}
                    onChange={e => {
                      let {selectedItems} = this.state;
                      if(e.target.checked){
                        if(selectItemIds.length < 15){

                          item.selectionAttacher = attachedItemsStatus[item.id] && attachedItemsStatus[item.id].attach;
                          selectedItems.push(item);
                          selectItemIds.push(item.id);
                          localStorage.setItem(`deal_offers_selected_${match.params.dealId}`, JSON.stringify(selectItemIds));
                          this.setState({
                            selectedItems: selectedItems,
                            selectItemIds: selectItemIds,
                          });
                        } else {
                          this.setState({
                            error: true,
                            errorMessage: 'Нельзя прикрепить больше 15 листингов',
                          });
                        }
                      }
                      else
                        this.deleteSelectionItem(item);
                    }}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  />
                </div>
              </React.Fragment>
            );}}
        >
          <OfferListItems/>
        </OfferList>
        {selectItemIds.length > 0 ?
          <React.Fragment>
            <Paper
              className={classes.paper}
              elevation={1}
            >
              <Typography variant="subtitle2" style={{color: '#fff', padding: '16px 16px 16px 16px'}}>Выбрано {selectItemIds.length} из 15</Typography>
            </Paper>
            <div className={classes.fabContainer}>
              <Fab
                color="secondary"
                variant="extended"
                className={classes.fab}
                size="medium"
                onClick={this.attacherOffersOpen}
              >
                Прикрепить
              </Fab>
              <Fab
                color="secondary"
                variant="extended"
                size="medium"
                className={classes.fab}
                onClick={this.createSelectionOpen}
              >
                  Создать подборку
              </Fab>
            </div>
          </React.Fragment>
          : null}
        {selectionOpen ? (
          <DealSelectionDialog
            open={selectionOpen}
            handleDeleteItem={this.deleteSelectionItem}
            handleClose={() => this.setState({selectionOpen: false})}
            contact={contact}
            onSubmitted={() => this.setState({selectionOpen: false})}
            offers={selectedItems}
            match={match}
            history={history}
            dealId={match.params.dealId}
          />
        ) : null}
        <SnackbarError
          open={error}
          errorMessage={errorMessage}
          onClose={() => this.setState({error: false})}
        />
        {addOpen
          ? <AddOffer
            open={addOpen}
            onSubmitted={this.openAddSubmit}
            onClose={() => this.setState({addOpen: false})}
            selectItemIds={selectItemIds}
            dealId={match.params.dealId}
            changeOffers={offers => this.setState({selectedItems: selectedItems.concat(offers)})}
            initialValues={{
              status: 'new',
              incoming: false,
            }}
            offers={selectedItems.sort(a => a.selectionAttacher ? 1 : -1)}
            handleDeleteItem={this.deleteSelectionItem}
          />
          : null}
      </React.Fragment>
    );
  }
}
const styles = theme => ({
  fabContainer: {
    zIndex: 50,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 6,
  },
  fab: {
    marginRight: 5,
  },
  paper: {
    zIndex: 50,
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    color: '#fff',
    backgroundColor: 'rgb(49, 49, 49)',
    left: theme.spacing.unit * 6,
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing.unit * 9,
      left: 'auto',
      right: theme.spacing.unit * 6,
      marginRight: 5,
    },
  },
  resetAll: {
    position: 'absolute',
    top: 10,
    zIndex: 100,
  },
  resetAllMobile: {
    zIndex: 100,
    top: 10,
    right: 10,
    padding: 0,
    position: 'absolute',
  },
});


export default withStyles(styles)(
  connect(state => ({
    offers: state.root.offer.offers,
  }), {
    setTitle, loadOffer,
  })(DealOffer));
