import * as axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import DealOfferDetailsWrapper from './DealOfferDetailsWrapper';
import Assignees from './relations/Assignees';
import Contact from './relations/Contact';
import Offer from './relations/Offer';
import Deals from './relations/Deals';
import Row from '../../components/Row';
import Column from '../../components/Column';
import EditableComment from '../../components/EditableComment';
import {queryDealOffer} from '../../queries/DealOffers';
import {createAreaFormatter, mapLabels} from '../../utils';
import ContactDialog from '../contacts/ContactDialog';
import OfferDialog from '../offer/OfferDialog';
import AssigneeDialog from '../contacts/AssigneeDialog';
import DealDialog from '../deals/DealDialog';
import Documents from './relations/Documents';
import CloseDealOfferForm from './form/CloseDealOfferForm';
import DeclineDealOfferForm from './form/DeclineDealOfferForm';
import MiddleColumn from '../../components/MiddleColumn';
import PressReleaseForm from './form/PressReleaseForm';
import PressReleaseEdit from '../pressRelease/PressReleaseEdit';
//import {hasRole} from '../../utils/roleFunc';
//import * as userRoles from '../../constants/userRoles';
import ActivityEventsList from '../../components/ActivityEventsList';

class DealOfferView extends Component {
  state = {
    openAdd: null,
    openEdit: false,
    dealOffer: null,
    declineDealOffer: false,
    closeDealOffer: false,
    openContactDialog: false,
    openOfferDialog: false,
    openAssigneeDialog: false,
    openDealDialog: false,
    pressReleaseEdit: false,
    pressReleaseOpen: false,
  };

  componentDidMount() {
    this.load();
  }

  load() {
    const {match} = this.props;
    this.setState({loading: true});
    queryDealOffer({id: match.params.dealOfferId})
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            loading: false,
            error: false,
            dealOffer: resp.data.data.dealOffer,
          });
          return;
        }
        this.setState({loading: false, error: true});
      })
      .catch(() => {
        this.setState({loading: false, error: true});
      });
  }

  render() {
    const {
      dealOffer,
      error,
      openAssigneeDialog,
      openOfferDialog,
      openContactDialog,
      openDealDialog,
      closeDealOffer,
      declineDealOffer,
      pressReleaseOpen,
      pressReleaseEdit,
    } = this.state;

    const {
      classes,
      history,
      //selfUser,
      match,
    } = this.props;
    return (
      <React.Fragment>
        <Row>
          <Column label="Информация" icon={<icons.InfoOutlined/>} key="information">
            <Paper elevation={1} square className="column">
              {dealOffer ? (
                <DealOfferDetailsWrapper
                  dealOffer={dealOffer}
                  onStatusChange={status => this.saveStatus(dealOffer.id, status, dealOffer.status)}
                  onAdditionalChange={additional => this.saveAdditional(dealOffer.id, additional, dealOffer.additional)}
                  onPotentialEndChange={potentialEndAt => this.onPotentialEndChange(dealOffer.id, potentialEndAt, dealOffer.potentialEndAt)}
                />) : (
                error ? (
                  <icons.ErrorOutline className={classes.progress}/>
                ) : (
                  <CircularProgress size={50} thickness={4} className={classes.progress}/>
                )
              )}
            </Paper>
          </Column>
          <Column label="История" icon={<icons.History/>} key="activity">
            <div className="column" style={{background: colors.grey[200], zIndex: 0}}>
              <div className="content" style={{padding: 16}}>
                {dealOffer ? (
                  <MiddleColumn entityId={dealOffer.id} entityName="DealOffer">
                    <EditableComment
                      comment={dealOffer.comment}
                      onSave={comment => this.saveComment(dealOffer.id, comment, dealOffer.comment)}
                    />
                    {//hasRole(selfUser.role, userRoles.ADMIN) || selfUser.team === dealOffer.team.id ?
                      <div style={{marginTop: 20}}>
                        <ActivityEventsList
                          entityId={match.params.dealOfferId}
                          onRef={ref => (this.activityEventsList = ref)}
                          aggregateType="dealOffer"
                        />
                      </div>
                      // : null
                    }
                  </MiddleColumn>
                ) : (
                  error ? (
                    <icons.ErrorOutline className={classes.progress}/>
                  ) : (
                    <CircularProgress size={50} thickness={4} className={classes.progress}/>
                  )
                )}
              </div>
            </div>
          </Column>
          <Column label="Связи" icon={<icons.Share/>} key="relationships">
            <Paper elevation={1} square className="column">
              <div className="content">
                {dealOffer ? (
                  <>
                    <Assignees updateActivity={() => this.activityEventsList.updateActivity()} dealOffer={dealOffer} handleDialogOpen={e => this.handleDialogOpen(e, 'openAssigneeDialog')}/>
                    <Divider/>
                    <Contact dealOffer={dealOffer} handleDialogOpen={e => this.handleDialogOpen(e, 'openContactDialog')}/>
                    <Divider/>
                    <Documents updateActivity={() => this.activityEventsList.updateActivity()} dealOffer={dealOffer}/>
                    <Divider/>
                    <Offer dealOffer={dealOffer} handleDialogOpen={e => this.handleDialogOpen(e, 'openOfferDialog')}/>
                    <Divider/>
                    <Deals
                      onRef={ref => (this.dealComponent = ref)}
                      deal={dealOffer.deal}
                      handleDialogOpen={e => this.handleDialogOpen(e, 'openDealDialog')}
                    />
                  </>) : (
                  error ? (
                    <icons.ErrorOutline className={classes.progress}/>
                  ) : (
                    <CircularProgress size={50} thickness={4} className={classes.progress}/>
                  )
                )}
              </div>
            </Paper>
          </Column>
        </Row>
        {openContactDialog ? (
          <ContactDialog
            history={history}
            contact={openContactDialog}
            open={Boolean(openContactDialog)}
            handleClose={() => this.handleDialogClose('openContactDialog')}
          />
        ) : null}
        {openOfferDialog ? (
          <OfferDialog
            history={history}
            offer={openOfferDialog}
            open={Boolean(openOfferDialog)}
            handleClose={() => this.handleDialogClose('openOfferDialog')}
          />
        ) : null}
        {openAssigneeDialog ? (
          <AssigneeDialog
            history={history}
            member={openAssigneeDialog}
            open={Boolean(openAssigneeDialog)}
            handleClose={() => this.handleDialogClose('openAssigneeDialog')}
          />
        ) : null}
        {openDealDialog ? (
          <DealDialog
            history={history}
            deal={openDealDialog}
            open={Boolean(openDealDialog)}
            handleClose={() => this.handleDialogClose('openDealDialog')}
            reload={() => this.dealComponent.load()}
          />
        ) : null}
        {closeDealOffer ? (
          <CloseDealOfferForm
            history={history}
            dealOffer={dealOffer}
            onSubmit={data => this.handleCloseDealOffer(data)}
            onClose={() => this.setState({closeDealOffer: false})}
            open={Boolean(closeDealOffer)}
            initialValues={{
              endAt: null,
              area: dealOffer.offer.property.normalizedArea,
            }}
          />
        ) : null}
        {pressReleaseOpen ? (
          <PressReleaseForm
            dealOffer={dealOffer}
            submit={() => this.setState({pressReleaseOpen: false, pressReleaseEdit: true})}
            onClose={() => this.setState({pressReleaseOpen: false})}
            open={Boolean(pressReleaseOpen)}
          />
        ) : null}
        {pressReleaseEdit ? (
          <PressReleaseEdit
            dealOffer={dealOffer}
            onSubmitted={data => {
              this.setState({pressReleaseEdit: false});
              history.push(`/pressRelease/dealClose/${data.id}`);
            }}
            onClose={() => this.setState({pressReleaseEdit: false})}
            type={'dealClose'}
            open={Boolean(pressReleaseEdit)}
          />
        ) : null}

        {declineDealOffer ? (
          <DeclineDealOfferForm
            history={history}
            dealOffer={dealOffer}
            onSubmit={data => this.handleDeclineDealOffer(data)}
            onClose={() => this.setState({declineDealOffer: false})}
            open={Boolean(declineDealOffer)}
          />
        ) : null}
      </React.Fragment>
    );
  }

  handleDialogClose = name => {
    this.setState({[name]: false});
  };

  handleDialogOpen = (value, name) => {
    this.setState({[name] : value});
  };

  saveStatus = (id, newStatus, oldStatus) => {
    if (newStatus === 'closed') {
      return this.setState({closeDealOffer: true});
    } else if (newStatus === 'declined') {
      return this.setState({declineDealOffer: true});
    }

    this.setState({
      dealOffer: {
        ...this.state.dealOffer,
        status: newStatus,
      },
    });

    return axios.post('/api/v1/setDealOfferStatus', {id, status: newStatus})
      .then(() => {
        this.activityEventsList && this.activityEventsList.updateActivity();
        this.setState({
          dealOffer: {
            ...this.state.dealOffer,
            dealEndAt: null,
            commission: null,
          },
        });
      })
      .catch(() => {
        this.setState({
          dealOffer: {
            ...this.state.dealOffer,
            status: oldStatus,
          },
        });
        throw new Error();
      });
  };

  handleCloseDealOffer = data => {
    const {endAt, commission, area} = data;

    this.setState({
      closeDealOffer: false,
      pressReleaseOpen: true,
      dealOffer: {
        ...this.state.dealOffer,
        dealEndAt: endAt,
        commission,
        area,
        status: 'closed',
      },

    });
    this.activityEventsList && this.activityEventsList.updateActivity();
  };

  handleDeclineDealOffer = data => {
    const {comment, reason} = data;

    this.setState({
      declineDealOffer: false,
      dealOffer: {
        ...this.state.dealOffer,
        declineReason: reason,
        declineComment: comment,
        status: 'declined',
        dealEndAt: new Date(),
      },
    });

  };

  saveComment = (id, nextComment, prevComment) => {
    this.setState({
      dealOffer: {
        ...this.state.dealOffer,
        comment: nextComment,
      },
    });

    return axios.post('/api/v1/setDealOfferComment', {id, comment: nextComment})
      .then(() => this.activityEventsList && this.activityEventsList.updateActivity())
      .catch(() => {
        this.setState({
          dealOffer: {
            ...this.state.dealOffer,
            comment: prevComment,
          },
        });
        throw new Error();
      });
  };

  saveAdditional = (id, newAdditional, oldAdditional) => {
    this.setState({
      dealOffer: {
        ...this.state.dealOffer,
        additional: newAdditional,
      },
    });

    return axios.post('/api/v1/setDealOfferAdditional', {id, additional: newAdditional})
      .then(() => this.activityEventsList && this.activityEventsList.updateActivity())
      .catch(() => {
        this.setState({
          dealOffer: {
            ...this.state.dealOffer,
            additional: oldAdditional,
          },
        });
        throw new Error();
      });
  };

  onPotentialEndChange = (id, potentialEndAt, oldPotentialEndAt) => {
    this.setState({
      dealOffer: {
        ...this.state.dealOffer,
        potentialEndAt: potentialEndAt,
      },
    });
    return axios.post('/api/v1/setDealOfferPotentialEndAt', {id, potentialEndAt})
      .then(() => this.activityEventsList && this.activityEventsList.updateActivity())
      .catch(() => {
        this.setState({
          dealOffer: {
            ...this.state.dealOffer,
            potentialEndAt: oldPotentialEndAt,
          },
        });
        throw new Error();
      });
  };
}

const styles = () => ({
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
});

export default connect(
  state => ({
    formatArea: createAreaFormatter(state.root.classification.areaUnits),
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    selfUser: state.root.selfUser,
  })
)(withStyles(styles)(DealOfferView));
