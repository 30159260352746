import axios from 'axios';
import {CircularProgress} from '@material-ui/core';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

import {setTitle} from '../actions';

class ExternalLink extends Component {
  state = {
    targetId: null,
    loading: false,
    error: false,
  };

  componentDidMount() {
    const {match} = this.props;
    this.props.setTitle('Переход в листинг...');

    this.setState({loading: true});
    axios.get(`/api/externalOffers/${match.params.id}`)
      .then(resp => {
        this.setState({
          targetId: resp.data.id,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({error: true, loading: false});
      });
  }

  render() {
    const {error, loading, targetId} = this.state;

    if (targetId) {
      return <Redirect to={`/offers/${targetId}`}/>;
    }

    return (
      <div style={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
        {loading ? (
          <CircularProgress style={{color: colors.grey[400], position: 'absolute'}} size={50} thickness={4}/>
        ) : null}
        {error ? (
          <icons.Error style={{color: colors.grey[400], width: 32, height: 32}}/>
        ) : null}
      </div>
    );
  }
}

export default connect(null, {setTitle})(ExternalLink);
