import React, {Component} from 'react';
import {connect} from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';

import OfferList from '../offer/OfferList';
import OfferListItems from '../offer/OfferListItems';
import {queryInDealOffersSelection} from '../../queries';
import {loadDealSelection, changeOffersFromSelection, setTitle} from '../../actions';
import SnackbarError from '../../components/SnackbarError';

class DealSelection extends Component {
  state = {
    selectedItems: [],
    attachedItems: [],
    selectionOpen: false,
    contact: null,
    error: false,
    attachedItemsStatus: [],
    errorMessage: '',
  };

  componentDidMount() {
    const {match: {params}, variableSelection, setTitle} = this.props;
    if(variableSelection){
      this.setState({
        selectedItems: variableSelection.offers,
      });
    }
    else {
      this.props.loadDealSelection(parseInt(params.selectionId));
    }
    setTitle('Выбор листингов');
  }

  componentWillReceiveProps(newProps) {
    if(newProps.offers !== this.props.offers){
      this.checkedDealSelection(newProps);
    }
    if((newProps.variableSelection && !this.props.variableSelection) ||
     (newProps.variableSelection && this.props.variableSelection &&
      (newProps.variableSelection.id !== this.props.variableSelection.id || this.props.variableSelection.offers.length !== newProps.variableSelection.offers.length)))
      this.setState({
        selectedItems: newProps.variableSelection.offers,
      });
  }

  checkedDealSelection = props => {
    queryInDealOffersSelection({deal: props.match.params.dealId, offers: Object.keys(props.offers)})
      .then(resp => this.setState({
        attachedItems: resp.data.data.checkDealOffersStatus.map(item => item.id),
        attachedItemsStatus: resp.data.data.checkDealOffersStatus.reduce((prev, cur) => ({...prev, [cur.id]: {...cur}}), {}),
      }));
  };

  deleteSelectionItem = item => {
    let {selectedItems} = this.state;
    let newSelect = selectedItems.filter(selectItem => selectItem.id !== item.id);
    this.setState({
      selectedItems: newSelect,
    });
  };

  changeOffers = () => {
    const {match: {params: {dealId, selectionId}}, history, changeOffersFromSelection} = this.props;
    const {selectedItems} = this.state;
    changeOffersFromSelection(selectedItems);
    history.push(`/deals/${dealId}/selections/${selectionId}/`);
  };

  cancel = () =>{
    const {history, match: {params: {dealId, selectionId}}} = this.props;
    history.push(`/deals/${dealId}/selections/${selectionId}/`);
  };

  render() {
    const {
      history,
      location,
      match,
      classes,
    } = this.props;

    const {
      selectedItems,
      attachedItems,
      attachedItemsStatus,
      error,
      errorMessage,
    } = this.state;

    return (
      <React.Fragment>
        <OfferList
          selectedItems={attachedItems}
          history={history}
          match={match}
          location={location}
          actionRight
          action={(item, selected) => {
            let icon = null;
            if(selected){
              if(attachedItemsStatus[item.id].selection)
                icon = attachedItemsStatus[item.id].selection === 'selected'
                  ? <icons.PlaylistAddCheck titleAccess={'Был добавлен в подборку'} style={{fontSize: '1rem', color: colors.green[300]}}/>
                  : <icons.Email titleAccess={'Был отправлен на почту клиенту'} style={{fontSize: '1rem', color: colors.green[300]}}/>;
            }
            return(
              <React.Fragment>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{flex: 1, marginTop: 2}}>
                    {icon}
                  </div>
                  <Checkbox
                    style={{padding: 0, flex: 1}}
                    checked={Boolean(selectedItems.find(selectItem => selectItem.id === item.id))}
                    onChange={e => {
                      let {selectedItems} = this.state;
                      if(e.target.checked){
                        if(selectedItems.length < 15){
                          selectedItems.push(item);
                          this.setState({
                            selectedItems: selectedItems,
                          });
                        }else{
                          this.setState({
                            error: true,
                            errorMessage: 'Нельзя прикрепить больше 15 листингов',
                          });
                        }
                      }
                      else
                        this.deleteSelectionItem(item);
                    }}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  />
                </div>
              </React.Fragment>
            );}}
        >
          <OfferListItems/>
        </OfferList>
        {selectedItems.length > 0 ?
          <React.Fragment>
            <Paper
              className={classes.paper}
              elevation={1}
            >
              <Typography variant="subtitle2" style={{color: '#fff', padding: '16px 16px 16px 16px'}}>Выбрано {selectedItems.length} из 15</Typography>
            </Paper>
            <div className={classes.fabContainer}>
              <Fab
                color="secondary"
                variant="extended"
                className={classes.fab}
                size="medium"
                onClick={this.changeOffers}
              >
                Изменить
              </Fab>
              <Fab
                color="secondary"
                variant="extended"
                className={classes.fab}
                size="medium"
                onClick={this.cancel}
              >
                Отменить
              </Fab>
            </div>
          </React.Fragment>
          : null}
        <SnackbarError
          open={error}
          errorMessage={errorMessage}
          onClose={() => this.setState({error: false})}
        />

      </React.Fragment>
    );
  }
}
const styles = theme => ({
  fabContainer: {
    zIndex: 50,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 6,
  },
  fab: {
    marginRight: 5,
  },
  paper: {
    zIndex: 50,
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    color: '#fff',
    backgroundColor: 'rgb(49, 49, 49)',
    left: theme.spacing.unit * 6,
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing.unit * 9,
      left: 'auto',
      right: theme.spacing.unit * 6,
      marginRight: 5,
    },
  },
});

export default withStyles(styles)(
  connect(
    state => ({
      variableSelection: state.root.selection.variableSelection,
      offers: state.root.offer.offers,
      selfUser: state.root.selfUser,
    }),
    {
      setTitle,
      loadDealSelection,
      changeOffersFromSelection,
    })(DealSelection));
