const defaultState = {
  teams: [],
  teamMembers: [],
};

const selfUser = (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'SELF_USER_TEAMS_LOAD_COMPLETED': {
      return {
        ...state,
        teams: payload.teams,
      };
    }

    case 'SELF_USER_TEAMS_LOAD_FAILED':
      return state;

    case 'SELF_USER_LOAD_COMPLETED':
      return {
        ...state,
        ...payload.selfUser,
      };

    case 'SELF_USER_LOAD_FAILED':
      return state;

    default:
      return state;
  }
};

export default selfUser;
