import axios from 'axios';
import {set} from 'lodash';
import React, {Component} from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {TextField} from 'redux-form-material-ui';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';

class AddRelationship extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      open,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Новая связь</DialogTitle>
        <Divider/>
        <DialogContent>
          <form>
            <FormControl fullWidth margin="normal">
              <Field
                name="role"
                label="Роль"
                component={TextField}
              />
            </FormControl>
          </form>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={handleSubmit(this.submit)}
          >
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = data => {
    const {onSubmitted} = this.props;

    return axios.post(`/api/contacts/${data.contactId}/relationships`, data)
      .then(() => onSubmitted(data))
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(error.response.data.error.errors.reduce((errors, {propertyPath, message}) => {
            return set(errors, propertyPath, message);
          }, {}));
        } else if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

export default reduxForm({
  form: 'add_relationship_to_contact',
  enableReinitialize: true,
})(AddRelationship);
