import pathtoRegexp from 'path-to-regexp';
import React, {Component} from 'react';
import dateIsSameDay from 'date-fns/isSameDay';
import {ListItem, ListItemText} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import {NavLink, withRouter} from 'react-router-dom';
import formatDate from '../../../utils/formatDate';
import Calendar from './Calendar';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';

class Sidebar extends Component {
  matchPath = pathtoRegexp.compile(this.props.match.path);

  render() {
    const {classes, currentDay, match, open, selectedDay, taskDays, weekSelected} = this.props;

    return (
      <div className={classNames(classes.root, {[classes.opened]: open})}>
        <List>
          {[{role: 'assigned', label: 'Ответственный'},
            {role: 'authored', label: 'Постановщик'},
            {role: 'associated', label: 'Соисполнитель'},
            {role: 'observed', label: 'Наблюдатель'},
          ].map(({role, label}, index) => {
            return (
              <ListItem
                key={index}
                button
                component={NavLink}
                to={this.matchPath({...match.params, role})}
                activeClassName={classes.selectedItem}
              >
                <ListItemText primary={label} classes={{primary: classes.itemLabel}}/>
              </ListItem>
            );
          })}
        </List>
        <Divider/>
        <List>
          {[{interval: 'all', label: 'Все задачи'},
            {interval: 'day', label: 'На день'},
            {interval: 'week', label: 'На неделю'},
            {interval: 'month', label: 'На месяц'},
          ].map(({interval, label}, index) => {
            return (
              <ListItem
                key={index}
                button
                component={NavLink}
                to={this.matchPath({...match.params, interval})}
                activeClassName={classes.selectedItem}
              >
                <ListItemText primary={label} classes={{primary: classes.itemLabel}}/>
              </ListItem>
            );
          })}
        </List>
        <Divider/>
        <div className={classes.selectedDay}>
          <Typography variant="subtitle1">{formatDate(selectedDay, 'dd MMM yyyy')}</Typography>
          <Button
            disabled={dateIsSameDay(selectedDay, currentDay)}
            className={classes.currentDayButton}
            size="small"
            onClick={() => {
              this.props.history.push(this.matchPath({...match.params, day: null}));
            }}
          >
            Сегодня
          </Button>
        </div>
        <Calendar
          selectedDate={selectedDay}
          taskDays={taskDays}
          weekSelected={weekSelected}
          onChange={date => {
            this.props.history.push(this.matchPath({...match.params, day: formatDate(date, 'yyyy-MM-dd')}));
          }}
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.divider}`,
    background: 'white',
    overflowY: 'auto',
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      width: 320,
    },
  },
  opened: {
    display: 'block',
  },
  currentDayButton: {
    marginRight: -12,
  },
  itemLabel: {
    fontWeight: 'inherit',
  },
  selectedItem: {
    fontWeight: 500,
  },
  selectedDay: {
    padding: '8px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '8px 16px',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default withRouter(withStyles(styles)(Sidebar));
