import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import PropertyAssignees from './PropertyAssignees';
import PropertyOwner from './PropertyOwner';
import PropertyListWrapper from './PropertyListWrapper';
import PropertyView from './PropertyView';
import {setTitle} from '../../actions';

class Property extends Component {
  componentDidMount() {
    this.props.setTitle('Объекты');
  }

  render() {
    const {match} = this.props;

    //alert
    return (
      <Switch>
        <Route path={`${match.url}/`} exact component={PropertyListWrapper}/>
        <Route path={`${match.url}/:propertyId/assignees`} exact component={PropertyAssignees}/>
        <Route path={`${match.url}/:propertyId/owner`} exact component={PropertyOwner}/>
        <Route path={`${match.url}/:propertyId`} component={PropertyView}/>
      </Switch>
    );
  }
}

export default connect(null, {setTitle})(Property);
