import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import DatePicker from 'material-ui-pickers/DatePicker';
import {formatDateWithoutTime} from '../../../utils';

class PotentialDealEndField extends Component {
  state = {
    editing: false,
    saving: false,
    error: false,
    potentialEndAt: null,
  };

  componentDidMount() {
    this.setState({potentialEndAt: this.props.potentialEndAt});
  }

  handleDateChange = date => {
    this.setState({potentialEndAt: date});
  };

  handleClickSave = () => {
    const {onPotentialEndChange} = this.props;
    this.setState({
      saving: true,
    });
    onPotentialEndChange(this.state.potentialEndAt)
      .then(() => this.setState({editing: false}))
      .catch(() => {
        this.setState({potentialEndAt: this.props.potentialEndAt});
        alert('Произошла ошибка');
      })
      .then(() => this.setState({saving: false, editing: false}));
  };

  handleClickCancel = () => {
    this.setState({editing: false, potentialEndAt: this.props.potentialEndAt});
  };

  render() {
    const {
      classes,
    } = this.props;

    const {editing, saving, potentialEndAt} = this.state;

    return (
      <React.Fragment>
        {editing ? (
          <ListItem dense>
            <ListItemText
              disableTypography={true}
              primary={
                <React.Fragment>
                  <DatePicker
                    margin="none"
                    format="yyyy-MM-dd"
                    fullWidth
                    value={potentialEndAt}
                    onChange={this.handleDateChange}
                    className={classes.inputRoot}
                    clearLabel="Сбросить"
                    cancelLabel="Отменить"
                  />
                  <div className={classes.buttons}>
                    <Button
                      className={classes.button}
                      onClick={this.handleClickCancel}
                      disabled={saving}
                    >
                      Отменить
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={this.handleClickSave}
                      disabled={saving}
                    >
                      Сохранить
                    </Button>
                  </div>
                </React.Fragment>
              }
            />
          </ListItem>   
        ) : (
          <ListItem 
            dense 
            button 
            className={classes.requirementsItem} 
            onClick={() => this.setState({editing: true})}
          >
            <ListItemText classes={{primary: classes.additionalField}} primary={formatDateWithoutTime(potentialEndAt)} secondary="Потенциальная дата оплаты"/>
            <icons.Edit className={classes.editIcon}/>
          </ListItem>
        )}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  editIcon: {
    fontSize: 18,
    color: colors.grey[600],
    visibility: 'hidden',
    marginLeft: 'auto',  
  },
  requirementsItem: {
    whiteSpace: 'pre-line',
    cursor: 'pointer',
    '&:hover $editIcon': {
      visibility: 'visible',
    },
  },
  menuPaper: {
    minWidth: 288,
  },
  inputRoot: {
    fontSize: theme.typography.pxToRem(13),
  },
  buttons: {
    textAlign: 'right',
    padding: 8,
  },
  additionalField: {
    overflow: 'hidden',
  },
});

export default withStyles(styles)(PotentialDealEndField);
