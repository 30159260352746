import {executeQuery} from '../utils';

export const queryUpdateList = query => {
  return executeQuery(
    `query systemUpdateList ($offset: Int, $limit: Int, $id: Int) {
      systemUpdateList(offset: $offset, limit: $limit, id: $id){
        items{
          id
          dateTime
          description
          major
          minor
          video
        }
        totalCount
        nav{
          offset
          limit
        }
      }
    }`,
    {...query},
  );
};
