import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import {setTitle} from '../../actions';
import CityList from './CityList';
import ReferenceList from './ReferenceList';
import SemanticsList from './SemanticsList';

class Reference extends Component {
  componentDidMount() {
    this.props.setTitle('Справочники');
  }

  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route path={`${match.url}/`} exact key="city" render={props => <CityList level="city" {...props}/>} />
        <Route path={`${match.url}/cities/:cityId`} exact key="district" render={props => <CityList level="district" {...props}/>} />
        <Route path={`${match.url}/cities/:cityId/:districtId`} exact key="microDistrict" render={props => <CityList level="microDistrict" {...props}/>} />
        <Route path={`${match.url}/activities/`} exact key="activities" render={props => <ReferenceList section="activities" {...props}/>} />
        <Route path={`${match.url}/activitiesDetails/`} exact key="activitiesDetails" render={props => <ReferenceList section="activitiesDetails" {...props}/>} />
        <Route path={`${match.url}/semantics/`} exact key="semantics" render={props => <SemanticsList section="semantics" {...props}/>} />
      </Switch>
    );
  }
}

export default connect(
  null,
  {setTitle}
)(Reference);
