import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {mapLabels} from '../../../utils';
import TableLink from '../../../components/TableLink';

class ArchivedOffersTable extends Component {
  constructor(props) {
    super(props);

    if (typeof props.statistics.items === 'undefined' || props.statistics.items === null) {
      props.statistics.items = [];
    }
  }

  render() {
    const {
      classes,
      statistics,
      title,
      titleClass,
      getHref,
      handleClickOpen,
      offerSources,
      offerSourcesLabels,
      offerArchiveReasonsLabels,
    } = this.props;

    let total = {},
      sum = {},
      offerSourcesSpan = offerSources.length + 1,
      offerReasonsSpan = offerSourcesSpan * Object.keys(offerSourcesLabels).length;

    return (
      <React.Fragment>
        <Typography variant="subtitle1" className={titleClass}>{title}</Typography>
        <Paper
          className={classes.root}
          style={{
            overflowX: 'auto',
            marginBottom: 104,
          }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow classes={{root: classes.tableRow}}>
                <TableCell rowSpan={3}>№</TableCell>
                <TableCell rowSpan={3} className={classes.memberNameColumn}>Брокер</TableCell>
                <TableCell colSpan={offerReasonsSpan} className={classes.tableCellCenter}>Листингов всего</TableCell>
              </TableRow>
              <TableRow classes={{root: classes.tableRow}}>
                {Object.values(offerArchiveReasonsLabels).map((reason, i) => (
                  <TableCell
                    colSpan={offerSourcesSpan}
                    key={'reasons-' + i}
                    className={classes.tableCellCenter}
                  >{reason}</TableCell>
                ))}
              </TableRow>
              <TableRow classes={{root: classes.tableRow}}>
                {Object.keys(offerArchiveReasonsLabels).map(function (reason, i) {
                  if (typeof total[reason] === 'undefined') {
                    total[reason] = {};
                  }
                  if (typeof sum[reason] === 'undefined') {
                    sum[reason] = 0;
                  }

                  let cells = offerSources.map(function (source, j) {
                    if (typeof total[reason][source.value] === 'undefined') {
                      total[reason][source.value] = 0;
                    }
                    return (
                      <TableCell
                        key={i + '-sources-' + j}
                        className={classes.tableCellCenter}
                      >{source.short}</TableCell>
                    );
                  });
                  cells.push(
                    <TableCell
                      key={i + '-sources-total'}
                      className={classes.tableCellCenter}
                    >Итого</TableCell>
                  );
                  return cells;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {statistics.items.map((item, n) => (
                <TableLink
                  key={n}
                  href={getHref(item.id)}
                  className={classes.tableRow}
                  hover
                  onClick={() => handleClickOpen(item.id)}
                >
                  <TableCell>{n + 1}</TableCell>
                  <TableCell
                    className={classes.memberNameColumn}
                    title={item.address}
                  >{item.member}</TableCell>
                  {Object.keys(offerArchiveReasonsLabels).map(function (reason, i) {
                    let rowSum = 0;
                    let cells = offerSources.map(function (source, j) {
                      const value = item['reason_' + reason][source.value] || 0;
                      total[reason][source.value] += value;
                      sum[reason] += value;
                      rowSum += value;
                      return (
                        <TableCell
                          key={n + '-reason-' + i + 'source-' + j}
                          className={classes.tableCellCenter}
                        >
                          {item['reason_' + reason][source.value] || '0'}
                        </TableCell>
                      );
                    });
                    cells.push(
                      <TableCell
                        key={n + '-reason-' + i + 'source-total'}
                        className={classes.tableCellCenter}
                      >
                        {rowSum}
                      </TableCell>
                    );
                    return cells;
                  })}
                </TableLink>
              ))}
              <TableRow>
                <TableCell>{' '}</TableCell>
                <TableCell>Итого:</TableCell>
                {Object.keys(offerArchiveReasonsLabels).map(function (reason, i) {
                  let cells = offerSources.map((source, j) => (
                    <TableCell
                      key={'total-' + i + '-' + j}
                      className={classes.tableCellCenter}
                    >
                      {total[reason][source.value] || '0'}
                    </TableCell>
                  ));
                  cells.push(
                    <TableCell
                      key={'total-' + i + '-total'}
                      className={classes.tableCellCenter}
                    >
                      {sum[reason] || '0'}
                    </TableCell>
                  );
                  return cells;
                })}
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  table: {
    background: 'white',
  },
  root: {
    margin: '0 20px',
  },
  memberNameColumn: {
    maxWidth: 200,
  },
  tableRow: {
    height: 37,
  },
  tableCellCenter: {
    textAlign: 'center',
    borderLeft: '1px solid rgba(224, 224, 224, 1);',
    paddingRight: '12px !important',
  },
});

export default connect(
  state => ({
    offerExports: mapLabels(state.root.classification.offerExports, 'value', 'label'),
    dealSourceLabels: mapLabels(state.root.classification.dealSources, 'value', 'label'),
    offerSources: state.root.classification.offerSources,
    offerSourcesLabels: mapLabels(state.root.classification.offerSources, 'value', 'label'),
    offerArchiveReasonsLabels: mapLabels(state.root.classification.offerArchiveReasons, 'value', 'label'),
    selfUser: state.root.selfUser,
  })
)(withStyles(styles)(ArchivedOffersTable));
