import React, {Component} from 'react';
import {connect} from 'react-redux';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';

import {mapLabels} from '../../utils';

import ContactDetails from './ContactDetails';

class ContactDetailsWrapper extends Component {
  state = {
    menuAnchor: null,
  };

  render() {
    const {
      classes,
      onEdit,
      contact,
      contactTypeLabels,
    } = this.props;
    const {menuAnchor} = this.state;

    return (
      <Paper elevation={2} square className="column">
        <CardHeader
          action={
            <IconButton
              classes={{root: classes.icon}}
              onClick={({currentTarget}) => {
                this.setState({menuAnchor: currentTarget});
              }}
            >
              <icons.MoreVert/>
            </IconButton>
          }
          title={contact.name}
          subheader={contactTypeLabels[contact.type] || contact.type}
        />
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClick={() => {
            this.setState({menuAnchor: null});
          }}
        >
          <MenuItem onClick={() => onEdit()}>
            Редактировать
          </MenuItem>
        </Menu>
        <Divider/>
        <ContactDetails
          contact={contact}
        />
      </Paper>
    );
  }
}

const styles = theme => ({
  avatar: {
    background: colors.blue[300],
  },
  photos: theme.mixins.gutters({
    paddingTop: 8,
    paddingBottom: 8,
  }),
  headerPopoverPaper: {
    padding: 16,
    width: '100%',
  },
  newLines: {
    whiteSpace: 'pre-line',
  },
  actionIcon: {
    fontSize: 18,
    color: colors.grey[600],
    visibility: 'hidden',
    marginLeft: 'auto',  
  },
  actionItem: {
    cursor: 'pointer',
    '&:hover $actionIcon': {
      visibility: 'visible',
    },
  },

});

export default connect(
  state => ({
    contactTypeLabels: mapLabels(state.root.classification.contactTypes, 'value', 'label'),
    contactRoleLabels: mapLabels(state.root.classification.contactRoles, 'value', 'label'),
    contactScopeLabels: mapLabels(state.root.classification.contactScopes, 'value', 'label'),
    contactAttributeKindLabels: mapLabels(state.root.classification.contactAttributeKinds, 'value', 'label'),
  }),
)(withStyles(styles)(ContactDetailsWrapper));
