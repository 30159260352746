import axios from 'axios';
import {set} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {SubmissionError, reduxForm, formValueSelector} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';

import {withStyles} from '@material-ui/core/styles';

import Form from './form';
import AuthLog from './AuthLog';

class MemberEdit extends Component {
  componentDidMount() {
    if (this.props.member) {
      this.props.initialize({
        member: {
          ...this.props.member,
          commission: this.props.member.commission.toString() || this.props.team.avgCommissionMember.toString() || '43',
        },
      });
    } else {
      this.props.initialize({
        member: {
          commission: this.props.team.avgCommissionMember.toString() || '43',
          authenticatable: false,
          canPublishOffers: false,
        },
      });
    }
  }

  render() {
    const {
      array,
      change,
      classes,
      handleSubmit,
      initialized,
      open,
      pristine,
      submitting,
      onClose,
      member,
      city,
    } = this.props;

    if (!open || !initialized) {
      return null;
    }
    return (
      <Dialog fullScreen open={open} classes={{paper: classes.dialog}}>
        <DialogTitle>
          Сотрудник
          <IconButton className={classes.closeButton} onClick={() => onClose()}>
            <icons.Close/>
          </IconButton>
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <Form
            change={change}
            array={array}
            member={member}
            city={city}
          />
          <AuthLog member={member} size={10}/>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button type="button" disabled={submitting} onClick={() => onClose()}>
            Отменить
          </Button>
          <Button type="button" disabled={pristine || submitting} onClick={handleSubmit(this.submit)}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = values => {
    const {team, member, onSubmitted} = this.props;
    let url = '/api/members';

    if (member) {
      url = `/api/members/${member.id}`;
      values.id = member.id;
    } else if (team) {
      url = `/api/teams/${team.id}/members`;
      values.teamId = team.id;
    }

    return axios.post(url, values)
      .then(resp => onSubmitted({...values.member, id: resp.data.id}))
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(error.response.data.error.errors.reduce((errors, {propertyPath, message}) => {
            return set(errors, propertyPath, message);
          }, {}));
        } else if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else if (error.response.status === 503) {
          alert('Пользователь с таким email уже существует');
        } else if (error.response.status === 409 && error.response.data && error.response.data.error) {
          alert(error.response.data.error);
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

const styles = () => ({
  dialog: {
    maxWidth: 960,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});
const selector = formValueSelector('team_member');
MemberEdit = connect(state => ({
  city: selector(state, 'member.city'),
}))(MemberEdit);
export default reduxForm({
  form: 'team_member',
})(withStyles(styles)(MemberEdit));
