import React, {Component} from 'react';
import {connect} from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

class PropertyAttributes extends Component {
  render() {
    const {
      propertyType,
      propertyTypes,
    } = this.props;

    const values = propertyType.attributes ? propertyType.attributes[propertyType.value] : null;
    const type = propertyTypes.find(t => t.value === propertyType.value);
    if (!values || !type) {
      return null;
    }

    return (
      <React.Fragment>
        {type.attributes.map(({name, label, ...attribute}, index) => values[name] ? (
          <ListItem dense key={index}>
            <ListItemText primary={this.formatValue(attribute, values[name])} secondary={label}/>
          </ListItem>
        ) : null)}
      </React.Fragment>
    );
  }

  formatValue(attribute, value) {
    switch (attribute.type) {
      case 'text':
        return value;
      case 'option':
        return value ? 'Да' : 'Нет';
      case 'variant': {
        if (!attribute.variants) {
          return value;
        }
        const variant = attribute.variants.find(v => v.value === value);
        return variant ? variant.label : value;
      }
      default:
        break;
    }
    return null;
  }
}

export default connect(
  state => ({
    propertyTypes: state.root.classification.propertyTypes,
  })
)(PropertyAttributes);
