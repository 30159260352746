import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors/index';
import {Warning} from '@material-ui/icons';

function AlertIcon(props) {
  const {classes, color, message, withoutMargin} = props;
  return (
    <span title={message} >
      <Warning
        className={classes.icon} style={{
          marginRight: withoutMargin ? 0 : 8,
          color: color,
        }}/>
    </span>
  );
}

const styles = _theme => ({
  icon: {
    color: colors.orange[400],
    width: 16,
    height: 16,
    float: 'left',
    verticalAlign: 'middle',
  },
});

export default withStyles(styles)(AlertIcon);