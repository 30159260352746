import React, {Component} from 'react';
import {connect} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as icons from '@material-ui/icons';

import SetOwner from './form/SetOwner';
import ContactList from '../contacts/ContactList';
import ContactListItems from '../contacts/ContactListItems';
import {setOwnerForProperty} from '../../actions';

class PropertyOwner extends Component {
  state = {
    openItemMenu: null,
    currentItem: null,
    setOwner: null,
  };

  render() {
    const {match, setOwnerForProperty} = this.props;
    const {
      setOwner,
      currentItem,
      openItemMenu,
    } = this.state;

    return (
      <React.Fragment>
        <ContactList
          action={item => (
            <IconButton onClick={e => {
              this.setState({
                openItemMenu: e.currentTarget,
                currentItem: item,
              });
              e.stopPropagation();
            }}>
              <icons.MoreVert/>
            </IconButton>
          )}
        >
          <ContactListItems/>
        </ContactList>
        <Menu
          anchorEl={openItemMenu}
          open={Boolean(openItemMenu)}
          onClick={() => this.setState({openItemMenu: null})}
        >
          <MenuItem
            onClick={() => this.setState({
              setOwner: {
                propertyId: match.params.propertyId,
                owner: currentItem,
              },
            })}
          >
            Назначить собственником
          </MenuItem>
        </Menu>
        {setOwner ? (
          <SetOwner
            onClose={() => this.setState({setOwner: null})}
            onSubmitted={() => {
              setOwnerForProperty(setOwner.propertyId, setOwner.owner);
              this.setState({setOwner: null});
            }}
            open={Boolean(setOwner)}
            initialValues={setOwner}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default connect(null, {setOwnerForProperty})(PropertyOwner);
