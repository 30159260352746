import {withStyles} from '@material-ui/core';
import React, {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {formatDate, mapLabels} from '../../utils';

class OfferDealListItem extends Component {
  render() {
    const {
      classes,
      dealOffer,
      propertyTypeLabels,
      onSecondaryActionClick,
      dealOfferColors,
      dealOfferStatusLabels,
      color,
    } = this.props;

    let listItemProps = {};

    if (this.props.handleDialogOpen) {
      listItemProps = {
        onClick: e => {
          e.preventDefault();
          this.props.handleDialogOpen();
        },
      };
    }

    return (
      <ListItem
        dense
        button
        component={Link}
        to={`/deals/${dealOffer.deal.id}`}
        {...listItemProps}
      >
        <Avatar style={{backgroundColor: color ? color : colors.purple[300]}}>
          {color ? <icons.CardTravelOutlined/> : <icons.Inbox/>}
        </Avatar>
        {dealOffer ? (
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body1">
                {propertyTypeLabels[dealOffer.deal.propertyType] || dealOffer.deal.propertyType}
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography variant="caption" noWrap>
                  {formatDate(dealOffer.createdAt)}
                </Typography>
                <Typography variant="body2" noWrap>
                  <span style={{background: (dealOfferColors[dealOffer.status] || {100: colors.white})[100]}}>
                    {dealOfferStatusLabels[dealOffer.status] || dealOffer.status}
                  </span>
                  {dealOffer.incoming ? (
                    <span className={classes.incomingTag}>Входящее обращение</span>
                  ) : dealOffer.createdBy ? (
                    <span className={classes.createdByTag}>{dealOffer.createdBy.name}</span>
                  ) : null}
                </Typography>
              </React.Fragment>
            }
          />
        ) : null}
        {onSecondaryActionClick ? (
          <ListItemSecondaryAction>
            <IconButton onClick={onSecondaryActionClick}>
              <icons.MoreVert/>
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    );
  }
}

const styles = () => ({
  incomingTag: {
    color: colors.red[800],
    marginLeft: 8,
  },
  createdByTag: {
    color: colors.blue[800],
    padding: '0 4px',
    display: 'inline-block',
  },
});

OfferDealListItem = connect(
  state => ({
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    dealOfferColors: mapLabels(state.root.classification.dealOfferStatuses, 'value', 'color'),
    dealOfferStatusLabels: mapLabels(state.root.classification.dealOfferStatuses, 'value', 'label'),
  }),
)(OfferDealListItem);

export default withStyles(styles)(OfferDealListItem);
