const defaultState = {
  scrollPosition:0,
};

export default (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'DEAL_OFFER_LIST_SET_SCROLL_POSITION':
      return {
        ...state,
        scrollPosition: payload.position,
      };
    default:
      return state;
  }
};
