const defaultState = {
  pressRelease: {
    status: {
      isLoad: false,
      totalCount: 0,
    },
  },
};

const pressRelease = (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'LOAD_PRESS_RELEASE_CLOSE_DEAL_LIST':
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease,
          deal: {
            ...payload.pressReleaseList.items,
          },
          status: {
            isLoad: false,
            totalCount: payload.pressReleaseList.totalCount,
          },
        },
      };
    case 'LOAD_PRESS_RELEASE_EVENT_LIST':
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease,
          event: {
            ...payload.pressReleaseList.items,
          },
          status: {
            isLoad: false,
            totalCount: payload.pressReleaseList.totalCount,
          },
        },
      };
    case 'LOAD_PRESS_RELEASE_EVENT_DETAILS':
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease.pressRelease,
          event: {
            ...state.event,
            [payload.item.id]: {
              ...payload.item,
            },
          },
          status: {
            isLoad: false,
          },
        },
      };
    case 'UPDATE_PRESS_RELEASE_CLOSE_DEAL':
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease,
          deal: {
            ...state.pressRelease.deal,
            [payload.pressRelease.id]: {
              ...state.pressRelease.deal[payload.pressRelease.id],
              ...payload.pressRelease,
            },
          },
          status: {
            isLoad: false,
          },
        },
      };
    case 'UPDATE_PRESS_RELEASE_EVENT':
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease,
          event: {
            ...state.pressRelease.event,
            [payload.pressRelease.id]: {
              ...state.pressRelease.event[payload.pressRelease.id],
              ...payload.pressRelease,
            },
          },
          status: {
            isLoad: false,
          },
        },
      };
    case 'LOAD_PRESS_RELEASE_CLOSE_DEAL_DETAILS':
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease,
          deal: {
            ...state.pressRelease.deal,
            [payload.item.id]: {
              ...payload.item,
            },
          },
          status: {
            isLoad: false,
          },
        },
      };
    case 'LOAD_PRESS_STARTED':
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease,
          status: {
            isLoad: true,
          },
        },
      };
    case 'PRESS_RELEASE_CLOSE_DEAL_SET_COMMENT': {
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease,
          deal: {
            ...state.pressRelease.deal,
            [payload.id]: {
              ...state.pressRelease.deal[payload.id],
              comment: payload.comment,
            },
          },
        },
      };
    }
    case 'PRESS_RELEASE_EVENT_SET_COMMENT': {
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease,
          event: {
            ...state.pressRelease.event,
            [payload.id]: {
              ...state.pressRelease.event[payload.id],
              comment: payload.comment,
            },
          },
        },
      };
    }
    case 'PRESS_RELEASE_EVENT_SET_PUBLISHED': {
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease,
          event: {
            ...state.pressRelease.event,
            [payload.id]: {
              ...state.pressRelease.event[payload.id],
              published: payload.published,
            },
          },
        },
      };
    }
    case 'PRESS_RELEASE_CLOSE_DEAL_SET_PUBLISHED': {
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease,
          deal: {
            ...state.pressRelease.deal,
            [payload.id]: {
              ...state.pressRelease.deal[payload.id],
              published: payload.published,
            },
          },
        },
      };
    }
    case 'PRESS_RELEASE_EVENT_SET_ARCHIVED': {
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease,
          event: {
            ...state.pressRelease.event,
            [payload.id]: {
              ...state.pressRelease.event[payload.id],
              archived: payload.archived,
            },
          },
        },
      };
    }
    case 'PRESS_RELEASE_CLOSE_DEAL_SET_ARCHIVED': {
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease,
          deal: {
            ...state.pressRelease.deal,
            [payload.id]: {
              ...state.pressRelease.deal[payload.id],
              archived: payload.archived,
            },
          },
        },
      };
    }
    case 'PRESS_RELEASE_ADD_ASSIGNEE': {
      const assignees = state.pressRelease.deal[payload.id].assignees || [];
      if (assignees.indexOf(payload.assignee) >= 0) {
        return state;
      }
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease,
          deal:{
            ...state.pressRelease.deal,
            [payload.id]: {
              ...state.pressRelease.deal[payload.id],
              assignees: [
                ...assignees,
                payload.assignee,
              ],
            },
          },
        },
      };
    }

    case 'PRESS_RELEASE_DELETE_ASSIGNEE': {
      const assignees = state.pressRelease.deal[payload.id].assignees || [];
      return {
        ...state,
        pressRelease: {
          ...state.pressRelease,
          deal: {
            ...state.pressRelease.deal,
            [payload.id]: {
              ...state.pressRelease.deal[payload.id],
              assignees: assignees.filter(assignee => assignee.id !== payload.assignee),
            },
          },
        },
      };
    }
    default:
      return state;
  }
};

export default pressRelease;
