import {set} from 'lodash';
import axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {SubmissionError, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';

import UpdateForm from './form';

class UpdateEdit extends Component {
  state = {
    contact: null,
    createContact: null,
    assignees: [],
    properties: [],
    contactAssignees: [],
  };

  componentDidMount() {
    const {initialize, item, id} = this.props;
    if (id) {
      initialize({...item});
    }else{
      initialize({
        nextMajor: false,
      });
    }
  }

  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      submitting,
      handleClose,
      id,
      change,
    } = this.props;

    return (
      <Dialog fullScreen open classes={{paper: classes.dialog}}>
        <DialogTitle>Обновление</DialogTitle>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <icons.Close />
        </IconButton>
        <Divider />
        <DialogContent>
          <UpdateForm
            id={id}
            change={change}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            type="button"
            disabled={submitting}
            onClick={handleClose}
          >
              Отменить
          </Button>
          <Button
            type="button"
            disabled={pristine || submitting}
            onClick={handleSubmit(this.submit)}
          >
              Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = data => {
    const {onSubmitted, id} = this.props;
    let url = '/api/v1/createSystemUpdate';
    if (id) {
      url = '/api/v1/editSystemUpdate';
    }

    return axios
      .post(url, {id, ...data})
      .then(resp => {
        onSubmitted({...data, id: resp.data.id});
      })
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(
            error.response.data.error.errors.reduce(
              (errors, {propertyPath, message}) => {
                return set(errors, propertyPath, message);
              },
              {}
            )
          );
        } else if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

const styles = () => ({
  dialog: {
    maxWidth: 960,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

UpdateEdit = withStyles(styles)(UpdateEdit);

UpdateEdit = connect(() => ({}),
  {})(UpdateEdit);

export default reduxForm({
  form: 'updateForm',
})(UpdateEdit);
