import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';

import MemberEdit from './MemberEdit';
import {addTeamMember, loadTeamMemberList, updateTeamMember} from '../../actions';
import {mapLabels, formatDate} from '../../utils';

class MemberList extends Component {
  state = {
    openAdd: false,
    openEdit: null,
  };

  componentDidMount() {
    if (this.props.selfUserTeams.length > 0) {
      this.initialize(this.props.location, this.props.teamId, this.props.showAuth);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.selfUserTeams.length > 0 &&
      ((this.props.selfUserTeams.length < 1 || newProps.teamId !== this.props.teamId))
    ) {
      this.initialize(newProps.location, newProps.teamId, newProps.showAuth);
    }
  }

  initialize(location, teamId, showAuth) {
    let filter = showAuth ? {all: true} : null;
    this.props.loadTeamMemberList(location.key, teamId, filter);
  }

  render() {
    const {
      action,
      addTeamMember,
      classes,
      members,
      memberList,
      location,
      teamId,
      updateTeamMember,
      userRoleLabels,
      selectedItems,
      showAuth,
    } = this.props;

    if (!memberList) {
      return null;
    }

    const {openAdd, openEdit} = this.state;
    const teams = this.props.selfUserTeams.filter(team => team.id === teamId);

    return (
      <React.Fragment>
        <div className="row" key={0}>
          <div className="column" style={{background: colors.grey[200], flex: 2}}>
            <Toolbar className="toolbar">
              <Typography variant="subtitle1">{teams.length > 0 ? teams[0].name : null}</Typography>
              <div className={classes.flex}/>
              <IconButton
                className={classes.rightIcon}
                disabled={memberList.loading}
                onClick={this.handleReload}
              >
                <icons.Refresh/>
              </IconButton>
            </Toolbar>
            <Divider/>
            <div className="content">
              {memberList.error ? (
                <icons.ErrorOutline className={classes.progress}/>
              ) : null}
              {memberList.loading ? (
                <CircularProgress size={50} thickness={4} className={classes.progress}/>
              ) : null}
              {memberList.items ? (
                <React.Fragment>
                  <Hidden xsDown>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Имя</TableCell>
                          <TableCell>Адрес эл. почты</TableCell>
                          <TableCell>Телефон</TableCell>
                          {showAuth ? <TableCell>Есть доступ</TableCell> : null}
                          <TableCell>Роль</TableCell>
                          {showAuth ? <TableCell>Последняя авторизация</TableCell> : null}
                          <TableCell/>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {memberList.items.map(id => {
                          const member = members[id];
                          const selected = selectedItems && selectedItems.indexOf(member.id) >= 0;
                          return (
                            <TableRow
                              key={id}
                              hover
                              selected={selected}
                            >
                              <TableCell>{member.name}</TableCell>
                              <TableCell>{member.email}</TableCell>
                              <TableCell>{member.phone}</TableCell>
                              {showAuth ? <TableCell>{member.authenticatable ? 'Да' : 'Нет'}</TableCell> : null}
                              <TableCell>{userRoleLabels[member.role] || member.role}</TableCell>
                              {showAuth ? <TableCell>{formatDate(member.lastAuth)}</TableCell> : null}
                              <TableCell style={{paddingRight: action ? 56 : 24, textAlign: 'right'}}>
                                <IconButton onClick={() => this.setState({openEdit: id})}>
                                  <icons.Edit/>
                                </IconButton>
                                {action ? (
                                  <div className={classes.action}>
                                    {action(member, selected)}
                                  </div>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Hidden>
                  <Hidden smUp>
                    {memberList.items.map(id => {
                      const member = members[id];
                      const selected = selectedItems && selectedItems.indexOf(member.id) >= 0;
                      return (
                        <Paper
                          key={id}
                          className={classes.card}
                          elevation={0}
                          square
                        >
                          <CardContent style={{position: 'relative'}}>
                            <Typography variant="subtitle1">{member.name}</Typography>
                            <Typography variant="caption">{member.email}</Typography>
                            {action ? (
                              <div className={classes.action}>
                                {action(member, selected)}
                              </div>
                            ) : null}
                          </CardContent>
                          <Divider/>
                        </Paper>
                      );
                    })}
                  </Hidden>
                </React.Fragment>
              ) : null}
            </div>
            <Fab
              color="secondary"
              className={classes.fab}
              onClick={() => this.setState({openAdd: true})}
            >
              <icons.Add/>
            </Fab>
          </div>
        </div>
        {openAdd && teams.length > 0 ? (
          <MemberEdit
            open={openAdd}
            team={teams[0]}
            onSubmitted={member => {
              addTeamMember(location.key, member);
              this.setState({openAdd: false});
            }}
            onClose={() => this.setState({openAdd: false})}
          />
        ) : null}
        {openEdit ? (
          <MemberEdit
            open={Boolean(openEdit)}
            team={teams[0]}
            member={members[openEdit]}
            onSubmitted={member => {
              updateTeamMember(member);
              this.setState({openEdit: null});
            }}
            onClose={() => this.setState({openEdit: null})}
          />
        ) : null}
      </React.Fragment>
    );
  }

  handleReload = () => {
    const {loadTeamMemberList, location, teamId, showAuth} = this.props;
    let filter = showAuth ? {all: true} : null;

    loadTeamMemberList(location.key, teamId, filter, true);
  };
}

MemberList = connect(
  (state, ownProps) => ({
    memberList: state.root.team.screens[ownProps.location.key],
    members: state.root.team.members,
    selfUserTeams: state.root.selfUser.teams,
    userRoleLabels: mapLabels(state.root.classification.userRoles, 'value', 'label'),
  }),
  {
    addTeamMember,
    loadTeamMemberList,
    updateTeamMember,
  }
)(MemberList);

const styles = theme => ({
  flex: {
    flex: 1,
  },
  table: {
    background: 'white',
    marginBottom: 56 + 48,
  },
  rightIcon: {
    marginRight: -12,
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
  action: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 8,
    },
    top: 0,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
});

export default withRouter(withStyles(styles)(MemberList));
