import React, {Component} from 'react';
import {connect} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as icons from '@material-ui/icons';

import {queryDealList} from '../../queries';
import base64url from '../../utils/base64url';
import AddDeal from './form/AddDeal';
import DealList from '../deals/DealList';
import DealListItems from '../deals/DealListItems';
import {queryOfferDealOffers} from '../../queries';

class OfferDeals extends Component {
  state = {
    openItemMenu: null,
    currentItem: null,
    addDeal: null,
    selectedItems: [],
    loading: false,
    error: false,
    totalCount: 0,
    query: {},
    deals: [],
  };

  componentDidMount() {
    this.search(this.props.location);
    this.load();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.search(nextProps.location);
    }
  }

  search(location) {
    let query = {};
    const qs = location.search.substr(1);
    if (qs.length > 0) {
      try {
        query = JSON.parse(base64url.decode(qs));
      } catch (_) {
      }
    }
    this.setState({loading: true, query});
    queryDealList(query)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            loading: false,
            error: false,
            deals: resp.data.data.deals.items,
            totalCount: resp.data.data.deals.totalCount,
          });
        } else {
          this.setState({loading: false, error: true});
        }
      })
      .catch(() => {
        this.setState({loading: false, error: true});
      });
  }

  load() {
    const {match} = this.props;
    queryOfferDealOffers(match.params.offerId)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            selectedItems: resp.data.data.offerDealOffers.map(dealOffer => dealOffer.deal.id),
          });
          return;
        }
        alert('Произошла ошибка!');
      })
      .catch(() => {
        alert('Произошла ошибка!');
      });
  }

  render() {
    const {history, location, match} = this.props;
    const {
      addDeal,
      currentItem,
      openItemMenu,
      selectedItems,
      query,
      deals,
      totalCount,
      loading,
      error,
    } = this.state;

    return (
      <React.Fragment>
        <DealList
          selectedItems={selectedItems}
          query={query}
          deals={deals}
          history={history}
          match={match}
          location={location}
          totalCount={totalCount}
          loading={loading}
          error={error}
          perPage={this.props.dealListSettings.page || 30}
          action={(item, selected) => (
            <IconButton
              disabled={selected}
              onClick={e => {
                this.setState({
                  openItemMenu: e.currentTarget,
                  currentItem: item,
                });
                e.stopPropagation();
              }}
            >
              <icons.MoreVert/>
            </IconButton>
          )}
        >
          <DealListItems/>
        </DealList>
        <Menu
          anchorEl={openItemMenu}
          open={Boolean(openItemMenu)}
          onClick={() => this.setState({openItemMenu: null})}
        >
          <MenuItem
            onClick={() => this.setState({
              addDeal: {
                status: 'new',
                dealId: currentItem.id,
                offerId: match.params.offerId,
              },
            })}
          >
            Добавить заявку
          </MenuItem>
        </Menu>
        {addDeal ? (
          <AddDeal
            onClose={() => this.setState({addDeal: null})}
            onSubmitted={() => {
              const {addDeal} = this.state;
              this.setState({
                addDeal: null,
                selectedItems: [
                  ...selectedItems,
                  addDeal.dealId,
                ],
              });
            }}
            open={Boolean(addDeal)}
            initialValues={addDeal}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  dealListSettings: state.root.settings.dealList,
}),)(OfferDeals);
