import {omit} from 'lodash';

const defaultState = {
  screens: {},
  properties: {},
};

const property = (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'SCREEN_REMOVE': {
      if (!(payload.key in state.screens)) {
        return state;
      }

      return {
        ...state,
        screens: omit(state.screens, payload.key),
      };
    }

    case 'PROPERTY_LIST_LOAD_STARTED': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            loading: true,
            filter: payload.filter,
          },
        },
      };
    }

    case 'PROPERTY_LIST_LOAD_COMPLETED': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            filter: state.screens[payload.key].filter,
            items: payload.items.map(property => property.id),
            page: payload.page,
            totalCount: payload.totalCount,
          },
        },
        properties: {
          ...state.properties,
          ...payload.items.reduce((properties, property) => ({...properties, [property.id]: property}), {}),
        },
      };
    }

    case 'PROPERTY_LIST_LOAD_FAILED': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            filter: state.screens[payload.key].filter,
            error: true,
          },
        },
      };
    }

    case 'PROPERTY_LIST_SET_SCROLL_POSITION': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            ...state.screens[payload.key],
            scrollPosition: payload.position,
          },
        },
      };
    }

    case 'PROPERTY_LOAD_STARTED': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {loading: true},
        },
      };
    }

    case 'PROPERTY_LOAD_COMPLETED': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {item: payload.property.id},
        },
        properties: {
          ...state.properties,
          [payload.property.id]: payload.property,
        },
      };
    }

    case 'PROPERTY_LOAD_FAILED': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {error: true},
        },
      };
    }

    case 'PROPERTY_ADD_OFFER': {
      const offers = state.properties[payload.id].offers || [];

      return {
        ...state,
        properties: {
          ...state.properties,
          [payload.id]: {
            ...state.properties[payload.id],
            offers: [
              ...offers,
              payload.offer,
            ],
          },
        },
      };
    }

    case 'PROPERTY_ADD': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            ...state.screens[payload.key],
            items: [
              payload.property.id,
              ...state.screens[payload.key].items,
            ],
            totalCount: state.screens[payload.key].totalCount + 1,
          },
        },
        properties: {
          ...state.properties,
          [payload.property.id]: payload.property,
        },
      };
    }

    case 'PROPERTY_UPDATE': {
      return {
        ...state,
        properties: {
          ...state.properties,
          [payload.property.id]: payload.property,
        },
      };
    }

    case 'PROPERTY_ADD_ASSIGNEE': {
      if (!state.properties[payload.id]) {
        return state;
      }

      const assignees = state.properties[payload.id].assignees || [];
      if (assignees.indexOf(payload.assignee) >= 0) {
        return state;
      }

      return {
        ...state,
        properties: {
          ...state.properties,
          [payload.id]: {
            ...state.properties[payload.id],
            assignees: [
              ...assignees,
              payload.assignee,
            ],
          },
        },
      };
    }

    case 'PROPERTY_DELETE_ASSIGNEE': {
      const assignees = state.properties[payload.id].assignees || [];

      return {
        ...state,
        properties: {
          ...state.properties,
          [payload.id]: {
            ...state.properties[payload.id],
            assignees: assignees.filter(assignee => assignee.id !== payload.assignee),
          },
        },
      };
    }

    case 'PROPERTY_ADD_DOCUMENT': {
      const documents = state.properties[payload.id].documents || [];

      return {
        ...state,
        properties: {
          ...state.properties,
          [payload.id]: {
            ...state.properties[payload.id],
            documents: [
              ...documents,
              payload.document,
            ],
          },
        },
      };
    }

    case 'PROPERTY_REMOVE_DOCUMENT': {
      const documents = state.properties[payload.id].documents || [];

      return {
        ...state,
        properties: {
          ...state.properties,
          [payload.id]: {
            ...state.properties[payload.id],
            documents: documents.filter(({path}) => path !== payload.documentPath),
          },
        },
      };
    }

    case 'PROPERTY_SET_OWNER': {
      return {
        ...state,
        properties: {
          ...state.properties,
          [payload.id]: {
            ...state.properties[payload.id],
            owner: {
              id: payload.owner.id,
              name: payload.owner.name,
              phone: payload.owner.phone,
            },
          },
        },
      };
    }
    case 'PROPERTY_SET_STATUS': {
      return {
        ...state,
        properties: {
          ...state.properties,
          [payload.id]: {
            ...state.properties[payload.id],
            status: payload.status,
          },
        },
      };
    }

    case 'PROPERTY_UNSET_OWNER': {
      return {
        ...state,
        properties: {
          ...state.properties,
          [payload.id]: {
            ...state.properties[payload.id],
            owner: {},
          },
        },
      };
    }

    case 'PROPERTY_SET_COMMENT': {
      return {
        ...state,
        properties: {
          ...state.properties,
          [payload.id]: {
            ...state.properties[payload.id],
            comment: payload.comment,
          },
        },
      };
    }
    case 'UNSET_OFFER': {
      let propertyId = payload.offer.origin;
      let offerId = payload.offer.id;
      let property = state.properties[propertyId];
      if(property) {
        let unsetOffer = state.properties[propertyId].offers.findIndex(offer => offer.id === offerId);
        state.properties[propertyId].offers.splice(unsetOffer, 1);
      }
      return {
        ...state,
      };
    }
    case 'PROPERTY_OFFER_UPDATE': {
      const {offerId, propertyId, status} = payload;
      let property = state.properties[propertyId];
      if(property)
        property.offers.find(offer => offer.id === offerId).status = status;
      return {
        ...state,
      };
    }
    default:
      return state;
  }

};

export default property;
