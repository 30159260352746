import React from 'react';
import Grid from '@material-ui/core/Grid';
import ContainerDimensions from 'react-container-dimensions';
import BarChart from './BarChart';
import DataTable from './DataTable';

const StatisticType = props => {
  const {
    statistics,
    barchartTitle,
    tableTitle,
    history,
    match,
    total,
    handleClickOpen,
    height,
    color,
    getHref,
  } = props;

  return (
    <Grid container>
      <Grid item lg={7} xs={12}>
        <ContainerDimensions>
          {({width}) =>
            <BarChart
              width={width}
              variant="horizontal"
              statistics={statistics}
              title={barchartTitle}
              height={height || null}
              color={color}
            />
          }
        </ContainerDimensions>
      </Grid>
      <Grid item lg={5} xs={12}>
        <DataTable
          statistics={statistics}
          title={tableTitle}
          total={total}
          history={history}
          match={match}
          getHref = {getHref}
          handleClickOpen={handleClickOpen}
        />
      </Grid>
    </Grid>
  );
};

export default StatisticType;
