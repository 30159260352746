import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Icon from '@material-ui/core/Icon';
import {formatDateWithoutTime} from '../../utils';

function RequestFeedbackStatus(props) {
  const {classes, onEditStatus, status, labels, value, waitingDeadline} = props;

  const color = getColor(status);
  const iconName = getIcon(status);

  function getIcon(status) {
    switch(status){
      case 'yes': return 'check_circle_outlined';
      case 'wait': return 'event_available_outlined';
      case 'no': return 'warning';
      case 'viewed': return 'visibility_outlined';

      default: return null;
    }
  }
  function getColor(status){
    switch(status){
      case 'yes': return colors.blue[700];
      case 'wait': return colors.yellow[700];
      case 'no': return colors.red[700];
      case 'viewed': return colors.green[700];
      default: return null;
    }
  }

  function getLabel(status){
    if (status === 'wait' && waitingDeadline) {
      return labels[status] + ' ' + formatDateWithoutTime(waitingDeadline);
    }

    return labels[status];
  }

  if (!value) {
    return null;
  }

  const label = getLabel(status);

  return (
    <SnackbarContent
      className={classes.warning} style={{backgroundColor: color}}
      elevation={0}
      message={
        <span className={color !== 'white' ? classes.message: classes.messageReverse}>
          <Icon className={classes.icon}>{iconName}</Icon>
          {label}
          <Icon
            className={classes.iconEdit}
            onClick={onEditStatus}
          >edit</Icon>
        </span>
      }
    />
  );
}

const styles = theme => ({
  warning: {
    backgroundColor: colors.amber[600],
    marginBottom: theme.spacing.unit,
    padding: '4px 16px',
    maxWidth: 'unset',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
  },
  messageReverse: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    color: 'black',
  },
  icon: {
    opacity: 0.9,
    marginRight: theme.spacing.unit * 2,
    fontSize: 22,
  },
  iconEdit: {
    fontSize: 18,
    padding: 10,
    cursor: 'pointer',
    position: 'absolute',
    right: 20,
  },
});

export default withStyles(styles)(RequestFeedbackStatus);
