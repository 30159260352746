const defaultState = {
  city: null,
  district: null,
};

export default (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'SET_CITY':
      return {
        ...state,
        city: payload.city,
        district: null,
      };
    case 'SET_DISTRICT':
      return {
        ...state,
        district: payload.district,
      };
    default:
      return state;
  }
};
