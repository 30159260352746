import {isObject} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, FormSection} from 'redux-form';
import {Checkbox} from 'redux-form-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';

import Assignees from '../../../components/relations/Assignees';
import OfferSearch from './OfferSearch';
import ContactField from '../../contacts/form/ContactField';
import DatePicker from '../../../components/Input/DatePicker';
import TextField from '../../../components/Input/TextField';
import Variant from '../../../components/Input/Variant';
import {getLabelsForValue} from '../../../utils';

class Form extends Component {
  render() {
    const {
      array: {push, remove},
      assignees,
      change,
      classes,
      contact,
      dealSources,
      dealStatuses,
      id,
      offerType,
      offers,
      onAssigneeSelect,
      onAssigneeUnselect,
      onContactCreate,
      onContactUnassign,
      onContactSelect,
      onOfferSelect,
      onOfferUnselect,
      priceTypes,
      propertyAddresses,
      propertyPurposes,
      propertyTypes,
      team,
      status,
      onTypeChange,
      dealClosesReason,
      dealClosesStatuses,
      classificationDeal,
    } = this.props;

    return (
      <div style={{marginTop: 24}}>
        <FormSection name="deal">
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  justifyContent: 'end',
                }}
              >
                <Field
                  name="status"
                  label="Статус"
                  required
                  component={TextField}
                  select
                  fullWidth
                  margin="none"
                >
                  {dealStatuses.map(({value, label}, i) => (
                    <MenuItem key={i} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Field>
                <Tooltip
                  enterTouchDelay={0}
                  title={
                    <div>
                      <ul style={{padding: '0 10px'}}>
                        <li>Новая — принят входящий звонок, получена первичная информация.</li>
                        <li>Уточнен запрос — клиент принят в работу консультантом.</li>
                        <li>Предложены объекты — отправлена подборка листингов клиенту.</li>
                        <li>Переговоры — клиент обсуждает сделку по одному из вариантов.</li>
                        <li>Закрыта нами — сделка закрыта нами.</li>
                        <li>Закрыта не нами — снял/купил через третьих лиц или сам.</li>
                        <li>Приостановлена — на паузе до указанной даты.</li>
                        <li>Нет вариантов — в базе АРР нет вариантов.</li>
                        <li>Не качественная — отказ от работы клиентом или ошибка.</li>
                      </ul>
                      <p>Указывайте причину изменения статуса в комментарии.</p>
                    </div>
                  }
                >
                  <icons.Help className={classes.help} />
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={12} style={{display: 'flex', alignItems: 'end'}}>
              <Field
                name="abcClass"
                label="Класс"
                component={TextField}
                select
                required
                fullWidth
                margin="none"
              >
                {classificationDeal.map((item, index) => (
                  <MenuItem value={item.value} key={index}>{item.selectLabel}</MenuItem>
                ))}
              </Field>
              <Tooltip
                enterTouchDelay={0}
                title={
                  <ul style={{padding: '0 10px'}}>
                    {classificationDeal.map((item, index) => (
                      <li key={index}>{item.label}</li>
                    ))}
                  </ul>
                }
              >
                <icons.Help className={classes.help} />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="none">
                <FormControlLabel
                  control={<Field name="multiple" component={Checkbox} />}
                  label="Клиенту требуется несколько объектов"
                  required
                />
              </FormControl>
              {!id ? (
                <FormControl margin="none">
                  <FormControlLabel
                    control={<Field name="free" component={Checkbox} />}
                    label="Свободная заявка"
                    required
                  />
                </FormControl>
              ) : null}
            </Grid>
            {id && dealClosesStatuses.includes(status) ? (
              <>
              {status === 'closed' ? (
                <Grid item xs={12}>
                  <Field
                    name="closeReason"
                    label="Причина"
                    required
                    select
                    component={TextField}
                    fullWidth
                    margin="none"
                  >
                    {Object.keys(dealClosesReason).map((key, index) =>
                      (
                        <MenuItem value={key} key={index}>{dealClosesReason[key]}</MenuItem>
                      )
                    )}
                  </Field>
                </Grid>
              ): null}
                <Grid item xs={12}>
                  <Field
                    name="closeComment"
                    label="Комментарий"
                    required
                    multiline
                    component={TextField}
                    fullWidth
                    margin="none"
                  />
                </Grid>
              </>
            ): null}
            {!id ? (
              <>
                <ContactField
                  contact={contact}
                  onContactCreate={onContactCreate}
                  onContactUnassign={onContactUnassign}
                  onContactSelect={onContactSelect}
                />
                <Field
                  name="contact"
                  component={({meta}) => {
                    if (meta.error) {
                      return (
                        <FormHelperText error style={{paddingLeft: 12}}>
                          {meta.error}
                        </FormHelperText>
                      );
                    }
                    return null;
                  }}
                />
              </>
            ) : null}
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Адреса <sup>*</sup>
              </Typography>
              <Field
                name="propertyAddresses"
                component={({meta}) => {
                  if (meta.error && !isObject(meta.error)) {
                    return <FormHelperText error>{meta.error}</FormHelperText>;
                  }
                  return null;
                }}
                margin="none"
              />
            </Grid>
            <OfferSearch
              id={id}
              addresses={propertyAddresses}
              offers={offers}
              change={(name, value) => change(`deal.${name}`, value)}
              array={{
                push: (name, value) => push(`deal.${name}`, value),
                remove: (name, index) => remove(`deal.${name}`, index),
              }}
              onSelect={onOfferSelect}
              onUnselect={onOfferUnselect}
            />
            {/*<Grid item xs={12}>
                <Address
                  address={propertyAddress}
                  change={(name, value) => change(`deal.${name}`, value)}
                  editable
                  fieldName="propertyAddress"
                />
              </Grid>*/}
            {!id ? (
              <Grid item xs={12}>
                <FormControl margin="none">
                  <FormControlLabel
                    control={<Field name="selfAssignee" component={Checkbox} />}
                    label="Я ответственный"
                    required
                  />
                </FormControl>
                {offers && offers.length > 0 ? (
                  <FormControl margin="none">
                    <FormControlLabel
                      control={
                        <Field name="offerAssignees" component={Checkbox} />
                      }
                      label="Ответственные за листингом"
                      required
                    />
                  </FormControl>
                ) : null}
              </Grid>
            ) : null}
            {!id ? (
              <Assignees
                team={team}
                assignees={assignees}
                onSelect={onAssigneeSelect}
                onUnselect={onAssigneeUnselect}
              />
            ) : null}
            <Grid item xs={12}>
              <Field
                name="source"
                label="Источник"
                required
                component={TextField}
                select
                fullWidth
                margin="none"
              >
                <MenuItem value={null} />
                {dealSources.map(({value, label}, i) => (
                  <MenuItem key={i} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="offerType"
                label="Вид сделки"
                required
                component={TextField}
                select
                fullWidth
                margin="none"
                onChange={e => {
                  onTypeChange(e.target.value);
                }}
              >
                <MenuItem value="sell">Продажа</MenuItem>
                <MenuItem value="rent">Аренда</MenuItem>
              </Field>
            </Grid>
            {offerType ? (
              <React.Fragment>
                <Grid item xs={4}>
                  <Field
                    name="offerPrice.value.amount"
                    label="Желаемая цена / бюджет"
                    component={TextField}
                    required
                    fullWidth
                    margin="none"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="offerPrice.value.currency"
                    label="Валюта"
                    component={TextField}
                    select
                    fullWidth
                    margin="none"
                  >
                    <MenuItem value="RUB">₽</MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name="offerPrice.type"
                    label="Тип"
                    component={TextField}
                    select
                    fullWidth
                    margin="none"
                  >
                    {priceTypes
                      .filter(type => offerType === type.offerType)
                      .map(({value, label}, i) => (
                        <MenuItem value={value} key={i}>
                          {label}
                        </MenuItem>
                      ))}
                  </Field>
                </Grid>
              </React.Fragment>
            ) : null}
            <Grid item xs={12}>
              <Field
                name="propertyType"
                label="Категория недвижимости"
                required
                component={TextField}
                select
                fullWidth
                margin="none"
              >
                {propertyTypes.map(({value, label}, i) => (
                  <MenuItem key={i} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="propertyAdditionalTypes"
                label="Дополнительные категории"
                component={TextField}
                select
                SelectProps={{
                  multiple: true,
                  renderValue: value =>
                    getLabelsForValue(propertyTypes, value).join(', '),
                }}
                format={value => value || []}
                fullWidth
                margin="none"
              >
                {propertyTypes.map(({label, value}, i) => (
                  <Variant key={i} value={value} label={label} />
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="propertyPurpose"
                label="Назначение"
                required
                component={TextField}
                select
                fullWidth
                margin="none"
              >
                <MenuItem value="" />
                {propertyPurposes.map(({label, value}, i) => (
                  <MenuItem key={i} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="propertyArea.from"
                label="Площадь от"
                required
                component={TextField}
                normalize={value => (value ? value.replace(',', '.') : value)}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="propertyArea.to"
                label="до"
                required
                component={TextField}
                normalize={value => (value ? value.replace(',', '.') : value)}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="propertyArea.unit"
                label="Ед. изм."
                component={TextField}
                select
                fullWidth
                margin="none"
              >
                <MenuItem value="m2">м²</MenuItem>
                <MenuItem value="a">ар (сотка)</MenuItem>
                <MenuItem value="ha">гектар</MenuItem>
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="requirements"
                label="Дополнительные требования"
                component={TextField}
                multiline
                rowsMax={15}
                fullWidth
                margin="none"
              />
              <FormHelperText>
                Конкретизируйте назначение и укажите дополнительные требования
                (открытая информация)
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="deadline"
                label="Крайний срок принятия решения"
                format={null}
                component={props => (
                  <DatePicker format="yyyy-MM-dd" clearable {...props} />
                )}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="comment"
                label="Комментарий"
                component={TextField}
                multiline
                rowsMax={15}
                fullWidth
                margin="none"
              />
            </Grid>
          </Grid>
        </FormSection>
      </div>
    );
  }
}

const styles = {
  help: {
    color: colors.grey[700],
    marginLeft: 12,
  },
};

export default connect(({root}) => ({
  dealSources: root.classification.dealSources,
  dealStatuses: root.classification.dealStatuses,
  priceTypes: root.classification.priceTypes,
  propertyPurposes: root.classification.propertyPurposes,
  propertyTypes: root.classification.propertyTypes,
  dealClosesReason: root.classification.dealClosesReason,
  dealClosesStatuses: root.classification.dealClosesStatuses,
  classificationDeal: root.classification.classificationDeal,
}))(withStyles(styles)(Form));
