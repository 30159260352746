import React, {Component} from 'react';
import {connect} from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import {
  openLightbox,
} from '../../actions';
import {
  mapLabels,
  formatDate,
  ImageFilters,
  getImageUrl,
} from '../../utils';

class NetRenterDetails extends Component {

  onStatusChange = status => {
    this.props.onStatusChange(status);
  };

  render() {
    const {
      deal,
      classes,
      openLightbox,
      activitiesLabels,
    } = this.props;

    return (
      <div className="content" style={{background: 'white'}}>
        <List>
          {deal.type && activitiesLabels[deal.type] ? (
            <ListItem dense>
              <ListItemText primary={activitiesLabels[deal.type]} secondary="Вид деятельности"/>
            </ListItem>
          ) : null}
          {deal.areaFrom ? (
            <ListItem dense>
              <ListItemText primary={deal.areaFrom + ' м²'} secondary="Площадь от"/>
            </ListItem>
          ) : null}
          {deal.areaTo ? (
            <ListItem dense>
              <ListItemText primary={deal.areaTo + ' м²'} secondary="Площадь до"/>
            </ListItem>
          ) : null}
          <ListItem dense>
            <ListItemText primary={formatDate(deal.createdAt)} secondary="Создан"/>
          </ListItem>
          {deal.updatedAt !== deal.createdAt ? (
            <ListItem dense>
              <ListItemText primary={formatDate(deal.updatedAt)} secondary="Изменен"/>
            </ListItem>
          ) : null}
          {deal.logo ? (
            <div className={classes.photos}>
              <Typography variant="caption" gutterBottom>Логотип</Typography>
              <GridList className={classes.photosWrapper} cellHeight={200} spacing={4}>
                <GridListTile className={classes.photoWrapper} key={0} onClick={() => openLightbox([deal.logo], 0)}>
                  <img src={getImageUrl({filter: ImageFilters.resize, width: 800, height: 600, path: deal.logo})} alt=""/>
                </GridListTile>
              </GridList>
            </div>
          ) : null}
          <ListItem dense>
            <ListItemText primary={deal.private ? 'Да' : 'Нет'} secondary="Закрытый"/>
          </ListItem>
        </List>
      </div>
    );
  }
}

const styles = theme => ({
  avatar: {
    background: colors.green[300],
  },
  menuPaper: {
    minWidth: 288,
  },
  photosWrapper: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  photoWrapper: {
    width: '100% !important',
  },
  photos: theme.mixins.gutters({
    paddingTop: 8,
    paddingBottom: 8,
  }),
});

export default connect(
  ({root}) => ({
    netRenterTypes: mapLabels(root.classification.netRenterTypes, 'value', 'label'),
    activitiesLabels: mapLabels(root.classification.activities, 'id', 'name'),
  }),
  {
    openLightbox,
  }
)(withStyles(styles)(NetRenterDetails));
