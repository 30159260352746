import React, {Component} from 'react';
import {connect} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as icons from '@material-ui/icons';
import axios from 'axios';

import ContactList from './ContactList';
import ContactListItems from './ContactListItems';
import AddRelationship from './form/AddRelationship';
import {addRelationshipToContact} from '../../actions';

class ContactRelationships extends Component {
  state = {
    openItemMenu: null,
    currentItem: null,
    addRelationship: null,
    selectedItems: [],
  };

  componentDidMount() {
    this.load();
  }

  load() {
    const {match} = this.props;
    let thisContactId = match.params.contactId;
    axios
      .get(`/api/contacts/${thisContactId}`)
      .then(res => {
        this.setState({
          selectedItems: res.data.relationships
            ? res.data.relationships.map(el => el.id)
            : [],
        });
      })
      .catch(() => {
        alert('Произошла ошибка');
      });
  }

  render() {
    const {addRelationshipToContact, match} = this.props;
    const {
      addRelationship,
      currentItem,
      openItemMenu,
      selectedItems,
    } = this.state;

    return (
      <React.Fragment>
        <ContactList
          selectedItems={selectedItems}
          action={(item, selected) => (
            <IconButton
              disabled={selected}
              onClick={e => {
                this.setState({
                  openItemMenu: e.currentTarget,
                  currentItem: item,
                });
                e.stopPropagation();
              }}
            >
              <icons.MoreVert />
            </IconButton>
          )}
        >
          <ContactListItems />
        </ContactList>
        <Menu
          anchorEl={openItemMenu}
          open={Boolean(openItemMenu)}
          onClick={() => this.setState({openItemMenu: null})}
        >
          <MenuItem
            onClick={() =>
              this.setState({
                addRelationship: {
                  contactId: match.params.contactId,
                  relationshipId: currentItem.id,
                },
              })
            }
          >
            Добавить в связанные
          </MenuItem>
        </Menu>
        <AddRelationship
          onClose={() => this.setState({addRelationship: null})}
          onSubmitted={data => {
            addRelationshipToContact(addRelationship.contactId, {
              id: addRelationship.relationshipId,
              role: data.role,
            });
            this.setState({
              addRelationship: null,
              selectedItems: [
                ...this.state.selectedItems,
                addRelationship.relationshipId,
              ],
            });
          }}
          open={Boolean(addRelationship)}
          initialValues={addRelationship}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  {addRelationshipToContact}
)(ContactRelationships);
