import React, {Component} from 'react';
import {connect} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as icons from '@material-ui/icons';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import ContactList from '../contacts/ContactList';
import ContactListItems from '../contacts/ContactListItems';
import {setTitle} from '../../actions';
import {queryNetRenterDetails} from '../../queries';

class NetRenterContact extends Component {
  state = {
    openItemMenu: null,
    currentItem: null,
    contactSet: null,
    selectedItem: null,
  };

  componentDidMount() {
    this.props.setTitle('Сетевые арендаторы');
    this.load();
  }

  load() {
    const {match} = this.props;
    queryNetRenterDetails(match.params.itemId)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            selectedItem: resp.data.data.netRenter.contact,
          });
          return;
        }
        alert('Произошла ошибка!');
      })
      .catch(() => {
        alert('Произошла ошибка!');
      });
  }

  render() {
    const {match} = this.props;
    const {
      currentItem,
      openItemMenu,
      contactSet,
      selectedItem,
    } = this.state;

    return (
      <React.Fragment>
        <ContactList
          selectedItems={selectedItem}
          action={(item, selected) => (
            <IconButton
              disabled={selected}
              onClick={e => {
                this.setState({
                  openItemMenu: e.currentTarget,
                  currentItem: item,
                });
                e.stopPropagation();
              }}>
              <icons.MoreVert/>
            </IconButton>
          )}
        >
          <ContactListItems/>
        </ContactList>
        <Menu
          anchorEl={openItemMenu}
          open={Boolean(openItemMenu)}
          onClick={() => this.setState({openItemMenu: null})}
        >
          <MenuItem
            onClick={() => this.submit({
              itemId: match.params.itemId,
              contactId: currentItem.id,
            })}
          >
            Установить контакт
          </MenuItem>
        </Menu>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(contactSet)}
          autoHideDuration={4000}
          onClose={() => this.setState({contactSet: false})}
          message="Контакт изменен"
          action={
            <IconButton
              color="inherit"
              onClick={() => this.setState({contactSet: false})}
            >
              <icons.Close/>
            </IconButton>
          }
        />
      </React.Fragment>
    );
  }

  submit = data => {
    return axios.put(`/api/deals/${data.itemId}/contact`, data)
      .then(resp => {
        if (resp.status === 200) {
          this.setState({contactSet: true, selectedItem: this.state.currentItem.id});
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

export default connect({}, {setTitle})(NetRenterContact);
