import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

class EditableStatus extends Component {
  state = {
    anchorEl: null,
  };

  handleItemClick = value => {
    this.setState({anchorEl: null});
    if(value !== this.props.status) {
      this.props.onChange(value);

    }
  };

  render() {
    const {
      status,
      statuses,
      statusLabels,
      classes,
    } = this.props;
    const {anchorEl} = this.state;

    return (
      <React.Fragment>
        <ListItem
          dense
          button
          className={classes.statusItem}
          onClick={event => this.setState({anchorEl: event.currentTarget})}
        >
          <ListItemText primary={statusLabels[status] || status} secondary="Статус" />
          <icons.Edit className={classes.editIcon}/>
        </ListItem>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => this.setState({anchorEl: null})}
          classes={{paper: classes.menuPaper}}
        >
          {statuses.map(({value, label}, i) => (
            <MenuItem
              key={i}
              value={value}
              selected={value === status}
              onClick={() => this.handleItemClick(value)}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  editIcon: {
    fontSize: 18,
    color: colors.grey[600],
    visibility: 'hidden',
    marginLeft: 'auto',
  },
  statusItem: {
    cursor: 'pointer',
    '&:hover $editIcon': {
      visibility: 'visible',
    },
  },
  menuPaper: {
    minWidth: 288,
  },
});

export default withStyles(styles)(EditableStatus);
