import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {queryDealOfferList} from '../../queries/DealOffers';
import base64url from '../../utils/base64url';
import DealOfferList from './DealOfferList';
import DealOfferListItems from './DealOfferListItems';
import FavoritesWrapper from '../../is/favorites/containers/FavoritesWrapper';
import {hasRole} from '../../utils/roleFunc';
import * as userRoles from '../../constants/userRoles';
import pathtoRegexp from 'path-to-regexp';

import {getSection} from '../../utils/lastSection';

import {find} from 'lodash';

class DealOfferListWrapper extends Component {
  state = {
    dealOffers: [],
    loading: false,
    error: false,
    totalCount: 0,
    query: {},
    searchInput: '',
    members: [],
    currentTeam: null,
    currentMember: null,
  };

  matchPath = pathtoRegexp.compile(this.props.match.path);

  componentDidMount() {
    const {query} = this.state;
    const {selfUser} = this.props;
    this.search(this.props.location);
    if (query.source && selfUser.role) {
      this.loadDealOffers(selfUser.role);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.search(nextProps.location);
    }
    if (nextProps.selfUser.role !== this.props.selfUser.role) {
      this.loadDealOffers(nextProps.selfUser.role);
    }
  }

  search(location) {
    let query = {};
    const qs = location.search.substr(1);
    if (qs.length > 0) {
      try {
        query = JSON.parse(base64url.decode(qs));
      } catch (_) {
      }
    } else {
      this.loadDealOffers(this.props.selfUser.role);
    }
    query = {...query, filter: {...query.filter, isActive: true}};

    this.setState({loading: true, query});
    if (query.team) {
      this.getTeamMembers(query.team);
      this.setState({currentTeam: query.team});
    }
    if (query.assignees && query.assignees.length) {
      this.findMember(query.assignees[0]);
    }
    if (query.filter && query.filter.searchQuery) {
      this.setState({searchInput: query.filter.searchQuery});
    }

    queryDealOfferList(query)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            loading: false,
            error: false,
            dealOffers: resp.data.data.dealOfferList.items,
            totalCount: resp.data.data.dealOfferList.totalCount,
          });
        } else {
          this.setState({loading: false, error: true});
        }
      })
      .catch(() => {
        this.setState({loading: false, error: true});
      });
  }

  loadDealOffers = role => {
    const {query} = this.state;
    let newQuery = {...query};

    if(!hasRole(role, userRoles.MANAGER) && !hasRole(role, userRoles.ADMIN))
      return;
    if(hasRole(role, userRoles.MANAGER)){
      if(!query.source){
        newQuery = {...query};
        let assignees = getSection('dealOfferManagerSource');
        if(assignees && hasRole(role, userRoles.MANAGER))
          if(assignees.length > 10){
            newQuery.assignees = [assignees];
            newQuery.source = 'TEAM';
          }
          else
            newQuery.source = assignees;
      }
    }
    /*
    if (!hasRole(role, userRoles.ADMIN)) {

      if(!query.source){
        newQuery = {...query, source: 'ASSIGNED', assignees: [], team: null};

    }else
    if(query.source === 'ALL'){
      newQuery = {...query, source: 'ASSIGNED', assignees: [], team: null};
    }
    if ((!query.source || query.source !== 'ALL') && !hasRole(role, userRoles.ADMIN)) {
      return;
    }
  */
    if(hasRole(role, userRoles.ADMIN)){
      if(!query.source){
        let assignees = getSection('dealOfferAssignees');
        if(assignees){
          newQuery = {team: null, source: 'ALL', assignees: [assignees]};
        }else{
          let team = getSection('dealOfferTeam');
          newQuery = {...query, team, source: 'ALL'};
        }
      }else{
        if(query.source !== 'ALL')
          newQuery =  {...query, source: 'ALL'};
        else{
          let team = getSection('dealOfferTeam');
          newQuery.team = team;
          newQuery.source = 'ALL';
        }
      }
    }/*
    if(hasRole(role, userRoles.ADMIN) && (query.source === 'ALL')){
      let team = getSection('dealOfferTeam');
      if(team && team !== 'ALL'){
        newQuery.team = team;
      }
      let assignees = getSection('dealOfferAssignees');
      if(assignees){
        newQuery.assignees = [assignees];
      }
    }
    else
    if (hasRole(role, userRoles.ADMIN) && (query.source !== 'ALL')) {
      newQuery = {...query, source: 'ALL'};
    }*/
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify(newQuery));
    this.props.history.push(path);
  };

  handleChangeStatus = (nextStatus, prevStatus, dealOfferId) => {
    const {dealOffers} = this.state;

    this.setState({
      dealOffers: dealOffers.map(dealOffer => {
        if (dealOffer.id === dealOfferId) {
          return {
            ...dealOffer,
            status: nextStatus,
          };
        }
        return dealOffer;
      }),
    });

    axios.post('/api/v1/setDealOfferStatus', {id: dealOfferId, status: nextStatus})
      .catch(error => {
        this.setState({
          dealOffers: dealOffers.map(dealOffer => {
            if (dealOffer.id === dealOfferId) {
              return {
                ...dealOffer,
                status: prevStatus,
              };
            }
            return dealOffer;
          }),
        });
        if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };

  handleRequestSort = property => {
    const {order} = this.state.query;

    if (order && order.column === property && order.direction === 'ASC') {
      this.getSortParams(property, 'DESC');
    } else if (order && order.column === property && order.direction === 'DESC') {
      this.getSortParams();
    } else {
      this.getSortParams(property, 'ASC');
    }
  };

  getSortParams = (orderBy, order) => {
    let queryOrder = {
      column: orderBy,
      direction: order,
    };

    let newQuery;
    if (!orderBy && !order) {
      newQuery = {...this.state.query};
      delete newQuery.order;
    } else {
      newQuery = {...this.state.query, order: queryOrder};
    }
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...newQuery, offset: 0}));
    this.props.history.push(path);
  };

  findMember = id => {
    const {selfUser} = this.props;
    axios.get(`/api/members/${id}`)
      .then(res => {
        const {team} = res.data;
        this.getTeamMembers(team);
        this.setState({currentTeam: team, currentMember: id});
      })
      .catch(() => {
        if (selfUser && find(selfUser.teams, team => team.id === id)) {
          this.setState({currentTeam: id});
          this.getTeamMembers(id);
        } else {
          alert('Произошла ошибка');
          this.props.history.push(this.matchPath({...this.props.match.params, source: 'all'}));
        }
      });
  };

  handleTeamDropdown = id => {
    this.setState({currentTeam: id === 'all' ? null : id});
    if (id !== 'all') {
      this.getTeamMembers(id);
    } else {
      this.setState({members: [], currentMember: null});
    }
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...this.props.query, team: id === 'all' ? null : id, assignees: [], source: 'ALL', offset: 0}));
    this.props.history.push(path);
  };

  handleMemberDropdown = id => {
    const {currentTeam} = this.state;
    this.setState({currentMember: id === currentTeam ? null : id});

    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...this.props.query, source: 'ALL', team: id === currentTeam ? id : null, assignees: id === currentTeam ? null : [id], offset: 0}));
    this.props.history.push(path);
  };

  getTeamMembers = id => {
    axios.get(`/api/teams/${id}/members`)
      .then(res => {
        this.setState({members: res.data.items});
      })
      .catch(() => {
        alert('Произошла ошибка');
      });
  };

  handleFilter = filter => {
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...this.state.query, filter, offset: 0}));
    this.props.history.push(path);
    this.setState({searchInput: filter.searchQuery});
  };



  render() {
    const {dealOffers, loading, error, totalCount, query, currentTeam, team, members} = this.state;

    return (
      <DealOfferList
        query={query}
        dealOffers={dealOffers}
        loading={loading}
        error={error}
        totalCount={totalCount}
        handleChangeStatus={(value, status, dealOffer) => this.handleChangeStatus(value, status, dealOffer)}
        handleRequestSort={e => this.handleRequestSort(e)}
        handleFilter={filter => this.handleFilter(filter)}
        handleTeamDropdown={id => this.handleTeamDropdown(id)}
        handleMemberDropdown={id => this.handleMemberDropdown(id)}
        currentTeam={currentTeam}
        team={team}
        members={members}
        {...this.props}
      >
        {dealOffers ? (
          <FavoritesWrapper items={dealOffers.map(dealOffer => dealOffer.id)}>
            <DealOfferListItems/>
          </FavoritesWrapper>
        ) : null}
      </DealOfferList>
    );
  }
}

export default connect(
  state => ({
    selfUser: state.root.selfUser,
  })
)(DealOfferListWrapper);
