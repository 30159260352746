import * as colors from '@material-ui/core/colors/index';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Checkbox} from 'redux-form-material-ui';
import {withStyles} from '@material-ui/core/styles';
import DatePicker from '../../../components/Input/DatePicker';
import TextField from '../../../components/Input/TextField';
import TextFieldCount from '../../../components/Input/TextFieldWithCount';
import CityInput from '../../../components/Input/CityInput';
import AccessComponent from '../../../components/AccessComponent';
import Photos from './Photos';
import {queryCities} from '../../../queries/CityQuery';

class NewsForm extends Component {
  render() {
    const {
      array: {push, remove, swap},
      array,
      photos,
      change,
      pressRelease,
      teams,
      cityHint,
    } = this.props;

    return (
      <React.Fragment>
        <Field
          name="name"
          label="Название"
          required
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          component={CityInput}
          name="city"
          label="Город"
          required
          change={change}
          placeholder="Введите название города"
          hint={teams[0].cities || cityHint}
          query={queryCities}
          initializeValue={pressRelease ? pressRelease.city : null}
        />
        <AccessComponent admin>
          <FormControl margin="none">
            <FormControlLabel
              control={<Field name="federal" component={Checkbox}/>}
              label="Федеральный уровень"
              required
            />
          </FormControl>
        </AccessComponent>
        <Field
          name="date"
          label="Дата события"
          required
          format={null}
          component={props => (
            <DatePicker format="yyyy-MM-dd" clearable {...props} />
          )}
          fullWidth
          rowsMax={15}
          margin="normal"
        />
        <Field
          name="description"
          label="Описание"
          required
          multiline
          component={TextFieldCount}
          width={'100%'}
          max={5000}
          fullWidth
          min={200}
          margin="normal"
        />
        <Field
          name="comment"
          label="Комментарий"
          multiline
          component={TextFieldCount}
          width={'100%'}
          max={1000}
          fullWidth
          margin="normal"
        />
        <Photos
          name="photos"
          label="Фотография (не обязательно)"
          array={array}
          photos={photos}
          push={(name, value) => push(name, value)}
          remove={(name, value) => remove(name, value)}
          swap={(name, oldIndex, newIndex) => swap(name, oldIndex, newIndex)}
          isOptional={true}
        />
      </React.Fragment>
    );
  }
}

const styles = {
  help: {
    color: colors.grey[700],
    marginLeft: 12,
    marginBottom: 8,
  },
  MenuItemContainer: {
    zIndex: 1,
    position: 'absolute',
    width:'100%',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  MenuItemWrapper: {
    position: 'relative',
  },
};

export default connect(state => ({
  propertyStatuses: state.root.classification.propertyStatuses,
  teams: state.root.selfUser.teams,
  cityHint: Object.values(state.root.hint.city),
}),{

})(withStyles(styles)(NewsForm));
