import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as icons from '@material-ui/icons';

import RemoveAssignee from '../form/RemoveAssignee';
import {queryDealAssignees} from '../../../queries';
import MemberListItem from '../../../components/relations/MemberListItem';

class Assignees extends Component {
  state = {
    assignees: null,
    error: null,
    loading: false,
    openMenu: null,
    removeAssignee: null,
  };

  handleOpenMenu = (target, id) => {
    this.setState({
      openMenu: {target, id},
    });
  };

  handleCloseMenu = () => {
    this.setState({
      openMenu: null,
    });
  };

  handleMenuItemClick = () => {
    this.setState({
      removeAssignee: {
        dealId: this.props.deal.id,
        assigneeId: this.state.openMenu.id,
      },
    });
  };

  handleRemoveSubmitted = () => {
    this.reload();
    this.setState({
      openMenu: null,
      removeAssignee: null,
    });
    this.props.updateActivity();
  };

  handleRemoveCancel = () => {
    this.setState({
      removeAssignee: null,
    });
  };

  reload() {
    this.setState({
      loading: true,
    });
    queryDealAssignees(this.props.deal.id)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            assignees: resp.data.data.deal.assignees,
          });
          this.props.deal.assignees = resp.data.data.deal.assignees;
        } else {
          this.setState({
            error: true,
          });
        }
      }, () => {
        this.setState({
          error: true,
        });
      })
      .then(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const {deal, history, handleDialogOpen} = this.props;
    const {assignees, error, loading, openMenu, removeAssignee} = this.state;

    if (!deal) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Ответственные
          <ListItemSecondaryAction>
            {loading ? (
              <IconButton disabled>
                <CircularProgress size={24}/>
              </IconButton>
            ) : (
              error ? (
                <IconButton disabled>
                  <icons.ErrorOutline/>
                </IconButton>
              ) : (
                <IconButton onClick={() => history.push(`/deals/${deal.id}/assignees`)}>
                  <icons.Add/>
                </IconButton>
              )
            )}
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {!error ? (assignees || deal.assignees).map(assignee => {
          return (
            <MemberListItem
              key={assignee.id}
              member={assignee}
              onSecondaryActionClick={
                (assignees && assignees.length > 1) || (deal.assignees && deal.assignees.length > 1)
                  ? e => this.handleOpenMenu(e.currentTarget, assignee.id)
                  : null
              }
              handleDialogOpen={e => handleDialogOpen(e)}
            />
          );
        }) : null}
        <Menu
          anchorEl={openMenu ? openMenu.target : null}
          open={Boolean(openMenu)}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleMenuItemClick}>
            Снять ответственного
          </MenuItem>
        </Menu>
        <RemoveAssignee
          open={Boolean(removeAssignee)}
          initialValues={removeAssignee}
          onSubmitted={this.handleRemoveSubmitted}
          onClose={this.handleRemoveCancel}
        />
      </List>
    );
  }
}

Assignees = connect(
  state => ({
    members: state.root.team.members,
  }),
)(Assignees);

export default withRouter(Assignees);
