import React, {Component} from 'react';
import {connect} from 'react-redux';
import RequestListWrapper from '../../containers/requests/RequestListWrapper';

class RequestStatistic extends Component {
  render() {
    const {history, location, match} = this.props;

    return (
      <React.Fragment>
        <RequestListWrapper
          history={history}
          match={match}
          location={location}
        />
      </React.Fragment>
    );
  }
}

export default connect(null)(RequestStatistic);
