export const onClickItem = function(fieldLabelName, fieldIdName, value, selectedField, query = null, fieldFilter = null, itemsName = null, callback=() => {} ){
  const {change} = this.props;
  const {name, id} = value;

  change(fieldLabelName, name.trim());
  change(fieldIdName, id);
  this.setState({[selectedField]: true});
  if(query){
    getListItems(query, fieldFilter, id)
      .then(res => {
        let state = {};
        for(let property in res.data.data)
          state[itemsName] = res.data.data[property].items;
        this.setState(state, callback);
      });
  }
};

export const _onBlur = function(name){
  setTimeout(function(context) {
    context.setState({[name]: false});
  }, 300, this);
};

export const getListItems = async function(query, fieldName, id){
  var variables = {
    filter: {
      [fieldName]: id,
      archived: 2,
    },
    limit: 1117,
  };
  return await query(variables);
};

export const compare = function(value1, value2){
  return value1.toLowerCase().trim() === value2.toLowerCase().trim();
};

export const isSubstring = function (value1, value2) {
  value1 = value1.toLowerCase().trim();
  value2 = value2.toLowerCase().trim();
  return value1.indexOf(value2) > -1;
};

export const clearFiled = (fields, method) => {
  for (let i = 0; i < fields.length; i++)
    method(fields[i], null);
};
export const setFilterListItems = function(event, listName, onClick, list, hint = []){

  let tmp = [];

  if(event.target.value === ''){
    tmp = hint;
  }
  else{
    tmp = list.filter(value => value.name.toLowerCase().includes(event.target.value.toLowerCase())) || [];
    var item = list.find(item => compare(item.name, event.target.value));
  }
  this.setState({
    ['list' + listName]: tmp,
    ['selected' + listName]: false,
    ['isOpenList' + listName]: true,
  },() => {
    item && onClick(item);
  });
};
