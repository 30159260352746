import React, {Component} from 'react';
import {reduxForm} from 'redux-form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import * as icons from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';

class OfferReport extends Component {
  state = {
    offer: this.props.offer,
  };

  componentDidMount() {
    const assignee = this.props.members[this.props.offer.assignees[0].id];
    axios.get(`/api/teams/${assignee.team}`)
      .then(res => {
        this.props.initialize({
          xml_id: this.props.offer.id,
          agent_phone: res.data.phone,
          agent_email: res.data.email,
        });
      })
      .catch(() => {
        this.props.initialize({
          xml_id: this.props.offer.id,
        });
      });
  }

  submit = () => {
    if (this.form.checkValidity()) {
      this.form.submit();
    }else{
      this.submitBtn.click();
    }
  };

  render() {
    const {
      handleSubmit,
      data,
      classes,
      onClose,
      open,
      // onSubmit,
    } = this.props;

    return (
      <Dialog fullScreen open={open} classes={{paper: classes.dialog}}>
        <DialogTitle>
          {data.title}
          <IconButton className={classes.closeButton} onClick={() => onClose()}>
            <icons.Close/>
          </IconButton>
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <form ref={form => this.form = form} onSubmit={handleSubmit(this.submit)} method="post" target="_blank" action={`https://crenet.ru/pdf${data.action}`} encType="multipart/form-data">
            {this.props.children}
            <button ref={submitBtn => this.submitBtn = submitBtn} style={{display: 'none'}} type="submit"></button>
          </form>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button margin="normal" onClick={handleSubmit(this.submit)}>
            Сформировать
          </Button>
          <Button type="reset" onClick={() => onClose()}>
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = () => ({
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  dialog: {
    maxWidth: 960,
  },
});

OfferReport = connect(
  state => ({
    members: state.root.team.members,
    selfUserTeams: state.root.selfUser.teams,
  }),
)(OfferReport);

OfferReport = withRouter(withStyles(styles)(OfferReport));

export default reduxForm({
  form: 'reportForm',
  enableReinitialize: true,
})(OfferReport);
