import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Select} from 'redux-form-material-ui';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import TextField from '../../../components/Input/TextField';

class FilterDeals extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      entityTypes,
      onClose,
      onSubmit,
      open,
      pristine,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Настройки фильтра</DialogTitle>
        <Divider/>
        <DialogContent>
          <Field
            name="searchQuery"
            label="Поисковый запрос"
            component={TextField}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Тип сущности</InputLabel>
            <Field name="entityType" component={Select}>
              <MenuItem value=""/>
              {entityTypes.map(({value, label}, i) => (
                <MenuItem value={value} key={i}>{label}</MenuItem>
              ))}
            </Field>
          </FormControl>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(onSubmit)}
          >
            Применить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

FilterDeals = connect(
  state => ({
    entityTypes: state.root.classification.entityTypes,
  })
)(FilterDeals);

export default reduxForm({
  form: 'filter_favorites',
  enableReinitialize: true,
})(FilterDeals);
