import React, {Component} from 'react';
import {connect} from 'react-redux';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';
import DealDetails from './NetRenterDetails';

class NetRenterDetailsWrapper extends Component {
  state = {
    menuAnchor: null,
  };

  render() {
    const {
      classes,
      deal,
      onEdit,
      onRemove,
    } = this.props;
    const {menuAnchor} = this.state;

    return (
      <Paper elevation={1} square className="column">
        <CardHeader
          action={
            <IconButton
              classes={{root: classes.icon}}
              onClick={({currentTarget}) => {
                this.setState({menuAnchor: currentTarget});
              }}
            >
              <icons.MoreVert/>
            </IconButton>
          }
          title={deal.trademark || ''}
        />
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClick={() => {
            this.setState({menuAnchor: null});
          }}
        >
          <MenuItem onClick={() => onEdit()}>
            Редактировать
          </MenuItem>
          <MenuItem onClick={() => onRemove()}>
            Удалить
          </MenuItem>
        </Menu>
        <Divider/>
        <DealDetails
          deal={deal}
        />
      </Paper>
    );
  }
}

const styles = () => ({
  avatar: {
    background: colors.green[300],
  },
});

export default connect(null)(withStyles(styles)(NetRenterDetailsWrapper));
