import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';
import React, {Component} from 'react';
import TextField from '../../components/Input/TextField';

class TeamSelect extends Component {
  state = {
    teams: [],
  };

  componentDidMount() {
    axios.get('/api/teams?franchisee=1')
      .then(resp => {
        this.setState({teams: resp.data});
      });
  }

  render() {
    const {...otherProps} = this.props;
    const {teams} = this.state;

    return (
      <TextField
        {...otherProps}
        select
      >
        {teams.map((team, index) => (
          <MenuItem key={index} value={team.id}>
            {team.name}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

export default TeamSelect;
