import React from 'react';
import MaskedInput from './MaskedInput';

const PhoneInput = props => {
  return (
    <MaskedInput {...props} mask="+7 999 999-99-99" />
  );
};

export default PhoneInput;
