import axios from 'axios';
import qs from 'qs';


export const downloadPhoto = async (url, name) => {
  const link = document.createElement('a');

  const token = axios.defaults.headers.common['Authorization'];
  delete axios.defaults.headers.common['Authorization'];
  const response = await axios.get(url, {
    responseType: 'blob',
    mode: 'cors',
    withCredentials: false,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  });
  axios.defaults.headers.common['Authorization'] = token;

  let originName = `${name}.jpg`;
  if (response.headers['content-disposition']) {
    let ar = response.headers['content-disposition'].split(';');
    let params = {};
    ar.forEach(element => {
      let arr = element.split('=');
      if (arr.length > 1) {
        params[arr[0].trim()] = arr[1].trim();
      }
    });
    if (params['filename'])
      originName = params['filename'];
    if (params['filename*'])
      originName = decodeURIComponent(params['filename*'].replace('UTF-8\'\'', ''));
  }
  url = await window.URL.createObjectURL(new Blob([response.data]));
  link.setAttribute('href', url);
  link.setAttribute('download', originName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const downloadPhotoZip = async (method, {id, name, number}) => {
  const queryFilter = {
    id: id,
  };
  axios({
    url: `/api/v1/${method}?` + qs.stringify(queryFilter),
    method: 'GET',
    responseType: 'blob',
  }).then((response => {
    var url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${translateName(name)}${number ? '-' + number : ''}.zip`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }))
    .catch(e => {
      if (e.response.status === 403) {
        alert('Недостаточно прав');
      }
      if (e.response.status === 404) {
        alert('Нет фотографий для скачивания');
      }
    });
};

export const downloadPropertyPhotoZip = (id, name) => {
  downloadPhotoZip('downloadPropertyImages', {id, name});
};
export const downloadOfferPhotoZip = (id, name, number) => {
  downloadPhotoZip('downloadOfferImages', {id, name, number});
};

const arrayU = ['Я', 'я', 'Ю', 'ю', 'Ч', 'ч', 'Ш', 'ш', 'Щ', 'щ', 'Ж', 'ж', 'А', 'а', 'Б', 'б', 'В', 'в', 'Г', 'г', 'Д', 'д', 'Е', 'е', 'Ё', 'ё', 'З', 'з', 'И', 'и', 'Й', 'й', 'К', 'к', 'Л', 'л', 'М', 'м', 'Н', 'н', 'О', 'о', 'П', 'п', 'Р', 'р', 'С', 'с', 'Т', 'т', 'У', 'у', 'Ф', 'ф', 'Х', 'х', 'Ц', 'ц', 'Ы', 'ы', 'Ь', 'ь', 'Ъ', 'ъ', 'Э', 'э', ' '];
const arrayN = ['Ya', 'ya', 'Yu', 'yu', 'Ch', 'ch', 'Sh', 'sh', 'Sh', 'sh', 'Zh', 'zh', 'A', 'a', 'B', 'b', 'V', 'v', 'G', 'g', 'D', 'd', 'E', 'e', 'E', 'e', 'Z', 'z', 'I', 'i', 'J', 'j', 'K', 'k', 'L', 'l', 'M', 'm', 'N', 'n', 'O', 'o', 'P', 'p', 'R', 'r', 'S', 's', 'T', 't', 'U', 'u', 'F', 'f', 'H', 'h', 'C', 'c', 'Y', 'y', '`', '`', '\'', '\'', 'E', 'e', '-'];

const translateName = text => {
  for (let i = 0; i < arrayU.length; i++) {
    let reg = new RegExp(arrayU[i], 'g');
    text = text.replace(reg, arrayN[i]);
  }
  return text.toLowerCase();
};
