import axios from 'axios';
import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

class AddFavorite extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      open,
      classes,
    } = this.props;

    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        classes={{paper: classes.noteDialog}}
        open={open}
      >
        <DialogTitle>Добавить в избранное?</DialogTitle>
        <Divider/>
        <DialogContent className={classes.dialogContent}>
          <Field
            placeholder="Добавьте заметку"
            multiline
            rowsMax={15}
            component={TextField}
            name="note"
            fullWidth
            inputRef={el => {
              this.textareaRef = el;
            }}
          />
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отмена
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={handleSubmit(this.submit)}
          >
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = () => {
    const {onSubmitted, onClose, entity} = this.props;

    const data = {
      entityId: entity.id,
      entityType: `Arr\\Is\\Entity\\${entity.type}`,
      note: this.textareaRef.value,
    };

    axios.post('/api/v1/favorites', data)
      .then(response => {
        onSubmitted(response.data, entity.id);
      })
      .catch(() => {
        alert('Произошла ошибка');
      })
      .then(() => onClose());
  };
}

const styles = theme => ({
  noteDialog: {
    width: 550,
    maxWidth: '100%',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100%',
    },
  },
  dialogContent: {
    padding: 20,
  },
});

export default reduxForm({
  form: 'add_favorite_from_list',
  enableReinitialize: true,
})(withStyles(styles)(AddFavorite));
