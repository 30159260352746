import React, {Component} from 'react';
import {connect} from 'react-redux';
import CardContent from '@material-ui/core/CardContent';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';

import PropertyAttributes from './PropertyAttributes';
import {openLightbox, setPropertyStatus} from '../../actions';
import {
  ImageFilters,
  createAreaFormatter,
  createPriceFormatter,
  formatDate,
  getImageUrl,
  mapLabels,
} from '../../utils';
import EditableStatus from '../../components/EditableStatus';
import {queryDistricts, querySubDistricts} from '../../queries/CityQuery';
import {getListItems} from '../../utils/filter';
class PropertyDetails extends Component {
  state = {
    menuAnchor: null,
    district:null,
    subDistrict:null,
  };
  componentDidMount(){
    const {property} = this.props;
    if(property.address){
      if(property.address.district)
        getListItems(queryDistricts, 'id', property.address.district)
          .then(res => {
            if(res.data.data.districts.items.length > 0)
              this.setState({district: res.data.data.districts.items[0].name});
          });
      if(property.address.microDistrict)
        getListItems(querySubDistricts, 'id', property.address.microDistrict)
          .then(res => {
            if(res.data.data.microDistricts.items.length > 0)
              this.setState({subDistrict: res.data.data.microDistricts.items[0].name});
          });
    }
  }
  render() {
    const {
      classes,
      openLightbox,
      property,
      propertyStatuses,
      formatArea,
      propertyStatusLabels,
      propertyTypeLabels,
      propertyTypes,
      propertyPurposeLabels,
      setPropertyStatus,
    } = this.props;
    const {district,subDistrict} = this.state;
    const additionalTypes = property.additionalTypes ? property.additionalTypes.map(value => propertyTypeLabels[value] || value) : [];
    const purposes = property.purposes ? property.purposes.map(value => propertyPurposeLabels[value] || value) : [];

    return (
      <div className="content" style={{background: 'white'}}>
        <List>
          <ListItem dense>
            <ListItemText
              primary={`${formatDate(property.createdAt)} / ${formatDate(property.updatedAt)}`}
              secondary="Дата создания / редактирования"
            />
          </ListItem>
          {property.status ? (
            <EditableStatus
              status={property.status}
              onChange={status => setPropertyStatus(property.id, status, property.status)}
              statuses={propertyStatuses}
              statusLabels={propertyStatusLabels}
            />
          ) : null}
          {property.address ? (
            <React.Fragment>
              <ListItem dense>
                <ListItemText primary={property.address.value} secondary="Адрес"/>
              </ListItem>
              {district ? (
                <ListItem dense>
                  <ListItemText primary={district} secondary="Район"/>
                </ListItem>
              ) : null}
              {subDistrict ? (
                <ListItem dense>
                  <ListItemText primary={subDistrict} secondary="Микрорайон"/>
                </ListItem>
              ) : null}
              {property.address.street ? (
                <ListItem dense>
                  <ListItemText primary={property.address.street} secondary="Улица"/>
                </ListItem>
              ) : null}
              {property.address.building ? (
                <React.Fragment>
                  {property.address.building.number ? (
                    <ListItem dense>
                      <ListItemText primary={property.address.building.number} secondary="Номер дома"/>
                    </ListItem>
                  ) : null}
                  {property.address.building.block ? (
                    <ListItem dense>
                      <ListItemText primary={property.address.building.block} secondary="Корпус"/>
                    </ListItem>
                  ) : null}
                  {property.address.building.letter ? (
                    <ListItem dense>
                      <ListItemText primary={property.address.building.letter} secondary="Строение"/>
                    </ListItem>
                  ) : null}
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : null}
          {additionalTypes.length > 0 ? (
            <ListItem dense>
              <ListItemText primary={additionalTypes.join(', ')} secondary="Дополнительные категории"/>
            </ListItem>
          ) : null}
          {purposes.length > 0 ? (
            <ListItem dense>
              <ListItemText primary={purposes.join(', ')} secondary="Назначение"/>
            </ListItem>
          ) : null}
          <ListItem dense>
            <ListItemText primary={formatArea(property.area)} secondary="Площадь"/>
          </ListItem>
          <PropertyAttributes
            propertyType={property.type}
            propertyTypes={propertyTypes}
          />
        </List>
        {property.photos && property.photos.length > 0 ? (
          <div className={classes.photos}>
            <Typography variant="caption" gutterBottom>Фотографии</Typography>
            <GridList cellHeight={60} cols={5} spacing={4}>
              {property.photos.map((photo, i) => (
                <GridListTile key={i} onClick={() => openLightbox(property.photos, i)}>
                  <img src={getImageUrl({filter: ImageFilters.crop, width: 100, height: 100, path: photo})} alt=""/>
                </GridListTile>
              ))}
            </GridList>
          </div>
        ) : null}
        {property.layouts && property.layouts.length > 0 ? (
          <div className={classes.photos}>
            <Typography variant="caption" gutterBottom>Планировки</Typography>
            <GridList cellHeight={60} cols={5} spacing={4}>
              {property.layouts.map((photo, i) => (
                <GridListTile key={i} onClick={() => openLightbox(property.layouts, i)}>
                  <img src={getImageUrl({filter: ImageFilters.crop, width: 100, height: 100, path: photo})} alt=""/>
                </GridListTile>
              ))}
            </GridList>
          </div>
        ) : null}
        {property.description ? (
          <CardContent>
            <Typography style={{whiteSpace: 'pre-line'}}>{property.description}</Typography>
          </CardContent>
        ) : null}
      </div>
    );
  }
}

const styles = theme => ({
  avatar: {
    background: colors.blue[300],
  },
  photos: theme.mixins.gutters({
    paddingTop: 8,
    paddingBottom: 8,
  }),
  headerPopoverPaper: {
    padding: 16,
    width: '100%',
  },
});

export default connect(
  ({root}) => ({
    formatPrice: createPriceFormatter(root.classification.priceTypes, root.classification.currencyCodes),
    formatArea: createAreaFormatter(root.classification.areaUnits),
    propertyStatusLabels: mapLabels(root.classification.propertyStatuses, 'value', 'label'),
    propertyTypeLabels: mapLabels(root.classification.propertyTypes, 'value', 'label'),
    propertyTypes: root.classification.propertyTypes,
    propertyStatuses: root.classification.propertyStatuses,
    propertyPurposeLabels: mapLabels(root.classification.propertyPurposes, 'value', 'label'),
  }),
  {openLightbox, setPropertyStatus}
)(withStyles(styles)(PropertyDetails));
