import {executeQuery} from '../utils';

export const queryCities = query => {
  return executeQuery(
    `query Cities($filter: CityFilter, $offset: Int, $limit: Int) {
      cities(filter: $filter, offset: $offset, limit: $limit) {
        items {
          id
          name
          code
          archived
        }
        totalCount
      }
    }`,
    {...query}
  );
};

export const queryDistricts = query => {
  return executeQuery(
    `query Districts($filter: DistrictFilter, $offset: Int, $limit: Int) {
      districts(filter: $filter, offset: $offset, limit: $limit) {
        items {
          id
          name
          code
          archived
          city {
            name
            id
          }
        }
        totalCount
      }
    }`,
    {...query}
  );
};
export const queryDistrictsName = query => {
  return executeQuery(
    `query Districts($filter: DistrictFilter, $offset: Int, $limit: Int) {
      districts(filter: $filter, offset: $offset, limit: $limit) {
        items {
          name
          id
        }
      }
    }`,
    {...query}
  );
};

export const querySubDistricts = query => {
  return executeQuery(
    `query MicroDistricts($filter: MicroDistrictFilter, $offset: Int, $limit: Int) {
      microDistricts(filter: $filter, offset: $offset, limit: $limit) {
        items {
          id
          name
          code
          archived
          district {
            name
            id
            city {
              id
              name
            }
          }
        }
        totalCount
      }
    }`,
    {...query}
  );
};
