import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import * as icons from '@material-ui/icons';
import React from 'react';

import MemberChip from '../../../components/MemberChip';

class Members extends React.Component {
  state = {
    openMenu: null,
  };

  render() {
    const {editable, label, members, teamMembers, onAdd, onRemove} = this.props;
    const {openMenu} = this.state;

    return (
      <ListItem>
        <ListItemText
          primary={members.map((member, index) => (
            <MemberChip
              key={index}
              member={member}
              onDelete={editable ? () => onRemove(member.id) : undefined}
            />
          ))}
          secondary={label}
        />
        <ListItemSecondaryAction>
          <IconButton
            disabled={!editable}
            onClick={event => this.setState({openMenu: event.target})}
          >
            <icons.Add/>
          </IconButton>
        </ListItemSecondaryAction>
        <Menu
          open={Boolean(openMenu)}
          anchorEl={openMenu}
          onClick={() => this.setState({openMenu: null})}
        >
          {teamMembers.map((member, index) => (
            <MenuItem
              key={index}
              disabled={members.some(otherMember => otherMember.id === member.id)}
              onClick={() => onAdd(member.id)}
            >
              {member.name}
            </MenuItem>
          ))}
        </Menu>
      </ListItem>
    );
  }
}

export default Members;
