import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import {mapLabels} from '../../../utils';
import ContactListItem from '../../../components/relations/ContactListItem';

class Owner extends Component {
  render() {
    const {
      property,
      contacts,
    } = this.props;

    if (!property) {
      return null;
    }

    return (
      <React.Fragment>
        <List subheader={
          <ListSubheader
            disableSticky
            style={{position: 'relative'}}
          >
            Собственник
          </ListSubheader>
        }>
          {property.owner ? [property.owner].map(owner => {
            const contact = contacts[owner.id];
            return contact ? (
              <ContactListItem
                contact={contact}
                key={owner.id}
              />
            ) : null;
          }) : null}
        </List>

      </React.Fragment>
    );
  }
}

Owner = connect(
  state => ({
    contactTypeLabels: mapLabels(state.root.classification.contactTypes, 'value', 'label'),
    contacts: state.root.contact.contacts,
  }),
)(Owner);

export default withRouter(Owner);
