import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';

import {formatFileSize} from '../../utils';

class DocumentListItem extends Component {
  render() {
    const {document, onSecondaryActionClick} = this.props;

    return (
      <ListItem button dense component="a" href={`/uploads/${document.path}`} target="_blank">
        <Avatar style={{backgroundColor: colors.pink[300]}}>
          <icons.AttachFile/>
        </Avatar>
        {document ? (
          <ListItemText
            disableTypography={true}
            primary={
              <Typography variant="body1" noWrap>
                {document.name}
              </Typography>
            }
            secondary={
              <Typography variant="caption" noWrap>
                {formatFileSize(document.fileSize)}
              </Typography>
            }
          />
        ) : null}
        {onSecondaryActionClick ? (
          <ListItemSecondaryAction>
            <IconButton onClick={onSecondaryActionClick}>
              <icons.MoreVert/>
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    );
  }
}

export default DocumentListItem;
