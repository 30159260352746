import React, {Component} from 'react';
import {connect} from 'react-redux';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';

import {
  addOfferToProperty,
  loadProperty,
  openLightbox,
  updateProperty,
  setPropertyComment,
} from '../../actions';
import {createAreaFormatter, createPriceFormatter, mapLabels} from '../../utils';
import EditableComment from '../../components/EditableComment';
import Documents from './relations/Documents';
import PropertyDetailsWrapper from './PropertyDetailsWrapper';
import PropertyEdit from './PropertyEdit';
import Assignees from './relations/Assignees';
import Owner from './relations/Owner';
import Contracts from './relations/Contracts';
import OfferEdit from './../offer/OfferEdit';
import Row from '../../components/Row';
import Column from '../../components/Column';
import OfferListItem from '../../components/relations/OfferListItem';


import ContractDialog from '../contract/ContractDialog';
import ContactDialog from '../contacts/ContactDialog';
import OfferDialog from '../offer/OfferDialog';
import AssigneeDialog from '../contacts/AssigneeDialog';
import ContractEdit from '../contract/ContractEdit';
import MiddleColumn from '../../components/MiddleColumn';
import {getPropertyWarningList} from '../../utils/alerts';
import Team from './relations/Team';
import getScreensView from '../../utils/getScreensView';

class PropertyView extends Component {
  state = {
    propertyViewCache: null,
    openAddOffer: false,
    openEdit: false,
    addContract: false,
    openContactDialog: false,
    openOfferDialog: false,
    openAssigneeDialog: false,
    openContractDialog: false,
  };

  componentDidMount() {
    this.props.loadProperty(this.props.location.key, this.props.match.params.propertyId);
    if (this.props.propertyView) {
      this.setState({propertyViewCache: this.props.propertyView});
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.match.params.propertyId !== this.props.match.params.propertyId) {
      this.props.loadProperty(newProps.location.key, newProps.match.params.propertyId);
    }
    if (newProps.propertyView && newProps.propertyView !== this.props.propertyView && newProps.location.key !== undefined) {
      this.setState({propertyViewCache: newProps.propertyView});
    }
  }

  handleDialogClose = name => {
    this.setState({[name]: false});
  };

  handleDialogOpen = (value, name) => {
    this.setState({[name] : value});
  };
  ignoreStatus = ['new', 'rejected', 'archived'];
  render() {
    const {
      addOfferToProperty,
      classes,
      match,
      offers,
      propertyView,
      properties,
      updateProperty,
      setPropertyComment,
      history,
    } = this.props;

    if (!propertyView && !this.state.propertyViewCache) {
      return null;
    }

    if (propertyView && propertyView.loading) {
      return <CircularProgress size={50} thickness={4} className={classes.progress}/>;
    }

    if (propertyView && propertyView.error) {
      return <icons.ErrorOutline className={classes.progress}/>;
    }

    const {
      openAddOffer,
      openEdit,
      openContactDialog,
      openAssigneeDialog,
      openOfferDialog,
      openContractDialog,
      addContract,
      propertyViewCache,
    } = this.state;

    const property = propertyView && propertyView.item ? properties[propertyView.item] : properties[propertyViewCache.item];
    return (
      <React.Fragment>
        <Row key={0}>
          <Column label="Информация" icon={<icons.InfoOutlined/>} key="information">
            <PropertyDetailsWrapper
              property={property}
              onEdit={() => this.setState({openEdit: true})}
            />
          </Column>
          <Column label="История" icon={<icons.History/>} key="activity">
            <div className="column" style={{background: colors.grey[200], zIndex: 0}}>
              <div className="content" style={{padding: 16}}>
                {getPropertyWarningList(property)}
                <MiddleColumn entityId={property.id} entityName="Property">
                  <EditableComment
                    comment={property.comment}
                    onSave={comment => setPropertyComment(property.id, comment, property.comment)}
                  />
                </MiddleColumn>
              </div>
            </div>
          </Column>
          <Column label="Связи" icon={<icons.Share/>} key="relationships">
            <Paper elevation={1} square className="column">
              <div className="content">
                <Team property={property} />
                <Assignees property={property} handleDialogOpen={e => this.handleDialogOpen(e, 'openAssigneeDialog')}/>
                <Divider/>
                <Owner property={property} handleDialogOpen={e => this.handleDialogOpen(e, 'openContactDialog')}/>
                <Divider/>
                <Documents property={property}/>
                <Divider/>
                <List subheader={
                  <ListSubheader disableSticky style={{position: 'relative'}}>
                    Листинги
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => this.setState({openAddOffer: true})}>
                        <icons.Add/>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListSubheader>
                }>
                  {property.offers ? (
                    property.offers.map(element => {
                      const offer = offers[element.id];
                      return offer ? (
                        <OfferListItem
                          key={element.id}
                          offer={offer}
                          handleDialogOpen={e => this.handleDialogOpen(e, 'openOfferDialog')}
                        />
                      ) : null;
                    })
                  ) : null}
                </List>
                <Divider/>
                <Contracts
                  property={property}
                  handleDialogOpen={e => this.handleDialogOpen(e, 'openContractDialog')}
                  onContractAdd={() => this.setState({addContract: true})}
                />
                <Divider/>
              </div>
            </Paper>
          </Column>
        </Row>
        {openEdit ? (
          <PropertyEdit
            open={openEdit}
            property={property}
            onSubmitted={property => {
              updateProperty(property);
              this.setState({openEdit: false});
            }}
            onClose={() => this.setState({openEdit: false})}
          />
        ) : null}
        {openAddOffer ? (
          <OfferEdit
            open={openAddOffer}
            property={property}
            onSubmitted={offer => {
              addOfferToProperty(match.params.propertyId, offer);
              this.setState({openAddOffer: false});
            }}
            onClose={() => this.setState({openAddOffer: false})}
          />
        ) : null}
        {openContactDialog ? (
          <ContactDialog
            history={history}
            contact={openContactDialog}
            open={Boolean(openContactDialog)}
            handleClose={() => this.handleDialogClose('openContactDialog')}
          />
        ) : null}
        {openOfferDialog ? (
          <OfferDialog
            history={history}
            offer={openOfferDialog}
            open={Boolean(openOfferDialog)}
            handleClose={() => this.handleDialogClose('openOfferDialog')}
          />
        ) : null}
        {openAssigneeDialog ? (
          <AssigneeDialog
            history={history}
            member={openAssigneeDialog}
            open={Boolean(openAssigneeDialog)}
            handleClose={() => this.handleDialogClose('openAssigneeDialog')}
          />
        ) : null}
        {openContractDialog ? (
          <ContractDialog
            history={history}
            contract={openContractDialog}
            open={Boolean(openContractDialog)}
            handleClose={() => this.handleDialogClose('openContractDialog')}
          />
        ) : null}
        {addContract ? (
          <ContractEdit
            open={addContract}
            sourceProperty={property}
            onSubmitted={({id}) => history.push(`/contracts/${id}`)}
            onClose={() => this.setState({addContract: false})}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const styles = () => ({
  itemText: {},
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
});

export default connect(
  (state, ownProps) => ({
    formatPrice: createPriceFormatter(state.root.classification.priceTypes, state.root.classification.currencyCodes),
    formatArea: createAreaFormatter(state.root.classification.areaUnits),
    offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
    propertyView: getScreensView(state.root.property.screens, ownProps.location.key),
    properties: state.root.property.properties,
    offers: state.root.offer.offers,
  }),
  {
    addOfferToProperty,
    loadProperty,
    openLightbox,
    updateProperty,
    setPropertyComment,
  },
)(withStyles(styles)(PropertyView));
