import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';

import OfferListItem from '../../../components/relations/OfferListItem';
import {updateOffer} from '../../../actions';

class Offer extends Component {
  state = {
    error: null,
    loading: false,
    openMenu: null,
    removeOffer: null,
    openStatusMenu: null,
  };

  componentDidMount() {
    this.reload();
  }

  reload() {
    this.setState({
      loading: true,
    });
    return axios.get(`/api/offers/${this.props.dealOffer.offer.id}`)
      .then(resp => {
        this.props.updateOffer(resp.data);
      })
      .catch(() => {
        this.setState({
          error: true,
        });
      })
      .then(() => {
        this.setState({loading: false});
      });
  }

  openSetStatusForm = (target, dealOffer) => {
    this.setState({
      openMenu: null,
      openStatusMenu: {target, dealOffer},
    });
  };

  render() {
    const {
      handleDialogOpen,
      offers,
    } = this.props;

    const {
      error,
      loading,
    } = this.state;

    const offer = offers[this.props.dealOffer.offer.id];

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Листинг
          <ListItemSecondaryAction>
            {loading ? (
              <IconButton disabled>
                <CircularProgress size={24}/>
              </IconButton>
            ) : (
              error ? (
                <IconButton disabled>
                  <icons.ErrorOutline/>
                </IconButton>
              ) : null
            )}
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {offer ? (
          <OfferListItem
            key={offer.id}
            offer={offer}
            handleDialogOpen={e => handleDialogOpen(e)}
          />
        ) : (error ? (
          <IconButton disabled>
            <icons.ErrorOutline/>
          </IconButton>
        ) : (
          <IconButton disabled>
            <CircularProgress size={24}/>
          </IconButton>
        ))}

      </List>
    );
  }
}

const styles = () => ({
  statusIcon: {
    display: 'inline-block',
    lineHeight: 1,
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 4,
  },
});

Offer = connect(
  state => ({
    dealOfferStatuses: state.root.classification.dealOfferStatuses,
    offers: state.root.offer.offers,
  }), {updateOffer}
)(Offer);

export default withRouter(withStyles(styles)(Offer));
