import React, {Component} from 'react';
import {connect} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as icons from '@material-ui/icons';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';

import {queryContractAxios} from '../../queries/ContractsQuery';
import PropertyList from '../property/PropertyList';
import PropertyListItems from '../property/PropertyListItems';

class ContractContacts extends Component {
  state = {
    openItemMenu: null,
    currentItem: null,
    contactSet: null,
    selectedItems: null,
  };

  componentDidMount() {
    this.load();
  }

  load() {
    const {match} = this.props;
    let contractId = match.params.contractId;
    queryContractAxios({id: contractId})
      .then(res => {
        const contractProperties = res.data.data.contract.properties;
        let selectedItems = [];
        if (contractProperties) {
          selectedItems = contractProperties.map(item => item.id);
        }
        this.setState({selectedItems});
      })
      .catch(() => {
        alert('Произошла ошибка');
      });
  }

  render() {
    const {match} = this.props;
    const {
      currentItem,
      openItemMenu,
      contactSet,
      selectedItems,
    } = this.state;

    return (
      <React.Fragment>
        <PropertyList
          history={this.props.history}
          match={match}
          location={this.props.location}
          selectedItems={selectedItems}
          action={(item, selected) => (
            <IconButton 
              disabled={selected}
              onClick={e => {
                this.setState({
                  openItemMenu: e.currentTarget,
                  currentItem: item,
                });
                e.stopPropagation();
              }}>
              <icons.MoreVert/>
            </IconButton>
          )}
        >
          <PropertyListItems/>
        </PropertyList>
        <Menu
          anchorEl={openItemMenu}
          open={Boolean(openItemMenu)}
          onClick={() => this.setState({openItemMenu: null})}
        >
          <MenuItem
            onClick={() => this.submit({
              contractId: match.params.contractId,
              id: currentItem.id,
            })}
          >
            Добавить объект
          </MenuItem>
        </Menu>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(contactSet)}
          autoHideDuration={4000}
          onClose={() => this.setState({contactSet: false})}
          message="Объект добавлен"
          action={
            <IconButton
              color="inherit"
              onClick={() => this.setState({contactSet: false})}
            >
              <icons.Close/>
            </IconButton>
          }
        />
      </React.Fragment>
    );
  }

  submit = data => {
    return axios.post('/api/v1/addPropertyContract', data)
      .then(resp => {
        if (resp.status === 200) {
          this.setState({contactSet: true, selectedItems: [...this.state.selectedItems, this.state.currentItem.id]});
        } 
      })
      .catch(error => {
        if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

export default connect(null)(ContractContacts);
