import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import {set} from 'lodash';
import axios from 'axios';

import TextField from '../../../components/Input/TextField';
import MenuItem from '@material-ui/core/MenuItem';

class DeclineDealOfferForm extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      open,
      pristine,
      dealOfferDeclineReasons,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Закрытие сделки</DialogTitle>
        <Divider/>
        <DialogContent>
          <Field
            name="reason"
            label="Причина"
            component={TextField}
            select
            // SelectProps={{
            //   multiple: true,
            //   renderValue: value => getLabelsForValue(teamSpecializations, value).join(', '),
            // }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {dealOfferDeclineReasons.map(({label, value}, i) => <MenuItem value={value} key={i}>{label}</MenuItem>)}
          </Field>
          <Field
            name="comment"
            label="Подробный комментарий"
            component={TextField}
            fullWidth
            margin="normal"
            multiline
            rowsMax={10}
          />
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(this.submit)}
          >
            Закрыть сделку
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = data => {
    const {comment, reason} = data;

    return axios.post('/api/v1/dealOfferDecline', {id: this.props.dealOffer.id, comment, reason})
      .then(res => {
        if (res.status === 200) {
          this.props.onSubmit(data);
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(error.response.data.error.errors.reduce((errors, {propertyPath, message}) => {
            return set(errors, propertyPath, message);
          }, {}));
        } else {
          alert('Ошибка при сохранении');
        }
      });
  };
}

DeclineDealOfferForm = connect(
  state => ({
    dealOfferDeclineReasons: state.root.classification.dealOfferDeclineReasons,
  })
)(DeclineDealOfferForm);

export default reduxForm({
  form: 'decline_form',
  enableReinitialize: true,
})(DeclineDealOfferForm);
