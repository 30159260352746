import axios from 'axios';
import {set} from 'lodash';
import React, {Component} from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import {Checkbox} from 'redux-form-material-ui';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '../../components/Input/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {connect} from 'react-redux';

class SemanticsItemEdit extends Component {
  componentDidMount() {
    if (this.props.item) {
      this.props.initialize({
        ...this.props.item,
      });
    } else {
      this.props.initialize({
        archived: false,
      });
    }
  }

  render() {
    const {
      classes,
      handleSubmit,
      initialized,
      open,
      pristine,
      submitting,
      onClose,
      item,
      propertyTypes,
      teams,
      isAdmin,
    } = this.props;

    if (!open || !initialized) {
      return null;
    }

    return (
      <Dialog open={open} classes={{paper: classes.dialog}}>
        <DialogTitle>
          {item ? 'Изменить' : 'Добавить'}
          <IconButton className={classes.closeButton} onClick={() => onClose()}>
            <icons.Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Field
            name="type"
            label="Категория"
            component={TextField}
            select
            required
            fullWidth
            margin="normal"
            disabled={!!item}
          >
            {propertyTypes.map((type, i) => {
              return (
                <MenuItem value={type.value} key={i}>
                  {type.label}
                </MenuItem>
              );
            })}
          </Field>
          <Field
            name="lookingFor"
            label="Возможно вы искали"
            component={TextField}
            fullWidth
            margin="normal"
            multiline
            rowsMax={5}
          />
          <Field
            name="purposes"
            label="Подойдет под"
            component={TextField}
            fullWidth
            margin="normal"
            multiline
            rowsMax={5}
          />
          {
            isAdmin ? (
              <Field
                name="team"
                label="Команда"
                component={TextField}
                select
                margin="normal"
                fullWidth
              >
                <MenuItem value="">Для всех команд</MenuItem>
                {teams.map((team, index) => (
                  <MenuItem key={index} value={team.id}>{team.name}</MenuItem>
                ))}
              </Field>
            ) : null
          }
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Field name="archived" component={Checkbox} />}
              label={'В архиве'}
            />
          </FormControl>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button type="button" disabled={submitting} onClick={() => onClose()}>
            Отменить
          </Button>
          <Button
            type="button"
            disabled={pristine || submitting}
            onClick={handleSubmit(this.submit)}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = values => {
    const {item, onSubmitted} = this.props;
    let url = '/api/v1/createReferenceSemantics';

    if (item) {
      url = '/api/v1/updateReferenceSemantics';
    }

    return axios
      .post(url, values)
      .then(() => onSubmitted())
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(
            error.response.data.error.errors.reduce(
              (errors, {propertyPath, message}) => {
                return set(errors, propertyPath, message);
              },
              {}
            )
          );
        } else if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else if (error.response.status === 409) {
          alert(error.response.data.error);
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

const styles = theme => ({
  dialog: {
    width: 550,
    maxWidth: '100%',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100%',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

SemanticsItemEdit = connect(state => ({
  propertyTypes: state.root.classification.propertyTypes,
}))(SemanticsItemEdit);

export default reduxForm({
  form: 'semanticsItemEdit',
})(withStyles(styles)(SemanticsItemEdit));
