import React, {Component} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class CheckboxInput extends Component {
  state = {
    checked: this.props.checked,
  };

  onCheck = () => {
    this.props.onChangeValue(!this.state.checked);
    this.setState({checked: !this.state.checked});
  };

  render() {
    const {label} = this.props;
    const {checked} = this.state;
    return (
      <FormControlLabel
        control={<Checkbox checked={checked}/>}
        onClick={this.onCheck.bind(this)}
        label={label}
      />
    );
  }
}

export default CheckboxInput;
