import {connect} from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import {hasRole} from '../utils/roleFunc';
import * as userRoles from '../constants/userRoles';

const AccessComponent = props => {
  const {
    selfUser, 
    children,
    condition,
    admin,
    manager,
    managerOnly,
    member,
  } = props;

  let access = false;

  if (admin && hasRole(selfUser.role, userRoles.ADMIN)) {
    access = true;
  } else if (manager && (hasRole(selfUser.role, userRoles.MANAGER) || hasRole(selfUser.role, userRoles.ADMIN))) {
    access = true;
  } else if (managerOnly && (hasRole(selfUser.role, userRoles.MANAGER))) {
    access = true;
  } else if (member && hasRole(selfUser.role, userRoles.MEMBER)) {
    access = true;
  } else if (condition) {
    access = true;
  }
  
  return (
    access ? (
      <React.Fragment>
        {children}
      </React.Fragment>
    ) : null    
  );
};

AccessComponent.propTypes = {
  admin: PropTypes.bool,
  manager: PropTypes.bool,
  managerOnly: PropTypes.bool,
  member: PropTypes.bool,
  condition: PropTypes.bool,
};

AccessComponent.defaultProps = {
  admin: false,
  manager: false,
  managerOnly: false,
  member: false,
  condition: false,
};

export default connect(
  ({root}) => ({
    selfUser: root.selfUser,
  })
)(AccessComponent);
