import * as colors from '@material-ui/core/colors/index';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import Photos from './Photos';
import TextFieldCount from '../../../components/Input/TextFieldWithCount';
import {Field} from 'redux-form';
class ResultsForm extends Component {
  render() {
    const {
      array: {push, remove, swap},
      array,
      photos,
    } = this.props;
    return (
      <React.Fragment>
        <Photos
          name="photos"
          label="Фотография"
          array={array}
          photos={photos}
          push={(name, value) => push(name, value)}
          remove={(name, value) => remove(name, value)}
          swap={(name, oldIndex, newIndex) => swap(name, oldIndex, newIndex)}
        />
        <Field
          name="comment"
          label="Комментарий"
          multiline
          component={TextFieldCount}
          width={'100%'}
          max={1000}
          fullWidth
          margin="normal"
        />
      </React.Fragment>
    );
  }
}

const styles = {
  help: {
    color: colors.grey[700],
    marginLeft: 12,
    marginBottom: 8,
  },
  MenuItemContainer: {
    zIndex: 1,
    position: 'absolute',
    width:'100%',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  MenuItemWrapper: {
    position: 'relative',
  },
};

export default connect(null,{})(withStyles(styles)(ResultsForm));
