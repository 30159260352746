import React, {Component} from 'react';
import {connect} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles/index';
import * as icons from '@material-ui/icons';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';

import {closeLightbox, nextImageInLightbox, prevImageInLightbox} from '../actions';
import {getImageUrl, ImageFilters} from '../utils';
import {downloadPhoto} from '../utils/photoFunc';

class Lightbox extends Component {
  componentWillMount() {
    this.lightboxListen = this.props.history.listen(() => {
      this.props.closeLightbox();
    });
  }

  componentWillUnmount() {
    this.lightboxListen();
  }

  render() {
    const {
      classes,
      show,
      images,
      currentIndex,
      nextImageInLightbox,
      prevImageInLightbox,
      closeLightbox,
    } = this.props;

    const path = images[currentIndex];
    if (!path) {
      return null;
    }
    let url = getImageUrl({filter: ImageFilters.resize, width: 800, height: 600, path});
    let downloadPhotoUrl = `/uploads/${path}`;
    return (
      <Modal
        open={show}
        onClose={() => closeLightbox()}
        classes={{root: classes.modal}}
      >
        <div className={classNames(classes.root, {[classes.visible]: show})}>
          <div className={classes.top}>
            <IconButton style={{color: 'white'}} onClick={() => downloadPhoto(downloadPhotoUrl, currentIndex + 1)} >
              <icons.ArrowDownward/>
            </IconButton>
            <IconButton onClick={() => closeLightbox()} style={{color: 'white'}}>
              <icons.Close/>
            </IconButton>
          </div>
          <div
            className={classes.image}
            style={{
              backgroundImage: `url(${url})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center center',
            }}
          >
            <div className={classes.prev} onClick={() => prevImageInLightbox()}/>
            <div className={classes.next} onClick={() => nextImageInLightbox()}/>
          </div>
          <Typography className={classes.bottom} onClick={() => closeLightbox()}>
            {currentIndex + 1}/{images.length}
          </Typography>
        </div>
      </Modal>
    );
  }
}

const styles = () => ({
  modal: {
    display: 'flex',
  },
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    visibility: 'hidden',
  },
  visible: {
    visibility: 'visible',
  },
  top: {
    padding: 12,
    textAlign: 'right',
  },
  bottom: {
    textAlign: 'center',
    color: 'white',
    padding: 12,
    userSelect: 'none',
  },
  image: {
    flex: 1,
    position: 'relative',
  },
  prev: {
    position: 'absolute',
    left: 0,
    width: '50%',
    height: '100%',
    cursor: 'pointer',
  },
  next: {
    position: 'absolute',
    right: 0,
    width: '50%',
    height: '100%',
    cursor: 'pointer',
  },
});

export default withRouter(connect(
  state => state.root.lightbox,
  {
    closeLightbox,
    nextImageInLightbox,
    prevImageInLightbox,
  }
)(withStyles(styles)(Lightbox)));
