import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as icons from '@material-ui/icons';

import {deleteAssigneeFromContact} from '../../../actions';
import {mapLabels} from '../../../utils';
import PropertyListItem from '../../../components/relations/PropertyListItem';

class Properties extends Component {
  render() {
    const {
      contact,
      history,
      properties,
      handleDialogOpen,
    } = this.props;

    if (!contact) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Объекты
          <ListItemSecondaryAction>
            <IconButton onClick={() => history.push(`/contacts/${contact.id}/properties`)}>
              <icons.Add/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {contact.properties ? contact.properties.map(id => {
          const property = properties[id];
          return property ? (
            <PropertyListItem 
              key={id}
              property={property}
              handleDialogOpen={e => handleDialogOpen(e)}
            />
          ) : null;
        }) : null}
      </List>
    );
  }
}

Properties = connect(
  state => ({
    properties: state.root.property.properties,
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
  }),
  {
    deleteAssigneeFromContact,
  }
)(Properties);

export default withRouter(Properties);
