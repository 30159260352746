import React, {Component} from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import PropertyDetails from './PropertyDetails';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as icons from '@material-ui/icons';
import {mapLabels} from '../../utils';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

class ContactDialog extends Component {
  render() {
    const {
      classes,
      propertyTypeLabels,
      handleClose,
      open,
      properties,
    } = this.props;

    let property = properties[this.props.property];

    const formatAddress = address => {
      return address;
    };

    return (
      <Dialog 
        open={open}
        onClose={handleClose}
        classes={{paper: classes.dialog}}
      > 
        {property ? (
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle}>
              <div className={classes.dialogTitleWrap}>
                <div className={classes.dialogText}>
                  <Typography variant="h6">{propertyTypeLabels[property.type.value] || property.type.value}</Typography>
                  <Typography variant="subtitle1">{formatAddress(property.address.value)}</Typography>
                </div>
                <div className={classes.dialogBtn}>
                  <IconButton
                    component={Link}
                    to={`/properties/${property.id}`}
                    classes={{root: classes.dialogIcon}}
                  >
                    <icons.OpenInNew/>
                  </IconButton>
                  <IconButton
                    onClick={handleClose}
                    classes={{root: classes.dialogIcon}}
                  >
                    <icons.Close/>
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <Divider/>
            <DialogContent className={classes.dialogContent}>
              <PropertyDetails property={property} />
            </DialogContent>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle}>
              <div className={classes.dialogTitleWrap}>
                <div className={classes.dialogText}>
                  <Typography variant="h6">Не удалось загрузить объект</Typography>
                </div>
                <div className={classes.dialogBtn}>
                  <IconButton
                    onClick={handleClose}
                    classes={{root: classes.dialogIcon}}
                  >
                    <icons.Close/>
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <Divider/>
            <DialogContent className={classNames(classes.dialogContent, classes.flexWrap)}>
              <icons.ErrorOutline/>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialog: {
    width: 550,
    maxWidth: '100%',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100%',
    },
  },
  dialogIcon: {
    width: 36,
    height: 36,
    padding: 4,
  },
  dialogTitleWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 0,
    minHeight: 150,
  },
  dialogTitle: {
    padding: '12px 24px 8px 24px',
  },
  dialogBtn: {
    marginRight: -12,
    minWidth: 80,
  },
  flexWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});


export default connect(
  state => ({
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    properties: state.root.property.properties,
  }),
)(withStyles(styles)(ContactDialog));
