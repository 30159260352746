import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {connect} from 'react-redux';
import {createAreaFormatter, createPriceFormatter, formatAddressListItem, mapLabels} from '../../utils';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';

class OfferListItem extends Component {
  render() {
    const {
      offer,
      onSecondaryActionClick,
      formatPrice,
      formatArea,
      offerTypeLabels,
      offerStatusColors,
      offerStatusLabels,
      disabled,
      propertyTypeLabels,
      showAddress,
    } = this.props;

    let listItemProps = {};

    if (this.props.handleDialogOpen) {
      listItemProps = {
        onClick: () => this.props.handleDialogOpen(offer.id),
      };
    } else {
      listItemProps = {

      };
    }
    return (
      <ListItem
        dense
        button
        disabled={disabled}
        component={Link}
        to={`/offers/${offer.id}`}
        {...listItemProps}
      >
        <Avatar style={{backgroundColor: colors.green[300]}}>
          <icons.ViewList/>
        </Avatar>
        {offer ? (
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body1">
                {propertyTypeLabels[offer.property.type.value] || offer.property.type.value}{', '} {formatArea(offer.property.area)}
              </Typography>
            }
            secondary={
              <React.Fragment>
                {showAddress ? (
                  <Typography variant="caption" noWrap>
                    {formatAddressListItem(offer.property.address)}
                  </Typography>
                ) : null}
                <Typography variant="body2">
                  <span style={{background: (offerStatusColors[offer.status] || {100: colors.white})[100], padding: '2px 5px'}}>
                    {offerStatusLabels[offer.status] || offer.status}
                  </span>
                  <span style={{marginLeft: 10}}>
                    {offerTypeLabels[offer.type] || offer.type}{', '} {formatPrice(offer.price)}
                  </span>
                </Typography>
              </React.Fragment>
            }
          />
        ) : null}
        {onSecondaryActionClick ? (
          <ListItemSecondaryAction>
            <IconButton onClick={onSecondaryActionClick}>
              <icons.MoreVert/>
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    );
  }
}

OfferListItem = connect(
  state => ({
    formatPrice: createPriceFormatter(state.root.classification.priceTypes, state.root.classification.currencyCodes),
    formatArea: createAreaFormatter(state.root.classification.areaUnits),
    offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
    offerStatusColors: mapLabels(state.root.classification.offerStatuses, 'value', 'color'),
    offerStatusLabels: mapLabels(state.root.classification.offerStatuses, 'value', 'label'),
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
  }),
)(OfferListItem);

export default OfferListItem;
