import React, {Component} from 'react';
import {connect} from 'react-redux';
import {queryDealList} from '../../queries';
import base64url from '../../utils/base64url';
import {getSection} from '../../utils/lastSection';
import DealList from './DealList';
import DealListItems from './DealListItems';
import FavoritesWrapper from '../../is/favorites/containers/FavoritesWrapper';
import {setTitle} from '../../actions';

class DealListWrapper extends Component {
  state = {
    deals: [],
    loading: false,
    error: false,
    totalCount: 0,
    query: {},
  };

  componentDidMount() {
    this.props.setTitle('Заявки');
    if(this.props.settingsStatus)
      this.search(this.props.location, this.props.dealListSettings);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.search(nextProps.location, nextProps.dealListSettings);
    }
    if(nextProps.settingsStatus && nextProps.settingsStatus !== this.props.settingsStatus){
      this.search(nextProps.location, nextProps.dealListSettings);
    }
  }

  search(location, settings) {
    let query = {
      offset: 0,
      limit: settings.page || 30,
    };
    const qs = location.search.substr(1);
    if (qs.length > 0) {
      try {
        query = JSON.parse(base64url.decode(qs));
      } catch (_) {
      }
    }
    if(!query.source){
      let source = getSection('dealsSource');
      if(source)
        query.source = source;
    }
    this.setState({loading: true, query});
    queryDealList(query)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            loading: false,
            error: false,
            deals: resp.data.data.deals.items,
            totalCount: resp.data.data.deals.totalCount,
          });
        } else {
          this.setState({loading: false, error: true});
        }
      })
      .catch(() => {
        this.setState({loading: false, error: true});
      });
  }

  render() {
    const {deals, loading, error, totalCount, query} = this.state;

    return (
      <DealList
        query={query}
        deals={deals}
        loading={loading}
        error={error}
        totalCount={totalCount}
        location={this.props.location}
        match={this.props.match}
        history={this.props.history}
        perPage={this.props.dealListSettings.page || 30}
      >
        {deals ? (
          <FavoritesWrapper items={deals.map(deal => deal.id)}>
            <DealListItems/>
          </FavoritesWrapper>
        ) : null}
      </DealList>
    );
  }
}

export default connect(
  state => ({
    dealListSettings: state.root.settings.dealList,
    settingsStatus: state.root.settings.load,
  }), {setTitle}
)(DealListWrapper);
