import axios from 'axios';
import {ListItem, ListItemText} from '@material-ui/core';
import List from '@material-ui/core/List';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import {Query, withApollo} from 'react-apollo';
import gql from 'graphql-tag';

import TaskDetailsQuery from '../../../queries/TaskDetailsQuery';
import ActivityEventsList from '../../../components/ActivityEventsList';
import CommentArea from '../components/CommentArea';
import ControlButton from '../components/ControlButton';
import EditableAssignee from '../components/EditableAssignee';
import EditableDeadline from '../components/EditableDeadline';
import EditableDescription from '../components/EditableDescription';
import EditableTitle from '../components/EditableTitle';
import Members from '../components/Members';
import Documents from '../components/Documents';
import {mapLabels} from '../../../utils';
import {hasRole} from './../../../utils/roleFunc';
import * as userRoles from '../../../constants/userRoles';

class TaskView extends Component {
  updateDescription = description => {
    const {client, match, id} = this.props;

    return axios.post('/api/v1/updateTask', {
      taskId: id || match.params.id,
      description,
    })
      .then(() => {
        client.writeFragment({
          id: id || match.params.id,
          fragment: gql`
            fragment updatedDescriptionTask on Task {
              description
            }
          `,
          data: {
            description,
          },
        });
      })
      .then(() => this.activityEventsList.updateActivity());
  };

  updateTitle = title => {
    const {client, match, id} = this.props;

    return axios.post('/api/v1/updateTask', {
      taskId: id || match.params.id,
      title,
    })
      .then(() => {
        client.writeFragment({
          id: id || match.params.id,
          fragment: gql`
            fragment updatedTitleTask on Task {
              title
            }
          `,
          data: {
            title,
          },
        });
      })
      .then(() => this.activityEventsList.updateActivity());
  };

  reassign = assigneeId => {
    const {client, match, id} = this.props;

    return axios.post('/api/v1/reassignTask', {
      taskId: id || match.params.id,
      assigneeId,
    })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                assignee {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(() => this.activityEventsList.updateActivity());
  };

  addCoworker = coworkerId => {
    const {client, match, id} = this.props;

    return axios.post('/api/v1/addCoworkerToTask', {
      taskId: id || match.params.id,
      coworkerId,
    })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                coworkers {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(() => this.activityEventsList.updateActivity());
  };

  removeCoworker = coworkerId => {
    const {client, match, id} = this.props;

    return axios.post('/api/v1/removeCoworkerFromTask', {
      taskId: id || match.params.id,
      coworkerId,
    })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                coworkers {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(() => this.activityEventsList.updateActivity());
  };

  addObserver = observerId => {
    const {client, match, id} = this.props;

    return axios.post('/api/v1/addObserverToTask', {
      taskId: id || match.params.id,
      observerId,
    })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                observers {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(() => this.activityEventsList.updateActivity());
  };

  removeObserver = observerId => {
    const {client, match, id} = this.props;

    return axios.post('/api/v1/removeObserverFromTask', {
      taskId: id || match.params.id,
      observerId,
    })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                observers {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(() => this.activityEventsList.updateActivity());
  };

  addDocument = data => {
    const {client, match, id} = this.props;

    return axios.post('/api/v1/addDocumentToTask', {
      taskId: id || match.params.id,
      document: data.document,
    })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                documents {
                  fileName
                  fileSize
                  mimeType
                  name
                  path
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(() => this.activityEventsList.updateActivity());
  };

  removeDocument = path => {
    const {client, match, id} = this.props;

    return axios.post('/api/v1/removeDocumentFromTask', {
      taskId: id || match.params.id,
      path: path,
    })
      .then(() => {
        return client.query({
          query: gql`
            query UpdatedTask($id: ID!) {
              task(id: $id) {
                id
                documents {
                  fileName
                  fileSize
                  mimeType
                  name
                  path
                }
              }
            }
          `,
          variables: {
            id: id || match.params.id,
          },
          fetchPolicy: 'network-only',
        });
      })
      .then(() => this.activityEventsList.updateActivity());
  };

  reschedule = deadline => {
    const {client, match, id} = this.props;

    return axios.post('/api/v1/rescheduleTask', {
      taskId: id || match.params.id,
      deadline,
    })
      .then(() => {
        client.writeFragment({
          id: id || match.params.id,
          fragment: gql`
            fragment rescheduledTask on Task {
              deadline
            }
          `,
          data: {
            deadline,
          },
        });
      })
      .then(() => this.activityEventsList.updateActivity());
  };

  close = () => {
    const {client, match, id} = this.props;

    return axios.post('/api/v1/closeTask', {
      taskId: id || match.params.id,
    })
      .then(() => {
        client.writeFragment({
          id: id || match.params.id,
          fragment: gql`
            fragment closedTask on Task {
              closed
            }
          `,
          data: {
            closed: true,
          },
        });
      })
      .then(() => this.activityEventsList.updateActivity());
  };

  addComment = comment => {
    const {/*client, */match, id} = this.props;

    return axios.post('/api/v1/addCommentToTask', {
      taskId: id || match.params.id,
      comment,
    })
      .then(() => this.activityEventsList.updateActivity());
  };

  reopen = () => {
    const {client, match, id} = this.props;

    return axios.post('/api/v1/reopenTask', {
      taskId: id || match.params.id,
    })
      .then(() => {
        client.writeFragment({
          id: match.params.id,
          fragment: gql`
            fragment reopenedTask on Task {
              closed
            }
          `,
          data: {
            closed: false,
          },
        });
      })
      .then(() => this.activityEventsList.updateActivity());
  };

  render() {
    const {
      classes,
      match,
      teamMembers,
      propertyTypes,
      contractTypes,
      selfUser,
      id,
      handleClose,
    } = this.props;

    return (
      <Dialog open={true} maxWidth="sm" fullWidth classes={{paper: classes.dialog}}>
        <Query query={TaskDetailsQuery} variables={{id: id || match.params.id}}>
          {({data, loading, error}) => {
            if (loading) {
              return null;
            }

            if (error) {
              return null;
            }

            return (
              <React.Fragment>
                <DialogTitle className={classes.dialogTitle}>
                  <EditableTitle
                    title={data.task.title}
                    onSave={value => this.updateTitle(value)}
                    editable={data.task.permissions.canUpdateTask}
                  />
                  <IconButton
                    className={classes.closeButton}
                    onClick={() => {
                      /*if (history.length > 0) {
                        history.goBack();
                      } else {
                        history.replace('/');
                      }*/
                      handleClose && handleClose();
                      window.location.href = '#/';
                    }}
                  >
                    <icons.Close/>
                  </IconButton>
                </DialogTitle>
                <Divider/>
                <DialogContent style={{padding: 0}}>
                  <List>
                    <EditableDeadline
                      deadline={data.task.deadline}
                      onSave={value => this.reschedule(value)}
                      editable={data.task.permissions.canRescheduleTask}
                    />
                    <EditableDescription
                      description={data.task.description ? data.task.description : ''}
                      onSave={value => this.updateDescription(value)}
                      editable={data.task.permissions.canUpdateTask}
                    />
                    <EditableAssignee
                      assignee={data.task.assignee}
                      members={teamMembers}
                      onSave={value => this.reassign(value)}
                      editable={data.task.permissions.canReassignTask}
                    />
                    {data.task.assignee.id !== data.task.author.id ? (
                      <ListItem>
                        <ListItemText
                          primary={data.task.author.name}
                          secondary="Постановщик"
                        />
                      </ListItem>
                    ) : null}
                    <Members
                      label="Соисполнители"
                      members={data.task.coworkers}
                      teamMembers={teamMembers}
                      onAdd={this.addCoworker}
                      onRemove={this.removeCoworker}
                      editable={
                        data.task.permissions.canAddCoworkerToTask &&
                        data.task.permissions.canRemoveCoworkerFromTask
                      }
                    />
                    <Members
                      label="Наблюдатели"
                      members={data.task.observers}
                      teamMembers={teamMembers}
                      onAdd={this.addObserver}
                      onRemove={this.removeObserver}
                      editable={
                        (data.task.permissions.canAddObserverToTask &&
                        data.task.permissions.canRemoveObserverFromTask) ||
                        (hasRole(selfUser.role, userRoles.MANAGER) || hasRole(selfUser.role, userRoles.ADMIN))
                      }
                    />
                  </List>
                  <div style={{padding: '0 24px 16px 24px'}}>
                    {data.task.entities.map(entity => {
                      let href;
                      let label;
                      let title;
                      switch (entity.__typename) {
                        case 'Contact':
                          href = `/contacts/${entity.id}`;
                          label = 'Контакт';
                          title = entity.name;
                          break;
                        case 'Deal':
                          href = `/deals/${entity.id}`;
                          label = 'Заявка';
                          title = propertyTypes[entity.propertyType];
                          break;
                        case 'Offer':
                          href = `/offers/${entity.id}`;
                          label = 'Листинг';
                          title = entity.name;
                          break;
                        case 'Property':
                          href = `/properties/${entity.id}`;
                          label = 'Объект';
                          title = entity.address.value;
                          break;
                        case 'DealOffer':
                          href = `/dealOffers/${entity.id}`;
                          label = 'Сделка';
                          title = entity.offer.property.address.value;
                          break;
                        case 'Contract':
                          href = `/contracts/${entity.id}`;
                          label = 'Договор';
                          title = `${entity.no}, ${contractTypes[entity.type]}`;
                          break;
                        default:
                          return null;
                      }
                      return (
                        <Chip
                          key={entity.id}
                          component="a"
                          target="_blank"
                          href={href}
                          style={{cursor: 'pointer', textDecoration: 'none'}}
                          label={label}
                          title={title}
                        />
                      );
                    })}
                  </div>
                  <ControlButton
                    closed={data.task.closed}
                    onClose={() => this.close()}
                    onReopen={() => this.reopen()}
                    permissions={data.task.permissions}
                  />
                  <CommentArea
                    onSave={value => this.addComment(value)}
                    permissions={data.task.permissions}
                  />
                  <Documents
                    task={data.task}
                    onAddDocument={data => this.addDocument(data)}
                    onRemoveDocument={data => this.removeDocument(data)}
                  />
                  <ActivityEventsList
                    entityId={id || match.params.id}
                    onRef={ref => (this.activityEventsList = ref)}
                    aggregateType="task"
                  />
                </DialogContent>
              </React.Fragment>
            );
          }}
        </Query>
      </Dialog>
    );
  }
}

const styles = () => ({
  dialogTitle: {
    paddingRight: 64,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  actionButtonsWrapper: {
    padding: '0 18px',
    display: 'flex',
    flexDirection: 'row',
  },
  actionButton: {
    margin: 6,
  },
  commentTextFieldWrapper: {
    padding: '0 24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
  },
  saveIconButton: {
    marginBottom: -8,
    marginRight: -12,
    marginLeft: 12,
  },
  authorLink: {
    marginLeft: 6,
  },
});

export default connect(
  state => ({
    teamMembers: state.root.selfUser.teamMembers,
    propertyTypes: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    contractTypes: mapLabels(state.root.classification.contractTypes, 'value', 'label'),
    selfUser: state.root.selfUser,
  }),
)(withApollo(withStyles(styles)(TaskView)));
