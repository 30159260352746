import React, {Component} from 'react';
import ContactList from './ContactList';
import ContactListItems from './ContactListItems';
import FavoritesWrapper from '../../is/favorites/containers/FavoritesWrapper';
import {connect} from 'react-redux';

class ContactListWrapper extends Component {
  render() {
    const {contacts} = this.props;

    return (
      <ContactList>
        {contacts ? (
          <FavoritesWrapper items={Object.keys(contacts)}>
            <ContactListItems/>
          </FavoritesWrapper>
        ) : null}
      </ContactList>
    );
  }
}

ContactListWrapper = connect(
  state => ({
    contacts: state.root.contact.contacts,
  }),
  {}
)(ContactListWrapper);

export default ContactListWrapper;
