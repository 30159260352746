import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {createAreaFormatter, formatAddressListItem, mapLabels} from '../../utils';

class DealOfferListItem extends Component {
  render() {
    const {
      dealOffer,
      propertyTypeLabels,
      dealOfferStatuses,
      formatArea,
      dealOfferColors,
      onSecondaryActionClick,
      classes,
      color,
    } = this.props;

    let listItemProps = {};

    if (this.props.handleDialogOpen) {
      listItemProps = {
        onClick: e => {
          e.preventDefault();
          this.props.handleDialogOpen();
        },
      };
    }

    return (
      <ListItem
        dense
        button
        component={Link}
        to={this.props.linkTo}
        {...listItemProps}
      >
        <Avatar style={{backgroundColor: color ? color : colors.green[300]}}>
          {color ? <icons.CardTravelOutlined/> : <icons.ViewList/>}
        </Avatar>
        {dealOffer ? (
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body1" className={classes.textLine}>
                {propertyTypeLabels[dealOffer.offer.property.type.value] || dealOffer.offer.property.type.value}{', '}
                {formatArea(dealOffer.offer.property.area)}
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography variant="caption" noWrap>
                  {formatAddressListItem(dealOffer.offer.property.address)}
                </Typography>
                <Typography variant="body2" className={classes.textLine}>
                  <span className={classes.offerStatus} style={{background: (dealOfferColors[dealOffer.status] || {100: colors.white})[100]}}>
                    {dealOfferStatuses[dealOffer.status] || dealOffer.status}
                  </span>
                  {dealOffer.incoming ? (
                    <span className={classes.incomingTag}>Входящее обращение</span>
                  ) : dealOffer.createdBy ? (
                    <span className={classes.createdByTag}>{dealOffer.createdBy.name}</span>
                  ) : null}
                </Typography>
              </React.Fragment>
            }
          />
        ) : null}
        {onSecondaryActionClick ? (
          <ListItemSecondaryAction>
            <IconButton onClick={onSecondaryActionClick}>
              <icons.MoreVert/>
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    );
  }
}

const styles = () => ({
  incomingTag: {
    color: colors.red[800],
    padding: '0 4px',
    display: 'inline-block',
  },
  createdByTag: {
    color: colors.blue[800],
    padding: '0 4px',
    display: 'inline-block',
  },
  offerStatus: {
    marginRight: 8,
    padding: '0 4px',
    display: 'inline-block',
  },
  textLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});

DealOfferListItem = connect(
  state => ({
    formatArea: createAreaFormatter(state.root.classification.areaUnits),
    dealOfferStatuses: mapLabels(state.root.classification.dealOfferStatuses, 'value', 'label'),
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    dealOfferColors: mapLabels(state.root.classification.dealOfferStatuses, 'value', 'color'),
  }),
)(DealOfferListItem);

export default withStyles(styles)(DealOfferListItem);
