import React, {Component} from 'react';
import {setTitle} from '../../actions';
import {connect} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';

import AnalyticsPage from './AnalyticsPage';
import DealStatistic from './DealStatistic';
import RequestStatistic from './RequestStatistic';

class AnalyticsApp extends Component {
  componentDidMount() {
    this.props.setTitle('Аналитика');
  }

  render() {
    const {match, selfUser} = this.props;

    return (
      <Switch>
        {selfUser.role === 'ROLE_ADMIN' ? (
          <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />
        ) : (<Redirect exact from={`${match.url}/`} to={`${match.url}/assigned`} />)}
        <Route path={`${match.url}/:source?`} exact component={AnalyticsPage}/>
        <Route path={`${match.url}/:source/deals`} exact component={DealStatistic}/>
        <Route path={`${match.url}/:source/requests`} exact component={RequestStatistic}/>
      </Switch>
    );
  }
}

export default connect(
  state => ({
    selfUser: state.root.selfUser,
  }),
  {setTitle}
)(AnalyticsApp);
