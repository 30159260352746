import React, {Component} from 'react';
import {connect} from 'react-redux';
import {queryRequestList} from '../../queries';
import base64url from '../../utils/base64url';
import {getSection} from '../../utils/lastSection';
import RequestList from './RequestList';
import RequestListItems from './RequestListItems';
import {setTitle} from '../../actions';
import {isSingleDealMenuSection, needUpdatePageTitleByDealMenuSection} from '../../utils';

class RequestListWrapper extends Component {
  state = {
    items: [],
    loading: false,
    error: false,
    totalCount: 0,
    query: {},
  };

  componentDidMount() {
    this.props.setTitle('Заявки beta');

    if (this.props.selfUser && this.props.selfUser.id) {
      if (isSingleDealMenuSection(this.props.selfUser)) {
        this.props.setTitle('Заявки');
      }
    }

    if(this.props.settingsStatus)
      this.search(this.props.location, this.props.dealListSettings);
  }

  componentWillReceiveProps(nextProps) {
    if (needUpdatePageTitleByDealMenuSection(this.props, nextProps)) {
      if (isSingleDealMenuSection(nextProps.selfUser)) {
        this.props.setTitle('Заявки');
      }
    }
    if (nextProps.location.search !== this.props.location.search) {
      this.search(nextProps.location, nextProps.dealListSettings);
    }
    if(nextProps.settingsStatus && nextProps.settingsStatus !== this.props.settingsStatus){
      this.search(nextProps.location, nextProps.dealListSettings);
    }
  }

  search(location, settings) {
    let query = {
      offset: 0,
      limit: settings.page || 30,
    };
    const qs = location.search.substr(1);
    if (qs.length > 0) {
      try {
        query = JSON.parse(base64url.decode(qs));
      } catch (_) {
      }
    }
    if(!query.source){
      let source = getSection('requestSource');
      if(source)
        query.source = source;
    }
    this.setState({loading: true, query});
    queryRequestList(query)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            loading: false,
            error: false,
            items: resp.data.data.requests.items,
            totalCount: resp.data.data.requests.totalCount,
          });
        } else {
          this.setState({loading: false, error: true});
        }
      })
      .catch(() => {
        this.setState({loading: false, error: true});
      });
  }

  render() {
    const {items, loading, error, totalCount, query} = this.state;

    return (
      <RequestList
        query={query}
        deals={items}
        loading={loading}
        error={error}
        totalCount={totalCount}
        location={this.props.location}
        match={this.props.match}
        history={this.props.history}
        perPage={this.props.dealListSettings.page || 30}
      >
        {items ? (
          <RequestListItems/>
        ) : null}
      </RequestList>
    );
  }
}

export default connect(
  state => ({
    selfUser: state.root.selfUser,
    dealListSettings: state.root.settings.requestList,
    settingsStatus: state.root.settings.load,
  }), {setTitle}
)(RequestListWrapper);
