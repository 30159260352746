import {isEqual, omit} from 'lodash';

const defaultState = {
  screens: {},
  contacts: {},
};

const contact = (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'SCREEN_REMOVE': {
      if (!(payload.key in state.screens)) {
        return state;
      }

      return {
        ...state,
        screens: omit(state.screens, payload.key),
      };
    }

    case 'CONTACT_LIST_LOAD_STARTED': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            loading: true,
            filter: payload.filter,
          },
        },
      };
    }

    case 'CONTACT_LIST_LOAD_COMPLETED': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            filter: state.screens[payload.key].filter,
            items: payload.items.map(contact => contact.id),
            page: payload.page,
            totalCount: payload.totalCount,
          },
        },
        contacts: {
          ...state.contacts,
          ...payload.items.reduce((contacts, contact) => ({...contacts, [contact.id]: contact}), {}),
        },
      };
    }

    case 'CONTACT_LIST_LOAD_FAILED': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            filter: state.screens[payload.key].filter,
            error: true,
          },
        },
      };
    }

    case 'CONTACT_LIST_SET_SCROLL_POSITION': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            ...state.screens[payload.key],
            scrollPosition: payload.position,
          },
        },
      };
    }

    case 'CONTACT_LOAD_STARTED': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {loading: true},
        },
      };
    }

    case 'CONTACT_LOAD_COMPLETED': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {item: payload.contact.id},
        },
        contacts: {
          ...state.contacts,
          [payload.contact.id]: payload.contact,
        },
      };
    }

    case 'CONTACT_LOAD_FAILED': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {error: true},
        },
      };
    }

    case 'CONTACT_ADD': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            ...state.screens[payload.key],
            items: [
              payload.contact.id,
              ...state.screens[payload.key].items,
            ],
            totalCount: state.screens[payload.key].totalCount + 1,
          },
        },
        contacts: {
          ...state.contacts,
          [payload.contact.id]: payload.contact,
        },
      };
    }

    case 'CONTACT_UPDATE': {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [payload.contact.id]: payload.contact,
        },
      };
    }

    case 'CONTACT_ADD_ASSIGNEE': {
      if (!state.contacts[payload.id]) {
        return state;
      }

      const assignees = state.contacts[payload.id].assignees || [];
      if (assignees.indexOf(payload.assignee) >= 0) {
        return state;
      }

      return {
        ...state,
        contacts: {
          ...state.contacts,
          [payload.id]: {
            ...state.contacts[payload.id],
            assignees: [
              ...assignees,
              payload.assignee,
            ],
          },
        },
      };
    }

    case 'CONTACT_DELETE_ASSIGNEE': {
      const assignees = state.contacts[payload.id].assignees || [];

      return {
        ...state,
        contacts: {
          ...state.contacts,
          [payload.id]: {
            ...state.contacts[payload.id],
            assignees: assignees.filter(assignee => assignee !== payload.assignee),
          },
        },
      };
    }

    case 'CONTACT_ADD_DOCUMENT': {
      const documents = state.contacts[payload.id].documents || [];

      return {
        ...state,
        contacts: {
          ...state.contacts,
          [payload.id]: {
            ...state.contacts[payload.id],
            documents: [
              ...documents,
              payload.document,
            ],
          },
        },
      };
    }

    case 'CONTACT_REMOVE_DOCUMENT': {
      const documents = state.contacts[payload.id].documents || [];

      return {
        ...state,
        contacts: {
          ...state.contacts,
          [payload.id]: {
            ...state.contacts[payload.id],
            documents: documents.filter(({path}) => path !== payload.documentPath),
          },
        },
      };
    }

    case 'CONTACT_ADD_RELATIONSHIP': {
      if (!state.contacts[payload.id]) {
        return state;
      }

      const relationships = state.contacts[payload.id].relationships || [];
      if (relationships.indexOf(payload.relationship) >= 0) {
        return state;
      }

      return {
        ...state,
        contacts: {
          ...state.contacts,
          [payload.id]: {
            ...state.contacts[payload.id],
            relationships: [
              ...relationships,
              payload.relationship,
            ],
          },
        },
      };
    }

    case 'CONTACT_DELETE_RELATIONSHIP': {
      const relationships = state.contacts[payload.id].relationships || [];

      return {
        ...state,
        contacts: {
          ...state.contacts,
          [payload.id]: {
            ...state.contacts[payload.id],
            relationships: relationships.filter(relationship => !isEqual(relationship, payload.relationship)),
          },
        },
      };
    }

    case 'CONTACT_ADD_PROPERTY': {
      if (!state.contacts[payload.id]) {
        return state;
      }

      const properties = state.contacts[payload.id].properties || [];
      if (properties.indexOf(payload.property) >= 0) {
        return state;
      }

      return {
        ...state,
        contacts: {
          ...state.contacts,
          [payload.id]: {
            ...state.contacts[payload.id],
            properties: [
              ...properties,
              payload.property,
            ],
          },
        },
      };
    }

    case 'CONTACT_SET_COMMENT': {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [payload.id]: {
            ...state.contacts[payload.id],
            comment: payload.comment,
          },
        },
      };
    }

    case 'CONTACT_SET_INFO': {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [payload.id]: {
            ...state.contacts[payload.id],
            publicInfo: payload.publicInfo,
          },
        },
      };
    }

    case 'CONTACT_SET_STATUS': {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [payload.id]: {
            ...state.contacts[payload.id],
            status: payload.status,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default contact;
