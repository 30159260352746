import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

class FileInput extends Component {
  render() {
    const { 
      input: {value: omitValue, onChange, onBlur, ...inputProps}, 
      meta: omitMeta,
      classes, 
      ...props 
    } = this.props;
    return (
      <TextField
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        {...props.input}
        {...props}
        {...inputProps}
        className={classes.fileInput}
      />
    );
  }
}

const styles = () => ({
  fileInput: {
    '& input': {
      height: '2em',
    },
  },
});

export default withStyles(styles)(FileInput);
