import React, {Component} from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import {set} from 'lodash';
import axios from 'axios';

import TextField from '../../../components/Input/TextField';

class DeclinedOfferForm extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      open,
      pristine,
      offerDeclineReasons,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Отказ от объекта</DialogTitle>
        <Divider/>
        <DialogContent>
          <Field
            name="reason"
            label="Причина"
            component={TextField}
            select
            required
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {offerDeclineReasons.map(({label, value}, i) => <MenuItem value={value} key={i}>{label}</MenuItem>)}
          </Field>
          <Field
            name="comment"
            label="Подробный комментарий"
            component={TextField}
            required
            multiline
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(this.submit)}
          >
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = data => {
    const {reason, comment} = data;

    return axios.post('/api/v1/setDealOfferStatus', {id: this.props.id, reason, comment, status: 'declined'})
      .then(res => {
        if (res.status === 200) {
          this.props.onSubmit();
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(error.response.data.error.errors.reduce((errors, {propertyPath, message}) => {
            return set(errors, propertyPath, message);
          }, {}));
        } else {
          alert('Ошибка при сохранении');
        }
      });
  };
}


DeclinedOfferForm = connect(
  state => ({
    offerDeclineReasons: state.root.classification.offerDeclineReasons,
  })
)(DeclinedOfferForm);


export default reduxForm({
  form: 'declined_offer_form',
  enableReinitialize: true,
})(DeclinedOfferForm);
