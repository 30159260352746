import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as icons from '@material-ui/icons';

import OfferDealListItem from '../../../components/relations/OfferDealListItem';
import base64url from '../../../utils/base64url';

class Deals extends Component {
  state = {
    loading: false,
    error: null,
    dealOffers: [],
  };

  componentDidMount() {
    if (this.props.dealOffers) {
      this.setState({dealOffers: this.props.dealOffers});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dealOffers !== this.props.dealOffers) {
      this.setState({dealOffers: nextProps.dealOffers});
    }
  }

  handleClickAdd = () => {
    const {offer, history} = this.props;
    const filter = {
      offerType: offer.type,
    };
    history.push(`/offers/${offer.id}/deals?${base64url.encode(JSON.stringify({filter}))}`);
  };

  render() {
    const {dealOffers, error, loading} = this.state;
    const {handleDialogOpen} = this.props;

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Листинг в заявках
          <ListItemSecondaryAction>
            {loading ? (
              <IconButton disabled>
                <CircularProgress size={24}/>
              </IconButton>
            ) : (
              error ? (
                <IconButton disabled>
                  <icons.ErrorOutline/>
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => this.handleClickAdd()}
                >
                  <icons.Add/>
                </IconButton>
              )
            )}
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {dealOffers.map(dealOffer => (
          <OfferDealListItem
            key={dealOffer.deal.id}
            dealOffer={dealOffer}
            handleDialogOpen={() => handleDialogOpen(dealOffer.deal.id)}
          />
        ))}
      </List>
    );
  }
}

export default Deals;
