import React from 'react';
import MuiDateTimePicker from 'material-ui-pickers/DateTimePicker';

const DateTimePicker = props => {
  const {
    showErrorsInline,
    dispatch,
    input: {onChange, value},
    meta: {touched, error},
    ...other
  } = props;

  const showError = showErrorsInline || touched;

  return (
    <MuiDateTimePicker
      error={!!(showError && error)}
      helperText={showError && error}
      value={value}
      onChange={onChange}
      {...other}
    />
  );
};

export default DateTimePicker;
