import React, {Component} from 'react';
import {connect} from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';

import {
  openLightbox,
  loadEventPressReleaseDetails,
  loadCloseDealPressReleaseDetails,
  updatePressReleaseCloseDeal,
  updatePressReleaseEvent,
  setPressReleaseCloseDealComment,
  setPressReleaseEventComment,
} from '../../actions';
import PressReleaseDetailsWrapper from './PressReleaseDetailsWrapper';
import Row from '../../components/Row';
import Column from '../../components/Column';
import CircularProgress from '@material-ui/core/CircularProgress';
import Member from './relations/Member';
import PressReleaseEdit from './PressReleaseEdit';
import EditableComment from '../../components/EditableComment';
import Assignees from './relations/Assignees';
import DealOffer from './relations/DealOffers';

class PressReleaseView extends Component {
  state = {
    openEdit: false,
  };
  componentDidMount() {
    const {
      match: {
        params: {
          type, pressReleaseId,
        },
      },
      loadEventPressReleaseDetails,
      loadCloseDealPressReleaseDetails,
    } = this.props;

    if(type === 'dealClose'){
      loadCloseDealPressReleaseDetails(pressReleaseId);
    }else{
      loadEventPressReleaseDetails(pressReleaseId);
    }
  }

  handleDialogClose = name => {
    this.setState({[name]: false});
  };

  handleDialogOpen = (value, name) => {
    this.setState({[name] : value});
  };

  render() {
    const {
      match : {params: {type, pressReleaseId}},
      pressReleaseList,
      classes,
      updatePressReleaseCloseDeal,
      updatePressReleaseEvent,
      setPressReleaseCloseDealComment,
      setPressReleaseEventComment,
      history,
    } = this.props;
    const {openEdit} = this.state;
    if(!pressReleaseList)
      return null;
    if (pressReleaseList.isLoad) {
      return <CircularProgress size={50} thickness={4} className={classes.progress}/>;
    }
    const pressRelease = pressReleaseList[pressReleaseId];
    if (pressReleaseList.error || !pressRelease) {
      return <icons.ErrorOutline className={classes.progress}/>;
    }
    return (
      <React.Fragment>
        <Row key={0}>
          <Column label="Информация" icon={<icons.InfoOutlined/>} key="information">
            <PressReleaseDetailsWrapper
              pressRelease={pressRelease}
              type={type}
              history={history}
              id={pressReleaseId}
              onEdit={() => this.setState({openEdit: true})}
            />
          </Column>
          <Column label="История" icon={<icons.History/>} key="activity">
            <div className="column" style={{background: colors.grey[200], zIndex: 0}}>
              <div className="content" style={{padding: 16}}>
                <EditableComment
                  comment={pressRelease.comment}
                  max={1000}
                  onSave={comment => type === 'dealClose'
                    ? setPressReleaseCloseDealComment(pressRelease.id, comment, pressRelease.comment)
                    : setPressReleaseEventComment(pressRelease.id, comment)}
                />
              </div>
            </div>
          </Column>
          <Column label="Связи" icon={<icons.Share/>} key="relationships">
            <Paper elevation={1} square className="column">
              <div className="content">
                <Member
                  pressRelease={pressRelease}
                />
                <Divider/>
                {pressRelease.assignees
                  ? (
                    <React.Fragment>
                      <Assignees
                        pressRelease={pressRelease}
                      />
                      <Divider/>
                    </React.Fragment>
                  ) : null}
                {pressRelease.dealOffer ?
                  <React.Fragment>
                    <DealOffer
                      dealOffer={pressRelease.dealOffer}
                      handleDialogOpen={() => history.push(`/dealOffers/${pressRelease.dealOffer.id}`)}
                    />
                    <Divider/>
                  </React.Fragment>
                  : null}
              </div>
            </Paper>
          </Column>
        </Row>
        {openEdit ? (
          <PressReleaseEdit
            open={openEdit}
            pressRelease={pressRelease}
            type={type}
            onSubmitted={pressRelease => {
              this.setState({openEdit: false});
              type === 'dealClose' ? updatePressReleaseCloseDeal(pressRelease) : updatePressReleaseEvent(pressRelease);
            }}
            onClose={() => this.setState({openEdit: false})}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const styles = () => ({
  itemText: {},
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
});

export default connect(
  (state, ownProps) => ({
    type: ownProps.type,
    pressReleaseList: ownProps.match.params.type === 'dealClose'
      ? state.root.pressRelease.pressRelease.deal
      : state.root.pressRelease.pressRelease.event,
    pressReleaseStatus: state.root.pressRelease.pressRelease.status,
  }),
  {
    openLightbox,
    loadEventPressReleaseDetails,
    loadCloseDealPressReleaseDetails,
    updatePressReleaseCloseDeal,
    updatePressReleaseEvent,
    setPressReleaseCloseDealComment,
    setPressReleaseEventComment,
  },
)(withStyles(styles)(PressReleaseView));
