import React, {Component} from 'react';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import OfferDealListItem from '../../../components/relations/OfferDealListItem';

class DealOffers extends Component {
  state = {
    dealOffer: [],
  };

  componentDidMount() {
  }

  render() {
    const {
      handleDialogOpen,
      dealOffer,
    } = this.props;

    if(!dealOffer)
      return null;
    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Сделка
        </ListSubheader>
      }>
        <OfferDealListItem
          key={dealOffer.id}
          dealOffer={dealOffer}
          handleDialogOpen={() => handleDialogOpen(dealOffer.id)}
          color={colors.indigo[500]}
        />
      </List>
    );
  }
}

const styles = () => ({
  statusIcon: {
    display: 'inline-block',
    lineHeight: 1,
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 4,
  },
});

DealOffers = connect(
  state => ({
    dealOfferActiveStatuses: state.root.classification.dealOfferActiveStatuses,
  }),
)(DealOffers);

export default withRouter(withStyles(styles)(DealOffers));
