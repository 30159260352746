import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import FavoritesList from './FavoritesList';
import {setTitle} from '../../../actions';

class FavoritesApp extends Component {
  componentDidMount() {
    this.props.setTitle('Избранное');
  }

  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route path={`${match.url}/`} component={FavoritesList}/>
      </Switch>
    );
  }
}

export default connect(null, {setTitle})(FavoritesApp);
