import axios from 'axios';
import React, {Component} from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {Field} from 'redux-form';
import Dropzone from 'react-dropzone';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import SnackbarError from '../../../components/SnackbarError';
import SnackbarSuccess from '../../../components/SnackbarSuccess';

let photoArray = new Map();

const SortableItem = SortableElement(({classes, fieldName, index, onDelete, value, ...props}) => {
  let photoInfo = photoArray.get(value) || {};
  return(
    <GridListTile cols={1} className={classes.item} {...props}>
      <img src={`/resized/crop_150x150/${value}.jpg`} alt="" style={{pointerEvents: 'none'}}/>
      <GridListTileBar
        title={photoInfo.name}
        subtitle={photoInfo.wh ? <span>{photoInfo.wh} ({photoInfo.size})</span> : null}
        actionIcon={
          <IconButton
            className={classes.removeButton}
            onClick={onDelete}
          >
            <icons.Close/>
          </IconButton>
        }/>
    </GridListTile>
  );
});

const SortableList = SortableContainer(({classes, fieldName, items, remove}) =>
  <GridList cellHeight={150} cols={4}>
    {items.map((value, index) => (
      <SortableItem
        key={index}
        index={index}
        classes={classes}
        value={value}
        onDelete={() => remove(fieldName, index)}
      />
    ))}
  </GridList>
);

class Photos extends Component {
  state = {
    arrayTostSuccess: [],
    arrayTostError: [],
  };
  handleDrop = files => {
    const {arrayTostSuccess, arrayTostError} = this.state;
    const {push, name} = this.props;
    for (const file of files) {
      /*
      if (['image/jpeg', 'image/png'].indexOf(file.type) < 0) {
        continue;
      }
      */
      axios.post(`/api/v1/uploadImage/${encodeURIComponent(file.name)}`, file, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      })
        .then(resp => {
          photoArray.set(resp.data.path, resp.data);
          push(name, resp.data.path);
          arrayTostSuccess.push(`Изображение ${resp.data.name} успешно загружено`);
          this.setState({
            arrayTostSuccess,
          });
        })
        .catch(error => {
          arrayTostError.push(error.response.data.error);
          this.setState({
            arrayTostError,
          });
        });
    }
  };

  render() {
    const {classes, label, name} = this.props;
    const {arrayTostSuccess, arrayTostError} = this.state;
    return (
      <FormControl fullWidth margin="normal">
        <Typography variant="caption">{label}</Typography>
        <Dropzone
          className={classes.dropzone}
          onDrop={this.handleDrop}
        >
          <Typography>
            Перетащите файлы в выделенную область или нажмите сюда для загрузки.
          </Typography>
        </Dropzone>
        <Field
          name={name}
          required
          component={this.photoComponent}
        />
        <div style={{left: 24, bottom: 24, right: 0, position: 'fixed', zIndex: 1400}}>
          {arrayTostError.map(element => (
            <SnackbarError
              key={element}
              setPosition
              open={Boolean(element)}
              errorMessage={element}
              onClose={() => arrayTostError.shift()}
            />
          ))}
          {arrayTostSuccess.map(element => (
            <SnackbarSuccess
              key={element}
              setPosition
              open={element}
              message={element}
              onClose={() => {
                arrayTostSuccess.shift();
                this.setState({
                  arrayTostSuccess,
                });
              }}
            />
          ))}
        </div>
      </FormControl>
    );
  }

  photoComponent = ({input, meta}) => {
    return input.value && input.value.length > 0
      ? this.renderPhotos(input.value, meta)
      : <Typography variant="caption" style={{color: '#f44336'}}>{meta.error}</Typography>;
  };

  renderPhotos(photos) {
    const {classes, name, remove} = this.props;

    return (
      <SortableList
        axis="xy"
        classes={classes}
        distance={5}
        fieldName={name}
        items={photos}
        remove={remove}
        onSortEnd={this.onSortEnd}
      />
    );
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    if (oldIndex === newIndex) {
      return;
    }

    this.props.move(this.props.name, oldIndex, newIndex);
  };
}

const styles = theme => ({
  dropzone: {
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    border: '1px dashed silver',
    textAlign: 'center',
  },
  item: {
    zIndex: theme.zIndex.modal + 1,
    display: 'block',
    userSelect: 'none',
  },
  removeButton: {
    color: 'white',
  },
});

export default withStyles(styles)(Photos);
