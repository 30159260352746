import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';

class MemberListItem extends Component {
  render() {
    const {
      member,
      onSecondaryActionClick,
      handleDialogOpen,
    } = this.props;

    return (
      <ListItem
        dense
        button
        onClick={() => handleDialogOpen ? handleDialogOpen(member.id) : null }
      >
        <Avatar style={{backgroundColor: colors.red[300]}}>
          <icons.Person/>
        </Avatar>
        {member ? (
          <ListItemText
            primary={
              <Typography variant="body1" noWrap>
                {member.name}
              </Typography>
            }
            secondary={
              <Typography variant="caption" noWrap>
                {`${member.phone}, ${member.email}`}
              </Typography>
            }
            disableTypography
          />
        ) : null}
        {onSecondaryActionClick ? (
          <ListItemSecondaryAction>
            <IconButton onClick={onSecondaryActionClick}>
              <icons.MoreVert/>
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    );
  }
}

export default MemberListItem;
