import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {connect} from 'react-redux';
import {mapLabels} from '../../utils';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';

class ContactListItem extends Component {
  render() {
    const {
      contact,
      onSecondaryActionClick,
      showEmail,
      contactFieldName,
    } = this.props;

    let listItemProps = {};

    if (this.props.handleDialogOpen) {
      listItemProps = {
        onClick: e => {
          if(this.props.handleDialogOpen){
            e.preventDefault();
            this.props.handleDialogOpen(contact.id);
          }
        },
      };
    }

    return (
      <ListItem
        dense
        button
        component={Link}
        to={`/contacts/${contact.id}`}
        {...listItemProps}
      >
        <Avatar style={{backgroundColor: colors.orange[300]}}>
          <icons.Contacts/>
        </Avatar>
        {contact ? (
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body1">
                {contact[contactFieldName || 'name']}
              </Typography>
            }
            secondary={
              <React.Fragment>
                {showEmail ?
                  contact.email ?
                    <Typography variant="caption" noWrap>
                      {contact.email}
                    </Typography>
                    :
                    <Typography variant="caption" style={{color: '#f44336'}} noWrap>
                      Адрес электронной почты не указан
                    </Typography>
                  :
                  <Typography variant="caption" noWrap>
                    {contact.phone}
                  </Typography>
                }
              </React.Fragment>
            }
          />
        ) : null}
        {onSecondaryActionClick ? (
          <ListItemSecondaryAction>
            <IconButton onClick={onSecondaryActionClick}>
              <icons.MoreVert/>
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    );
  }
}

ContactListItem = connect(
  state => ({
    contactStatusColors: mapLabels(state.root.classification.offerStatuses, 'value', 'color'),
    contactStatusLabels: mapLabels(state.root.classification.offerStatuses, 'value', 'label'),
  }),
)(ContactListItem);

export default ContactListItem;
