import React, {Component} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

class RadioInputField extends Component {
  onChange = value => {
    this.props.onChangeValue(value);
  };

  render() {
    const {variants, label, value, classes} = this.props;
    return (
      <>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.label}
        >
          {label}
        </Typography>
        <RadioGroup className={classes.radioGroup} row value={value}>
          {variants.map(({value, label}, i) => (
            <FormControlLabel
              key={i}
              value={value}
              control={<Radio color="primary"/>}
              label={label}
              className={classes.radio}
              onChange={() => this.onChange(value)}
            />
          ))}
        </RadioGroup>
      </>
    );
  }
}

const styles = () => ({
  label: {
    marginTop: 8,
    marginBottom: 8,
  },
  radio: {
    marginLeft: 0,
    marginRight: 8,
    '& svg': {
      fontSize: '20px',
    },
    '& > span:first-child': {
      padding: 0,
      paddingRight: 2,
    },
  },
  radioGroup: {
    justifyContent: 'space-around',
    margin: '12px 0',
  },
});

RadioInputField = withStyles(styles)(RadioInputField);

export default RadioInputField;
