import * as colors from '@material-ui/core/colors/index';
import {differenceInHours, differenceInDays, differenceInMonths} from 'date-fns/esm';

export const propertyAlerts = [
  {
    message: ['Не указан собственник'],
    warningColor: [colors.red[600]],
    iconColor: [colors.red[400]],
    order: 2,
    selectedVariant: 0,
    activeStatusWhiteList: null,
    activeSourceBlackList: null,
    triggerCondition: function(property){
      return property.owner && property.owner.length === 0;
    },
  },
  {
    message: ['К объекту не прикреплен договор'],
    warningColor: [colors.amber[600]],
    iconColor: [colors.orange[400]],
    order: 1,
    activeStatusWhiteList: ['opened'],
    activeSourceBlackList: null,
    selectedVariant: 0,
    triggerCondition: function(property) {
      return property.hasOwnProperty('hasContract');
    },
  },
  {
    message: ['Не указан ответственный'],
    warningColor: [colors.amber[600]],
    iconColor:  [colors.orange[400]],
    order: 1,
    selectedVariant: 0,
    activeStatusWhiteList: null,
    activeSourceBlackList: null,
    triggerCondition: function(property){
      return !property.assignees || property.assignees.length < 1;
    },
  },
  {
    message: ['Нет активных листингов'],
    warningColor: [colors.red[600]],
    iconColor: [colors.red[400]],
    order: 2,
    selectedVariant: 0,
    activeStatusWhiteList: ['opened'],
    activeSourceBlackList: null,
    triggerCondition: function(property){
      if(property.offers && property.offers.length > 0){
        if(property.offers.every(element => element.status !== 'opened'))
          return true;
        else return false;}
      return true;
    },
  },
];

export const dealAlerts = [
  {
    message: ['Свободная заявка'],
    warningColor: [colors.green[600]],
    iconColor: [colors.green[400]],
    order: 10,
    selectedVariant: 0,
    activeStatusWhiteList: null,
    activeSourceBlackList: ['FREE'],
    triggerCondition: function(deal) {
      return deal.free;
    },
  },
  {
    message: ['Есть новые листинги'],
    warningColor: [colors.red[600]],
    iconColor: [colors.red[400]],
    order: 1,
    selectedVariant: 0,
    activeStatusWhiteList: ['opened', 'accepted', 'with_offers', 'negotiation', 'paused', 'no_offers'],
    activeSourceBlackList: null,
    triggerCondition: function(deal) {
      return  deal.hasNewOffers;
    },
  },
  {
    message: ['Заявка не взята в работу в течение 6 часов'],
    warningColor: [colors.red[600]],
    iconColor: [colors.red[400]],
    order: 1,
    selectedVariant: 0,
    activeStatusWhiteList: ['opened'],
    activeSourceBlackList: null,
    triggerCondition: function(deal) {
      return differenceInHours(new Date(), new Date(deal.createdAt)) > 6;
    },
  },
  {
    message: ['Не было предложено ни одного листинга'],
    warningColor: [colors.red[600]],
    iconColor: [colors.red[400]],
    order: 0,
    selectedVariant: 0,
    activeStatusWhiteList: ['opened', 'accepted', 'paused'],
    activeSourceBlackList: null,
    triggerCondition: function(deal) {
      return !deal.hasOffers;
    },
  },
  {
    message: ['Заявка без изменений более 4 дней'],
    warningColor: [colors.red[600]],
    iconColor: [colors.red[400]],
    order: 0,
    selectedVariant: 0,
    activeStatusWhiteList: ['accepted', 'with_offers', 'negotiation'],
    activeSourceBlackList: null,
    triggerCondition: function(deal){
      return differenceInDays(new Date(), new Date(deal.updatedAt)) > 4;
    },
  },
];

export const offerAlerts = [
  {
    message: ['Цена завышена', 'Цена не корректировалась 1 месяц', 'Цена не изменялась более 2 месяцев'],
    warningColor: [colors.amber[600], colors.amber[600], colors.red[600] ],
    iconColor: [colors.amber[400], colors.amber[400], colors.red[400]],
    order: 10,
    selectedVariant: 0,
    activeStatusWhiteList: null,
    activeSourceBlackList: ['FREE'],
    triggerCondition: function(offer) {
      if(offer.priceHigh){
        this.selectedVariant = 0;
        if(differenceInMonths(new Date(), new Date(offer.priceUpdatedAt)) >= 1)
          this.selectedVariant = 1;
        if(differenceInMonths(new Date(), new Date(offer.priceUpdatedAt)) >= 2)
          this.selectedVariant = 2;
        return true;
      }
    },
  },
];