import * as axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';

import DealDetailsWrapper from './DealDetailsWrapper';
import DealEdit from './DealEdit';
import Assignees from './relations/Assignees';
import Contact from './relations/Contact';
import Offers from './relations/Offers';
import Selection from './relations/Selection';
import DealOffers from './relations/DealOffers';
import Documents from './relations/Documents';
import DealSelectionDialog from './DealSelectionDialog';
import Row from '../../components/Row';
import Column from '../../components/Column';
import EditableComment from '../../components/EditableComment';
import {queryDealDetails} from '../../queries';
import {queryDealOffersPreview} from '../../queries/DealOffers';
import {createAreaFormatter, mapLabels} from '../../utils';
import ActivityEventsList from '../../components/ActivityEventsList';
import {hasRole} from '../../utils/roleFunc';
import * as userRoles from '../../constants/userRoles';

import DealOfferDialog from '../dealOffers/DealOfferDialog';
import ContactDialog from '../contacts/ContactDialog';
import OfferDialog from '../offer/OfferDialog';
import AssigneeDialog from '../contacts/AssigneeDialog';
import MiddleColumn from '../../components/MiddleColumn';
import {getDealWarningList} from '../../utils/alerts';
import ChangeStatusCommentForm from './form/DealStatusChangeForm';
import {cancelChangeSelection, changeDealSelection, setTitle, changePageSelection} from '../../actions';

class DealView extends Component {
  state = {
    openAdd: null,
    openEdit: false,
    deal: null,
    openContactDialog: false,
    openOfferDialog: false,
    openAssigneeDialog: false,
    openDealOfferDialog: false,
    openSelection: false,
    dealOfferLoading: false,
    offerLoading: false,
    dealOfferError: false,
    offerError: false,
    nextStatus: null,
    changeStatusComment: false,
    offerPage: 1,
    offerTotalCount: 0,
    dealOfferPage: 1,
    dealOfferTotalCount: 0,
  };

  perPage = 5;

  handleDialogClose = name => {
    this.setState({[name]: false});
  };

  handleDialogOpen = (value, name) => {
    this.setState({[name] : value});
  };

  componentDidMount() {
    this.props.setTitle('Заявки');
    this.load();
    this.getDealOffers();
    if(this.props.match.params.selectionId)
      this.setState({
        openSelection: true,
      });
  }
  componentWillUnmount(){
    this.props.changeDealSelection();
  }

  componentWillReceiveProps(props) {
    if(props.match.params.selectionId){
      this.setState({
        openSelection: true,
      });
    }else{
      this.setState({
        openSelection: false,
      });
    }
  }

  load() {
    const {match} = this.props;
    queryDealDetails(match.params.dealId)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            deal: resp.data.data.deal,
          });
          return;
        }
        alert('Произошла ошибка!');
      })
      .catch(() => {
        alert('Произошла ошибка!');
      });
  }

  getDealOffers = () => {
    this.getActiveDealOffers();
    this.getAttachedOffers();
  };

  getAttachedOffers = () => {
    const {match} = this.props;
    this.setState({OfferLoading: true});
    queryDealOffersPreview({source: 'ALL', filter: {deal: match.params.dealId, isActive: false}, limit: this.perPage, offset: this.perPage * (this.state.offerPage - 1)})
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({dealOffers: resp.data.data.dealOfferList.items, offerTotalCount: resp.data.data.dealOfferList.totalCount, offerLoading: false, offerError: false});
        }
      })
      .catch(() => {
        this.setState({dealOfferLoading: false, dealOfferError: true});
      });
  };

  getActiveDealOffers = () => {
    const {match} = this.props;
    this.setState({dealOfferLoading: true});
    queryDealOffersPreview({source: 'ALL', filter: {deal: match.params.dealId, isActive: true}, limit: this.perPage, offset: this.perPage * (this.state.dealOfferPage - 1)})
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({dealOffersActive: resp.data.data.dealOfferList.items, dealOfferTotalCount: resp.data.data.dealOfferList.totalCount, dealOfferLoading: false, dealOfferError: false});
        }
      })
      .catch(() => {
        this.setState({dealOfferLoading: false, dealOfferError: true});
      });
  };

  getSelections = () => {

  };

  saveComment = (id, nextComment, prevComment) => {
    this.setState({
      deal: {
        ...this.state.deal,
        comment: nextComment,
      },
    });

    return axios.put(`/api/deals/${id}/comment`, {id, comment: nextComment})
      .then(() => this.activityEventsList.updateActivity())
      .catch(() => {
        this.setState({
          deal: {
            ...this.state.deal,
            comment: prevComment,
          },
        });
        throw new Error();
      });
  };

  saveClass = newClass => {
    this.setState({
      deal: {
        ...this.state.deal,
        abcClass: newClass,
      },
    });
    this.activityEventsList && this.activityEventsList.updateActivity();
  };

  saveStatus = (id, newStatus, oldStatus) => {
    if(this.props.dealClosesStatuses.includes(newStatus)){
      return this.setState({
        nextStatus: newStatus,
        changeStatusComment: true,
      });
    }
    this.setState({
      deal: {
        ...this.state.deal,
        status: newStatus,
      },
    });

    return axios.post('/api/v1/setDealStatus', {id, status: newStatus})
      .then(() => {
        this.load();
        this.activityEventsList && this.activityEventsList.updateActivity();
      })
      .catch(error => {
        if(error.response.status === 400)
          alert(error.response.data.error);
        this.setState({
          deal: {
            ...this.state.deal,
            status: oldStatus,
          },
        });
      });
  };

  saveRequirements = (id, newReq, oldReq) => {
    this.setState({
      deal: {
        ...this.state.deal,
        requirements: newReq,
      },
    });

    return axios.put(`/api/deals/${id}/requirements`, {id, requirements: newReq})
      .then(() => this.activityEventsList.updateActivity())
      .catch(() => {
        this.setState({
          deal: {
            ...this.state.deal,
            requirements: oldReq,
          },
        });
        throw new Error();
      });
  };

  onSubmitDealClose = data => {
    this.activityEventsList && this.activityEventsList.updateActivity();
    this.setState({
      changeStatusComment: false,
      deal: {
        ...this.state.deal,
        status: this.state.nextStatus,
        closeReason: data.closeReason,
        closeComment: data.closeComment,
      }});
  };

  handleCloseSelectionDialog = () => {
    const {history, cancelChangeSelection} = this.props;
    const {deal} = this.state;
    cancelChangeSelection();
    history.push(`/deals/${deal.id}/`);
  };

  handleChangePageOffer = page => {
    this.setState({
      offerPage: page,
    }, this.getAttachedOffers);
  };
  handleChangePageDealOffer = page => {
    this.setState({
      dealOfferPage: page,
    }, this.getActiveDealOffers);
  };

  handleSelectionSubmit = () => {
    this.getDealOffers();
    this.activityEventsList.updateActivity();
  };
  handleOpenSelectionSubmit = () => {
    this.getDealOffers();
    this.props.changePageSelection(1);
    this.activityEventsList.updateActivity();
  };

  render() {
    const {
      deal,
      openEdit,
      openAssigneeDialog,
      openOfferDialog,
      openContactDialog,
      openDealOfferDialog,
      dealOffers,
      dealOffersActive,
      dealOfferLoading,
      offerLoading,
      offerError,
      dealOfferError,
      changeStatusComment,
      nextStatus,
      openSelection,
      offerPage,
      offerTotalCount,
      dealOfferPage,
      dealOfferTotalCount,
    } = this.state;

    const {
      history,
      match,
      selfUser,
      selections,
    } = this.props;

    if (!deal) {
      return null;
    }
    return (
      <React.Fragment>
        <Row>
          <Column label="Информация" icon={<icons.InfoOutlined/>} key="information">
            <DealDetailsWrapper
              deal={deal}
              onEdit={() => this.setState({openEdit: true})}
              onStatusChange={status => this.saveStatus(deal.id, status, deal.status)}
              onClassChange={newClass => this.saveClass(newClass)}
              onRequirementsChange={requirements => this.saveRequirements(deal.id, requirements, deal.requirements)}
            />
          </Column>
          <Column label="История" icon={<icons.History/>} key="activity">
            <div className="column" style={{background: colors.grey[200], zIndex: 0}}>
              <div className="content" style={{padding: 16}}>
                {getDealWarningList(deal)}
                <MiddleColumn entityId={deal.id} entityName="Deal">
                  <EditableComment
                    comment={deal.comment}
                    onSave={comment => this.saveComment(deal.id, comment, deal.comment)}
                  />
                  {hasRole(selfUser.role, userRoles.ADMIN) || selfUser.team === deal.team.id ?
                    <div style={{marginTop: 20}}>
                      <ActivityEventsList
                        entityId={match.params.dealId}
                        onRef={ref => (this.activityEventsList = ref)}
                        aggregateType="deal"
                      />
                    </div>
                    : null}
                </MiddleColumn>
              </div>
            </div>
          </Column>
          <Column label="Связи" icon={<icons.Share/>} key="relationships">
            <Paper elevation={1} square className="column">
              <div className="content">
                <Assignees
                  deal={deal}
                  handleDialogOpen={e => this.handleDialogOpen(e, 'openAssigneeDialog')}
                  updateActivity={() => this.activityEventsList.updateActivity()}
                />
                <Divider/>
                <Contact
                  deal={deal}
                  handleDialogOpen={e => this.handleDialogOpen(e, 'openContactDialog')}
                />
                <Divider/>
                <Documents updateActivity={() => this.activityEventsList.updateActivity()} deal={deal}/>
                <Divider/>
                <DealOffers
                  loading={dealOfferLoading}
                  error={dealOfferError}
                  page={dealOfferPage}
                  totalCount={dealOfferTotalCount}
                  handleChangePage={this.handleChangePageDealOffer}
                  perPage={this.perPage}
                  dealOffers={dealOffersActive}
                  history={history}
                  handleDialogOpen={e => this.handleDialogOpen(e, 'openDealOfferDialog')}
                  updateActivity={() => this.activityEventsList.updateActivity()}
                />
                <Divider/>
                <Offers
                  loading={offerLoading}
                  reload={() => this.getDealOffers()}
                  error={offerError}
                  dealOffers={dealOffers}
                  deal={deal}
                  page={offerPage}
                  totalCount={offerTotalCount}
                  perPage={this.perPage}
                  handleChangePage={this.handleChangePageOffer}
                  handleDialogOpen={e => this.handleDialogOpen(e, 'openOfferDialog')}
                  updateActivity={() => this.activityEventsList.updateActivity()}
                />
                <Divider/>
                <Selection
                  selections={selections}
                  deal={deal}
                  updateActivity={() => this.activityEventsList.updateActivity()}
                  perPage={this.perPage}
                />
              </div>
            </Paper>
          </Column>
        </Row>
        {openEdit ? (
          <DealEdit
            open={openEdit}
            id={deal.id}
            onSubmitted={() => {
              this.load();
              this.activityEventsList && this.activityEventsList.updateActivity();
              this.setState({openEdit: false});
            }}
            onClose={() => this.setState({openEdit: false})}
          />
        ) : null}
        {changeStatusComment ? (
          <ChangeStatusCommentForm
            open={changeStatusComment}
            id={deal.id}
            status={nextStatus}
            onSubmit={this.onSubmitDealClose}
            onClose={() => this.setState({changeStatusComment: false})}
          />
        ) : null}
        {openContactDialog ? (
          <ContactDialog
            history={history}
            contact={openContactDialog}
            open={Boolean(openContactDialog)}
            handleClose={() => this.handleDialogClose('openContactDialog')}
          />
        ) : null}
        {openOfferDialog ? (
          <OfferDialog
            history={history}
            offer={openOfferDialog}
            open={Boolean(openOfferDialog)}
            handleClose={() => this.handleDialogClose('openOfferDialog')}
          />
        ) : null}
        {openAssigneeDialog ? (
          <AssigneeDialog
            history={history}
            member={openAssigneeDialog}
            open={Boolean(openAssigneeDialog)}
            handleClose={() => this.handleDialogClose('openAssigneeDialog')}
          />
        ) : null}
        {openDealOfferDialog ? (
          <DealOfferDialog
            history={history}
            dealOffer={openDealOfferDialog}
            open={Boolean(openDealOfferDialog)}
            handleClose={() => this.handleDialogClose('openDealOfferDialog')}
            reload={() => this.getDealOffers()}
          />
        ) : null}
        {openSelection ?
          <DealSelectionDialog
            open={openSelection}
            deal={deal}
            history={history}
            match={match}
            handleClose={this.handleCloseSelectionDialog}
            handleSubmit={this.handleOpenSelectionSubmit}
          />
          : null}
      </React.Fragment>
    );
  }
}

const styles = () => ({});

export default connect(
  state => ({
    formatArea: createAreaFormatter(state.root.classification.areaUnits),
    dealClosesStatuses: state.root.classification.dealClosesStatuses,
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    selfUser: state.root.selfUser,
    selections: state.root.selection.selections,
  }),
  {
    cancelChangeSelection,
    changeDealSelection,
    setTitle,
    changePageSelection,
  }
)(withStyles(styles)(DealView));
