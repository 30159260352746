import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import TeamListItem from '../../../components/relations/TeamListItem';

class Team extends Component {
  render() {
    const {
      offer,
    } = this.props;

    if (!offer) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Команда
        </ListSubheader>
      }>
        {offer.team && offer.teamName ? (
          <TeamListItem
            key={offer.team}
            name={offer.teamName}
          />
        ) : null}
      </List>
    );
  }
}

export default withRouter(Team);
