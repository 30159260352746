import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import PublicDealListWrapper from './PublicDealListWrapper';
import PublicDealView from './PublicDealView';
import {setTitle} from '../../actions';

class PublicDeals extends Component {
  componentDidMount() {
    this.props.setTitle('Наши сделки');
  }

  render() {
    const {match} = this.props;
    return (
      <Switch>
        <Route path={`${match.url}/`} exact component={PublicDealListWrapper}/>
        <Route path={`${match.url}/:publicDealId`} component={PublicDealView}/>
      </Switch>
    );
  }
}

export default connect(null, {setTitle})(PublicDeals);
