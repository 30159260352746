import React, {Component} from 'react';
import {connect} from 'react-redux';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import {
  openLightbox,
} from '../../actions';
import {
  ImageFilters,
  getImageUrl,
  formatDateWithoutTime,
} from '../../utils';

class PublicDeal extends Component {
  render() {
    const {
      classes,
      openLightbox,
      publicDeal,
    } = this.props;
    return (
      <div className="content" style={{background: 'white'}}>
        <List>
          {publicDeal.city && publicDeal.city.name ?
            <ListItem dense>
              <ListItemText
                primary={publicDeal.city.name}
                secondary="Город"
              />
            </ListItem>
            : null}
          {publicDeal.date ?
            <ListItem dense>
              <ListItemText
                primary={`${formatDateWithoutTime(publicDeal.date)}`}
                secondary="Дата сделки"
              />
            </ListItem>
            : null}
          {publicDeal.description ?
            <ListItem dense>
              <ListItemText
                primary={publicDeal.description}
                secondary="Описание"
              />
            </ListItem>
            : null}
        </List>
        {publicDeal.photo ? (
          <div className={classes.photos}>
            <Typography variant="caption" gutterBottom>Фотография</Typography>
            <GridList className={classes.photosWrapper} cellHeight={300} spacing={4}>
              <GridListTile className={classes.photoWrapper} key={0} onClick={() => openLightbox([publicDeal.photo], 0)}>
                <img src={getImageUrl({filter: ImageFilters.resize, width: 800, height: 600, path: publicDeal.photo})} alt=""/>
              </GridListTile>
            </GridList>
          </div>
        ) : null}
      </div>
    );
  }
}

const styles = theme => ({
  avatar: {
    background: colors.blue[300],
  },
  photosWrapper: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  photoWrapper: {
    width: '100% !important',
  },
  photos: theme.mixins.gutters({
    paddingTop: 8,
    paddingBottom: 8,
  }),
  editIcon: {
    fontSize: 18,
    color: colors.grey[600],
    visibility: 'hidden',
    marginLeft: 'auto',
  },
  menuPaper: {
    minWidth: 288,
  },
  headerPopoverPaper: {
    padding: 16,
    width: '100%',
  },
  statusItem: {
    cursor: 'pointer',
    '&:hover $editIcon': {
      visibility: 'visible',
    },
  },
});

export default connect(
  ({root}) => ({
    user: root.selfUser,
  }),
  {
    openLightbox,
  }
)(withStyles(styles)(PublicDeal));
