import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {connect} from 'react-redux';
import {mapLabels} from '../../utils';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';

class PropertyListItem extends Component {
  render() {
    const {
      property,
      propertyTypeLabels,
      onSecondaryActionClick,
      propertyStatusColors,
      propertyStatusLabels,
    } = this.props;

    let listItemProps = {};

    if (this.props.handleDialogOpen) {
      listItemProps = {
        onClick: e => {
          e.preventDefault();
          this.props.handleDialogOpen(property.id);
        },
      };
    }

    return (
      <ListItem 
        button 
        dense 
        component={Link}
        to={`/properties/${property.id}`}
        {...listItemProps}
      >
        <Avatar style={{backgroundColor: colors.blue[300]}}>
          <icons.Business/>
        </Avatar>
        {property ? (
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body1">
                {propertyTypeLabels[property.type.value] || property.type.value}
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography variant="caption" noWrap>
                  {property.address.value}
                </Typography>
                <Typography variant="body2">
                  <span style={{background: (propertyStatusColors[property.status] || {100: colors.white})[100]}}>
                    {propertyStatusLabels[property.status] || property.status}
                  </span>
                </Typography>
              </React.Fragment>
            }
          />
        ) : null}
        {onSecondaryActionClick ? (
          <ListItemSecondaryAction>
            <IconButton onClick={onSecondaryActionClick}>
              <icons.MoreVert/>
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    );
  }
}

PropertyListItem = connect(
  state => ({
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    propertyStatusColors: mapLabels(state.root.classification.propertyStatuses, 'value', 'color'),
    propertyStatusLabels: mapLabels(state.root.classification.propertyStatuses, 'value', 'label'),
  }),
)(PropertyListItem);

export default PropertyListItem;
