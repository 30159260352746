import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import * as icons from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';

import AddDocument from '../form/AddDocument';
import RemoveDocument from '../form/RemoveDocument';
import DocumentListItem from '../../../components/relations/DocumentListItem';
import {queryDealOfferDocuments} from '../../../queries/DealOffers';

class Documents extends Component {
  state = {
    addDocument: null,
    openMenu: false,
    removeDocument: false,
    error: false,
    loading: false,
  };

  componentDidMount() {
    this.reload();
  }

  reload() {
    this.setState({
      loading: true,
    });
    queryDealOfferDocuments(this.props.dealOffer.id)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            documents: resp.data.data.dealOffer.documents,
          });
        } else {
          this.setState({
            error: true,
          });
        }
      }, () => {
        this.setState({
          error: true,
        });
      })
      .then(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const {
      dealOffer,
    } = this.props;
    const {
      addDocument,
      openMenu,
      removeDocument,
      error,
      loading,
      documents,
    } = this.state;

    if (!dealOffer) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Документы
          <ListItemSecondaryAction>
            {loading ? (
              <IconButton disabled>
                <CircularProgress size={24}/>
              </IconButton>
            ) : (
              error ? (
                <IconButton disabled>
                  <icons.ErrorOutline/>
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    this.setState({
                      addDocument: {
                        id: dealOffer.id,
                      },
                    });
                  }}
                  disabled={dealOffer.documents && dealOffer.documents.length >= 3}
                >
                  <icons.Add/>
                </IconButton>
              )
            )}

          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {!error && documents ? documents.map((document, index) => (
          <DocumentListItem
            key={index}
            document={document}
            onSecondaryActionClick={e => this.setState({openMenu: {target: e.currentTarget, path: document.path}})}
          />
        )) : null}
        {openMenu ? (
          <Menu
            anchorEl={openMenu.target}
            open={Boolean(openMenu)}
            onClick={() => this.setState({openMenu: false})}
          >
            <MenuItem onClick={() => {
              this.setState({
                removeDocument: {
                  id: dealOffer.id,
                  path: openMenu.path,
                },
              });
            }}>
              Удалить документ
            </MenuItem>
          </Menu>
        ) : null}
        {removeDocument ? (
          <RemoveDocument
            open={Boolean(removeDocument)}
            initialValues={removeDocument}
            onSubmitted={() => {
              this.reload();
              this.props.updateActivity();
              this.setState({removeDocument: null});
            }}
            onClose={() => this.setState({removeDocument: null})}
          />
        ) : null}
        {addDocument ? (
          <AddDocument
            open={Boolean(addDocument)}
            initialValues={addDocument}
            onSubmitted={() => {
              this.reload();
              this.props.updateActivity();
              this.setState({addDocument: null});
            }}
            onClose={() => {
              this.setState({addDocument: null});
            }}
          />
        ) : null}
      </List>
    );
  }
}

export default withRouter(Documents);
