import gql from 'graphql-tag';

export default gql`
  query taskDetails($id: ID!) {
    task(id: $id) {
      id
      title
      deadline
      description
      author {
        id
        name
      }
      assignee {
        id
        name
      }
      coworkers {
        id
        name
      }
      observers {
        id
        name
      }
      closed
      expired
      entities {
        ... on Contact {
          __typename
          id
          name
        }
        ... on Deal {
          __typename
          id
          propertyType
        }
        ... on Offer {
          __typename
          id
          name
        }
        ... on Property {
          __typename
          id
          address {
            value
          }
        }
        ... on DealOffer {
          __typename
          id
          offer {
            property {
              address {
                value
              }
            }
          }
        }
        ... on Contract {
          __typename
          id
          no
          type
        }
      }
      permissions {
        canAddCommentToTask
        canAddCoworkerToTask
        canAddObserverToTask
        canCloseTask
        canReassignTask
        canRemoveCoworkerFromTask
        canRemoveObserverFromTask
        canReopenTask
        canRescheduleTask
        canUpdateTask
      }
      documents {
        fileName
        fileSize
        mimeType
        name
        path
      }
    }
  }
`;

