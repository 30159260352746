import gql from 'graphql-tag';
import {executeQuery} from '../utils';

export const contractsListQuery = gql`
  query contracts(
    $filter: ContractFilter
    $offset: Int
    $limit: Int
    $order: OrderBy
    $source: Source
  ) {
    contracts(
      filter: $filter
      offset: $offset
      limit: $limit
      order: $order
      source: $source
    ) {
      items {
        id
        no
        startAt
        endAt
        assignees {
          id
          name
        }
        comment
        commission
        renewalTerms
        additionalAgreements
        createdAt
        updatedAt
        documents {
          fileName
          fileSize
          mimeType
          name
          path
        }
        type
      }
      totalCount
    }
  }
`;

export const contractQuery = gql`
  query contract($id: ID!) {
    contract(id: $id) {
      id
      no
      startAt
      endAt
      assignees {
        id
      }
      comment
      commission
      renewalTerms
      additionalAgreements
      createdAt
      updatedAt
      documents {
        fileName
        fileSize
        mimeType
        name
        path
      }
      type
      contact {
        id
        name
        phone
        email
      }
      properties {
        id
      }
    }
  }
`;

export const queryContractsAxios = (query, conf = {}) => {
  return executeQuery(
    `query contracts($filter: ContractFilter, $offset: Int, $limit: Int, $order: OrderBy, $source: Source) {
      contracts(filter: $filter, offset: $offset, limit: $limit, order: $order, source: $source) {
        items {
          id
          no
          startAt
          endAt
          assignees {
            id
            name
          }
          comment
          commission
          renewalTerms
          additionalAgreements
          createdAt
          updatedAt
          documents {
            fileName
            fileSize
            mimeType
            name
            path
          }
          type
        }
        totalCount
      }
    }`,
    {...query},
    conf
  );
};

export const queryContractAxios = query => {
  return executeQuery(
    `query contract($id: ID!) {
      contract(id: $id) {
        id
        no
        startAt
        endAt
        assignees {
          id
        }
        comment
        commission
        renewalTerms
        additionalAgreements
        createdAt
        updatedAt
        contact {
          id
          name
        }
        documents {
          fileName
          fileSize
          mimeType
          name
          path
        }
        properties {
          id
        }
        type
      }
    }`,
    {...query}
  );
};

export const queryContractAssignees = id => {
  return executeQuery(
    `query contract($id: ID!) {
      contract(id: $id) {
        assignees {
          id
          name
          email
          phone
        }
      }
    }`,
    {id}
  );
};

export const queryContractDocuments = id => {
  return executeQuery(
    `query contract($id: ID!) {
      contract(id: $id) {
        documents {
          fileName
          fileSize
          mimeType
          name
          path
        }
      }
    }`,
    {id}
  );
};

export const queryContractContact = id => {
  return executeQuery(
    `query contract($id: ID!) {
      contract(id: $id) {
        contact {
          id
        }
      }
    }`,
    {id}
  );
};

export const queryContractProperties = id => {
  return executeQuery(
    `query contract($id: ID!) {
      contract(id: $id) {
        properties {
          id
          status
          additionalTypes
          purposes
          address {
            value
            street
            building {
              number
              block
              letter
            }
          }
          type {
            value
          }
          area {
            amount
            unit
          }
          photos
          layouts
          description
          createdAt
          updatedAt
        }
      }
    }`,
    {id}
  );
};
