import React, {Component} from 'react';
import Inputmask from 'inputmask';

class MaskedInput extends Component {
  componentDidMount() {
    if (this.$elem) {
      const input = Inputmask({
        mask: this.props.mask,
        showMaskOnFocus: true,
        showMaskOnHover: false,
      });
      input.mask(this.$elem);
    }
  }

  render() {
    const {inputRef, ...props} = this.props;

    return (
      <input
        {...props}
        ref={elem => {
          this.$elem = elem;
          if (inputRef) {
            inputRef(elem);
          }
        }}
      />
    );
  }
}

export default MaskedInput;
