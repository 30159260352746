import React, {Component} from 'react';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import {NavLink} from 'react-router-dom';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';
import AccessComponent from './AccessComponent';
import {getMenuSettings} from '../utils';

class AppMenu extends Component {
  render() {
    const {classes, onClose, onTeamAdd, onTeamEdit, open, user, version} = this.props;

    const menuItems = [
      {divider: true},
      {
        to: '/news',
        exact: true,
        label: 'Обновления',
        icon: icons.Mood,
      },
      {
        to: '/favorites',
        exact: true,
        label: 'Избранное',
        icon: icons.Star,
      },
      {
        to: '/analytics',
        label: 'Аналитика',
        icon: icons.FilterList,
      },
      {
        to: '/tasks',
        label: 'Задачи',
        icon: icons.Event,
      },
      {
        to: '/reference',
        label: 'Справочники',
        icon: icons.BookmarkOutlined,
        access: {manager: true},
      },
      {
        to: '/pressRelease',
        label: 'Пресс-релизы',
        icon: icons.PostAddOutlined,
      },
      {
        to: '/publicDeals',
        label: 'Наши сделки',
        icon: icons.BusinessCenterOutlined,
        access: {manager: true},
      },
      {
        to: '/netRenters',
        label: 'Сетевые арендаторы',
        icon: icons.StoreMallDirectory,
      },
      {divider: true},
      {
        to: '/properties',
        label: 'Объекты',
        icon: icons.Business,
      },
      {
        to: '/offers',
        label: 'Листинги',
        icon: icons.ViewList,
      },
      {
        to: '/deals',
        label: 'Заявки',
        icon: icons.Inbox,
        hidden: true,
        settings: 'deals',
      },
      {
        to: '/requests',
        label: 'Заявки beta',
        icon: icons.InboxOutlined,
        hidden: true,
        settings: 'requests',
      },
      {
        to: '/dealOffers',
        label: 'Сделки',
        icon: icons.CardTravelOutlined,
      },
      {
        to: '/contacts',
        label: 'Контакты',
        icon: icons.Contacts,
      },
      {
        to: '/contracts',
        label: 'Договоры',
        icon: icons.Assignment,
      },
    ];
    const menu = getMenuSettings(user);
    const isSingleDealSection = (menu.deals && !menu.requests) || (!menu.deals && menu.requests);

    if (!menu.deals && !menu.requests) {
      menuItems.forEach(function (item, i) {
        if (item.to === '/analytics') {
          menuItems[i].hidden = true;
        }
      });
    } else {
      menuItems.forEach(function (item, i) {
        if (item.settings) {
          if (isSingleDealSection) {
            menuItems[i].label = 'Заявки';
          }
          if (menu[item.settings]) {
            menuItems[i].hidden = false;
          }
        }
      });
    }

    return (
      <Drawer
        anchor="left"
        classes={{paper: classes.menuPaper}}
        onClick={onClose}
        onClose={onClose}
        open={open}
      >
        <List>
          <ListItem
            activeClassName={classes.selectedMenuItem}
            button
            className={classes.menuItem}
            component={NavLink}
            disabled={false}
            exact={true}
            to={'/'}
          >
            <ListItemIcon>
              <icons.Dashboard className={classes.icon}/>
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.label}}
            >
              CRENOW.IS <span className={classes.version}>{version}</span>
            </ListItemText>
          </ListItem>
          {menuItems.map((item, i) =>
            item.divider ? (
              <Divider key={i}/>
            ) : item.access ? (
              <AccessComponent manager={item.access.manager} key={i}>
                <ListItem
                  activeClassName={classes.selectedMenuItem}
                  button
                  className={classes.menuItem}
                  component={NavLink}
                  disabled={!!item.disabled}
                  exact={!!item.exact}
                  to={item.to}
                >
                  <ListItemIcon>
                    <item.icon className={classes.icon}/>
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    classes={{primary: classes.label}}
                  />
                </ListItem>
              </AccessComponent>
            ) : (
              <ListItem
                activeClassName={classes.selectedMenuItem}
                button
                className={classes.menuItem + (item.hidden ? ' _hidden' : '')}
                component={NavLink}
                disabled={!!item.disabled}
                exact={!!item.exact}
                key={i}
                to={item.to}
              >
                <ListItemIcon>
                  <item.icon className={classes.icon}/>
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  classes={{primary: classes.label}}
                />
              </ListItem>
            )
          )}
        </List>
        <Divider/>
        <List>
          {user
            ? user.teams.map((team, i) => {
              return (
                <ListItem
                  activeClassName={classes.selectedMenuItem}
                  button
                  className={classes.menuItem}
                  component={NavLink}
                  key={i}
                  to={`/teams/${team.id}/members`}
                >
                  <ListItemIcon>
                    <Badge badgeContent={team.memberCount || 0}>
                      <icons.People className={classes.icon}/>
                    </Badge>
                  </ListItemIcon>
                  <ListItemText
                    primary={team.name}
                    classes={{primary: classes.label}}
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => onTeamEdit(team.id)}>
                      <icons.Edit/>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
            : null}
          <ListItem className={classes.menuItem}>
            <ListItemIcon>
              <icons.PeopleOutline className={classes.icon}/>
            </ListItemIcon>
            <ListItemSecondaryAction>
              <IconButton onClick={() => onTeamAdd()}>
                <icons.Add/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Drawer>
    );
  }
}

const styles = () => ({
  menuPaper: {
    maxWidth: 320,
    width: '80%',
  },
  icon: {
    color: 'inherit',
  },
  label: {
    fontWeight: 'inherit',
  },
  menuItem: {
    color: colors.grey[500],
    fontWeight: 400,
    '&._hidden': {
      display: 'none',
    },
  },
  version: {
    fontSize: 11,
    opacity: .5,
  },
  selectedMenuItem: {
    color: colors.deepOrange['A400'],
    fontWeight: 500,
    /*pointerEvents: 'none',
    cursor: 'default',*/
  },
});

export default withStyles(styles)(AppMenu);
