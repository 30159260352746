import React, {Component} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Field} from 'redux-form';

import TextField from '../../../components/Input/TextField';
import DatePicker from '../../../components/Input/DatePicker';
import CheckboxInput from '../../../components/Input/Checkbox';

class ReportForm extends Component {
  render() {
    return (
      <React.Fragment>
        <Field
          label="Номер объекта в базе"
          style={{display: 'none'}}
          name="xml_id"
          margin="normal"
          fullWidth
          component={TextField}
        />
        <Field
          name="treatment"
          select
          label="Обращение"
          fullWidth
          margin="normal"
          component={TextField}
          format={value => value || []}
        >
          <MenuItem value="Уважаемый">
            Господин
          </MenuItem>
          <MenuItem value="Уважаемая">
            Госпожа
          </MenuItem>
        </Field>
        <Field
          label="Собственник"
          name="owner"
          margin="normal"
          fullWidth
          required
          component={TextField}
          inputProps={{
            required: true,
          }}
        />
        <Field
          label="Взят в работу"
          name="taken"
          format={null}
          component={props => (
            <DatePicker
              format="yyyy-MM-dd"
              clearable
              {...props}
            />
          )}
          fullWidth
          required
          margin="normal"
          inputProps={{
            required: true,
          }}
        />
        <Field
          label="Выставлен в маркетинг"
          name="market"
          format={null}
          component={props => (
            <DatePicker
              format="yyyy-MM-dd"
              clearable
              {...props}
            />
          )}
          fullWidth
          margin="normal"
          required
          inputProps={{
            required: true,
          }}
        />
        <Field
          label="Количество площадок"
          name="places"
          margin="normal"
          fullWidth
          component={TextField}
          inputProps={{
            type: 'number',
            min: 0,
          }}
        />
        <Field
          label="Cубагентских предложений"
          name="sentences"
          margin="normal"
          fullWidth
          component={TextField}
          inputProps={{
            type: 'number',
            min: 0,
          }}
        />
        <Field
          label="Премиальное размещение"
          name="premium"
          margin="normal"
          fullWidth
          component={TextField}
          inputProps={{
            type: 'number',
            min: 0,
          }}
        />
        <Field
          label="Выслано коммерческих предложений"
          name="commercial"
          margin="normal"
          fullWidth
          component={TextField}
          inputProps={{
            type: 'number',
            min: 0,
          }}
        />
        <Field
          name="type"
          select
          label="Тип договора"
          fullWidth
          margin="normal"
          component={TextField}
          format={value => value || []}
        >
          <MenuItem value="Эксклюзив">
            Эксклюзив
          </MenuItem>
          <MenuItem value="Коэксклюзив">
            Ко-эксклюзив
          </MenuItem>
          <MenuItem value="Неэксклюзив">
            Неэксклюзив
          </MenuItem>
        </Field>
        <Field
          name="banner"
          select
          label="Баннер на объекте"
          fullWidth
          margin="normal"
          component={TextField}
          format={value => value || []}
        >
          <MenuItem value="none">
            Не выбрано
          </MenuItem>
          <MenuItem value="Размещен">
            Размещен
          </MenuItem>
          <MenuItem value="Не размещен">
            Не размещен
          </MenuItem>
        </Field>
        <Field
          label="Просмотров на площадке"
          name="views"
          margin="normal"
          fullWidth
          component={TextField}
          inputProps={{
            type: 'number',
            min: 0,
          }}
        />
        <Field
          label="Получено звонков"
          name="calls"
          margin="normal"
          fullWidth
          component={TextField}
          inputProps={{
            type: 'number',
            min: 0,
          }}
        />
        <Field
          label="Показов объекта"
          name="objectViews"
          margin="normal"
          fullWidth
          component={TextField}
          inputProps={{
            type: 'number',
            min: 0,
          }}
        />
        <Field
          label="Подготовлено оферт"
          name="offers"
          margin="normal"
          fullWidth
          component={TextField}
          inputProps={{
            type: 'number',
            min: 0,
          }}
        />
        <Field
          label="Лучшее ценовое предложение"
          name="bestPrice"
          margin="normal"
          fullWidth
          component={TextField}
          inputProps={{
            type: 'number',
            min: 0,
          }}
        />
        <Field
          label="Положительные особенности"
          name="positive"
          component={TextField}
          multiline
          rowsMax={10}
          fullWidth
          margin="normal"
          placeholder="Не более 4 пунктов"
          rows="3"
          inputProps={{
            maxLength: 350,
          }}
        />
        <Field
          label="Отрицательные особенности"
          name="negative"
          component={TextField}
          multiline
          rowsMax={10}
          fullWidth
          margin="normal"
          placeholder="Не более 4 пунктов"
          rows="3"
          inputProps={{
            maxLength: 350,
          }}
        />
        <Field
          label="Рекомендации"
          name="recomendations"
          component={TextField}
          multiline
          rowsMax={10}
          fullWidth
          margin="normal"
          placeholder="Не более 6 пунктов"
          rows="3"
          inputProps={{
            maxLength: 500,
          }}
        />
        <Field
          label="Телефон агента"
          name="agent_phone"
          margin="normal"
          fullWidth
          required
          component={TextField}
          inputProps={{
            required: true,
          }}
        />
        <Field
          label="Email агента"
          name="agent_email"
          margin="normal"
          fullWidth
          required
          component={TextField}
          inputProps={{
            required: true,
            type: 'email',
          }}
        />
        <Field
          label="Адрес офиса агента"
          name="agent_address"
          margin="normal"
          fullWidth
          component={TextField}
        />
        <Field
          label="Отчет составил"
          name="built"
          margin="normal"
          fullWidth
          required
          component={TextField}
          inputProps={{
            required: true,
          }}
        />
        <FormControl margin="none">
          <FormControlLabel
            control={<Field name="owner-sign" type="checkbox" required component={Checkbox}/>}
            label="Подпись об ознакомлениии"
          />
        </FormControl>
        <FormControl margin="none">
          <FormControlLabel
            control={<Field name="nak" type="checkbox" component={CheckboxInput}/>}
            label="Шаблон НАК"
          />
        </FormControl>
      </React.Fragment>
    );
  }
}

export default ReportForm;
