import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '../../../components/Input/TextField';
import DatePicker from '../../../components/Input/DatePicker';
import TeamSelect from '../../team/TeamSelect';
import AccessComponent from '../../../components/AccessComponent';

class FilterPublicDeal extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      onSubmit,
      open,
      pristine,
      teamMembers,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Настройки фильтра</DialogTitle>
        <Divider />
        <DialogContent>
          <Field name="searchQuery" label="Фраза для поиска" component={TextField} fullWidth margin="normal" />
          <Field
            name="date"
            label="Дата сделки"
            format={null}
            component={props => <DatePicker format="yyyy-MM-dd" clearable {...props} />}
            fullWidth
            margin="normal"
          />
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="createdAtFrom"
                  label="Создан с"
                  format={null}
                  component={props => <DatePicker format="yyyy-MM-dd" clearable {...props} />}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="createdAtTo"
                  label="по"
                  format={null}
                  component={props => <DatePicker format="yyyy-MM-dd" clearable {...props} />}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
          </Grid>
          <AccessComponent manager>
            <Field
              name="member"
              label="Создатель"
              component={TextField}
              select
              fullWidth
              margin="normal"
            >
              {
                teamMembers.map(({id, name}, index) => (
                  <MenuItem key={index} value={id}>{name}</MenuItem>
                ))}
            </Field>
          </AccessComponent>
          <AccessComponent admin>
            <Field name="team" label="Команда" component={TeamSelect} fullWidth margin="normal" />
          </AccessComponent>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button type="button" color="primary" disabled={submitting} onClick={onClose}>
            Отменить
          </Button>
          <Button type="button" color="primary" disabled={submitting || pristine} onClick={handleSubmit(onSubmit)}>
            Применить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

}

const styles = _theme => ({
  searchInput: {
    marginTop: 10,
  },
  MenuItemContainer: {
    zIndex: 1,
    position: 'absolute',
    width:'100%',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  MenuItemWrapper: {
    position: 'relative',
  },
});

FilterPublicDeal = connect(state => ({
  teamMembers: state.root.selfUser.teamMembers,
}),
{}
)(FilterPublicDeal);

export default reduxForm({
  form: 'filter_publicDeal',
  enableReinitialize: true,
})(withStyles(styles)(FilterPublicDeal));
