import axios from 'axios';
import qs from 'qs';
import {format} from 'date-fns';
import {queryDealList} from '../queries/index';
import {queryDealOfferList} from '../queries/DealOffers';
import {queryContractsAxios} from '../queries/ContractsQuery';

export const downloadCsv = async (entity, filter, exportFormat) => {
  const queryFilter = {
    ...filter,
    export: exportFormat.toUpperCase(),
  };

  const response = await axios({
    url: `/api/${entity}?` + qs.stringify(queryFilter),
    method: 'GET',
    responseType: 'blob',
  });

  const url = await window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const today = format(new Date(), 'dd-MM-yyyy');
  link.setAttribute('download', `${entity}-${today}.${exportFormat}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadCsvDeals = async (query, exportFormat) => {
  const qs = {
    ...query,
    filter: {
      ...query.filter,
      export: exportFormat.toUpperCase(),
    },
  };

  const response = await queryDealList(qs, {responseType: 'blob'});
  const url = await window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const today = format(new Date(), 'dd-MM-yyyy');
  link.setAttribute('download', `deals-${today}.${exportFormat}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadCsvDealOffers = async (query, exportFormat) => {
  const qs = {
    ...query,
    filter: {
      ...query.filter,
      export: exportFormat.toUpperCase(),
    },
  };

  const response = await queryDealOfferList(qs, {responseType: 'blob'});
  const url = await window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const today = format(new Date(), 'dd-MM-yyyy');
  link.setAttribute('download', `dealOffers-${today}.${exportFormat}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadCsvContracts = async (query, exportFormat) => {
  const qs = {
    ...query,
    filter: {
      ...query.filter,
      export: exportFormat.toUpperCase(),
    },
  };

  const response = await queryContractsAxios(qs, {responseType: 'blob'});
  const url = await window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const today = format(new Date(), 'dd-MM-yyyy');
  link.setAttribute('download', `contracts-${today}.${exportFormat}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
