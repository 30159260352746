import React, {Component} from 'react';
import {connect} from 'react-redux';
import OfferList from './OfferList';
import OfferListItems from './OfferListItems';
import FavoritesWrapper from '../../is/favorites/containers/FavoritesWrapper';

class OfferListWrapper extends Component {
  render() {
    const {offers} = this.props;

    return (
      <OfferList {...this.props}>
        {offers ? (
          <FavoritesWrapper items={Object.keys(offers)}>
            <OfferListItems/>
          </FavoritesWrapper>
        ) : null}
      </OfferList>
    );
  }
}

export default OfferListWrapper = connect(
  state => ({
    offers: state.root.offer.offers,
  }),
  {}
)(OfferListWrapper);
