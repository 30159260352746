import React, {Component} from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import {Divider} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';

import axios from 'axios';

import UpdateEdit from './UpdateEdit';
import Contacts from './Contacts';
import Video from '../../components/Video';
import {setTitle} from '../../actions';
import {queryUpdateList} from '../../queries/UpdateQuery';
import {formatFullDate} from '../../utils';
import {hasRole} from '../../utils/roleFunc';
import * as userRoles from '../../constants/userRoles';

class Dashboard extends Component {
  state = {
    openAdd: false,
    items: [],
    totalCount: 0,
    page: 0,
    editItem: null,
  };

  getList = id => {
    let filter = {
      offset: this.PER_PAGE * this.state.page,
      limit: this.PER_PAGE,
    };
    if(id)
      filter = {
        id: parseInt(id),
      };
    queryUpdateList(filter).then(resp => {
      let {items, totalCount, nav} = resp.data.data.systemUpdateList;
      this.setState({
        page: nav.offset / nav.limit,
        items,
        totalCount,
      });
      this.PER_PAGE = nav.limit;
      if(id)
        setTimeout(() => {
          let item = document.getElementById(`item${id}`);
          if(item)
            this.$content.scrollTop = item.offsetTop;
        }, 0);
    });
  };

  PER_PAGE = 10;

  componentDidMount() {
    this.getList(this.props.match.params.updateId);
  }

  onClickCaption = item => {
    this.setState({
      editItem: item,
    });
  };

  handlePrevPage = () => {
    this.setState({
      page: this.state.page - 1,
    }, this.getList);
  };

  handleNextPage = () => {
    this.setState({
      page: this.state.page + 1,
    }, this.getList);
  };

  deleteUpdate = id => {
    axios.post('/api/v1/deleteSystemUpdate', {id}).then(() => {
      this.setState({
        page: 0,
      }, this.getList);

    });
  };

  render() {
    const {classes, selfUser} = this.props;
    if(!selfUser)
      return;
    const {
      openAdd,
      items,
      editItem,
      page,
      totalCount} = this.state;
    return (
      <div className="row">
        {hasRole(selfUser.role, userRoles.ADMIN)
          ? <Fab
            color="secondary"
            className={classes.fab}
            onClick={() => this.setState({openAdd: true})}
          >
            <icons.Add />
          </Fab>
          : null}
        {openAdd
          ? <UpdateEdit
            handleClose={()=>this.setState({openAdd: false})}
            onSubmitted={() => {
              this.getList();
              this.setState({openAdd: false});
            }}

          />
          : null}
        {editItem
          ? <UpdateEdit
            handleClose={()=>this.setState({editItem: null})}
            onSubmitted={() => {
              this.getList();
              this.setState({editItem: null});
            }}
            id={editItem.id}
            item={editItem}
          />
          : null}
        <div
          className="column" ref={$content => {
            this.$content = $content;
          }}
          style={{background: colors.grey[200], flex: 2, overflow: 'auto'}}
        >
          <Paper square elevation={0} className={classes.root}>
            <Contacts/>
          </Paper>
          <Divider/>
          {items.map((item, index) => {
            return(
              <Paper square elevation={0} className={classes.root} key={index}>
                {hasRole(selfUser.role, userRoles.ADMIN) ?
                  <Typography
                    className={classes.captionItem}
                    variant="subtitle1"
                    id={`item${item.id}`}
                  >
                    {formatFullDate(item.dateTime) + ` (${item.major}.${item.minor})`}
                    <icons.Edit
                      onClick={() => hasRole(selfUser.role, userRoles.ADMIN) && this.onClickCaption(item)}
                      className={classes.editIcon}/>
                    <icons.Delete
                      className={classes.editIcon}
                      onClick={e => {
                        e.stopPropagation();
                        this.deleteUpdate(item.id);
                      }}/>
                  </Typography>
                  : (
                    <Typography
                      variant="subtitle1"
                      id={`item${item.id}`}
                    >
                      {formatFullDate(item.dateTime) + ` (${item.major}.${item.minor})`}
                    </Typography>
                  )}
                <Typography className={classes.description}>{item.description}</Typography>
                <Video url={item.video} id={item.id}/>
              </Paper>
            );
          })}
          <Divider/>
          <Paper square elevation={0} className={classes.pagination}>
            {items.length > 0 ? (
              <Toolbar>
                <IconButton
                  style={{marginLeft: -20}}
                  disabled={page < 1}
                  onClick={this.handlePrevPage}
                >
                  <icons.ChevronLeft/>
                </IconButton>
                <IconButton
                  disabled={page >= Math.floor((totalCount - 1) / this.PER_PAGE)}
                  onClick={this.handleNextPage}
                >
                  <icons.ChevronRight/>
                </IconButton>
                <Typography variant="caption">
                  {`${page * this.PER_PAGE + 1} — ${(page + 1) * this.PER_PAGE > totalCount ? totalCount : (page + 1) * this.PER_PAGE} из ${totalCount}`}
                </Typography>
              </Toolbar>
            ) : null}
          </Paper>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  pagination: {
    ...theme.mixins.gutters(),
  },
  description: {
    whiteSpace: 'pre-wrap',
  },
  list: {
    paddingLeft: '10px',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    zIndex: 10,
    right: theme.spacing.unit * 3,
  },
  editIcon: {
    fontSize: '1rem',
    marginLeft: 8,
    color: colors.grey[600],
    cursor: 'pointer',
  },
  captionItem: {
    '&:hover $editIcon': {
      visibility: 'visible',
    },
  },
});

export default connect(({root}) => ({
  selfUser: root.selfUser,
}), {setTitle})(withStyles(styles)(Dashboard));
