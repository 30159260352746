import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as icons from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as colors from '@material-ui/core/colors';
import axios from 'axios';

import OfferListItem from '../../components/relations/OfferListItem';
import ContactListItem from '../../components/relations/ContactListItem';
import Variant from '../../components/Input/Variant';
import {mapLabels} from '../../utils';
import base64url from '../../utils/base64url';
import {
  deleteOfferFromDealSelection,
  startChangeSelection,
  finishChangeSelection,
  loadDealSelection,
} from '../../actions';

import SnackbarError from '../../components/SnackbarError';

class DealDialog extends Component {

  state = {
    selection: null,
    send: false,
    selfSend: false,
    errorShow: false,
    errorMessage: '',
    error: false,
  };

  componentDidMount() {
    const {match, initialize, offers} = this.props;
    if(match.params.selectionId){
      this.getCurrentSelection(this.props);
      initialize({
        send: false,
        selfSend: false,
        offers,
      });
    }
  }

  componentWillReceiveProps(props) {
    if(props.selections.length !== this.props.selections.length || (!props.loading && this.props.loading) || (props.error !== this.props.error)){
      this.getCurrentSelection(props);
    }
  }

  getCurrentSelection = props => {
    const {
      match,
      selections,
      startChangeSelection,
      variableSelection,
      loading,
      error,
      loadDealSelection,
    } = props;

    if(match.params.selectionId){
      if(variableSelection){
        return;
      }
      if(error){
        return;
      }
      if(loading)
        return;
      if(selections.length === 0)
        return;
      let currentSelection = selections.find(selection => selection.id === parseInt(match.params.selectionId));
      if(currentSelection){
        startChangeSelection(currentSelection);
      } else {
        loadDealSelection(parseInt(match.params.selectionId));
      }
    }
  };

  handleSave = () => {
    if(this.props.match.params.selectionId)
      this.update();
    else
      this.create();
  };

  create = () => {
    const {offers, contact, dealId, match, history} = this.props;
    axios.post('/api/v1/createDealOffersSelection', {
      deal: dealId,
      contact: contact.id,
      offers: offers.map(offer => offer.id),
      sendEmail: this.state.send,
      blindCopy: this.state.selfSend,
    }).then(
      () => {
        this.props.onSubmitted && this.props.onSubmitted();
        localStorage.removeItem(`deal_offers_selected_${dealId}`);
        history.push(`/deals/${match.params.dealId}/`);
      })
      .catch(error => {
        if(error.response && error.response.status === 400)
          this.setState({
            errorShow: true,
            errorMessage: error.response.data.error,
          });
        else{
          this.setState({
            errorShow: true,
            errorMessage: 'Неизвестная ошибка сервера',
          });
        }
      });
  };

  update = () => {
    const {
      variableSelection,
      finishChangeSelection,
      history,
      match,
      handleSubmit,
    } = this.props;
    const {send, selfSend} = this.state;
    axios
      .post('/api/v1/updateDealOffersSelection', {
        id: parseInt(variableSelection.id),
        offers: variableSelection.offers.map(offer => offer.id),
        sendEmail: send,
        blindCopy: selfSend,
      })
      .then(() => {
        finishChangeSelection();
        history.push(`/deals/${match.params.dealId}/`);
        handleSubmit && handleSubmit();
      })
      .catch(error => {
        if(error.response && error.response.status === 400)
          this.setState({
            errorShow: true,
            errorMessage: error.response.data.error,
          });
        else{
          this.setState({
            errorShow: true,
            errorMessage: 'Неизвестная ошибка сервера',
          });
        }
      });
  };

  changeOffers = () => {
    const {
      match,
      history,
      deal,
      variableSelection,
    } = this.props;

    const filter = {
      type: deal.offerType,
    };
    history.push(
      `/deals/${match.params.dealId}/selections/${variableSelection.id}/change/?${base64url.encode(JSON.stringify(filter))}`
    );
  };

  selectionOfferDelete = offer => {
    this.props.deleteOfferFromDealSelection(offer.id);
  };

  render() {
    const {
      classes,
      handleClose,
      open,
      offers,
      handleDeleteItem,
      contact,
      selfUser,
      match,
      variableSelection,
      pristine,
      change,
      offerChange,
    } = this.props;

    const {
      selfSend,
      errorMessage,
      errorShow,
      send,
      error,
    } = this.state;

    let disabled = false;
    let disabledDelete = false;
    if(match.params.selectionId){
      if(variableSelection && variableSelection.offers.length < 2){
        disabledDelete = true;
      }
      disabled = true;
      if(variableSelection && selfUser.id === variableSelection.createdBy.id){
        disabled = false;
      }
    }
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{paper: classes.dialog}}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div className={classes.dialogTitleWrap}>
            <div className={classes.dialogText}>
              <Typography variant="h6">Подборка листингов</Typography>
            </div>
            <div className={classes.dialogBtn}>
              <IconButton
                onClick={handleClose}
                classes={{root: classes.dialogIcon}}
              >
                <icons.Close/>
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <Divider/>
        {!match.params.selectionId ?
          <DialogContent className={classes.dialogContent}>
            <List>
              <ContactListItem
                showEmail
                contactFieldName={'formattedPersonName'}
                contact={contact}
              />
              <Divider/>
              <Variant
                selected={send}
                label={'Отправить письмо на почту контакта'}
                onClick={() => {
                  this.setState({
                    send: !this.state.send,
                  });
                }}
              />
              {send ?
                <Variant
                  selected={selfSend}
                  label={'Скрытая копия письма себе'}
                  onChange={e => {
                    this.setState({
                      selfSend: e.target.checked,
                    });
                  }}
                  onClick={() => {
                    this.setState({
                      selfSend: !this.state.selfSend,
                    });
                  }}
                />
                : null}
              <Divider/>
              {offers.map(offer =>
                <React.Fragment key={offer.id}>
                  <div style={{position: 'relative'}}>
                    <OfferListItem
                      offer={offer}
                    />
                    <IconButton
                      className={classes.deleteItemButton}
                      onClick={() => handleDeleteItem(offer)}
                    >
                      <icons.Delete/>
                    </IconButton>
                  </div>
                  <Divider/>
                </React.Fragment>
              )}
            </List>
          </DialogContent>
          : error ?
            <React.Fragment>
              <DialogContent className={classes.flexWrap}>
                <IconButton disabled>
                  <icons.ErrorOutline/>
                </IconButton>
              </DialogContent>
            </React.Fragment>
            : !variableSelection ? (
              <React.Fragment>
                <div
                  style={{
                    display: 'flex',
                    height: '200px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <icons.Lock
                    style={{color: colors.grey[400], width: 32, height: 32}}
                  />
                  <CircularProgress
                    style={{color: colors.grey[400], position: 'absolute'}}
                    size={50}
                    thickness={2}
                  />
                </div>
              </React.Fragment>
            ) : (
              <DialogContent className={classes.dialogContent}>
                <List>
                  <ContactListItem
                    showEmail
                    contactFieldName={'formattedPersonName'}
                    contact={variableSelection.contact}
                  />
                  <Divider/>
                  <Field
                    component={Variant}
                    name="send"
                    value={send}
                    props={{
                      selected: send,
                      disabled: disabled,
                      label: 'Отправить письмо на почту контакта',
                    }}
                    onClick={() => {
                      change('send', !this.state.send);
                      this.setState({
                        send: !this.state.send,
                      });
                    }}
                  />
                  {send ?
                    <Field
                      component={Variant}
                      name="selfSend"
                      props={{
                        selected: selfSend,
                        disabled: disabled,
                        label: 'Скрытая копия письма себе',
                      }}
                      onClick={() => {
                        this.setState({
                          selfSend: !this.state.selfSend,
                        });
                      }}
                    />
                    : null}
                  <Divider/>
                  {variableSelection.offers.map(offer =>
                    <React.Fragment key={offer.id}>
                      <div style={{position: 'relative'}}>
                        <OfferListItem
                          offer={offer}
                        />
                        <IconButton
                          disabled={disabled || disabledDelete}
                          className={classes.deleteItemButton}
                          onClick={() => this.selectionOfferDelete(offer)}
                        >
                          <icons.Delete/>
                        </IconButton>
                      </div>
                      <Divider/>
                    </React.Fragment>
                  )}
                </List>
              </DialogContent>
            )}
        <DialogActions>
          {variableSelection ?
            <Button
              className={classes.selectButton}
              type="button"
              disabled={disabled}
              onClick={this.changeOffers}
            >
              Изменить
            </Button>
            : null}
          <Button
            type="button"
            disabled={disabled || (variableSelection && pristine && !offerChange) || (!variableSelection && offers.length === 0)}
            onClick={this.handleSave}
          >
            Сохранить
          </Button>
          <Button
            type="button"
            onClick={handleClose}
          >
            Отменить
          </Button>
        </DialogActions>
        <SnackbarError
          open={errorShow}
          onClose={() => this.setState({errorShow: false})}
          errorMessage={errorMessage}
        />
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialog: {
    width: 550,
    maxWidth: '100%',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100%',
    },
  },
  dialogIcon: {
    width: 36,
    height: 36,
    padding: 4,
  },
  dialogTitleWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 0,
    minHeight: 150,
  },
  dialogTitle: {
    padding: '12px 24px 8px 24px',
  },
  dialogBtn: {
    marginRight: -12,
  },
  deleteItemButton: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    right: 12,
  },
  selectButton: {
    position: 'absolute',
    left: 16,
  },
  flexWrap: {
    padding: 0,
    minHeight: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

DealDialog = reduxForm({
  form: 'selection',
})(DealDialog);

export default connect(
  state => ({
    offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    selections: state.root.selection.selections,
    loading: state.root.selection.selectionStatus.loading,
    offerChange: state.root.selection.selectionStatus.offerChange,
    error: state.root.selection.selectionStatus.error,
    variableSelection: state.root.selection.variableSelection,
    selfUser: state.root.selfUser,
  }), {
    deleteOfferFromDealSelection,
    startChangeSelection,
    finishChangeSelection,
    loadDealSelection,
  }
)(withStyles(styles)(DealDialog));
