import React, {Component} from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import debounce from 'lodash/debounce';

class Row extends Component {
  state = {
    selected: 0,
    visible: undefined,
  };

  componentDidMount() {
    this.setState({visible: Math.floor(window.innerWidth / 320)});

    window.onresize = debounce(() => {
      this.setState({visible: Math.floor(window.innerWidth / 320)});
    }, 166);
  }

  render() {
    const {children, classes} = this.props;
    const {selected, visible} = this.state;

    return [
      <div className="row" key={0}>
        {visible >= children.length ? children : children[selected]}
      </div>,
      visible < children.length ? (
        <Paper elevation={3} style={{zIndex: 1}} key={1}>
          <BottomNavigation
            showLabels
            value={selected}
            onChange={(_, value) => {
              this.setState({selected: value});
              /*if (children[value].props.to) {
                                history.push(children[value].props.to);
                              }*/
            }}
            classes={{root: classes.BottomNavigation}}
          >
            {children.map(({props: {label, icon}}, index) => (
              <BottomNavigationAction
                label={label}
                icon={icon}
                value={index}
                classes={{root: classes.BottomNavigationAction}}
                key={index}
              />
            ))}
          </BottomNavigation>
        </Paper>
      ) : null,
    ];
  }
}

Row.propTypes = {
  // children: PropTypes.array.isRequired,
};

const styles = () => ({
  BottomNavigation: {
    width: '100%',
  },
  BottomNavigationAction: {
    maxWidth: 'none',
  },
});

export default withStyles(styles)(Row);
