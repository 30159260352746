import React, {Component} from 'react';
import {connect} from 'react-redux';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';

import {mapLabels} from '../../utils';
import ContractDetails from './ContractDetails';

class ContractDetailsWrapper extends Component {
  state = {
    menuAnchor: null,
  };

  render() {
    const {
      error,
      classes,
      contract,
      onEdit,
      contractTypesLabels,
      onStatusChange,
      onRequirementsChange,
    } = this.props;
    const {menuAnchor} = this.state;

    return (
      <Paper elevation={1} square className="column">
        {contract ? (
          <>
            <CardHeader
              action={
                <IconButton
                  classes={{root: classes.icon}}
                  onClick={({currentTarget}) => {
                    this.setState({menuAnchor: currentTarget});
                  }}
                >
                  <icons.MoreVert />
                </IconButton>
              }
              title={contract.no}
              subheader={contractTypesLabels[contract.type]}
            />
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClick={() => {
                this.setState({menuAnchor: null});
              }}
            >
              <MenuItem onClick={() => onEdit()}>Редактировать</MenuItem>
            </Menu>
            <Divider />
            <ContractDetails
              contract={contract}
              onStatusChange={onStatusChange}
              onRequirementsChange={onRequirementsChange}
            />
          </>
        ) : error ? (
          <icons.ErrorOutline disabled className={classes.progress}/>
        ) : (
          <IconButton disabled>
            <CircularProgress size={24} />
          </IconButton>
        )}
      </Paper>
    );
  }
}

const styles = () => ({
  avatar: {
    background: colors.green[300],
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
});

export default connect(({root}) => ({
  contractTypesLabels: mapLabels(
    root.classification.contractTypes,
    'value',
    'label'
  ),
}))(withStyles(styles)(ContractDetailsWrapper));
