const defaultState = {
  propertyNames: {},
  offerNames: {},
  contactNames: {},
  memberNames: {},
  teamNames: {},
};

export default (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'SET_PROPERTY_NAME': {
      return{
        ...state,
        propertyNames: {
          ...state.propertyNames,
          ...payload,
        },
      };
    }
    case 'SET_OFFER_NAME': {
      return {
        ...state,
        offerNames: {
          ...state.offerNames,
          ...payload,
        },
      };
    }
    case 'SET_CONTACT_NAME': {
      return{
        ...state,
        contactNames: {
          ...state.contactNames,
          ...payload,
        },
      };
    }
    case 'SET_MEMBER_NAME': {
      return{
        ...state,
        memberNames: {
          ...state.memberNames,
          ...payload,
        },
      };
    }
    case 'SET_TEAM_NAME': {
      return{
        ...state,
        teamNames: {
          ...state.teamNames,
          ...payload,
        },
      };
    }
    default:
      return state;
  }
};
