import {executeQuery} from '../utils';

export const querySemanticsItems = query => {
  return executeQuery(
    `query refSemanticsItems($filter: RefSemanticsFilter, $offset: Int, $limit: Int) {
      refSemanticsItems(filter: $filter, offset: $offset, limit: $limit) {
        items {
          id
          type
          purposes
          lookingFor
          team
          archived
        }
        totalCount
      }
    }`,
    {...query}
  );
};
