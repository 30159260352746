import {propertyAlerts, dealAlerts, offerAlerts} from '../constants/alert';
import React from 'react';
import Warning from '../components/Warning';
import AlertIcon from '../components/IconWarning';

const sort = (A, B) => {
  return A.order > B.order ? -1 : 1;
};

function getAlertList(alertsArray, value, source = null){
  let tmp = alertsArray.filter(alert =>
    alert.triggerCondition(value) &&
    (alert.activeStatusWhiteList ? alert.activeStatusWhiteList.find(status => status === value.status): true)
    && (source && alert.activeSourceBlackList ? !alert.activeSourceBlackList.includes(source) : 1)
  );
  return tmp.sort(sort);
}

function getAlertIcon(alerts){
  if(alerts.length > 0){
    let message = alerts.map(element => element.message[element.selectedVariant]).join('\n');
    let color = alerts[0].iconColor[alerts[0].selectedVariant];
    return <AlertIcon message={message} color={color}/>;
  }
}

function getDetailsWarningList(alertsArray, value){
  let tmp = getAlertList(alertsArray, value);
  return tmp.map((element, index) => (
    <Warning
      key={index}
      message={element.message[element.selectedVariant]}
      color={element.warningColor[element.selectedVariant]}
    />
  ));
}

export function getPropertyWarningList(property) {
  return getDetailsWarningList(propertyAlerts, property);
}

export function getDealWarningList(deal){
  return getDetailsWarningList(dealAlerts, deal);
}

export function getOfferWarningList(offer){
  return getDetailsWarningList(offerAlerts, offer);
}

export const getPropertyAlertIcon = function (property, source = null){
  let alerts = getAlertList(propertyAlerts, property, source);
  return getAlertIcon(alerts);
};

export const getDealAlertIcon = function (deal, source = null){
  let alerts = getAlertList(dealAlerts, deal, source);
  return getAlertIcon(alerts);
};

export const getOfferAlertIcon = function (offer, source = null){
  let alerts = getAlertList(offerAlerts, offer, source);
  return getAlertIcon(alerts);
};

