import * as icons from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import React from 'react';

import TextArea from '../../../components/Input/TextArea';

class CommentArea extends React.Component {
  state = {
    saving: false,
    value: '',
  };

  render() {
    const {permissions, onSave} = this.props;
    const {saving, value} = this.state;

    const pristine = value.length === 0;

    return (
      <div style={{padding: '24px 24px 0 24px'}}>
        <TextField
          style={{marginBottom: 8}}
          disabled={saving || !permissions.canAddCommentToTask}
          placeholder="Комментарий..."
          InputProps={{
            inputComponent: TextArea,
            inputProps: {rowsMax: 4},
          }}
          multiline
          fullWidth
          margin="none"
          value={value}
          onChange={e => this.setState({value: e.target.value})}
        />
        {!pristine && permissions.canAddCommentToTask ? (
          <div>
            <Button
              size="small"
              disabled={pristine || saving}
              onClick={() => {
                this.setState({saving: true});
                onSave(value).then(() => this.setState({
                  editing: false,
                  saving: false,
                  value: '',
                }));
              }}
            >
              Сохранить
            </Button>
            <IconButton
              style={{width: 32, height: 32}}
              disabled={pristine || saving}
              onClick={() => this.setState({value: ''})}
            >
              <icons.Close style={{width: 16, height: 16}}/>
            </IconButton>
          </div>
        ) : null}
      </div>
    );
  }
}

const styles = () => ({});

export default withStyles(styles)(CommentArea);
