import React, {Component} from 'react';
import {connect} from 'react-redux';

import {Route, Switch} from 'react-router-dom';
import {setTitle} from '../../actions';
import PersonView from './PersonView';
import PersonSelfView from './PersonSelfView';


class Person extends Component {

  render() {
    const {match} = this.props;
    return (
      <Switch>
        <Route path={`${match.url}/self`} exact component={PersonSelfView}/>
        <Route path={`${match.url}/:memberId`} exact component={PersonView}/>
      </Switch>
    );
  }
}

export default connect(
  null,
  {setTitle}
)(Person);
