import React, {Component} from 'react';
import {connect} from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import * as icons from '@material-ui/icons';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors/index';
import MobilePaginationToolbar from '../../components/MobilePaginationToolbar';
import {
  formatDate,
  mapLabels,
  formatAddress,
} from '../../utils';
import AccessComponent from '../../components/AccessComponent';
import TablePaginationActions from '../../components/TablePaginationActions';
import {downloadCsvDealOffers} from '../../utils/csvFunc';
import TableLink from '../../components/TableLink';

class DealOfferListItems extends Component {
  state = {
    anchorEl: null,
    popoverContent: null,
    openStatusMenu: null,
    openExportMenu: null,
    loading: false,
  };

  render() {
    const {
      classes,
      query,
      dealOffers,
      action,
      offerTypeLabels,
      history,
      totalCount,
      dealOfferActiveStatusLabels,
      propertyTypeLabels,
      propertyPurposeLabels,
      handleRequestSort,
      handleChangePage,
      favList,
      dealOfferActiveStatuses,
      handleChangeStatus,
    } = this.props;

    let dealOfferActiveStatusesFiltered = dealOfferActiveStatuses.filter(status => status.value !== 'closed' && status.value !== 'declined');

    const {order} = query;

    const {anchorEl, popoverContent, openStatusMenu, openExportMenu, loading} = this.state;

    return (
      <React.Fragment>
        <Hidden xsDown>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Листинг</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order && order.column === 'status'}
                    direction={order ? order.direction.toLowerCase() : 'asc'}
                    onClick={() => handleRequestSort('status')}
                    classes={{root: classes.sortableCell}}
                  >
                    Статус
                  </TableSortLabel>
                </TableCell>
                <TableCell>Назначение&nbsp;/ Вид сделки</TableCell>
                <TableCell>Контакт&nbsp;/ Категория</TableCell>
                <TableCell>Ответственный&nbsp;/
                  <TableSortLabel
                    active={order && order.column === 'dealStartAt'}
                    direction={order ? order.direction.toLowerCase() : 'asc'}
                    onClick={() => handleRequestSort('dealStartAt')}
                    classes={{root: classes.sortableCell}}
                  >
                    Дата перехода
                  </TableSortLabel>
                </TableCell>
                <TableCell>Комментарий</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {dealOffers && dealOffers.map(dealOffer => {
                return (
                  <TableLink
                    className={classes.tableRow}
                    key={dealOffer.id}
                    href={`/dealOffers/${dealOffer.id}`}
                    hover
                    onClick={e => {
                      e.preventDefault();
                      history.push(`/dealOffers/${dealOffer.id}`);
                    }}
                  >
                    <TableCell>
                      <div
                        className={classes.status}
                        style={{backgroundColor: (dealOfferActiveStatuses.find(item => item.value === dealOffer.status).color)[400]}}
                      />
                      {formatAddress(dealOffer.offer.property.address)}
                    </TableCell>
                    <TableCell title={dealOfferActiveStatusLabels[dealOffer.status]}>
                      <div className={classes.flexWrapper}>
                        <div className={classes.statusWrapper}>
                          {dealOfferActiveStatusLabels[dealOffer.status]}
                        </div>
                        <div className={classes.flex}/>
                        <icons.MoreVert
                          className={classes.editIcon}
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.openSetStatusForm(e.currentTarget, dealOffer);
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      {propertyPurposeLabels[dealOffer.deal.propertyPurpose]}
                      <Typography variant="caption">
                        {offerTypeLabels[dealOffer.deal.offerType] || dealOffer.offerType}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {dealOffer.deal.contact ? dealOffer.deal.contact.name : null}
                      <Typography variant="caption">
                        {propertyTypeLabels[dealOffer.deal.propertyType]}
                      </Typography>
                    </TableCell>
                    <TableCell title={dealOffer.assignees.map(assignee => assignee.name).join('\n')}>
                      <div className={classes.flexWrapper}>
                        <div>
                          {dealOffer.assignees && dealOffer.assignees.length > 0 ? dealOffer.assignees[0].name : null}
                          <Typography variant="caption">
                            {formatDate(dealOffer.dealStartAt)}
                          </Typography>
                        </div>
                        {dealOffer.assignees && dealOffer.assignees.length > 1 ? (
                          <icons.MoreHoriz
                            onClick={event => this.handlePopoverOpen(event, dealOffer.assignees.map(assignee => assignee.name).join('\n'))}
                            className={classes.popoverIcon}
                          />
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell className={classes.requirementsCell}>
                      <div className={classes.flexWrapper}>
                        <div className={classes.requirementsWrapper}>
                          {dealOffer.comment}
                        </div>
                        {(dealOffer.comment && dealOffer.comment.length > 130) ? (
                          <icons.MoreHoriz
                            onClick={event => this.handlePopoverOpen(event, dealOffer.comment)}
                            className={classes.popoverIcon}
                          />
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell style={{paddingRight: action ? 56 : 24}}>
                      {action ? (
                        <div className={classes.action}>
                          {action(dealOffer, null, 'dealOfferId', favList)}
                        </div>
                      ) : null}
                    </TableCell>
                  </TableLink>
                );
              })}
            </TableBody>
          </Table>
          <div className={classes.pagination}>
            <AccessComponent manager>
              <Button
                size="small"
                className={classes.button}
                onClick={e =>
                  this.setState({openExportMenu: e.currentTarget})
                }
                disabled={loading}
              >
                <icons.ArrowDownward className={classes.leftIcon} />
                      Выгрузить
              </Button>
            </AccessComponent>
            <TablePagination
              rowsPerPage={30}
              component={'div'}
              rowsPerPageOptions={[30]}
              count={totalCount}
              page={query.offset ? Math.floor(query.offset / 30) : 0}
              onChangePage={handleChangePage}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} из ${count}`
              }
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </Hidden>
        <Hidden smUp>
          {dealOffers && dealOffers.map((dealOffer, i) => {
            return (<Paper
              key={i}
              elevation={0}
              onClick={() => history.push(`/dealOffers/${dealOffer.id}`)}
              square
            >
              <CardContent style={{position: 'relative'}}>
                <Typography>
                  {propertyTypeLabels[dealOffer.deal.propertyType]}
                </Typography>
                <Typography variant="subtitle1">
                  {offerTypeLabels[dealOffer.deal.offerType] || dealOffer.offerType}
                </Typography>
                <Typography variant="caption">
                  {dealOffer.deal.contact ? dealOffer.deal.contact.name : null}
                </Typography>
                {action ? (
                  <div className={classes.action}>
                    {action(dealOffer, null, 'dealOfferId', favList)}
                  </div>
                ) : null}
              </CardContent>
              <Divider/>
            </Paper>);
          })}
          <MobilePaginationToolbar
            totalCount={totalCount}
            page={query.offset ? Math.ceil(query.offset / 30) : 0}
            handleOnChangePage={handleChangePage}
          />
        </Hidden>
        <Popover
          classes={{paper: classes.requirementsPopover}}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
        >
          <Typography>{popoverContent}</Typography>
        </Popover>
        {openStatusMenu ? (
          <Menu
            anchorEl={openStatusMenu.target}
            open={true}
            onClose={() => this.setState({openStatusMenu: null})}
          >
            {dealOfferActiveStatusesFiltered.map(({value, label}, i) => (
              <MenuItem
                key={i}
                value={value}
                selected={value === openStatusMenu.dealOffer.status}
                onClick={() => {
                  handleChangeStatus(value, openStatusMenu.dealOffer.status, openStatusMenu.dealOffer.id);
                  this.setState({openStatusMenu: null});
                }}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        ) : null}
        {openExportMenu ? (
          <Menu
            anchorEl={openExportMenu}
            open={true}
            onClose={() => this.setState({openExportMenu: null})}
          >
            <MenuItem
              value="xlsx"
              onClick={() => this.onDownloadCsv('xlsx')}
            >
              Выгрузить в XLSX
            </MenuItem>
            <MenuItem
              value="csv"
              onClick={() => this.onDownloadCsv('csv')}
            >
              Выгрузить в CSV
            </MenuItem>
          </Menu>
        ) : null}
      </React.Fragment>
    );
  }

  handlePopoverOpen = (e, text) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({anchorEl: e.currentTarget.parentNode, popoverContent: text});
  };

  handlePopoverClose = () => {
    this.setState({anchorEl: null, popoverContent: null});
  };

  onDownloadCsv = async exportFormat => {
    const {query} = this.props;
    try {
      this.setState({openExportMenu: null, loading: true});
      await downloadCsvDealOffers(query, exportFormat);
      this.setState({loading: false});
    } catch (err) {
      this.setState({openExportMenu: null, loading: false});
      alert('Произошла ошибка');
    }
  };

  openSetStatusForm = (target, dealOffer) => {
    this.setState({
      openStatusMenu: {target, dealOffer},
    });
  };
}

const styles = theme => ({
  table: {
    background: 'white',
  },
  tableRow: {
    display:'table-row',
    color: 'inherit',
    height: 48,
    outline: 'none',
    verticalAlign: 'middle',
    textDecoration: 'none',
    cursor: 'default',
    '&:hover': {
      backgroundColor:'rgba(0,0,0,0.07)',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 56,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    backgroundColor: 'white',
    marginBottom: 56 + 48,
  },
  action: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 8,
    },
    top: 0,
  },
  sortableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  flex: {
    flex: 1,
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
  requirementsCell: {
    whiteSpace: 'normal',
    minWidth: 200,
  },
  requirementsWrapper: {
    overflow: 'hidden',
    maxHeight: 48,
    lineClamp: 3,
    fontSize: '0.75rem',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
  status: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 6,
    height: '100%',
  },
  malformedIcon: {
    color: colors.orange[400],
    width: 16,
    height: 16,
    marginRight: 8,
    float: 'left',
    verticalAlign: 'middle',
  },
  mobilePagination: {
    marginRight: 16,
  },
  rightIcon: {
    marginRight: -12,
  },
});

export default connect(
  state => ({
    selfUser: state.root.selfUser,
    dealOfferActiveStatusLabels: mapLabels(state.root.classification.dealOfferActiveStatuses, 'value', 'label'),
    dealOfferActiveStatuses: state.root.classification.dealOfferActiveStatuses,
    offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    propertyPurposeLabels: mapLabels(state.root.classification.propertyPurposes, 'value', 'label'),
  })
)(withStyles(styles)(DealOfferListItems));
