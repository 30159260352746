import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';

class TableLink extends React.Component {
  createContextMenu = e => {
    e.persist();
    const {classes} = this.props;
    let contextMenu =  document.createElement('div');
    contextMenu.style.left = e.clientX + 'px';
    contextMenu.style.top = e.clientY + 'px';
    contextMenu.tabIndex= 1000;
    contextMenu.className = classes.contextMenu;
    {
      let menuItem = document.createElement('p');
      menuItem.className = classes.menuItem;
      menuItem.innerText = 'Открыть в новой вкладке';
      contextMenu.appendChild(menuItem);
      menuItem.onclick = () => {
        this.createLink('_blank');
      };
    }
    {
      let menuItem = document.createElement('p');
      menuItem.innerText = 'Открыть в новом окне';
      menuItem.className = classes.menuItem;
      menuItem.onclick = () => {
        this.createLink('_top');
      };
      contextMenu.appendChild(menuItem);
    }
    let root = document.getElementById('root');
    contextMenu.onblur = e => {
      root.removeChild(e.target);
    };

    root.appendChild(contextMenu);
    contextMenu.focus();
    e.preventDefault();
    e.stopPropagation();

  };

  createLink = type => {
    const {href} = this.props;
    if(type === '_top'){
      window.open(href, href, `popup=1, height=${window.screen.availHeight}, width=${window.screen.availWidth}`);
      return;
    }
    window.open(href, '_blank');
  };

  handleEvent = e => {
    e.persist();
    if(e.button === 1) this.createLink();
  };

  onClick = e => {
    e.persist();
    if(e.ctrlKey)
      this.createLink();
    else
      this.props.onClick(e);
  };
  render() {
    const {children, className} = this.props;
    return (
      <TableRow className={className} onClick={this.onClick} hover onContextMenu={this.createContextMenu} onMouseDown={this.handleEvent}>
        {children}
      </TableRow>);
  }

}
const styles = () => ({
  contextMenu: {
    position: 'absolute',
    backgroundColor: '#FFF',
    zIndex: 1000,
    borderWidth: '1px',
    display: 'inline',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',

    fontSize: '0.75rem',
    borderColor: 'rgba(224, 224, 224, 1)',
    boxShadow: '1px 1px 5px rgba(0,0,0,0.2)',
    borderStyle: 'solid',
    outline: 'none',
  },
  menuItem: {
    margin: 0,
    cursor: 'pointer',
    padding: '8px 5px 5px 5px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },

});

export default withStyles(styles)(TableLink);
