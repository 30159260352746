const defaultState = {
  title: '',
  color: null,
  favicon: '/favicon.ico',
  version: localStorage['version'] || '',
};

export default (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'SET_TITLE':
      return {
        ...state,
        title: payload.title,
        color: payload.color,
      };
    case 'SET_FAVICON':
      return {
        ...state,
        favicon: payload.favicon || '/favicon.ico',
      };
    case 'SET_VERSION':
      return {
        ...state,
        version: payload.version,
      };
    default:
      return state;
  }
};
