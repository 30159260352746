import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import EmailForm from './EmailForm';
import Snackbar from '@material-ui/core/Snackbar';
import * as icons from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import * as colors from '@material-ui/core/colors';

class Contacts extends Component {
  state = {
    openWrite: false,
    messageSent: null,
  };

  render() {
    const {classes} = this.props;
    const {openWrite, messageSent} = this.state;
    return (
      <>
        <div className={classes.contacts}>
          <Typography variant="subtitle1" className={classes.flexText}>Контакты техподдержки:</Typography>
          <Typography variant="subtitle1" className={classes.flexText}><a tabIndex={1} href="mailto:fr@arrpro.ru">fr@arrpro.ru</a></Typography>
          <Typography variant="subtitle1" className={classes.flexText}><a tabIndex={2} href="tel:+79217309201">+79217309201</a></Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disableRipple
            tabIndex={3}
            className={classes.button}
            onClick={() => this.setState({openWrite: true})}
          >
            Отправить сообщение
          </Button>
        </div>
        {openWrite ? (
          <EmailForm
            open={openWrite}
            onClose={() => this.setState({openWrite: false})}
            onSubmitted={(message, warning) => this.setState({messageSent: {message, warning}})}
          />
        ) : null}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}

          open={Boolean(messageSent)}
          autoHideDuration={4000}
          onClose={() => this.setState({messageSent: null})}
          message={
            <span className={classes.message}>
              {messageSent && messageSent.warning ? (
                <icons.Warning style={{color: colors.orange[300], marginRight: 16}}/>
              ) : null}
              {messageSent && messageSent.message}
            </span>
          }
          action={
            <IconButton
              color="inherit"
              onClick={() => this.setState({messageSent: null})}
            >
              <icons.Close/>
            </IconButton>
          }
        />
      </>
    );
  }
}

const styles = theme => ({
  contacts: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    maxWidth: 767,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  flexText: {
    marginRight: 24,
    [theme.breakpoints.down('sm')]: {
      marginRight: 'unset',
    },
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default withStyles(styles)(Contacts);
