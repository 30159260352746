import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';

import RemoveOffer from '../form/RemoveOffer';
import base64url from '../../../utils/base64url';
import DealOfferListItem from '../../../components/relations/DealOfferListItem';
import {updateOffer} from '../../../actions';
import AccessComponent from '../../../components/AccessComponent';
import ShortPagination from '../../../components/ShortPagination';
import DeclinedOfferForm from '../form/DeclinedOffer';

class Offers extends Component {
  state = {
    dealOffers: [],
    error: null,
    loading: false,
    openMenu: null,
    removeOffer: null,
    openStatusMenu: null,
    offerDeclined: false,
  };

  componentDidMount() {
    if (this.props.dealOffers) {
      this.setState({dealOffers: this.props.dealOffers});
      this.props.dealOffers.forEach(dealOffer => {
        this.props.updateOffer(dealOffer.offer);
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dealOffers !== this.props.dealOffers) {
      this.setState({dealOffers: nextProps.dealOffers});
      nextProps.dealOffers.forEach(dealOffer => {
        this.props.updateOffer(dealOffer.offer);
      });
    }
  }

  handleOpenMenu = (target, dealOffer) => {
    this.setState({
      openMenu: {target, dealOffer},
    });
  };

  handleCloseMenu = () => {
    this.setState({
      openMenu: null,
    });
  };

  handleMenuItemClick = () => {
    this.setState({
      openMenu: null,
      removeOffer: {
        dealId: this.props.deal.id,
        offerId: this.state.openMenu.dealOffer.offer.id,
      },
    });
  };

  openSetStatusForm = (target, dealOffer) => {
    this.setState({
      openMenu: null,
      openStatusMenu: {target, dealOffer},
    });
  };

  handleClickAdd = () => {
    const {deal, history} = this.props;
    const filter = {
      type: deal.offerType,
    };
    history.push(
      `/deals/${deal.id}/offers?${base64url.encode(JSON.stringify(filter))}`
    );
  };

  changeStatus = (id, status) => {
    const {dealOffers} = this.state;
    this.setState({
      dealOffers: dealOffers.map(dealOffer => {
        if (dealOffer.offer.id === id) {
          return {
            ...dealOffer,
            status: status,
          };
        }
        return dealOffer;
      }),
    });
  };

  handleChangeStatus = (nextStatus, prevStatus) => {
    const {openStatusMenu} = this.state;
    if(nextStatus === 'declined'){
      this.setState({
        offerDeclined: true,
        openMenu: null,
      });
      return;
    }
    this.changeStatus(openStatusMenu.dealOffer.offer.id, nextStatus);
    axios
      .post('/api/v1/setDealOfferStatus', {
        id: openStatusMenu.dealOffer.id,
        status: nextStatus,
      })
      .then(() => {
        this.props.reload();
        this.props.updateActivity && this.props.updateActivity();
      })
      .catch(error => {
        this.changeStatus(openStatusMenu.dealOffer.offer.id, prevStatus);
        if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });

    this.setState({
      openStatusMenu: null,
    });
  };

  dialogOpen = offerId => {
    axios.get(`/api/offers/${offerId}`)
      .then(resp => {
        this.props.updateOffer(resp.data);
        this.props.handleDialogOpen(offerId);
      });
  };

  render() {
    const {
      dealOfferStatuses,
      error,
      loading,
      reload,
      page,
      perPage,
      totalCount,
      handleChangePage,
    } = this.props;

    const {dealOffers, openMenu, openStatusMenu, removeOffer, offerDeclined} = this.state;
    const offerStatuses = dealOfferStatuses.filter(
      status => status.value !== 'closed'
    );

    return (
      <List
        subheader={
          <ListSubheader disableSticky style={{position: 'relative'}}>
            Листинги ({totalCount})
            <ListItemSecondaryAction>
              {loading ? (
                <IconButton disabled>
                  <CircularProgress size={24} />
                </IconButton>
              ) : error ? (
                <IconButton disabled>
                  <icons.ErrorOutline />
                </IconButton>
              ) : (
                <IconButton onClick={() => this.handleClickAdd()}>
                  <icons.Add />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListSubheader>
        }
      >
        {offerDeclined
          ? <DeclinedOfferForm
            open={offerDeclined}
            onSubmit={() => {
              this.changeStatus(openStatusMenu.dealOffer.offer.id, 'declined');
              this.setState({
                offerDeclined: false,
                openStatusMenu: null,
              });
              this.props.updateActivity();
            }}
            id={openStatusMenu.dealOffer.id}
            onClose={() => {
              this.setState({
                offerDeclined: false,
                openStatusMenu : null,
              });
            }}
          />
          : null}
        {dealOffers
          ? dealOffers.map(dealOffer => (
            <DealOfferListItem
              key={dealOffer.offer.id}
              dealOffer={dealOffer}
              onSecondaryActionClick={e =>
                this.handleOpenMenu(e.currentTarget, dealOffer)
              }
              handleDialogOpen={() => this.dialogOpen(dealOffer.offer.id)}
              linkTo={`/offers/${dealOffer.offer.id}`}
            />
          ))
          : null}

        <Menu
          anchorEl={openMenu ? openMenu.target : null}
          open={Boolean(openMenu)}
          onClose={this.handleCloseMenu}
        >
          <MenuItem
            onClick={() =>
              this.openSetStatusForm(openMenu.target, openMenu.dealOffer)
            }
          >
            Поменять статус
          </MenuItem>
          <AccessComponent manager>
            <MenuItem onClick={this.handleMenuItemClick}>
              Убрать листинг
            </MenuItem>
          </AccessComponent>
        </Menu>

        <RemoveOffer
          open={Boolean(removeOffer)}
          initialValues={removeOffer}
          onSubmitted={() => {
            this.setState({removeOffer: null});
            this.props.updateActivity();
            reload();
          }}
          onClose={() => this.setState({removeOffer: null})}
        />
        {openStatusMenu ? (
          <Menu
            anchorEl={openStatusMenu.target}
            open={true}
            onClose={() => this.setState({openStatusMenu: null})}
          >
            {offerStatuses.map(({value, label}, i) => (
              <MenuItem
                key={i}
                value={value}
                selected={value === openStatusMenu.dealOffer.status}
                onClick={() =>
                  this.handleChangeStatus(
                    value,
                    openStatusMenu.dealOffer.status
                  )
                }
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        ) : null}
        <ShortPagination
          page={page}
          totalCount={totalCount}
          perPage={perPage}
          handleChangePage={handleChangePage}
        />
      </List>
    );
  }
}

const styles = () => ({
  statusIcon: {
    display: 'inline-block',
    lineHeight: 1,
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 4,
  },
});

Offers = connect(
  state => ({
    dealOfferStatuses: state.root.classification.dealOfferStatuses,
    offers: state.root.offer.offers,
  }),
  {updateOffer}
)(Offers);

export default withRouter(withStyles(styles)(Offers));
