import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import {isObject, set} from 'lodash';
import axios from 'axios';
import {showResponseError} from '../../../utils';
import DatePicker from '../../../components/Input/DatePicker';
import CheckboxInput from '../../../components/Input/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';

class RequestFeedbackChange extends Component {
  state = {
    id: null,
    feedbackYes: false,
    feedbackWait: false,
    waitingDeadline: null,
    feedbackNo: false,
    feedbackViewed: false,
  };

  componentDidMount() {
    if (this.props.data) {
      ['id', 'feedbackYes', 'feedbackWait', 'waitingDeadline', 'feedbackNo', 'feedbackViewed'].forEach(field => {
        this.setState({[field]: this.props.data[field]});
      });
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      open,
    } = this.props;

    const {feedbackYes, feedbackWait, waitingDeadline, feedbackNo, feedbackViewed} = this.state;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Обратная связь</DialogTitle>
        <Divider/>
        <DialogContent>
          <Field
            name="feedback"
            component={({meta}) => {
              if (meta.error && !isObject(meta.error)) {
                return <FormHelperText error>{meta.error}</FormHelperText>;
              }
              return null;
            }}
            margin="none"
          />
          <Field
            name="feedbackYes"
            type="checkbox"
            component={() => (
              <CheckboxInput
                label="Обратная связь получена"
                checked={feedbackYes}
                onChangeValue={checked => {
                  this.setState({feedbackYes: checked});
                }}/>
            )}
          />
          <Field
            name="feedbackWait"
            type="checkbox"
            component={() => (
              <CheckboxInput
                label="Отложенный показ"
                checked={feedbackWait}
                onChangeValue={checked => {
                  let _waitingDeadline = waitingDeadline;
                  if (!checked) {
                    _waitingDeadline = null;
                  }
                  this.setState({feedbackWait: checked, waitingDeadline: _waitingDeadline});
                }}/>
            )}
          />
          {
            feedbackWait ? (
              <Field
                name="waitingDeadline"
                label="Дата отложенного показа"
                format={null}
                value={waitingDeadline}
                onChange={
                  (e, value) => {
                    this.setState({waitingDeadline: value});
                  }
                }
                component={props => (
                  <DatePicker
                    format="yyyy-MM-dd"
                    value={waitingDeadline}
                    clearable
                    {...props}
                    style={{marginBottom: 16}}
                  />
                )}
                fullWidth
                margin="none"
              />
            ) : null
          }
          <Field
            name="feedbackNo"
            type="checkbox"
            component={() => (
              <CheckboxInput
                label="Обратная связь не дана"
                checked={feedbackNo}
                onChangeValue={checked => {
                  this.setState({feedbackNo: checked});
                }}/>
            )}
          />
          <Field
            name="feedbackViewed"
            type="checkbox"
            component={() => (
              <CheckboxInput
                label="Просмотр состоялся"
                checked={feedbackViewed}
                onChangeValue={checked => {
                  this.setState({feedbackViewed: checked});
                }}/>
            )}
          />
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={handleSubmit(this.submit)}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = () => {
    const data = this.state;
    return axios.post('/api/v1/setRequestFeedback', data)
      .then(res => {
        if (res.status === 200) {
          this.props.onSubmit(data);
        }
      })
      .catch(error => {
        showResponseError(error.response, SubmissionError, set);
      });
  };
}

export default reduxForm({
  form: 'request_feedback_change',
})(connect(
  () => ({})
)(RequestFeedbackChange));
