import React, {Component} from 'react';
import {connect} from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import MobilePaginationToolbar from '../../components/MobilePaginationToolbar';
import {
  createAreaFormatter,
  formatAddressListItem,
  formatAddressListItemTwoLines,
  shortAddress,
  mapLabels,
  formatDateWithoutTime,
} from '../../utils';
import AccessComponent from '../../components/AccessComponent';
import TablePaginationActions from '../../components/TablePaginationActions';
import {downloadCsv} from '../../utils/csvFunc';

import {getPropertyAlertIcon} from '../../utils/alerts';
import TableLink from '../../components/TableLink';

class PropertyListItems extends Component {
  state = {
    anchorEl: null,
    popoverContent: null,
    openExportMenu: null,
    loading: false,
  };
  render() {
    const {
      classes,
      query,
      propertyList,
      selectedItems,
      propertyTypeLabels,
      action,
      properties,
      history,
      formatArea,
      propertyStatusLabels,
      favList,
      handleOnChangePage,
      handleRequestSort,
      propertyListSettings,
      districtList,
      _perPage,
    } = this.props;

    const {order} = query;

    const {anchorEl, popoverContent, openExportMenu, loading} = this.state;
    const columnSettings = propertyListSettings.columns;

    return (
      <React.Fragment>
        <Hidden xsDown>
          <Table padding="none" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={order && order.column === 'address'}
                    direction={
                      order && order.direction
                        ? order.direction.toLowerCase()
                        : 'asc'
                    }
                    onClick={() => handleRequestSort('address')}
                    classes={{root: classes.sortableCell}}
                  >
                    Адрес
                  </TableSortLabel>
                </TableCell>
                {columnSettings.district ? (
                  <TableCell>Район</TableCell>
                ) : null}
                {columnSettings.category ? (
                  <TableCell>Категория</TableCell>
                ) : null}
                {columnSettings.area ? (
                  <TableCell>
                    <TableSortLabel
                      active={order && order.column === 'area'}
                      direction={
                        order && order.direction
                          ? order.direction.toLowerCase()
                          : 'asc'
                      }
                      onClick={() => handleRequestSort('area')}
                      classes={{root: classes.sortableCell}}
                    >
                      Площадь
                    </TableSortLabel>
                  </TableCell>
                ) : null}
                {columnSettings.owner ? (
                  <TableCell>Собственник</TableCell>
                ) : null}
                {columnSettings.status ? (
                  <TableCell>Статус</TableCell>
                ) : null}
                {columnSettings.assignees ? (
                  <TableCell>Ответственный</TableCell>
                ) : null}
                {columnSettings.dates ? (
                  <TableCell>
                    <TableSortLabel
                      active={order && order.column === 'createdAt'}
                      direction={
                        order && order.direction
                          ? order.direction.toLowerCase()
                          : 'asc'
                      }
                      onClick={() => handleRequestSort('createdAt')}
                      classes={{root: classes.sortableCell}}
                      hideSortIcon={true}
                    >
                      Создан
                    </TableSortLabel>
                    &nbsp;/&nbsp;
                    <TableSortLabel
                      active={order && order.column === 'updatedAt'}
                      direction={
                        order && order.direction
                          ? order.direction.toLowerCase()
                          : 'asc'
                      }
                      onClick={() => handleRequestSort('updatedAt')}
                      classes={{root: classes.sortableCell}}
                    >
                      Изменен
                    </TableSortLabel>
                  </TableCell>
                ) : null}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {propertyList.items.map(id => {
                const property = properties[id];
                const selected =
                  selectedItems && selectedItems.indexOf(property.id) >= 0;
                return (
                  <TableLink
                    className={classes.tableRow}
                    key={id}
                    href={`/properties/${id}`}
                    hover
                    selected={selected}
                    onClick={e => {
                      e.preventDefault();
                      history.push(`/properties/${property.id}`);
                    }}
                  >
                    <TableCell className={classes.tdWithoutPadding} title={formatAddressListItem(property.address)}>
                      {getPropertyAlertIcon(property)}
                      {
                        columnSettings.shortAddress
                          ? shortAddress(property.address)
                          : formatAddressListItemTwoLines(property.address)
                      }
                    </TableCell>
                    {columnSettings.district ? (
                      <TableCell>
                        {districtList[property.address.district]}
                      </TableCell>
                    ) : null}
                    {columnSettings.category ? (
                      <TableCell>
                        {propertyTypeLabels[property.type.value] ||
                          property.type.value}
                      </TableCell>
                    ) : null}
                    {columnSettings.area ? (
                      <TableCell>{formatArea(property.area)}</TableCell>
                    ) : null}
                    {columnSettings.owner ? (
                      property.owner ?
                        <TableCell title={property.owner.name + '\n' + property.owner.phone}>
                          {property.owner.name}
                          {'\n'}
                          {property.owner.phone}
                        </TableCell>
                        : <TableCell/>
                    ) : null}
                    {columnSettings.status ? (
                      <TableCell
                      >
                        {propertyStatusLabels[property.status] ||
                          property.status}
                      </TableCell>
                    ) : null}
                    {columnSettings.assignees ? (
                      <TableCell
                        title={property.assignees
                          .map(assignee => assignee.name)
                          .join('\n')}
                      >
                        <div className={classes.flexWrapper}>
                          <div>
                            {property.assignees && property.assignees.length > 0
                              ? property.assignees[0].name
                              : null}
                          </div>
                          {property.assignees &&
                          property.assignees.length > 1 ? (
                              <icons.MoreHoriz
                                onClick={event =>
                                  this.handlePopoverOpen(
                                    event,
                                    property.assignees
                                      .map(assignee => assignee.name)
                                      .join('\n')
                                  )
                                }
                                className={classes.popoverIcon}
                              />
                            ) : null}
                        </div>
                      </TableCell>
                    ) : null}
                    {columnSettings.dates ? (
                      <TableCell>
                        {formatDateWithoutTime(property.createdAt)}&nbsp;/{' '}
                        {formatDateWithoutTime(property.updatedAt)}
                      </TableCell>
                    ) : null}
                    <TableCell style={{paddingRight: action ? 56 : 24}}>
                      {action ? (
                        <div className={classes.action}>
                          {action(property, selected, 'propertyId', favList)}
                        </div>
                      ) : null}
                    </TableCell>
                  </TableLink>
                );
              })}
            </TableBody>
          </Table>
          <div className={classes.pagination}>
            <AccessComponent manager>
              <Button
                size="small"
                className={classes.button}
                onClick={e =>
                  this.setState({openExportMenu: e.currentTarget})
                }
                disabled={loading}
              >
                <icons.ArrowDownward className={classes.leftIcon} />
                      Выгрузить
              </Button>
            </AccessComponent>
            <TablePagination
              rowsPerPage={_perPage}
              component={'div'}
              rowsPerPageOptions={[_perPage]}
              count={propertyList.totalCount}
              page={propertyList.page}
              onChangePage={handleOnChangePage}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} из ${count}`
              }
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </Hidden>
        <Hidden smUp>
          {propertyList.items.map(id => {
            const property = properties[id];
            const selected =
              selectedItems && selectedItems.indexOf(property.id) >= 0;
            return (
              <Paper
                key={id}
                className={classes.card}
                elevation={0}
                onClick={() => history.push(`/properties/${property.id}`)}
                square
              >
                <CardContent style={{position: 'relative'}}>
                  <Typography>
                    {propertyTypeLabels[property.type.value] ||
                      property.type.value}
                  </Typography>
                  <Typography variant="subtitle1">
                    {formatArea(property.area)}
                  </Typography>
                  <Typography variant="caption">
                    {formatAddressListItem(property.address)}
                  </Typography>
                  {action ? (
                    <div className={classes.action}>
                      {action(property, selected, 'propertyId', favList)}
                    </div>
                  ) : null}
                </CardContent>
                <Divider />
              </Paper>
            );
          })}
          <MobilePaginationToolbar
            totalCount={propertyList.totalCount}
            page={propertyList.page}
            handleOnChangePage={(e, page) => handleOnChangePage(e, page)}
          />
        </Hidden>
        <Popover
          classes={{paper: classes.requirementsPopover}}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
        >
          <Typography>{popoverContent}</Typography>
        </Popover>
        {openExportMenu ? (
          <Menu
            anchorEl={openExportMenu}
            open={true}
            onClose={() => this.setState({openExportMenu: null})}
          >
            <MenuItem value="xlsx" onClick={() => this.onDownloadCsv('xlsx')}>
              Выгрузить в XLSX
            </MenuItem>
            <MenuItem value="csv" onClick={() => this.onDownloadCsv('csv')}>
              Выгрузить в CSV
            </MenuItem>
          </Menu>
        ) : null}
      </React.Fragment>
    );
  }

  onDownloadCsv = async exportFormat => {
    const {propertyList} = this.props;
    try {
      this.setState({openExportMenu: null, loading: true});
      await downloadCsv('properties', propertyList.filter, exportFormat);
      this.setState({loading: false});
    } catch (err) {
      this.setState({openExportMenu: null, loading: false});
      alert('Произошла ошибка');
    }
  };

  handlePopoverOpen = (e, text) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({anchorEl: e.currentTarget.parentNode, popoverContent: text});
  };

  handlePopoverClose = () => {
    this.setState({anchorEl: null, popoverContent: null});
  };
}

const styles = theme => ({
  tableRow: {
    display:'table-row',
    color: 'inherit',
    height: 48,
    outline: 'none',
    verticalAlign: 'middle',
    textDecoration: 'none',
    cursor: 'default',
    '&:hover': {
      backgroundColor:'rgba(0,0,0,0.07)',
    },
  },
  tdWithoutPadding:{
    padding: '0px',
  },
  table: {
    background: 'white',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 56,
    alignItems: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    backgroundColor: 'white',
    marginBottom: 56 + 48,
  },
  action: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 8,
    },
    top: 0,
  },
  sortableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
  malformed: {
    color: colors.orange[400],
    width: 16,
    height: 16,
    marginLeft: 16,
    verticalAlign: 'middle',
  },
  malformedIcon: {
    color: colors.orange[400],
    width: 16,
    height: 16,
    marginRight: 8,
    float: 'left',
    verticalAlign: 'middle',
  },
});

export default connect(state => ({
  selfUser: state.root.selfUser,
  propertyTypeLabels: mapLabels(
    state.root.classification.propertyTypes,
    'value',
    'label'
  ),
  properties: state.root.property.properties,
  formatArea: createAreaFormatter(state.root.classification.areaUnits),
  districtList: state.root.district.districts,
  propertyStatusLabels: mapLabels(
    state.root.classification.propertyStatuses,
    'value',
    'label'
  ),
  propertyListSettings: state.root.settings.propertyList,
}))(withStyles(styles)(PropertyListItems));
