import React, {Component} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Field} from 'redux-form';
import {connect} from 'react-redux';

import TextField from '../../../components/Input/TextField';
import FileInput from '../../../components/Input/FileInput';
import CheckboxInput from '../../../components/Input/Checkbox';

class PresentationForm extends Component {
  render() {
    const {
      propertyTypes,
    } = this.props;

    return (
      <React.Fragment>
        <Field
          label="Номер объекта в базе"
          style={{display: 'none'}}
          name="xml_id"
          margin="normal"
          fullWidth
          component={TextField}
        />
        <Field
          label="Название"
          name="name"
          margin="normal"
          fullWidth
          component={TextField}
        />
        <Field 
          name="obj_photos[]" 
          component={FileInput} 
          type="file"
          fullWidth
          margin="normal"
          inputProps={{
            multiple: true,
            maxLength: 3,
          }}
          label="Фотографии объекта"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Field
          name="type"
          select
          label="Тип объекта"
          fullWidth
          margin="normal"
          component={TextField}
          format={value => value || []}
        >
          {propertyTypes.map((type, key) => {
            return (
              <MenuItem key={key} value={type.label}>
                {type.label}
              </MenuItem>
            );
          })}
        </Field>
        <Field
          label="Площадь"
          name="area"
          margin="normal"
          fullWidth
          component={TextField}
          inputProps={{
            type: 'number',
            min: 0,
          }}
        />
        <Field
          label="Адрес"
          name="address"
          margin="normal"
          fullWidth
          component={TextField}
        />
        <Field
          label="Описание объекта"
          name="descr"
          margin="normal"
          fullWidth
          multiline
          rows="3"
          component={TextField}
          placeholder="Не более 4 пунктов"
          rowsMax={10}
        />
        <Grid container>
          <Grid item xs={5}>
            <Field
              name="price_type"
              select
              label="Тип цены"
              fullWidth
              margin="normal"
              component={TextField}
              format={value => value || []}
            >
              <MenuItem value="за м2">
                Цена за м2
              </MenuItem>
              <MenuItem value="за объект">
                Цена за объект
              </MenuItem>
            </Field>
          </Grid>
          <Grid item xs={7}>
            <Field
              label="Цена"
              name="price"
              margin="normal"
              fullWidth
              component={TextField}
              inputProps={{
                type: 'number',
                min: 0,
              }}
            />
          </Grid>
        </Grid>
        <Field
          label="Телефон агента"
          name="agent_phone"
          margin="normal"
          fullWidth
          required
          component={TextField}
          inputProps={{
            required: true,
          }}
        />
        <Field
          label="Email агента"
          name="agent_email"
          margin="normal"
          fullWidth
          required
          component={TextField}
          inputProps={{
            required: true,
            type: 'email',
          }}
        />
        <FormControl margin="none">
          <FormControlLabel
            control={<Field name="wo_contact" type="checkbox" component={CheckboxInput}/>}
            label="Без контактов"
          />
        </FormControl>
        <FormControl margin="none">
          <FormControlLabel
            control={<Field name="nak" type="checkbox" component={CheckboxInput}/>}
            label="Шаблон НАК"
          />
        </FormControl>
      </React.Fragment>
    );
  } 
}

export default connect(
  ({root}) => ({
    propertyTypes: root.classification.propertyTypes,
  }),
)(PresentationForm);
