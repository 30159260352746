import axios from 'axios';
import {set, without} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {SubmissionError, formValueSelector, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import CloseDealForm from './form/CloseDeal';
import NewsForm from './form/NewsForm';
import {capitalizeFirstLetter, mapLabels} from '../../utils';
import VideosForm from './form/VideosForm';
import EventsForm from './form/EventsForm';
import ResultsForm from './form/ResultsForm';

class PressReleaseForm extends Component {
  state = {
    contact: null,
    createContact: null,
    assignees: [],
    contactAssignees: [],
  };

  eventInitialize = () => {
    this.props.initialize({});
  };
  newCloseDealInitialize = () => {
    this.props.initialize({
      dealType: 'rent',
    });
  };

  existCloseDealInitialize = () => {
    const {dealOffer, offerTypeLabels} = this.props;
    this.props.initialize({
      dealType: 'rent_owner',
      name: dealOffer.deal ? offerTypeLabels[dealOffer.deal.offerType] : '',
      propertyAddress: dealOffer.offer ? dealOffer.offer.property.address.value: '',
      propertyArea: dealOffer.offer ? dealOffer.offer.property.area.amount : 0,
      photos: dealOffer.offer ? dealOffer.offer.property.photos : [],
    });
  };

  componentDidMount() {
    const {pressRelease, dealOffer, type, initialize} = this.props;

    if(pressRelease){
      return initialize({
        ...pressRelease,
      });

    }
    if(dealOffer)
      this.existCloseDealInitialize();
    else
    if(type === 'dealClose')
      this.newCloseDealInitialize();
    else this.eventInitialize();
  }

  getTitleByType(type) {
    switch (type) {
      case 'videos':
        return 'Видео пост';
      case 'news':
        return 'Новость';
      case 'events':
        return 'Событие';
      case 'results':
        return 'Итоги';
      default:
        return 'О сделке';
    }
  }

  getFormByType(type) {
    const {
      array,
      teams,
      photos,
      pressRelease,
      change,
    } = this.props;
    const {assignees} =  this.state;

    switch (type) {
      case 'videos':
        return <VideosForm
          pressRelease={pressRelease}
        />;
      case 'news':
        return <NewsForm
          array={array}
          team={teams[0]}
          change={change}
          pressRelease={pressRelease}
          photos={photos}
        />;
      case 'events':
        return <EventsForm
          array={array}
          team={teams[0]}
          change={change}
          pressRelease={pressRelease}
          photos={photos}
        />;
      case 'results':
        return <ResultsForm
          pressRelease={pressRelease}
          array={array}
          photos={photos}
        />;
      default:
        return <CloseDealForm
          array={array}
          photos={photos}
          pressRelease={pressRelease}
          change={change}
          assignees={assignees}
          team={teams[0]}
          onAssigneeSelect={this.onAssigneeSelect}
          onAssigneeUnselect={this.onAssigneeUnselect}
        />;
    }
  }


  render() {
    const {
      classes,
      handleSubmit,
      initialized,
      open,
      pristine,
      submitting,
      onClose,
      type,
    } = this.props;
    if (!open || !initialized) {
      return null;
    }
    return (
      <Dialog fullScreen open={open} classes={{paper: classes.dialog}}>
        <DialogTitle>
          {this.getTitleByType(type)}
          <IconButton className={classes.closeButton} onClick={onClose}>
            <icons.Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {this.getFormByType(type)}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button type="button" disabled={submitting} onClick={onClose}>
            Отменить
          </Button>
          <Button
            type="button"
            disabled={pristine || submitting}
            onClick={handleSubmit(this.submit)}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  onAssigneeUnselect = index => {
    const {array} = this.props;
    array.remove('assignees', index);
    this.setState({
      assignees: without(
        this.state.assignees,
        this.state.assignees[index]
      ),
    });
  };
  onAssigneeSelect = assignee => {
    const {array} = this.props;
    array.push('assignees', assignee.id);
    this.setState({assignees: [...this.state.assignees, assignee]});
  };

  submit = data => {
    const {onSubmitted, type, pressRelease, dealOffer} = this.props;
    let url = null, _type = capitalizeFirstLetter(type);
    if(type === 'dealClose'){
      url = pressRelease ? '/api/v1/pressRelease/updateClosedDeal' : '/api/v1/pressRelease/createClosedDeal';
      if(pressRelease && pressRelease.dealOffer)
        data.dealOffer = pressRelease.dealOffer.id;
      if(dealOffer)
        data.dealOffer = dealOffer.id;
    }
    else
      url = '/api/v1/pressRelease/' + (pressRelease ? ('update' + _type) : ('create' + _type));

    if(data.propertyArea)
      data.propertyArea = parseFloat(data.propertyArea);

    return axios
      .post(url, data)
      .then(resp => {
        if(resp.data.id)
          data.id = resp.data.id;
        delete data.dealOffer;
        onSubmitted(data);
      })
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(
            error.response.data.error.errors.reduce(
              (errors, {propertyPath, message}) => {
                return set(errors, propertyPath, message);
              },
              {}
            )
          );
        } else if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

const styles = () => ({
  dialog: {
    maxWidth: 960,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

PressReleaseForm = withStyles(styles)(PressReleaseForm);

const selector = formValueSelector('pressRelease');
PressReleaseForm = connect(state => ({
  selfUser: state.root.selfUser,
  photos: selector(state, 'photos'),
  teams: state.root.selfUser.teams,
  offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
}))(PressReleaseForm);

export default reduxForm({
  form: 'pressRelease',
})(PressReleaseForm);
