import {isEmpty} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import * as colors from '@material-ui/core/colors/index';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import InputBase from '@material-ui/core/InputBase';

import DealEdit from './DealEdit';
import FilterDeals from './form/FilterDeals';
import base64url from '../../utils/base64url';
import {setSection} from '../../utils/lastSection';
import {setDealsListScrollPosition} from '../../actions';
import ListSettings from '../../components/ListSettings';

class DealList extends Component {
  state = {
    openAdd: false,
    openFilterDialog: false,
    searchInput: '',
    openSettings: false,
  };

  componentDidMount() {
    let query = this.props.location.search.substr(1);
    if (query) {
      const data = JSON.parse(base64url.decode(query));
      if (data.filter)
        this.setState({searchInput: data.filter.searchQuery});
    }
    if (this.$content) {
      this.setScrollPosition();
    }
  }

  setScrollPosition = () => {
    setTimeout(() => {
      const {deals, error} = this.props;
      if(deals.length > 0 && !error)
        this.$content.scrollTop = this.props.scrollPosition;
      else
        this.setScrollPosition();
    }, 0);
  };

  componentWillUnmount() {
    if (this.$content) {
      this.props.setDealsListScrollPosition(this.props.location.key, this.$content.scrollTop);
    }
  }

  handleFilter = filter => {
    this.setState({openFilterDialog: false});
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({source: this.props.query.source, filter, offset: 0, limit: this.props.perPage}));
    this.props.history.push(path);
    this.setState({searchInput: filter.searchQuery});
  };

  handleReset = () => {
    if(this.props.query.source) {
      const path = this.props.match.url + '?'
        + base64url.encode(JSON.stringify({source: this.props.query.source}));
      this.props.history.push(path);
    } else {
      this.props.history.push(this.props.match.url);
    }
    this.setState({searchInput: ''});
  };

  handleChangeSource = e => {
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...this.props.query, source: e.target.value, offset: 0, limit: this.props.perPage}));
    this.props.history.push(path);
    setSection('dealsSource', e.target.value);
  };

  handleChangePage = (_, page) => {
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...this.props.query, offset: page * this.props.perPage, limit: this.props.perPage}));
    this.props.history.push(path);
  };

  handlePopoverOpen = (e, text) => {
    e.stopPropagation();
    this.setState({anchorEl: e.currentTarget.parentNode, popoverContent: text});
  };

  handlePopoverClose = () => {
    this.setState({anchorEl: null, popoverContent: null});
  };

  handleSearchChange = e => {
    this.setState({searchInput: e.target.value});
  };

  handleSearchEnter = e => {
    if(e.key === 'Enter') {
      this.handleSearchInit();
    }
  };

  handleSearchInit = () => {
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...this.props.query, filter: {...this.props.query.filter, searchQuery: this.state.searchInput}, offset: 0, limit: this.props.perPage}));
    this.props.history.push(path);
  };

  handleRequestSort = property => {
    const {order} = this.props.query;

    if (order && order.column === property && order.direction === 'ASC') {
      this.getSortParams(property, 'DESC');
    } else if (order && order.column === property && order.direction === 'DESC') {
      this.getSortParams();
    } else {
      this.getSortParams(property, 'ASC');
    }
  };

  getSortParams = (orderBy, order) => {
    let queryOrder = {
      column: orderBy,
      direction: order,
    };

    let newQuery;
    if (!orderBy && !order) {
      newQuery = {...this.props.query};
      delete newQuery.order;
    } else {
      newQuery = {...this.props.query, order: queryOrder};
    }
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...newQuery, offset: 0, limit: this.props.perPage}));
    this.props.history.push(path);
  };

  onSubmittedListSettings = newSettings => {
    const {dealListColumns} = this.props;
    this.setState({openSettings: false});
    if(dealListColumns.page !== newSettings.page){
      const {match, query} = this.props;
      const path = match.url + '?' + base64url.encode(JSON.stringify({...query, offset: 0, limit: newSettings.page}));
      this.props.history.push(path);
    }
  };

  render() {
    const {
      classes,
      history,
      query,
      deals,
      action,
      selectedItems,
      totalCount,
      error,
      loading,
      dealListColumns,
      dealListSettings,
      perPage,
    } = this.props;
    const {
      openAdd,
      openFilterDialog,
      searchInput,
      openSettings,
    } = this.state;

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        query: query,
        history: history,
        deals: deals,
        entityId: 'dealId',
        entityType: 'Deal',
        action: action ? action : null,
        selectedItems: selectedItems,
        totalCount: totalCount,
        source: query.source,
        perPage: perPage,
        handleRequestSort: e => this.handleRequestSort(e),
        handleChangePage: (e, page) => this.handleChangePage(e, page),
      })
    );

    return (
      <React.Fragment>
        <div key={0} className="row">
          <div className="column" style={{background: colors.grey[200], flex: 2}}>
            <Toolbar className="toolbar">
              <Select
                value={query.source || 'ASSIGNED'}
                input={<Input/>}
                onChange={this.handleChangeSource}
              >
                <MenuItem value="FREE">Свободные заявки</MenuItem>
                <MenuItem value="ASSIGNED">Мои заявки</MenuItem>
                <MenuItem value="TEAM">Заявки команды</MenuItem>
                <MenuItem value="ALL">Все заявки</MenuItem>
              </Select>
              <Hidden xsDown>
                <IconButton
                  className={classes.settingsIcon}
                  disabled={loading}
                  color="primary"
                  onClick={() => this.setState({openSettings: true})}
                >
                  <icons.Settings/>
                </IconButton>
              </Hidden>
              <div className={classes.flex}/>
              <Hidden xsDown>
                <InputBase
                  className={classes.input}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                  onKeyDown={this.handleSearchEnter}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  disabled={!searchInput}
                >
                  <icons.Search/>
                </IconButton>
              </Hidden>
              {!isEmpty(query.filter) ? (
                <IconButton
                  disabled={loading}
                  onClick={this.handleReset}
                >
                  <icons.Clear/>
                </IconButton>
              ) : null}
              <IconButton
                className={classes.rightIcon}
                disabled={loading}
                color={!isEmpty(query.filter) ? 'primary' : 'default'}
                onClick={() => this.setState({openFilterDialog: true})}
              >
                <icons.FilterList/>
              </IconButton>
            </Toolbar>
            <Hidden smUp>
              <Toolbar>
                <InputBase
                  classes={{root: classes.searchInputRoot, input: classes.searchInput}}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  className={classes.rightIcon}
                  disabled={!searchInput}
                >
                  <icons.Search/>
                </IconButton>
              </Toolbar>
            </Hidden>
            <Divider/>
            <div
              className="content"
              ref={$content => {
                this.$content = $content;
              }}
            >
              {loading ?
                <CircularProgress size={50} thickness={4} className={classes.progress}/>
                :
                error ?
                  <icons.ErrorOutline className={classes.progress}/>
                  :
                  childrenWithProps}
            </div>
            <Fab
              color="secondary"
              className={classes.fab}
              onClick={() => this.setState({openAdd: true})}
            >
              <icons.Add/>
            </Fab>
          </div>
        </div>
        {openFilterDialog ? (
          <FilterDeals
            open={openFilterDialog}
            initialValues={{
              createdAtFrom: null,
              createdAtTo: null,
              ...query.filter,
            }}
            onClose={() => this.setState({openFilterDialog: false})}
            onSubmit={this.handleFilter}
          />
        ) : null}
        {openAdd ? (
          <DealEdit
            open={openAdd}
            onSubmitted={({id}) => history.push(`/deals/${id}`)}
            onClose={() => this.setState({openAdd: false})}
          />
        ) : null}
        {openSettings ? (
          <ListSettings
            columnNames={dealListColumns}
            open={openSettings}
            entityList="dealList"
            initialValues={dealListSettings}
            onSubmitted={this.onSubmittedListSettings}
            onClose={() => this.setState({openSettings: false})}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  flex: {
    flex: 1,
  },
  rightIcon: {
    marginRight: -12,
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  searchInputRoot: {
    width: '100%',
  },
  searchInput: {
    width: '100%',
  },
});

export default connect(
  (state, ownProps) => ({
    selfUser: state.root.selfUser,
    scrollPosition: (state.root.deal.screens[ownProps.location.key] && state.root.deal.screens[ownProps.location.key].scrollPosition) || 0,
    dealListColumns: state.root.classification.dealListColumns,
    dealListSettings: state.root.settings.dealList,
  }),
  {
    setDealsListScrollPosition,
  }
)(withStyles(styles)(DealList));
