import React, {Component} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';

class MobilePaginationToolbar extends Component {
  componentWillReceiveProps(newProps) {
    const {page, handleOnChangePage} = this.props;
    const leftBound = page * 30 + 1;
    if (newProps.totalCount !== this.props.totalCount && newProps.totalCount < leftBound) {
      handleOnChangePage(null, page - 1);
    }
  }

  getPagination = () => {
    const {totalCount} = this.props;

    const page = this.props.page || 0;
    const leftBound = page * 30 + 1;
    let rightBound = page * 30 + 30;
    rightBound = rightBound < totalCount ? rightBound : totalCount;

    return `${leftBound}-${rightBound} из ${totalCount}`;
  };

  render() {
    const {classes, totalCount, page, handleOnChangePage, paddingBottom} = this.props;

    if (!totalCount) {
      return null;
    }

    return (
      <Toolbar style={paddingBottom ? {paddingBottom} : {}}>
        <Typography color="inherit" variant="caption" className={classes.mobilePagination}>
          {this.getPagination()}
        </Typography>
        <IconButton
          disabled={page < 1}
          onClick={e => handleOnChangePage(e, page - 1)}
          className={classes.leftIcon}
        >
          <icons.ChevronLeft/>
        </IconButton>
        <IconButton
          disabled={page >= Math.floor((totalCount - 1) / 30)}
          onClick={e => handleOnChangePage(e, page + 1)}
        >
          <icons.ChevronRight/>
        </IconButton>
      </Toolbar>
    );
  }
}

const styles = () => ({
  mobilePagination: {
    marginRight: 16,
  },
});

export default withStyles(styles)(MobilePaginationToolbar);
