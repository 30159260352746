import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography/Typography';


function Notice(props) {
  const {classes, message} = props;

  return (
    <Snackbar
      className={classes.warning}
      open={Boolean(true)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Typography className={classes.message}>
        <icons.Warning className={classes.icon}/>
        {message}
      </Typography>
    </Snackbar>

  );
}

const styles = theme => ({
  warning: {
    marginBottom: theme.spacing.unit,
    padding: '4px 16px',
    maxWidth: 'unset',
  },
  message: {
    padding: '10px 24px',
    color: 'rgba(0, 0, 0, 0.54)',
    //display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    borderRadius: 4,
    backgroundColor: colors.yellow[100],
  },
  icon: {
    opacity: 0.9,
    marginRight: 2,
    marginBottom: -2,
    width: 16,
    height: 16,
  },
});

export default withStyles(styles)(Notice);
