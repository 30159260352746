import React, {Component} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography/Typography';
import * as icons from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';

class ShortPagination extends Component {
  render() {

    const {perPage, totalCount, page, handleChangePage} = this.props;

    if(totalCount <= perPage)
      return null;

    return(
      <Toolbar>
        <IconButton
          style={{marginLeft: -20}}
          disabled={page === 1}
          onClick={() => handleChangePage(page - 1)}
        >
          <icons.ChevronLeft/>
        </IconButton>
        <IconButton
          disabled={page >= Math.floor((totalCount - 1) / perPage) + 1}
          onClick={() => handleChangePage(page + 1)}
        >
          <icons.ChevronRight/>
        </IconButton>
        <Typography variant="caption">
          {`${page} / ${Math.floor((totalCount - 1) / perPage) + 1}`}
        </Typography>
      </Toolbar>
    );
  }
}


const styles = () => ({
});

export default withStyles(styles)(ShortPagination);
