import * as axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';
import DealDetailsWrapper from './NetRenterDetailsWrapper';
import NetRenterEdit from './NetRenterEdit';
import Contact from './relations/Contact';
import Row from '../../components/Row';
import Column from '../../components/Column';
import {queryNetRenterDetails} from '../../queries';
import {mapLabels} from '../../utils';
import ContactDialog from '../contacts/ContactDialog';
import {setTitle} from '../../actions';
import EditableField from '../../components/EditableField';

class NetRenterView extends Component {
  state = {
    openAdd: null,
    openEdit: false,
    deal: null,
    openContactDialog: false,
  };
  perPage = 5;

  handleDialogClose = name => {
    this.setState({[name]: false});
  };

  handleDialogOpen = (value, name) => {
    this.setState({[name] : value});
  };

  componentDidMount() {
    this.props.setTitle('Сетевые арендаторы');
    this.load();
  }

  load() {
    const {match} = this.props;
    queryNetRenterDetails(match.params.itemId)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            deal: resp.data.data.netRenter,
          });
          return;
        }
        alert('Произошла ошибка!');
      })
      .catch(() => {
        alert('Произошла ошибка!');
      });
  }

  savePrivateRequest = (id, nextValue, prevValue) => {
    this.setState({
      deal: {
        ...this.state.deal,
        privateRequest: nextValue,
      },
    });

    return axios.post('/api/v1/netRenter/setPrivateRequest', {id, privateRequest: nextValue})
      .catch(() => {
        this.setState({
          deal: {
            ...this.state.deal,
            privateRequest: prevValue,
          },
        });
        throw new Error();
      });
  };

  savePublicRequest = (id, nextValue, prevValue) => {
    this.setState({
      deal: {
        ...this.state.deal,
        publicRequest: nextValue,
      },
    });

    return axios.post('/api/v1/netRenter/setPublicRequest', {id, publicRequest: nextValue})
      .catch(() => {
        this.setState({
          deal: {
            ...this.state.deal,
            publicRequest: prevValue,
          },
        });
        throw new Error();
      });
  };

  removeNetRenter = id => {
    return axios.post('/api/v1/removeNetRenter', {id})
      .then(res => {
        if (res && res.data && res.data.success) {
          this.props.history.push('/netRenters/');
        }
      })
      .catch(() => {
        alert('Не удалось удалить арендатора');
      });
  };

  render() {
    const {
      deal,
      openEdit,
      openContactDialog,
    } = this.state;

    const {
      history,
    } = this.props;

    if (!deal) {
      return null;
    }
    return (
      <React.Fragment>
        <Row>
          <Column label="Информация" icon={<icons.InfoOutlined/>} key="information">
            <DealDetailsWrapper
              deal={deal}
              onEdit={() => this.setState({openEdit: true})}
              onRemove={() => this.removeNetRenter(deal.id)}
            />
          </Column>
          <Column label="Требования" icon={<icons.History/>} key="activity">
            <div className="column" style={{background: colors.grey[200], zIndex: 0}}>
              <div className="content" style={{padding: 16}}>
                <EditableField
                  field={deal.publicRequest}
                  onSave={publicRequest => this.savePublicRequest(deal.id, publicRequest, deal.publicRequest)}
                  name="Публичные требования"
                  color={colors.blue[100]}
                />
                <EditableField
                  field={deal.privateRequest}
                  onSave={privateRequest => this.savePrivateRequest(deal.id, privateRequest, deal.privateRequest)}
                  name="Закрытые требования"
                  color={colors.yellow[100]}
                />
              </div>
            </div>
          </Column>
          <Column label="Связи" icon={<icons.Share/>} key="relationships">
            <Paper elevation={1} square className="column">
              <div className="content">
                <Contact
                  deal={deal}
                  handleDialogOpen={e => this.handleDialogOpen(e, 'openContactDialog')}
                />
              </div>
            </Paper>
          </Column>
        </Row>
        {openEdit ? (
          <NetRenterEdit
            open={openEdit}
            id={deal.id}
            onSubmitted={() => {
              this.load();
              this.setState({openEdit: false});
            }}
            onClose={() => this.setState({openEdit: false})}
          />
        ) : null}
        {openContactDialog ? (
          <ContactDialog
            history={history}
            contact={openContactDialog}
            open={Boolean(openContactDialog)}
            handleClose={() => this.handleDialogClose('openContactDialog')}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const styles = () => ({});

export default connect(
  state => ({
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    selfUser: state.root.selfUser,
  }),
  {
    setTitle,
  }
)(withStyles(styles)(NetRenterView));
