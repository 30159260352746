import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import DealAssignees from './DealAssignees';
import DealContact from './DealContact';
import DealListWrapper from './DealListWrapper';
import DealOffers from './DealOffers';
import DealSelections from './DealSelections';
import DealView from './DealView';
import {setTitle} from '../../actions';

class Deals extends Component {

  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route path={`${match.url}/`} exact component={DealListWrapper}/>
        <Route path={`${match.url}/:dealId/assignees`} exact component={DealAssignees}/>
        <Route path={`${match.url}/:dealId/contact`} exact component={DealContact}/>
        <Route path={`${match.url}/:dealId/offers`} exact component={DealOffers}/>
        <Route path={`${match.url}/:dealId/selections/:selectionId/change/`} exact component={DealSelections}/>
        <Route path={`${match.url}/:dealId/selections/:selectionId/`} exact component={DealView}/>
        <Route path={`${match.url}/:dealId`} component={DealView}/>
      </Switch>
    );
  }
}

export default connect(null, {setTitle})(Deals);
