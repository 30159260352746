import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';
import {find, intersectionBy} from 'lodash';

import DealOfferListItem from '../../../components/relations/DealOfferListItem';
import ShortPagination from '../../../components/ShortPagination';
import {hasRole} from '../../../utils/roleFunc';
import * as userRoles from '../../../constants/userRoles';
import CloseDealOfferForm from '../../dealOffers/form/CloseDealOfferForm';
import DeclineDealOfferForm from '../../dealOffers/form/DeclineDealOfferForm';
import PressReleaseForm from '../../dealOffers/form/PressReleaseForm';
import PressReleaseEdit from '../../pressRelease/PressReleaseEdit';


class DealOffers extends Component {
  state = {
    dealOffers: [],
    openMenu: null,
    openStatusMenu: null,
    closeDealOffer: false,
    declineDealOffer: false,
  };

  componentDidMount() {
    const {dealOffers} = this.props;
    if (dealOffers)
      this.setState({dealOffers});

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dealOffers !== this.props.dealOffers) {
      this.setState({dealOffers: nextProps.dealOffers});
    }
  }

  handleOpenMenu = (target, dealOffer) => {
    this.setState({
      openMenu: {target, dealOffer},
    });
  };

  handleCloseMenu = () => {
    this.setState({
      openMenu: null,
    });
  };

  openSetStatusForm = (target, dealOffer) => {
    this.setState({
      openMenu: null,
      openStatusMenu: {target, dealOffer},
    });
  };

  handleChangeStatus = (nextStatus, prevStatus) => {
    const {openStatusMenu, dealOffers} = this.state;

    if (nextStatus === 'closed') {
      return this.setState({closeDealOffer: true});
    } else if (nextStatus === 'declined') {
      return this.setState({declineDealOffer: true});
    }

    this.setState({
      dealOffers: dealOffers.map(dealOffer => {
        if (dealOffer.offer.id === openStatusMenu.dealOffer.offer.id) {
          return {
            ...dealOffer,
            status: nextStatus,
          };
        }
        return dealOffer;
      }),
    });

    axios.post('/api/v1/setDealOfferStatus', {id: openStatusMenu.dealOffer.id, status: nextStatus})
      .then(() => this.props.updateActivity && this.props.updateActivity())
      .catch(error => {
        this.setState({
          dealOffers: dealOffers.map(dealOffer => {
            if (dealOffer.offer.id === openStatusMenu.dealOffer.offer.id) {
              return {
                ...dealOffer,
                status: prevStatus,
              };
            }
            return dealOffer;
          }),
        });
        if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });

    this.setState({
      openStatusMenu: null,
    });
  };

  handleDeclineDealOffer = () => {
    const {dealOffers, openStatusMenu} = this.state;

    this.setState({
      declineDealOffer: false,
      dealOffers: dealOffers.map(dealOffer => {
        if (dealOffer.offer.id === openStatusMenu.dealOffer.offer.id) {
          return {
            ...dealOffer,
            status: 'declined',
            dealEndAt: new Date(),
          };
        }
        return dealOffer;
      }),
      openStatusMenu: null,
    });
  };

  handleCloseDealOffer = () => {
    const {dealOffers, openStatusMenu} = this.state;
    this.setState({pressReleaseOpen: true});
    this.setState({
      closeDealOffer: false,
      dealOffers: dealOffers.map(dealOffer => {
        if (dealOffer.offer.id === openStatusMenu.dealOffer.offer.id) {
          return {
            ...dealOffer,
            status: 'closed',
            dealEndAt: new Date(),
          };
        }
        return dealOffer;
      }),
    });
  };

  checkAccess = id => {
    const {dealOffers} = this.state;
    const {selfUser} = this.props;

    let access = false;
    const dealOffer = find(dealOffers, dealOffer => dealOffer.id === id);
    const isAssigned = Boolean(find(dealOffer.assignees, assignee => assignee.id === selfUser.id));
    const hasMember = Boolean(intersectionBy(selfUser.teamMembers, dealOffer.assignees, 'id'));

    if (hasRole(selfUser.role, userRoles.ADMIN)) {
      access = true;
    } else if (hasRole(selfUser.role, userRoles.MANAGER) && hasMember) {
      access = true;
    } else if (isAssigned) {
      access = true;
    }

    return access;
  };

  render() {
    const {
      dealOfferActiveStatuses,
      handleDialogOpen,
      loading,
      error,
      history,
      page,
      perPage,
      handleChangePage,
      totalCount,
    } = this.props;

    const {
      dealOffers,
      openMenu,
      openStatusMenu,
      closeDealOffer,
      declineDealOffer,
      pressReleaseOpen,
      pressReleaseEdit,
    } = this.state;
    return (
      <React.Fragment>
        <List subheader={
          <ListSubheader
            disableSticky
            style={{position: 'relative'}}
          >
            Сделки ({totalCount})
            <ListItemSecondaryAction>
              {loading ? (
                <IconButton disabled>
                  <CircularProgress size={24}/>
                </IconButton>
              ) : (
                error ? (
                  <IconButton disabled>
                    <icons.ErrorOutline/>
                  </IconButton>
                ) : null
              )}
            </ListItemSecondaryAction>
          </ListSubheader>
        }>
          {dealOffers.map(dealOffer => (
            <DealOfferListItem
              key={dealOffer.id}
              dealOffer={dealOffer}
              onSecondaryActionClick={this.checkAccess(dealOffer.id) ? e => this.handleOpenMenu(e.currentTarget, dealOffer) : null}
              handleDialogOpen={this.checkAccess(dealOffer.id) ? () => handleDialogOpen(dealOffer.id) : null}
              color={colors.indigo[500]}
              linkTo={`/dealOffers/${dealOffer.id}`}
            />
          ))}
          <Menu
            anchorEl={openMenu ? openMenu.target : null}
            open={Boolean(openMenu)}
            onClose={this.handleCloseMenu}
          >
            <MenuItem onClick={() => this.openSetStatusForm(openMenu.target, openMenu.dealOffer)}>
              Поменять статус
            </MenuItem>
          </Menu>

          {openStatusMenu ? (
            <Menu
              anchorEl={openStatusMenu.target}
              open={true}
              onClose={() => this.setState({openStatusMenu: null})}
            >
              {dealOfferActiveStatuses.map(({value, label}, i) => (
                <MenuItem
                  key={i}
                  value={value}
                  selected={value === openStatusMenu.dealOffer.status}
                  onClick={() => this.handleChangeStatus(value, openStatusMenu.dealOffer.status)}
                >
                  {label}
                </MenuItem>
              ))}
            </Menu>
          ) : null}
        </List>
        {closeDealOffer ? (
          <CloseDealOfferForm
            dealOffer={openStatusMenu.dealOffer}
            onSubmit={data => this.handleCloseDealOffer(data)}
            onClose={() => this.setState({closeDealOffer: false})}
            open={Boolean(closeDealOffer)}
            initialValues={{
              endAt: null,
              area: openStatusMenu.dealOffer.offer.property.normalizedArea,
            }}
          />
        ) : null}
        {pressReleaseOpen ? (
          <PressReleaseForm
            dealOffer={openStatusMenu.dealOffer}
            submit={() => this.setState({pressReleaseOpen: false, pressReleaseEdit: true})}
            onClose={() => this.setState({pressReleaseOpen: false})}
            open={Boolean(pressReleaseOpen)}
          />
        ) : null}
        {pressReleaseEdit ? (
          <PressReleaseEdit
            dealOffer={openStatusMenu.dealOffer}
            onSubmitted={data => {
              this.setState({pressReleaseEdit: false});
              history.push(`/pressRelease/dealClose/${data.id}`);
            }}
            onClose={() => this.setState({pressReleaseEdit: false})}
            type={'dealClose'}
            open={Boolean(pressReleaseEdit)}
          />
        ) : null}
        {declineDealOffer ? (
          <DeclineDealOfferForm
            dealOffer={openStatusMenu.dealOffer}
            onSubmit={data => this.handleDeclineDealOffer(data)}
            onClose={() => this.setState({declineDealOffer: false})}
            open={Boolean(declineDealOffer)}
          />
        ) : null}
        <ShortPagination
          page={page}
          totalCount={totalCount}
          perPage={perPage}
          handleChangePage={handleChangePage}
        />
      </React.Fragment>
    );
  }
}

const styles = () => ({
  statusIcon: {
    display: 'inline-block',
    lineHeight: 1,
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 4,
  },
});

DealOffers = connect(
  state => ({
    dealOfferActiveStatuses: state.root.classification.dealOfferActiveStatuses,
    selfUser: state.root.selfUser,
  }),
)(DealOffers);

export default withRouter(withStyles(styles)(DealOffers));
