import React, {Component} from 'react';
import {connect} from 'react-redux';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';

import {mapLabels} from '../../utils';
import {downloadPropertyPhotoZip} from '../../utils/photoFunc';
import PropertyDetails from './PropertyDetails';

class DealDetailsWrapper extends Component {
  state = {
    menuAnchor: null,
  };

  render() {
    const {
      classes,
      property,
      onEdit,
      propertyTypeLabels,
    } = this.props;
    const {menuAnchor} = this.state;

    const formatAddress = address => {
      return address;
    };

    return (
      <Paper elevation={1} square className="column">
        <CardHeader
          action={
            <IconButton
              classes={{root: classes.icon}}
              onClick={({currentTarget}) => {
                this.setState({menuAnchor: currentTarget});
              }}
            >
              <icons.MoreVert/>
            </IconButton>
          }
          title={propertyTypeLabels[property.type.value] || property.type.value}
          subheader={formatAddress(property.address.value)}
        />
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClick={() => {
            this.setState({menuAnchor: null});
          }}
        >
          <MenuItem onClick={() => onEdit()}>
            Редактировать
          </MenuItem>
          <MenuItem onClick={() => downloadPropertyPhotoZip(property.id, propertyTypeLabels[property.type.value])}>
            Скачать архив фотографий
          </MenuItem>
        </Menu>
        <Divider/>
        <PropertyDetails property={property}/>
      </Paper>
    );
  }
}

const styles = () => ({
  avatar: {
    background: colors.green[300],
  },
});

export default connect(
  ({root}) => ({
    propertyTypeLabels: mapLabels(root.classification.propertyTypes, 'value', 'label'),
  })
)(withStyles(styles)(DealDetailsWrapper));
