import axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import OfferChip from '../../../components/OfferChip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import qs from 'qs';

import Address from '../../property/form/Address';
import {
  createAreaFormatter, 
  formatAddressListItem, 
  mapLabels,
} from '../../../utils';

class OfferSearch extends Component {
  PER_PAGE = 5;

  state = {
    loading: false,
    offerList: null,
    searchAnchor: null,
    searchAddress: null,
  };

  handleSearch = (e, address) => {
    this.setState({
      searchAnchor: e.currentTarget,
      searchAddress: address,
      offerList: null,
    });
    this.search(0, address);
  };

  handlePrevPage = () => {
    const page = this.state.offerList.page - 1;
    this.search(page, this.state.searchAddress);
  };

  handleNextPage = () => {
    const page = this.state.offerList.page + 1;
    this.search(page, this.state.searchAddress);
  };

  search(page, address) {
    const url = '/api/offers?' + qs.stringify({
      _source: 'team',
      _perPage: this.PER_PAGE,
      _page: page,
      fiasCityId: address.attributes ? address.attributes.city_fias_id : null,
      fiasHouseId: address.attributes ? address.attributes.house_fias_id : null,
      street: address.street,
      district:address.district,
      microDistrict:address.microDistrict,
      buildingNumber: address.building ? address.building.number : null,
      buildingBlock: address.building ? address.building.block : null,
      buildingLetter: address.building ? address.building.letter : null,
    });

    this.setState({loading: true});
    axios.get(url)
      .then(resp => {
        this.setState({offerList: resp.data, loading: false});
      })
      .catch(() => {
        this.setState({loading: false});
      });
  }

  handleClose = () => {
    this.setState({searchAnchor: null});
  };

  handleSelect = offer => {
    this.setState({searchAnchor: null});
    this.props.onSelect(offer);
  };

  render() {
    const {
      id,
      array: {push, remove},
      addresses,
      change,
      classes,
      formatArea,
      offerTypeLabels,
      propertyTypeLabels,
      offers,
      onUnselect,
    } = this.props;
    const {searchAnchor, loading, offerList} = this.state;

    return (
      <React.Fragment>
        {!id && offers && offers.length > 0 ? (
          <Grid item xs={12}>
            {offers.map((offer, index) => (
              <OfferChip 
                offer={offer} 
                key={index}
                onDelete={() => onUnselect(index)}
              />
            ))}
          </Grid>
        ) : null}
        {addresses ? addresses.map((address, index) => (
          <Grid key={index} item xs={12}>
            <Grid container spacing={24} className={classes.subForm}>
              <Grid item xs={12}>
                <Address
                  address={address}
                  change={change}
                  showMap={false}
                  editable={true}
                  fieldName={`propertyAddresses[${index}]`}
                />
              </Grid>
              <Grid item xs={12}>
                {!id ? (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={loading || !address.value}
                    onClick={e => this.handleSearch(e, address)}
                  >
                    Найти листинг
                  </Button>
                ) : null}
                <IconButton
                  onClick={() => remove('propertyAddresses', index)}
                  style={{float: 'right'}}
                >
                  <icons.DeleteForever/>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )) : null}
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => push('propertyAddresses', {})}
          >
            Добавить адрес
          </Button>
        </Grid>
        <Popover
          PaperProps={{square: true}}
          open={Boolean(searchAnchor)}
          anchorEl={searchAnchor}
          classes={{paper: classes.popover}}
        >
          <Toolbar>
            <Typography variant="subtitle1">Листинги</Typography>
            <div style={{flex: 1}}/>
            {loading ? (
              <CircularProgress
                size={24}
                thickness={5}
                className={classes.loader}
              />
            ) : (
              <IconButton
                style={{marginRight: -20}}
                onClick={this.handleClose}
              >
                <icons.Close/>
              </IconButton>
            )}
          </Toolbar>
          <Divider/>
          <List>
            {offerList ? offerList.items.map(offer => (
              <MenuItem
                key={offer.id}
                dense
                onClick={() => this.handleSelect(offer)}
              >
                <ListItemText
                  primary={`${offerTypeLabels[offer.type]} ${formatArea(offer.property.area)}, ${(propertyTypeLabels[offer.property.type.value] || offer.property.type.value).toLowerCase()}`}
                  secondary={`${formatAddressListItem(offer.property.address)}`}
                />
              </MenuItem>
            )) : null}
            {offerList && offerList.totalCount < 1 ? (
              <ListItem>
                <ListItemText primary="Листинги не найдены" secondary="Попробуйте изменить запрос"/>
              </ListItem>
            ) : null}
          </List>
          <Divider/>
          {offerList && offerList.totalCount > 0 ? (
            <Toolbar>
              <IconButton
                style={{marginLeft: -20}}
                disabled={offerList.page < 1}
                onClick={this.handlePrevPage}
              >
                <icons.ChevronLeft/>
              </IconButton>
              <IconButton
                disabled={offerList.page >= Math.floor((offerList.totalCount - 1) / this.PER_PAGE)}
                onClick={this.handleNextPage}
              >
                <icons.ChevronRight/>
              </IconButton>
              <Typography variant="caption">
                {`${offerList.page + 1} / ${Math.floor((offerList.totalCount - 1) / this.PER_PAGE) + 1}`}
              </Typography>
            </Toolbar>
          ) : null}
        </Popover>
      </React.Fragment>
    );
  }
}

const styles = {
  popover: {
    minWidth: 312,
  },
  loader: {},
  subForm: {
    width: '100%',
    margin: 0,
    background: '#FFFDE7',
    border: '1px solid #FFF9C4',
  },
  offerChip: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
};

export default connect(
  state => ({
    formatArea: createAreaFormatter(state.root.classification.areaUnits),
    offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
  })
)(withStyles(styles)(OfferSearch));
