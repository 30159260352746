import React from 'react';
import {TextField as MuiTextField} from 'redux-form-material-ui';
import Typography from '@material-ui/core/Typography';

const TextField = ({input: {value, onBlur, ...inputProps}, ...otherProps}) => (
  <div style={{display: 'inline-flex', width: otherProps.width, position: 'relative'}}>
    <MuiTextField
      input={{
        value,
        ...inputProps,
      }}
      {...otherProps}
      inputProps={{
        style: otherProps.multiline ? {
          minHeight: '3rem',
          maxHeight: '12rem',
        } : {},
      }}
      onBlur={() => onBlur(value)}
    />
    {otherProps.min || otherProps.max ? <Typography style={{position: 'absolute', right: '10px', top: '13px'}}>{value.length}/{otherProps.min ? otherProps.min : ''}{otherProps.min && otherProps.max ? '-' : ''}{otherProps.max ? otherProps.max : ''}</Typography>:null}
  </div>
);

export default TextField;
