import axios from 'axios';
import React, {Component} from 'react';
import {reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

class AddDeal extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      open,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Добавить заявку?</DialogTitle>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отмена
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={handleSubmit(this.submit)}
          >
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = data => {
    const {onSubmitted} = this.props;

    return axios.post(`/api/deals/${data.dealId}/offers`, data)
      .then(resp => {
        onSubmitted(resp.data);
      })
      .catch(error => {
        if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

export default reduxForm({
  form: 'add_assignee_to_offer',
  enableReinitialize: true,
})(AddDeal);
