import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {formatDate} from '../../utils';
import {hasRole} from '../../utils/roleFunc';
import * as userRoles from '../../constants/userRoles';
import TablePaginationActions from '../../components/TablePaginationActions';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import {queryAuthorizationList} from '../../queries';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';

class AuthLog extends Component {
  state = {
    items: [],
    loading: false,
    error: false,
    totalCount: 0,
    query: {limit: this.props.size, offset: 0},
  };

  componentDidMount() {
    this.getList(this.props.member);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.member !== this.props.member) {
      this.getList(nextProps.member);
    }
  }

  getList(member, page) {
    if(!member || !member.email)
      return null;

    if(!page)
      page = 0;

    let query = {...this.state.query, username: member.email, offset: page * this.props.size};
    this.setState({loading: true, query});
    queryAuthorizationList(query)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            loading: false,
            error: false,
            items: resp.data.data.authorizations.items,
            totalCount: resp.data.data.authorizations.totalCount,
          });
        } else {
          this.setState({loading: false, error: true});
        }
      })
      .catch(() => {
        this.setState({loading: false, error: true});
      });
  }

  handleChangePage = (_, page) => {
    this.getList(this.props.member, page);
  };

  render() {
    const {
      classes,
      member,
      selfUser,
    } = this.props;

    const {
      totalCount,
      loading,
      error,
      query,
      items,
    } = this.state;

    if (!member)
      return null;
    if(!hasRole(selfUser.role, userRoles.ADMIN) && !hasRole(selfUser.role, userRoles.MANAGER))
      return null;
    return (
        <>
          <Typography
            variant="h6"
            className={classes.textLabel}
          >
            Список авторизаций
            {loading || error ? (
              loading ? (
                <CircularProgress size={18} thickness={4} className={classes.progress}/>
              ) : (
                error ? (
                  <icons.ErrorOutline className={classes.progress}/>
                ) : null
              )
            ) : null}
          </Typography>
          <Table className={classes.table} >
            <TableHead>
              <TableRow>
                <TableCell>Дата и время</TableCell>
                <TableCell>IP</TableCell>
                <TableCell>Устройство</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.map((item, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{item.dateTime ? formatDate(item.dateTime) : ''}</TableCell>
                    <TableCell>{item.ip ? item.ip : ''}</TableCell>
                    <TableCell>{item.userAgent ? item.userAgent : ''}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={3}
                  rowsPerPage={this.props.size}
                  rowsPerPageOptions={[this.props.size]}
                  count={totalCount || 0}
                  page={query.offset ? Math.floor(query.offset / this.props.size) : 0}
                  onChangePage={this.handleChangePage}
                  labelDisplayedRows={({from, to, count}) => `${from}-${to} из ${count}`}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </>
    );
  }
}

const styles = {
  textLabel: {
    marginTop: 40,
    marginBottom: 4,
    textAlign: 'center',
  },
  progress: {
    verticalAlign: 'text-top',
    color: colors.grey[500],
    height: 18,
    width: 18,
  },
};

export default connect(
  state => ({
    selfUser: state.root.selfUser,
  })
)(withStyles(styles)(AuthLog));
