import React, {Component} from 'react';
import {find} from 'lodash';
import {withApollo} from 'react-apollo';
import EntityListStar from '../components/EntityListStar';
import RemoveFavorite from '../components/RemoveFavorite';
import AddFavorite from '../components/AddFavorite';
import {queryInFavorites} from '../../../queries/FavoritesQuery';
import {inFavoritesQuery} from '../../../queries/FavoritesQuery';

class FavoritesWrapper extends Component {
  state = {
    removeFavorite: false,
    addFavorite: false,
    favList: [],
  };

  componentDidMount() {
    if (this.props.items.length > 0) {
      this.getFavoriteList(this.props.items);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.items.length !== this.props.items.length || (this.props.items.length !== 0 && this.props.items[0] !== newProps.items[0])) {
      this.getFavoriteList(newProps.items);
    }
  }

  getFavoriteList = ids => {
    const {entityId} = this.props;
    queryInFavorites({ids})
      .then(res => {
        let favList = res.data.data.inFavorites.items.map(item => {
          return {id: item.id, [entityId]: item.entity.id};
        });
        this.setState({favList});
      })
      .catch(() => {
        alert('Произошла ошибка');
      });
  };

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        ...this.props,
        favList: this.state.favList,
        action: (item, selected, entityId, favList) => (
          <EntityListStar
            favList={favList}
            item={item}
            entityId={entityId}
            onRemoveFavoriteClick={id => this.onRemoveFavoriteClick(id)}
            onAddFavoriteClick={id => this.onAddFavoriteClick(id)}
          />
        ),
      })
    );

    const {addFavorite, removeFavorite} = this.state;

    return (
      <div>
        {childrenWithProps}
        {addFavorite ? (
          <AddFavorite
            open={Boolean(addFavorite)}
            entity={addFavorite}
            onSubmitted={(data, id) => this.onAddFavorite(data, id)}
            onClose={() => this.setState({addFavorite: null})}
          />
        ) : null}
        {removeFavorite ? (
          <RemoveFavorite
            open={Boolean(removeFavorite)}
            initialValues={removeFavorite}
            onSubmitted={() => this.onRemoveFavorite(removeFavorite.id)}
            onClose={() => this.setState({removeFavorite: null})}
          />
        ) : null}
      </div>
    );
  }

  apolloRefetch = () => {
    const {client} = this.props;
    return client.query({
      query: inFavoritesQuery,
      variables: {
        ids: this.props.items,
      },
      fetchPolicy: 'network-only',
    });
  };

  onRemoveFavorite = id => {
    const {favList} = this.state;
    const newFavorites = favList.filter(data => data.id !== id);
    this.setState({favList: newFavorites, removeFavorite: null});
    this.apolloRefetch();
  };

  onRemoveFavoriteClick = id => {
    const {favList} = this.state;
    const {entityId} = this.props;
    let favorite = find(favList, fav => fav[entityId] === id);
    this.setState({removeFavorite: {id: favorite.id}});
  };

  onAddFavorite = (data, id) => {
    const {favList} = this.state;
    const {entityId} = this.props;
    this.setState({favList: [...favList, {id: data.id, [entityId]: id}]});
    this.apolloRefetch();
  };

  onAddFavoriteClick = id => {
    const {entityType} = this.props;
    this.setState({addFavorite: {id, type: entityType}});
  };

}

export default withApollo(FavoritesWrapper);
