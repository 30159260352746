const defaultState = {
  authenticated: false,
};

export default (state = defaultState, {type, ..._payload}) => {
  switch (type) {
    case 'AUTHENTICATE':
      return {
        ...state,
        authenticated: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        authenticated: false,
      };
    default:
      return state;
  }
};
