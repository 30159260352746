import gql from 'graphql-tag';

export default gql`
  query taskList($filter: TaskFilter) {
    tasks(filter: $filter) {
      items {
        id
        title
        deadline
        description
        assignee {
          id
          name
        }
        closed
        expired
        entities {
          ... on Contact {
            __typename
            id
          }
          ... on Deal {
            __typename
            id
          }
          ... on Offer {
            __typename
            id
          }
          ... on Property {
            __typename
            id
          }
          ... on Contract {
            __typename
            id
          }
        }
        permissions {
          canCloseTask
        }
      }
      totalCount
      permissions {
        canCreateTask
      }
    }
  }
`;
