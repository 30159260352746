import {set, without, unionBy} from 'lodash';
import axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {SubmissionError, formValueSelector, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';

import {updateProperty} from '../../actions';
import {queryContractAxios} from '../../queries/ContractsQuery';
import Form from './form';
import ContactForm from '../contacts/form/ContactForm';

class ContractEdit extends Component {
  state = {
    contact: null,
    createContact: null,
    assignees: [],
    properties: [],
    contactAssignees: [],
  };

  componentDidMount() {
    const {initialize, id} = this.props;

    if (id) {
      queryContractAxios({id}).then(resp => {
        const contract = resp.data.data.contract;
        initialize({
          contract: {
            ...contract,
            assignees: contract.assignees.map(assignee => assignee.id),
            contact: contract.contact.id,
            properties: contract.properties && contract.properties.map(property => property.id),
          },
        });
      });
    } else {
      initialize({
        contract: {
          contact: this.props.sourceContact
            ? this.props.sourceContact.id
            : null,
          properties: this.props.sourceProperty
            ? [this.props.sourceProperty.id]
            : null,
          assignees: [],
          selfAssignee: true,
          startAt: null,
          endAt: null,
        },
        contact: {
          attributes: [],
          persons: [],
          assignees: [],
          status: 'opened',
          offerPrice: {value: {currency: 'RUB'}},
          open: false,
          showTeam: false,
          blacklist: false,
        },
      });
      if (this.props.sourceContact) {
        const {sourceContact} = this.props;
        this.onContactSelect(sourceContact);
        sourceContact.properties.map(id => {
          this.onPropertySelect(id);
          return id;
        });
      }
      if (this.props.sourceProperty) {
        const {sourceProperty, contacts} = this.props;
        this.setState({properties: [this.props.sourceProperty]});
        let contact = contacts[sourceProperty.owner.id];
        if(contact)
          this.onContactSelect(contact);
      }
    }
  }

  onContactSelect = contact => {
    const {change, selfUser} = this.props;
    change('contract.contact', contact.id);
    this.setState({contact});
    if (contact.assignees) {
      const contactAssignees = contact.assignees.filter(
        assignee => assignee.id !== selfUser.id
      );
      const newContactAssignees = unionBy(
        this.state.assignees,
        contactAssignees,
        'id'
      );
      this.setState({assignees: newContactAssignees});
      change(
        'contract.assignees',
        newContactAssignees.map(assignee => assignee.id)
      );
    }
  };

  onPropertySelect = property => {
    const {array, properties} = this.props;
    array.push('contract.properties', property);
    if (properties[property]) {
      this.setState(prevState => ({properties: [...prevState.properties, properties[property]]}));
    } else {
      axios.get(`/api/properties/${property}`)
        .then(res => {
          const newProperty = res.data;
          this.setState(prevState => ({properties: [...prevState.properties, newProperty]}));
        });
    }
  };

  render() {
    const {
      array,
      change,
      classes,
      contactAttributes,
      contactType,
      contactPersons,
      contactSubject,
      handleSubmit,
      id,
      initialized,
      pristine,
      submitting,
      teams,
      onClose,
      contractType,
      blacklist,
      showTeam,
    } = this.props;
    const {
      contact,
      createContact,
      assignees,
      properties,
      contactAssignees,
    } = this.state;

    if (!initialized) {
      return null;
    }

    if (createContact) {
      return (
        <Dialog fullScreen open classes={{paper: classes.dialog}}>
          <DialogTitle>Контакт</DialogTitle>
          <Divider />
          <DialogContent>
            <ContactForm
              array={array}
              change={change}
              attributes={contactAttributes}
              contactType={contactType}
              persons={contactPersons}
              subject={contactSubject}
              blacklist={blacklist}
              showTeam={showTeam}
              assignees={contactAssignees}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              type="button"
              disabled={submitting}
              onClick={() =>
                this.setState({createContact: null, contact: null})
              }
            >
              Отменить
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={handleSubmit(this.onContactSubmit)}
            >
              Сохранить
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return (
      <Dialog fullScreen open classes={{paper: classes.dialog}}>
        <DialogTitle>
          Договор
          <IconButton className={classes.closeButton} onClick={() => onClose()}>
            <icons.Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Form
            id={id}
            array={array}
            change={change}
            assignees={assignees}
            properties={properties}
            team={teams[0]}
            contractType={contractType}
            contact={contact}
            onContactCreate={createContact => {
              this.setState({createContact});
              change('contact.phone', createContact.phone);
            }}
            onContactUnassign={() => {
              change('contract.contact', null);
              this.setState({contact: null});
            }}
            onContactSelect={contact => {
              this.onContactSelect(contact);
              contact.properties && contact.properties.map(property => {
                this.onPropertySelect(property);
                return id;
              });
            }}
            onAssigneeSelect={assignee => {
              array.push('contract.assignees', assignee.id);
              this.setState({assignees: [...this.state.assignees, assignee]});
            }}
            onAssigneeUnselect={index => {
              array.remove('contract.assignees', index);
              this.setState({
                assignees: without(
                  this.state.assignees,
                  this.state.assignees[index]
                ),
              });
            }}
            onPropertyUnselect={property => {
              array.remove('contract.properties', property);
              this.setState({
                properties: without(
                  this.state.properties,
                  this.state.properties[property]
                ),
              });
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button type="button" disabled={submitting} onClick={() => onClose()}>
            Отменить
          </Button>
          <Button
            type="button"
            disabled={pristine || submitting}
            onClick={handleSubmit(this.submit)}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = ({contract}) => {
    const {onSubmitted, id} = this.props;
    let url = '/api/v1/createContract';

    if (id) {
      url = '/api/v1/updateContract';
    }

    return axios
      .post(url, {id, contract})
      .then(resp => {
        onSubmitted({...contract, id: resp.data.id});
      })
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(
            error.response.data.error.errors.reduce(
              (errors, {propertyPath, message}) => {
                return set(errors, propertyPath, message);
              },
              {}
            )
          );
        } else if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };

  onContactSubmit = ({contact}) => {
    const {change} = this.props;

    return axios
      .post('/api/contacts', {contact})
      .then(resp => {
        change('contract.contact', resp.data.id);
        this.setState({
          createContact: null,
          contact: {
            ...contact,
            id: resp.data.id,
          },
        });
      })
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(
            error.response.data.error.errors.reduce(
              (errors, {propertyPath, message}) => {
                return set(errors, propertyPath, message);
              },
              {}
            )
          );
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

const styles = () => ({
  dialog: {
    maxWidth: 960,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

ContractEdit = withStyles(styles)(ContractEdit);

const selector = formValueSelector('contract');
ContractEdit = connect(state => ({
  selfUser: state.root.selfUser,
  teams: state.root.selfUser.teams,
  contacts: state.root.contact.contacts,
  properties: state.root.property.properties,
  contactType: selector(state, 'contact.type'),
  contactAttributes: selector(state, 'contact.attributes'),
  contactPersons: selector(state, 'contact.persons'),
  contactSubject: selector(state, 'contact.subject'),
  contractType: selector(state, 'contract.type'),
  blacklist: selector(state, 'contact.blacklist'),
  showTeam: selector(state, 'contact.showTeam'),
}), {
  updateProperty,
})(ContractEdit);

export default reduxForm({
  form: 'contract',
})(ContractEdit);
