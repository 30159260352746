import {combineReducers} from 'redux';

import app from './app';
import auth from './auth';
import classification from './classification';
import contact from './contact';
import lightbox from './lightbox';
import offer from './offer';
import property from './property';
import selfUser from './selfUser';
import team from './team';
import settings from './settings';
import reference from './reference';
import deal from './deal';
import dealOffer from './dealOffer';
import hint from './hint';
import entityNames from './entityNames';
import district from './district';
import pressRelease from './pressRelease';
import publicDeals from './publicDeals';
import selection from './selection';

export default combineReducers({
  app,
  auth,
  classification,
  contact,
  lightbox,
  offer,
  property,
  selfUser,
  team,
  settings,
  reference,
  deal,
  hint,
  dealOffer,
  entityNames,
  district,
  pressRelease,
  publicDeals,
  selection,
});
