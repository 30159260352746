import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {Select} from 'redux-form-material-ui';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import TextField from '../../../components/Input/TextField';

class FilterCities extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      onSubmit,
      open,
      pristine,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Настройки фильтра</DialogTitle>
        <Divider />
        <DialogContent>
          <Field
            name="searchQuery"
            label="Фраза для поиска"
            component={TextField}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Видимость</InputLabel>
            <Field name="archived" component={Select}>
              <MenuItem value={0}>Все</MenuItem>
              <MenuItem value={1}>Архивированные</MenuItem>
              <MenuItem value={2}>Неархивированные</MenuItem>
            </Field>
          </FormControl>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(onSubmit)}
          >
            Применить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'filter_cities',
  enableReinitialize: true,
})(FilterCities);
