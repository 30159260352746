import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, FormSection} from 'redux-form';
import {Checkbox} from 'redux-form-material-ui';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import {setListSettings} from '../actions';
import {Grid} from '@material-ui/core';
import TextField from './Input/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

class ListSettings extends Component {
  state = {
    page: 30,
  };
  onChangePage = e => {
    this.setState({
      page: e.target.value,
    });
  };
  componentDidMount(){
    const {initialValues} = this.props;
    this.setState({
      page: initialValues.page,
    });
  }
  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      open,
      pristine,
      columnNames,
    } = this.props;
    const {page} = this.state;
    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Настройки списка</DialogTitle>
        <Divider />
        <DialogContent>
          <FormSection name={'columns'}>
            <Grid container>
              {columnNames.map((column, key) => (
                <Grid  key={key} item xs={6}>
                  <FormControl margin="none">
                    <FormControlLabel
                      control={<Field name={column.value} component={Checkbox}/>}
                      label={column.label}
                    />
                  </FormControl>
                </Grid>
              ))}
            </Grid>
          </FormSection>
          <Grid container>
            <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
              <InputLabel style={{marginRight: '5px'}} htmlFor="page">Записей на странице</InputLabel>
              <Field
                id="page"
                name="page"
                value={page}
                select
                onChange={this.onChangePage}
                component={TextField}
              >
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
              </Field>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(this.submit)}
          >
            Применить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = data => {
    const {setListSettings, entityList, onSubmitted} = this.props;
    onSubmitted(data);
    setListSettings(entityList, data);
  };
}

ListSettings = connect(
  state => ({
    settings: state.root.settings,
  }), {
    setListSettings,
  }
)(ListSettings);

export default reduxForm({
  form: 'offers_settings',
  enableReinitialize: true,
})(ListSettings);
