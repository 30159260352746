import React from 'react';
import {connect} from 'react-redux';

import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button';
import {Field, reduxForm} from 'redux-form';
import Toolbar from '@material-ui/core/Toolbar';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {ListItem, ListItemText} from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';

import {setFavicon} from '../actions';
import DatePicker from './Input/DatePicker';
import TextField from './Input/TextField';
import {formatDate, drawFavicon} from '../utils';
import {updateSub} from '../utils/pushNotifications';
import {notificationsList, notificationsUnreadCount} from '../queries/NotificationQuery';
import DealDialog from '../containers/deals/DealDialog';
import DealOfferDialog from '../containers/dealOffers/DealOfferDialog';
import OfferDialog from '../containers/offer/OfferDialog';
import TaskView from '../is/tasks/containers/TaskView';

class NotificationsList extends React.Component {
  constructor(props) {
    super(props);
    window.addEventListener('storage', this.changeLocalStorage);
  }
  state = {
    page: 0,
    notifications: [],
    countUnread: 0,
    totalCount: 0,
    totalCountAll: 0,
    anchor: null,
    allList: false,
    filter: {
      unread: undefined,
      entityType: undefined,
    },
    unreadFilter: undefined,
    notificationsFullList: [],
    reload: false,
    openDetails: {
      entity: null,
      entityId: null,
    },
  };
  updateTime = 30000;
  updateTimer = null;
  sendReadIds = [];
  sendUnreadIds = [];
  sendReadTimer = null;
  sendUnreadTimer = null;
  sendTime = 500;
  changeFaviconTime = 1000;
  changeFaviconTimer = null;
  nextFavicon = false;
  PER_PAGE = 10;

  componentDidMount() {
    this.error = 0;
    updateSub();
    let notification = this.getNotificationCache();
    this.setState({
      countUnread: notification.unread,
      totalCountAll: notification.totalCount,
    }, this.updateCount);

    this.myAudio = new Audio('/assets/notify/msg.mp3');
    this.myAudio.autoplay = false;
    this.updateTimer = setInterval(this.updateCount, this.updateTime);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.changeLocalStorage);
    clearInterval(this.sendReadTimer);
    clearInterval(this.sendUnreadTimer);
    clearInterval(this.changeFaviconTimer);
    clearInterval(this.updateTimer);

  }

  saveNotificationCache = (unread, totalCount, time = 0) => {
    localStorage['unreadNotification'] = unread;
    localStorage['totalCountNotification'] = totalCount;
    localStorage['timeNotification'] = time;
  };

  saveNotificationCacheByKey = (key, value) => {
    localStorage[key] = value;
  };

  getNotificationCache = key => {
    if(key){
      return parseInt(localStorage[key]) || 0;
    }
    let time = parseInt(localStorage['timeNotification']) || 0;
    let totalCount = parseInt(localStorage['totalCountNotification']) || 0;
    let unread = parseInt(localStorage['unreadNotification']) || 0;
    return {
      time,
      totalCount,
      unread,
    };
  };

  updateCount = () => {
    if(this.error >= 5)
      return;
    let notification = this.getNotificationCache();
    let sec = Date.now();
    if(notification.time <= sec - this.updateTime){
      notification.time = sec;
      this.saveNotificationCache(notification.unread, notification.totalCount, sec);
      notificationsUnreadCount()
        .then(resp => {
          if(resp.data.errors){
            this.error++;
            return;
          }
          const {unread, totalCount} = resp.data.data.notificationsList;
          this.error = 0;
          if(this.state.totalCountAll !== 0 && totalCount > this.state.totalCountAll){
            let promise = this.myAudio.play();
            promise.catch(()=>{});
            if(document.hidden || (!this.state.anchor && !this.state.allList)){
              let oldCount = this.getNotificationCache('newCountNotification');
              let newCount = totalCount - this.state.totalCountAll + oldCount;
              this.saveNotificationCacheByKey('newCountNotification', newCount);
              this.showNew(newCount);
            }
            if(!document.hidden && !this.state.reload)
              if(this.state.anchor)
                this.updateList();
              else
              if(this.state.allList)
                this.updateFullList();
          }
          this.saveNotificationCache(unread, totalCount, Date.now());
          this.setState({
            countUnread: this.state.reload ? this.state.countUnread : unread,
            totalCount: this.state.allList && this.state.filter.unread ? this.state.totalCount : totalCount,
            totalCountAll: totalCount,
          });
        })
        .catch(() => {
          this.error++;
        });
    }
  };

  changeLocalStorage = event => {
    switch(event.key){
      case 'breakNotification':
        this.breakShowNew();
        break;
      case 'totalCountNotification':
        if(!document.hidden)
          if(this.state.anchor)
            this.updateList();
          else
          if(this.state.allList)
            this.updateFullList();
        this.setState({totalCountAll: event.newValue});
        break;
      case 'unreadNotification':
        this.setState({countUnread: event.newValue});
        break;
      case 'newCountNotification':
        if(parseInt(event.newValue) === 0)
          this.breakShowNew();
        else
          this.showNew(event.newValue);
        break;
      default:
        break;
    }
  };

  showNew = newCount => {
    if(this.changeFaviconTimer)
      clearInterval(this.changeFaviconTimer);
    this.changeFaviconTimer = setInterval(this.changeFavicon, this.changeFaviconTime, newCount);
  };

  breakShowNew = notify => {
    this.props.setFavicon();
    clearInterval(this.changeFaviconTimer);
    this.changeFaviconTimer = null;
    if(notify)
      this.saveNotificationCacheByKey('newCountNotification', 0);
  };

  changeFavicon = newCount => {
    let newIcon = this.nextFavicon ? drawFavicon(newCount) : null;
    this.props.setFavicon(newIcon);
    this.nextFavicon = !this.nextFavicon;
  };

  handleNextPage = () => {
    this.setState(prevState => ({page: prevState.page + 1}), this.updateFullList);
  };

  handlePrevPage = () => {
    this.setState(prevState => ({page: prevState.page - 1}), this.updateFullList);
  };

  setRead = ids => {
    const {allList, notifications, notificationsFullList, filter} = this.state;
    axios.post('/api/v1/readNotification', ids ? {ids} : {}).then(() => {
      if(!ids){
        if(filter.unread){
          this.setState({
            notificationsFullList: [],
            notifications: [],
            countUnread: 0,
          });
        }
        else {
          let fullList = notificationsFullList.map(element => {
            element.read = true;
            return element;
          });
          this.setState({
            notificationsFullList: fullList,
            notifications: notifications.map(element => {
              element.read = true;
              return element;
            }),
            countUnread: 0,
          });
        }
      }
      if(allList){
        this.updateFullList();
        this.setState({reload: false});
      }
      this.saveNotificationCacheByKey('timeNotification', 0);
    });
  };

  setUnread = ids => {
    axios.post('/api/v1/unreadNotification', {ids: ids}).then(() => {
      if(this.getNotificationCache('timeNotification') !== 0){
        this.saveNotificationCacheByKey('timeNotification', 0);
        if(this.state.reload){
          this.updateFullList();
        }
      }
    }).catch(() => {
      const {notifications, notificationsFullList, countUnread} = this.state;
      let fullList = notificationsFullList.map(element => {
        if(ids.find(id => element.id === id))
          element.read = true;
        return element;
      });
      let list = notifications.map(element => {
        if(ids.find(id => element.id === id))
          element.read = true;
        return element;
      });
      this.setState({
        notificationsFullList: fullList,
        notifications: list,
        countUnread: countUnread - ids.length,
      });
    });
  };

  updateList() {
    notificationsList({
      offset: 0,
      limit: 5,
      filter: {
        unread: true,
      }})
      .then(resp => {
        if(resp.data.errors){
          this.error++;
          return;
        }
        this.error = 0;
        const {unread, totalCount, items} = resp.data.data.notificationsList;
        if(this.state.anchor && items.length === 0){
          this.setState({
            allList: true,
            anchor: false,
          });
        }
        this.setState({
          notifications: items,
          countUnread: unread,
          totalCount,
        });
        this.saveNotificationCache(unread, this.state.totalCountAll, Date.now());
      })
      .catch(() => this.error++);
  }

  updateFullList() {
    const {page, filter} = this.state;
    let params = {
      offset: page * 10,
      limit: 10,
      filter,
    };

    notificationsList(params)
      .then(resp => {
        if(resp.data.errors){
          this.error++;
          return;
        }
        this.error = 0;
        const {unread, totalCount, items} = resp.data.data.notificationsList;
        this.setState({
          notificationsFullList: items,
          countUnread: unread,
          totalCount,
        });
        this.saveNotificationCache(unread, this.state.totalCountAll, Date.now());
      })
      .catch(() => {
        this.error++;
      });
  }

  chipFilterClick = type => {
    const {filter} = this.state;
    let value = filter.entityType === type ? null : type;
    this.props.change('entityType', value);
    this.setState({
      filter: {
        ...filter,
        entityType: value,
      }, page: 0},
    () => {
      this.updateFullList();
    });
  };

  unreadFilterClick = () => {
    const {filter} = this.state;
    this.setState({
      filter: {
        ...filter,
        unread: filter.unread ? undefined : true,
      },
      page: 0},
    () => {
      if(this.sendUnreadTimer)
        this.setState({reload: true});
      this.updateFullList();
    });
  };

  handleRead = () => {
    this.sendReadTimer = null;
    if(this.sendReadIds.length === 0)
      return;
    this.setRead([...this.sendReadIds]);
    this.sendReadIds = [];
  };

  handleUnread = () =>{
    this.sendUnreadTimer = null;
    if(this.sendUnreadIds.length === 0)
      return;
    this.setUnread([...this.sendUnreadIds]);
    this.sendUnreadIds = [];
  };

  itemOnClick = event => {
    const {
      notifications,
      notificationsFullList,
      countUnread,
      anchor,
      allList,
      filter,
      totalCount,
      page,
    } = this.state;

    let total = totalCount;
    let id = parseInt(event.currentTarget.getAttribute('id'));
    let read = event.currentTarget.getAttribute('read');
    if(read === 'true'){
      if(anchor){
        let index = notifications.findIndex(item => item.id === id);
        notifications[index].read = false;
      }
      else {
        let index = notificationsFullList.findIndex(item => item.id === id);
        notificationsFullList[index].read = false;
      }
      this.saveNotificationCacheByKey('unreadNotification', countUnread + 1);
      this.setState({
        notificationsFullList,
        notifications,
        countUnread: countUnread + 1,
      });
      if(this.sendUnreadTimer)
        clearTimeout(this.sendUnreadTimer);
      this.sendUnreadTimer = setTimeout(this.handleUnread, this.sendTime);
      this.sendUnreadIds.push(id);
      let deleteIndex = this.sendReadIds.indexOf(id);
      if(deleteIndex !== -1){
        this.sendReadIds.splice(deleteIndex, 1);
        if(this.sendReadIds.length === 0){
          clearTimeout(this.sendReadTimer);
          this.sendReadTimer = null;
        }
      }
    }
    else {
      if(anchor){
        let index = notifications.findIndex(item => item.id === id);
        notifications[index].read = true;
      }
      else {
        if(allList){
          if(filter.unread){
            let index = notificationsFullList.findIndex(item => item.id === id);
            notificationsFullList.splice(index, 1);
            total--;
          }
          else {
            let index = notificationsFullList.findIndex(item => item.id === id);
            notificationsFullList[index].read = true;
          }
        }
      }
      let nextPage = page;
      if(page > parseInt((total -1) / 10)){
        nextPage--;
      }
      this.saveNotificationCacheByKey('unreadNotification', countUnread - 1);
      this.setState({
        notificationsFullList,
        notifications,
        countUnread: countUnread - 1,
        totalCount: total,
        page: nextPage,
        reload: filter.unread && allList,
      });
      if(!this.sendReadTimer){
        this.sendReadTimer = setTimeout(this.handleRead, this.sendTime);
      }
      else{
        clearTimeout(this.sendReadTimer);
        this.sendReadTimer = setTimeout(this.handleRead, this.sendTime);
      }
      this.sendReadIds.push(id);
      let deleteIndex = this.sendUnreadIds.indexOf(id);
      if(deleteIndex !== -1){
        this.sendUnreadIds.splice(deleteIndex, 1);
        if(this.sendUnreadIds.length === 0){
          clearTimeout(this.sendUnreadTimer);
          this.sendUnreadTimer = null;
        }
      }
    }
  };

  onSubmitFilter = data => {
    this.setState({
      filter: {
        ...this.state.filter,
        entityType: data.entityType,
        ...data,
      },
      page: 0,
      openFilter: false},
    this.updateFullList);
  };

  setAllRead = e => {
    e.preventDefault();
    this.setRead();
  };

  onClickOpenInNew = (e, item, link) => {
    const {entityLinksType} = this.props;
    e.stopPropagation();
    let linkType = entityLinksType[item.entityType];
    if(linkType.preview){
      e.preventDefault();
      this.setState({
        openDetails: {
          entity: item.entityType,
          entityId: item.entityId,
        },
      });
    }else{
      window.location.href = link;
    }
  };

  handleCloseDetails = () => {
    this.setState({
      openDetails: {},
    });
  };
  handleCloseDetailsWithPopup = () => {
    this.setState({
      openDetails: {},
      anchor: null,
      allList: false,
    });
  };

  render() {
    const {
      countUnread,
      anchor,
      notifications,
      allList,
      totalCount,
      page,
      notificationsFullList,
      filter,
      openFilter,
      openDetails,
    } = this.state;

    const {
      handleSubmit,
      submitting,
      classes,
      entityLinks,
      entityLinksType,
      width,
    } = this.props;

    return (
      <>
        <div className={classes.iconContainer}>
          <IconButton
            className={classes.rightButton}
            color="inherit"
            onClick={({currentTarget}) => {
              this.breakShowNew(true);
              const matches = (width !== 'xs');
              if(countUnread !== 0 && matches){
                this.updateList();
                this.setState({anchor: currentTarget});
              }
              else {
                this.updateFullList();
                this.setState({allList: true});
              }
            }}
          >
            {countUnread !== 0 ?
              <span className={classes.countUnread}>{countUnread}</span>
              : null}
            <icons.Notifications/>
          </IconButton>
          <Popover
            open={Boolean(anchor)}
            elevation={1}
            PaperProps={{style: {
              background: colors.grey[200],
              paddingTop: 4,
            }}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => {
              this.setState({anchor: null});
            }}
            anchorEl={anchor}
          >
            <Paper style={{background: colors.grey[200], minWidth: 400, padding: 4, paddingTop: 0, paddingBottom: 0}}>
              {notifications && notifications.map((item, index) => {
                let link = null;
                if(item.entityType){
                  let entityLinkType = entityLinksType[item.entityType];
                  if(entityLinkType)
                    link = entityLinkType.getLink(item.entityId);
                }
                return(
                  <ListItem
                    title={item.read ? 'Отменить непрочитанным' : 'Отметить прочитанным'}
                    key={index}
                    id={item.id}
                    read={`${item.read}`}
                    onClick={this.itemOnClick}
                    dense
                    className={classes.activityItem}
                  >
                    <ListItemText
                      disableTypography={true}
                      primary={<Typography variant="subtitle1" className={classes.payload} dangerouslySetInnerHTML={{__html: item.content}}></Typography>}
                      className={classes.activityText}
                      secondary={
                        <Typography color="textSecondary" className={classes.metadata}>
                          {formatDate(item.createdAt)}
                        </Typography>
                      }
                    />
                    {!item.read ? <span className={classes.unread}/> : null}
                    {link ?
                      <IconButton
                        component={Link}
                        size="small"
                        onClick={e => this.onClickOpenInNew(e, item, link)}
                        to={link}
                        classes={{root: classes.dialogIcon}}
                      >
                        <icons.OpenInNew className={classes.smallIcon} />
                      </IconButton>
                      : null}
                  </ListItem>
                );
              })}
              <Paper style={{marginLeft: -4, marginRight: -4}}>
                <MenuItem
                  onClick={() => {
                    this.updateFullList();
                    this.setState({
                      allList: true,
                      anchor: null,
                    });
                  }}
                  className={classes.showAllButton}>Показать все</MenuItem>
              </Paper>
            </Paper>
          </Popover>
        </div>
        <Dialog fullScreen open={allList} classes={{paper: classes.dialog}}>
          <DialogTitle>
            <div style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'baseline'}}>
              <div style={{display: 'flex', justifyContent: 'flex-end', flex: '1 1 0'}}>
                <IconButton className={classes.filterButton} onClick={() => this.setState({openFilter: true})}>
                  <icons.FilterList />
                </IconButton>
                <IconButton className={classes.closeButton} onClick={() => this.setState({allList: false})}>
                  <icons.Close />
                </IconButton>
              </div>
              <div style={{display: 'flex', flexWrap: 'wrap',  flex: '2 1 0', paddingTop: 12}}>
                <span style={{marginRight: 5}}>Уведомления</span>
                <div>
                  <Chip  style={{marginTop: '5px', marginRight: '5px'}} label="Непрочитанные" color={filter.unread ? 'primary' : 'default'} clickable={true} onClick={this.unreadFilterClick}/>
                  <Chip style={{marginTop: '5px', marginRight: '5px'}} label="Задачи" color={filter.entityType === entityLinksType.Task.type ? 'primary' : 'default'} clickable={true} onClick={() => this.chipFilterClick(entityLinksType.Task.type)}/>
                  <Chip style={{marginTop: '5px', marginRight: '5px'}} label="Сделки" color={filter.entityType === entityLinksType.DealOffer.type ? 'primary' : 'default'} clickable={true} onClick={() => this.chipFilterClick(entityLinksType.DealOffer.type)}/>
                </div>
              </div>
            </div>
          </DialogTitle>
          <Divider />
          <DialogContent style={{paddingTop: 4, background: colors.grey[200]}}>
            {this.state.reload && notificationsFullList.length === 0
              ? <div className={classes.status}>
                <CircularProgress size={24} thickness={5}/>
              </div>
              : notificationsFullList.map((item, i) => {
                let link = null;
                if(item.entityType){
                  let entityLinkType = entityLinksType[item.entityType];
                  if(entityLinkType)
                    link = entityLinkType.getLink(item.entityId);
                }
                return (
                  <ListItem key={i} title={item.read ? 'Отменить непрочитанным' : 'Отметить прочитанным'} id={item.id} read={`${item.read}`} onClick={this.itemOnClick} dense className={classes.activityItemFullList}>
                    <ListItemText
                      disableTypography={true}
                      primary={<Typography variant="subtitle1" className={classes.payload} dangerouslySetInnerHTML={{__html: item.content}}></Typography>}
                      className={classes.activityText}
                      secondary={
                        <Typography color="textSecondary" className={classes.metadata}>
                          {formatDate(item.createdAt)}
                        </Typography>
                      }
                    />
                    {!item.read ? <span className={classes.unread}/> : null}
                    {link ?
                      <IconButton
                        component={Link}
                        size="small"
                        onClick={e => this.onClickOpenInNew(e, item, link)}
                        to={link}
                        classes={{root: classes.dialogIcon}}
                      >
                        <icons.OpenInNew className={classes.smallIcon} />
                      </IconButton>
                      : null}
                  </ListItem>

                );
              })}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Toolbar>
              {countUnread !== 0 ?
                <Typography style={{marginRight: 20, cursor: 'pointer'}} onClick={this.setAllRead}>
                  <a href="/#">
                    Отметить все как прочитанные</a>
                </Typography>
                : null}
              {notificationsFullList && totalCount > 10 ? (
                <React.Fragment>
                  <IconButton
                    style={{marginLeft: -20}}
                    disabled={page === 0}
                    onClick={this.handlePrevPage}
                  >
                    <icons.ChevronLeft/>
                  </IconButton>
                  <IconButton
                    disabled={page + 1 >= Math.floor((totalCount - 1) / 10) + 1}
                    onClick={this.handleNextPage}
                  >
                    <icons.ChevronRight/>
                  </IconButton>
                  <Typography variant="caption">
                    {`${page * this.PER_PAGE + 1} — ${(page + 1) * this.PER_PAGE > totalCount ? totalCount : (page + 1) * this.PER_PAGE} из ${totalCount}`}
                  </Typography>
                </React.Fragment>
              ) : null}
            </Toolbar>
          </DialogActions>
        </Dialog>
        {openDetails.entity === entityLinksType.Offer.type
          ? <OfferDialog
            open={openDetails.entity === entityLinksType.Offer.type}
            offer={openDetails.entityId}
            handleClose={this.handleCloseDetails}
            handleClick={this.handleCloseDetailsWithPopup}
          />
          : null}
        {openDetails.entity === entityLinksType.Deal.type
          ? <DealDialog
            deal={openDetails.entityId}
            handleClose={this.handleCloseDetails}
            handleClick={this.handleCloseDetailsWithPopup}
            open={openDetails.entity === entityLinksType.Deal.type}
          />
          : null}
        {openDetails.entity === entityLinksType.DealOffer.type
          ? <DealOfferDialog
            open={openDetails.entity === entityLinksType.DealOffer.type}
            withEntityLinks={true}
            handleClose={this.handleCloseDetails}
            handleClick={this.handleCloseDetailsWithPopup}
            dealOffer={openDetails.entityId}
          />
          : null}
          {openDetails.entity === entityLinksType.Task.type
            ? <TaskView
              id={openDetails.entityId}
              handleClose={this.handleCloseDetails}
              open={openDetails.entity === entityLinksType.Task.type}
            />
            : null}
        {openFilter ? (
          <Dialog open={openFilter}>
            <DialogTitle>Настройки фильтра</DialogTitle>
            <Divider />
            <DialogContent style={{paddingTop: 4}}>
              <Grid container spacing={8}>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal">
                    <Field
                      name="createdAtFrom"
                      label="Создан с"
                      format={null}
                      component={props => (
                        <DatePicker
                          format="yyyy-MM-dd"
                          clearable
                          {...props}
                        />
                      )}
                      fullWidth
                      margin="none"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal">
                    <Field
                      name="createdAtTo"
                      label="по"
                      format={null}
                      component={props => (
                        <DatePicker
                          format="yyyy-MM-dd"
                          clearable
                          {...props}
                        />
                      )}
                      fullWidth
                      margin="none"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Field
                name="entityType"
                label="Тип сущности"
                select
                component={TextField}
                fullWidth
                margin="normal"
              >
                <MenuItem value={null} />
                {entityLinks.map((item, index) =>
                  <MenuItem value={item.type} key={index}>{item.label}</MenuItem>
                )}
              </Field>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button
                type="button"
                color="primary"
                disabled={submitting}
                onClick={() => this.setState({openFilter: false})}
              >
                Отменить
              </Button>
              <Button
                type="button"
                color="primary"
                disabled={submitting}
                onClick={handleSubmit(this.onSubmitFilter)}
              >
                Применить
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </>
    );
  }
}


const styles = () => ({
  rightButton: {
    //marginRight: -8,
    borderRadius: 0,
  },
  dialog: {
    maxWidth: 960,
  },
  dialogIcon: {
    width: 20,
    height: 20,
    padding: 4,
    marginTop: -1,
  },
  smallIcon: {
    fontSize: 15,
  },
  iconContainer: {
    position: 'relative',
    display: 'flex',
    flex: 'auto',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  countUnread: {
    height: 24,
    fontSize: 12,
    padding: 4,
    borderRadius: '5px',
    backgroundColor: 'white',
    color: '#607d8b',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    minWidth: 24,
  },
  showAllButton: {
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    height: 'auto',
    display: 'flex',
    width: '100%',
  },
  status: {
    margin: 8,
    padding: 6,
    display: 'flex',
    justifyContent: 'center',
  },
  unread: {
    borderRadius: '50%',
    right: 30,
    position: 'absolute',
    width: 7,
    height: 7,
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: colors.amber[600],
  },
  closeButton: {

  },
  filterButton: {
  },
  payload: {
    fontSize: 'inherit',
  },
  additionalText:{
    fontSize: 'inherit',
    wordWrap:'break-word',
    padding: '2px 6px',
    width:'100%',
    marginBottom: 4,
    backgroundColor: colors.indigo[50],
    border: `1px solid ${colors.indigo[100]}`,
    borderRadius: 2,
  },
  activityText: {
    whiteSpace: 'pre-line',
    paddingRight: -15,
  },
  activityItemFullList: {
    backgroundColor: '#fff',
    borderRadius: 3,
    paddingRight: 0,
    marginBottom: 4,
    cursor: 'pointer',
  },
  activityItem: {
    backgroundColor: '#fff',
    borderRadius: 3,
    paddingRight: 0,
    marginBottom: 4,
    padding: '4px 8px',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
});

NotificationsList = connect(
  state => ({
    entityLinks: state.root.classification.entityLinks,
    entityLinksType: state.root.classification.entityLinks.reduce((prev, cur) => ({
      ...prev, [cur.type]: {...cur},
    }), {}),
  }),
  {setFavicon}
)(withStyles(styles)(NotificationsList));

export default reduxForm({
  form: 'filter_notification',
  enableReinitialize: true,
})(withRouter(withWidth()(NotificationsList)));
