const defaultState = {
  districts: [],
};

export default (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'SET_DISTRICTS':
      return {
        ...state,
        districts: {
          ...state.districts,
          ...payload.districts,
        },
      };
    default:
      return state;
  }
};
