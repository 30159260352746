import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import * as colors from '@material-ui/core/colors';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

class EditableNote extends Component {
  state = {
    editing: false,
    saving: false,
  };

  componentDidMount() {
    if (!this.props.note) {
      this.setState({editing: true});
    }
  }

  handleClickCancel = () => {
    if (!this.props.note) {
      this.props.onCancel();
    }
    this.setState({editing: false});
  };

  handleClickSave = () => {
    if (this.props.onSave) {
      this.setState({saving: true});
      this.props.onSave(this.textareaRef.value, this.setState({editing: false}));
      this.setState({saving: false});   
    }
  };

  render() {
    const {note, classes} = this.props;
    const {editing, saving} = this.state;

    return (
      editing ? (
        <Paper
          className={classNames(classes.commentArea, classes.commentEdit)}
          elevation={0}
        >
          <Typography variant="subtitle2" style={{padding: '16px 16px 0 16px'}}>Заметка</Typography>
          <TextField
            defaultValue={note}
            multiline
            rowsMax={15}
            fullWidth
            disabled={saving}
            inputRef={el => {
              this.textareaRef = el;
            }}
            InputProps={{
              className: classes.textarea,
              classes: {
                multiline: classes.inputMultiline,
                input: classes.inputRoot,
              },
            }}
          />
          <div className={classes.icons}>
            <Button
              className={classes.button}
              onClick={this.handleClickCancel}
              disabled={saving}
            >
              Отменить
            </Button>
            <Button
              className={classes.button}
              onClick={() => this.handleClickSave()}
              disabled={saving}
            >
              Сохранить
            </Button>
          </div>
        </Paper>
      ) : (
        <Paper
          className={classNames(classes.commentArea, classes.commentText)}
          elevation={0}
          onClick={() => this.setState({editing: true})}
        >
          <Typography variant="subtitle2">Заметка</Typography>
          <Typography margin="dense" variant="body1" className={classes.commentTypography}>
            {note ? (
              note 
            ) : (
              <icons.ChatBubbleOutline className={classes.commentIcon}/>
            )}
            <icons.Edit className={classes.editIcon}/>
          </Typography>
        </Paper>
      )
    );
  }
}

const styles = theme => ({
  commentTypography: {
    whiteSpace: 'pre-line',
    overflow: 'hidden',
  },
  editIcon: {
    display: 'none',
    fontSize: 18,
    marginLeft: 8,
    color: colors.grey[600],
    verticalAlign: 'middle',
  },
  commentIcon: {
    display: 'inline-block',
    marginLeft: 8,
    fontSize: 18,
    color: colors.grey[600],
    verticalAlign: 'middle',
  },
  commentArea: {
    backgroundColor: colors.green[100],
    marginBottom: 8,
  },
  commentText: {
    padding: 16,
    cursor: 'pointer',
    position: 'relative',
    '&:hover $editIcon': {
      display: 'inline-block',
    },
    '&:hover $commentIcon': {
      display: 'none',
    },
  },
  commentEdit: {},
  textarea: {
    padding: '0 16px 8px',
    boxSizing: 'border-box',
  },
  inputRoot: theme.typography.body1,
  icons: {
    textAlign: 'right',
    padding: 8,
  },
});

export default withStyles(styles)(EditableNote);
