import React, {Component} from 'react';
import {connect} from 'react-redux';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';
import {
  openLightbox,
  loadPublicDeal,
  updatePublicDeal,
  removePublicDeal,
} from '../../actions';
import PublicDealWrapper from './PublicDealWrapper';
import Row from '../../components/Row';
import Column from '../../components/Column';
import CircularProgress from '@material-ui/core/CircularProgress';
import Member from './relations/Member';
import PublicDealEdit from './PublicDealEdit';

class PublicDealView extends Component {
  state = {
    openEdit: false,
  };
  componentDidMount() {
    const {
      match: {
        params: {
          publicDealId,
        },
      },
      loadPublicDeal,
    } = this.props;

    loadPublicDeal(publicDealId);
  }

  handleDialogClose = name => {
    this.setState({[name]: false});
  };

  handleDialogOpen = (value, name) => {
    this.setState({[name] : value});
  };

  render() {
    const {
      match : {params: {publicDealId}},
      publicDealList,
      classes,
      history,
      removePublicDeal,
      updatePublicDeal,
    } = this.props;
    const {openEdit} = this.state;
    if(!publicDealList)
      return null;
    if (publicDealList.isLoad) {
      return <CircularProgress size={50} thickness={4} className={classes.progress}/>;
    }
    const publicDeal = publicDealList[publicDealId];
    if (publicDealList.error || !publicDeal) {
      return <icons.ErrorOutline className={classes.progress}/>;
    }
    return (
      <React.Fragment>
        <Row key={0}>
          <Column label="Информация" icon={<icons.InfoOutlined/>} key="information">
            <PublicDealWrapper
              publicDeal={publicDeal}
              history={history}
              id={publicDealId}
              onEdit={() => this.setState({openEdit: true})}
              onRemove={() => {
                removePublicDeal(publicDealId, function () {
                  history.push('/publicDeals/');
                });
              }}
            />
          </Column>
          <Column label="Создатель" icon={<icons.Share/>} key="relationships">
            <Paper elevation={1} square className={`column ${classes.columnRight}`}>
              <div className="content">
                <Member publicDeal={publicDeal}/>
              </div>
            </Paper>
          </Column>
        </Row>
        {openEdit ? (
          <PublicDealEdit
            open={openEdit}
            publicDeal={publicDeal}
            onSubmitted={publicDeal => {
              this.setState({openEdit: false});
              updatePublicDeal(publicDeal);
            }}
            onClose={() => this.setState({openEdit: false})}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  itemText: {},
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },

  columnRight: {
    flex: 'unset',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1',
    },
  },
});

export default connect(
  state => ({
    publicDealList: state.root.publicDeals.publicDeals.publicDeals,
    publicDealStatus: state.root.publicDeals.publicDeals.status,
  }),
  {
    openLightbox,
    loadPublicDeal,
    updatePublicDeal,
    removePublicDeal,
  },
)(withStyles(styles)(PublicDealView));
