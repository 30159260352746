import React, {Component} from 'react';
import {connect} from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import * as colors from '@material-ui/core/colors/index';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import base64url from '../../utils/base64url';
import InputBase from '@material-ui/core/InputBase';
import Popover from '@material-ui/core/Popover';

import {downloadCsv} from '../../utils/csvFunc';
import OfferEdit from './OfferEdit';
import FilterOffers from './form/FilterOffers';
import {addOffer, loadOfferList, setOfferListScrollPosition} from '../../actions';
import ListSettings from '../../components/ListSettings';
import {setSection, getSection} from '../../utils/lastSection';

class OffersList extends Component {
  state = {
    actionAnchor: null,
    openAdd: false,
    openFilterDialog: false,
    searchInput: '',
    query: {},
    _perPage: 30,
    showExportSettingsList: false,
  };

  componentDidMount() {
    this.initialize(this.props.location);

    if (this.$content) {
      setTimeout(() => {
        this.$content.scrollTop = this.props.offerList.scrollPosition;
      }, 0);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.location !== this.props.location) {
      this.initialize(newProps.location);
    }
  }

  componentWillUnmount() {
    if (this.$content) {
      this.props.setOfferListScrollPosition(this.props.location.key, this.$content.scrollTop);
    }
  }

  initialize(location) {
    let filter = null;
    const {offerListSettings} = this.props;
    const query = location.search.substr(1);
    if (query.length > 0) {
      try {
        filter = JSON.parse(base64url.decode(query));
        if(!filter._perPage){
          filter._perPage = offerListSettings.page;
        }
        this.setState({searchInput: filter.searchQuery});
      } catch (_) {
      }
    } else {
      filter = {
        order: {
          column: null,
          direction: null,
        },
        _perPage: offerListSettings.page,
      };
    }
    if(!filter._source){
      let source = getSection('offersSource');
      if(source)
        filter._source = source;
    }
    this.setState({query: filter, _perPage: filter._perPage});
    this.props.loadOfferList(location.key, filter);
  }

  toggleExportSettingsList() {
    this.setState(state => {
      return {showExportSettingsList: !state.showExportSettingsList};
    });
  }

  render() {
    const {
      addOffer,
      classes,
      location,
      offerList,
      action,
      selectedItems,
      history,
      actionResetMobile,
      offerListColumns,
      offerListSettings,
      actionRight,
      actionReset,
    } = this.props;

    if (!offerList) {
      return null;
    }

    const {
      openAdd,
      openFilterDialog,
      searchInput,
      anchorEl,
      popoverContent,
      query,
      openSettings,
      _perPage,
      showExportSettingsList,
    } = this.state;

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        query: query,
        history: history,
        _perPage: _perPage,
        offerList: offerList,
        entityId: 'offerId',
        entityType: 'Offer',
        action: action ? action : null,
        actionReset: actionReset || null,
        actionResetMobile: actionResetMobile || null,
        actionRight: actionRight,
        selectedItems: selectedItems,
        handleRequestSort: e => this.handleRequestSort(e),
        handleOnChangePage: (e, page) => this.handleOnChangePage(e, page),
        showExportSettingsList,
      })
    );
    return (
      <React.Fragment>
        <div className="row" key={0}>
          <div className="column" style={{background: colors.grey[200], flex: 2}}>
            <Toolbar className="toolbar">
              <Select
                value={(offerList.filter ? offerList.filter._source : null) || 'assigned'}
                input={<Input/>}
                onChange={this.handleOnChangeSource}
              >
                <MenuItem value="assigned">Мои листинги</MenuItem>
                <MenuItem value="team">Листинги команды</MenuItem>
                <MenuItem value="all">Все листинги</MenuItem>
              </Select>
              <Hidden xsDown>
                {/*<IconButton*/}
                {/*  className={classes.exportSettingsListBtn}*/}
                {/*  disabled={offerList.loading}*/}
                {/*  color="primary"*/}
                {/*  title={"Управление выгрузкой"}*/}
                {/*  onClick={() => this.toggleExportSettingsList()}*/}
                {/*>*/}
                {/*  <icons.ImportExportRounded/>*/}
                {/*</IconButton>*/}
                {!showExportSettingsList ?
                  <IconButton
                    title={'Настройки списка'}
                    disabled={offerList.loading}
                    color="primary"
                    onClick={() => this.setState({openSettings: true})}
                  >
                    <icons.Settings/>
                  </IconButton>
                  : null}
              </Hidden>
              <div className={classes.flex}/>
              <Hidden xsDown>
                <InputBase
                  className={classes.input}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                  onKeyDown={this.handleSearchEnter}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  disabled={!searchInput}
                >
                  <icons.Search/>
                </IconButton>
              </Hidden>
              <IconButton
                disabled={offerList.loading}
                onClick={this.handleReload}
              >
                <icons.Refresh/>
              </IconButton>
              {offerList.filter ? (
                <IconButton
                  disabled={offerList.loading}
                  onClick={this.handleReset}
                >
                  <icons.Clear/>
                </IconButton>
              ) : null}
              <IconButton
                className={classes.rightIcon}
                disabled={offerList.loading}
                color={offerList.filter ? 'primary' : 'default'}
                onClick={() => this.setState({openFilterDialog: true})}
              >
                <icons.FilterList/>
              </IconButton>
            </Toolbar>
            <Hidden smUp>
              <Toolbar>
                <InputBase
                  classes={{root: classes.searchInputRoot, input: classes.searchInput}}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  className={classes.rightIcon}
                  disabled={!searchInput}
                >
                  <icons.Search/>
                </IconButton>
              </Toolbar>
            </Hidden>
            <Divider/>
            <div
              className="content"
              ref={$content => {
                this.$content = $content;
              }}
            >
              {offerList.error ? (
                <icons.ErrorOutline className={classes.progress}/>
              ) : null}
              {offerList.loading ? (
                <CircularProgress size={50} thickness={4} className={classes.progress}/>
              ) : null}
              {offerList.items ? (
                childrenWithProps
              ) : null}
            </div>
          </div>
        </div>
        {openFilterDialog ? (
          <FilterOffers
            open={openFilterDialog}
            initialValues={{
              createdAtFrom: null,
              createdAtTo: null,
              ...offerList.filter,
              _page: 0,
            }}
            onClose={() => this.setState({openFilterDialog: false})}
            onSubmit={this.handleFilter}
          />
        ) : null}
        {openAdd ? (
          <OfferEdit
            open={openAdd}
            onSubmitted={offer => {
              addOffer(location.key, {...offer, _new: true});
              this.setState({openAdd: false});
            }}
            onClose={() => this.setState({openAdd: false})}
          />
        ) : null}
        {openSettings ? (
          <ListSettings
            columnNames={offerListColumns}
            open={openSettings}
            entityList="offerList"
            initialValues={offerListSettings}
            onSubmitted={this.onSubmittedListSettings}
            onClose={() => this.setState({openSettings: false})}
          />
        ) : null}
        <Popover
          classes={{paper: classes.requirementsPopover}}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
        >
          <Typography>{popoverContent}</Typography>
        </Popover>
      </React.Fragment>
    );
  }

  onSubmittedListSettings = newSettings => {
    const {offerListSettings} = this.props;
    this.setState({openSettings: false});
    if(offerListSettings.page !== newSettings.page){
      const {match, offerList} = this.props;
      const path = match.url + '?' + base64url.encode(JSON.stringify({...offerList.filter, _page: 0, _perPage: newSettings.page}));
      this.props.history.push(path);
    }
  };
  handleReload = () => {
    const {loadOfferList, location, offerList} = this.props;

    loadOfferList(location.key, offerList.filter, true);
  };

  handleOnChangePage = (_, page) => {
    const {/*loadOfferList, location,*/match, offerList} = this.props;

    this.props.history.push(match.url + '?' + base64url.encode(JSON.stringify({
      ...offerList.filter,
      _page: page,
    })));
    //loadOfferList(location.key, {...offerList.filter, page}, true);
  };

  handleOnChangeSource = event => {
    const {match, offerList} = this.props;

    const qs = base64url.encode(JSON.stringify({...offerList.filter, _source: event.target.value, _page: 0}));
    this.props.history.push(match.url + '?' + qs);
    setSection('offersSource', event.target.value);
  };

  handleReset = () => {
    this.props.history.push(this.props.match.url);
    this.setState({searchInput: ''});
  };

  handleFilter = data => {
    this.props.history.push(this.props.match.url + '?' + base64url.encode(JSON.stringify({...data, _page: 0})));
    this.setState({openFilterDialog: false});
  };

  handleSearchChange = e => {
    this.setState({searchInput: e.target.value});
  };

  handleSearchEnter = e => {
    if(e.key === 'Enter') {
      this.handleSearchInit();
    }
  };

  handleSearchInit = () => {
    const {match, history, offerList} = this.props;

    const path = match.url + '?'
      + base64url.encode(JSON.stringify({...offerList.filter, searchQuery: this.state.searchInput}));
    history.push(path);
  };

  handleRequestSort = property => {
    const {order} = this.state.query;

    if (order && order.column === property && order.direction === 'ASC') {
      this.getSortParams(property, 'DESC');
    } else if (order && order.column === property && order.direction === 'DESC') {
      this.getSortParams();
    } else {
      this.getSortParams(property, 'ASC');
    }
  };

  getSortParams = (orderBy, order) => {
    const {offerList, match} = this.props;

    const qs = base64url.encode(JSON.stringify({...offerList.filter, order: {column: orderBy, direction: order}, _page: 0}));
    this.props.history.push(match.url + '?' + qs);
  };

  handlePopoverOpen = (e, text) => {
    e.stopPropagation();
    this.setState({anchorEl: e.currentTarget.parentNode, popoverContent: text});
  };

  handlePopoverClose = () => {
    this.setState({anchorEl: null, popoverContent: null});
  };

  onDownloadCsv = () => {
    const {offerList} = this.props;
    downloadCsv('offers', offerList.filter);
  };
}

const styles = theme => ({
  flex: {
    flex: 1,
  },
  table: {
    background: 'white',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
  rightIcon: {
    marginRight: -12,
  },
  card: {},
  action: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 8,
    },
    top: 0,
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
  searchInputRoot: {
    width: '100%',
  },
  searchInput: {
    width: '100%',
  },
  priceCell: {
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  sortableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  mobilePagination: {
    marginRight: 16,
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
  exportSettingsListBtn: {
    marginLeft: 24,
  },
});

export default connect(
  (state, ownProps) => ({
    offerList: state.root.offer.screens[ownProps.location.key],
    offers: state.root.offer.offers,
    selfUser: state.root.selfUser,
    offerListColumns: state.root.classification.offerListColumns,
    offerListSettings: state.root.settings.offerList,
  }),
  {
    addOffer,
    loadOfferList,
    setOfferListScrollPosition,
  },
)(withStyles(styles)(OffersList));
