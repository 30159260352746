import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import * as colors from '@material-ui/core/colors/index';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import MuiCheckbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Checkbox} from 'redux-form-material-ui';

import TextField from '../../../components/Input/TextField';
import TextFieldCount from '../../../components/Input/TextFieldWithCount';
import CityInput from '../../../components/Input/CityInput';
import Assignees from '../../../components/relations/Assignees';
import AccessComponent from '../../../components/AccessComponent';
import {getLabelsForValue} from '../../../utils';
import Photos from './Photos';
import {queryCities} from '../../../queries/CityQuery';
import {
  setCityHint,
} from '../../../actions';

class Variant extends Component {
  render() {
    const {label, selected, ...props} = this.props;

    return (
      <MenuItem selected={selected} {...props}>
        <MuiCheckbox checked={selected}/>
        <ListItemText primary={label}/>
      </MenuItem>
    );
  }
}


class Form extends Component {
  state = {
    pressReleaseDealTypes: null,
  };

  pressReleaseDealTypesRender = ()  => {
    this.setState({pressReleaseDealTypes: this.props.pressReleaseDealTypes.map(({value, label}, index) =>
      <MenuItem key={index} value={value}>{label}</MenuItem>
    )});
  };
  normalizeArea = value => {
    return value ? value.replace(',', '.') : value;
  };

  componentDidMount(){
    this.pressReleaseDealTypesRender();
  }

  render() {
    const {
      array: {push, remove, swap},
      photos,
      propertyTypes,
      pressReleaseBuyerServices,
      pressReleaseContractTypes,
      pressReleaseOwnerServices,
      pressReleaseFeatures,
      team,
      assignees,
      onAssigneeSelect,
      onAssigneeUnselect,
      pressRelease,
      change,
      teams,
      cityHint,
    } = this.props;

    const {pressReleaseDealTypes} = this.state;
    if(!pressReleaseDealTypes) return null;
    return (
      <React.Fragment>
        <Field
          name="name"
          label="Название"
          required
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          component={CityInput}
          name="city"
          label="Город"
          change={change}
          required
          placeholder="Введите название города"
          hint={teams[0].cities || cityHint}
          query={queryCities}
          initializeValue={pressRelease ? pressRelease.city : null}
        />
        <AccessComponent admin>
          <FormControl margin="none">
            <FormControlLabel
              control={<Field name="federal" component={Checkbox}/>}
              label="Федеральный уровень"
              required
            />
          </FormControl>
        </AccessComponent>
        {!pressRelease ?
          <div style={{marginTop: 16, marginBottom: 8}}>
            <Grid container spacing={24}>
              <Field
                component={Assignees}
                team={team}
                name={'assignees'}
                label="Консультант&nbsp;*"
                margin="normal"
                assignees={assignees}
                onSelect={onAssigneeSelect}
                onUnselect={onAssigneeUnselect}
              >
              </Field>
            </Grid>
          </div>
          : null}
        <Field
          name="dealType"
          label="Вид сделки"
          required
          component={TextField}
          select
          fullWidth
          margin="normal"
        >
          {pressReleaseDealTypes}
        </Field>
        <Field
          name="rentPeriod"
          label="Срок сдачи, неделя/месяц"
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="idlePeriod"
          label="Простой объекта, неделя/месяц"
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="propertyType"
          label="Тип объекта"
          required
          component={TextField}
          select
          fullWidth
          margin="normal"
        >
          {propertyTypes.map(({value, label}, index) =>
            <MenuItem key={index} value={value}>{label}</MenuItem>
          )}
        </Field>
        <Field
          name="propertyAddress"
          label="Адрес объекта"
          required
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="propertyArea"
          label="Площадь, кв.м."
          required
          normalize={this.normalizeArea}
          component={TextField}
          onChange={this.GetValidArea}
          fullWidth
          margin="normal"
        />
        <Field
          name="buyer"
          label="Арендатор/покупатель"
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="activityType"
          label="Вид деятельности/бизнеса клиента"
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="requirements"
          label="Требования клиента, с которыми обратился"
          multiline
          component={TextFieldCount}
          width={'100%'}
          max={1000}
          fullWidth
          margin="normal"
        />
        <Field
          name="buyerServices"
          label="Какие услуги оказаны арендатору/покупателю"
          required
          component={TextField}
          select
          SelectProps={{
            multiple: true,
            renderValue: value => getLabelsForValue(pressReleaseBuyerServices, value).join(', '),
          }}
          format={value => value || []}
          fullWidth
          margin="normal"
        >
          {pressReleaseBuyerServices.map(({label, value}, i) =>
            <Variant value={value} label={label} key={i}/>
          )}
        </Field>
        <Field
          name="contractType"
          label="Работа с собственником по договору"
          required
          component={TextField}
          select
          fullWidth
          margin="normal"
        >
          {pressReleaseContractTypes.map(({value, label}, index) =>
            <MenuItem key={index} value={value}>{label}</MenuItem>
          )}
        </Field>
        <Field
          name="ownerServices"
          label="Какие услуги оказаны собственнику"
          required
          component={TextField}
          select
          SelectProps={{
            multiple: true,
            renderValue: value => getLabelsForValue(pressReleaseOwnerServices, value).join(', '),
          }}
          format={value => value || []}
          fullWidth
          margin="normal"
        >
          {pressReleaseOwnerServices.map(({label, value}, i) =>
            <Variant value={value} label={label} key={i}/>
          )}
        </Field>
        <Field
          name="workInProgress"
          label="Продолжена ли работа с собственником по другим объектам"
          required
          component={TextField}
          select
          fullWidth
          margin="normal"
        >
          <MenuItem value="y">Да</MenuItem>
          <MenuItem value="n">Нет</MenuItem>
        </Field>
        <Field
          name="features"
          label="Особенности сделки"
          required
          component={TextField}
          select

          SelectProps={{
            multiple: true,
            renderValue: value => getLabelsForValue(pressReleaseFeatures, value).join(', '),
          }}
          format={value => value || []}
          fullWidth
          margin="normal"
        >
          {pressReleaseFeatures.map(({label, value}, i) =>
            <Variant value={value} label={label} key={i}/>
          )}
        </Field>
        <Field
          name="comment"
          label="Комментарий консультанта"
          multiline
          component={TextFieldCount}
          fullWidth
          max={1000}
          width={'100%'}
          margin="normal"
        />
        <Field
          name="description"
          label="Описание"
          required
          multiline
          max={2000}
          min={200}
          width={'100%'}
          component={TextFieldCount}
          fullWidth
          margin="normal"
        />
        <Photos
          name="photos"
          label="Фотография"
          photos={photos}
          push={push}
          remove={remove}
          swap={swap}
        />
      </React.Fragment>
    );
  }

  GetValidArea = e => {

    let value = e.target.value;
    if(value === '')
      return true;
    let lastChar = value[value.length-1];
    let indexD = value.indexOf('.');
    let indexZ = value.indexOf(',');
    if((lastChar === '.' && indexD === value.length - 1) || (lastChar === ',' && indexZ === value.length - 1 && (indexD === value.length - 1 || indexD === - 1)))
      return true;
    if(!isFinite(lastChar))
      e.preventDefault();
  };

}

const styles = {
  help: {
    color: colors.grey[700],
    marginLeft: 12,
    marginBottom: 8,
  },
  MenuItemContainer: {
    zIndex: 1,
    position: 'absolute',
    width:'100%',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  MenuItemWrapper: {
    position: 'relative',
  },
};

export default connect(state => ({
  propertyStatuses: state.root.classification.propertyStatuses,
  propertyTypes: state.root.classification.propertyTypes,
  pressReleaseDealTypes: state.root.classification.pressReleaseDealTypes,
  pressReleaseBuyerServices: state.root.classification.pressReleaseBuyerServices,
  pressReleaseContractTypes: state.root.classification.pressReleaseContractTypes,
  pressReleaseOwnerServices: state.root.classification.pressReleaseOwnerServices,
  pressReleaseFeatures: state.root.classification.pressReleaseFeatures,
  teams: state.root.selfUser.teams,
  cityHint: Object.values(state.root.hint.city),
}), {
  setCityHint,
})(withStyles(styles)(Form));
