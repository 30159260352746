import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import * as icons from '@material-ui/icons';
import  * as colors from '@material-ui/core/colors';

import Typography from '@material-ui/core/Typography/Typography';

const SnackbarError = ({errorMessage, classes, open, onClose, setPosition}) => {
  return (
    <Snackbar
      open={Boolean(open)}
      autoHideDuration={4000}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className={classes.errorWrapper}
      classes={{anchorOriginBottomLeft: setPosition ? classes.setPosition : classes.errorMessageMobile}}
    >
      <Typography className={classes.errorMessage}>
        <icons.Error className={classes.errorIcon}/>
        {errorMessage}
      </Typography>
    </Snackbar>
  );
};

const styles = {
  errorWrapper: {
    backgroundColor: colors.red[400],
    padding: 10,
    borderRadius: 5,
  },
  errorIcon: {
    color: '#FFF',
    marginBottom: -2,
    marginRight: 2,
    height: 16,
    width: 16,
  },
  errorMessage: {
    color: '#FFF',
  },
  errorMessageMobile: {
    right: 'auto',
    left: 24,
    bottom: 24,
  },
  setPosition: {
    position: 'static',
    width: 'fit-content',
    marginBottom: 5,
  },
};

export default withStyles(styles)(SnackbarError);
