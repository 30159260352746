import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';

import TableLink from '../../../components/TableLink';

class DataTable extends Component {

  render() {
    const {
      classes,
      statistics,
      total,
      title,
      handleClickOpen,
      getHref,
    } = this.props;

    return (
      <Paper className={classes.root}>
        <Hidden xsDown>
          <Table className={classes.table}>
            <TableHead>
              <TableRow classes={{root: classes.tableRow}}>
                <TableCell>{title}</TableCell>
                <TableCell>Количество</TableCell>
                <TableCell>Процент</TableCell>
                <TableCell>Комиссия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statistics.map((row, i) => (
                <TableLink
                  className={classes.tableRow} key={i}
                  onContextMenu={this.onContextMenuHandler}
                  href={getHref(row.data)}
                  onClick={() => handleClickOpen(row.data)}
                >
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.value ? row.value.toLocaleString('ru-RU') : 0}</TableCell>
                  <TableCell>{row.value ? (row.value * 100 / total.count).toFixed(2) : 0}%</TableCell>
                  <TableCell>{row.commission.toLocaleString('ru-RU')}</TableCell>
                </TableLink>
              ))}
              <TableRow>
                <TableCell>Всего:</TableCell>
                <TableCell>{total.count.toLocaleString('ru-RU')}</TableCell>
                <TableCell></TableCell>
                <TableCell>{total.commission.toLocaleString('ru-RU')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Hidden>
        <Hidden smUp>
          <Table className={classes.table}>
            <TableHead>
              <TableRow classes={{root: classes.tableRow}}>
                <TableCell>{title}</TableCell>
                <TableCell>Кол-во</TableCell>
                <TableCell>Комиссия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statistics.map((row, i) => (
                <TableRow
                  key={i}
                  classes={{root: classes.tableRow}}
                  hover
                  onClick={() => handleClickOpen(row.data)}
                >
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.value ? row.value.toLocaleString('ru-RU') : 0}</TableCell>
                  <TableCell>{row.commission.toLocaleString('ru-RU')}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>Всего:</TableCell>
                <TableCell>{total.count}</TableCell>
                <TableCell>{total.commission.toLocaleString('ru-RU')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Hidden>
      </Paper>
    );
  }
}

const styles = () => ({
  table: {
    background: 'white',
    marginBottom: 56 + 48,
  },
  root: {
    margin: '0 20px',
  },
  tableRow: {
    height: 37,
  },
  tableRowLink: {
    display:'table-row',
    color: 'inherit',
    height: 37,
    outline: 'none',
    verticalAlign: 'middle',
    textDecoration: 'none',
    cursor: 'default',
    '&:hover': {
      backgroundColor:'rgba(0,0,0,0.07)',
    },
  },
});

export default withStyles(styles)(DataTable);
