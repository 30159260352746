import React, {Component} from 'react';
import {Query, withApollo} from 'react-apollo';
import {connect} from 'react-redux';
import pathtoRegexp from 'path-to-regexp';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import {MenuItem} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Sidebar from './components/Sidebar';
import {queryArchivedOffersStatistics} from '../../queries/AnalyticsQuery';
import base64url from '../../utils/base64url';
import AccessComponent from '../../components/AccessComponent';
import {getSection} from '../../utils/lastSection';
import ArchivedOffersTable from './components/ArchivedOffersTable';

class AnalyticsArchivedOffers extends Component {
  state = {
    filter: {},
    dynamicsFilter: {},
    query: {},
    openSidebar: false,
    startOfMonth: null,
    endOfMonth: null,
    monthDays: [],
    members: [],
    currentTeam: null,
    currentMember: null,
    entitySource: getSection('analyticsEntitySource') || 'deal',
    chipAppeals: false,
    typeAppeals: 'default',
  };

  matchPath = pathtoRegexp.compile(this.props.match.path);

  componentDidMount() {
    this.search(this.props.location);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.search(this.props.location);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {currentTeam, query} = state;

    return {
      filter: {
        dateFrom: query.dateFrom ? new Date(query.dateFrom) : null,
        dateTo: query.dateTo ? new Date(query.dateTo) : null,
        team: currentTeam && currentTeam !== 'all' ? currentTeam : null,
      },
    };
  }

  search(location) {
    let query = {};
    const qs = location.search.substr(1);
    if (qs.length > 0) {
      try {
        query = JSON.parse(base64url.decode(qs));
      } catch (_) {
      }
    } else {
      query = {
        dateFrom: null,
        dateTo: null,
      };
    }

    let team = null;
    if (typeof this.props.openTeam !== 'undefined') {
      team = this.props.openTeam;
    }

    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        team: team,
      },
      currentTeam: team,
      query,
    }));
  }

  render() {
    const {
      match,
      classes,
      history,
      selfUser,
      onChangeTeam,
      onChangeSection,
      entitySourceMenu,
    } = this.props;

    const {
      filter,
      openSidebar,
      currentTeam,
      query,
      entitySource,
    } = this.state;

    const {dateFrom, dateTo} = query;

    return (
      <div className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.addButton}
            onClick={() => this.setState({openSidebar: !openSidebar})}
          >
            {openSidebar ? <icons.Close/> : <icons.FilterList/>}
          </IconButton>
          <Select
            className={classes.adminSelect}
            value={entitySource}
            input={<Input/>}
            onChange={event => onChangeSection(event.target.value)}
          >
            {entitySourceMenu.map((item, index) => (
              <MenuItem key={index} value={item[0]}>{item[1]}</MenuItem>
            ))}
          </Select>
          <AccessComponent admin>
            <>
              <Select
                className={classes.adminSelect}
                value={currentTeam ? currentTeam : match.params.source}
                input={<Input/>}
                onChange={event => {
                  onChangeTeam(event.target.value);
                  this.setState(prevState => ({
                    filter: {
                      ...prevState.filter,
                      team: event.target.value,
                    },
                    currentTeam: event.target.value,
                  }));
                }}
              >
                {selfUser.teams.map((team, index) => (
                  <MenuItem key={index} value={team.id}>{team.name}</MenuItem>
                ))}
              </Select>
            </>
          </AccessComponent>
        </Toolbar>
        <Divider/>
        <div className={classes.wrapper}>
          <Sidebar
            filter={filter}
            open={openSidebar}
            entitySource={entitySource}
            initialValues={{
              dateFrom: dateFrom ? dateFrom : null,
              dateTo: dateTo ? dateTo : null,
            }}
            onSubmit={this.handleFilter}
          />
          <div className={classes.dashboardWrap}>
            <React.Fragment>
              <Query
                query={queryArchivedOffersStatistics}
                variables={{filter}}
                fetchPolicy="network-only"
              >
                {({data, loading, error}) => {
                  if (loading) {
                    return (
                      <div className={classes.status}>
                        <CircularProgress size={24} thickness={5}/>
                      </div>
                    );
                  }
                  if (error) {
                    return (
                      <div className={classes.status}>
                        <icons.Warning color="primary"/>
                      </div>
                    );
                  }
                  return (
                    <ArchivedOffersTable
                      statistics={data.archivedOffersStatistics}
                      title="Статистика по закрытым листингам"
                      titleClass={classes.statisticsTitle}
                      history={history}
                      match={match}
                      getHref={memberId => this.getOpenUrl(memberId)}
                      handleClickOpen={memberId => this.handleClickOpen(memberId)}
                    />
                  );
                }}
              </Query>
            </React.Fragment>
          </div>
        </div>
      </div>
    );
  }

  handleFilter = dates => {
    if (dates) {
      const from = dates.dateFrom,
        to = dates.dateTo;

      const newQuery = {
        ...this.state.query,
        dateFrom: from,
        dateTo: to,
      };
      const path = this.props.match.url + '?'
        + base64url.encode(JSON.stringify(newQuery));
      this.props.history.push(path);
    }
  };

  getOpenUrl = memberId => {
    const {selfUser} = this.props;
    const {query} = this.state;
    let filter = {};

    if (selfUser.role === 'ROLE_ADMIN') {
      filter = {
        '_source': 'all',
        statuses: ['archived'],
        createdAtFrom: query.dateFrom ? query.dateFrom : undefined,
        createdAtTo: query.dateTo ? query.dateTo : undefined,
        assignees: [memberId],
      };
    } else {
      filter = {
        '_source': 'team',
        statuses: ['archived'],
        createdAtFrom: query.dateFrom ? query.dateFrom : undefined,
        createdAtTo: query.dateTo ? query.dateTo : undefined,
        assignees: [memberId],
      };
    }

    return `/offers?${base64url.encode(JSON.stringify(filter))}`;
  };

  handleClickOpen = memberId => {
    const {history} = this.props;
    history.push(this.getOpenUrl(memberId));
  };
}

const styles = theme => ({
  flex: {
    flex: 1,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    flex: 1,
  },
  addButton: {
    marginLeft: -12,
    marginRight: 12,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  root: {
    flex: 1,
    backgroundColor: colors.grey[200],
    zIndex: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  dashboardWrap: {
    flex: 1,
    overflowY: 'auto',
    padding: '20px 0',
  },
  monthPickerWrap: {
    display: 'flex',
    justifyContent: 'end',
    padding: '0 20px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'start',
    },
  },
  status: {
    margin: 8,
    padding: 6,
    display: 'flex',
    justifyContent: 'center',
  },
  adminSelect: {
    marginRight: 12,
    [theme.breakpoints.down(500)]: {
      width: '100%',
    },
  },
  toolbar: {
    [theme.breakpoints.down(500)]: {
      flexWrap: 'wrap',
      paddingBottom: 10,
    },
  },
  statisticsTitle: {
    textAlign: 'center',
    marginBottom: 10,
  },
  statisticChip: {
    margin: '0 20px',
  },
  searchInputWrapper: {
    backgroundColor: colors.grey[300],
    padding: '5px 10px',
    borderRadius: 5,
    width: 300,
    maxWidth: '100%',
  },
});

AnalyticsArchivedOffers = connect(
  state => ({
    selfUser: state.root.selfUser,
  })
)(AnalyticsArchivedOffers);

export default withApollo(withStyles(styles)(AnalyticsArchivedOffers));
