import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import {set} from 'lodash';
import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';

import TextField from '../../../components/Input/TextField';

class CloseDealForm extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      open,
      pristine,
      status,
      dealClosesReason,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Закрытие заявки</DialogTitle>
        <Divider/>
        <DialogContent>
          {status === 'closed' ?
            <Field
              name="closeReason"
              label="Причина"
              required
              multiline
              select
              margin="normal"
              component={TextField}
              fullWidth
            >
              {Object.keys(dealClosesReason).map((key, index) =>
                (
                  <MenuItem value={key} key={index}>{dealClosesReason[key]}</MenuItem>
                )
              )}
            </Field>
            : null}
          <Field
            name="closeComment"
            label="Комментарий"
            required
            component={TextField}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(this.submit)}
          >
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = data => {
    const {status, id} = this.props;
    data.id = id;
    data.status = status;
    return axios.post('/api/v1/setDealStatus', data)
      .then(res => {
        if (res.status === 200) {
          this.props.onSubmit(data);
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(error.response.data.error.errors.reduce((errors, {propertyPath, message}) => {
            return set(errors, propertyPath, message);
          }, {}));
        } else {
          alert('Ошибка при сохранении');
        }
      });
  };
}

export default reduxForm({
  form: 'close_form',
  enableReinitialize: true,
})(connect(
  state => ({
    dealClosesReason: state.root.classification.dealClosesReason,
  })
)(CloseDealForm));
