import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import * as icons from '@material-ui/icons';

import AddDocument from '../form/AddDocument';
import RemoveDocument from '../form/RemoveDocument';
import {addDocumentToProperty, removeDocumentFromProperty} from '../../../actions';
import DocumentListItem from '../../../components/relations/DocumentListItem';

class Documents extends Component {
  state = {
    addDocument: null,
    openMenu: false,
    removeDocument: false,
  };

  render() {
    const {
      addDocumentToProperty,
      removeDocumentFromProperty,
      property,
    } = this.props;
    const {addDocument, openMenu, removeDocument} = this.state;

    if (!property) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Документы
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => {
                this.setState({
                  addDocument: {
                    propertyId: property.id,
                  },
                });
              }}
            >
              <icons.Add/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {property.documents ? property.documents.map((document, index) => (
          <DocumentListItem
            key={index}
            document={document}
            onSecondaryActionClick={e => this.setState({openMenu: {target: e.currentTarget, path: document.path}})}
          />
        )) : null}
        {openMenu ? (
          <Menu
            anchorEl={openMenu.target}
            open={Boolean(openMenu)}
            onClick={() => this.setState({openMenu: false})}
          >
            <MenuItem onClick={() => {
              this.setState({
                removeDocument: {
                  propertyId: property.id,
                  documentPath: openMenu.path,
                },
              });
            }}>
              Удалить документ
            </MenuItem>
          </Menu>
        ) : null}
        {removeDocument ? (
          <RemoveDocument
            open={Boolean(removeDocument)}
            initialValues={removeDocument}
            onSubmitted={() => {
              removeDocumentFromProperty(removeDocument.propertyId, removeDocument.documentPath);
              this.setState({removeDocument: null});
            }}
            onClose={() => this.setState({removeDocument: null})}
          />
        ) : null}
        {addDocument ? (
          <AddDocument
            open={Boolean(addDocument)}
            initialValues={addDocument}
            onSubmitted={document => {
              addDocumentToProperty(addDocument.propertyId, document);
              this.setState({addDocument: null});
            }}
            onClose={() => {
              this.setState({addDocument: null});
            }}
          />
        ) : null}
      </List>
    );
  }
}

Documents = connect(
  null,
  {
    addDocumentToProperty,
    removeDocumentFromProperty,
  },
)(Documents);

export default withRouter(Documents);
