import {isEmpty} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import * as colors from '@material-ui/core/colors/index';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import InputBase from '@material-ui/core/InputBase';
import NetRenterEdit from './NetRenterEdit';
import FilterList from './form/FilterList';
import base64url from '../../utils/base64url';

class NetRenterList extends Component {
  state = {
    openAdd: false,
    openFilterDialog: false,
    searchInput: '',
  };

  componentDidMount() {
    let query = this.props.location.search.substr(1);
    if (query) {
      const data = JSON.parse(base64url.decode(query));
      if (data.filter)
        this.setState({searchInput: data.filter.searchQuery});
    }
  }

  handleFilter = filter => {
    this.setState({openFilterDialog: false});
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({filter, offset: 0, limit: this.props.perPage}));
    this.props.history.push(path);
    this.setState({searchInput: filter.searchQuery});
  };

  handleReset = () => {
    this.props.history.push(this.props.match.url);
    this.setState({searchInput: ''});
  };

  handleChangePage = (_, page) => {
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...this.props.query, offset: page * this.props.perPage, limit: this.props.perPage}));
    this.props.history.push(path);
  };

  handlePopoverOpen = (e, text) => {
    e.stopPropagation();
    this.setState({anchorEl: e.currentTarget.parentNode, popoverContent: text});
  };

  handlePopoverClose = () => {
    this.setState({anchorEl: null, popoverContent: null});
  };

  handleSearchChange = e => {
    this.setState({searchInput: e.target.value});
  };

  handleSearchEnter = e => {
    if(e.key === 'Enter') {
      this.handleSearchInit();
    }
  };

  handleSearchInit = () => {
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...this.props.query, filter: {...this.props.query.filter, searchQuery: this.state.searchInput}, offset: 0, limit: this.props.perPage}));
    this.props.history.push(path);
  };

  render() {
    const {
      classes,
      history,
      query,
      deals,
      action,
      selectedItems,
      totalCount,
      error,
      loading,
      perPage,
    } = this.props;
    const {
      openAdd,
      openFilterDialog,
      searchInput,
    } = this.state;

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        query: query,
        history: history,
        deals: deals,
        entityId: 'dealId',
        entityType: 'Deal',
        action: action ? action : null,
        selectedItems: selectedItems,
        totalCount: totalCount,
        perPage: perPage,
        handleChangePage: (e, page) => this.handleChangePage(e, page),
      })
    );

    return (
      <React.Fragment>
        <div key={0} className="row">
          <div className="column" style={{background: colors.grey[200], flex: 2}}>
            <Toolbar className="toolbar">
              <div className={classes.flex}/>
              <Hidden xsDown>
                <InputBase
                  className={classes.input}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                  onKeyDown={this.handleSearchEnter}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  disabled={!searchInput}
                >
                  <icons.Search/>
                </IconButton>
              </Hidden>
              {!isEmpty(query.filter) ? (
                <IconButton
                  disabled={loading}
                  onClick={this.handleReset}
                >
                  <icons.Clear/>
                </IconButton>
              ) : null}
              <IconButton
                className={classes.rightIcon}
                disabled={loading}
                color={!isEmpty(query.filter) ? 'primary' : 'default'}
                onClick={() => this.setState({openFilterDialog: true})}
              >
                <icons.FilterList/>
              </IconButton>
            </Toolbar>
            <Hidden smUp>
              <Toolbar>
                <InputBase
                  classes={{root: classes.searchInputRoot, input: classes.searchInput}}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  className={classes.rightIcon}
                  disabled={!searchInput}
                >
                  <icons.Search/>
                </IconButton>
              </Toolbar>
            </Hidden>
            <Divider/>
            <div
              className="content"
              ref={$content => {
                this.$content = $content;
              }}
            >
              {loading ?
                <CircularProgress size={50} thickness={4} className={classes.progress}/>
                :
                error ?
                  <icons.ErrorOutline className={classes.progress}/>
                  :
                  childrenWithProps}
            </div>
            <Fab
              color="secondary"
              className={classes.fab}
              onClick={() => this.setState({openAdd: true})}
            >
              <icons.Add/>
            </Fab>
          </div>
        </div>
        {openFilterDialog ? (
          <FilterList
            open={openFilterDialog}
            initialValues={{
              ...query.filter,
            }}
            onClose={() => this.setState({openFilterDialog: false})}
            onSubmit={this.handleFilter}
          />
        ) : null}
        {openAdd ? (
          <NetRenterEdit
            open={openAdd}
            onSubmitted={({id}) => history.push(`/netRenters/${id}`)}
            onClose={() => this.setState({openAdd: false})}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  flex: {
    flex: 1,
  },
  rightIcon: {
    marginRight: -12,
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  searchInputRoot: {
    width: '100%',
  },
  searchInput: {
    width: '100%',
  },
});

export default connect(
  state => ({
    selfUser: state.root.selfUser,
  }),
)(withStyles(styles)(NetRenterList));
