import React, {Component} from 'react';
import {isEmpty} from 'lodash';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import InputBase from '@material-ui/core/InputBase';
import {connect} from 'react-redux';
import Popover from '@material-ui/core/Popover';
import pathtoRegexp from 'path-to-regexp';

import FilterDealOffers from './form/FilterDealOffers';
import AccessComponent from '../../components/AccessComponent';
import base64url from '../../utils/base64url';
import {setDealOfferListScrollPosition} from '../../actions';
import {setSection} from '../../utils/lastSection';

class DealOfferList extends Component {
  state = {
    openFilterDialog: false,
    anchorEl: null,
    popoverContent: null,
    searchInput: '',
  };

  matchPath = pathtoRegexp.compile(this.props.match.path);

  componentDidMount() {
    if (this.$content) {
      this.setScrollPosition();
    }
  }

  setScrollPosition = () => {
    setTimeout(() => {
      const {dealOffers, error} = this.props;
      if(dealOffers.length > 0)
        this.$content.scrollTop = this.props.scrollPosition;
      else if(!error)
        this.setScrollPosition();
    }, 0);
  };

  componentWillReceiveProps(newProps) {
    if (newProps.searchInput !== this.props.searchInput) {
      this.setState({searchInput: newProps.searchInput});
    }
  }

  componentWillUnmount() {
    if (this.$content) {
      this.props.setDealOfferListScrollPosition(this.props.location.key, this.$content.scrollTop);
    }
  }

  render() {
    const {
      classes,
      history,
      selfUser,
      action,
      loading,
      error,
      dealOffers,
      totalCount,
      query,
      handleChangeStatus,
      handleRequestSort,
      members,
      currentTeam,
      handleFilter,
      handleMemberDropdown,
      handleTeamDropdown,
    } = this.props;

    const {
      popoverContent,
      anchorEl,
      openFilterDialog,
      searchInput,
    } = this.state;

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        query: query,
        history: history,
        dealOffers: dealOffers,
        entityId: 'dealOfferId',
        entityType: 'DealOffer',
        action: action ? action : null,
        totalCount: totalCount,
        handleRequestSort: handleRequestSort,
        handleChangePage: (e, page) => this.handleChangePage(e, page),
        handleChangeStatus: handleChangeStatus,
      })
    );

    return (
      <React.Fragment>
        <div key={0} className="row">
          <div className="column" style={{background: colors.grey[200], flex: 2}}>
            <Toolbar className="toolbar">
              <AccessComponent managerOnly>
                <Select
                  value={(query.assignees && query.assignees[0]) || query.source || 'ASSIGNED'}
                  input={<Input/>}
                  onChange={this.handleChangeSource}
                >
                  <MenuItem value="ASSIGNED">Мои сделки</MenuItem>
                  <MenuItem value="TEAM">Сделки команды</MenuItem>
                  <Divider/>
                  {selfUser.teamMembers.map((member, index) => (
                    <MenuItem key={index} value={member.id}>{member.name}</MenuItem>
                  ))}
                </Select>
              </AccessComponent>
              <AccessComponent admin>
                <div className={classes.adminSelectGroup}>
                  <Select
                    className={classes.adminSelect}
                    value={currentTeam || (query.source && query.source.toLowerCase())}
                    input={<Input/>}
                    onChange={event => {
                      setSection('dealOfferTeam', event.target.value);
                      localStorage.removeItem('dealOfferAssignees');
                      handleTeamDropdown(event.target.value);
                    }}
                  >
                    <MenuItem value="all">Все сделки</MenuItem>
                    {selfUser.teams.map((team, index) => (
                      <MenuItem key={index} value={team.id}>{team.name}</MenuItem>
                    ))}
                  </Select>
                  {members.length ? (
                    <Select
                      value={(query.assignees && query.assignees[0]) || currentTeam}
                      input={<Input/>}
                      onChange={event => {
                        setSection('dealOfferAssignees', event.target.value);
                        handleMemberDropdown(event.target.value);
                      }}
                    >
                      <MenuItem value={currentTeam}>Сделки команды</MenuItem>
                      {members.map((member, index) => (
                        <MenuItem key={index} value={member.id}>{member.name}</MenuItem>
                      ))}
                    </Select>
                  ) : null}
                </div>
              </AccessComponent>
              <div className={classes.flex}/>
              <Hidden xsDown>
                <InputBase
                  className={classes.input}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                  onKeyDown={this.handleSearchEnter}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  disabled={!searchInput}
                >
                  <icons.Search/>
                </IconButton>
              </Hidden>
              {!isEmpty(query.filter) ? (
                <IconButton
                  disabled={loading}
                  onClick={this.handleReset}
                >
                  <icons.Clear/>
                </IconButton>
              ) : null}
              <IconButton
                className={classes.rightIcon}
                disabled={loading}
                color={!isEmpty(query.filter) ? 'primary' : 'default'}
                onClick={() => this.setState({openFilterDialog: true})}
              >
                <icons.FilterList/>
              </IconButton>
            </Toolbar>
            <Hidden smUp>
              <Toolbar>
                <InputBase
                  classes={{root: classes.searchInputRoot, input: classes.searchInput}}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  className={classes.rightIcon}
                  disabled={!searchInput}
                >
                  <icons.Search/>
                </IconButton>
              </Toolbar>
            </Hidden>
            <Divider/>
            <div
              className="content"
              ref={$content => {
                this.$content = $content;
              }}
            >
              {loading ? (
                <CircularProgress size={50} thickness={4} className={classes.progress}/>
              ) : (
                error ? (
                  <icons.ErrorOutline className={classes.progress}/>
                ) : (
                  childrenWithProps
                )
              )}
            </div>
          </div>
        </div>
        <Popover
          classes={{paper: classes.requirementsPopover}}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
        >
          <Typography>{popoverContent}</Typography>
        </Popover>

        {openFilterDialog ? (
          <FilterDealOffers
            open={openFilterDialog}
            initialValues={{
              dealStartFrom: null,
              dealStartTo: null,
              dealEndFrom: null,
              dealEndTo: null,
              ...query.filter,
            }}
            onClose={() => this.setState({openFilterDialog: false})}
            onSubmit={filter => {
              handleFilter(filter);
              this.setState({openFilterDialog: false});
            }}
          />
        ) : null}
      </React.Fragment>
    );
  }

  handleChangePage = (_, page) => {
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...this.props.query, offset: page * 30, limit: 30}));
    this.props.history.push(path);
  };

  handleChangeSource = e => {
    let source = e.target.value;
    let assignees = [];
    setSection('dealOfferManagerSource', e.target.value);
    if (e.target.value.length > 10) {
      source = 'TEAM';
      assignees = [e.target.value];
    }
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...this.props.query, source, assignees, offset: 0}));
    this.props.history.push(path);
  };

  handleSearchChange = e => {
    this.setState({searchInput: e.target.value});
  };

  handleSearchEnter = e => {
    if(e.key === 'Enter') {
      this.handleSearchInit();
    }
  };

  handleSearchInit = () => {
    const path = this.props.match.url + '?'
      + base64url.encode(JSON.stringify({...this.props.query, filter: {...this.props.query.filter, searchQuery: this.state.searchInput}, offset: 0}));
    this.props.history.push(path);
  };

  handlePopoverOpen = (e, text) => {
    e.stopPropagation();
    this.setState({anchorEl: e.currentTarget.parentNode, popoverContent: text});
  };

  handlePopoverClose = () => {
    this.setState({anchorEl: null, popoverContent: null});
  };

  handleReset = () => {
    if(this.props.query.source) {
      const path = this.props.match.url + '?'
        + base64url.encode(JSON.stringify({...this.props.query, filter: {}}));
      this.props.history.push(path);
    } else {
      this.props.history.push(this.props.match.url);
    }
    this.setState({searchInput: ''});
  };
}

const styles = theme => ({
  adminSelect: {
    marginRight: 12,
  },
  flex: {
    flex: 1,
  },
  table: {
    background: 'white',
    marginBottom: 56 + 48,
  },
  requirementsCell: {
    whiteSpace: 'normal',
    minWidth: 200,
  },
  requirementsWrapper: {
    overflow: 'hidden',
    maxHeight: 48,
    lineClamp: 3,
    fontSize: '0.75rem',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
  rightIcon: {
    marginRight: -12,
  },
  status: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 6,
    height: '100%',
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
  malformedIcon: {
    color: colors.orange[400],
    width: 16,
    height: 16,
    marginRight: 8,
    float: 'left',
    verticalAlign: 'middle',
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  searchInputRoot: {
    width: '100%',
  },
  searchInput: {
    width: '100%',
  },
  sortableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  editIcon: {
    fontSize: 18,
    marginLeft: 8,
    color: colors.grey[600],
    verticalAlign: 'middle',
    cursor: 'pointer',
    '&:hover': {
      color: '#000',
    },
  },
  statusWrapper: {
    maxWidth: 'calc(100% - 18px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  adminSelectGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 'calc(100% - 48px)',
    padding: '10px 0',
  },
});

export default connect(
  state => ({
    selfUser: state.root.selfUser,
    scrollPosition: state.root.dealOffer.scrollPosition,
  }),
  {
    setDealOfferListScrollPosition,
  }
)(withStyles(styles)(DealOfferList));
