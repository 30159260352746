import axios from 'axios';
import {set, without} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {SubmissionError, reduxForm, formValueSelector} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';

import ContactForm from './form/ContactForm';

class ContactEdit extends Component {
  state = {
    assignees: [],
  };

  componentDidMount() {
    if (this.props.open && !this.props.initialized) {
      if (this.props.contact) {
        const contact = this.props.contact;
        this.setState({assignees: contact.assignees});
        this.props.initialize({
          contact: {
            ...contact,
            assignees: contact.assignees.map(assignee => assignee.id),
          },
        });
      } else {
        this.props.initialize({
          contact: {
            attributes: [],
            persons: [],
            status: 'opened',
            open: false,
            showTeam: false,
            blacklist: false,
            assignees: [],
            selfAssignee: true,
          },
        });
      }
    }
  }

  render() {
    const {
      array,
      attributes,
      change,
      classes,
      contactType,
      blacklist,
      showTeam,
      handleSubmit,
      initialized,
      open,
      pristine,
      persons,
      subject,
      submitting,
      onClose,
      contact,
      teams,
    } = this.props;

    if (!open || !initialized) {
      return null;
    }

    const {assignees} = this.state;

    return (
      <Dialog fullScreen open={open} classes={{paper: classes.dialog}}>
        <DialogTitle>
          Контакт
          <IconButton className={classes.closeButton} onClick={() => onClose()}>
            <icons.Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <ContactForm
            contact={contact}
            array={array}
            attributes={attributes}
            change={change}
            contactType={contactType}
            blacklist={blacklist}
            showTeam={showTeam}
            persons={persons}
            subject={subject}
            team={teams[0]}
            assignees={assignees}
            onAssigneeSelect={assignee => {
              array.push('contact.assignees', assignee.id);
              this.setState({assignees: [...this.state.assignees, assignee]});
            }}
            onAssigneeUnselect={index => {
              array.remove('contact.assignees', index);
              this.setState({
                assignees: without(
                  this.state.assignees,
                  this.state.assignees[index]
                ),
              });
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button type="button" disabled={submitting} onClick={() => onClose()}>
            Отменить
          </Button>
          <Button
            type="button"
            disabled={pristine || submitting}
            onClick={handleSubmit(this.submit)}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = values => {
    const {contact, onSubmitted, selfUser} = this.props;

    let req;
    if (contact) {
      req = axios.put(`/api/contacts/${contact.id}`, {
        id: contact.id,
        contact: values.contact,
      });
    } else {
      req = axios.post('/api/contacts', {contact: values.contact});
    }

    return req
      .then(resp =>
        onSubmitted({
          ...values.contact,
          id: resp.data.id,
          website:resp.data.website,
          assignees: contact
            ? [...contact.assignees]
            : [{name: selfUser.name, id: selfUser.id}],
        })

      )
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(
            error.response.data.error.errors.reduce(
              (errors, {propertyPath, message}) => {
                return set(errors, propertyPath, message);
              },
              {}
            )
          );
        } else if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

const styles = () => ({
  dialog: {
    maxWidth: 960,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

ContactEdit = withStyles(styles)(ContactEdit);

const selector = formValueSelector('contact');
ContactEdit = connect(state => ({
  contactType: selector(state, 'contact.type'),
  attributes: selector(state, 'contact.attributes'),
  subject: selector(state, 'contact.subject'),
  persons: selector(state, 'contact.persons'),
  blacklist: selector(state, 'contact.blacklist'),
  showTeam: selector(state, 'contact.showTeam'),
  selfUser: state.root.selfUser,
  teams: state.root.selfUser.teams,
}))(ContactEdit);

export default reduxForm({
  form: 'contact',
})(ContactEdit);
