import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {colors} from '@material-ui/core';

class PageNumber extends Component {
  onNumberClick = event => {
    if (this.props.active) {
      return;
    }
    this.props.onChangePage(event, this.props.number);
  };

  render() {
    const {number, active, classes} = this.props;

    return (
      <div onClick={this.onNumberClick} className={classes.button} style={{fontWeight: active ? '500' : '400', background: active ? colors.grey[200] : null, cursor: active ? 'default' : 'pointer'}}>{number+1}</div>
    );
  } 
}

const buttonStyles = () => ({
  button: {
    display: 'inline-block',
    padding: '8px 5px',
    borderRadius: 3,
    margin: '0 2px',
    '&:hover': {
      background: colors.grey[200],
    },
  },
});

const PageNumberWrapped = withStyles(buttonStyles)(PageNumber);

class TablePaginationActions extends Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  buttonsFunc = () => {
    const {page} = this.props;

    const totalCount = Math.floor(this.props.count / this.props.rowsPerPage);

    let pageButtons = [];

    if (totalCount <= 1) {
      return pageButtons;
    }
  
    for(let i = page-3; i <= page+3; i++) {
      let button = i === page ? 
        <PageNumberWrapped key={i} onChangePage={this.props.onChangePage} number={page} active/> 
        : (i >= 0 && i <= totalCount ? 
          <PageNumberWrapped key={i} onChangePage={this.props.onChangePage} number={i}/> 
          : null);
      pageButtons.push(button);
    }

    return pageButtons;
  };

  render() {
    const {classes, count, page, rowsPerPage, theme} = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <div style={{display: 'inline-block'}}>
          {this.buttonsFunc()}
        </div>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

export default withStyles(styles, {withTheme: true})(TablePaginationActions);
