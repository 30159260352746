import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {mapLabels} from '../../../utils';
import * as icons from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import axios from 'axios';

import {queryContractContact} from '../../../queries/ContractsQuery';
import ContactListItem from '../../../components/relations/ContactListItem';
import {updateContact} from '../../../actions';

class Contact extends Component {
  state = {
    contactId: null,
    error: null,
    loading: false,
    anchorEl: null,
  };

  componentDidMount() {
    this.reload();
  }

  handleOpenMenu = target => {
    this.setState({anchorEl: target});
  };

  handleCloseMenu = () => {
    this.setState({anchorEl: null});
  };

  reload() {
    const {contract, contacts, updateContact} = this.props;
    this.setState({loading: true});
    queryContractContact(contract.id).then(res => {
      const contactId = res.data.data.contract.contact.id;
      this.setState({contactId});
      if (!contacts[contactId]) {
        axios
          .get(`/api/contacts/${contactId}`)
          .then(resp => updateContact(resp.data))
          .catch(() => {
            this.setState({error: true});
          })
          .then(() => {
            this.setState({
              loading: false,
            });
          });
      }
    });
  }

  render() {
    const {error, anchorEl, contactId} = this.state;
    const {contract, contacts, history, handleDialogOpen} = this.props;

    const contact = contacts[contactId];

    return (
      <List
        subheader={
          <ListSubheader disableSticky style={{position: 'relative'}}>
            Контакт
            <ListItemSecondaryAction>
              <IconButton onClick={e => this.handleOpenMenu(e.currentTarget)}>
                <icons.MoreVert />
              </IconButton>
            </ListItemSecondaryAction>
          </ListSubheader>
        }
      >
        {!error ? (
          contact ? (
            <ContactListItem
              contact={contact}
              handleDialogOpen={e => handleDialogOpen(e)}
            />
          ) : null
        ) : null}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseMenu}
        >
          <MenuItem
            onClick={() => history.push(`/contracts/${contract.id}/contact`)}
          >
            Сменить контакт
          </MenuItem>
        </Menu>
      </List>
    );
  }
}

Contact = connect(
  state => ({
    contactTypeLabels: mapLabels(
      state.root.classification.contactTypes,
      'value',
      'label'
    ),
    contacts: state.root.contact.contacts,
  }),
  {updateContact}
)(Contact);

export default withRouter(Contact);
