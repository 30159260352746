import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import {find} from 'lodash';

const EntityListStar = props => {
  const {
    favList,
    item,
    entityId,
    classes,
    onRemoveFavoriteClick,
    onAddFavoriteClick,
  } = props;

  return (
    <div className={classes.star}>
      {find(favList, fav => fav[entityId] === item.id) ? (
        <IconButton
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            onRemoveFavoriteClick(item.id);
          }}
        >
          <icons.Star/>
        </IconButton>
      ) : (
        <IconButton
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            onAddFavoriteClick(item.id);
          }}
        >
          <icons.StarBorder/>
        </IconButton>
      )}
    </div>
  );
};

const styles = theme => ({
  star: {
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      float: 'none',
      right: 8,
      top: 8,
    },
  },
});

export default withStyles(styles)(EntityListStar);
