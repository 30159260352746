import classNames from 'classnames';
import {withStyles} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import * as colors from '@material-ui/core/colors';
import Hidden from '@material-ui/core/Hidden/Hidden';
import Paper from '@material-ui/core/Paper/Paper';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';

import formatDate from '../../../utils/formatDate';

class Task extends React.Component {
  render() {
    const {classes, task, onClick, onClose, onReopen} = this.props;

    return (
      <Paper
        key={task.id}
        elevation={0}
        className={classNames(classes.task, {
          [classes.closed]: task.closed,
          [classes.expired]: !task.closed && task.expired,
        })}
        onClick={() => onClick()}
      >
        <Hidden smDown>
          <Checkbox
            style={{
              margin: '6px 12px',
              width: 24,
              height: 24,
              padding: 0,
            }}
            disabled={!task.permissions.canCloseTask}
            checked={task.closed}
            onClick={event => {
              if (task.closed) {
                onReopen();
              } else {
                onClose();
              }
              event.stopPropagation();
            }}
          />
          <Typography className={classes.date}>{formatDate(task.deadline, 'EEEEEE, dd MMM yyyy')}</Typography>
          <Typography className={classes.time}>{formatDate(task.deadline, 'HH:mm')}</Typography>
          <Typography className={classes.title} variant="subtitle1">{task.title}</Typography>
          <Typography className={classes.assignee} variant="caption">{task.assignee.name}</Typography>
        </Hidden>
        <Hidden mdUp>
          <Checkbox
            style={{
              margin: 4,
              width: 24,
              height: 24,
            }}
            checked={task.closed}
            disabled={!task.permissions.canCloseTask}
            onClick={event => {
              if (task.closed) {
                onReopen();
              } else {
                onClose();
              }
              event.stopPropagation();
            }}
          />
          <Typography className={classes.title} variant="subtitle1">{task.title}</Typography>
          <div style={{flexBasis: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Typography className={classes.date}>{formatDate(task.deadline, 'EEEEEE, dd MMM yyyy')}</Typography>
            <Typography className={classes.time}>{formatDate(task.deadline, 'HH:mm')}</Typography>
            <div style={{flex: 1}}/>
            <Typography className={classes.assignee} variant="caption">{task.assignee.name}</Typography>
          </div>
        </Hidden>
      </Paper>
    );
  }
}

const styles = () => ({
  task: {
    margin: 8,
    padding: 6,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    minWidth: 320,
  },
  date: {
    margin: 6,
  },
  time: {
    margin: 6,
  },
  title: {
    margin: 6,
    flex: 1,
  },
  closed: {
    background: colors.grey[100],
    '& $title': {
      textDecoration: 'line-through',
    },
  },
  expired: {
    '& $date, $time': {
      color: colors.red[500],
    },
  },
  assignee: {
    margin: 6,
  },
});

export default withStyles(styles)(Task);
