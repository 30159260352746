import * as colors from '@material-ui/core/colors/index';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import MuiCheckbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import {Checkbox} from 'redux-form-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Address from './Address';
import Photos from './Photos';
import PropertyType from './PropertyType';
import TextField from '../../../components/Input/TextField';
import {getLabelsForValue} from '../../../utils';

class Variant extends Component {
  render() {
    const {label, selected, ...props} = this.props;

    return (
      <MenuItem selected={selected} {...props}>
        <MuiCheckbox checked={selected}/>
        <ListItemText primary={label}/>
      </MenuItem>
    );
  }
}

class PropertyFields extends Component {
  render() {
    const {
      address,
      array: {push, remove, move},
      change,
      classes,
      onAreaAmountChange,
      onAreaUnitChange,
      onTypeChange,
      propertyPurposes,
      propertyType,
      propertyTypes,
      readonlyAddress,
      hideDescription,
      buildingTypes,
    } = this.props;

    return (
      <React.Fragment>
        <Address
          readonlyAddress={readonlyAddress}
          showMap
          editable
          address={address}
          change={(name, value) => change(`property.${name}`, value)}
        />
        <PropertyType variants={propertyTypes} buildingTypes={buildingTypes} value={propertyType} onTypeChange={onTypeChange}/>
        <div style={{display: 'flex', alignItems: 'end', justifyContent: 'end'}}>
          <Field
            name="purposes"
            label="Назначения"
            required
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(propertyPurposes, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {propertyPurposes.map(({label, value}, i) => <Variant value={value} label={label} key={i}/>)}
          </Field>
          <Tooltip
            enterTouchDelay={0}
            title="Указывается наилучший или текущий вид использования объекта."
          >
            <icons.Help className={classes.help}/>
          </Tooltip>
        </div>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <Field
              name="area.amount"
              label="Площадь"
              required
              component={TextField}
              normalize={value => value ? value.toString().replace(',', '.') : value}
              onChange={e => {
                if (onAreaAmountChange) {
                  onAreaAmountChange(e);
                }
              }}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name="area.unit"
              label="Ед. изм."
              required
              component={TextField}
              onChange={e => {
                if (onAreaUnitChange) {
                  onAreaUnitChange(e);
                }
              }}
              select
              fullWidth
              margin="normal"
            >
              <MenuItem value="m2">м²</MenuItem>
              <MenuItem value="a">ар (сотка)</MenuItem>
              <MenuItem value="ha">гектар</MenuItem>
            </Field>
          </Grid>
        </Grid>
        <Photos
          name="photos"
          label="Фотографии&nbsp;*"
          push={(name, value) => push(`property.${name}`, value)}
          remove={(name, value) => remove(`property.${name}`, value)}
          move={(name, oldIndex, newIndex) => move(`property.${name}`, oldIndex, newIndex)}
        />
        <Photos
          name="layouts"
          label="Планировки"
          push={(name, value) => push(`property.${name}`, value)}
          remove={(name, value) => remove(`property.${name}`, value)}
          move={(name, oldIndex, newIndex) => move(`property.${name}`, oldIndex, newIndex)}
        />
        <FormControl margin="normal">
          <FormControlLabel
            control={
              <Field name="watermark" component={Checkbox} />
            }
            label="Использовать водяной знак на изображениях"
            required
          />
        </FormControl>
        <Field
          name="ruTubeVideo"
          label="Видео об объекте (RuTube)"
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="overallVideo"
          label="Видео об объекте (YouTube)"
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="trafficVideo"
          label="Видео о трафике (YouTube)"
          component={TextField}
          fullWidth
          margin="normal"
        />
        {
          !hideDescription ? (
            <Field
              name="description"
              label="Описание"
              multiline
              rowsMax={10}
              component={TextField}
              fullWidth
              margin="normal"
            />
          ) : null
        }
      </React.Fragment>
    );
  }
}

const styles = {
  help: {
    color: colors.grey[700],
    marginLeft: 12,
    marginBottom: 8,
  },
};

export default connect(
  state => ({
    propertyTypes: state.root.classification.propertyTypes,
    propertyPurposes: state.root.classification.propertyPurposes,
    buildingTypes: state.root.classification.buildingTypes,
  })
)(withStyles(styles)(PropertyFields));
