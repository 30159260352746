import React, {Component} from 'react';
import {connect} from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import  * as colors from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import * as icons from '@material-ui/icons';

import {checkSub, checkPushSettings, pushSub, unSub, unSubFront} from '../../utils/pushNotifications';
import formatDate from '../../utils/formatDate';
import {queryWebPushList} from '../../queries/WebPushQuery';
import TablePaginationActions from '../../components/TablePaginationActions';
import TablePagination from '@material-ui/core/TablePagination';

class PropertyDetails extends Component {
  state = {
    currentDeviceSub: false,
    loading: false,
    error: false,
    selectedDevice: null,
    deviceList: [],
    currentDeviceKey: null,
    limit: 4,
    offset: 0,
    totalCount: 0,
    currentDeviceCreateAt: null,
    currentDeviceName: null,
    cacheDevice: {},
  };

  componentDidMount(){
    checkSub(this.changeButtonState);
  }

  loadDeviceList = () => {
    const {currentDeviceKey, limit, offset, currentDeviceSub} = this.state;
    queryWebPushList({publicKey: currentDeviceKey, limit, offset})
      .then(resp => {
        if(currentDeviceSub && !resp.data.data.webPushSubList.current){
          unSubFront();
        }
        this.setState({
          deviceList: resp.data.data.webPushSubList.items,
          totalCount: resp.data.data.webPushSubList.totalCount,
          currentDeviceSub: resp.data.data.webPushSubList.current,
        });
      });
  };

  changeButtonState = sub => {
    let pushInfo = localStorage['pushNotification'];
    let settings = {};
    if(pushInfo){
      settings = JSON.parse(pushInfo);
    }
    this.setState({
      currentDeviceSub: !!sub,
      currentDeviceKey: sub,
      cacheDevice: settings,
    }, this.loadDeviceList);
  };


  handleClickChangeStatusCurrentDevice = () => {
    this.setState({
      loading: true,
    }, this.changeStatusCurrentDevice);
  };

  unSubDevice = id => {
    unSub(id)
      .then(() => {
        this.loadDeviceList();
      })
      .catch(() => {
        this.setState({
          error: true,
        });
      });
  };

  changeStatusCurrentDevice = () => {
    const {currentDeviceSub} = this.state;
    if(currentDeviceSub){
      unSub()
        .then(() => {
          this.setState({
            currentDeviceSub: false,
            loading: false,
            cacheDevice: {},
          });
        })
        .catch(() => {
          this.setState({
            currentDeviceSub: true,
            error: true,
          });
        });
    }
    else {
      pushSub()
        .then(() => {
          let pushInfo = localStorage['pushNotification'];
          let settings = {};
          if(pushInfo){
            settings = JSON.parse(pushInfo);
          }
          this.setState({
            currentDeviceSub: true,
            loading: false,
            cacheDevice: settings,
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
            error: true,
          });
        });
    }
  };

  handleOnChangePage = (_, page) => {
    this.setState({
      offset: this.state.limit * page,
    }, this.loadDeviceList);
  };

  render() {
    const {classes} = this.props;
    const {
      currentDeviceSub,
      loading,
      deviceList,
      limit,
      offset,
      totalCount,
      cacheDevice,
      error,
    } = this.state;

    return (
      <React.Fragment>
        <Typography className={classes.title}>Управление push уведомлениями</Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell >Дата</TableCell>
              <TableCell >Устройство</TableCell>
              <TableCell>Статус</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow selected title={'Текущее устройство'}>
              {currentDeviceSub && cacheDevice  && cacheDevice.createdAt
                ? <TableCell title={formatDate(cacheDevice.createdAt, 'dd MMM yyyy')} >
                  {formatDate(cacheDevice.createdAt, 'dd MMM yyyy')}
                </TableCell>
                : <TableCell/>
              }
              {currentDeviceSub && cacheDevice
                ? <TableCell title={cacheDevice.deviceName}>
                  {cacheDevice.deviceName}
                </TableCell>
                : <TableCell title="Текущее устройство">
                  Текущее устройство
                </TableCell>}
              <TableCell>
                {checkPushSettings() ?
                  <React.Fragment>
                    <Button
                      onClick={this.handleClickChangeStatusCurrentDevice}
                      disabled={loading}
                      className={classes.button}
                    >
                      {currentDeviceSub ? 'Отписать' : 'Подписать'}
                    </Button>
                  </React.Fragment>
                  : 'Устройство не поддерживает push уведомления'}
              </TableCell>
            </TableRow>
            {deviceList.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{formatDate(item.createdAt, 'dd MMM yyyy')}</TableCell>
                <TableCell>{item.deviceName}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => this.unSubDevice(item.publicKey)}
                    className={classes.button}
                  >
                    Отписать
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.pagination}>
          <TablePagination
            rowsPerPage={limit + 1}
            component={'div'}
            rowsPerPageOptions={[limit + 1]}
            count={totalCount + 1}
            page={Math.floor(offset / limit)}
            onChangePage={this.handleOnChangePage}
            labelDisplayedRows={({from, to, count}) =>
              `${from}-${to} из ${count}`
            }
            ActionsComponent={TablePaginationActions}
          />
        </div>
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={4000}
          onClose={() => this.setState({error: false})}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className={classes.errorWrapper}
          classes={{anchorOriginBottomLeft: classes.errorMessageMobile}}
        >
          <Typography className={classes.errorMessage}>
            <icons.Warning className={classes.errorIcon}/>
              Произошла ошибка!
          </Typography>
        </Snackbar>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  title: {
    marginTop: 20,
    marginLeft: 5,
    marginBottom: 10,
  },
  button: {
    marginLeft: '-10px',
  },
  table: {
    borderWidth: 1,
    borderStyle:  'solid',
    borderColor: colors.grey[300],
    borderRadius: 5,
    borderCollapse: 'separate',
  },
  errorWrapper: {
    backgroundColor: colors.red[400],
    padding: 10,
    borderRadius: 5,
  },
  errorIcon: {
    color: '#FFF',
    marginBottom: -2,
    marginRight: 2,
    height: 16,
    width: 16,
  },
  errorMessage: {
    color: '#FFF',
  },
  errorMessageMobile: {
    right: 'auto',
    left: 24,
    bottom: 24,
  },
});

export default connect(
  ({root}) => ({
    selfUser: root.selfUser,
  }),
  {}
)(withStyles(styles)(PropertyDetails));
