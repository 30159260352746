import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';

import {
  createAreaRangeFormatter,
  createPriceFormatter,
  formatAddressListItem,
  mapLabels,
  formatDate,
} from '../../utils';
import EditableStatus from '../../components/EditableStatus';
import ListItemWithMenu from '../../components/ListItemWithMenu';
import EditableRequirements from './EditableRequirements';


class DealDetails extends Component {

  onStatusChange = status => {
    this.props.onStatusChange(status);
  };

  onChangeDealClass = newClass => {
    axios
      .post('/api/v1/setDealClass', {id: this.props.deal.id, abcClass: newClass})
      .then(() => {
        this.setState({
          anchor: null,
        });
        this.props.onClassChange(newClass);
      })
      .catch(resp =>{
        if(resp.status ===  403){
          alert('У вас недостаточно прав для выполнения данного действия');
        }
      });

  };

  render() {
    const {
      deal,
      dealSourceLabels,
      dealStatusLabels,
      formatAreaRange,
      formatPrice,
      propertyPurposeLabels,
      onStatusChange,
      onRequirementsChange,
      dealStatuses,
      dealClosesReason,
      classificationDeal,
    } = this.props;


    return (
      <div className="content" style={{background: 'white'}}>
        <List>
          {deal.status ? (
            <EditableStatus
              status={deal.status}
              onChange={status => onStatusChange(status)}
              statuses={dealStatuses}
              statusLabels={dealStatusLabels}
            />
          ) : null}
          {deal.abcClass ? (
            <ListItemWithMenu
              title="Класс"
              values={classificationDeal}
              selectedValue={deal.abcClass}
              menuValueName={'value'}
              menuLabelName={'selectLabel'}
              onChange={this.onChangeDealClass}
            />
          ) : null}
          <ListItem dense>
            <ListItemText
              primary={deal.multiple ? 'Да': 'Нет'} secondary="Множественная"/>
          </ListItem>
          {deal.closeReason ? (
            <ListItem dense>
              <ListItemText primary={dealClosesReason[deal.closeReason]} secondary="Причина закрытия"/>
            </ListItem>
          ) : null}
          {deal.closeComment ? (
            <ListItem dense>
              <ListItemText primary={deal.closeComment} secondary="Комментарий закрытия"/>
            </ListItem>
          ) : null}
          {deal.source ? (
            <ListItem dense>
              <ListItemText primary={dealSourceLabels[deal.source] || deal.source} secondary="Источник"/>
            </ListItem>
          ) : null}
          {deal.propertyAddresses ? deal.propertyAddresses.map((address, index) => (
            <ListItem key={index} dense>
              <ListItemText
                primary={formatAddressListItem(address)}
                secondary="Адрес"/>
            </ListItem>
          )) : null}
          {deal.deadline ? (
            <ListItem dense>
              <ListItemText primary={formatDate(deal.deadline)} secondary="Крайний срок принятия решения"/>
            </ListItem>
          ) : null}
          {deal.propertyPurpose ? (
            <ListItem dense>
              <ListItemText
                primary={propertyPurposeLabels[deal.propertyPurpose] || deal.propertyPurpose}
                secondary="Назначение"
              />
            </ListItem>
          ) : null}
          {deal.propertyArea ? (
            <ListItem dense>
              <ListItemText primary={formatAreaRange(deal.propertyArea)} secondary="Площадь"/>
            </ListItem>
          ) : null}
          {deal.offerPrice ? (
            <ListItem dense>
              <ListItemText primary={formatPrice(deal.offerPrice)} secondary="Желаемая цена"/>
            </ListItem>
          ) : null}
          <EditableRequirements
            requirements={deal.requirements}
            onChange={requirements => onRequirementsChange(requirements)}
          />
          <ListItem dense>
            <ListItemText primary={formatDate(deal.createdAt)} secondary="Создана"/>
          </ListItem>
          {deal.updatedAt !== deal.createdAt ? (
            <ListItem dense>
              <ListItemText primary={formatDate(deal.updatedAt)} secondary="Изменена"/>
            </ListItem>
          ) : null}
        </List>
      </div>
    );
  }
}

const styles = () => ({
  avatar: {
    background: colors.green[300],
  },
  menuPaper: {
    minWidth: 288,
  },
});

export default connect(
  ({root}) => ({
    dealSourceLabels: mapLabels(root.classification.dealSources, 'value', 'label'),
    dealStatusColors: mapLabels(root.classification.dealStatuses, 'value', 'color'),
    dealStatusLabels: mapLabels(root.classification.dealStatuses, 'value', 'label'),
    dealStatuses: root.classification.dealStatuses,
    dealClosesReason: root.classification.dealClosesReason,
    classificationDeal: root.classification.classificationDeal,
    formatPrice: createPriceFormatter(root.classification.priceTypes, root.classification.currencyCodes),
    formatAreaRange: createAreaRangeFormatter(root.classification.areaUnits),
    propertyPurposeLabels: mapLabels(root.classification.propertyPurposes, 'value', 'label'),
  })
)(withStyles(styles)(DealDetails));
