import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

// import ContactList from './ContactList';
import ContactAssignees from './ContactAssignees';
import ContactRelationships from './ContactRelationships';
import ContactProperties from './ContactProperties';
import ContactContracts from './ContactContracts';
import ContactView from './ContactView';
import {setTitle} from '../../actions';
import ContactListWrapper from './ContactListWrapper';

class Contacts extends Component {
  componentDidMount() {
    this.props.setTitle('Контакты');
  }

  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route path={`${match.url}/:contactId/assignees`} exact component={ContactAssignees}/>
        <Route path={`${match.url}/:contactId/relationships`} exact component={ContactRelationships}/>
        <Route path={`${match.url}/:contactId/properties`} exact component={ContactProperties}/>
        <Route path={`${match.url}/:contactId/contracts`} exact component={ContactContracts}/>
        <Route path={`${match.url}/:contactId`} component={ContactView}/>
        <Route path={`${match.url}/`} exact component={ContactListWrapper}/>
        {/* <Route path={`${match.url}/`} exact component={ContactListWrapper}/> */}
      </Switch>
    );
  }
}

export default connect(null, {setTitle})(Contacts);
