import debounce from 'lodash/debounce';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';
import React, {Component} from 'react';
import {Field} from 'redux-form';

class AsyncField extends Component {
  state = {
    result: null,
    error: false,
    loading: false,
  };

  render() {
    const {
      InputProps,
      requester,
      validator,
      result: _result,
      ...otherProps
    } = this.props;
    const {error, result, loading} = this.state;

    let endAdornment = null;
    if (loading) {
      endAdornment = (
        <InputAdornment position="end">
          <CircularProgress thickness={5} size={20}/>
        </InputAdornment>
      );
    } else if (error) {
      endAdornment = (
        <InputAdornment position="end">
          <Tooltip title="При проверке произошла ошибка.">
            <icons.Error style={{color: colors.red[300]}}/>
          </Tooltip>
        </InputAdornment>
      );
    } else if (result) {
      endAdornment = (
        <InputAdornment position="end">
          <icons.Warning style={{color: colors.orange[300]}}/>
        </InputAdornment>
      );
    }

    return (
      <React.Fragment>
        <Field
          {...otherProps}
          onChange={this.handleChange}
          InputProps={{
            ...InputProps,
            endAdornment,
          }}
        />
        {result}
      </React.Fragment>
    );
  }

  handleChange = debounce(async e => {
    const {onChange, result, requester, validator} = this.props;

    if (validator(e.target.value)) {
      this.setState({loading: true});

      requester(e.target.value)
        .then(resp => {
          this.setState({
            error: !resp.data.canPost,
            loading: false,
            result: resp.data.totalCount > 0 ? result(e.target.value, resp.data.canPost) : null,
          });
        })
        .catch(() => {
          this.setState({
            error: true,
            loading: false,
            result: null,
          });
        });
    } else {
      this.setState({
        error: false,
        loading: false,
        result: null,
      });
    }

    if (onChange) {
      onChange(e);
    }
  }, 500);
}

export default AsyncField;
