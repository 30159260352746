import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import * as icons from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import qs from 'qs';
import axios from 'axios';
import {connect} from 'react-redux';

import {getUniqueArray} from '../utils';
import {
  assigneeUpdateArray,
  contactUpdateArray,
  propertyUpdateArray,
  offerUpdateArray,
  arrayNames, teamUpdateArray,
} from '../constants/activityEvents';
import {
  updatePropertyNames,
  updateOfferNames,
  updateContactNames,
  updateMemberNames,
  updateTeamNames,
} from '../actions';
import ActivityEvent from './ActivityEvent';

class ActivityEventsList extends React.Component {
  state = {
    page: 1,
    activityEvents: [],
  };

  componentDidMount() {
    this.updateActivity();
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  handlePrevPage = () => {
    const {page} = this.state;
    this.setState(prevState => ({page: prevState.page - 1}));
    this.updateActivity(page - 1);
  };

  handleNextPage = () => {
    const {page} = this.state;
    this.setState(prevState => ({page: prevState.page + 1}));
    this.updateActivity(page + 1);
  };

  updateActivity(page = 1) {
    const {aggregateType, entityId} = this.props;
    const url = `/api/v1/getActivityEvents/${aggregateType}/${entityId}?`
      + qs.stringify({
        limit: 10,
        offset: page - 1,
      });

    return axios.get(url)
      .then(resp => {
        this.getOffer(resp.data.items);
        this.getProperty(resp.data.items);
        this.getContact(resp.data.items);
        this.getMember(resp.data.items);
        this.getTeam(resp.data.items);
        this.setState({activityEvents: resp.data});
      });
  }

  getOffer = events => {
    let array = this.filterOffer(events);
    this.getNames(array, '/api/offersNames', this.props.updateOfferNames);
  };

  getProperty = events => {
    let array = this.filterProperty(events);
    this.getNames(array, '/api/propertiesNames', this.props.updatePropertyNames);
  };

  getContact = events => {
    let array = this.filterContact(events);
    this.getNames(array, '/api/contactsNames', this.props.updateContactNames);
  };

  getMember = events => {
    let array = this.filterMembers(events);
    this.getNames(array, '/api/membersNames', this.props.updateMemberNames);
  };

  getTeam = events => {
    let array = this.filterTeams(events);
    this.getNames(array, '/api/teamsNames', this.props.updateTeamNames);
  };

  filterTeams = items => this.filterArray(items, teamUpdateArray, arrayNames.team);
  filterMembers = items => this.filterArray(items, assigneeUpdateArray, arrayNames.assignee);
  filterContact = items => this.filterArray(items, contactUpdateArray, arrayNames.contact);
  filterProperty = items => this.filterArray(items, propertyUpdateArray, arrayNames.property);
  filterOffer = items => this.filterArray(items, offerUpdateArray, arrayNames.offer);


  filterArray = (events, entity, nameId)  => {
    let resultArray = [];
    const names = this.props[nameId];
    for(let i = 0; i < events.length; i++){
      let property = entity.find(({type}) => type === events[i].type);
      if(property){
        let id = property.id;
        if(!names.hasOwnProperty(id) && typeof events[i].payload[id] !== 'undefined' && events[i].payload[id])
          resultArray = resultArray.concat(events[i].payload[id]);
      }
    }
    return getUniqueArray(resultArray);
  };


  getNames = (arrayNames, url, functionUpdate) => {
    if(arrayNames.length > 0) {
      axios.get(url, {
        params: {
          'id': arrayNames,
        },
      }).then(res => {
        functionUpdate(res.data);
      });
    }
  };

  render() {
    const {
      activityEvents,
      page,
    } = this.state;
    const {
      entityId,
    } = this.props;
    return (
      <>
        <Typography
          variant="subtitle1"
          style={{marginBottom: 8, paddingLeft: 24}}
        >
          История ({activityEvents.totalCount || 0})
        </Typography>
        {activityEvents.items && activityEvents.items.map((event, index) => (
          <ActivityEvent entityId={entityId} aggregateType={this.props.aggregateType} key={index} event={event} />
        ))}
        {activityEvents && activityEvents.totalCount > 10 ? (
          <Toolbar>
            <IconButton
              style={{marginLeft: -20}}
              disabled={page === 1}
              onClick={this.handlePrevPage}
            >
              <icons.ChevronLeft/>
            </IconButton>
            <IconButton
              disabled={page >= Math.floor((activityEvents.totalCount - 1) / 10) + 1}
              onClick={this.handleNextPage}
            >
              <icons.ChevronRight/>
            </IconButton>
            <Typography variant="caption">
              {`${page} / ${Math.floor((activityEvents.totalCount - 1) / 10) + 1}`}
            </Typography>
          </Toolbar>
        ) : null}
      </>
    );
  }
}
export default connect(
  state => ({
    [arrayNames.property]: state.root.entityNames.propertyNames,
    [arrayNames.offer]: state.root.entityNames.offerNames,
    [arrayNames.contact]: state.root.entityNames.contactNames,
    [arrayNames.assignee]: state.root.entityNames.memberNames,
    [arrayNames.team]: state.root.entityNames.teamNames,
  }),
  {
    updatePropertyNames,
    updateOfferNames,
    updateContactNames,
    updateMemberNames,
    updateTeamNames,
  }
)(ActivityEventsList);
