const defaultState = {
  screens: {},
  members: {},
  teams: {},
};

const team = (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'SCREEN_REMOVE': {
      const screens = state.screens;

      if (payload.key in screens) {
        delete screens[payload.key];

        return {
          ...state,
          screens,
        };
      }

      return state;
    }

    case 'TEAM_MEMBER_LIST_LOAD_STARTED':
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            loading: true,
            filter: payload.filter,
          },
        },
      };

    case 'TEAM_MEMBER_LIST_LOAD_COMPLETED': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            filter: state.screens[payload.key].filter,
            items: payload.items.map(member => member.id),
            page: payload.page,
            totalCount: payload.totalCount,
          },
        },
        members: {
          ...state.members,
          ...payload.items.reduce((members, member) => ({...members, [member.id]: member}), {}),
        },
      };
    }

    case 'TEAM_MEMBER_LIST_LOAD_FAILED':
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            filter: state.screens[payload.key].filter,
            error: true,
          },
        },
      };

    case 'TEAM_MEMBER_ADD': {
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]: {
            items: [
              payload.member.id,
              ...state.screens[payload.key].items,
            ],
          },
        },
        members: {
          ...state.members,
          [payload.member.id]: payload.member,
        },
      };
    }

    case 'TEAM_MEMBER_UPDATE': {
      return {
        ...state,
        members: {
          ...state.members,
          [payload.member.id]: payload.member,
        },
      };
    }

    default:
      return state;
  }
};

export default team;
