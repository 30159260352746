import axios from 'axios';
import * as colors from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, FormSection} from 'redux-form';
import Button from '@material-ui/core/Button';
import MuiCheckbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import qs from 'qs';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Checkbox} from 'redux-form-material-ui';

import AsyncField from '../../../components/Input/AsyncField';
import DatePicker from '../../../components/Input/DatePicker';
import MaskedInput from '../../../components/Input/MaskedInput';
import PhoneInput from '../../../components/Input/PhoneInput';
import TextField from '../../../components/Input/TextField';
import {getLabelsForValue, mapLabels} from '../../../utils';
import base64url from '../../../utils/base64url';
import {hasRole} from '../../../utils/roleFunc';
import * as userRoles from '../../../constants/userRoles';
import Assignees from '../../../components/relations/Assignees';

class Variant extends Component {
  render() {
    const {label, selected, ...props} = this.props;

    return (
      <MenuItem selected={selected} {...props}>
        <MuiCheckbox checked={selected} />
        <ListItemText primary={label} />
      </MenuItem>
    );
  }
}

const PersonName = ({classes}) => {
  return (
    <Grid container spacing={24}>
      <Grid item xs={6} md={4}>
        <Field
          name="lastName"
          label="Фамилия"
          component={TextField}
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <Field
          name="firstName"
          label="Имя"
          component={TextField}
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <div
          style={{position:'relative'}}
        >
          <Field
            name="middleName"
            label="Отчество"
            component={TextField}
            fullWidth
            margin="none"
          />
          <Tooltip
            enterTouchDelay={0}
            title={
              <div>
                <ul style={{padding: '0 10px'}}>
                  <li>ФИО директора для организации.</li>
                  <li>ФИО руководителя для ИП.</li>
                  <li>ФИО самого физического лица.</li>
                </ul>
              </div>
            }
          >
            <icons.Help className={classes.helpInField}/>
          </Tooltip>
        </div>
      </Grid>
    </Grid>
  );
};

const Organization = props => {
  const {classes} = props;
  return (
    <Grid container spacing={24} className={classes.subForm}>
      <Grid item xs={12}>
        <Field
          name="name"
          label="Наименование организации"
          component={TextField}
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="legalAddress"
          label="Юридический адрес"
          component={TextField}
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="INN"
          label="ИНН"
          component={TextField}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {mask: '9999999999'},
          }}
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="KPP"
          label="КПП"
          component={TextField}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {mask: '9999**999'},
          }}
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="OGRN"
          label="ОГРН"
          component={TextField}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {mask: '9999999999999'},
          }}
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="OKPO"
          label="ОКПО"
          component={TextField}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {mask: '99999999'},
          }}
          fullWidth
          margin="none"
        />
      </Grid>
    </Grid>
  );
};

const Entrepreneur = props => {
  const {classes} = props;

  return (
    <Grid container spacing={24} className={classes.subForm}>
      <Grid item xs={12}>
        <Field
          name="name"
          label="Название ИП"
          component={TextField}
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="residenceAddress"
          label="Адрес регистрации"
          component={TextField}
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="INN"
          label="ИНН"
          component={TextField}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {mask: '999999999999'},
          }}
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="OGRNIP"
          label="ОГРНИП"
          component={TextField}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {mask: '999999999999999'},
          }}
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="OKPO"
          label="ОКПО"
          component={TextField}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {mask: '9999999999'},
          }}
          fullWidth
          margin="none"
        />
      </Grid>
    </Grid>
  );
};

class Attributes extends React.Component {
  state = {
    showAttributeKindMenu: null,
  };

  render() {
    const {
      attributes,
      contactAttributeKinds,
      contactAttributeKindLabels,
      push,
      remove,
    } = this.props;
    const {showAttributeKindMenu} = this.state;

    return (
      <React.Fragment>
        {attributes
          ? attributes.map((attribute, index) => {
            const name = `attributes[${index}]`;
            return (
              <FormSection name={name} key={index} component={React.Fragment}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="value"
                    label={contactAttributeKindLabels[attribute.kind]}
                    required
                    component={TextField}
                    InputProps={
                      attribute.kind === 'phone' || attribute.kind === 'fax'
                        ? {inputComponent: PhoneInput}
                        : null
                    }
                    fullWidth
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{position: 'relative'}}>
                  <Field
                    name="label"
                    label="Метка"
                    component={TextField}
                    fullWidth
                    margin="none"
                    style={{paddingRight: 60, boxSizing: 'border-box'}}
                  />
                  <IconButton
                    style={{position: 'absolute', right: 12}}
                    onClick={() => remove('attributes', index)}
                  >
                    <icons.DeleteForever />
                  </IconButton>
                </Grid>
              </FormSection>
            );
          })
          : null}
        <Grid item xs={12}>
          <Button
            size="small"
            color="primary"
            onClick={e => this.setState({showAttributeKindMenu: e.target})}
          >
            <icons.Add />
            контактные данные
          </Button>
          <Menu
            anchorEl={showAttributeKindMenu}
            open={Boolean(showAttributeKindMenu)}
            onClose={() => this.setState({showAttributeKindMenu: null})}
          >
            {contactAttributeKinds.map(({value, label}, index) => (
              <MenuItem
                key={index}
                value={value}
                dense
                onClick={() => {
                  push('attributes', {kind: value});
                  this.setState({showAttributeKindMenu: null});
                }}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </React.Fragment>
    );
  }
}

const Person = props => {
  const {classes} = props;

  return (
    <Grid container spacing={24} className={classes.subForm}>
      <Grid item xs={12}>
        <Field
          name="INN"
          label="ИНН"
          component={TextField}
          InputProps={{
            inputComponent: MaskedInput,
            inputProps: {mask: '999999999999'},
          }}
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={12}>
        <FormSection name="passport">
          <Grid container spacing={24}>
            <Grid item xs={6}>
              <Field
                name="series"
                label="Серия паспорта"
                component={TextField}
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {mask: '99 99'},
                }}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="number"
                label="Номер"
                component={TextField}
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {mask: '999999'},
                }}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="issuedAt"
                label="Когда выдан"
                component={TextField}
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {mask: '9[9].9[9].9999'},
                }}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="issuedBy"
                label="Кем выдан"
                component={TextField}
                fullWidth
                margin="none"
              />
            </Grid>
          </Grid>
        </FormSection>
      </Grid>
      <Grid item xs={12}>
        <Field
          name="residenceAddress"
          label="Адрес регистрации"
          component={TextField}
          fullWidth
          margin="none"
        />
      </Grid>
    </Grid>
  );
};

const Persons = props => {
  const {
    contactAttributeKinds,
    contactAttributeKindLabels,
    persons,
    classes,
    push,
    remove,
  } = props;

  return (
    <React.Fragment>
      {persons
        ? persons.map((person, index) => {
          return (
            <Grid key={index} item xs={12}>
              <FormSection name={`persons[${index}]`} key={index}>
                <Grid container spacing={24} className={classes.subForm}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="name"
                      label="Имя"
                      required
                      component={TextField}
                      fullWidth
                      margin="none"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="position"
                      label="Должность"
                      required
                      component={TextField}
                      fullWidth
                      margin="none"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="phone"
                      label="Телефон"
                      required
                      component={TextField}
                      InputProps={{inputComponent: PhoneInput}}
                      fullWidth
                      margin="none"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="email"
                      label="Адрес эл. почты"
                      component={TextField}
                      fullWidth
                      margin="none"
                    />
                  </Grid>
                  <Attributes
                    attributes={person.attributes}
                    classes={classes}
                    contactAttributeKinds={contactAttributeKinds}
                    contactAttributeKindLabels={contactAttributeKindLabels}
                    push={(attrName, attrValue) =>
                      push(`contact.persons[${index}].${attrName}`, attrValue)
                    }
                    remove={(attrName, attrIndex) =>
                      remove(
                        `contact.persons[${index}].${attrName}`,
                        attrIndex
                      )
                    }
                  />
                  <Grid item xs={12} md={12} style={{position: 'relative'}}>
                    <Field
                      name="note"
                      label="Заметка"
                      component={TextField}
                      multiline
                      rowsMax={3}
                      fullWidth
                      margin="none"
                      style={{paddingRight: 60, boxSizing: 'border-box'}}
                    />
                    <IconButton
                      style={{position: 'absolute', right: 12}}
                      onClick={() => remove('contact.persons', index)}
                    >
                      <icons.DeleteForever />
                    </IconButton>
                  </Grid>
                </Grid>
              </FormSection>
            </Grid>
          );
        })
        : null}
      <Grid item xs={12}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => push('contact.persons', {})}
        >
          <icons.Add />
          контактное лицо
        </Button>
      </Grid>
    </React.Fragment>
  );
};

class ContactForm extends Component {
  render() {
    const {
      array: {push, remove},
      attributes,
      blacklist,
      change,
      classes,
      contactAttributeKinds,
      contactAttributeKindLabels,
      contactScopes,
      contactType,
      contactTypes,
      contactRoles,
      offerStatuses,
      persons,
      selfUser,
      team,
      contact,
      onAssigneeSelect,
      onAssigneeUnselect,
      assignees,
    } = this.props;

    return (
      <div style={{marginTop: 12}}>
        <FormSection name="contact">
          <Grid container spacing={24}>
            <Grid item xs={12} md={6}>
              <Field
                name="name"
                label="Название"
                required
                component={TextField}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="type"
                label="Тип"
                required
                component={TextField}
                select
                onChange={() => change('contact.subject', null)}
                fullWidth
                margin="none"
              >
                {contactTypes.map(({value, label}, i) => (
                  <MenuItem value={value} key={i}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12} md={6}>
              <AsyncField
                name="phone"
                label="Основной телефон"
                required
                component={TextField}
                InputProps={{inputComponent: PhoneInput}}
                validator={value =>
                  /^\+\d \d\d\d \d\d\d-\d\d-\d\d$/.test(value)
                }
                requester={value => {
                  const url =
                    '/api/contacts?' +
                    qs.stringify({
                      _source: 'team',
                      statuses: ['opened', 'closed', 'archived'],
                      phone: value,
                    });
                  return axios.get(url);
                }}
                result={(value, canPost) => {
                  const url =
                    '/contacts?' +
                    base64url.encode(
                      JSON.stringify({
                        _source: 'team',
                        phone: value,
                        statuses: ['opened', 'closed', 'archived'],
                      })
                    );
                  return (
                    <Typography>
                      Уже{' '}
                      <a href={url}>
                        используется
                      </a>
                      .{' '}
                      {canPost
                        ? 'Вы можете создать контакт.'
                        : 'Вы не можете создать контакт.'}
                    </Typography>
                  );
                }}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AsyncField
                name="email"
                type="email"
                label="Основной адрес эл. почты"
                component={TextField}
                validator={value => /^[^@]+@[^@]+$/.test(value)}
                requester={value =>
                  axios.get(
                    '/api/contacts?_source=team&email=' +
                      encodeURIComponent(value)
                  )
                }
                result={value => {
                  const url =
                    '/contacts?' +
                    base64url.encode(
                      JSON.stringify({email: value, _source: 'team'})
                    );
                  return (
                    <Typography>
                      Уже используется.{' '}
                      <a href={url}>
                        Посмотреть
                      </a>{' '}
                      контакты.
                    </Typography>
                  );
                }}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="roles"
                label="Роли"
                required
                component={TextField}
                select
                SelectProps={{
                  multiple: true,
                  renderValue: value =>
                    getLabelsForValue(contactRoles, value).join(', '),
                }}
                format={value => value || []}
                fullWidth
                margin="none"
              >
                {contactRoles.map(({label, value}, i) => (
                  <Variant value={value} label={label} key={i} />
                ))}
              </Field>
            </Grid>
            <Grid item xs={12} md={4} style={{display: 'flex', alignItems: 'center'}}>
              <FormControl margin="none">
                <FormControlLabel
                  control={
                    <Field
                      name="open"
                      disabled={hasRole(selfUser.role, userRoles.MEMBER)}
                      component={Checkbox}
                    />
                  }
                  label="Открытый контакт"
                />
              </FormControl>
              <Tooltip
                enterTouchDelay={0}
                title={
                  <div>
                    <p>Контакт, которым вы хотите поделиться, виден всем пользователям системы. Сетевые компании, надежные подрядчики и прочее.</p>
                  </div>
                }
                style={{display: 'block'}}
              >
                <icons.Help className={classes.help} />
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={4} style={{display: 'flex', alignItems: 'center'}}>
              <FormControl margin="none">
                <FormControlLabel
                  control={<Field name="blacklist" component={Checkbox} />}
                  label="Черный список"
                />
              </FormControl>
              <Tooltip
                enterTouchDelay={0}
                title={
                  <div>
                    <p>Неблагонадежный клиент, с вашим комментарием, виден всем пользователям системы.</p>
                  </div>
                }
                style={{display: 'block'}}
              >
                <icons.Help className={classes.help} />
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={4} style={{display: 'flex', alignItems: 'center'}}>
              <FormControl margin="none">
                <FormControlLabel
                  control={
                    <Field
                      name="showTeam"
                      component={Checkbox}
                    />
                  }
                  label="Виден команде"
                />
              </FormControl>
              <Tooltip
                enterTouchDelay={0}
                title={
                  <div>
                    <p>Контакт виден всем участником Вашей команды.</p>
                  </div>
                }
                style={{display: 'block'}}
              >
                <icons.Help className={classes.help} />
              </Tooltip>
            </Grid>
            {blacklist ? (
              <Grid item xs={12}>
                <Field
                  name="blacklistNote"
                  label="Причина добавления в черный список"
                  required
                  component={TextField}
                  multiline
                  rowsMax={10}
                  fullWidth
                  margin="none"
                />
              </Grid>
            ) : null}
            {contactType ? (
              <Grid item xs={12}>
                {contactType === 'person'?
                  <FormSection
                    name="subject.name"
                    component={PersonName}
                    key="1"
                    classes={classes}
                  />
                  :<FormSection
                    name="subject.principalName"
                    component={PersonName}
                    key="2"
                    classes={classes}
                  />}
              </Grid>
            ) : null}
            {contactType ? (
              <>
                {contactType ?
                  <Grid item xs={4}>
                    {contactType !== 'person' ?
                      <Field
                        name="subject.principalName.birthday"
                        label="Дата рождения"
                        key={1}
                        format={null}
                        component={props => (
                          <DatePicker format="yyyy-MM-dd" clearable {...props} />
                        )}
                        fullWidth
                        margin="none"
                      />
                      : (contactType === 'person' ? (
                        <Field
                          name="subject.name.birthday"
                          label="Дата рождения"
                          key={2}
                          format={null}
                          component={props => (
                            <DatePicker format="yyyy-MM-dd" clearable {...props} />
                          )}
                          fullWidth
                          margin="none"
                        />
                      ): null)
                    }
                  </Grid>
                  : null}
                <Grid item xs={8}>
                  <Field
                    name="website"
                    label="Адрес сайта"
                    component={TextField}
                    fullWidth
                    margin="none"
                  />
                </Grid>
             </>
            ) : (
              <Grid item xs={12}>
                <Field
                  name="website"
                  label="Адрес сайта"
                  component={TextField}
                  fullWidth
                  margin="none"
                />

              </Grid>
            )}
            <Attributes
              attributes={attributes}
              classes={classes}
              contactAttributeKinds={contactAttributeKinds}
              contactAttributeKindLabels={contactAttributeKindLabels}
              push={(attrName, attrValue) =>
                push(`contact.${attrName}`, attrValue)
              }
              remove={(attrName, attrIndex) =>
                remove(`contact.${attrName}`, attrIndex)
              }
            />
            <Grid item xs={12}>
              <Field
                name="address"
                label="Фактический адрес"
                component={TextField}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="description"
                label="Дополнительные сведения"
                component={TextField}
                multiline
                rowsMax={10}
                fullWidth
                margin="none"
              />
            </Grid>
            {!contact ? (
              <Grid item xs={12}>
                <FormControl margin="none">
                  <FormControlLabel
                    control={<Field name="selfAssignee" component={Checkbox} />}
                    label="Я ответственный"
                    required
                  />
                </FormControl>
              </Grid>
            ) : null}
            {!contact ? (
              <Assignees
                team={team}
                assignees={assignees}
                onSelect={onAssigneeSelect}
                onUnselect={onAssigneeUnselect}
              />
            ) : null}
            <Persons
              persons={persons}
              classes={classes}
              contactAttributeKinds={contactAttributeKinds}
              contactAttributeKindLabels={contactAttributeKindLabels}
              remove={remove}
              push={push}
            />
            {/*{subject ? (*/}
            <Grid item xs={12}>
              {contactType === 'organization' ? (
                <FormSection
                  name="subject"
                  component={Organization}
                  classes={classes}
                />
              ) : null}
              {contactType === 'entrepreneur' ? (
                <FormSection
                  name="subject"
                  component={Entrepreneur}
                  classes={classes}
                />
              ) : null}
              {contactType === 'person' ? (
                <FormSection
                  name="subject"
                  component={Person}
                  classes={classes}
                />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Field
                name="comment"
                label="Комментарий"
                component={TextField}
                multiline
                rowsMax={15}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="scopes"
                label="Сферы деятельности"
                component={TextField}
                select
                SelectProps={{
                  multiple: true,
                  renderValue: value =>
                    getLabelsForValue(contactScopes, value).join(', '),
                }}
                format={value => value || []}
                fullWidth
                margin="none"
              >
                {contactScopes.map(({label, value}, i) => (
                  <Variant value={value} label={label} key={i} />
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="usefulness"
                label="Чем может быть полезен, связи"
                component={TextField}
                multiline
                rowsMax={15}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="publicInfo"
                label="Публичная информация"
                component={TextField}
                multiline
                rowsMax={15}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="status"
                label="Статус"
                required
                component={TextField}
                select
                fullWidth
                margin="none"
              >
                {offerStatuses.map(({value, label}, i) => (
                  <MenuItem key={i} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
        </FormSection>
      </div>
    );
  }
}

const styles = () => ({
  subForm: {
    width: '100%',
    margin: 0,
    background: '#FFFDE7',
    border: '1px solid #FFF9C4',
  },
  help: {
    color: colors.grey[700],
    marginLeft: 12,
  },
  helpInField: {
    color: colors.grey[700],
    position:'absolute',
    display: 'block',
    top: 0,
    right: 10,
  },
});

export default connect(state => ({
  contactScopes: state.root.classification.contactScopes,
  contactTypes: state.root.classification.contactTypes,
  contactRoles: state.root.classification.contactRoles,
  contactAttributeKinds: state.root.classification.contactAttributeKinds,
  contactAttributeKindLabels: mapLabels(
    state.root.classification.contactAttributeKinds,
    'value',
    'label'
  ),
  offerStatuses: state.root.classification.offerStatuses,
  selfUser: state.root.selfUser,
}))(withStyles(styles)(ContactForm));
