import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import TextField from '../../../components/Input/TextField';
import {withStyles} from '@material-ui/core/styles';
import {reduxForm, Field} from 'redux-form';
import axios from 'axios';

class EditNote extends Component {
  render() {
    const {
      submitting,
      handleSubmit,
      open,
      onClose,
      favData,
      classes,
      sourceOwn,
    } = this.props;

    return (
      <Dialog
        fullWidth
        classes={{paper: classes.noteDialog}}
        maxWidth="xs"
        open={open}
      >
        <DialogTitle>{favData.name}</DialogTitle>
        <Divider/>
        <DialogContent className={classes.dialogContent}>
          <Field
            component={TextField}
            name="note"
            disabled={!sourceOwn || submitting}
            placeholder="Добавьте заметку"
            multiline
            rowsMax={15}
            fullWidth
          />
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отмена
          </Button>
          {sourceOwn ? (
            <Button
              type="button"
              color="primary"
              disabled={submitting}
              onClick={handleSubmit(this.submit)}
            >
              Сохранить
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    );
  }

  submit = values => {
    const {onSubmitted, onClose, favData} = this.props;
    axios.put('/api/v1/favorites/note', {id: favData.id, note: values.note})
      .then(resp => {
        if (resp.status === 200) {
          onSubmitted(values.note);
        }
      })
      .catch(error => {
        if(error.response.status === 404) {
          alert('Запись не найдена');
        } else {
          alert('Произошла ошибка');
        }
      })
      .then(() => onClose());
  };
}

const styles = theme => ({
  noteDialog: {
    width: 550,
    maxWidth: '100%',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100%',
    },
  },
  dialogContent: {
    padding: 20,
  },
});

export default reduxForm({
  form: 'edit_favorite',
  enableReinitialize: true,
})(withStyles(styles)(EditNote));
