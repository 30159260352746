import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

class ListItemWithMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleItemClick = value => {
    this.setState({anchorEl: null});
    if(value !== this.props.status) {
      this.props.onChange(value);

    }
  };

  render() {
    const {
      title,
      value,
      values,
      selectedValue,
      menuValueName,
      menuLabelName,
      classes,
    } = this.props;
    const {anchorEl} = this.state;
    let valueItem = values.find(item => item.value === selectedValue);
    let textValue = value || (valueItem && valueItem[menuLabelName]);
    return (
      <React.Fragment>
        <ListItem
          dense
          button
          className={classes.item}
          onClick={event => this.setState({anchorEl: event.currentTarget})}
        >
          <ListItemText primary={textValue} secondary={title}/>
          <icons.Edit className={classes.editIcon}/>
        </ListItem>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => this.setState({anchorEl: null})}
          classes={{paper: classes.menuPaper}}
        >
          {values.map((item, i) => {
            let itemValue = item[menuValueName || 'value'];
            let itemLabel = item[menuLabelName || 'label'];
            return(
              <MenuItem
                key={i}
                selected={itemValue === selectedValue}
                value={itemValue}
                onClick={() => this.handleItemClick(itemValue)}
              >
                {itemLabel}
              </MenuItem>
            );
          })}
        </Menu>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  editIcon: {
    fontSize: 18,
    color: colors.grey[600],
    visibility: 'hidden',
    marginLeft: 'auto',
  },
  item: {
    cursor: 'pointer',
    '&:hover $editIcon': {
      visibility: 'visible',
    },
  },
  menuPaper: {
    minWidth: 288,
  },
});

export default withStyles(styles)(ListItemWithMenu);
