import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';

import TaskList from './TaskList';
import {setTitle} from '../../../actions';

class TaskApp extends Component {
  componentDidMount() {
    this.props.setTitle('Задачи');
  }

  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/assigned/all`} />
        <Route path={`${match.url}/:role/:interval/:day(\\d{4}-\\d{2}-\\d{2})?`} component={TaskList}/>
      </Switch>
    );
  }
}

export default connect(null, {setTitle})(TaskApp);
