import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';


class CloseDealOfferForm extends Component {
  render() {
    const {
      onClose,
      open,
      submit,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Отправить пресс-релиз о сделке?</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            onClick={onClose}
          >
            Нет
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={submit}
          >
            Да
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default (CloseDealOfferForm);
