import React, {Component} from 'react';
import {scaleBand, scaleLinear} from 'd3-scale';
import Axes from './Axes';
import Bars from './Bars';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';

class Statistics extends Component {
  render() {
    const {
      variant,
      statistics,
      width,
      height,
      title,
      marginLeft,
      color,
    } = this.props;

    let newData = statistics.map(datum => {
      return {...datum, title: variant === 'horizontal' ? `${datum.title} (${datum.value === null ? '0' : datum.value})` : `${datum.title}`};
    });

    const maxValue = Math.max(...statistics.map(d => d.value));

    let margins, svgDimensions, yScale, xScale;

    if (variant === 'horizontal') {
      newData = newData.reverse();
      margins = {top: 50, right: 20, bottom: 50, left: marginLeft ? marginLeft : 250};
      svgDimensions = {width: width, height: height || 400};

      yScale = scaleBand()
        .padding(0.4)
        .domain(newData.map(d => d.title))
        .range([svgDimensions.height - margins.bottom, margins.top]);

      xScale = scaleLinear()
        .domain([0, maxValue])
        .range([margins.left, svgDimensions.width - margins.right]);

    } else {
      margins = {top: 50, right: 20, bottom: 80, left: 60};
      if (isWidthUp('sm', this.props.width)) {
        svgDimensions = {width: width-320, height: height || 500};
      } else {
        svgDimensions = {width: width, height: height || 500};
      }

      xScale = scaleBand()
        .padding(0.5)
        .domain(newData.map(d => d.title))
        .range([margins.left, svgDimensions.width - margins.right]);

      yScale = scaleLinear()
        .domain([0, maxValue])
        .range([svgDimensions.height - margins.bottom, margins.top]);
    }

    return (
      <svg width={svgDimensions.width} height={svgDimensions.height}>
        <text
          x={svgDimensions.width/2}
          y={margins.top/1.5}
          fontFamily="Roboto"
          textAnchor="middle"
        >
          {title}
        </text>
        <Axes
          scales={{xScale, yScale}}
          margins={margins}
          svgDimensions={svgDimensions}
        />
        <Bars
          scales={{xScale, yScale}}
          margins={margins}
          data={newData}
          maxValue={maxValue}
          svgDimensions={svgDimensions}
          variant={variant}
          color={color}
        />
      </svg>
    );
  }
}

export default withWidth()(Statistics);
