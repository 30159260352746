import Chip from '@material-ui/core/Chip';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';

const MemberChip = ({member, onDelete, classes}) => {
  if (onDelete) {
    const onDelete = e => {
      e.preventDefault();
      onDelete(e);
    };
  }

  return (
    <Chip
      label={member.name}
      onDelete={onDelete}
      className={classes.chip}
    />
  );
};

const styles = {
  chip: {},
};

export default withStyles(styles)(MemberChip);
