import React, {Component} from 'react';
import {connect} from 'react-redux';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';

import {mapLabels} from '../../utils';
import DealOfferDetails from './DealOfferDetails';

class DealDetailsWrapper extends Component {
  state = {
    menuAnchor: null,
  };

  render() {
    const {
      onStatusChange,
      dealOffer,
      offerTypeLabels,
      propertyTypeLabels,
      onAdditionalChange,
      onPotentialEndChange,
      dealOfferStatuses,
      dealOfferStatusesAll,
    } = this.props;

    return (
      <>
        <CardHeader
          title={propertyTypeLabels[dealOffer.deal.propertyType] || dealOffer.deal.propertyType}
          subheader={offerTypeLabels[dealOffer.deal.offerType] || dealOffer.deal.offerType}
        />
        <Divider/>
        <DealOfferDetails
          dealOffer={dealOffer}
          onStatusChange={onStatusChange}
          onAdditionalChange={onAdditionalChange}
          onPotentialEndChange={onPotentialEndChange}
          dealOfferStatuses={dealOfferStatuses}
          dealOfferStatusesAll={dealOfferStatusesAll}
        />
      </>
    );
  }
}

const styles = () => ({
  avatar: {
    background: colors.green[300],
  },
});

export default connect(
  ({root}) => ({
    offerTypeLabels: mapLabels(root.classification.offerTypes, 'value', 'label'),
    propertyTypeLabels: mapLabels(root.classification.propertyTypes, 'value', 'label'),
    dealOfferStatuses: root.classification.dealOfferActiveStatuses,
    dealOfferStatusesAll: root.classification.dealOfferStatuses,
  })
)(withStyles(styles)(DealDetailsWrapper));
