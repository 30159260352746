import React, {Component} from 'react';
import PressReleaseList from './PressReleaseList';
import PressReleaseListItems from './PressReleaseListItems';
import {connect} from 'react-redux';



class PressReleaseListWrapper extends Component {

  render() {
    return (
      <PressReleaseList {...this.props}>
        <PressReleaseListItems/>
      </PressReleaseList>
    );
  }
}

export default PressReleaseListWrapper = connect(
  state => ({
    dealClose: state.root.pressRelease.pressRelease.deal,
    event: state.root.pressRelease.pressRelease.event,
    status: state.root.pressRelease.pressRelease.status,
  }),
  {}
)(PressReleaseListWrapper);
