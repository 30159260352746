import React, {Component} from 'react';
import ContractList from './ContractList';
import FavoritesWrapper from '../../is/favorites/containers/FavoritesWrapper';
import ContractListItems from './ContractListItems';

import {queryContractsAxios} from '../../queries/ContractsQuery';
import base64url from '../../utils/base64url';
import {getSection} from '../../utils/lastSection';

class ContractListWrapper extends Component {
  state = {
    contracts: [],
    error: false,
    loading: false,
    totalCount: null,
    query: {},
  };

  componentDidMount() {
    this.search(this.props.location);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.search(nextProps.location);
    }
  }

  search(location) {
    let query = {};
    let qs = location.search.substr(1);
    if (qs.length > 0) {
      try {
        query = JSON.parse(base64url.decode(qs));
      } catch (_) {}
    }
    if(!query.source){
      let source = getSection('contractSource');
      if(source)
        query.source = source;
    }
    this.setState({loading: true, query});
    queryContractsAxios(query)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            loading: false,
            error: false,
            contracts: resp.data.data.contracts.items,
            totalCount: resp.data.data.contracts.totalCount,
          });
        } else {
          this.setState({loading: false, error: true});
        }
      })
      .catch(() => {
        this.setState({loading: false, error: true});
      });
  }

  render() {
    const {contracts, loading, error, totalCount, query} = this.state;

    return (
      <ContractList
        contracts={contracts}
        loading={loading}
        error={error}
        totalCount={totalCount}
        query={query}
        location={this.props.location}
        match={this.props.match}
        history={this.props.history}
      >
        <FavoritesWrapper items={contracts.map(deal => deal.id)}>
          <ContractListItems />
        </FavoritesWrapper>
      </ContractList>
    );
  }
}

export default ContractListWrapper;
