import {Component} from 'react';
import {connect} from 'react-redux';

class Person extends Component {
  componentDidUpdate() {
    const {selfUser} = this.props;
    if (selfUser && selfUser.id) {
      window.location.href = '/members/' + selfUser.id;
    }
  }

  render() {
    return null;
  }
}

export default connect(state => ({
  selfUser: state.root.selfUser,
}), {})(Person);
