import axios from 'axios';
import React, {Component} from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {Field} from 'redux-form';
import Dropzone from 'react-dropzone';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';

const SortableItem = SortableElement(({classes, fieldName, index, onDelete, value, ...props}) =>
  <GridListTile cols={1} className={classes.item} {...props}>
    <img src={`/resized/crop_150x150/${value}.jpg`} alt="" style={{pointerEvents: 'none'}}/>
    <GridListTileBar actionIcon={
      <IconButton
        className={classes.removeButton}
        onClick={onDelete}
      >
        <icons.Close/>
      </IconButton>
    }/>
  </GridListTile>
);

const SortableList = SortableContainer(({classes, fieldName, photos, remove}) =>
  <GridList name={fieldName} cellHeight={150} cols={4}>
    {photos.map((value, index) => (
      <SortableItem
        key={index}
        index={index}
        classes={classes}
        value={value}
        onDelete={() => remove(fieldName, index)}
      />
    ))}
  </GridList>
);

class Photos extends Component {
  handleDrop = files => {
    const {push, name, photos} = this.props;
    if(!photos || (photos && photos.length < 1)){
      for (const file of files) {
        if (['image/jpeg', 'image/png'].indexOf(file.type) < 0) {
          continue;
        }
        axios.post(`/api/files/${encodeURIComponent(file.name)}`, file, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        })
          .then(resp => {
            if (resp.data.isImage) {
              push(name, resp.data.name);
            }
          });
      }
    }
  };

  photoComponent = ({input, meta}) => {
    return input.value && input.value.length > 0
      ? this.renderPhotos(input.value, meta)
      : <Typography variant="caption" style={{color: '#f44336'}}>{meta.error}</Typography>;
  };

  render() {
    const {classes, label, name} = this.props;
    return (
      <FormControl fullWidth margin="normal">
        <Typography variant="caption">{label} *</Typography>
        <Dropzone
          className={classes.dropzone}
          onDrop={this.handleDrop}
          name={name}
        >
          <Typography>
            Перетащите файлы в выделенную область или нажмите сюда для загрузки.
            <br/>
            Перед загрузкой нового файла удалите старый.
          </Typography>
        </Dropzone>
        <Field
          name={name}
          required
          component={this.photoComponent}
        />
      </FormControl>
    );
  }

  renderPhotos(photos, meta) {
    const {classes, name, remove} = this.props;
    return (
      <React.Fragment>
        <SortableList
          axis="xy"
          classes={classes}
          distance={5}
          fieldName={name}
          photos={photos}
          remove={remove}
          onSortEnd={this.onSortEnd}
        />
        {meta.error && !meta.dirty? <Typography variant="caption" style={{color: '#f44336'}}>{meta.error}</Typography>: null}
      </React.Fragment>
    );
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    if (oldIndex === newIndex) {
      return;
    }
    this.props.swap(this.props.name, oldIndex, newIndex);
  };
}

const styles = theme => ({
  dropzone: {
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    border: '1px dashed silver',
    textAlign: 'center',
  },
  item: {
    zIndex: theme.zIndex.modal + 1,
    display: 'block',
    userSelect: 'none',
  },
  removeButton: {
    color: 'white',
  },
});

export default withStyles(styles)(Photos);
