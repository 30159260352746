const defaultState = {
  screens: {},
};

export default (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'DEALS_LIST_SET_SCROLL_POSITION':
      return {
        ...state,
        screens: {
          ...state.screens,
          [payload.key]:{
            scrollPosition: payload.position,
          },
        },
      };
    default:
      return state;
  }
};
