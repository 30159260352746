import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import NetRenterContact from './NetRenterContact';
import NetRenterListWrapper from './NetRenterListWrapper';
import NetRenterView from './NetRenterView';
import {setTitle} from '../../actions';

class NetRenters extends Component {
  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route path={`${match.url}/`} exact component={NetRenterListWrapper}/>
        <Route path={`${match.url}/:itemId/contact`} exact component={NetRenterContact}/>
        <Route path={`${match.url}/:itemId`} component={NetRenterView}/>
      </Switch>
    );
  }
}

export default connect(null, {setTitle})(NetRenters);
