import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Select} from 'redux-form-material-ui';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import TextField from '../../../components/Input/TextField';
import Variant from '../../../components/Input/Variant';
import DatePicker from '../../../components/Input/DatePicker';
import {getLabelsForValue} from '../../../utils';

class FilterDealOffers extends Component {
  render() {
    const {
      dealOfferActiveStatuses,
      handleSubmit,
      submitting,
      offerTypes,
      onClose,
      onSubmit,
      open,
      pristine,
      propertyPurposes,
      propertyTypes,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Настройки фильтра</DialogTitle>
        <Divider/>
        <DialogContent>
          <Field
            name="searchQuery"
            label="Поисковый запрос"
            component={TextField}
            fullWidth
            margin="normal"
          />
          <Field
            name="statuses"
            label="Статусы"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(dealOfferActiveStatuses, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {dealOfferActiveStatuses.map(({label, value}, index) => (
              <Variant key={index} value={value} label={label}/>
            ))}
          </Field>
          <FormControl fullWidth margin="normal">
            <InputLabel>Вид сделки</InputLabel>
            <Field name="type" component={Select}>
              <MenuItem value=""/>
              {offerTypes.map(({value, label}, i) => (
                <MenuItem value={value} key={i}>{label}</MenuItem>
              ))}
            </Field>
          </FormControl>
          <Field
            name="propertyTypes"
            label="Категория"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(propertyTypes, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {propertyTypes.map(({label, value}, index) => (
              <Variant key={index} value={value} label={label}/>
            ))}
          </Field>
          <Field
            name="propertyPurposes"
            label="Назначение"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(propertyPurposes, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {propertyPurposes.map(({label, value}, index) => (
              <Variant key={index} value={value} label={label}/>
            ))}
          </Field>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="dealStartFrom"
                  label="Создана с"
                  format={null}
                  component={props => (
                    <DatePicker
                      format="yyyy-MM-dd"
                      clearable
                      {...props}
                    />
                  )}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="dealStartTo"
                  label="по"
                  format={null}
                  component={props => (
                    <DatePicker
                      format="yyyy-MM-dd"
                      clearable
                      {...props}
                    />
                  )}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="dealEndFrom"
                  label="Закрыта с"
                  format={null}
                  component={props => (
                    <DatePicker
                      format="yyyy-MM-dd"
                      clearable
                      {...props}
                    />
                  )}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="dealEndTo"
                  label="по"
                  format={null}
                  component={props => (
                    <DatePicker
                      format="yyyy-MM-dd"
                      clearable
                      {...props}
                    />
                  )}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(onSubmit)}
          >
            Применить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

FilterDealOffers = connect(
  state => ({
    dealOfferActiveStatuses: state.root.classification.dealOfferActiveStatuses,
    offerTypes: state.root.classification.offerTypes,
    propertyTypes: state.root.classification.propertyTypes,
    propertyPurposes: state.root.classification.propertyPurposes,
    teamMembers: state.root.selfUser.teamMembers,
  })
)(FilterDealOffers);

export default reduxForm({
  form: 'filter_dealOffers',
  enableReinitialize: true,
})(FilterDealOffers);
