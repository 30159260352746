import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import OfferListWrapper from './OfferListWrapper';
import OfferOrigin from './OfferOrigin';
import OfferAssignees from './OfferAssignees';
import OfferView from './OfferView';
import {setTitle} from '../../actions';
import OfferDeals from './OfferDeals';

class Offer extends Component {
  componentDidMount() {
    this.props.setTitle('Листинги');
  }

  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route path={`${match.url}/`} exact component={OfferListWrapper}/>
        <Route path={`${match.url}/:offerId/origin`} exact component={OfferOrigin}/>
        <Route path={`${match.url}/:offerId/assignees`} exact component={OfferAssignees}/>
        <Route path={`${match.url}/:offerId/deals`} exact component={OfferDeals}/>
        <Route path={`${match.url}/:offerId`} component={OfferView}/>
      </Switch>
    );
  }
}

export default connect(null, {setTitle})(Offer);
