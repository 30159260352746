import React, {Component} from 'react';
import * as axios from 'axios';

import EntityTaskList from '../is/tasks/containers/EntityTaskList';
import {queryInFavorites} from '../queries/FavoritesQuery';
import EditableNote from './EditableNote';
import RemoveFavorite from '../is/favorites/components/RemoveFavorite';

class MiddleColumn extends Component {
  state = {
    isFavorite: false,
    addNote: false,
    note: '',
    removeFavorite: null,
    favoriteId: null,
  };

  componentDidMount() {
    this.getInFavorites();
  }

  getInFavorites = () => {
    queryInFavorites({ids: [this.props.entityId]})
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          if (resp.data.data.inFavorites.items.length) {
            this.setState({
              isFavorite: true,
              favoriteId: resp.data.data.inFavorites.items[0].id,
              note: resp.data.data.inFavorites.items[0].note,
            });
          } 
        }
      });
  };

  toggleFavorite = id => {
    if (this.state.isFavorite) {
      this.setState({removeFavorite: {id: this.state.favoriteId}});
    } else {
      this.addToFavorites(id);
    }
  };

  addToFavorites = (id, note, successFunc) => {
    this.setState({isFavorite: !this.state.isFavorite});
    let data = {
      entityId: id,
      entityType: `Arr\\Is\\Entity\\${this.props.entityName}`,
      note: note,
    };
    axios.post('/api/v1/favorites', data)
      .then(response => {
        if(response.status === 200) {
          this.setState({favoriteId: response.data.id, addNote: false});
          if(successFunc) {
            successFunc();
          }
        } else {
          this.setState({isFavorite: !this.state.isFavorite});
        }
      })
      .catch(err => {
        if (err.response.status === 409) {
          alert('Сущность уже в избранном');
        } else {
          alert('Произошла ошибка');
        }
      });
  };

  removeFromFavorites = id => {
    this.setState({isFavorite: !this.state.isFavorite});
    axios.delete('/api/v1/favorites', {data: {id}})
      .then(response => {
        if(response.status === 200) {
          this.setState({note: '', favoriteId: null});
        } else {
          this.setState({isFavorite: !this.state.isFavorite});
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          alert('Запись не найдена');
        }
        alert('Произошла ошибка');
      });
  };

  editNote = (entityId, nextNote, prevNote, successFunc) => {
    this.setState({note: nextNote});
    if (this.state.isFavorite) {
      axios.put('/api/v1/favorites/note', {id: this.state.favoriteId, note: nextNote})
        .catch(error => {
          if (error.response.status === 404) {
            alert('Запись не найдена');
          } else {
            alert('Произошла ошибка');
          }
          this.setState({note: prevNote});
        })
        .then(() => {
          this.setState({addNote: false});
        });
    } else {
      this.addToFavorites(entityId, nextNote, successFunc);
    }
  };

  render() {
    const {entityId, entityName, children} = this.props;
    const {note, isFavorite, addNote, removeFavorite} = this.state;
    return (
      <div className="content" style={{padding: 16}}>
        <EntityTaskList
          entity={{
            type: `Arr\\Is\\Entity\\${entityName}`,
            id: entityId,
          }}
          note={note}
          isFavorite={isFavorite}
          addNote={() => this.setState({addNote: true})}
          toggleFavorite={id => this.toggleFavorite(id)}
        />
        {note || addNote ? (
          <EditableNote
            note={note}
            onSave={(newNote, successFunc) => this.editNote(entityId, newNote, note, successFunc)}
            onCancel={() => this.setState({addNote: false})}
          />
        ) : null}
        {children}
        {removeFavorite? (
          <RemoveFavorite
            open={Boolean(removeFavorite)}
            initialValues={removeFavorite}
            onSubmitted={() => {
              this.setState({removeFavorite: null, note: ''});
            }}
            changeState={() => {
              this.setState({isFavorite: !this.state.isFavorite});
            }}
            onClose={() => this.setState({removeFavorite: null})}
          />
        ) : null}
      </div>
    );
  }
}

export default MiddleColumn;
