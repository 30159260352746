import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';

import {formatDate} from '../../../utils';
import Variant from '../../../components/Input/Variant';
import SnackbarSuccess from '../../../components/SnackbarSuccess';
import SnackbarError from '../../../components/SnackbarError';
import ShortPagination from '../../../components/ShortPagination';
import {loadDealSelections, changePageSelection} from '../../../actions';
import {Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core';

class Selection extends Component {
  state = {
    error: null,
    loading: false,
    anchorEl: null,
    alertShow: false,
    alertMessage: '',
    errorShow: false,
    errorMessage: 'Неизвестная ошибка сервера',
    openSelection: false,
    selectionItem: null,
    selfSend: false,
    openSendEmail: false,
  };

  perPage = 5;

  componentDidMount(){
    const {loadDealSelections, match: {params: {dealId}}, page} = this.props;
    loadDealSelections({
      filter: {deal: dealId},
      offset: (page - 1) * this.perPage,
      limit: this.perPage,
    });
  }

  componentWillReceiveProps(newProps){
    if(newProps.page !== this.props.page){
      const {loadDealSelections, match: {params: {dealId}}, page} = newProps;
      loadDealSelections({
        filter: {deal: dealId},
        offset: (page - 1) * this.perPage,
        limit: this.perPage,
      });
    }
  }

  handleOpenMenu = (target, selection) => {
    this.setState({anchorEl: {target, selection}});
  };

  handleCloseMenu = () => {
    this.setState({anchorEl: null});
  };

  sendMail = item => {
    axios
      .post('/api/v1/sendDealOffersSelectionEmail', {id: parseInt(item.id), blindCopy: this.state.selfSend})
      .then(() => {
        this.setState({
          alertShow: true,
          alertMessage: 'Подборка успешно отправлена на почту',
          anchorEl: null,
          openSendEmail: false,
        });
        this.props.updateActivity && this.props.updateActivity();
      })
      .catch(error => {
        if(error.response && error.response.status === 400)
          this.setState({errorShow: true, errorMessage: error.response.data.error});
        else
          this.setState({errorShow: true, errorMessage: 'Неизвестная ошибка сервера'});
      });
  };

  openPDF = item => {
    window.open(`/api/public/selection/${item.secret}`, `/api/public/selection/${item.secret}`);
    this.handleCloseMenu();
  };

  downloadPDF = item => {
    let link = document.createElement('a');
    link.setAttribute('href', `/api/public/selection/${item.secret}`);
    link.setAttribute('download', formatDate(item.createdAt));
    link.click();
    this.handleCloseMenu();
  };

  sharePDF = item => {
    let link = document.createElement('span');
    link.style.width = 0;
    link.style.height = 0;
    link.innerText = `${window.location.origin}/api/public/selection/${item.secret}`;
    document.body.appendChild(link);
    var range = document.createRange();
    range.selectNode(link);
    let selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    link.remove();
    this.setState({
      alertShow: true,
      alertMessage: 'Ссылка скопирована в буфер обмена',
      anchorEl: null,
    });
  };

  openSelection = item => {
    const {history, match: {params: {dealId}}} = this.props;
    history.push(`/deals/${dealId}/selections/${item.id}/`);
  };

  getWordEnding(number) {
    if ((number % 100 >= 5 && number % 100 <= 20) || number % 10 === 0) {
      return 'ов';
    }
    if (number % 10 === 1) {
      return '';
    }
    return 'а';
  }

  render() {

    const {
      selections,
      totalCount,
      changePageSelection,
      page,
    } = this.props;
    const {
      anchorEl,
      alertShow,
      alertMessage,
      errorMessage,
      selfSend,
      errorShow,
      openSendEmail,
    } = this.state;

    if(totalCount === 0)
      return null;

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Подборки ({totalCount})
        </ListSubheader>
      }>
        {(selections || []).map((item, key) =>
          <ListItem key={key} dense button onClick={() => this.openSelection(item)}>
            <Avatar style={{backgroundColor: colors.green[300]}}>
              <icons.PlaylistAddCheck/>
            </Avatar>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body1">
                  {item.createdBy.name}
                </Typography>
              }
              secondary={
                <React.Fragment>
                  <Typography variant="caption" style={{alignItems: 'center', display: 'flex'}} noWrap>
                    {`№${item.id}, ${formatDate(item.updatedAt)}, ${item.offers.length} листинг${this.getWordEnding(item.offers.length)}`}
                    {item.emailAt ?
                      <icons.Email titleAccess={'Отправлена на почту'} style={{fontSize: '0.9rem', color: colors.green[300], marginLeft: 1}}/>
                      : null}
                  </Typography>

                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <IconButton onClick={e => this.handleOpenMenu(e.currentTarget, item)}>
                <icons.MoreVert/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )}
        <Menu
          anchorEl={anchorEl && anchorEl.target}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={() => this.setState({openSendEmail: true})}>
            Отправить на почту
          </MenuItem>
          <MenuItem onClick={() => this.openPDF(anchorEl.selection)}>
            Открыть PDF
          </MenuItem>
          <MenuItem onClick={() => this.downloadPDF(anchorEl.selection)}>
            Скачать PDF
          </MenuItem>
          <MenuItem onClick={() => this.sharePDF(anchorEl.selection)}>
            Поделиться
          </MenuItem>
        </Menu>
        <Dialog open={openSendEmail}>
          <DialogTitle>
            Отправить на почту
          </DialogTitle>
          <DialogContent>
            <Variant
              selected={selfSend}
              label={'Скрытая копия письма себе'}
              onChange={e => {
                this.setState({
                  selfSend: e.target.checked,
                });
              }}
              onClick={() => {
                this.setState({
                  selfSend: !this.state.selfSend,
                });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              type="button"
              onClick={() => this.sendMail(anchorEl.selection)}
            >
            Отправить
            </Button>
            <Button
              type="button"
              onClick={() => this.setState({openSendEmail: false})}
            >
            Отменить
            </Button>
          </DialogActions>
        </Dialog>
        <SnackbarSuccess
          open={alertShow}
          onClose={() => this.setState({alertShow: null})}
          message={alertMessage}
        />
        <SnackbarError
          open={errorShow}
          onClose={() => this.setState({errorShow: null})}
          errorMessage={errorMessage}
        />
        <ShortPagination
          page={page}
          totalCount={totalCount}
          perPage={this.perPage}
          handleChangePage={changePageSelection}
        />
      </List>
    );
  }
}

Selection = connect(
  state => ({
    totalCount: state.root.selection.selectionStatus.totalCount,
    page: state.root.selection.selectionStatus.page,
    loading: state.root.selection.selectionStatus.loading,
  }),
  {loadDealSelections, changePageSelection}
)(Selection);

export default withRouter(Selection);
