import React, {Component} from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import {set} from 'lodash';
import axios from 'axios';
import TextField from '../../../components/Input/TextField';
import {mapLabelsBack} from '../../../utils';
import MenuItem from '@material-ui/core/MenuItem';
import {connect} from 'react-redux';
import {archivedOffer} from '../../../actions';

class ArchiveOfferDialog extends Component {
  render() {
    const {
      open,
      onClose,
      pristine,
      submitting,
      handleSubmit,
      offerSourceLabels,
      offerArchiveReasonLabels,
    } = this.props;

    const offerSources = mapLabelsBack(offerSourceLabels);
    const offerArchiveReasons = mapLabelsBack(offerArchiveReasonLabels);

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Закрытие листинга</DialogTitle>
        <Divider/>
        <DialogContent>
          <Field
            name="source"
            label="Источник"
            required
            component={TextField}
            select
            fullWidth
            margin="normal"
          >
            {offerSources.map(({value, label}, i) => (
              <MenuItem value={value} key={i}>{label}</MenuItem>
            ))}
          </Field>
          <Field
            name="status"
            label="Статус"
            required
            component={TextField}
            select
            fullWidth
            margin="normal"
            disabled
          >
            <MenuItem value="archived">Архивированный</MenuItem>
          </Field>
          <Field
            name="archiveReason"
            label="Причина архивации"
            required
            component={TextField}
            select
            fullWidth
            margin="normal"
          >
            {offerArchiveReasons.map(({value, label}, i) => (
              <MenuItem key={i} value={value}>{label}</MenuItem>
            ))}
          </Field>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(this.submit)}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = data => {
    const {offer} = this.props;
    const {source, archiveReason} = data;

    return axios.post(`/api/offers/${offer.id}/archive`, {
      id: offer.id,
      source,
      archiveReason,
    })
      .then(res => {
        if (res.status === 200) {
          this.props.archivedOffer(offer.id, offer.status, offer.origin, source, archiveReason);
          this.props.onSubmit();
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(error.response.data.error.errors.reduce((errors, {propertyPath, message}) => {
            return set(errors, propertyPath, message);
          }, {}));
        } else {
          alert('Ошибка при сохранении');
        }
      });
  };
}

ArchiveOfferDialog = connect(
  null,
  {
    archivedOffer,
  },
)(ArchiveOfferDialog);

export default reduxForm({
  form: 'archive_offer',
  enableReinitialize: true,
})(ArchiveOfferDialog);
