import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {formatDate, mapLabels} from '../../../utils';
import TableLink from '../../../components/TableLink';
import axios from 'axios';

class OfferWithoutDealTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statistics: props.statistics,
    };
  }

  onIgnoreExportsChangeResult = (idx, res) => {
    if (res && res.data && res.data.ignoreExports) {
      this.setState(state => {
        state.statistics[idx].ignoreExports = res.data.ignoreExports;
        state.statistics[idx].exportAvitoTimer = res.data.exportAvitoTimer;
        return {statistics: state.statistics};
      });
    }
  };

  handleIgnoreExportsChange = (idx, source, sourceIndex) => {
    if (!this.state.statistics[idx]) return;

    let item = JSON.parse(JSON.stringify(this.state.statistics[idx]));

    if (sourceIndex > -1) {
      item.ignoreExports.splice(sourceIndex, 1);
    } else {
      item.ignoreExports.push(source);
    }

    this.send({
      id: item.id,
      ignoreExports: item.ignoreExports,
    }).then(res => this.onIgnoreExportsChangeResult(idx, res));
  };

  handleAvitoTimerReset = idx => {
    if (!this.state.statistics[idx]) return;

    let item = JSON.parse(JSON.stringify(this.state.statistics[idx]));

    this.send({
      id: item.id,
      ignoreExports: item.ignoreExports,
      avitoTimerReset: true,
    }).then(res => this.onIgnoreExportsChangeResult(idx, res));
  };

  send = data => {
    return axios.post('/api/v1/setIgnoreExportsOfferAction', data)
      .catch(error => {
        if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };

  render() {
    const {
      classes,
      title,
      handleClickOpen,
      titleClass,
      getHref,
      platformManagement,
    } = this.props;

    let {offerExports} = this.props;

    offerExports = {
      avito: 'avito.ru',
      cian: 'cian.ru',
      yandex: 'realty.yandex.ru',
      ...offerExports,
    };

    const offerExportsList = Object.keys(offerExports);
    const total = {avito: 0, cian: 0, yandex: 0, domclick: 0};
    return (
      <React.Fragment>
        <Typography
          variant="subtitle1"
          className={titleClass}
        >
          {title}
        </Typography>
        <Paper
          className={classes.root}
          style={{
            overflowX: 'auto',
            marginBottom: 104,
          }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow classes={{root: classes.tableRow}}>
                <TableCell className={classes.addressColumn}>Адрес</TableCell>
                {offerExportsList.map((source, i) => (
                  <TableCell key={'header' + i} className={classes.tableHead}>{offerExports[source]}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.statistics.map((item, i) => (
                <TableLink
                  key={i}
                  href={getHref(item.id)}
                  className={classes.tableRow}
                  hover
                  onClick={() => handleClickOpen(item.id)}
                >
                  <TableCell
                    className={classes.addressColumn}
                    title={item.address}
                  >
                    {item.address}
                  </TableCell>
                  {offerExportsList.map((source, j) => {
                    const sourceIndex = item.ignoreExports.indexOf(source);
                    const isExportWaiting = source === 'avito' && item.exportAvitoTimer === 'true';
                    let exportLabel = 'Выгружается';
                    if (source === 'avito' && item.exportAvitoTimer && item.exportAvitoTimer !== 'true') {
                      exportLabel += ' до ' + formatDate(item.exportAvitoTimer);
                    }
                    if (sourceIndex < 0 && typeof total[source] !== 'undefined') {
                      total[source]++;
                    }
                    return (
                      <TableCell
                        key={i + '' + j}
                        className={
                          (platformManagement ? classes.sourceExportedLabel : classes.sourceLabel)
                          + ` ${sourceIndex > -1 ? (isExportWaiting ? 'pausing' : 'disabled') : ''}`
                        }
                        title={
                          `${offerExports[source]} - ${sourceIndex > -1
                            ? (isExportWaiting ? 'На паузе' : 'Не выгружается') : exportLabel
                          }`
                        }
                        onClick={e => {
                          if (!platformManagement) return;
                          e.stopPropagation();
                          if (isExportWaiting) {
                            this.handleAvitoTimerReset(i);
                            return;
                          }
                          this.handleIgnoreExportsChange(i, source, sourceIndex);
                        }}
                      >&#9679;</TableCell>
                    );
                  })}
                </TableLink>
              ))}
              <TableRow>
                {this.state.statistics.length >= 1000 ?
                  <React.Fragment>
                    <TableCell>Список ограничен 1000 строк</TableCell>
                    <TableCell className={classes.tableHead}>{total.avito}</TableCell>
                    <TableCell className={classes.tableHead}>{total.cian}</TableCell>
                    <TableCell className={classes.tableHead}>{total.yandex}</TableCell>
                    <TableCell className={classes.tableHead}>{total.domclick}</TableCell>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <TableCell>Всего: {this.state.statistics.length}</TableCell>
                    <TableCell className={classes.tableHead}>{total.avito}</TableCell>
                    <TableCell className={classes.tableHead}>{total.cian}</TableCell>
                    <TableCell className={classes.tableHead}>{total.yandex}</TableCell>
                    <TableCell className={classes.tableHead}>{total.domclick}</TableCell>
                  </React.Fragment>
                }
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  table: {
    background: 'white',
  },
  root: {
    margin: '0 20px',
  },
  addressColumn: {
    maxWidth: 200,
  },
  tableRow: {
    height: 37,
  },
  tableHead: {
    textAlign: 'center',
  },
  sourceLabel: {
    color: '#607d8b',
    fontSize: 28,
    marginBottom: 10,
    textAlign: 'center',
    '&.disabled': {
      color: '#ccc',
    },
    '&.pausing': {
      color: '#f9a825',
    },
  },
  sourceExportedLabel: {
    color: '#607d8b',
    fontSize: 28,
    marginBottom: 10,
    textAlign: 'center',
    cursor: 'pointer',
    '&.disabled': {
      color: '#ccc',
    },
    '&.pausing': {
      color: '#f9a825',
    },
    '&:hover': {
      color: '#1175f6',
    },
  },
});

export default connect(
  state => ({
    offerExports: mapLabels(state.root.classification.offerExports, 'value', 'label'),
    selfUser: state.root.selfUser,
  })
)(withStyles(styles)(OfferWithoutDealTable));
