import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import MemberListItem from '../../../components/relations/MemberListItem';

class Member extends Component {
  state = {
    openMenu: false,
    removeAssignee: false,
  };

  render() {
    const {
      pressRelease,
    } = this.props;

    if (!pressRelease) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Создатель
        </ListSubheader>
      }>
        {pressRelease.member ?
          <MemberListItem
            member={pressRelease.member}
          />
          : null}
      </List>
    );
  }
}

Member = connect(
  state => ({
    members: state.root.team,
  }),
  {}
)(Member);

export default withRouter(Member);
