import React, {Component} from 'react';
import {isEmpty} from 'lodash';
import {connect} from 'react-redux';
import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import InputBase from '@material-ui/core/InputBase';

import ContractEdit from './ContractEdit';
import base64url from '../../utils/base64url';
import AccessComponent from '../../components/AccessComponent';
import FilterContracts from './form/FilterContracts';
import {setSection} from '../../utils/lastSection';

class ContractList extends Component {
  state = {
    searchInput: '',
    openAdd: false,
    openFilterDialog: false,
    popoverContent: null,
  };

  componentDidMount() {
    let query = this.props.location.search.substr(1);
    if (query) {
      const data = JSON.parse(base64url.decode(query));
      if (data.filter) {
        this.setState({searchInput: data.filter.searchQuery});
      }
    }
  }

  render() {
    const {searchInput, openAdd, openFilterDialog} = this.state;
    const {
      classes,
      history,
      action,
      selectedItems,
      query,
      contracts,
      totalCount,
      loading,
      error,
    } = this.props;

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        query,
        history,
        contracts,
        entityId: 'contractId',
        entityType: 'Contract',
        action: action ? action : null,
        selectedItems,
        totalCount,
        handleRequestSort: e => this.handleRequestSort(e),
        handleChangePage: (e, page) => this.handleChangePage(e, page),
      })
    );

    return (
      <React.Fragment>
        <div key={0} className="row">
          <div
            className="column"
            style={{background: colors.grey[200], flex: 2}}
          >
            <Toolbar className="toolbar">
              <AccessComponent manager>
                <Select
                  value={query.source || 'ASSIGNED'}
                  input={<Input />}
                  onChange={this.handleChangeSource}
                >
                  <MenuItem value="ASSIGNED">Мои договоры</MenuItem>
                  <MenuItem value="TEAM">Договоры команды</MenuItem>
                </Select>
              </AccessComponent>
              <div className={classes.flex} />
              <Hidden xsDown>
                <InputBase
                  className={classes.input}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                  onKeyDown={this.handleSearchEnter}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  disabled={!searchInput}
                >
                  <icons.Search />
                </IconButton>
              </Hidden>
              {!isEmpty(query.filter) ? (
                <IconButton disabled={loading} onClick={this.handleReset}>
                  <icons.Clear />
                </IconButton>
              ) : null}
              <IconButton
                className={classes.rightIcon}
                disabled={loading}
                color={!isEmpty(query.filter) ? 'primary' : 'default'}
                onClick={() => this.setState({openFilterDialog: true})}
              >
                <icons.FilterList />
              </IconButton>
            </Toolbar>
            <Hidden smUp>
              <Toolbar>
                <InputBase
                  classes={{
                    root: classes.searchInputRoot,
                    input: classes.searchInput,
                  }}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  className={classes.rightIcon}
                  disabled={!searchInput}
                >
                  <icons.Search />
                </IconButton>
              </Toolbar>
            </Hidden>
            <Divider />
            <div className="content">
              {loading ? (
                <CircularProgress
                  size={50}
                  thickness={4}
                  className={classes.progress}
                />
              ) : error ? (
                <icons.ErrorOutline className={classes.progress} />
              ) : contracts ? (
                childrenWithProps
              ) : null}
            </div>
            <Fab
              color="secondary"
              className={classes.fab}
              onClick={() => this.setState({openAdd: true})}
            >
              <icons.Add />
            </Fab>
          </div>
        </div>
        {openAdd ? (
          <ContractEdit
            open={openAdd}
            onSubmitted={({id}) => history.push(`/contracts/${id}`)}
            onClose={() => this.setState({openAdd: false})}
          />
        ) : null}
        {openFilterDialog ? (
          <FilterContracts
            open={openFilterDialog}
            initialValues={{
              startAtFrom: null,
              startAtTo: null,
              endAtFrom: null,
              endAtTo: null,
              ...query.filter,
            }}
            onClose={() => this.setState({openFilterDialog: false})}
            onSubmit={filter => {
              this.handleFilter(filter);
              this.setState({openFilterDialog: false});
            }}
          />
        ) : null}
      </React.Fragment>
    );
  }

  handleFilter = filter => {
    const {query, match, history} = this.props;
    this.setState({openFilterDialog: false});
    const path =
      match.url +
      '?' +
      base64url.encode(JSON.stringify({source: query.source, filter}));
    history.push(path);
    this.setState({searchInput: filter.searchQuery});
  };

  handleReset = () => {
    const {history, match, query} = this.props;
    if (query.source) {
      const path =
        match.url +
        '?' +
        base64url.encode(JSON.stringify({source: query.source}));
      history.push(path);
    } else {
      history.push(match.url);
    }
    this.setState({searchInput: ''});
  };

  handleChangeSource = e => {
    const {history, match, query} = this.props;
    const path =
      match.url +
      '?' +
      base64url.encode(
        JSON.stringify({...query, source: e.target.value, offset: 0})
      );
    history.push(path);
    setSection('contractSource', e.target.value);
  };

  handleChangePage = (_, page) => {
    const {history, match, query} = this.props;
    const path =
      match.url +
      '?' +
      base64url.encode(
        JSON.stringify({...query, offset: page * 30, limit: 30})
      );
    history.push(path);
  };

  handlePopoverOpen = (e, text) => {
    e.stopPropagation();
    this.setState({anchorEl: e.currentTarget.parentNode, popoverContent: text});
  };

  handlePopoverClose = () => {
    this.setState({anchorEl: null, popoverContent: null});
  };

  handleSearchChange = e => {
    this.setState({searchInput: e.target.value});
  };

  handleSearchEnter = e => {
    if (e.key === 'Enter') {
      this.handleSearchInit();
    }
  };

  handleSearchInit = () => {
    const {history, match, query} = this.props;
    const path =
      match.url +
      '?' +
      base64url.encode(
        JSON.stringify({
          ...query,
          filter: {...query.filter, searchQuery: this.state.searchInput},
          offset: 0,
        })
      );
    history.push(path);
  };

  handleRequestSort = property => {
    const {order} = this.props.query;

    if (order && order.column === property && order.direction === 'ASC') {
      this.getSortParams(property, 'DESC');
    } else if (
      order &&
      order.column === property &&
      order.direction === 'DESC'
    ) {
      this.getSortParams();
    } else {
      this.getSortParams(property, 'ASC');
    }
  };

  getSortParams = (orderBy, order) => {
    const {history, match, query} = this.props;

    let queryOrder = {
      column: orderBy,
      direction: order,
    };

    let newQuery;
    if (!orderBy && !order) {
      newQuery = {...query};
      delete newQuery.order;
    } else {
      newQuery = {...query, order: queryOrder};
    }
    const path =
      match.url +
      '?' +
      base64url.encode(JSON.stringify({...newQuery, offset: 0}));
    history.push(path);
  };
}

const styles = theme => ({
  flex: {
    flex: 1,
  },
  rightIcon: {
    marginRight: -12,
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  searchInputRoot: {
    width: '100%',
  },
  searchInput: {
    width: '100%',
  },
});

export default connect(
  null,
  {}
)(withStyles(styles)(ContractList));
