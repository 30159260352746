import * as colors from '@material-ui/core/colors/index';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {withStyles} from '@material-ui/core/styles';
import DatePicker from '../../../components/Input/DatePicker';
import TextFieldCount from '../../../components/Input/TextFieldWithCount';
import CityInput from '../../../components/Input/CityInput';
import Photos from './Photos';
import {queryCities} from '../../../queries/CityQuery';

class PublicDeal extends Component {
  render() {
    const {
      array: {push, remove, swap},
      array,
      photos,
      change,
      publicDeal,
      teams,
      cityHint,
    } = this.props;

    return (
      <React.Fragment>
        <Field
          component={CityInput}
          name="city"
          label="Город"
          required
          change={change}
          placeholder="Введите название города"
          hint={teams[0].cities || cityHint}
          query={queryCities}
          initializeValue={publicDeal && publicDeal.city ? publicDeal.city.id : null}
          useObject
        />
        <Field
          name="date"
          label="Дата сделки"
          required
          format={null}
          component={props => (
            <DatePicker format="yyyy-MM-dd" clearable {...props} />
          )}
          fullWidth
          rowsMax={15}
          margin="normal"
          value={publicDeal && publicDeal.date ? publicDeal.date : null}
        />
        <Field
          name="description"
          label="Описание"
          required
          multiline
          component={TextFieldCount}
          width={'100%'}
          max={250}
          fullWidth
          min={10}
          margin="normal"
        />
        <Photos
          name="photos"
          label="Фотография"
          array={array}
          photos={photos}
          push={(name, value) => push(name, value)}
          remove={(name, value) => remove(name, value)}
          swap={(name, oldIndex, newIndex) => swap(name, oldIndex, newIndex)}
        />
      </React.Fragment>
    );
  }
}

const styles = {
  help: {
    color: colors.grey[700],
    marginLeft: 12,
    marginBottom: 8,
  },
  MenuItemContainer: {
    zIndex: 1,
    position: 'absolute',
    width:'100%',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  MenuItemWrapper: {
    position: 'relative',
  },
};

export default connect(state => ({
  teams: state.root.selfUser.teams,
  cityHint: Object.values(state.root.hint.city),
}),{})(withStyles(styles)(PublicDeal));
