import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import {formatDate} from '../../../utils';
import MemberListItem from '../../../components/relations/MemberListItem';

class Member extends Component {
  state = {
    openMenu: false,
    removeAssignee: false,
  };

  render() {
    const {
      publicDeal,
    } = this.props;

    if (!publicDeal) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Создатель
        </ListSubheader>
      }>
        {publicDeal.member ?
          <MemberListItem
            member={publicDeal.member}
          />
          : null}

        {publicDeal.createdAt ?
          <ListItem dense>
            <ListItemText
              primary={`${formatDate(publicDeal.createdAt)}`}
              secondary="Дата создания"
            />
          </ListItem>
          : null}
        {publicDeal.updatedAt ?
          <ListItem dense>
            <ListItemText
              primary={`${formatDate(publicDeal.updatedAt)}`}
              secondary="Дата изменения"
            />
          </ListItem>
          : null}
      </List>
    );
  }
}

export default withRouter(Member);
