const defaultState = {
  city:{},
  district:{},
  subDistrict:{},
};

export default (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'SET_CITY_HINT':{
      if(state.city[payload.id])
        return state;
      else{
        let keys = Object.keys(state.city);
        if(keys.length >= 5)
          delete state.city[keys[0]];
        return {
          ...state,city:{...state.city, [payload.id]:payload},
        };
      }
    }
    case 'SET_DISTRICT_HINT':{
      if(state.district[payload.cityId] && state.district[payload.cityId][payload.district.id])
        return state;
      else{
        let keys = Object.keys(state.district);
        if(keys.length>=5)
          delete state.district[keys[0]];
        keys = state.district[payload.cityId] ? Object.keys(state.district[payload.cityId]) : [];
        if(keys.length >= 5)
          delete state.district[payload.cityId][keys[0]];
        return {
          ...state,
          district:{
            ...state.district,
            [payload.cityId]:{...state.district[payload.cityId], [payload.district.id]:{...payload.district}},
          },
        };
      }
    }
    case 'SET_SUB_DISTRICT_HINT':{
      if(state.subDistrict[payload.districtId] && state.subDistrict[payload.districtId][payload.subDistrict.id])
        return state;
      else{
        let keys = Object.keys(state.subDistrict);
        if(keys.length>=5)
          delete state.subDistrict[keys[0]];
        keys = state.subDistrict[payload.districtId] ? Object.keys(state.subDistrict[payload.districtId]) : [];
        if(keys.length>=5)
          delete state.subDistrict[payload.districtId][keys[0]];
        return{
          ...state,
          subDistrict:{
            ...state.subDistrict,
            [payload.districtId]:{
              ...state.subDistrict[payload.districtId], [payload.subDistrict.id] : {...payload.subDistrict},
            },
          },
        };
      }
    }
    default:
      return state;
  }
};