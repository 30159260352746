import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import * as icons from '@material-ui/icons';

import RemoveRelationship from '../form/RemoveRelationship';
import {deleteRelationshipFromContact} from '../../../actions';
import ContactRelationshipListItem from '../../../components/relations/ContactRelationshipListItem';

class Relationships extends Component {
  state = {
    openMenu: false,
    removeRelationship: false,
  };

  render() {
    const {
      deleteRelationshipFromContact,
      contact,
      contacts,
      history,
      handleDialogOpen,
    } = this.props;
    const {openMenu, removeRelationship} = this.state;

    if (!contact) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Связанные контакты
          <ListItemSecondaryAction>
            <IconButton onClick={() => history.push(`/contacts/${contact.id}/relationships`)}>
              <icons.Add/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {contact.relationships ? contact.relationships.map((relationship, index) => {
          const contact = contacts[relationship.id];
          return contact ? (
            <ContactRelationshipListItem  
              key={index}
              contact={contact}
              relationship={relationship}
              onSecondaryActionClick={e => this.setState({openMenu: {target: e.currentTarget, relationship}})}
              handleDialogOpen={e => handleDialogOpen(e)}
            />
          ) : null;
        }) : null}
        {openMenu ? (
          <Menu
            anchorEl={openMenu.target}
            open={Boolean(openMenu)}
            onClick={() => this.setState({openMenu: false})}
          >
            <MenuItem onClick={() => {
              this.setState({
                removeRelationship: {
                  contactId: contact.id,
                  relationshipId: openMenu.relationship.id,
                  role: openMenu.relationship.role,
                },
              });
            }}>
              Удалить связь
            </MenuItem>
          </Menu>
        ) : null}
        {removeRelationship ? (
          <RemoveRelationship
            open={Boolean(removeRelationship)}
            initialValues={removeRelationship}
            onSubmitted={data => {
              deleteRelationshipFromContact(data.contactId, {id: data.relationshipId, role: data.role});
              this.setState({removeRelationship: false});
            }}
            onClose={() => this.setState({removeRelationship: false})}
          />
        ) : null}
      </List>
    );
  }
}

Relationships = connect(
  state => ({
    contacts: state.root.contact.contacts,
  }),
  {
    deleteRelationshipFromContact,
  }
)(Relationships);

export default withRouter(Relationships);
