import React, {Component} from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DealOfferDetails from './DealOfferDetails';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as icons from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import axios from 'axios';
import {Link, withRouter} from 'react-router-dom';

import {mapLabels, formatDateWithoutTime} from '../../utils';
import {queryDealOffer} from '../../queries/DealOffers';
import CloseDealOfferForm from './form/CloseDealOfferForm';
import DeclineDealOfferForm from './form/DeclineDealOfferForm';
import PressReleaseForm from './form/PressReleaseForm';
import PressReleaseEdit from '../pressRelease/PressReleaseEdit';
import Offer from './relations/Offer';
import Deals from './relations/Deals';

class DealOfferDialog extends Component {
  state = {
    dealOffer: null,
    loading: null,
    error: null,
    closeDealOffer: false,
    declineDealOffer: false,
    pressReleaseOpen: false,
  };

  getDealOfferInfo = () => {
    const dealOffer = this.props.dealOffer;
    this.setState({loading: true});
    queryDealOffer({id: dealOffer})
      .then(resp => {
        this.setState({
          dealOffer: resp.data.data.dealOffer,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({error: true, loading: false});
      });
  };

  // handleOpenLink = () => {
  //   this.setState({dealOffer: null});
  //   this.props.history.push(`/dealOffers/${this.state.dealOffer.id}`);
  // };

  saveStatus = (id, newStatus, oldStatus) => {
    if (newStatus === 'closed') {
      return this.setState({closeDealOffer: true});
    } else if (newStatus === 'declined') {
      return this.setState({declineDealOffer: true});
    }

    this.setState({
      dealOffer: {
        ...this.state.dealOffer,
        status: newStatus,
      },
    });

    return axios.post('/api/v1/setDealOfferStatus', {id, status: newStatus})
      .then(() => {
        if (this.props.reload) {
          this.props.reload();
        }
      })
      .catch(() => {
        alert('Ошибка при сохранении');
        this.setState({
          dealOffer: {
            ...this.state.dealOffer,
            status: oldStatus,
          },
        });
        // throw new Error();
      });
  };

  saveAdditional = (id, newAdditional, oldAdditional) => {
    this.setState({
      dealOffer: {
        ...this.state.dealOffer,
        additional: newAdditional,
      },
    });

    return axios.post('/api/v1/setDealOfferAdditional', {id, additional: newAdditional})
      .catch(() => {
        this.setState({
          dealOffer: {
            ...this.state.dealOffer,
            additional: oldAdditional,
          },
        });
        throw new Error();
      });
  };

  onPotentialEndChange = (id, potentialEndAt, oldPotentialEndAt) => {
    this.setState({
      dealOffer: {
        ...this.state.dealOffer,
        potentialEndAt: potentialEndAt,
      },
    });
    return axios.post('/api/v1/setDealOfferPotentialEndAt', {id, potentialEndAt})
      .catch(() => {
        this.setState({
          dealOffer: {
            ...this.state.dealOffer,
            potentialEndAt: oldPotentialEndAt,
          },
        });
        throw new Error();
      });
  };

  handleCloseDealOffer = data => {
    const {endAt, commission, area} = data;
    this.setState({
      closeDealOffer: false,
      pressReleaseOpen: true,
      dealOffer: {
        ...this.state.dealOffer,
        status: 'closed',
        dealEndAt: endAt,
        commission,
        area,
      },
    });

    if (this.props.reload) {
      this.props.reload();
    }
  };

  handleDeclineDealOffer = data => {
    const {reason, comment} = data;

    this.setState({
      declineDealOffer: false,
      dealOffer: {
        ...this.state.dealOffer,
        status: 'declined',
        dealEndAt: new Date(),
        declineReason: reason,
        declineComment: comment,
      },
    });

    if (this.props.reload) {
      this.props.reload();
    }
  };

  componentDidMount() {
    this.getDealOfferInfo();
  }

  render() {
    const {
      classes,
      offerTypeLabels,
      propertyTypeLabels,
      dealOfferStatuses,
      handleClose,
      open,
      history,
      withEntityLinks,
      handleClick,
    } = this.props;

    const {
      dealOffer,
      loading,
      error,
      closeDealOffer,
      declineDealOffer,
      pressReleaseOpen,
      pressReleaseEdit,
    } = this.state;
    if(!dealOffer)
      return null;

    return (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          classes={{paper: classes.dialog}}
        >
          {dealOffer ? (
            <React.Fragment>
              <DialogTitle className={classes.dialogTitle}>
                <div className={classes.dialogTitleWrap}>
                  <div className={classes.dialogText}>
                    <Typography variant="h6">{propertyTypeLabels[dealOffer.deal.propertyType] || dealOffer.deal.propertyType}</Typography>
                    <Typography variant="subtitle1">{offerTypeLabels[dealOffer.deal.offerType] || dealOffer.deal.offerType}</Typography>
                  </div>
                  <div className={classes.dialogBtn}>
                    <IconButton
                      onClick={() => {
                        handleClick && handleClick();
                      }}
                      component={Link}
                      to={`/dealOffers/${this.state.dealOffer.id}`}
                      classes={{root: classes.dialogIcon}}
                    >
                      <icons.OpenInNew/>
                    </IconButton>
                    <IconButton
                      onClick={handleClose}
                      classes={{root: classes.dialogIcon}}
                    >
                      <icons.Close/>
                    </IconButton>
                  </div>
                </div>
              </DialogTitle>
              <Divider/>
              <DialogContent className={classes.dialogContent}>
                {dealOfferStatuses.find(status => dealOffer.status === status.value) ?
                  <DealOfferDetails
                    dealOffer={dealOffer}
                    onStatusChange={status => this.saveStatus(dealOffer.id, status, dealOffer.status)}
                    onAdditionalChange={additional => this.saveAdditional(dealOffer.id, additional, dealOffer.additional)}
                    onPotentialEndChange={potentialEndAt => this.onPotentialEndChange(dealOffer.id, potentialEndAt, dealOffer.potentialEndAt)}
                    dealOfferStatuses={dealOfferStatuses}
                  />
                  :
                  <List>
                    <ListItem dense>
                      <ListItemText
                        primary={formatDateWithoutTime(dealOffer.createdAt)}
                        secondary={'Дата прикрепления'}
                      />
                    </ListItem>
                  </List>
                }
                {withEntityLinks
                  ?
                  <React.Fragment>
                    <Divider/>
                    <Offer
                      dealOffer={dealOffer} handleDialogOpen={() => {
                        history.push(`/offers/${dealOffer.offer.id}/`);
                        handleClick && handleClick();
                      }}/>
                    <Divider/>
                    <Deals
                      onRef={ref => (this.dealComponent = ref)}
                      deal={dealOffer.deal}
                      handleDialogOpen={() => {
                        history.push(`/deals/${dealOffer.deal.id}`);
                        handleClick && handleClick();
                      }}
                    />
                  </React.Fragment>
                  : null}
              </DialogContent>
            </React.Fragment>
          ) : null}
          {loading ? (
            <React.Fragment>
              <DialogTitle className={classes.dialogTitle}>
                <div className={classes.dialogTitleWrap}>
                  <div className={classes.dialogText}>
                    <Typography variant="h6">Загрузка сделки</Typography>
                  </div>
                  <div className={classes.dialogBtn}>
                    <IconButton
                      onClick={handleClose}
                      classes={{root: classes.dialogIcon}}
                    >
                      <icons.Close/>
                    </IconButton>
                  </div>
                </div>
              </DialogTitle>
              <Divider/>
              <DialogContent className={classNames(classes.dialogContent, classes.flexWrap)}>
                <CircularProgress color="primary"/>
              </DialogContent>
            </React.Fragment>
          ) : null}
          {error ? (
            <React.Fragment>
              <DialogTitle className={classes.dialogTitle}>
                <div className={classes.dialogTitleWrap}>
                  <div className={classes.dialogText}>
                    <Typography variant="h6">Не удалось загрузить сделку</Typography>
                  </div>
                  <div className={classes.dialogBtn}>
                    <IconButton
                      onClick={handleClose}
                      classes={{root: classes.dialogIcon}}
                    >
                      <icons.Close/>
                    </IconButton>
                  </div>
                </div>
              </DialogTitle>
              <Divider/>
              <DialogContent className={classNames(classes.dialogContent, classes.flexWrap)}>
                <icons.ErrorOutline/>
              </DialogContent>
            </React.Fragment>
          ) : null}
        </Dialog>
        {closeDealOffer ? (
          <CloseDealOfferForm
            dealOffer={dealOffer}
            onSubmit={data => this.handleCloseDealOffer(data)}
            onClose={() => this.setState({closeDealOffer: false})}
            open={Boolean(closeDealOffer)}
            initialValues={{
              endAt: null,
              area: dealOffer.offer.property.normalizedArea,
            }}
          />
        ) : null}
        {pressReleaseOpen ? (
          <PressReleaseForm
            dealOffer={dealOffer}
            submit={() => this.setState({pressReleaseOpen: false, pressReleaseEdit: true})}
            onClose={() => this.setState({pressReleaseOpen: false})}
            open={Boolean(pressReleaseOpen)}
          />
        ) : null}
        {pressReleaseEdit ? (
          <PressReleaseEdit
            dealOffer={dealOffer}
            onSubmitted={data => {
              this.setState({pressReleaseEdit: false});
              history.push(`/pressRelease/dealClose/${data.id}`);
            }}
            onClose={() => this.setState({pressReleaseEdit: false})}
            type={'dealClose'}
            open={Boolean(pressReleaseEdit)}
          />
        ) : null}
        {declineDealOffer ? (
          <DeclineDealOfferForm
            dealOffer={dealOffer}
            onSubmit={data => this.handleDeclineDealOffer(data)}
            onClose={() => this.setState({declineDealOffer: false})}
            open={Boolean(declineDealOffer)}
          />
        ) : null}
      </>
    );
  }
}

const styles = theme => ({
  dialog: {
    width: 550,
    maxWidth: '100%',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100%',
    },
  },
  dialogIcon: {
    width: 36,
    height: 36,
    padding: 4,
  },
  dialogTitleWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 0,
    minHeight: 150,
  },
  dialogTitle: {
    padding: '12px 24px 8px 24px',
  },
  dialogBtn: {
    marginRight: -12,
    minWidth: 72,
  },
  flexWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});


export default connect(
  state => ({
    offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    dealOfferStatuses: state.root.classification.dealOfferActiveStatuses,
  }),
)(withRouter(withStyles(styles)(DealOfferDialog)));
