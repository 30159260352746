import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import OfferDealListItem from '../../../components/relations/OfferDealListItem';

class DealOffers extends Component {
  state = {
    dealOffers: [],
  };

  componentDidMount() {
    if (this.props.dealOffers) {
      this.setState({dealOffers: this.props.dealOffers});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dealOffers !== this.props.dealOffers) {
      this.setState({dealOffers: nextProps.dealOffers});
    }
  }

  render() {
    const {
      handleDialogOpen,
      loading,
      error,
    } = this.props;

    const {
      dealOffers,
    } = this.state;

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Сделки
          <ListItemSecondaryAction>
            {loading ? (
              <IconButton disabled>
                <CircularProgress size={24}/>
              </IconButton>
            ) : (
              error ? (
                <IconButton disabled>
                  <icons.ErrorOutline/>
                </IconButton>
              ) : null)}
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {dealOffers.map(dealOffer => (
          <OfferDealListItem
            key={dealOffer.id}
            dealOffer={dealOffer}
            handleDialogOpen={() => handleDialogOpen(dealOffer.id)}
            color={colors.indigo[500]}
          />
        ))}
      </List>
    );
  }
}

const styles = () => ({
  statusIcon: {
    display: 'inline-block',
    lineHeight: 1,
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 4,
  },
});

DealOffers = connect(
  state => ({
    dealOfferActiveStatuses: state.root.classification.dealOfferActiveStatuses,
  }),
)(DealOffers);

export default withRouter(withStyles(styles)(DealOffers));
