import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import * as icons from '@material-ui/icons';

import RemoveOwner from '../form/RemoveOwner';
import {unsetOwnerFromProperty} from '../../../actions';
import {mapLabels} from '../../../utils';
import ContactListItem from '../../../components/relations/ContactListItem';

class Owner extends Component {
  state = {
    openMenu: false,
    removeOwner: false,
  };

  render() {
    const {
      unsetOwnerFromProperty,
      contacts,
      history,
      property,
      handleDialogOpen,
    } = this.props;
    const {openMenu, removeOwner} = this.state;

    if (!property) {
      return null;
    }
    return (
      <React.Fragment>
        <List subheader={
          <ListSubheader
            disableSticky
            style={{position: 'relative'}}
          >
            Собственник
            <ListItemSecondaryAction>
              <IconButton onClick={() => history.push(`/properties/${property.id}/owner`)}>
                <icons.Add/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListSubheader>
        }>
          {property.owner ? [property.owner].map(owner => {
            const contact = contacts[owner.id];
            return contact ? (
              <ContactListItem
                contact={contact}
                key={owner.id}
                onSecondaryActionClick={e => this.setState({openMenu: {target: e.currentTarget, id: owner.id}})}
                handleDialogOpen={e => handleDialogOpen(e)}
              />
            ) : null;
          }) : null}
          {openMenu ? (
            <Menu
              anchorEl={openMenu.target}
              open={Boolean(openMenu)}
              onClick={() => this.setState({openMenu: false})}
            >
              <MenuItem onClick={() => {
                this.setState({
                  removeOwner: {
                    propertyId: property.id,
                    ownerId: openMenu.id,
                  },
                });
              }}>
                Убрать собственника
              </MenuItem>
            </Menu>
          ) : null}
          {removeOwner ? (
            <RemoveOwner
              open={Boolean(removeOwner)}
              initialValues={removeOwner}
              onSubmitted={() => {
                unsetOwnerFromProperty(removeOwner.propertyId);
                this.setState({removeOwner: false});
              }}
              onClose={() => this.setState({removeOwner: false})}
            />
          ) : null}
        </List>

      </React.Fragment>
    );
  }
}

Owner = connect(
  state => ({
    contactTypeLabels: mapLabels(state.root.classification.contactTypes, 'value', 'label'),
    contacts: state.root.contact.contacts,
  }),
  {
    unsetOwnerFromProperty,
  }
)(Owner);

export default withRouter(Owner);
