import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import format from 'date-fns/format';
import locale from 'date-fns/locale/ru';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import TableLink from '../../../components/TableLink';

class DynamicsTable extends Component {
  state = {
    firstArr: [],
    secondArr: [],
    thirdArr: [],
  };

  componentDidMount() {
    const {statistics} = this.props;
    const divideIndex = Math.ceil(statistics.length/3);
    const firstArr = statistics.slice(0, divideIndex);
    const secondArr = statistics.slice(divideIndex, divideIndex * 2);
    const thirdArr = statistics.slice(divideIndex * 2, statistics.length);
    this.setState({firstArr, secondArr, thirdArr});
  }

  render() {
    const {
      classes,
      statistics,
      total,
      title,
      handleClickOpen,
      getHref,
    } = this.props;

    const {firstArr, secondArr, thirdArr} = this.state;

    const threeColumnTable = arr => {
      return (
        <Table className={classes.doubleTable}>
          <TableHead>
            <TableRow className={classNames(classes.tableRow, {[classes.firstTableRow]: arr === firstArr || arr === secondArr})}>
              <TableCell>{title}</TableCell>
              <TableCell>Количество</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {arr.map((row, i) => (
              <TableLink
                key={i}
                href={getHref(row.data)}
                className={classNames(classes.tableRow, {[classes.firstTableRow]: arr === firstArr || arr === secondArr})}
                hover
                onClick={() => handleClickOpen(row.data)}
              >
                <TableCell>{format(new Date(row.data), 'dd LLLL', {locale: locale})}</TableCell>
                <TableCell>{row.value ? row.value.toLocaleString('ru-RU') : 0}</TableCell>
              </TableLink>
            ))}
            {arr === thirdArr && (thirdArr.length !== firstArr.length) ? (
              <TableRow classes={{root: classes.tableRow}}>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : null}
            {arr === thirdArr && (firstArr.length - thirdArr.length === 2) ? (
              <TableRow classes={{root: classes.tableRow}}>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      );
    };

    return (
      <Paper className={classNames(classes.root, {[classes.oneTableRoot]: !firstArr.length})}>
        <Hidden xsDown>
          {!firstArr.length ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow classes={{root: classes.tableRow}}>
                  <TableCell>{title}</TableCell>
                  <TableCell>Количество</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!statistics.length ? (
                  <TableRow>
                    <TableCell
                      classes={{root: classes.tableRow}}
                      colSpan={2}
                    >Нет данных для статистики</TableCell>
                  </TableRow>
                ) : statistics.map((row, i) => (
                  <TableRow
                    key={i}
                    classes={{root: classes.tableRow}}
                    hover
                    onClick={() => handleClickOpen(row.data)}
                  >
                    <TableCell>{format(new Date(row.data), 'd LLL', {locale: locale})}</TableCell>
                    <TableCell>{row.value ? row.value.toLocaleString('ru-RU') : 0}</TableCell>

                  </TableRow>
                ))}
                <TableRow
                  onClick={() => handleClickOpen()}
                  hover
                >
                  <TableCell>Всего:</TableCell>
                  <TableCell>{total.count.toLocaleString('ru-RU')}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <>
              <Grid
                container
                alignItems="flex-start"
              >
                <Grid item sm={4} xs={12}>
                  {threeColumnTable(firstArr)}
                </Grid>
                <Grid item sm={4} xs={12}>
                  {threeColumnTable(secondArr)}
                </Grid>
                <Grid item sm={4} xs={12}>
                  {threeColumnTable(thirdArr)}
                </Grid>
              </Grid>
              <Table>
                <TableBody className={classes.table}>
                  <TableLink
                    className={classes.tableRow}
                    hover
                    href={getHref()}
                    onClick={() => handleClickOpen()}
                  >
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>Всего:</TableCell>
                    <TableCell>{total.count.toLocaleString('ru-RU')}</TableCell>
                  </TableLink>
                </TableBody>
              </Table>
            </>
          )}
        </Hidden>
        <Hidden smUp>
          <Table className={classes.table}>
            <TableHead>
              <TableRow classes={{root: classes.tableRow}}>
                <TableCell>{title}</TableCell>
                <TableCell>Кол-во</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!statistics.length ? (
                <TableRow>
                  <TableCell
                    classes={{root: classes.tableRow}}
                    colSpan={2}
                  >Нет данных для статистики</TableCell>
                </TableRow>
              ) : statistics.map((row, i) => (
                <TableRow
                  key={i}
                  classes={{root: classes.tableRow}}
                  hover
                  onClick={() => handleClickOpen(row.data)}
                >
                  <TableCell>{format(new Date(row.data), 'dd LLLL', {locale: locale})}</TableCell>
                  <TableCell>{row.value ? row.value.toLocaleString('ru-RU') : 0}</TableCell>

                </TableRow>
              ))}
              <TableRow
                onClick={() => handleClickOpen()}
                hover
              >
                <TableCell>Всего:</TableCell>
                <TableCell>{total.count}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Hidden>
      </Paper>
    );
  }
}



const styles = () => ({
  table: {
    background: 'white',
    marginBottom: 56 + 48,
  },
  root: {
    margin: 20,
  },
  oneTableRoot: {
    maxWidth: 700,
  },
  tableRow: {
    height: 37,
  },
  doubleTable: {
    marginBottom: 0,
  },
  firstTableRow: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
});

export default withStyles(styles)(DynamicsTable);
