import React, {Component} from 'react';
import PropertyList from './PropertyList';
import PropertyListItems from './PropertyListItems';
import FavoritesWrapper from '../../is/favorites/containers/FavoritesWrapper';
import {connect} from 'react-redux';

class PropertyListWrapper extends Component {
  render() {
    const {properties} = this.props;
    return (
      <PropertyList {...this.props}>
        {properties ? (
          <FavoritesWrapper items={Object.keys(properties)}>
            <PropertyListItems/>
          </FavoritesWrapper>
        ) : null}
      </PropertyList>
    );
  }
}

export default PropertyListWrapper = connect(
  state => ({
    properties: state.root.property.properties,
  }),
  {}
)(PropertyListWrapper);
