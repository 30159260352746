
import React, {Component} from 'react';
import {scaleLinear} from 'd3-scale';
import {interpolateLab} from 'd3-interpolate';

class Bars extends Component {
  render() {
    const {
      scales,
      margins,
      data,
      svgDimensions,
      variant,
      color,
    } = this.props;

    const {xScale, yScale} = scales;
    const {colorStart, colorEnd} = color;
    const {height} = svgDimensions;

    const colorScale = scaleLinear()
      .domain([0, this.props.maxValue])
      .range([colorStart, colorEnd])
      .interpolate(interpolateLab);

    let bars;

    if (variant === 'horizontal') {
      bars = (
        data.map(datum =>
          <React.Fragment
            key={datum.title}
          >
            <rect
              y={yScale(datum.title)}
              x={margins.left}
              width={scales.xScale(datum.value) - margins.left}
              height={yScale.bandwidth()}
              fill={colorScale(datum.value)}
            />
          </React.Fragment>
        )
      );
    } else {
      bars = (
        data.map(datum =>
          <React.Fragment
            key={datum.title}
          >
            <rect
              key={datum.title}
              x={xScale(datum.title)}
              y={yScale(datum.value)}
              height={height - margins.bottom - scales.yScale(datum.value)}
              width={xScale.bandwidth()}
              fill={colorScale(datum.value)}
            />
          </React.Fragment>
        )
      );
    }

    return (
      <g>{bars}</g>
    );
  }
}

export default Bars;
