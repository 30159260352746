import React, {Component} from 'react';
import {connect} from 'react-redux';
import CardContent from '@material-ui/core/CardContent';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {formatDateWithoutTime, getImageUrl, ImageFilters} from '../../utils';
import MobilePaginationToolbar from '../../components/MobilePaginationToolbar';
import TablePaginationActions from '../../components/TablePaginationActions';
import TableLink from '../../components/TableLink';

class PressReleaseListItems extends Component {
  state = {
    anchorEl: null,
    popoverContent: null,
    openExportMenu: null,
  };

  TableNews = () => {
    const {
      classes,
      history,
      eventIds,
      event,
      _page,
      handleOnChangePage,
      totalCount,
      type,
    } = this.props;

    return (
      <React.Fragment>
        <Hidden xsDown>
          <Table padding="none" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell>Дата события</TableCell>
                <TableCell>Создатель</TableCell>
                <TableCell>Опубликован</TableCell>
                <TableCell>Создан&nbsp;/&nbsp;Изменен</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventIds && eventIds.map(id => {
                let pressRelease = event[id];
                return (
                  <TableLink
                    className={classes.tableRow}
                    key={id}
                    href={`/pressRelease/${type}/${id}`}
                    hover
                    onClick={e => {
                      e.preventDefault();
                      history.push(`/pressRelease/${type}/${id}`);
                    }}>
                    <TableCell>{pressRelease.name}</TableCell>
                    <TableCell>{formatDateWithoutTime(pressRelease.date)}</TableCell>
                    <TableCell>{pressRelease.member.name}{'\n'}{pressRelease.member.phone}</TableCell>
                    <TableCell>{pressRelease.published ? 'Да' : 'Нет'}</TableCell>
                    <TableCell>
                      {formatDateWithoutTime(pressRelease.createdAt)}&nbsp;/{' '}
                      {formatDateWithoutTime(pressRelease.updatedAt)}
                    </TableCell>
                  </TableLink>
                );
              })}
            </TableBody>
          </Table>
          <div className={classes.pagination}>
            <TablePagination
              rowsPerPage={30}
              component={'div'}
              rowsPerPageOptions={[30]}
              count={totalCount}
              page={_page}
              onChangePage={handleOnChangePage}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} из ${count}`
              }
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </Hidden>
        <Hidden smUp>
          {eventIds && eventIds.map(id => {
            const  pressRelease = event[id];
            return (
              <Paper
                key={id}
                className={classes.card}
                elevation={0}
                onClick={() => history.push(`/pressRelease/${type}/${pressRelease.id}`)}
                square
              >
                <CardContent style={{position: 'relative'}}>
                  <Typography variant="subtitle1">
                    {pressRelease.name}
                  </Typography>
                  <Typography>
                    {pressRelease.member.name}
                  </Typography>
                  <Typography>
                    {pressRelease.member.phone}
                  </Typography>
                  <Typography variant="caption">
                    {formatDateWithoutTime(pressRelease.createdAt)}&nbsp;/&nbsp;{formatDateWithoutTime(pressRelease.updatedAt)}
                  </Typography>
                </CardContent>
                <Divider />
              </Paper>
            );
          })}
          <MobilePaginationToolbar
            totalCount={totalCount}
            page={_page}
            handleOnChangePage={(e, page) => handleOnChangePage(e, page)}
          />
        </Hidden>
      </React.Fragment>
    );
  };

  TableEvents = () => {
    const {
      classes,
      history,
      eventIds,
      event,
      _page,
      handleOnChangePage,
      totalCount,
      type,
    } = this.props;

    return (
      <React.Fragment>
        <Hidden xsDown>
          <Table padding="none" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell>Дата события</TableCell>
                <TableCell>Создатель</TableCell>
                <TableCell>Опубликован</TableCell>
                <TableCell>Создан&nbsp;/&nbsp;Изменен</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventIds && eventIds.map(id => {
                let pressRelease = event[id];
                return (
                  <TableLink
                    className={classes.tableRow}
                    key={id}
                    href={`/pressRelease/${type}/${id}`}
                    hover
                    onClick={e => {
                      e.preventDefault();
                      history.push(`/pressRelease/${type}/${id}`);
                    }}>
                    <TableCell>{pressRelease.name}</TableCell>
                    <TableCell>{formatDateWithoutTime(pressRelease.date)}</TableCell>
                    <TableCell>{pressRelease.member.name}{'\n'}{pressRelease.member.phone}</TableCell>
                    <TableCell>{pressRelease.published ? 'Да' : 'Нет'}</TableCell>
                    <TableCell>
                      {formatDateWithoutTime(pressRelease.createdAt)}&nbsp;/{' '}
                      {formatDateWithoutTime(pressRelease.updatedAt)}
                    </TableCell>
                  </TableLink>
                );
              })}
            </TableBody>
          </Table>
          <div className={classes.pagination}>
            <TablePagination
              rowsPerPage={30}
              component={'div'}
              rowsPerPageOptions={[30]}
              count={totalCount}
              page={_page}
              onChangePage={handleOnChangePage}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} из ${count}`
              }
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </Hidden>
        <Hidden smUp>
          {eventIds && eventIds.map(id => {
            const  pressRelease = event[id];
            return (
              <Paper
                key={id}
                className={classes.card}
                elevation={0}
                onClick={() => history.push(`/pressRelease/${type}/${pressRelease.id}`)}
                square
              >
                <CardContent style={{position: 'relative'}}>
                  <Typography variant="subtitle1">
                    {pressRelease.name}
                  </Typography>
                  <Typography>
                    {pressRelease.member.name}
                  </Typography>
                  <Typography>
                    {pressRelease.member.phone}
                  </Typography>
                  <Typography variant="caption">
                    {formatDateWithoutTime(pressRelease.createdAt)}&nbsp;/&nbsp;{formatDateWithoutTime(pressRelease.updatedAt)}
                  </Typography>
                </CardContent>
                <Divider />
              </Paper>
            );
          })}
          <MobilePaginationToolbar
            totalCount={totalCount}
            page={_page}
            handleOnChangePage={(e, page) => handleOnChangePage(e, page)}
          />
        </Hidden>
      </React.Fragment>
    );
  };

  TableResults = () => {
    const {
      classes,
      history,
      eventIds,
      event,
      _page,
      handleOnChangePage,
      totalCount,
      type,
    } = this.props;

    return (
      <React.Fragment>
        <Hidden xsDown>
          <Table padding="none" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Фотография</TableCell>
                <TableCell>Создатель</TableCell>
                <TableCell>Опубликован</TableCell>
                <TableCell>Создано&nbsp;/&nbsp;Изменено</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventIds && eventIds.map(id => {
                let pressRelease = event[id];
                return (
                  <TableLink
                    className={classes.tableRow}
                    key={id}
                    href={`/pressRelease/${type}/${id}`}
                    hover
                    onClick={e => {
                      e.preventDefault();
                      history.push(`/pressRelease/${type}/${id}`);
                    }}>
                    <TableCell>
                      {
                        pressRelease.photos && pressRelease.photos.length ? (
                          <img src={getImageUrl({filter: ImageFilters.resize, width: 100, height: 100, path: pressRelease.photos[0] || ''})} alt=""/>
                        ) : null
                      }
                    </TableCell>
                    <TableCell>{pressRelease.member.name}{'\n'}{pressRelease.member.phone}</TableCell>
                    <TableCell>{pressRelease.published ? 'Да' : 'Нет'}</TableCell>
                    <TableCell>
                      {formatDateWithoutTime(pressRelease.createdAt)}&nbsp;/{' '}
                      {formatDateWithoutTime(pressRelease.updatedAt)}
                    </TableCell>
                  </TableLink>
                );
              })}
            </TableBody>
          </Table>
          <div className={classes.pagination}>
            <TablePagination
              rowsPerPage={30}
              component={'div'}
              rowsPerPageOptions={[30]}
              count={totalCount}
              page={_page}
              onChangePage={handleOnChangePage}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} из ${count}`
              }
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </Hidden>
        <Hidden smUp>
          {eventIds && eventIds.map(id => {
            const  pressRelease = event[id];
            return (
              <Paper
                key={id}
                className={classes.card}
                elevation={0}
                onClick={() => history.push(`/pressRelease/${type}/${pressRelease.id}`)}
                square
              >
                <CardContent style={{position: 'relative'}}>
                  <Typography variant="subtitle1">
                    {pressRelease.name}
                  </Typography>
                  <Typography>
                    {pressRelease.member.name}
                  </Typography>
                  <Typography>
                    {pressRelease.member.phone}
                  </Typography>
                  <Typography variant="caption">
                    {formatDateWithoutTime(pressRelease.createdAt)}&nbsp;/&nbsp;{formatDateWithoutTime(pressRelease.updatedAt)}
                  </Typography>
                </CardContent>
                <Divider />
              </Paper>
            );
          })}
          <MobilePaginationToolbar
            totalCount={totalCount}
            page={_page}
            handleOnChangePage={(e, page) => handleOnChangePage(e, page)}
          />
        </Hidden>
      </React.Fragment>
    );
  };

  TableVideos = () => {
    const {
      classes,
      history,
      eventIds,
      event,
      _page,
      handleOnChangePage,
      totalCount,
      type,
    } = this.props;

    return (
      <React.Fragment>
        <Hidden xsDown>
          <Table padding="none" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Дата публикации</TableCell>
                <TableCell>Описание</TableCell>
                <TableCell>Создатель</TableCell>
                <TableCell>Опубликован</TableCell>
                <TableCell>Создано&nbsp;/&nbsp;Изменено</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventIds && eventIds.map(id => {
                let pressRelease = event[id];
                return (
                  <TableLink
                    className={classes.tableRow}
                    key={id}
                    href={`/pressRelease/${type}/${id}`}
                    hover
                    onClick={e => {
                      e.preventDefault();
                      history.push(`/pressRelease/${type}/${id}`);
                    }}>
                    <TableCell>{formatDateWithoutTime(pressRelease.date)}</TableCell>
                    <TableCell>{pressRelease.description}</TableCell>
                    <TableCell>{pressRelease.member.name}{'\n'}{pressRelease.member.phone}</TableCell>
                    <TableCell>{pressRelease.published ? 'Да' : 'Нет'}</TableCell>
                    <TableCell>
                      {formatDateWithoutTime(pressRelease.createdAt)}&nbsp;/{' '}
                      {formatDateWithoutTime(pressRelease.updatedAt)}
                    </TableCell>
                  </TableLink>
                );
              })}
            </TableBody>
          </Table>
          <div className={classes.pagination}>
            <TablePagination
              rowsPerPage={30}
              component={'div'}
              rowsPerPageOptions={[30]}
              count={totalCount}
              page={_page}
              onChangePage={handleOnChangePage}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} из ${count}`
              }
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </Hidden>
        <Hidden smUp>
          {eventIds && eventIds.map(id => {
            const  pressRelease = event[id];
            return (
              <Paper
                key={id}
                className={classes.card}
                elevation={0}
                onClick={() => history.push(`/pressRelease/${type}/${pressRelease.id}`)}
                square
              >
                <CardContent style={{position: 'relative'}}>
                  <Typography variant="subtitle1">
                    {pressRelease.description}
                  </Typography>
                  <Typography>
                    {pressRelease.member.name}
                  </Typography>
                  <Typography>
                    {pressRelease.member.phone}
                  </Typography>
                  <Typography variant="caption">
                    {formatDateWithoutTime(pressRelease.createdAt)}&nbsp;/&nbsp;{formatDateWithoutTime(pressRelease.updatedAt)}
                  </Typography>
                </CardContent>
                <Divider />
              </Paper>
            );
          })}
          <MobilePaginationToolbar
            totalCount={totalCount}
            page={_page}
            handleOnChangePage={(e, page) => handleOnChangePage(e, page)}
          />
        </Hidden>
      </React.Fragment>
    );
  };

  TableCloseDeal = () => {
    const {
      classes,
      history,
      dealCloseIds,
      dealClose,
      handleOnChangePage,
      totalCount,
      _page,
      type,
    } = this.props;

    return (
      <React.Fragment>
        <Hidden xsDown>
          <Table padding="none" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell>Создатель</TableCell>
                <TableCell>Опубликован</TableCell>
                <TableCell>Создан&nbsp;/&nbsp;Изменен</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dealCloseIds && dealCloseIds.map(id => {
                let pressRelease = dealClose[id];
                return (
                  <TableLink
                    className={classes.tableRow}
                    key={id}
                    href={`/pressRelease/${type}/${id}`}
                    hover
                    onClick={e => {
                      e.preventDefault();
                      history.push(`/pressRelease/${type}/${id}`);
                    }}>
                    <TableCell>{pressRelease.name}</TableCell>
                    <TableCell>{pressRelease.member.name}{'\n'}{pressRelease.member.phone}</TableCell>
                    <TableCell>{pressRelease.published ? 'Да' : 'Нет'}</TableCell>
                    <TableCell>
                      {formatDateWithoutTime(pressRelease.createdAt)}&nbsp;/{' '}
                      {formatDateWithoutTime(pressRelease.updatedAt)}
                    </TableCell>
                  </TableLink>
                );
              })}
            </TableBody>
          </Table>
          <div className={classes.pagination}>
            <TablePagination
              rowsPerPage={30}
              component={'div'}
              rowsPerPageOptions={[30]}
              count={totalCount}
              page={_page}
              onChangePage={handleOnChangePage}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} из ${count}`
              }
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </Hidden>
        <Hidden smUp>
          {dealCloseIds && dealCloseIds.map(id => {
            const  pressRelease = dealClose[id];
            return (
              <Paper
                key={id}
                className={classes.card}
                elevation={0}
                onClick={() => history.push(`/pressRelease/${type}/${pressRelease.id}`)}
                square
              >
                <CardContent style={{position: 'relative'}}>
                  <Typography variant="subtitle1">
                    {pressRelease.name}
                  </Typography>
                  <Typography>
                    {pressRelease.member.name}
                  </Typography>
                  <Typography>
                    {pressRelease.member.phone}
                  </Typography>
                  <Typography variant="caption">
                    {formatDateWithoutTime(pressRelease.createdAt)}
                  </Typography>
                </CardContent>
                <Divider />
              </Paper>
            );
          })}
          <MobilePaginationToolbar
            totalCount={totalCount}
            page={_page}
            handleOnChangePage={(e, page) => handleOnChangePage(e, page)}
          />
        </Hidden>
      </React.Fragment>
    );
  };


  render() {
    const {
      type,
    } = this.props;


    if(type === 'news')
      return this.TableNews();
    else if(type === 'events')
      return this.TableEvents();
    else if(type === 'results')
      return this.TableResults();
    else if(type === 'videos')
      return this.TableVideos();
    else
      return this.TableCloseDeal();
  }
}

const styles = theme => ({
  tableRow: {
    display:'table-row',
    color: 'inherit',
    height: 48,
    outline: 'none',
    verticalAlign: 'middle',
    textDecoration: 'none',
    cursor: 'default',
    '&:hover': {
      backgroundColor:'rgba(0,0,0,0.07)',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 56,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    backgroundColor: 'white',
    marginBottom: 56 + 48,
  },
  tdWithoutPadding:{
    padding: '0px',
  },
  table: {
    background: 'white',
  },
  action: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 8,
    },
    top: 0,
  },
  sortableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
  malformed: {
    color: colors.orange[400],
    width: 16,
    height: 16,
    marginLeft: 16,
    verticalAlign: 'middle',
  },
  malformedIcon: {
    color: colors.orange[400],
    width: 16,
    height: 16,
    marginRight: 8,
    float: 'left',
    verticalAlign: 'middle',
  },
});

export default connect(state => ({
  selfUser: state.root.selfUser,
}))(withStyles(styles)(PressReleaseListItems));
