import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import * as icons from '@material-ui/icons';

import RemoveAssignee from '../form/RemoveAssignee';
import {deleteAssigneeFromPressRelease} from '../../../actions';
import MemberListItem from '../../../components/relations/MemberListItem';

class Assignees extends Component {
  state = {
    openMenu: false,
    removeAssignee: false,
  };

  render() {
    const {
      deleteAssigneeFromPressRelease,
      history,
      pressRelease,
    } = this.props;
    const {openMenu, removeAssignee} = this.state;

    if (!pressRelease) {
      return null;
    }
    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Консультанты
          <ListItemSecondaryAction>
            <IconButton onClick={() => history.push(`/pressRelease/closeDeal/${pressRelease.id}/assignees`)}>
              <icons.Add/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {pressRelease.assignees ? pressRelease.assignees.map(assignee => {
          return(
            <MemberListItem
              key={assignee.id}
              member={assignee}
              onSecondaryActionClick={
                pressRelease.assignees.length > 1 ?
                  e =>
                    this.setState({openMenu: {target: e.currentTarget, id: assignee.id}})
                  : null
              }
            />
          );
        }) : null}
        {openMenu ? (
          <Menu
            anchorEl={openMenu.target}
            open={Boolean(openMenu)}
            onClick={() => this.setState({openMenu: false})}
          >
            <MenuItem onClick={() => {
              this.setState({
                removeAssignee: {
                  pressReleaseId: pressRelease.id,
                  assigneeId: openMenu.id,
                },
              });
            }}>
              Удалить
            </MenuItem>
          </Menu>
        ) : null}
        {removeAssignee ? (
          <RemoveAssignee
            open={Boolean(removeAssignee)}
            initialValues={
              {
                assignees: pressRelease.assignees.filter(item => item.id !== removeAssignee.assigneeId).map(item => item.id),
                id: removeAssignee.pressReleaseId,
              }
            }
            onSubmitted={() => {
              deleteAssigneeFromPressRelease(removeAssignee.pressReleaseId, removeAssignee.assigneeId);
              this.setState({removeAssignee: false});
            }}
            onClose={() => this.setState({removeAssignee: false})}
          />
        ) : null}
      </List>
    );
  }
}

Assignees = connect(
  () => ({}),
  {
    deleteAssigneeFromPressRelease,
  }
)(Assignees);

export default withRouter(Assignees);
