import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, FormSection} from 'redux-form';
import {Checkbox} from 'redux-form-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import * as icons from '@material-ui/icons';

import {formatAddressListItem} from '../../../utils';
import Assignees from '../../../components/relations/Assignees';
import ContactField from '../../contacts/form/ContactField';
import DatePicker from '../../../components/Input/DatePicker';
import TextField from '../../../components/Input/TextField';
import {Typography} from '@material-ui/core';

class Form extends Component {
  render() {
    const {
      assignees,
      contact,
      id,
      offers,
      onAssigneeSelect,
      onAssigneeUnselect,
      onContactCreate,
      onContactUnassign,
      onContactSelect,
      onPropertyUnselect,
      team,
      contractTypes,
      properties,
      classes,
    } = this.props;

    return (
      <div style={{marginTop: 24}}>
        <FormSection name="contract">
          <Grid container spacing={24}>
            {!id ? (
              <ContactField
                contact={contact}
                onContactCreate={onContactCreate}
                onContactUnassign={onContactUnassign}
                onContactSelect={onContactSelect}
              />
            ) : null}
            {!id ? (
              <Grid item xs={12}>
                <FormControl margin="none">
                  <FormControlLabel
                    control={<Field name="selfAssignee" component={Checkbox} />}
                    label="Я ответственный"
                    required
                  />
                </FormControl>
                {offers && offers.length > 0 ? (
                  <FormControl margin="none">
                    <FormControlLabel
                      control={
                        <Field name="offerAssignees" component={Checkbox} />
                      }
                      label="Ответственные за листингом"
                      required
                    />
                  </FormControl>
                ) : null}
              </Grid>
            ) : null}
            {!id ? (
              <Assignees
                team={team}
                assignees={assignees}
                onSelect={onAssigneeSelect}
                onUnselect={onAssigneeUnselect}
              />
            ) : null}
            {properties.length ? (
              <Grid item xs={12}>
                <Typography variant="subtitle1">Объекты</Typography>
                {properties.map((property, index) => (
                  <Chip
                    key={index}
                    component="a"
                    target="_blank"
                    href={`/properties/${property.id}`}
                    label={formatAddressListItem(property.address)}
                    // label={property.address.value}
                    onDelete={e => {
                      onPropertyUnselect(index);
                      e.preventDefault();
                    }}
                  />
                ))}
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Field
                name="type"
                label="Тип договора"
                required
                component={TextField}
                select
                fullWidth
                margin="none"
              >
                {contractTypes.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="no"
                required
                label="Номер договора"
                component={TextField}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="startAt"
                label="Дата заключения"
                format={null}
                required
                component={props => (
                  <DatePicker format="yyyy-MM-dd" clearable {...props} />
                )}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <div style={{position:'relative'}}>
                <Field
                  name="endAt"
                  label="Дата окончания"
                  format={null}
                  component={props => (
                    <DatePicker format="yyyy-MM-dd" clearable {...props} />
                  )}
                  fullWidth
                  margin="none"
                />
                <Tooltip
                  enterTouchDelay={0}
                  title={
                    <div>
                      <p>Если дата окончания договора не указана, договор является бессрочным.</p>
                    </div>
                  }
                  style={{position:'absolute',display: 'block',top:0,right:10}}
                >
                  <icons.Help className={classes.help} />
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="commission"
                label="Комиссия, %"
                required
                component={TextField}
                normalize={value =>
                  value ? value.toString().replace(',', '.') : value
                }
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="renewalTerms"
                label="Условия пролонгации"
                component={TextField}
                multiline
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="additionalAgreements"
                label="Дополнительные требования"
                component={TextField}
                multiline
                rowsMax={15}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="comment"
                label="Комментарий"
                component={TextField}
                multiline
                rowsMax={15}
                fullWidth
                margin="none"
              />
            </Grid>
          </Grid>
        </FormSection>
      </div>
    );
  }
}

const styles = {
  help: {
    color: colors.grey[700],
    marginLeft: 12,
  },
};

export default connect(({root}) => ({
  dealSources: root.classification.dealSources,
  dealStatuses: root.classification.dealStatuses,
  priceTypes: root.classification.priceTypes,
  contractTypes: root.classification.contractTypes,
}))(withStyles(styles)(Form));
