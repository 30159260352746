import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import * as icons from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

class PropertyMaterials extends Component {
  state = {
    materials: true,
    loading: false,
  };

  render() {
    const {
      materials,
      loading,
    } = this.state;
    const {
      classes,
      onClose,
      open,
      offer,
    } = this.props;

    return (
      <Dialog open={open} classes={{paper: classes.dialog}}>
        <DialogTitle className={classes.dialogTitle}>
          <div className={classes.dialogTitleWrap}>
            <div className={classes.dialogText}>
              <Typography variant="h6">Маркетинговые материалы</Typography>
            </div>
            <div className={classes.dialogBtn}>
              <IconButton
                onClick={() => onClose()}
                className={classes.dialogIcon}
              >
                <icons.Close/>
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <Divider/>
        {loading ? (
          <DialogContent className={classNames(classes.dialogContent, classes.flexWrap)}>
            <CircularProgress color="primary"/>
          </DialogContent>
        ) : null }
        {materials ? (
          <List>
            <ListItem
              dense
              button
              component="a"
              href={`https://crenet.ru/report/${offer.id}/`}
              target="_blank"
            >
              <ListItemIcon>
                <icons.CloudDownload/>
              </ListItemIcon>
              <ListItemText inset primary="Карточка объекта" />
            </ListItem>
            <Divider/>
            <ListItem
              dense
              button
              component="a"
              href={`https://crenet.ru/report/${offer.id}/presentation/`}
              target="_blank"
            >
              <ListItemIcon>
                <icons.CloudDownload/>
              </ListItemIcon>
              <ListItemText inset primary="Презентация" />
            </ListItem>
            <Divider/>
            <ListItem
              dense
              button
              component="a"
              href={`https://crenet.ru/report/${offer.id}/short_presentation/`}
              target="_blank"
            >
              <ListItemIcon>
                <icons.CloudDownload/>
              </ListItemIcon>
              <ListItemText inset primary="Краткая презентация" />
            </ListItem>
            <Divider/>
            <ListItem
              dense
              button
              component="a"
              href={`https://crenet.ru/report/${offer.id}/plan/`}
              target="_blank"
            >
              <ListItemIcon>
                <icons.CloudDownload/>
              </ListItemIcon>
              <ListItemText inset primary="План" />
            </ListItem>
            <Divider/>
            <ListItem
              dense
              button
              component="a"
              href={`https://crenet.ru/report/${offer.id}/plan_wo-contact/`}
              target="_blank"
            >
              <ListItemIcon>
                <icons.CloudDownload/>
              </ListItemIcon>
              <ListItemText inset primary="Карточка без контактов" />
            </ListItem>
            <Divider/>
            <ListItem
              dense
              button
              component="a"
              href={`https://crenet.ru/report/${offer.id}/presentation_wo-contact/`}
              target="_blank"
            >
              <ListItemIcon>
                <icons.CloudDownload/>
              </ListItemIcon>
              <ListItemText inset primary="Презентация без контактов" />
            </ListItem>
            <Divider/>
            <ListItem
              dense
              button
              component="a"
              href={`https://crenet.ru/report/${offer.id}/presentation_wo-address/`}
              target="_blank"
            >
              <ListItemIcon>
                <icons.CloudDownload/>
              </ListItemIcon>
              <ListItemText inset primary="Презентация без адреса" />
            </ListItem>
          </List>
        ) : null }
      </Dialog>
    );
  }
}

const styles = () => ({
  dialogIcon: {
    marginLeft: 15,
  },
  dialogTitleWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 0,
    minHeight: 150,
  },
  dialogTitle: {
    padding: '12px 24px 8px 24px',
  },
  dialogBtn: {
    marginRight: -12,
  },
  flexWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default withStyles(styles)(PropertyMaterials);
