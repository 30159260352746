import {set} from 'lodash';
import axios from 'axios';
import React, {Component} from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import {showResponseError} from '../../utils';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import {TextField} from 'redux-form-material-ui';

class RequestAdditional extends Component {
  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      submitting,
      onClose,
    } = this.props;

    return (
      <Dialog open classes={{paper: classes.dialog}}>
        <DialogTitle>
          Добавить доп. критерии
          <IconButton className={classes.closeButton} onClick={() => onClose()}>
            <icons.Close/>
          </IconButton>
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Field
                style={{marginTop: 24}}
                name="additional"
                label="Дополнительные критерии"
                component={TextField}
                multiline
                rows={2}
                rowsMax={15}
                fullWidth
                margin="none"
              />
              <FormHelperText>
                Опишите дополнительные критерии текстом до 500 символов
              </FormHelperText>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button type="button" disabled={submitting} onClick={() => onClose()}>
            Отменить
          </Button>
          <Button
            type="button"
            disabled={pristine || submitting}
            onClick={handleSubmit(this.submit)}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = ({additional}) => {
    const {onSubmitted, id} = this.props;
    return axios
      .post('/api/v1/addRequestAdditional', {id, additional})
      .then(() => onSubmitted())
      .catch(error => {
        showResponseError(error.response, SubmissionError, set);
      });
  };
}

const styles = () => ({
  dialog: {
    maxWidth: 960,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

RequestAdditional = withStyles(styles)(RequestAdditional);

export default reduxForm({
  form: 'request_additional',
})(RequestAdditional);
