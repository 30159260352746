import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as icons from '@material-ui/icons';

import {queryContactDeals} from '../../../queries';
import DealListItem from '../../../components/relations/DealListItem';

class Deals extends Component {
  state = {
    loading: false,
    error: null,
    deals: [],
  };

  componentDidMount() {
    this.load(); 
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  load() {
    this.setState({
      loading: true,
    });
    queryContactDeals(this.props.contact.id)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            deals: resp.data.data.contactDeals,
          });
        } else {
          this.setState({
            error: true,
          });
        }
      }, () => {
        this.setState({
          error: true,
        });
      })
      .then(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const {deals, error, loading} = this.state;
    const {handleDialogOpen, handleAddDeal} = this.props;

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Заявки
          <ListItemSecondaryAction>
            {loading ? (
              <IconButton disabled>
                <CircularProgress size={24}/>
              </IconButton>
            ) : (
              error ? (
                <IconButton disabled>
                  <icons.ErrorOutline/>
                </IconButton>
              ) : (
                <IconButton onClick={handleAddDeal}>
                  <icons.Add/>
                </IconButton>
              )
            )}
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {deals.map(deal => (
          <DealListItem
            key={deal.id} 
            deal={deal}
            handleDialogOpen={e => handleDialogOpen(e)}
          />
        ))}
      </List>
    );
  }
}

export default Deals;
