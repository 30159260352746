import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {connect} from 'react-redux';
import {mapLabels} from '../../utils';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';

class DealListItem extends Component {
  render() {
    const {
      deal,
      propertyTypeLabels,
      offerTypeLabels,
      onSecondaryActionClick,
      dealTypeLabels,
      dealStatusColor,
    } = this.props;

    let listItemProps = {};

    if (this.props.handleDialogOpen) {
      listItemProps = {
        onClick: e => {
          e.preventDefault();
          this.props.handleDialogOpen(deal.id);
        },
      };
    }

    return (
      <ListItem 
        dense 
        button
        component={Link}
        to={`/deals/${deal.id}`}
        {...listItemProps}
      >
        <Avatar style={{backgroundColor: colors.purple[300]}}>
          <icons.Inbox/>
        </Avatar>
        {deal ? (
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body1">
                {propertyTypeLabels[deal.propertyType] || deal.propertyType}
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography variant="caption" noWrap>
                  {offerTypeLabels[deal.offerType] || deal.offerType}
                </Typography>
                <Typography variant="body2">
                  <span style={{background: (dealStatusColor[deal.status] || {100: colors.white})[100]}}>
                    {dealTypeLabels[deal.status] || deal.status}
                  </span>
                </Typography>
              </React.Fragment>
            }
          />
        ) : null}
        {onSecondaryActionClick ? (
          <ListItemSecondaryAction>
            <IconButton onClick={onSecondaryActionClick}>
              <icons.MoreVert/>
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    );
  }
}

DealListItem = connect(
  state => ({
    offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    dealTypeLabels: mapLabels(state.root.classification.dealStatuses, 'value', 'label'),
    dealStatusColor: mapLabels(state.root.classification.dealStatuses, 'value', 'color'),
  }),
)(DealListItem);

export default DealListItem;
