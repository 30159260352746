import axios from 'axios';

const applicationServerKey = 'BEWjl6q6tJD7lkJCMNGToYgc2yP-4_ETRvd63YGNOnQrJAW4kyieJPGtCWmE8tPaOj3vXT7nOx3CK_O_27KgvEc';

export const checkPushSettings = () => {
  if (!('serviceWorker' in navigator)) {
    return false;
  }

  if (!('PushManager' in window)) {
    return false;
  }

  if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
    return false;
  }

  if (Notification.permission === 'denied') {
    return false;
  }
  return true;
};

const serviceWorkerRegister = () => {
  navigator.serviceWorker.register('/serviceWorker.js?v=2');
};

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const updateSub = () => {
  if(checkPushSettings()){
    serviceWorkerRegister();
    navigator.serviceWorker.ready
      .then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
      .then(sub => {
        if(sub){
          let oldSub = localStorage.getItem('pushNotification');
          if(oldSub){
            let oldSubSettings = JSON.parse(oldSub);

            let contentEncoding = PushManager.supportedContentEncodings[0];
            let publicKey = btoa(String.fromCharCode.apply(null, new Uint8Array(sub.getKey('p256dh'))));
            let authToken = btoa(String.fromCharCode.apply(null, new Uint8Array(sub.getKey('auth'))));
            let endpoint = sub.endpoint;
            if(oldSubSettings.contentEncoding !== contentEncoding ||
              oldSubSettings.publicKey !== publicKey ||
              oldSubSettings.authToken !== authToken ||
              oldSubSettings.endpoint !== endpoint)
            {
              axios.put('/api/v1/pushNotification', {publicKey, contentEncoding, authToken, endpoint, id: oldSubSettings.publicKey})
                .then(resp => {
                  localStorage.setItem('pushNotification', JSON.stringify(resp.data));
                })
                .catch(() => {});
            }
          }
        }
      })
      .catch(() => {
        return;
      });
  }
};

export const pushSub = () => {
  return new Promise((resole, reject) => {
    navigator.serviceWorker.ready
      .then(serviceWorkerRegistration => {
        serviceWorkerRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
        })
          .then(sub => {
            let contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];
            let publicKey = btoa(String.fromCharCode.apply(null, new Uint8Array(sub.getKey('p256dh'))));
            let authToken = btoa(String.fromCharCode.apply(null, new Uint8Array(sub.getKey('auth'))));
            let endpoint = sub.endpoint;

            axios.post('/api/v1/pushNotification', {publicKey, contentEncoding, authToken, endpoint})
              .then(resp => {
                localStorage.setItem('pushNotification', JSON.stringify(resp.data));
                return resole();
              })
              .catch(() => {
                sub.unsubscribe();
                return reject();
              });
          })
          .catch(() => {
            return reject();
          });
      });
  });
};

export const unSubFront = () => {
  navigator.serviceWorker.ready
    .then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
    .then(sub => {
      if(sub){
        sub.unsubscribe();
        localStorage.removeItem('pushNotification');
      }
    });
};

export const unSub = publicKey => {
  return new Promise((resole, reject) => {
    if(publicKey){
      axios.delete('/api/v1/pushNotification', {data: {publicKey}})
        .then(() => {
          return resole();
        })
        .catch(() => {
          return reject();
        });
    }
    else{
      navigator.serviceWorker.ready
        .then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
        .then(sub => {
          let settings = JSON.parse(localStorage['pushNotification']);
          publicKey = (sub && btoa(String.fromCharCode.apply(null, new Uint8Array(sub.getKey('p256dh'))))) || settings.publicKey;
          axios.delete('/api/v1/pushNotification', {data: {publicKey}})
            .then(() => {
              sub.unsubscribe();
              localStorage.removeItem('pushNotification');
              return resole();
            })
            .catch(() => {
              return reject();
            });
        })
        .catch(() => {
          return reject();
        });
    }
  });
};

export const checkSub = callback => {
  if(checkPushSettings())
    navigator.serviceWorker.ready
      .then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
      .then(subscription => {
        callback(subscription && btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh')))));
      });
};
