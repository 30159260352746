import React, {Component} from 'react';
import {connect} from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';

import ContactDetailsWrapper from './ContactDetailsWrapper';
import ContactEdit from './ContactEdit';
import Assignees from './relations/Assignees';
import Deals from './relations/Deals';
import DealOffers from './relations/DealOffers';
import Contracts from './relations/Contracts';
import Documents from './relations/Documents';
import Relationships from './relations/Relationships';
import Properties from './relations/Properties';
import {loadContact, setContactComment, setContactPublicInfo, updateContact} from '../../actions';
import {mapLabels} from '../../utils';
import Row from '../../components/Row';
import Column from '../../components/Column';
import EditableField from '../../components/EditableField';

import ContactDialog from '../contacts/ContactDialog';
import AssigneeDialog from '../contacts/AssigneeDialog';
import PropertyDialog from '../property/PropertyDialog';
import ContractDialog from '../contract/ContractDialog';
import DealDialog from '../deals/DealDialog';
import DealEdit from '../deals/DealEdit';
import DealOfferDialog from '../dealOffers/DealOfferDialog';
import {queryOfferDealOffersPreview} from '../../queries/DealOffers';
import ContractEdit from '../contract/ContractEdit';
import MiddleColumn from '../../components/MiddleColumn';
import getScreensView from '../../utils/getScreensView';
import {hasRole} from '../../utils/roleFunc';
import * as userRoles from '../../constants/userRoles';
import ActivityEventsList from '../../components/ActivityEventsList';

class ContactView extends Component {
  state = {
    openEdit: false,
    addContract: false,
    openContractDialog: false,
    openPropertyDialog: false,
    openContactDialog: false,
    openAssigneeDialog: false,
    openDealDialog: false,
    openAddDeal: false,
    dealOfferLoading: false,
    dealOfferError: false,
    dealOffersActive: [],
    openDealOfferDialog: false,
    contactViewCache: null,
  };

  componentDidMount() {
    this.props.loadContact(this.props.location.key, this.props.match.params.contactId);
    this.getDealOffers();
    if (this.props.contactView) {
      this.setState({contactViewCache: this.props.contactView});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.contactId !== this.props.match.params.contactId) {
      this.props.loadContact(nextProps.location.key, nextProps.match.params.contactId);
    }
    if (nextProps.contactView && nextProps.contactView !== this.props.contactView && nextProps.location.key !== undefined) {
      this.setState({contactViewCache: nextProps.contactView});
    }
  }

  handleDialogClose = name => {
    this.setState({[name]: false});
  };

  handleDialogOpen = (value, name) => {
    this.setState({[name] : value});
  };

  getDealOffers = () => {
    const {match} = this.props;
    this.setState({dealOfferLoading: true});
    queryOfferDealOffersPreview({source: 'ALL', filter: {contact: match.params.contactId}})
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          let dealOfferArrays = this.divideDealOffers(resp.data.data.dealOfferList.items);
          this.setState({dealOffersActive: dealOfferArrays[0], dealOfferLoading: false});          
          return;
        }
        this.setState({dealOfferLoading: false, dealOfferError: true});
      })
      .catch(() => {
        this.setState({dealOfferLoading: false, dealOfferError: true});
      });
  };

  divideDealOffers = dealOfferArray => {
    return dealOfferArray.reduce(([dealOffersActive, dealOffers], elem) => {
      return elem.dealStartAt ? [[...dealOffersActive, elem], dealOffers] : [dealOffersActive, [...dealOffers, elem]];  
    }, [[], []]);
  };

  render() {
    const {
      classes,
      contactView,
      contacts,
      updateContact,
      setContactComment,
      setContactPublicInfo,
      history,
      selfUser,
    } = this.props;

    if (!contactView && !this.state.contactViewCache) {
      return null;
    }

    if (contactView && contactView.loading) {
      return <CircularProgress size={50} thickness={4} className={classes.progress}/>;
    }

    if (contactView && contactView.error) {
      return <icons.ErrorOutline className={classes.progress}/>;
    }

    const {
      openEdit,
      addContract,
      openContactDialog,
      openDealDialog,
      openAssigneeDialog,
      openPropertyDialog,
      openContractDialog,
      openAddDeal,
      dealOfferLoading,
      dealOfferError,
      dealOffersActive,
      openDealOfferDialog,
      contactViewCache,
    } = this.state;

    const contact = contactView && contactView.item ? contacts[contactView.item] : contacts[contactViewCache.item];

    return (
      <React.Fragment>
        <Row key={0}>
          <Column label="Информация" icon={<icons.InfoOutlined/>} key="information">
            <ContactDetailsWrapper
              contact={contact}
              onEdit={() => this.setState({openEdit: true})}
            />
          </Column>
          <Column label="История" icon={<icons.History/>} key="activity">
            <div
              className="column"
              style={{background: colors.grey[200], zIndex: 0}}
            >
              <div className="content" style={{padding: 16}}>
                <MiddleColumn entityId={contact.id} entityName="Contact">
                  <EditableField
                    field={contact.publicInfo}
                    onSave={info => setContactPublicInfo(contact.id, info, contact.publicInfo)}
                    name="Публичная информация"
                    color={colors.blue[100]}
                  />
                  <EditableField
                    field={contact.comment}
                    onSave={comment => setContactComment(contact.id, comment, contact.comment)}
                    name="Комментарий"
                    color={colors.yellow[100]}
                  />
                  {hasRole(selfUser.role, userRoles.ADMIN)
                  || (hasRole(selfUser.role, userRoles.MANAGER) && selfUser.team === contact.team) ?
                    <div style={{marginTop: 20}}>
                      <ActivityEventsList
                        entityId={contact.id}
                        onRef={ref => (this.activityEventsList = ref)}
                        aggregateType="contact"
                      />
                    </div>
                    : null}
                </MiddleColumn>
              </div>
            </div>
          </Column>
          <Column label="Связи" icon={<icons.Share/>} key="relationships">
            <Paper elevation={1} square className="column">
              <div className="content">
                <Assignees contact={contact} handleDialogOpen={e => this.handleDialogOpen(e, 'openAssigneeDialog')}/>
                <Divider/>
                <Relationships contact={contact} handleDialogOpen={e => this.handleDialogOpen(e, 'openContactDialog')}/>
                <Divider/>
                <Documents contact={contact}/>
                <Divider/>
                <Properties contact={contact} handleDialogOpen={e => this.handleDialogOpen(e, 'openPropertyDialog')}/>
                <Divider/>
                <Deals 
                  onRef={ref => (this.dealComponent = ref)}
                  contact={contact} 
                  handleDialogOpen={e => this.handleDialogOpen(e, 'openDealDialog')}
                  handleAddDeal={() => this.setState({openAddDeal: true})}
                />
                <Divider/>
                <DealOffers 
                  loading={dealOfferLoading} 
                  error={dealOfferError} 
                  dealOffers={dealOffersActive} 
                  handleDialogOpen={e => this.handleDialogOpen(e, 'openDealOfferDialog')}
                />
                <Divider/>
                <Contracts 
                  contact={contact}
                  handleDialogOpen={e => this.handleDialogOpen(e, 'openContractDialog')}
                  onContractAdd={() => this.setState({addContract: true})}
                />
                <Divider/>
              </div>
            </Paper>
          </Column>
        </Row>
        {openEdit ? (
          <ContactEdit
            open={openEdit}
            contact={contact}
            onSubmitted={contact => {
              updateContact(contact);
              this.activityEventsList && this.activityEventsList.updateActivity();
              this.setState({openEdit: false});
            }}
            onClose={() => this.setState({openEdit: false})}
          />
        ) : null}
        {openAddDeal ? (
          <DealEdit
            open={openAddDeal}
            sourceContact={contact}
            onSubmitted={({id}) => history.push(`/deals/${id}`)}
            onClose={() => this.setState({openAddDeal: false})}
          />
        ) : null}
        {addContract ? (
          <ContractEdit
            open={addContract}
            sourceContact={contact}
            onSubmitted={({id}) => history.push(`/contracts/${id}`)}
            onClose={() => this.setState({addContract: false})}
          />
        ) : null}
        {openContactDialog ? (
          <ContactDialog
            history={history}
            contact={openContactDialog}
            open={Boolean(openContactDialog)}
            handleClose={() => this.handleDialogClose('openContactDialog')}
          />
        ) : null}
        {openAssigneeDialog ? (
          <AssigneeDialog
            history={history}
            member={openAssigneeDialog}
            open={Boolean(openAssigneeDialog)}
            handleClose={() => this.handleDialogClose('openAssigneeDialog')}
          />
        ) : null}
        {openPropertyDialog ? (
          <PropertyDialog 
            history={history}
            property={openPropertyDialog}
            open={Boolean(openPropertyDialog)}
            handleClose={() => this.handleDialogClose('openPropertyDialog')}
          />
        ) : null}
        {openDealDialog ? (
          <DealDialog 
            history={history}
            deal={openDealDialog}
            open={Boolean(openDealDialog)}
            handleClose={() => this.handleDialogClose('openDealDialog')}
            reload={() => this.dealComponent.load()}
          />
        ) : null}
        {openDealOfferDialog ? (
          <DealOfferDialog
            history={history}
            dealOffer={openDealOfferDialog}
            open={Boolean(openDealOfferDialog)}
            handleClose={() => this.handleDialogClose('openDealOfferDialog')}
            reload={() => this.getDealOffers()} 
          />
        ) : null}
        {openContractDialog ? (
          <ContractDialog 
            history={history}
            contract={openContractDialog}
            open={Boolean(openContractDialog)}
            handleClose={() => this.handleDialogClose('openContractDialog')}
            // reload={() => this.dealComponent.load()}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const styles = () => ({
  itemText: {},
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
});

export default connect(
  (state, ownProps) => ({
    contactTypeLabels: mapLabels(state.root.classification.contactTypes, 'value', 'label'),
    contactView: getScreensView(state.root.contact.screens, ownProps.location.key),
    contacts: state.root.contact.contacts,
    selfUser: state.root.selfUser,
  }),
  {
    loadContact,
    updateContact,
    setContactComment,
    setContactPublicInfo,
  }
)(withStyles(styles)(ContactView));
