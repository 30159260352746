import React, {Component} from 'react';
import classNames from 'classnames';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MuiCalendar from 'material-ui-pickers/DatePicker/components/Calendar';
import {withUtils} from 'material-ui-pickers/_shared/WithUtils';
import {startOfWeek, endOfWeek, eachDayOfInterval} from 'date-fns/esm';
import {ru} from 'date-fns/locale';

class Calendar extends Component {
  render() {
    const {classes, onChange, selectedDate, utils, taskDays, weekSelected} = this.props;
    const currentDate = new Date();

    const dayHasTask = (taskDays, day) => {
      return taskDays.some(elem => utils.isEqual(elem, day));
    };

    const highlightSelectedWeek = (selectedDate, day) => {
      let startDay = startOfWeek(selectedDate, {locale: ru});
      let endDay = endOfWeek(selectedDate, {locale: ru});
      let weekArray = eachDayOfInterval({start: startDay, end: endDay});
      weekArray.push(startDay, endDay);
      return weekArray.some(elem => utils.isEqual(elem, day));
    };

    return (
      <MuiCalendar
        date={selectedDate}
        onChange={onChange}
        allowKeyboardControl={false}
        renderDay={(day, selectedDate, dayInCurrentMonth) => {
          const className = classNames(classes.day, {
            [classes.dayWithEvent]: false,
            [classes.dayNotInCurrentMonth]: !dayInCurrentMonth,
            [classes.selectedDay]: utils.isSameDay(selectedDate, day),
            [classes.currentDay]: utils.isSameDay(currentDate, day),
            [classes.hasTask]: taskDays ? dayHasTask(taskDays, day) : false,
            [classes.selectedWeek]: weekSelected ? highlightSelectedWeek(selectedDate, day) : false,
          });
          return (
            <IconButton className={className}>
              <span className={classes.dayLabel}>{utils.getDayText(day)}</span>
            </IconButton>
          );
        }}
      />
    );
  }
}

const styles = theme => ({
  calendar: {
    width: 320,
    zIndex: 1,
    padding: 16,
    boxSizing: 'border-box',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dayWithEvent: {
    color: colors.red[400],
    fontWeight: 800,
  },
  dayNotInCurrentMonth: {
    color: theme.palette.text.hint,
  },
  dayLabel: {},
  closeIcon: {
    visibility: 'hidden',
    position: 'absolute',
  },
  selectedDay: {
    backgroundColor: colors.blueGrey[50],
  },
  selectedWeek: {
    backgroundColor: colors.blueGrey[50],
  },
  currentDay: {
    fontWeight: 800,
  },
  hasTask: {
    position: 'relative',
    '&:after': {
      position: 'absolute',
      content: '""',
      height: 5,
      width: 5,
      backgroundColor: colors.red[500],
      borderRadius: '50%',
      bottom: 2,
    },
  },
});

export default withStyles(styles)(withUtils()(Calendar));
