import axios from 'axios';
import {set} from 'lodash';
import React, {Component} from 'react';
import {SubmissionError, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import {Field} from 'redux-form';
import {Checkbox} from 'redux-form-material-ui';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import TextField from '../../../components/Input/TextField';
// import Form from './form';

class DistrcitEdit extends Component {
  componentDidMount() {
    if (this.props.district) {
      this.props.initialize({
        ...this.props.district,
      });
    } else {
      this.props.initialize({
        archived: false,
        city: this.props.city,
      });
    }
  }

  render() {
    const {
      classes,
      handleSubmit,
      initialized,
      open,
      pristine,
      submitting,
      onClose,
    } = this.props;

    if (!open || !initialized) {
      return null;
    }

    return (
      <Dialog open={open} classes={{paper: classes.dialog}}>
        <DialogTitle>
          Район
          <IconButton className={classes.closeButton} onClick={() => onClose()}>
            <icons.Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Field
            name="name"
            label="Название"
            required
            component={TextField}
            fullWidth
            margin="normal"
          />
          <Field
            name="code"
            label="Код"
            component={TextField}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Field name="archived" component={Checkbox} />}
              label={'Архивирован'}
              required
            />
          </FormControl>
          {/* <Form
            change={change}
            array={array}
            member={member}
          /> */}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button type="button" disabled={submitting} onClick={() => onClose()}>
            Отменить
          </Button>
          <Button
            type="button"
            disabled={pristine || submitting}
            onClick={handleSubmit(this.submit)}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = values => {
    const {district, onSubmitted} = this.props;
    let url = '/api/v1/createDistrict';

    if (district) {
      url = '/api/v1/updateDistrict';
    }

    return axios
      .post(url, values)
      .then(() => onSubmitted())
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(
            error.response.data.error.errors.reduce(
              (errors, {propertyPath, message}) => {
                return set(errors, propertyPath, message);
              },
              {}
            )
          );
        } else if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

const styles = () => ({
  dialog: {
    maxWidth: 960,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

export default reduxForm({
  form: 'district',
})(withStyles(styles)(DistrcitEdit));
