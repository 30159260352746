import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import * as colors from '@material-ui/core/colors';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

class EditableComment extends Component {
  state = {
    editing: false,
    saving: false,
    value: '',
  };

  handleClickCancel = () => {
    this.setState({editing: false, value: this.props.comment});
  };

  handleClickSave = () => {
    if (this.props.onSave) {
      this.setState({saving: true});
      this.props.onSave(this.textareaRef.value)
        .then(() => this.setState({editing: false}))
        .catch(() => {
          alert('Ошибка при сохранении комментария');
        })
        .then(() => this.setState({saving: false}));
    }
  };
  componentDidMount(){
    this.setState({value: this.props.comment});
  }
  render() {
    const {comment, classes, max, min} = this.props;
    const {editing, saving, value} = this.state;
    return (
      editing ? (
        <Paper
          className={classNames(classes.commentArea, classes.commentEdit)}
          elevation={0}
        >
          <Typography variant="subtitle2" style={{padding: '16px 16px 0 16px'}}>Комментарий</Typography>
          <TextField
            defaultValue={comment}
            multiline
            rowsMax={15}
            fullWidth
            onChange={e => {
              this.setState({value: e.currentTarget.value});
            }}
            disabled={saving}
            inputRef={el => {
              this.textareaRef = el;
            }}
            InputProps={{
              className: classes.textarea,
              classes: {
                multiline: classes.inputMultiline,
                input: classes.inputRoot,
              },
            }}
          />
          <div className={classes.icons}>
            {(value && max) || min
              ? (<Typography style={{flex: 1, textAlign: 'left', alignSelf: 'center', paddingLeft: 8}}>{value.length}/{min ? min : ''}{min && max ? '-' : ''}{max ? max : ''}</Typography>)
              : null}
            <Button
              className={classes.button}
              onClick={this.handleClickCancel}
              disabled={saving}
            >
              Отменить
            </Button>
            <Button
              className={classes.button}
              onClick={this.handleClickSave}
              disabled={saving}
            >
              Сохранить
            </Button>
          </div>
        </Paper>
      ) : (
        <Paper
          className={classNames(classes.commentArea, classes.commentText)}
          elevation={0}
          onClick={() => this.setState({editing: true})}
        >
          <Typography variant="subtitle2">Комментарий</Typography>
          <Typography margin="dense" variant="body1" className={classes.commentTypography}>
            {comment ? (
              comment
            ) : (
              <icons.ChatBubbleOutline className={classes.commentIcon}/>
            )}
            <icons.Edit className={classes.editIcon}/>
          </Typography>
        </Paper>
      )
    );
  }
}

const styles = theme => ({
  commentTypography: {
    whiteSpace: 'pre-line',
    overflow: 'hidden',
  },
  editIcon: {
    display: 'none',
    fontSize: 18,
    marginLeft: 8,
    color: colors.grey[600],
    verticalAlign: 'middle',
  },
  commentIcon: {
    display: 'inline-block',
    marginLeft: 8,
    fontSize: 18,
    color: colors.grey[600],
    verticalAlign: 'middle',
  },
  commentArea: {
    backgroundColor: colors.yellow[100],
  },
  commentText: {
    padding: 16,
    cursor: 'pointer',
    position: 'relative',
    '&:hover $editIcon': {
      display: 'inline-block',
    },
    '&:hover $commentIcon': {
      display: 'none',
    },
  },
  commentEdit: {},
  textarea: {
    padding: '0 16px 8px',
    boxSizing: 'border-box',
  },
  inputRoot: theme.typography.body1,
  icons: {
    padding: 8,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export default withStyles(styles)(EditableComment);
