import * as colors from '@material-ui/core/colors/index';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, FormSection} from 'redux-form';
import {Checkbox} from 'redux-form-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import PhoneInput from '../../../components/Input/PhoneInput';
import TextField from '../../../components/Input/TextField';
import AccessComponent from '../../../components/AccessComponent';
import CityField from './City';
import Typography from '@material-ui/core/Typography';
import Variant from '../../../components/Input/Variant';

class Form extends Component {
  render() {
    const {
      userRoles,
      classes,
      selfUser,
      member,
      change,
      city,
      readOnlySections,
    } = this.props;

    function getReadOnlyLabels(values) {
      let labels = [];

      values.forEach(function (value) {
        if (readOnlySections[value]) {
          labels.push(readOnlySections[value]);
        }
      });

      return labels.join(', ');
    }

    return (
      <FormSection name="member">
        <Field
          name="name"
          label="Имя"
          required
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="city"
          label="Название"
          component={CityField}
          cities={city ? [city] : null}
          addMethod={(name, id) => change('member.city', id)}
          removeMethod={() => change('member.city', null)}
        />
        <Field
          name="email"
          type="email"
          label="Адрес эл. почты"
          required
          component={TextField}
          fullWidth
          margin="normal"
        />
        <Field
          name="phone"
          label="Телефон"
          required
          component={TextField}
          InputProps={{inputComponent: PhoneInput}}
          fullWidth
          margin="normal"
        />
        <AccessComponent
          manager
          condition={member && selfUser.id === member.id}
        >
          <Field
            name="commission"
            label="Комиссия, %"
            component={TextField}
            normalize={value => value ? value.replace(',', '.') : value}
            fullWidth
            margin="normal"
          />
        </AccessComponent>
        <Typography variant="h6" className={classes.sectionLabel}>Права доступа</Typography>
        <div style={{display: 'flex', alignItems: 'end', justifyContent: 'end'}}>
          <Field
            name="role"
            label="Роль"
            required
            component={TextField}
            select
            fullWidth
            margin="normal"
          >
            {userRoles.map(({value, label}, i) => (
              <MenuItem value={value} key={i}>{label}</MenuItem>
            ))}
          </Field>
          <Tooltip
            enterTouchDelay={0}
            title={
              <div>
                <ul style={{padding: '0 10px'}}>
                  <li>Участник команды — может создавать сущности и редактировать те, где он ответственный.</li>
                  <li>Руководитель команды — может создавать и редактировать все сущности команды.</li>
                  <li>Контент-менеджер — те же права, что и у консультанта, но дополнительно может редактировать все листинги.</li>
                </ul>
                <p>Комментарии и контактные данные видят только ответственные и руководители.</p>
              </div>
            }>
            <icons.Help className={classes.help}/>
          </Tooltip>
        </div>
        <Field
          name="readOnly"
          label='Разделы в режиме "Только просмотр"'
          component={TextField}
          select
          SelectProps={{
            multiple: true,
            renderValue: values => getReadOnlyLabels(values),
          }}
          format={value => value || []}
          fullWidth
          margin="dense"
        >
          {Object.keys(readOnlySections).map((sectionId, i) =>
            <Variant value={sectionId} label={readOnlySections[sectionId]} key={i}/>
          )}
        </Field>
        <FormControl fullWidth margin="dense">
          <FormControlLabel
            control={<Field name="authenticatable" component={Checkbox}/>}
            label={'Может авторизоваться'}
            required
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <FormControlLabel
            control={<Field name="canPublishOffers" component={Checkbox}/>}
            label={'Может публиковать листинги'}
            required
          />
        </FormControl>
      </FormSection>
    );
  }
}

const styles = {
  help: {
    color: colors.grey[700],
    marginLeft: 12,
    marginBottom: 8,
  },
  sectionLabel: {
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 20,
  },
};

export default connect(
  state => ({
    userRoles: state.root.classification.userRoles,
    selfUser: state.root.selfUser,
    readOnlySections: {
      'favorites': 'Избранное',
      'tasks': 'Задачи',
      'pressRelease': 'Пресс-релизы',
      'properties': 'Объекты',
      'offers': 'Листинги',
      'deals': 'Заявки',
      'dealOffers': 'Сделки',
      'contacts': 'Контакты',
      'contracts': 'Договоры',
      'team': 'Команда',
      'uploads': 'Загрузка файлов',
      'net_renters': 'Сетевые арендаторы',
      'requests': 'Заявки beta',
    },
  })
)(withStyles(styles)(Form));
