import React, {Component} from 'react';
import {connect} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as icons from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';

import MemberList from '../team/MemberList';
import {addAssigneeToOffer} from '../../actions';

class OfferAssignees extends Component {
  state = {
    openItemMenu: null,
    currentItem: null,
    assigneeAdded: false,
    selectedItems: [],
  };

  componentDidMount() {
    this.load();
  }

  load() {
    const {match} = this.props;
    let thisOfferId = match.params.offerId;
    axios.get(`/api/offers/${thisOfferId}`)
      .then(res => {
        this.setState({selectedItems: res.data.assignees});
      })
      .catch(() => {
        alert('Произошла ошибка');
      });
  }

  render() {
    const {match, selfUserTeams} = this.props;
    const {
      currentItem,
      openItemMenu,
      assigneeAdded,
      selectedItems,
    } = this.state;

    return (
      <React.Fragment>
        {selfUserTeams[0] ? (
          <MemberList
            selectedItems={selectedItems}
            teamId={selfUserTeams[0].id}
            action={(item, selected) => (
              <IconButton 
                disabled={selected}
                onClick={e => {
                  this.setState({
                    openItemMenu: e.currentTarget,
                    currentItem: item,
                  });
                  e.stopPropagation();
                }}>
                <icons.MoreVert/>
              </IconButton>
            )}
          />
        ) : null}
        <Menu
          anchorEl={openItemMenu}
          open={Boolean(openItemMenu)}
          onClick={() => this.setState({openItemMenu: null})}
        >
          <MenuItem
            onClick={() => {
              this.handleClickAdd({
                offerId: match.params.offerId,
                assigneeId: currentItem.id,
              });
            }}
          >
            Назначить ответственным
          </MenuItem>
        </Menu>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(assigneeAdded)}
          autoHideDuration={4000}
          onClose={() => this.setState({assigneeAdded: false})}
          message="Ответственный назначен"
          action={
            <IconButton
              color="inherit"
              onClick={() => this.setState({assigneeAdded: false})}
            >
              <icons.Close/>
            </IconButton>
          }
        />
      </React.Fragment>
    );
  }

  handleClickAdd = data => {
    return axios.post(`/api/offers/${data.offerId}/assignees`, data)
      .then(response => {
        if(response.status === 200) {
          this.props.addAssigneeToOffer(data.offerId, data.assigneeId);
          this.setState({assigneeAdded: true, selectedItems: [...this.state.selectedItems, this.state.currentItem.id]});
        }}
      )
      .catch(error => {
        if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else if (error.response.status === 500) {
          alert('Пользователь уже является ответственным');
        }  else {
          alert('Произошла ошибка');
        }
      });
  };
}

export default connect(
  state => ({
    selfUserTeams: state.root.selfUser.teams,
  }),
  {
    addAssigneeToOffer,
  }
)(OfferAssignees);
