import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';


class Video extends Component {

  render() {
    const {classes, url, id} = this.props;
    if(!url)
      return null;
    return(
      <div className={classes.container}>
        <iframe
          width="100%"
          title={id}
          height="100%"
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>);
  }
}
const styles = theme => ({
  container:{
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    height: 400,
    marginTop: 20,
  },
});

export default withStyles(styles)(Video);
