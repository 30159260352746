import {omit} from 'lodash';

const defaultState = {
  offerList: {
    columns: {
      propertyType: true,
      owner: true,
      offerType: true,
      area: true,
      priceForObj: true,
      priceForM: true,
      dates: true,
      assignees: true,
      status: true,
    },
    page: 30,
  },
  propertyList: {
    columns: {
      owner: true,
      category: true,
      area: true,
      status: true,
      assignees: true,
      dates: true,
    },
    page: 30,
  },
  dealList: {
    columns: {
      contact: true,
      requirements: true,
      assignee: true,
      offerType: true,
      propertyPurpose: true,
      abcClass: false,
    },
    page: 30,
  },
  requestList: {
    columns: {
      source: true,
      area: true,
      price: true,
      activity: true,
      contact: true,
      requirements: true,
      status: true,
      assignee: true,
      offers: true,
    },
    page: 30,
  },
  dealOfferList: {},
  contactList: {},
  load: false,
};

const settings = (state = defaultState, {type, ...payload}) => {

  switch (type) {
    case 'GET_USER_SETTINGS': {
      return {
        ...state,
        ...payload.listSettings,
        load: true,
      };
    }
    case 'SET_LIST_SETTINGS': {
      return {
        ...state,
        [payload.entityList]: {
          columns: {
            ...payload.columns,
          },
          page: payload.page,
        },
      };
    }
    case 'CLEAR_LIST_SETTINS': {
      return {
        state: omit(state, payload.entityList),
      };
    }

    default:
      return state;
  }
};

export default settings;
