import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import * as icons from '@material-ui/icons';

import AddDocument from '../form/AddDocument';
import RemoveDocument from '../form/RemoveDocument';
import DocumentListItem from '../../../components/relations/DocumentListItem';
import {queryDealDocuments} from '../../../queries';

class Documents extends Component {
  state = {
    addDocument: null,
    openMenu: false,
    removeDocument: false,
  };

  componentDidMount() {
    this.reload();
  }

  reload() {
    this.setState({
      loading: true,
    });
    queryDealDocuments(this.props.deal.id)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            documents: resp.data.data.deal.documents,
          });
        } else {
          this.setState({
            error: true,
          });
        }
      }, () => {
        this.setState({
          error: true,
        });
      })
      .then(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleRemoveSubmitted = () => {
    this.reload();
    this.setState({
      openMenu: null,
      removeDocument: null,
    });
    this.props.updateActivity();
  };

  handleAddSubmitted = () => {
    this.reload();
    this.setState({
      openMenu: null,
      addDocument: null,
    });
    this.props.updateActivity();
  };

  render() {
    const {
      deal,
    } = this.props;
    const {addDocument, openMenu, removeDocument, documents} = this.state;

    if (!deal) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Документы
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => {
                this.setState({
                  addDocument: {
                    dealId: deal.id,
                  },
                });
              }}
            >
              <icons.Add/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {documents ? documents.map((document, index) => (
          <DocumentListItem
            key={index}
            document={document}
            onSecondaryActionClick={e => this.setState({openMenu: {target: e.currentTarget, path: document.path}})}
          />
        )) : null}
        {openMenu ? (
          <Menu
            anchorEl={openMenu.target}
            open={Boolean(openMenu)}
            onClick={() => this.setState({openMenu: false})}
          >
            <MenuItem onClick={() => {
              this.setState({
                removeDocument: {
                  id: deal.id,
                  path: openMenu.path,
                },
              });
            }}>
              Удалить документ
            </MenuItem>
          </Menu>
        ) : null}
        {removeDocument ? (
          <RemoveDocument
            open={Boolean(removeDocument)}
            initialValues={removeDocument}
            onSubmitted={this.handleRemoveSubmitted}
            onClose={() => this.setState({removeDocument: null})}
          />
        ) : null}
        {addDocument ? (
          <AddDocument
            open={Boolean(addDocument)}
            initialValues={addDocument}
            onSubmitted={this.handleAddSubmitted}
            onClose={() => this.setState({addDocument: null})}
          />
        ) : null}
      </List>
    );
  }
}

export default Documents;
