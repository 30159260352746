import React from 'react';
import MuiDatePicker from 'material-ui-pickers/DatePicker';

const DatePicker = props => {
  const {
    showErrorsInline,
    dispatch,
    input: {onChange, value},
    meta: {touched, error},
    ...other
  } = props;

  const showError = showErrorsInline || touched;
  const today = new Date().setHours(0,0,0,0);
  return (
    <MuiDatePicker
      error={!!(showError && error)}
      helperText={showError && error}
      value={value || null}
      onChange={onChange}
      {...other}
      clearLabel="Сбросить"
      cancelLabel="Отменить"
      initialFocusedDate={today}
    />
  );
};

export default DatePicker;
