import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import TextField from '../../../components/Input/TextField';
import Variant from '../../../components/Input/Variant';
import DatePicker from '../../../components/Input/DatePicker';
import {getLabelsForValue} from '../../../utils';

class FilterContracts extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      contractTypes,
      onClose,
      onSubmit,
      open,
      pristine,
      teamMembers,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Настройки фильтра</DialogTitle>
        <Divider/>
        <DialogContent>
          <Field
            name="searchQuery"
            label="Поисковый запрос"
            component={TextField}
            fullWidth
            margin="normal"
          />
          <Field
            name="types"
            label="Тип договора"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(contractTypes, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {contractTypes.map(({label, value}, index) => (
              <Variant key={index} value={value} label={label}/>
            ))}
          </Field>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="startAtFrom"
                  label="Заключен с"
                  format={null}
                  component={props => (
                    <DatePicker
                      format="yyyy-MM-dd"
                      clearable
                      {...props}
                    />
                  )}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="startAtTo"
                  label="по"
                  format={null}
                  component={props => (
                    <DatePicker
                      format="yyyy-MM-dd"
                      clearable
                      {...props}
                    />
                  )}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="endAtFrom"
                  label="Оканчивается с"
                  format={null}
                  component={props => (
                    <DatePicker
                      format="yyyy-MM-dd"
                      clearable
                      {...props}
                    />
                  )}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="endAtTo"
                  label="по"
                  format={null}
                  component={props => (
                    <DatePicker
                      format="yyyy-MM-dd"
                      clearable
                      {...props}
                    />
                  )}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Field
            name="assignees"
            label="Ответственные"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(teamMembers, value, 'id', 'name').join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {teamMembers.map(({id, name}, index) => (
              <Variant key={index} value={id} label={name}/>
            ))}
          </Field>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(onSubmit)}
          >
            Применить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

FilterContracts = connect(
  state => ({
    dealOfferActiveStatuses: state.root.classification.dealOfferActiveStatuses,
    contractTypes: state.root.classification.contractTypes,
    propertyTypes: state.root.classification.propertyTypes,
    propertyPurposes: state.root.classification.propertyPurposes,
    teamMembers: state.root.selfUser.teamMembers,
  })
)(FilterContracts);

export default reduxForm({
  form: 'filter_contracts',
  enableReinitialize: true,
})(FilterContracts);
