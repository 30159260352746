import React, {Component} from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ContractDetails from './ContractDetails';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as icons from '@material-ui/icons';
import {mapLabels} from '../../utils';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import axios from 'axios';
import {Link} from 'react-router-dom';

import {contractQuery} from '../../queries/ContractsQuery';
import {Query, withApollo} from 'react-apollo';
import gql from 'graphql-tag';

class ContractDialog extends Component {
  state = {
    contract: null,
    loading: null,
    error: null,
  };

  saveRequirements = (id, additional) => {
    const {client} = this.props;
    return axios.post('/api/v1/setContractAdditionalAgreements', {id, additionalAgreements: additional})
      .then(() => {
        return client.writeFragment({
          id,
          fragment: gql`
            fragment contractComment on Contract {
              additionalAgreements
            }
          `,
          data: {
            __typename: 'Contract',
            additionalAgreements: additional,
          },
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  };

  render() {
    const {
      classes,
      contractTypeLabels,
      handleClose,
      open,
    } = this.props;

    return (
      <Query query={contractQuery} variables={{id: this.props.contract}}>
        {({data, error, loading}) => {
          let contract = null;
          if (data && data.contract) {
            contract = data.contract;
          }  
          return (
            <Dialog 
              open={open}
              onClose={handleClose}
              classes={{paper: classes.dialog}}
            > 
              {contract ? (
                <React.Fragment>
                  <DialogTitle className={classes.dialogTitle}>
                    <div className={classes.dialogTitleWrap}>
                      <div className={classes.dialogText}>
                        <Typography variant="h6">{contract.no}</Typography>
                        <Typography variant="subtitle1">{contractTypeLabels[contract.type] || contract.type}</Typography>
                      </div>
                      <div className={classes.dialogBtn}>
                        <IconButton
                          component={Link}
                          to={`/contracts/${contract.id}`}
                          classes={{root: classes.dialogIcon}}
                        >
                          <icons.OpenInNew/>
                        </IconButton>
                        <IconButton
                          onClick={handleClose}
                          classes={{root: classes.dialogIcon}}
                        >
                          <icons.Close/>
                        </IconButton>
                      </div>
                    </div>
                  </DialogTitle>
                  <Divider/>
                  <DialogContent className={classes.dialogContent}>
                    <ContractDetails 
                      contract={contract}
                      onRequirementsChange={additional => this.saveRequirements(contract.id, additional, contract.additionalAgreements)}
                    />
                  </DialogContent>
                </React.Fragment>
              ) : null}
              {loading ? (
                <React.Fragment>
                  <DialogTitle className={classes.dialogTitle}>
                    <div className={classes.dialogTitleWrap}>
                      <div className={classes.dialogText}>
                        <Typography variant="h6">Загрузка заявки</Typography>
                      </div>
                      <div className={classes.dialogBtn}>
                        <IconButton
                          onClick={handleClose}
                          classes={{root: classes.dialogIcon}}
                        >
                          <icons.Close/>
                        </IconButton>
                      </div>
                    </div>
                  </DialogTitle>
                  <Divider/>
                  <DialogContent className={classNames(classes.dialogContent, classes.flexWrap)}>
                    <CircularProgress color="primary"/>
                  </DialogContent>
                </React.Fragment>
              ) : null}
              {error ? (
                <React.Fragment>
                  <DialogTitle className={classes.dialogTitle}>
                    <div className={classes.dialogTitleWrap}>
                      <div className={classes.dialogText}>
                        <Typography variant="h6">Не удалось загрузить договор</Typography>
                      </div>
                      <div className={classes.dialogBtn}>
                        <IconButton
                          onClick={handleClose}
                          classes={{root: classes.dialogIcon}}
                        >
                          <icons.Close/>
                        </IconButton>
                      </div>
                    </div>
                  </DialogTitle>
                  <Divider/>
                  <DialogContent className={classNames(classes.dialogContent, classes.flexWrap)}>
                    <icons.ErrorOutline/>
                  </DialogContent>
                </React.Fragment>
              ) : null}
            </Dialog>
          );
        }}
      </Query>
    );
  }
}

const styles = theme => ({
  dialog: {
    width: 550,
    maxWidth: '100%',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100%',
    },
  },
  dialogIcon: {
    width: 36,
    height: 36,
    padding: 4,
  },
  dialogTitleWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 0,
    minHeight: 150,
  },
  dialogTitle: {
    padding: '12px 24px 8px 24px',
  },
  dialogBtn: {
    marginRight: -12,
    minWidth: 80,
  },
  flexWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

ContractDialog = withApollo(ContractDialog);

export default connect(
  state => ({
    contractTypeLabels: mapLabels(state.root.classification.contractTypes, 'value', 'label'),
  }),
)(withStyles(styles)(ContractDialog));
