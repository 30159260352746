import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setTitle} from '../../actions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import {TextField} from 'redux-form-material-ui';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import SnackbarError from '../../components/SnackbarError';
import SnackbarSuccess from '../../components/SnackbarSuccess';
import axios from 'axios';
import {set} from 'lodash';

class ChangePasswordView extends Component {
  state = {
    title: 'Изменение пароля',
    successMessage: null,
    errorMessage: null,
    passwordError: false,
    confirmPasswordError: false,
    passwordErrorMessage: null,
    confirmPasswordErrorMessage: null,
    submitting: false,
  };

  componentDidMount() {
    this.props.setTitle(this.state.title);
  }

  render() {
    const {
      submitting,
      successMessage,
      errorMessage,
      passwordError,
      confirmPasswordError,
      passwordErrorMessage,
      confirmPasswordErrorMessage,
    } = this.state;

    return (
      <React.Fragment>
        <Dialog open maxWidth="xs" fullWidth>
          <DialogTitle>
            {this.state.title}
          </DialogTitle>
          <Divider/>
          <DialogContent>
            <TextField
              name="password"
              label="Новый пароль"
              type="password"
              inputProps={{ref: input => (this.password = input)}}
              fullWidth
              margin="normal"
              error={passwordError}
            />
            <TextField
              name="confirmPassword"
              label="Повтор пароля"
              type="password"
              inputProps={{ref: input => (this.confirmPassword = input)}}
              fullWidth
              margin="normal"
              error={confirmPasswordError}
            />
          </DialogContent>
          <Divider/>
          <DialogActions>
            <Typography
              variant="body1"
              color="textSecondary"
              component="a"
              href="#"
              onClick={this.backNav}
              style={{
                fontSize: 14,
                position: 'absolute',
                left: '20px',
                opacity: .5,
                fontWeight: 400,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                lineHeight: '1.46429em',
              }}
            >
              Назад
            </Typography>
            <Button
              type="submit"
              disabled={submitting}
              onClick={this.handleSubmit}
            >
              Сохранить
            </Button>
          </DialogActions>
        </Dialog>
        <SnackbarError
          open={!!errorMessage}
          errorMessage={errorMessage}
          onClose={() => this.setState({errorMessage: null})}
        />
        <SnackbarError
          setPosition={1}
          open={!!passwordErrorMessage}
          errorMessage={passwordErrorMessage}
          onClose={() => this.setState({passwordErrorMessage: null})}
        />
        <SnackbarError
          setPosition={2}
          open={!!confirmPasswordErrorMessage}
          errorMessage={confirmPasswordErrorMessage}
          onClose={() => this.setState({confirmPasswordErrorMessage: null})}
        />
        <SnackbarSuccess
          open={!!successMessage}
          message={successMessage}
          onClose={() => this.setState({successMessage: null})}
        />
      </React.Fragment>
    );
  }

  handleSubmit = () => {
    if (!this.password.value || !this.confirmPassword.value) {
      return;
    }

    this.setState({submitting: true});
    axios
      .create()
      .post('/api/security/changePassword', {
        password: this.password.value,
        confirmPassword: this.confirmPassword.value,
      })
      .then(() => {
        this.backNav();
      })
      .catch(error => {
        if (error.response.status === 400) {
          let fields = {};

          error.response.data.error.errors.reduce((errors, {propertyPath, message}) => {
            return set(fields, propertyPath, message);
          }, {});

          this.setState({
            passwordErrorMessage: fields.password ? fields.password : null,
            confirmPasswordErrorMessage: fields.confirmPassword ? fields.confirmPassword : null,
            passwordError: !!fields.password,
            confirmPasswordError: !!fields.confirmPassword,
          });
        } else
          this.setState({errorMessage: 'Неизвестная ошибка сервера', error: true});
      })
      .then(() => {
        this.setState({
          submitting: false,
        });
      });
  };

  backNav = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = '/';
    }
  };
}

export default connect(null, {setTitle})(ChangePasswordView);
