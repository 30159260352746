import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import UpdateList from './UpdateList';
import {setTitle} from '../../actions';

class Update extends Component {
  componentDidMount() {
    this.props.setTitle('Обновления');
  }

  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route path={`${match.url}/`} exact component={UpdateList}/>
        <Route path={`${match.url}/:updateId/`} exact component={UpdateList}/>
      </Switch>
    );
  }
}

export default connect(null, {setTitle})(Update);
