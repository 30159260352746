import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';

class TeamListItem extends Component {
  render() {
    const {
      name,
    } = this.props;

    return (
      <ListItem
        dense
      >
        <Avatar style={{backgroundColor: colors.red[300]}}>
          <icons.Group/>
        </Avatar>
        {name ? (
          <ListItemText
            primary={
              <Typography variant="body1" noWrap>
                {name}
              </Typography>
            }
            disableTypography
          />
        ) : null}
      </ListItem>
    );
  }
}

export default TeamListItem;
