import axios from 'axios';
import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

class EmailForm extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      open,
      classes,
    } = this.props;

    return (
      <Dialog 
        fullWidth 
        maxWidth="xs"
        classes={{paper: classes.noteDialog}}
        open={open}
      >
        <DialogTitle>Отправьте нам личное сообщение</DialogTitle>
        <Divider/>
        <DialogContent className={classes.dialogContent}>
          <Field
            name="message"
            placeholder="Текст сообщения"
            multiline
            rowsMax={15}
            component={TextField}
            fullWidth
            inputRef={el => {
              this.textareaRef = el;
            }}
          />
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отмена
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={handleSubmit(this.submit)}
          >
            Отправить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = () => {
    const {onSubmitted, onClose} = this.props;

    const message = this.textareaRef.value;

    axios.post('/api/v1/sendMessageToSupport', {message})
      .then(() => {
        onSubmitted('Сообщение отправлено', false);
      })
      .catch(() => {
        onSubmitted('Произошла ошибка', true);
      })
      .then(() => onClose());
  };
}

const styles = theme => ({
  noteDialog: {
    width: 550,
    maxWidth: '100%',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100%',
    },
  },
  dialogContent: {
    padding: 20,
  },
});

EmailForm = withStyles(styles)(EmailForm);

export default reduxForm({
  form: 'send_mail',
  // enableReinitialize: true,
})(EmailForm);
