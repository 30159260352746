import React, {Component} from 'react';
import {Field, FormSection} from 'redux-form';
import Grid from '@material-ui/core/Grid';

import DateTimePicker from '../../../components/Input/DateTimePicker';
/*
https://github.com/mui-org/@material-ui/core/pull/11159
@todo Убрать компонент при переходе на релизную версию material-ui
*/
import TextArea from '../../../components/Input/TextArea';
import TextField from '../../../components/Input/TextField';

class TaskForm extends Component {
  render() {
    return (
      <FormSection name="task">
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Field
              name="title"
              label="Название"
              required
              component={TextField}
              fullWidth
              margin="none"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="deadline"
              label="Крайний срок"
              required
              format={null}
              component={props => (
                <DateTimePicker
                  ampm={false}
                  format="dd.MM.yyyy HH:mm"
                  {...props}
                />
              )}
              fullWidth
              margin="none"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="description"
              label="Описание"
              component={TextField}
              InputProps={{
                inputComponent: TextArea,
                inputProps: {rowsMax: 5},
              }}
              multiline
              fullWidth
              margin="none"
            />
          </Grid>
        </Grid>
      </FormSection>
    );
  }
}

export default TaskForm;
