import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '../../../components/Input/TextField';
import Variant from '../../../components/Input/Variant';
import {getLabelsForValue} from '../../../utils';
import TeamSelect from '../../team/TeamSelect';
import AccessComponent from '../../../components/AccessComponent';
import {_onBlur, clearFiled, getListItems, onClickItem, setFilterListItems} from '../../../utils/filter';
import {queryCities} from '../../../queries/CityQuery';
import {setCityHint} from '../../../actions';
import {withStyles} from '@material-ui/core/styles';

class FilterList extends Component {
  constructor(props){
    super(props);
    this.state = {
      listCity: [],
      selectedCity: false,
      cityName: '',
      hint: {
        fiasCityId: null,
      },
      isOpenListCity: false,
    };
    this.onBlur = _onBlur.bind(this);
    this.getListItems = getListItems.bind(this);
    this.onClickItem = onClickItem.bind(this);
    this.setFilterListItems = setFilterListItems.bind(this);
  }

  componentDidMount() {
    const {fiasCityId, cityName} = this.props.initialValues;
    this.initialValuesState();
    this.getListItems(queryCities, 'archived', 0).then(res => {
      const city = res.data.data.cities.items;
      this.setState({city}, () => {
        cityName && this.onClickItem('cityName', 'fiasCityId', {id: fiasCityId, name: cityName}, 'selectedCity');
      });
    });
  }

  initialValuesState = () => {
    const {fiasCityId} = this.props.initialValues;
    let state = {};
    if(fiasCityId) {
      state.hint = {fiasCityId};
    }
    this.setState(state);
  };

  onChangeCity = e => {
    const {city} = this.state;
    clearFiled(['fiasCityId'], this.props.change);
    this.setFilterListItems(
      e,
      'City',
      value => {
        this.setState({hint: {fiasCityId: value.id}});
        this.props.setCityHint(value);
        this.onClickItem('cityName', 'fiasCityId', value,'selectedCity');
      },
      city,
      this.props.cityHint
    );
  };

  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      onSubmit,
      open,
      pristine,
      activities,
      classes,
    } = this.props;

    const {
      listCity,
      selectedCity,
      cityName,
      isOpenListCity,
    } = this.state;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Настройки фильтра</DialogTitle>
        <Divider/>
        <DialogContent>
          <Field
            name="searchQuery"
            label="Поисковый запрос"
            component={TextField}
            fullWidth
            margin="normal"
          />
          <Field name="fiasCityId" component={TextField} className={classes.searchInputHide} />
          <div className={classes.MenuItemWrapper}>
            <Field
              placeholder="Введите название города"
              fullWidth
              name="cityName"
              onBlur={() => this.onBlur('isOpenListCity')}
              component={TextField}
              label="Город"
              margin="normal"
              autoComplete="off"
              value={cityName}
              onFocus={e => {
                if(e.target.value === '')
                  this.setState({isOpenListCity: true, listCity: this.props.cityHint});
              }}
              onChange={this.onChangeCity}
            />
            {listCity.length > 0 && !selectedCity && isOpenListCity
              ? <div className={classes.MenuItemContainer}>{ listCity.slice(0, 5).map((value, index) => (
                <MenuItem
                  fullWidth margin="normal"
                  className={classes.MenuItem}
                  onClick={() => {
                    this.setState({hint: {fiasCityId: value.id}});
                    this.props.setCityHint(value);
                    this.onClickItem('cityName', 'fiasCityId', value, 'selectedCity');
                  }}
                  key={index}>{value.name}</MenuItem>
              ))}</div>: null}
          </div>
          <Field
            name="trademark"
            label="Торговая марка"
            component={TextField}
            fullWidth
            margin="normal"
          />
          <Field
            name="type"
            label="Вид деятельности"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(activities, value, 'id', 'name').join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {activities.map(({name, id}, index) => (
              <Variant key={index} value={id} label={name}/>
            ))}
          </Field>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="areaFrom"
                  label="Площадь от"
                  component={TextField}
                  normalize={value => value ? value.replace(',', '.') : value}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="areaTo"
                  label="до (м²)"
                  component={TextField}
                  normalize={value => value ? value.replace(',', '.') : value}
                />
              </FormControl>
            </Grid>
          </Grid>
          <AccessComponent admin>
            <Field
              name="team"
              label="Команда"
              component={TeamSelect}
              fullWidth
              margin="normal"
            />
          </AccessComponent>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(onSubmit)}
          >
            Применить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
const styles = _theme => ({
  searchInput: {
    marginTop: 10,
  },
  searchInputHide: {
    marginTop: 10,
    display: 'none',
  },
  MenuItem: {
  },
  MenuItemContainer: {
    zIndex: 1,
    flexGrow: 1,
    position: 'absolute',
    backgroundColor: '#fff',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  MenuItemWrapper: {
    position: 'relative',
  },
});
FilterList = connect(
  state => ({
    activities: state.root.classification.activities,
    cityHint: Object.values(state.root.hint.city),
  }),
  {
    setCityHint,
  }
)(FilterList);

export default reduxForm({
  form: 'filterNetRenterList',
  enableReinitialize: true,
})(withStyles(styles)(FilterList));
