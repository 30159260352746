import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import React from 'react';

class EditableAssignee extends React.Component {
  state = {
    openMenu: null,
  };

  handleSelect = event => {
    this.setState({value: event.target.value});
  };

  handleClick = event => {
    const {editable} = this.props;
    if (editable) {
      this.setState({openMenu: event.target});
    }
  };

  render() {
    const {assignee, classes, editable, members, onSave} = this.props;
    const {openMenu} = this.state;

    return (
      <React.Fragment>
        <ListItem
          className={classes.listItem}
          onClick={this.handleClick}
        >
          <ListItemText
            primary={assignee.name}
            secondary="Ответственный"
          />
          {editable ? <icons.Edit className={classes.editIcon}/> : null}
        </ListItem>
        <Menu
          open={Boolean(openMenu)}
          anchorEl={openMenu}
          onClick={() => this.setState({openMenu: null})}
        >
          {members.map((member, index) => (
            <MenuItem
              key={index}
              disabled={member.id === assignee.id}
              onClick={() => onSave(member.id)}
            >
              {member.name}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  editIcon: {
    fontSize: 18,
    color: colors.grey[600],
    visibility: 'hidden',
    marginLeft: 'auto',
  },
  listItem: {
    cursor: 'pointer',
    '&:hover $editIcon': {
      visibility: 'visible',
    },
  },
});

export default withStyles(styles)(EditableAssignee);
