import React, {Component} from 'react';
import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import InputBase from '@material-ui/core/InputBase';
import {connect} from 'react-redux';

import {
  queryCities,
  queryDistricts,
  querySubDistricts,
} from '../../queries/CityQuery';
import CityListItems from './CityListItems';
import base64url from '../../utils/base64url';
import EditModal from './form/EditModal';
import {setCity, setDistrict} from '../../actions';
import AccessComponent from '../../components/AccessComponent';
import FilterCities from './form/FilterCities';
import SelectSection from './SelectSection';

class CityList extends Component {
  state = {
    searchInput: '',
    loading: false,
    error: false,
    items: [],
    totalCount: 0,
    query: {},
    openAdd: false,
    city: null,
    district: null,
    openFilterDialog: false,
  };

  componentDidMount() {
    this.search(this.props.location);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.search(nextProps.location);
    }
  }

  search(location) {
    let query = {};
    const qs = location.search.substr(1);
    if (qs.length > 0) {
      try {
        query = JSON.parse(base64url.decode(qs));
      } catch (_) {}
    }
    this.setState({
      loading: true,
      query,
      searchInput: query.filter ? query.filter.searchQuery : '',
    });
    if (this.props.level === 'city') {
      this.queryCityList(query);
    } else if (this.props.level === 'district') {
      this.queryDistrictList(query);
    } else {
      this.querySubDistrictList(query);
    }
  }

  queryCityList = query => {
    queryCities(query)
      .then(res => {
        const items = res.data.data.cities.items;
        const totalCount = res.data.data.cities.totalCount;
        this.setState({items, totalCount, loading: false, error: false});
      })
      .catch(() => {
        this.setState({items: []});
      });
  };

  queryDistrictList = query => {
    queryDistricts({
      ...query,
      filter: {...query.filter, city: this.props.match.params.cityId},
    })
      .then(res => {
        const items = res.data.data.districts.items;
        const totalCount = res.data.data.districts.totalCount;
        this.setState({items, totalCount, loading: false, error: false});
        if (!this.props.city) {
          if (items.length) {
            this.props.setCity(items[0].city);
          } else {
            this.queryCityName();
          }
        }
      })
      .catch(() => {
        this.setState({loading: false, error: true, items: []});
      });
  };

  queryCityName = () => {
    queryCities({filter: {id: this.props.match.params.cityId}})
      .then(res => {
        const city = res.data.data.cities.items[0];
        this.props.setCity(city);
      })
      .catch(() => {
        alert('Произошла ошибка');
      });
  };

  queryDistrictName = () => {
    queryDistricts({filter: {id: this.props.match.params.districtId}})
      .then(res => {
        const district = res.data.data.districts.items[0];
        const city = district.city;
        this.props.setCity(city);
        this.props.setDistrict(district);
      })
      .catch(() => {
        alert('Произошла ошибка');
      });
  };

  querySubDistrictList = query => {
    querySubDistricts({
      ...query,
      filter: {...query.filter, district: this.props.match.params.districtId},
    })
      .then(res => {
        const items = res.data.data.microDistricts.items;
        const totalCount = res.data.data.microDistricts.totalCount;
        this.setState({items, totalCount, loading: false, error: false});
        if (!this.props.city) {
          if (items.length) {
            this.props.setCity(items[0].district.city);
            this.props.setDistrict(items[0].district);
          } else {
            this.queryDistrictName();
          }
        }
      })
      .catch(() => {
        this.setState({loading: false, error: true, items: []});
      });
  };

  handleChangePage = (_, page) => {
    const path =
      this.props.match.url +
      '?' +
      base64url.encode(
        JSON.stringify({...this.state.query, offset: page * 30, limit: 30})
      );
    this.props.history.push(path);
  };

  handleSearchChange = e => {
    this.setState({searchInput: e.target.value});
  };

  handleSearchEnter = e => {
    if (e.key === 'Enter') {
      this.handleSearchInit();
    }
  };

  handleSearchInit = () => {
    const path =
      this.props.match.url +
      '?' +
      base64url.encode(
        JSON.stringify({
          ...this.state.query,
          filter: {
            ...this.state.query.filter,
            searchQuery: this.state.searchInput,
          },
          offset: 0,
        })
      );
    this.props.history.push(path);
  };

  handleFilter = filter => {
    this.setState({openFilterDialog: false});
    const path =
      this.props.match.url + '?' + base64url.encode(JSON.stringify({filter}));
    this.props.history.push(path);
    this.setState({searchInput: filter.searchQuery});
  };

  handleReset = () => {
    this.props.history.push(this.props.match.url);
    this.setState({searchInput: ''});
  };

  getTitle = () => {
    const {level, city, district, history} = this.props;
    if (level === 'district') {
      return (
        <Typography variant="subtitle1">
          <span
            style={{cursor: 'pointer'}}
            onClick={() => history.push('/reference')}
          >
            Назад
          </span>{' '}
          - {city.name}
        </Typography>
      );
    } else if (level === 'microDistrict') {
      return (
        <Typography variant="subtitle1">
          <span
            style={{cursor: 'pointer'}}
            href="#"
            onClick={() => history.push('/reference')}
          >
            Назад
          </span>{' '}
          -{' '}
          <span
            style={{cursor: 'pointer'}}
            href="#"
            onClick={() => history.push(`/reference/cities/${city.id}`)}
          >
            {city.name}
          </span>{' '}
          - {district ? district.name : ''}
        </Typography>
      );
    }
    return null;
  };

  render() {
    const {classes, history, level, match, city} = this.props;
    const {
      searchInput,
      error,
      loading,
      items,
      totalCount,
      query,
      openAdd,
      openFilterDialog,
    } = this.state;

    const {filter} = query;

    return (
      <React.Fragment>
        <div className="row" key={0}>
          <div
            className="column"
            style={{background: colors.grey[200], flex: 2}}
          >
            <Toolbar className="toolbar">
              <SelectSection section="cities" history={history} />
              {city ? this.getTitle() : null}
              <div className={classes.flex} />
              <Hidden xsDown>
                <InputBase
                  className={classes.input}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                  onKeyDown={this.handleSearchEnter}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  disabled={!searchInput}
                >
                  <icons.Search />
                </IconButton>
              </Hidden>
              {filter ? (
                <IconButton disabled={loading} onClick={this.handleReset}>
                  <icons.Clear />
                </IconButton>
              ) : null}
              <IconButton
                className={classes.rightIcon}
                disabled={loading}
                color={filter ? 'primary' : 'default'}
                onClick={() => this.setState({openFilterDialog: true})}
              >
                <icons.FilterList />
              </IconButton>
            </Toolbar>
            <Hidden smUp>
              <Toolbar>
                <InputBase
                  classes={{
                    root: classes.searchInputRoot,
                    input: classes.searchInput,
                  }}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  className={classes.rightIcon}
                  disabled={!searchInput}
                >
                  <icons.Search />
                </IconButton>
              </Toolbar>
            </Hidden>
            <Divider />
            <div
              className="content"
              ref={$content => {
                this.$content = $content;
              }}
            >
              {error ? (
                <icons.ErrorOutline className={classes.progress} />
              ) : null}
              {loading ? (
                <CircularProgress
                  size={50}
                  thickness={4}
                  className={classes.progress}
                />
              ) : null}
              {items ? (
                <CityListItems
                  items={items}
                  totalCount={totalCount}
                  query={query}
                  history={history}
                  level={level}
                  match={match}
                  handleChangePage={this.handleChangePage}
                  onSubmitted={() => this.search(this.props.location)}
                />
              ) : null}
            </div>
            <AccessComponent admin>
              <Fab
                color="secondary"
                className={classes.fab}
                onClick={() => this.setState({openAdd: true})}
              >
                <icons.Add />
              </Fab>
            </AccessComponent>
          </div>
        </div>
        {openAdd ? (
          <EditModal
            open={openAdd}
            close={() => this.setState({openAdd: false})}
            onSubmitted={() => {
              this.search(this.props.location);
              this.setState({openAdd: false});
            }}
            match={this.props.match}
            level={level}
          />
        ) : null}
        {openFilterDialog ? (
          <FilterCities
            open={openFilterDialog}
            onClose={() => this.setState({openFilterDialog: false})}
            onSubmit={this.handleFilter}
            initialValues={{
              ...filter,
            }}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  flex: {
    flex: 1,
  },
  table: {
    background: 'white',
    marginBottom: 56 + 48,
  },
  rightIcon: {
    marginRight: -12,
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
  action: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 8,
    },
    top: 0,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  searchInputRoot: {
    width: '100%',
  },
  searchInput: {
    width: '100%',
  },
  sortableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  mobilePagination: {
    marginRight: 16,
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
});

export default connect(
  state => ({
    city: state.root.reference.city,
    district: state.root.reference.district,
  }),
  {
    setCity,
    setDistrict,
  }
)(withStyles(styles)(CityList));
