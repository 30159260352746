export default function (screens, key){
  if (key === undefined) {
    if (typeof screens[undefined] !== 'undefined') {
      return screens[undefined];
    }

    for (const screenId in screens) {
      return screens[screenId];
    }
  }

  return screens[key] || null;
}
