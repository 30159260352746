import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as icons from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';

import ContractListItem from '../../../components/relations/ContractListItem';
import {queryContractsAxios} from '../../../queries/ContractsQuery';
import {hasRole} from '../../../utils/roleFunc';
import * as userRoles from '../../../constants/userRoles';

class Contracts extends Component {
  state = {
    contracts: [],
    loading: false,
    error: false,
  };

  componentDidMount() {
    if (this.props.contact) {
      this.query(this.props.selfUser);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.selfUser.role !== this.props.selfUser.role) {
      this.query(newProps.selfUser);
    }
  }

  query = user => {
    this.setState({loading: true});
    let query = {
      filter: {
        contact: this.props.contact.id,
      },
    };
    if (hasRole(user.role, userRoles.MANAGER)) {
      query = {
        ...query,
        source: 'TEAM',
      };
    } else if (hasRole(user.role, userRoles.ADMIN)) {
      query = {
        ...query,
        source: 'ALL',
      };
    }
    queryContractsAxios(query)
      .then(res => {
        this.setState({contracts: res.data.data.contracts.items});
        this.setState({loading: false});
      })
      .catch(() => {
        alert('Произошла ошибка');
        this.setState({loading: false, error: true});
      });
  };

  render() {
    const {contact, handleDialogOpen, onContractAdd} = this.props;
    const {
      contracts, 
      loading, 
      error,
    } = this.state;

    if (!contact) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Договоры
          <ListItemSecondaryAction>
            {loading ? (
              <IconButton disabled>
                <CircularProgress size={24}/>
              </IconButton>
            ) : (
              error ? (
                <IconButton disabled>
                  <icons.ErrorOutline/>
                </IconButton>
              ) : (
                <IconButton onClick={() => onContractAdd()}>
                  <icons.Add/>
                </IconButton>
              ))}
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {contracts ? contracts.map(contract => (
          <ContractListItem 
            key={contract.id}
            contract={contract}
            handleDialogOpen={e => handleDialogOpen(e)}
          />
        )) : null}
      </List>
    );
  }
}

export default connect(
  state => ({
    selfUser: state.root.selfUser,
  })
)(withRouter(Contracts));
