const defaultState = {
  selections: [],
  variableSelection: null,
  selectionStatus: {
    loading: false,
    error: false,
    totalCount: 0,
    page: 1,
    offerChange: false,
  },
};

export default (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'START_LOAD_SELECTIONS': {
      return{
        ...state,
        selections: [],
        variableSelection: null,
        selectionStatus: {
          ...state.selectionStatus,
          loading: true,
        },
      };
    }
    case 'CHANGE_DEAL_SELECTION': {

      return{
        ...defaultState,
        variableSelection: state.variableSelection,
      };
    }
    case 'CHANGE_PAGE_SELECTIONS': {
      return {
        ...state,
        selectionStatus: {
          ...state.selectionStatus,
          page: payload.page,
        },
      };
    }
    case 'LOAD_SELECTIONS':
      return {
        ...state,
        selections: [...payload.items],
        selectionStatus: {
          ...state.selectionStatus,
          loading: false,
          error: false,
          totalCount: payload.totalCount,
        },
      };
    case 'LOAD_SELECTION': {
      return {
        ...state,
        variableSelection: payload.selection,
        selectionStatus: {
          ...state.selectionStatus,
          loading: false,
          error: false,
        },
      };
    }
    case 'LOAD_SELECTION_FAILED': {
      return {
        ...state,
        selectionStatus: {
          ...state.selectionStatus,
          loading: false,
          error: true,
        },
      };
    }
    case 'START_CHANGE_SELECTION': {
      return {
        ...state,
        variableSelection: Object.assign({}, payload.selection),
      };
    }
    case 'DELETE_OFFER_FROM_SELECTION': {
      let oldSelection = Object.assign({}, state.variableSelection);
      if(oldSelection){
        let oldOffers = oldSelection.offers;
        let newOffers = oldOffers.filter(offer => payload.offerId !== offer.id);
        oldSelection.offers = newOffers;
      }
      return {
        ...state,
        variableSelection: oldSelection,
        selectionStatus: {
          ...state.selectionStatus,
          offerChange: true,
        },
      };
    }
    case 'CHANGE_OFFERS_FROM_SELECTION': {
      let oldSelection = state.variableSelection;
      let change = false;
      if(oldSelection.offers.length !== payload.offers.length)
        change = true;
      else{
        oldSelection.offers.forEach((element, index) => {
          if(element.id === payload.offers[index].id)
            change = true;
        });
      }
      oldSelection.offers = payload.offers;
      return {
        ...state,
        variableSelection: oldSelection,
        selectionStatus: {
          ...state.selectionStatus,
          offerChange: change,
        },
      };
    }
    case 'CANCEL_CHANGE_SELECTION': {
      return {
        ...state,
        variableSelection: null,
        selectionStatus: {
          ...state.selectionStatus,
          error: false,
          offerChange: false,
        },
      };
    }
    case 'FINISH_CHANGE_SELECTION': {
      let oldSelections = state.selections.filter(selection => selection.id !== state.variableSelection.id);
      oldSelections.push(state.variableSelection);
      return {
        ...state,
        selections: oldSelections,
        variableSelection: null,
        selectionStatus: {
          ...state.selectionStatus,
          error: false,
          offerChange: false,
        },
      };
    }
    default:
      return state;
  }

};
