import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import TeamListItem from '../../../components/relations/TeamListItem';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import {showResponseError} from '../../../utils';
import {SubmissionError} from 'redux-form';
import {set} from 'lodash';
import SnackbarSuccess from '../../../components/SnackbarSuccess';

class Team extends Component {
  state = {
    loading: false,
    menuAnchor: null,
    teams: [],
    successMessage: null,
  };

  handleChangeTeam = teamId => {
    const {requestId} = this.props;

    this.setState({
      menuAnchor: null,
      loading: true,
    });

    axios
      .post('/api/v1/copyRequestTeam', {id: requestId, team: teamId})
      .then(res => {
        if (res.data && res.data.message) {
          this.setState({
            successMessage: res.data.message,
            loading: false,
          });
          this.props.updateActivity();
        }
      })
      .catch(error => {
        showResponseError(error.response, SubmissionError, set);
        this.setState({loading: false});
      });
  };

  handleLoadTeamList = target => {
    const {teams} = this.state;

    if (!teams.length) {
      this.setState({loading: true});
      axios.get('/api/teams')
        .then(resp => {
          this.setState({
            teams: resp.data,
            menuAnchor: target,
            loading: false,
          });
        });
    } else {
      this.setState({menuAnchor: target});
    }
  };

  render() {
    const {show, team} = this.props;
    const {menuAnchor, loading, teams, successMessage} = this.state;

    if (!show || !team) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative', marginTop: '1rem'}}
        >
          Команда
          <ListItemSecondaryAction>
            {
              loading ? (
                <IconButton disabled>
                  <CircularProgress size={24}/>
                </IconButton>
              ) : (
                <IconButton
                  title="Передать заявку другой команде"
                  onClick={({currentTarget}) => this.handleLoadTeamList(currentTarget)}
                >
                  <icons.Reply/>
                </IconButton>
              )
            }
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {team.id && team.name ? (
          <TeamListItem
            key={team.id}
            name={team.name}
          />
        ) : null}
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClick={() => {
            this.setState({menuAnchor: null});
          }}
        >
          {teams.map((team, index) => (
            <MenuItem key={index} onClick={() => this.handleChangeTeam(team.id)}>
              {team.name}
            </MenuItem>
          ))}
        </Menu>
        <SnackbarSuccess
          open={!!successMessage}
          message={successMessage}
          onClose={() => this.setState({successMessage: null})}
        />
      </List>
    );
  }
}

export default withRouter(Team);
