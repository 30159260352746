import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import MuiAppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import NotificationsList from './NotificationsList';
import {withRouter} from 'react-router-dom';
class AppBar extends Component {
  state = {
    accountMenu: null,
  };

  render() {
    const {
      classes,
      logout,
      onMenuClick,
      selfUser,
      title,
      version,
      history,
    } = this.props;

    const {accountMenu} = this.state;

    return (
      <MuiAppBar position="static" className="appbar">
        <Toolbar>
          <IconButton
            className={classes.appMenuButton}
            onClick={onMenuClick}
            color="inherit"
          >
            <icons.Menu/>
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.pageTitle}>
            {title}
            <Hidden smDown> — CRENOW.IS <span className={classes.version}>{version}</span></Hidden>
          </Typography>
          {/* <Hidden xsDown>
            <div className={classes.mainSearch}>
              <icons.Search style={{marginRight: -40, paddingLeft: 16, opacity: 0.5}}/>
              <input disabled className={classes.searchInput}/>
            </div>
          </Hidden> */}
          {/* <Hidden smUp>
            <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
              <IconButton color="inherit">
                <icons.Search/>
              </IconButton>
            </div>
          </Hidden> */}
          <NotificationsList/>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <IconButton
              className={classes.rightButton}
              color="inherit"
              onClick={({currentTarget}) => {
                this.setState({accountMenu: currentTarget});
              }}
            >
              <icons.AccountCircle/>
            </IconButton>
          </div>
        </Toolbar>
        <Menu
          anchorEl={accountMenu}
          onClick={() => {
            this.setState({accountMenu: null});
          }}
          open={Boolean(accountMenu)}
        >
          <MenuItem
            onClick={() => {
              history.push(`/members/${selfUser.id}`);
            }}
            divider>
            <span className={classes.userName}>
              <Link
                style={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  textDecoration: 'none',
                }}
                to={`/members/${selfUser.id}`}>
                {selfUser ? selfUser.name : ''}
              </Link>
            </span>

          </MenuItem>
          <MenuItem onClick={logout}>Выйти</MenuItem>
        </Menu>
      </MuiAppBar>
    );
  }
}

const styles = () => ({
  title: {
    flex: 1,
  },
  flex: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  version: {
    fontSize: 11,
    opacity: .5,
  },
  pageTitle: {
    marginLeft: 20,
    whiteSpace: 'nowrap',
  },
  mainSearch: {
    flex: 1,
    paddingLeft: 32,
    paddingRight: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchInput: {
    flex: 1,
    maxWidth: 500,
    padding: '8px 8px 8px 56px',
    color: 'white',
    background: 'rgba(255, 255, 255, 0.15)',
    border: 0,
  },
  appMenuButton: {
    marginLeft: -12,
  },
  rightButton: {
    marginRight: -12,
  },
  Notifications: {
  },
  userName: {
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export default withRouter(withStyles(styles)(AppBar));
