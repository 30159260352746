import React, {Component} from 'react';
import {connect} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as icons from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';

import MemberList from '../team/MemberList';
import {addAssigneeToPressRelease, loadCloseDealPressReleaseDetails} from '../../actions';

class PressReleaseAssignees extends Component {
  state = {
    openItemMenu: null,
    currentItem: null,
    assigneeAdded: null,
    selectedItems: [],
  };

  componentDidMount(){
    this.load();
  }

  load() {
    const {match, loadCloseDealPressReleaseDetails} = this.props;
    loadCloseDealPressReleaseDetails(match.params.pressReleaseId);
  }

  render() {
    const {
      match,
      selfUserTeams,
      pressReleaseList,
    } = this.props;

    const {
      assigneeAdded,
      currentItem,
      openItemMenu,
    } = this.state;

    if(!pressReleaseList)
      return null;

    const pressRelease = pressReleaseList[match.params.pressReleaseId];

    return (
      <React.Fragment>
        {selfUserTeams[0] ? (
          <MemberList
            selectedItems={pressRelease.assignees.map(item => item.id)}
            teamId={selfUserTeams[0].id}
            action={(item, selected) => (
              <IconButton
                disabled={selected}
                onClick={e => {
                  this.setState({
                    openItemMenu: e.currentTarget,
                    currentItem: item,
                  });
                  e.stopPropagation();
                }}>
                <icons.MoreVert/>
              </IconButton>
            )}
          />
        ) : null}
        <Menu
          anchorEl={openItemMenu}
          open={Boolean(openItemMenu)}
          onClick={() => this.setState({openItemMenu: null})}
        >
          <MenuItem
            onClick={() => {
              this.handleClickAdd({
                pressReleaseId: match.params.pressReleaseId,
                assignee: currentItem,
              });
            }}
          >
            Добавить
          </MenuItem>
        </Menu>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(assigneeAdded)}
          autoHideDuration={4000}
          onClose={() => this.setState({assigneeAdded: false})}
          message="Добавлен"
          action={
            <IconButton
              color="inherit"
              onClick={() => this.setState({assigneeAdded: false})}
            >
              <icons.Close/>
            </IconButton>
          }
        />
      </React.Fragment>
    );
  }
  handleClickAdd = data => {
    const {
      match,
      pressReleaseList,
      addAssigneeToPressRelease,
    } = this.props;

    const pressRelease = pressReleaseList[match.params.pressReleaseId];

    return axios.post('/api/v1/pressRelease/setClosedDealAssignees', {id: data.pressReleaseId, assignees: [...pressRelease.assignees.map(item => item.id), data.assignee.id]})
      .then(response => {
        if(response.status === 200) {
          addAssigneeToPressRelease(data.pressReleaseId, data.assignee);
          this.setState({assigneeAdded: true});
        }}
      )
      .catch(error => {
        if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else if (error.response.status === 500) {
          alert('Пользователь уже является ответственным');
        }  else {
          alert('Произошла ошибка');
        }
      });
  };
}

export default connect(
  state => ({
    selfUserTeams: state.root.selfUser.teams,
    pressReleaseList: state.root.pressRelease.pressRelease.deal,
  }),
  {
    addAssigneeToPressRelease,
    loadCloseDealPressReleaseDetails,
  }
)(PressReleaseAssignees);
