import React, {Component} from 'react';
import {connect} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as icons from '@material-ui/icons';
import axios from 'axios';

import AddProperty from './form/AddProperty';
import PropertyList from '../property/PropertyList';
import PropertyListItems from '../property/PropertyListItems';
import {addPropertyToContact} from '../../actions';

class ContactProperties extends Component {
  state = {
    openItemMenu: null,
    currentItem: null,
    addProperty: null,
    selectedItems: [],
  };

  componentDidMount() {
    this.load();
  }

  load() {
    const {match} = this.props;
    let thisContactId = match.params.contactId;
    axios.get(`/api/contacts/${thisContactId}`)
      .then(res => {
        this.setState({selectedItems: res.data.properties});
      })
      .catch(() => {
        alert('Произошла ошибка');
      });
  }

  render() {
    const {history, location, match, addPropertyToContact} = this.props;
    const {
      addProperty,
      currentItem,
      openItemMenu,
      selectedItems,
    } = this.state;

    return (
      <React.Fragment>
        <PropertyList
          history={history}
          match={match}
          location={location}
          selectedItems={selectedItems}
          action={(item, selected) => (
            <IconButton 
              disabled={selected}
              onClick={e => {
                this.setState({
                  openItemMenu: e.currentTarget,
                  currentItem: item,
                });
                e.stopPropagation();
              }}>
              <icons.MoreVert/>
            </IconButton>
          )}
        >
          <PropertyListItems/>
        </PropertyList>
        <Menu
          anchorEl={openItemMenu}
          open={Boolean(openItemMenu)}
          onClick={() => this.setState({openItemMenu: null})}
        >
          <MenuItem
            onClick={() => this.setState({
              addProperty: {
                contactId: match.params.contactId,
                propertyId: currentItem.id,
              },
            })}
          >
            Добавить объект
          </MenuItem>
        </Menu>
        {addProperty ? (
          <AddProperty
            onClose={() => this.setState({addProperty: null})}
            onSubmitted={() => {
              /*if (history.length > 0) {
                history.goBack();
              }*/
              addPropertyToContact(addProperty.contactId, addProperty.propertyId);
              this.setState({addProperty: null, selectedItems: [...this.state.selectedItems, addProperty.propertyId]});
            }}
            open={Boolean(addProperty)}
            initialValues={addProperty}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default connect(null, {addPropertyToContact})(ContactProperties);
