import axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Popover from '@material-ui/core/Popover';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import qs from 'qs';

import {createAreaFormatter, mapLabels} from '../../utils';
import Variant from '../Input/Variant';

class Assignees extends Component {
  PER_PAGE = 5;

  state = {
    loading: false,
    members: null,
    searchAnchor: null,
  };

  handleSearch = e => {
    this.setState({searchAnchor: e.currentTarget, offers: null});
    this.search(0);
  };

  handlePrevPage = () => {
    const page = this.state.members.page - 1;
    this.search(page);
  };

  handleNextPage = () => {
    const page = this.state.members.page + 1;
    this.search(page);
  };

  search(page) {
    const {team} = this.props;

    const url = `/api/teams/${team.id}/members?` + qs.stringify({
      _perPage: this.PER_PAGE,
      _page: page,
    });

    this.setState({loading: true});
    axios.get(url)
      .then(resp => {
        this.setState({members: resp.data, loading: false});
      })
      .catch(() => {
        this.setState({loading: false});
      });
  }

  handleClose = () => {
    this.setState({searchAnchor: null});
  };

  handleSelect = (member, selected) => {
    const {assignees} = this.props;

    if (selected) {
      this.props.onUnselect(assignees.findIndex(assignee => assignee.id === member.id));
    } else {
      this.props.onSelect(member);
    }
  };

  render() {
    const {
      classes,
      assignees,
      onUnselect,
      label,
      meta,
    } = this.props;
    const {searchAnchor, loading, members} = this.state;

    return (
      <React.Fragment>
        {assignees.length > 0 ? (
          <Grid item xs={12}>
            {assignees.map((assignee, index) => (
              <Chip
                key={index}
                label={assignee.name}
                onDelete={() => onUnselect(index)}
              />
            ))}
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            disabled={loading}
            onClick={this.handleSearch}
          >
            {label ? label : 'Назначить ответственных'}
          </Button>
          {meta ? <Typography style={{marginTop: 5}} color="error">{meta.error}</Typography> : null}
        </Grid>
        <Popover
          PaperProps={{square: true}}
          open={Boolean(searchAnchor)}
          anchorEl={searchAnchor}
          classes={{paper: classes.popover}}
        >
          <Toolbar>
            <Typography variant="subtitle1">Участники команды</Typography>
            <div style={{flex: 1}}/>
            {loading ? (
              <CircularProgress
                size={24}
                thickness={5}
                className={classes.loader}
              />
            ) : (
              <IconButton
                style={{marginRight: -20}}
                onClick={this.handleClose}
              >
                <icons.Close/>
              </IconButton>
            )}
          </Toolbar>
          <Divider/>
          <List>
            {members ? members.items.map(member => {
              const selected = assignees.findIndex(assignee => assignee.id === member.id) >= 0;
              return (
                <Variant
                  value={member}
                  key={member.id}
                  label={member.name}
                  selected={selected}
                  onClick={() => this.handleSelect(member, selected)}
                />
              );
            }) : null}
          </List>
          <Divider/>
          {members && members.totalCount > 0 ? (
            <Toolbar>
              <IconButton
                style={{marginLeft: -20}}
                disabled={members.page < 1}
                onClick={this.handlePrevPage}
              >
                <icons.ChevronLeft/>
              </IconButton>
              <IconButton
                disabled={members.page >= Math.floor((members.totalCount - 1) / this.PER_PAGE)}
                onClick={this.handleNextPage}
              >
                <icons.ChevronRight/>
              </IconButton>
              <Typography variant="caption">
                {`${members.page + 1} / ${Math.floor((members.totalCount - 1) / this.PER_PAGE) + 1}`}
              </Typography>
            </Toolbar>
          ) : null}
        </Popover>
      </React.Fragment>
    );
  }
}

const styles = {
  popover: {
    minWidth: 312,
  },
  loader: {},
};

export default connect(
  state => ({
    formatArea: createAreaFormatter(state.root.classification.areaUnits),
    offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
  })
)(withStyles(styles)(Assignees));
