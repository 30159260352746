import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

class EditableInformation extends Component {
  state = {
    editing: false,
    saving: false,
  };

  handleClickSave = () => {
    if(this.props.onChange) {
      this.setState({
        saving: true,
      });
      this.props.onChange(this.textareaRef.value)
        .then(() => this.setState({editing: false}))
        .catch(() => {
          alert('Ошибка при сохранении дополнительных требований');
        })
        .then(() => this.setState({saving: false, editing: false}));
    }
  };

  handleClickCancel = () => {
    this.setState({editing: false});
  };

  render() {
    const {
      additional,
      classes,
    } = this.props;

    const {editing, saving} = this.state;

    return (
      <React.Fragment>
        {editing ? (
          <ListItem dense>
            <ListItemText
              disableTypography={true}
              primary={
                <React.Fragment>
                  <TextField
                    className={classes.textField}
                    defaultValue={additional}
                    multiline
                    margin="none"
                    disabled={saving}
                    fullWidth
                    inputRef={el => {
                      this.textareaRef = el;
                    }}
                    InputProps={{
                      className: classes.textarea,
                      classes: {
                        multiline: classes.inputMultiline,
                        input: classes.inputRoot,
                      },
                    }}
                  />
                  <div className={classes.buttons}>
                    <Button
                      className={classes.button}
                      onClick={this.handleClickCancel}
                      disabled={saving}
                    >
                      Отменить
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={this.handleClickSave}
                      disabled={saving}
                    >
                      Сохранить
                    </Button>
                  </div>
                </React.Fragment>
              }
            />
          </ListItem>   
        ) : (
          <ListItem 
            dense 
            button 
            className={classes.requirementsItem} 
            onClick={() => this.setState({editing: true})}
          >
            <ListItemText classes={{primary: classes.additionalField}} primary={additional} secondary="Дополнительная информация"/>
            <icons.Edit className={classes.editIcon}/>
          </ListItem>
        )}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  editIcon: {
    fontSize: 18,
    color: colors.grey[600],
    visibility: 'hidden',
    marginLeft: 'auto',  
  },
  requirementsItem: {
    whiteSpace: 'pre-line',
    cursor: 'pointer',
    '&:hover $editIcon': {
      visibility: 'visible',
    },
  },
  menuPaper: {
    minWidth: 288,
  },
  inputRoot: {
    fontSize: theme.typography.pxToRem(13),
  },
  buttons: {
    textAlign: 'right',
    padding: 8,
  },
  additionalField: {
    overflow: 'hidden',
  },
});

export default withStyles(styles)(EditableInformation);
