import {executeQuery} from '../utils';

export const queryClosedDealPressReleaseList = (query, conf = {}) => {
  return executeQuery(
    `query closedDealPressReleaseList($filter: ClosedDealPressReleaseFilter, $offset: Int, $limit: Int) {
      closedDealPressReleaseList(filter: $filter, offset: $offset, limit: $limit) {
        items {
          id
          name
          member {
            id
            name
            phone
          }
          assignees {
            id
            name
          }
          team {
            id
            name
          }
          dealType
          rentPeriod
          propertyAddress
          propertyArea
          propertyType
          published
          buyer
          createdAt
          updatedAt
          activityType
          requirements
          contractType
          workInProgress
          photos
          comment
        }
        total
      }
    }`,
    {...query},
    conf,
  );
};

export const queryEventPressReleaseList = (query, conf = {}) => {
  return executeQuery(
    `query eventPressReleaseList($filter: EventPressReleaseFilter, $offset: Int, $limit: Int, $section: String) {
      eventPressReleaseList(filter: $filter, offset: $offset, limit: $limit, section: $section) {
        items {
          id
          name
          member {
            id
            name
            phone
          }
          team {
            id
            name
          }
          description
          date
          published
          members
          createdAt
          updatedAt
          comment
          photos
        }
        total
      }
    }`,
    {...query},
    conf,
  );
};

export const queryPublicDealList = (query, conf = {}) => {
  return executeQuery(
    `query publicDealList($filter: PublicDealFilter, $offset: Int, $limit: Int) {
      publicDealList(filter: $filter, offset: $offset, limit: $limit) {
        items {
          id
          member {
            id
            name
            email
            phone
          }
          description
          date
          createdAt
          updatedAt
        }
        total
      }
    }`,
    {...query},
    conf,
  );
};

export const queryEventPressRelease = id => {
  return executeQuery(
    `query eventPressRelease($id: ID!) {
      eventPressRelease(id: $id) {
        id
        name
        member {
          id
          name
          phone
          email
        }
        team {
          id
          name
        }
        description
        federal
        date
        published
        archived
        members
        city
        comment
        photos
        youTubeUrl
      }
    }`,
    {id: id},
  );
};

export const queryCloseDealPressRelease = id => {
  return executeQuery(
    `query closedDealPressRelease($id: ID!) {
      closedDealPressRelease(id: $id) {
        id
        name
        member {
          id
          name
          email
          phone
        }
        assignees {
          id
          name
          email
          phone
        }
        team {
          id
          name
        }
        federal
        dealType
        rentPeriod
        propertyAddress
        propertyArea
        buyerServices
        idlePeriod
        features
        ownerServices
        propertyType
        published
        archived
        buyer
        description
        activityType
        requirements
        contractType
        workInProgress
        photos
        city
        comment
        dealOffer {
          id
          status
          createdAt
          deal {
            propertyType
            id
          }
          incoming
        }
      }
    }`,
    {id: id},
  );
};

export const queryPublicDeal = id => {
  return executeQuery(
    `query publicDeal($id: ID!) {
      publicDeal(id: $id) {
        id
        member {
          id
          name
          email
          phone
        }
        createdAt
        updatedAt
        description
        date
        city {
          id
          name
        }
        photo
      }
    }`,
    {id: id},
  );
};
