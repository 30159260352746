import React, {Component} from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DealDetails from './DealDetails';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as icons from '@material-ui/icons';
import {mapLabels} from '../../utils';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import axios from 'axios';
import {Link} from 'react-router-dom';

import {queryDealDetails} from '../../queries';

class DealDialog extends Component {
  state = {
    deal: null,
    loading: null,
    error: null,
  };

  getDealInfo = () => {
    const deal = this.props.deal;
    this.setState({loading: true});
    queryDealDetails(deal)
      .then(resp => {
        this.setState({
          deal: resp.data.data.deal,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({error: true, loading: false});
      });
  };

  saveStatus = (id, newStatus, oldStatus) => {
    this.setState({
      deal: {
        ...this.state.deal,
        status: newStatus,
      },
    });

    return axios.put(`/api/deals/${id}/status`, {id, status: newStatus})
      .then(() => {
        if (this.props.reload) {
          this.props.reload();
        }
      })
      .catch(() => {
        this.setState({
          deal: {
            ...this.state.deal,
            status: oldStatus,
          },
        });
        throw new Error();
      });
  };

  saveRequirements = (id, newReq, oldReq) => {
    this.setState({
      deal: {
        ...this.state.deal,
        requirements: newReq,
      },
    });

    return axios.put(`/api/deals/${id}/requirements`, {id, requirements: newReq})
      .catch(() => {
        this.setState({
          deal: {
            ...this.state.deal,
            requirements: oldReq,
          },
        });
        throw new Error();
      });
  };

  componentDidMount() {
    this.getDealInfo();
  }

  render() {
    const {
      classes,
      offerTypeLabels,
      propertyTypeLabels,
      handleClose,
      open,
      handleClick,
    } = this.props;
    const {deal, loading, error} = this.state;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{paper: classes.dialog}}
      >
        {deal ? (
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle}>
              <div className={classes.dialogTitleWrap}>
                <div className={classes.dialogText}>
                  <Typography variant="h6">{propertyTypeLabels[deal.propertyType] || deal.propertyType}</Typography>
                  <Typography variant="subtitle1">{offerTypeLabels[deal.offerType] || deal.offerType}</Typography>
                </div>
                <div className={classes.dialogBtn}>
                  <IconButton
                    onClick={() => handleClick && handleClick()}
                    component={Link}
                    to={`/deals/${this.state.deal.id}`}
                    classes={{root: classes.dialogIcon}}
                  >
                    <icons.OpenInNew/>
                  </IconButton>
                  <IconButton
                    onClick={handleClose}
                    classes={{root: classes.dialogIcon}}
                  >
                    <icons.Close/>
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <Divider/>
            <DialogContent className={classes.dialogContent}>
              <DealDetails
                deal={deal}
                onStatusChange={status => this.saveStatus(deal.id, status, deal.status)}
                onRequirementsChange={requirements => this.saveRequirements(deal.id, requirements, deal.requirements)}
              />
            </DialogContent>
          </React.Fragment>
        ) : null}
        {loading ? (
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle}>
              <div className={classes.dialogTitleWrap}>
                <div className={classes.dialogText}>
                  <Typography variant="h6">Загрузка заявки</Typography>
                </div>
                <div className={classes.dialogBtn}>
                  <IconButton
                    onClick={handleClose}
                    classes={{root: classes.dialogIcon}}
                  >
                    <icons.Close/>
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <Divider/>
            <DialogContent className={classNames(classes.dialogContent, classes.flexWrap)}>
              <CircularProgress color="primary"/>
            </DialogContent>
          </React.Fragment>
        ) : null}
        {error ? (
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle}>
              <div className={classes.dialogTitleWrap}>
                <div className={classes.dialogText}>
                  <Typography variant="h6">Не удалось загрузить заявку</Typography>
                </div>
                <div className={classes.dialogBtn}>
                  <IconButton
                    onClick={handleClose}
                    classes={{root: classes.dialogIcon}}
                  >
                    <icons.Close/>
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <Divider/>
            <DialogContent className={classNames(classes.dialogContent, classes.flexWrap)}>
              <icons.ErrorOutline/>
            </DialogContent>
          </React.Fragment>
        ) : null}
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialog: {
    width: 550,
    maxWidth: '100%',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100%',
    },
  },
  dialogIcon: {
    width: 36,
    height: 36,
    padding: 4,
  },
  dialogTitleWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: 0,
    minHeight: 150,
  },
  dialogTitle: {
    padding: '12px 24px 8px 24px',
  },
  dialogBtn: {
    marginRight: -12,
    minWidth: 72,
  },
  flexWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});


export default connect(
  state => ({
    offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
  }),
)(withStyles(styles)(DealDialog));
