import React, {Component} from 'react';
import {connect} from 'react-redux';
import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import InputBase from '@material-ui/core/InputBase';
import Popover from '@material-ui/core/Popover';
import {downloadCsv} from '../../utils/csvFunc';

import PropertyEdit from './PropertyEdit';
import FilterProperties from './form/FilterProperties';
import {addProperty, loadPropertyList, setPropertyListScrollPosition} from '../../actions';
import base64url from '../../utils/base64url';
import ListSettings from '../../components/ListSettings';
import {setSection, getSection} from '../../utils/lastSection';

class PropertyList extends Component {
  state = {
    openAdd: false,
    openFilterDialog: false,
    searchInput: '',
    query: {},
    anchorEl: null,
    popoverContent: null,
    openSettings: false,
    city:[],
    _perPage: 30,
  };

  componentDidMount() {
    this.initialize(this.props.location);

    if (this.$content) {
      setTimeout(() => {
        this.$content.scrollTop = this.props.propertyList.scrollPosition;
      }, 0);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.location !== this.props.location) {
      this.initialize(newProps.location);
    }
  }

  componentWillUnmount() {
    if (this.$content) {
      this.props.setPropertyListScrollPosition(this.props.location.key, this.$content.scrollTop);
    }
  }

  initialize(location) {
    const {propertyListSettings} = this.props;
    let filter = null;
    const query = location.search.substr(1);
    if (query.length > 0) {
      try {
        filter = JSON.parse(base64url.decode(query));
        if(!filter._perPage){
          filter._perPage = propertyListSettings.page;
        }
        this.setState({searchInput: filter.searchQuery});
      } catch (_) {
      }
    } else {
      filter = {
        order: {
          column: null,
          direction: null,
        },
        _perPage: propertyListSettings.page,
      };
    }
    if(!filter._source){
      let source = getSection('propertiesSource');
      if(source)
        filter._source = source;
    }
    this.setState({query: filter, _perPage: filter._perPage});
    this.props.loadPropertyList(location.key, filter);
  }

  render() {
    const {
      action,
      addProperty,
      classes,
      history,
      location,
      propertyList,
      selectedItems,
      propertyListSettings,
      propertyListColumns,
    } = this.props;
    if (!propertyList) {
      return null;
    }

    const {
      openAdd,
      openFilterDialog,
      searchInput,
      query,
      anchorEl,
      popoverContent,
      openSettings,
      _perPage,
    } = this.state;

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        query: query,
        history: history,
        _perPage: _perPage,
        propertyList: propertyList,
        entityId: 'propertyId',
        entityType: 'Property',
        action: action ? action : null,
        selectedItems: selectedItems,
        handleRequestSort: e => this.handleRequestSort(e),
        handleOnChangePage: (e, page) => this.handleOnChangePage(e, page),
      })
    );

    return (
      <React.Fragment>
        <div className="row" key={0}>
          <div className="column" style={{background: colors.grey[200], flex: 2}}>
            <Toolbar className="toolbar">
              <Select
                value={(propertyList.filter ? propertyList.filter._source : null) || 'assigned'}
                input={<Input/>}
                onChange={this.handleOnChangeSource}
              >
                <MenuItem value="assigned">Мои объекты</MenuItem>
                <MenuItem value="team">Объекты команды</MenuItem>
                <MenuItem value="all">Все объекты</MenuItem>
              </Select>
              <Hidden xsDown>
                <IconButton
                  className={classes.settingsIcon}
                  disabled={propertyList.loading}
                  color="primary"
                  onClick={() => this.setState({openSettings: true})}
                >
                  <icons.Settings/>
                </IconButton>
              </Hidden>
              <div className={classes.flex}/>
              <Hidden xsDown>
                <InputBase
                  className={classes.input}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                  onKeyDown={this.handleSearchEnter}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  disabled={!searchInput}
                >
                  <icons.Search/>
                </IconButton>
              </Hidden>
              <IconButton
                disabled={propertyList.loading}
                onClick={this.handleReload}
              >
                <icons.Refresh/>
              </IconButton>
              {propertyList.filter ? (
                <IconButton
                  disabled={propertyList.loading}
                  onClick={this.handleReset}
                >
                  <icons.Clear/>
                </IconButton>
              ) : null}
              <IconButton
                className={classes.rightIcon}
                disabled={propertyList.loading}
                color={propertyList.filter ? 'primary' : 'default'}
                onClick={() => this.setState({openFilterDialog: true})}
              >
                <icons.FilterList/>
              </IconButton>

            </Toolbar>
            <Hidden smUp>
              <Toolbar>
                <InputBase
                  classes={{root: classes.searchInputRoot, input: classes.searchInput}}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  className={classes.rightIcon}
                  disabled={!searchInput}
                >
                  <icons.Search/>
                </IconButton>
              </Toolbar>
            </Hidden>
            <Divider/>
            <div
              className="content"
              ref={$content => {
                this.$content = $content;
              }}
            >
              {propertyList.error ? (
                <icons.ErrorOutline className={classes.progress}/>
              ) : null}
              {propertyList.loading ? (
                <CircularProgress size={50} thickness={4} className={classes.progress}/>
              ) : null}
              {propertyList.items ? (
                childrenWithProps
              ) : null}
            </div>
            <Fab
              color="secondary"
              className={classes.fab}
              onClick={() => this.setState({openAdd: true})}
            >
              <icons.Add/>
            </Fab>
          </div>
        </div>
        {openFilterDialog ? (
          <FilterProperties
            open={openFilterDialog}
            initialValues={{
              createdAtFrom: null,
              createdAtTo: null,
              fiasCityId:null,
              district:null,
              ...propertyList.filter,
              _page: 0,
            }}
            onClose={() => this.setState({openFilterDialog: false})}
            onSubmit={this.handleFilter}
          />
        ) : null}
        {openAdd ? (
          <PropertyEdit
            open={openAdd}
            onSubmitted={property => {
              addProperty(location.key, property);
              this.setState({openAdd: false});
              history.push(`/properties/${property.id}`);
            }}
            onClose={() => this.setState({openAdd: false})}
          />
        ) : null}
        {openSettings ? (
          <ListSettings
            columnNames={propertyListColumns}
            open={openSettings}
            entityList="propertyList"
            initialValues={propertyListSettings}
            onSubmitted={this.onSubmittedListSettings}
            onClose={() => this.setState({openSettings: false})}
          />
        ) : null}
        <Popover
          classes={{paper: classes.requirementsPopover}}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
        >
          <Typography>{popoverContent}</Typography>
        </Popover>
      </React.Fragment>
    );
  }
  onSubmittedListSettings = newSettings => {
    const {propertyListSettings} = this.props;
    this.setState({openSettings: false});
    if(propertyListSettings.page !== newSettings.page){
      const {match, propertyList} = this.props;
      const path = match.url + '?' + base64url.encode(JSON.stringify({...propertyList.filter,_page:0, _perPage: newSettings.page}));
      this.props.history.push(path);
    }
  };

  handleReload = () => {
    const {loadPropertyList, location, propertyList} = this.props;

    loadPropertyList(location.key, propertyList.filter, true);
  };

  handleOnChangePage = (_, page) => {
    const {/*loadPropertyList, location,*/match, propertyList} = this.props;

    const qs = base64url.encode(JSON.stringify({...propertyList.filter, _page: page}));
    this.props.history.push(match.url + '?' + qs);
    //loadPropertyList(location.key, {...propertyList.filter, page}, true);
  };

  handleOnChangeSource = event => {
    const {match, propertyList} = this.props;

    const qs = base64url.encode(JSON.stringify({...propertyList.filter, _source: event.target.value, _page: 0}));
    this.props.history.push(match.url + '?' + qs);
    setSection('propertiesSource', event.target.value);
  };

  handleReset = () => {
    this.props.history.push(this.props.match.url);
    this.setState({searchInput: ''});
  };

  handleFilter = data => {
    this.props.history.push(this.props.match.url + '?' + base64url.encode(JSON.stringify(data)));
    this.setState({openFilterDialog: false});
  };

  handleSearchChange = e => {
    this.setState({searchInput: e.target.value});
  };

  handleSearchEnter = e => {
    if(e.key === 'Enter') {
      this.handleSearchInit();
    }
  };

  handleSearchInit = () => {
    const {match, propertyList, history} = this.props;
    const path = match.url + '?'
      + base64url.encode(JSON.stringify({...propertyList.filter, searchQuery: this.state.searchInput}));
    history.push(path);
  };

  handleRequestSort = property => {
    const {order} = this.state.query;

    if (order && order.column === property && order.direction === 'DESC') {
      this.getSortParams();
    } else if (order && order.column === property && order.direction === 'ASC') {
      this.getSortParams(property, 'DESC');
    } else {
      this.getSortParams(property, 'ASC');
    }
  };

  getSortParams = (orderBy, order) => {
    const {propertyList, match} = this.props;

    const qs = base64url.encode(JSON.stringify({...propertyList.filter, order: {column: orderBy, direction: order}, _page: 0}));
    this.props.history.push(match.url + '?' + qs);
  };

  handlePopoverOpen = (e, text) => {
    e.stopPropagation();
    this.setState({anchorEl: e.currentTarget.parentNode, popoverContent: text});
  };

  handlePopoverClose = () => {
    this.setState({anchorEl: null, popoverContent: null});
  };

  onDownloadCsv = () => {
    const {propertyList} = this.props;
    downloadCsv('properties', propertyList.filter);
  };
}

const styles = theme => ({
  flex: {
    flex: 1,
  },
  table: {
    background: 'white',
    marginBottom: 56 + 48,
  },
  rightIcon: {
    marginRight: -12,
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
  action: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 8,
    },
    top: 0,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  searchInputRoot: {
    width: '100%',
  },
  searchInput: {
    width: '100%',
  },
  sortableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  mobilePagination: {
    marginRight: 16,
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
});

export default connect(
  (state, ownProps) => ({
    propertyList: state.root.property.screens[ownProps.location.key],
    properties: state.root.property.properties,
    selfUser: state.root.selfUser,
    propertyListColumns: state.root.classification.propertyListColumns,
    propertyListSettings: state.root.settings.propertyList,
    districtList: state.root.district,
  }),
  {
    addProperty,
    loadPropertyList,
    setPropertyListScrollPosition,
  }
)(withStyles(styles)(PropertyList));
