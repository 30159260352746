import Button from '@material-ui/core/Button/Button';
import {withStyles} from '@material-ui/core/styles';
import React from 'react';

class ControlButton extends React.Component {
  state = {
    saving: false,
  };

  render() {
    const {classes, closed, permissions, onClose, onReopen} = this.props;
    const {saving} = this.state;

    return (
      <div className={classes.actionButtonsWrapper}>
        <Button
          className={classes.actionButton}
          color="primary"
          disabled={saving || !permissions.canCloseTask || !permissions.canReopenTask}
          size="small"
          variant="contained"
          onClick={() => {
            this.setState({saving: true});
            (closed ? onReopen() : onClose()).then(() => {
              this.setState({saving: false});
            });
          }}
        >
          {closed ? 'Открыть заново' : 'Завершить'}
        </Button>
      </div>
    );
  }
}


const styles = () => ({
  actionButtonsWrapper: {
    padding: '0 18px',
    display: 'flex',
    flexDirection: 'row',
  },
  actionButton: {
    margin: 6,
  },
});

export default withStyles(styles)(ControlButton);
