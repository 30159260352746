import Chip from '@material-ui/core/Chip';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';

const ContactChip = ({contact, onDelete, classes}) => {
  return (
    <Chip
      component="a"
      target="_blank"
      href={`/contacts/${contact.id}`}
      label={contact.name}
      onDelete={e => {
        e.preventDefault();
        onDelete(e);
      }}
      className={classes.contactChip}
    />
  );
};

const styles = {
  contactChip: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
};

export default withStyles(styles)(ContactChip);
