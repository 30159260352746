import axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import * as icons from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {Checkbox} from 'redux-form-material-ui';


import TextField from '../../../components/Input/TextField';
import OfferListItem from '../../../components/relations/OfferListItem';
import {List, Typography} from '@material-ui/core';
import {grey} from '@material-ui/core/colors';

class AddOffer extends Component {

  componentDidMount() {
  }

  render() {
    const {
      dealOfferStatuses,
      handleSubmit,
      submitting,
      onClose,
      open,
      offers,
      classes,
      handleDeleteItem,
    } = this.props;


    const dealOfferActiveStatuses = dealOfferStatuses.filter(status => status.value !== 'declined' && status.value !== 'closed');
    const disabledOffers = offers.filter(offer => offer.selectionAttacher);
    const disabledSave = offers.length === 0 || disabledOffers.length === offers.length;

    return (
      <Dialog fullWidth classes={{paper: classes.dialog}} open={open}>
        <DialogTitle>Прикрепление листингов</DialogTitle>
        <Divider/>
        <DialogContent className={classes.dialogContent}>
          <List>
            <div style={{paddingLeft: 24, paddingRight: 24}}>
              <Field
                name="status"
                label="Статус"
                component={TextField}
                select
                format={value => value || []}
                fullWidth
              >
                {dealOfferActiveStatuses.map(({label, value}, index) => (
                  <MenuItem key={index} value={value}>{label}</MenuItem>
                ))}
              </Field>
              <FormControl>
                <FormControlLabel
                  control={<Field name="incoming" component={Checkbox}/>}
                  label="Входящее обращение"
                  required
                />
              </FormControl>
            </div>
            <Divider/>
            {offers.map(offer =>
              <React.Fragment key={offer.id}>
                <div style={{position: 'relative'}}>
                  <OfferListItem
                    offer={offer}
                    disabled={offer.selectionAttacher}
                  />
                  <IconButton
                    className={classes.deleteItemButton}
                    onClick={() => handleDeleteItem(offer)}
                  >
                    <icons.Delete/>
                  </IconButton>
                </div>
                <Divider/>
              </React.Fragment>
            )}
            {disabledOffers.length > 0
              ?
              <Typography style={{color: grey[400], paddingLeft: 24, paddingRight: 24, paddingTop: 8}}>
                Повторно листинги не будут прикреплены
              </Typography>
              : null}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            disabled={submitting}
            onClick={onClose}
          >
            Отмена
          </Button>
          <Button
            type="button"
            disabled={submitting || disabledSave}
            onClick={handleSubmit(this.submit)}
          >
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = data => {
    const {onSubmitted, offers, dealId} = this.props;
    return axios.post('/api/v1/addOffersToDeal', {id: dealId, offers: offers.filter(offer => !offer.selectionAttacher).map(offer => offer.id), ...data})
      .then(resp => {
        localStorage.removeItem(`deal_offers_selected_${dealId}`);
        onSubmitted(resp.data);
      })
      .catch(error => {
        if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

const styles = theme => ({
  deleteItemButton: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    right: 12,
  },
  dialogContent: {
    minHeight: 150,
    padding: 0,
  },
  dialog: {
    width: 550,
    maxWidth: '100%',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100%',
    },
  },
});

AddOffer = connect(
  state => ({
    dealOfferStatuses: state.root.classification.dealOfferStatuses,
  }),
)(withStyles(styles)(AddOffer));

export default reduxForm({
  form: 'add_offer_to_deal',
  enableReinitialize: true,
})(AddOffer);
