import Chip from '@material-ui/core/Chip';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import {formatAddressLine2} from '../utils';

const OfferChip = ({offer, onDelete, classes}) => {
  return (
    <Chip
      component="a"
      target="_blank"
      href={`/offers/${offer.id}`}
      onDelete={e => {
        e.preventDefault();
        onDelete(e);
      }}
      className={classes.offerChip}
      label={formatAddressLine2(offer.property.address)}
    />
  );
};

const styles = {
  offerChip: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
};

export default withStyles(styles)(OfferChip);
