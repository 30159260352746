import axios from 'axios';
import React, {Component} from 'react';
import {reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

class RemoveFavorite extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      onClose,
      open,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Удалить из избранного?</DialogTitle>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отмена
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={handleSubmit(this.submit)}
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = data => {
    const {onSubmitted, onClose, changeState} = this.props;

    if (changeState) {
      changeState();
    }

    return axios.delete('/api/v1/favorites', {data})
      .then(response => {
        if(response.status === 200) {
          onSubmitted(response.data);
        } else {
          if (changeState) {
            changeState();
          }
        }
      })
      .catch(error => {
        if(error.response.status === 404) {
          alert('Заметки не существует');
        } else {
          alert('Произошла ошибка');
        }
        if (changeState) {
          changeState();
        }
      })
      .then(() => onClose());
  };
}

export default reduxForm({
  form: 'remove_favorite_from_property',
  enableReinitialize: true,
})(RemoveFavorite);
