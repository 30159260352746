import React from 'react';
import {TextField as MuiTextField} from 'redux-form-material-ui';

const TextField = ({input: {value, onBlur, ...inputProps}, ...otherProps}) => (
  <MuiTextField
    input={{
      value,
      ...inputProps,
    }}
    {...otherProps}
    inputProps={{
      style: otherProps.multiline ? {
        minHeight: '3rem',
        maxHeight: '12rem',
      } : {},
    }}
    onBlur={() => onBlur(value)}
  />
);

export default TextField;
