import React, {Component} from 'react';
import {connect} from 'react-redux';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';

import {emailToHref, mapLabels, formatDate, formatDateWithoutTime, phoneToHref} from '../../utils';

import EditableStatus from '../../components/EditableStatus';
import {setContactStatus} from '../../actions';

class ContactDetails extends Component {
  state = {
    menuAnchor: null,
  };

  render() {
    const {
      classes,
      contact,
      contactScopeLabels,
      contactRoleLabels,
      offerStatusLabels,
      setContactStatus,
      offerStatuses,
    } = this.props;

    const scopes = contact.scopes
      ? contact.scopes.map(value => contactScopeLabels[value] || value)
      : [];
    const roles = contact.roles
      ? contact.roles.map(value => contactRoleLabels[value] || value)
      : [];
    return (
      <div className="content" style={{background: 'white'}}>
        <List>
          {contact.open ? (
            <ListItem dense>
              <ListItemText primary="Открытый контакт" />
            </ListItem>
          ) : null}
          {contact.blacklist ? (
            <>
              <ListItem dense>
                <ListItemText primary="Контакт в черном списке" />
              </ListItem>
              <ListItem dense>
                <ListItemText
                  primary={contact.blacklistNote}
                  secondary="Причина добавления в черный список"
                />
              </ListItem>
            </>
          ) : null}
          {contact.showTeam ? (
            <ListItem dense>
              <ListItemText
                title="Контакт виден всем участником Вашей команды"
                primary="Виден команде" />
            </ListItem>
          ) : null}
          {contact.phone ? (
            <ListItem
              dense
              button
              component="a"
              href={phoneToHref(contact.phone)}
              className={classes.actionItem}
            >
              <ListItemText
                primary={contact.phone}
                secondary="Основной телефон"
              />
              <icons.Call className={classes.actionIcon} />
            </ListItem>
          ) : null}
          {contact.email ? (
            <ListItem
              dense
              button
              component="a"
              href={emailToHref(contact.email)}
              className={classes.actionItem}
            >
              <ListItemText
                primary={contact.email}
                secondary="Основной адрес эл. почты"
              />
              <icons.MailOutline className={classes.actionIcon} />
            </ListItem>
          ) : null}
          {contact.website ? (
            <ListItem
              dense
              button
              component="a"
              href={contact.website}
              target="_blank"
              className={classes.actionItem}
            >
              <ListItemText primary={contact.website} secondary="Адрес сайта" />
              <icons.Link className={classes.actionIcon} />
            </ListItem>
          ) : null}
          {contact.address ? (
            <ListItem dense>
              <ListItemText
                primary={contact.address}
                secondary="Фактический адрес"
              />
            </ListItem>
          ) : null}
          {contact.attributes
            ? contact.attributes.map((attribute, index) => {
              return this.renderAttribute(index, attribute);
            })
            : null}
          {roles.length > 0 ? (
            <ListItem dense>
              <ListItemText primary={roles.join(', ')} secondary="Роли" />
            </ListItem>
          ) : null}
          {scopes.length > 0 ? (
            <ListItem dense>
              <ListItemText
                primary={scopes.join(', ')}
                secondary="Сферы деятельности"
              />
            </ListItem>
          ) : null}
          {contact.usefulness ? (
            <ListItem dense>
              <ListItemText
                primary={contact.usefulness}
                secondary="Чем может быть полезен, связи"
              />
            </ListItem>
          ) : null}
          {contact.type === 'organization' && contact.subject ? (
            <React.Fragment>
              {contact.subject.name ? (
                <ListItem dense>
                  <ListItemText
                    primary={contact.subject.name}
                    secondary="Наименование организации"
                  />
                </ListItem>
              ) : null}
              {contact.subject.legalAddress ? (
                <ListItem dense>
                  <ListItemText
                    primary={contact.subject.legalAddress}
                    secondary="Юридический адрес"
                  />
                </ListItem>
              ) : null}
              {contact.subject.INN ? (
                <ListItem dense>
                  <ListItemText primary={contact.subject.INN} secondary="ИНН" />
                </ListItem>
              ) : null}
              {contact.subject.KPP ? (
                <ListItem dense>
                  <ListItemText primary={contact.subject.KPP} secondary="КПП" />
                </ListItem>
              ) : null}
              {contact.subject.OGRN ? (
                <ListItem dense>
                  <ListItemText
                    primary={contact.subject.OGRN}
                    secondary="ОГРН"
                  />
                </ListItem>
              ) : null}
              {contact.subject.OKPO ? (
                <ListItem dense>
                  <ListItemText
                    primary={contact.subject.OKPO}
                    secondary="ОКПО"
                  />
                </ListItem>
              ) : null}
              {contact.subject.principalName && (contact.subject.principalName.lastName || contact.subject.principalName.firstName || contact.subject.principalName.middleName)? (
                <ListItem dense>
                  <ListItemText
                    primary={[
                      contact.subject.principalName.lastName,
                      contact.subject.principalName.firstName,
                      contact.subject.principalName.middleName,
                    ]
                      .filter(v => !!v)
                      .join(' ')}
                    secondary="Директор"
                  />
                </ListItem>
              ) : null}
              {contact.subject.principalName && contact.subject.principalName.birthday ? (
                <ListItem dense>
                  <ListItemText
                    primary={formatDateWithoutTime(contact.subject.principalName.birthday)}
                    key={1}
                    secondary="Дата рождения"
                  />
                </ListItem>
              ) : null}
            </React.Fragment>
          ) : null}
          {contact.type === 'entrepreneur' && contact.subject ? (
            <React.Fragment>
              {contact.subject.name ? (
                <ListItem dense>
                  <ListItemText
                    primary={contact.subject.name}
                    secondary="Название ИП"
                  />
                </ListItem>
              ) : null}
              {contact.subject.principalName && (contact.subject.principalName.lastName || contact.subject.principalName.firstName || contact.subject.principalName.middleName) ? (
                <ListItem dense>
                  <ListItemText
                    primary={[
                      contact.subject.principalName.lastName,
                      contact.subject.principalName.firstName,
                      contact.subject.principalName.middleName,
                    ]
                      .filter(v => !!v)
                      .join(' ')}
                    secondary="Руководитель"
                  />
                </ListItem>
              ) : null}
              {contact.subject.principalName && contact.subject.principalName.birthday ? (
                <ListItem dense>
                  <ListItemText
                    primary={formatDateWithoutTime(contact.subject.principalName.birthday)}
                    key={1}
                    secondary="Дата рождения"
                  />
                </ListItem>
              ) : null}
              {contact.subject.residenceAddress ? (
                <ListItem dense>
                  <ListItemText
                    primary={contact.subject.residenceAddress}
                    secondary="Адрес регистрации"
                  />
                </ListItem>
              ) : null}
              {contact.subject.INN ? (
                <ListItem dense>
                  <ListItemText primary={contact.subject.INN} secondary="ИНН" />
                </ListItem>
              ) : null}
              {contact.subject.OGRNIP ? (
                <ListItem dense>
                  <ListItemText
                    primary={contact.subject.OGRNIP}
                    secondary="ОГРНИП"
                  />
                </ListItem>
              ) : null}
              {contact.subject.OKPO ? (
                <ListItem dense>
                  <ListItemText
                    primary={contact.subject.OKPO}
                    secondary="ОКПО"
                  />
                </ListItem>
              ) : null}
            </React.Fragment>
          ) : null}
          {contact.type === 'person' && contact.subject ? (
            <React.Fragment>
              {contact.subject.name && (contact.subject.name.lastName || contact.subject.name.firstName || contact.subject.name.middleName) ? (
                <ListItem dense>
                  <ListItemText
                    primary={[
                      contact.subject.name.lastName,
                      contact.subject.name.firstName,
                      contact.subject.name.middleName,
                    ]
                      .filter(v => !!v)
                      .join(' ')}
                    secondary="Имя"
                  />
                </ListItem>
              ) : null}
              {contact.subject.name && contact.subject.name.birthday ? (
                <ListItem dense>
                  <ListItemText
                    primary={formatDateWithoutTime(contact.subject.name.birthday)}
                    secondary="Дата рождения"
                    key={2}
                  />
                </ListItem>
              ) : null}
              {contact.subject.INN ? (
                <ListItem dense>
                  <ListItemText primary={contact.subject.INN} secondary="ИНН" />
                </ListItem>
              ) : null}
              {contact.subject.passport ? (
                <ListItem dense>
                  <ListItemText
                    primary={['series', 'number', 'issuedAt', 'issuedBy']
                      .map(k => contact.subject.passport[k])
                      .filter(v => !!v)
                      .join(' ')}
                    secondary="Паспортные данные"
                  />
                </ListItem>
              ) : null}
              {contact.subject.residenceAddress ? (
                <ListItem dense>
                  <ListItemText
                    primary={contact.subject.residenceAddress}
                    secondary="Адрес регистрации"
                  />
                </ListItem>
              ) : null}
            </React.Fragment>
          ) : null}
          {contact.description ? (
            <ListItem dense>
              <ListItemText
                primary={contact.description}
                secondary="Дополнительные сведения"
                classes={{primary: classes.newLines}}
              />
            </ListItem>
          ) : null}
          {contact.persons
            ? contact.persons.map((person, index) => (
              <div key={index}>
                <Divider />
                <ListItem dense>
                  <ListItemText primary={person.name} secondary="Имя" />
                </ListItem>
                <ListItem dense>
                  <ListItemText
                    primary={person.position}
                    secondary="Должность"
                  />
                </ListItem>
                <ListItem
                  dense
                  button
                  component="a"
                  href={phoneToHref(person.phone)}
                  className={classes.actionItem}
                >
                  <ListItemText primary={person.phone} secondary="Телефон" />
                  <icons.Call className={classes.actionIcon} />
                </ListItem>
                {person.email ? (
                  <ListItem
                    dense
                    button
                    component="a"
                    href={emailToHref(person.email)}
                    className={classes.actionItem}
                  >
                    <ListItemText
                      primary={person.email}
                      secondary="Адрес эл. почты"
                    />
                    <icons.MailOutline className={classes.actionIcon} />
                  </ListItem>
                ) : null}
                {person.attributes
                  ? person.attributes.map((attribute, index) => {
                    return this.renderAttribute(index, attribute);
                  })
                  : null}
                {person.note ? (
                  <ListItem dense>
                    <ListItemText
                      primary={person.note}
                      secondary="Заметка"
                      classes={{primary: classes.newLines}}
                    />
                  </ListItem>
                ) : null}
              </div>
            ))
            : null}
          {contact.status ? (
            <EditableStatus
              status={contact.status}
              onChange={status =>
                setContactStatus(contact.id, status, contact.status)
              }
              statuses={offerStatuses}
              statusLabels={offerStatusLabels}
            />
          ) : null}
          <ListItem dense>
            <ListItemText
              primary={`${formatDate(contact.createdAt)} / ${formatDate(
                contact.updatedAt
              )}`}
              secondary="Дата создания / редактирования"
            />
          </ListItem>
        </List>
      </div>
    );
  }

  renderAttribute(index, attribute) {
    let label =
      this.props.contactAttributeKindLabels[attribute.kind] || attribute.kind;
    if (attribute.label) {
      label += ` (${attribute.label})`;
    }
    switch (attribute.kind) {
      case 'phone':
        return (
          <ListItem
            key={index}
            dense
            button
            component="a"
            href={phoneToHref(attribute.value)}
            className={this.props.classes.actionItem}
          >
            <ListItemText primary={attribute.value} secondary={label} />
            <icons.Call className={this.props.classes.actionIcon} />
          </ListItem>
        );
      case 'email':
        return (
          <ListItem
            key={index}
            dense
            button
            component="a"
            href={emailToHref(attribute.value)}
            className={this.props.classes.actionItem}
          >
            <ListItemText primary={attribute.value} secondary={label} />
            <icons.MailOutline className={this.props.classes.actionIcon} />
          </ListItem>
        );
      default:
        return (
          <ListItem key={index} dense>
            <ListItemText primary={attribute.value} secondary={label} />
          </ListItem>
        );
    }
  }
}

const styles = () => ({
  newLines: {
    whiteSpace: 'pre-line',
  },
  actionIcon: {
    fontSize: 18,
    color: colors.grey[600],
    visibility: 'hidden',
    marginLeft: 'auto',
  },
  actionItem: {
    cursor: 'pointer',
    '&:hover $actionIcon': {
      visibility: 'visible',
    },
  },
});

export default connect(
  state => ({
    contactTypeLabels: mapLabels(
      state.root.classification.contactTypes,
      'value',
      'label'
    ),
    contactRoleLabels: mapLabels(
      state.root.classification.contactRoles,
      'value',
      'label'
    ),
    contactScopeLabels: mapLabels(
      state.root.classification.contactScopes,
      'value',
      'label'
    ),
    contactAttributeKindLabels: mapLabels(
      state.root.classification.contactAttributeKinds,
      'value',
      'label'
    ),
    offerStatusLabels: mapLabels(
      state.root.classification.offerStatuses,
      'value',
      'label'
    ),
    offerStatuses: state.root.classification.offerStatuses,
  }),
  {setContactStatus}
)(withStyles(styles)(ContactDetails));
