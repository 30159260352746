//import axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Popover from '@material-ui/core/Popover';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MuiTextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import * as icons from '@material-ui/icons';
import {Field} from 'redux-form';

import TextField from '../../../components/Input/TextField';

import {createAreaFormatter, mapLabels} from '../../../utils';
import {queryCities} from '../../../queries/CityQuery';
import {
  setCityHint,
} from '../../../actions';

class Cities extends Component {
  PER_PAGE = 5;
  state = {
    loading: false,
    members: null,
    searchAnchor: null,
    isOpenListCity: false,
    selectedCity: false,
    allCities: [],
    listCity: [],
    page: 0,
    sq: '',
    totalCount: 0,
    cityNames: null,
  };

  getCityName = () => {
    const {cities, cityNames} = this.props;
    let filter = {
      filter: {
        id: cities,
      },
    };
    queryCities(filter)
      .then(resp => {
        const {items} = resp.data.data.cities;
        this.setState({
          cityNames: {...cityNames, ...items.reduce((items, item) => ({...items, [item.id]: item.name}), {})},
        }, this.getCity);
      });

  };

  getCity = () => {
    const {page, sq, cityNames} = this.state;

    let filter = {
      filter: {
        searchQuery: sq,
      },
      limit: this.PER_PAGE,
      offset: this.PER_PAGE*page,
    };
    queryCities(filter)
      .then(resp => {
        const {items, totalCount} = resp.data.data.cities;
        this.setState({
          listCity: items,
          cityNames: {...cityNames, ...items.reduce((items, item) => ({...items, [item.id]: item.name}), {})},
          totalCount,
        });
      });
  };

  componentDidMount(){
    const cities = this.props.cities;
    if(cities && cities.length > 0)
      this.getCityName();
    else this.getCity();
  }

  onChangeCity = e => {
    this.setState({
      sq: e.target.value,
      page: 0,
    }, this.getCity);
  };

  handlePrevPage = () => {
    this.setState({
      page: this.state.page - 1,
    }, this.getCity);
  };

  handleNextPage = () => {
    this.setState({
      page: this.state.page + 1,
    }, this.getCity);
  };

  handleClose = () => {
    this.setState({searchAnchor: null});
  };


  render() {
    const {
      classes,
      cities,
      addMethod,
      removeMethod,
      input,
      meta,
    } = this.props;
    const {
      searchAnchor,
      loading,
      page,
      listCity,
      totalCount,
      cityNames,
    } = this.state;

    return (
      <React.Fragment>
        <Field
          name={input.name}
          label="Название"
          style={{display: 'none'}}
          component={TextField}
        />
        {cities && cities.length > 0 ? (
          <Grid item xs={12} style={{marginTop: 20, marginBottom: 20}}>
            {cities.map((city, index) => (
              <Chip
                key={index}
                label={cityNames ? cityNames[city] : '222' }
                onDelete={() => removeMethod(input.name, index)}
              />
            ))}
          </Grid>
        ) : null}
        <Grid item xs={12} style={{marginTop: 20, marginBottom: 20}}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            disabled={loading}
            onClick={e => this.setState({searchAnchor: e.currentTarget})}
          >
            География работы *
          </Button>
        </Grid>
        <Typography color="error">{meta.error}</Typography>
        <Popover
          PaperProps={{square: true}}
          open={Boolean(searchAnchor)}
          anchorEl={searchAnchor}
          classes={{paper: classes.popover}}
        >
          <Toolbar>
            <Typography variant="subtitle1">Список городов</Typography>
            <IconButton
              style={{marginRight: -20, position: 'absolute', right: 20}}
              onClick={this.handleClose}
            >
              <icons.Close/>
            </IconButton>
          </Toolbar>
          <Toolbar>
            <MuiTextField
              placeholder="Поиск города"
              fullWidth
              margin="none"
              autoComplete="off"
              onChange={this.onChangeCity}
            />
          </Toolbar>
          <Divider />
          <List style={{flex: 1, overflow: 'auto'}}>
            {listCity.length > 0 ?
              listCity.map((value, index) => (
                <MenuItem
                  onClick={() => {
                    cities ? cities.indexOf(value.id) === -1 && addMethod(input.name, value.id) : addMethod(input.name, value.id);
                  }}
                  key={index}
                >
                  <ListItemText
                    key={index}
                    primary={value.name}
                  />
                </MenuItem>
              ))
              : null}
          </List>
          <Divider/>
          {listCity.length > 0 ? (
            <Toolbar>
              <IconButton
                style={{marginLeft: -20}}
                disabled={page < 1}
                onClick={this.handlePrevPage}
              >
                <icons.ChevronLeft/>
              </IconButton>
              <IconButton
                disabled={page >= Math.floor((totalCount - 1) / this.PER_PAGE)}
                onClick={this.handleNextPage}
              >
                <icons.ChevronRight/>
              </IconButton>
              <Typography variant="caption">
                {`${page + 1} / ${Math.floor((totalCount - 1) / this.PER_PAGE) + 1}`}
              </Typography>
            </Toolbar>
          ) : null}



        </Popover>
      </React.Fragment>
    );
  }
}

const styles = {
  popover: {
    marginTop: 20,

    display: 'flex',
    flexDirection: 'column',
    minWidth: 272,
    maxWidth: 400,
    width: 'calc(100% - 48px)',
  },
  contactListPaper: {

  },
  loader: {},
  MenuItemContainer: {
    zIndex: 1,
    position: 'absolute',
    width:'100%',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  MenuItemWrapper: {
    position: 'relative',
  },
};

export default connect(
  state => ({
    formatArea: createAreaFormatter(state.root.classification.areaUnits),
    offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    cityHint: Object.values(state.root.hint.city),
  }),{
    setCityHint,
  }
)(withStyles(styles)(Cities));
