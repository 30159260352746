import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as icons from '@material-ui/icons';

import RemoveAssignee from '../form/RemoveAssignee';
import {queryContractAssignees} from '../../../queries/ContractsQuery';
import MemberListItem from '../../../components/relations/MemberListItem';

class Assignees extends Component {
  state = {
    assignees: null,
    error: null,
    loading: false,
    openMenu: null,
    removeAssignee: null,
  };

  componentDidMount() {
    this.reload();
  }

  handleOpenMenu = (target, id) => {
    this.setState({
      openMenu: {target, id},
    });
  };

  handleCloseMenu = () => {
    this.setState({
      openMenu: null,
    });
  };

  handleMenuItemClick = () => {
    this.setState({
      removeAssignee: {
        id: this.props.contract.id,
        assigneeId: this.state.openMenu.id,
      },
    });
  };

  handleRemoveSubmitted = () => {
    this.reload();
    this.setState({
      openMenu: null,
      removeAssignee: null,
    });
    this.props.updateActivity();
  };

  handleRemoveCancel = () => {
    this.setState({
      removeAssignee: null,
    });
  };

  reload() {
    this.setState({
      loading: true,
    });
    queryContractAssignees(this.props.contract.id)
      .then(
        resp => {
          if (resp.status === 200 && !resp.data.errors) {
            this.setState({
              assignees: resp.data.data.contract.assignees,
            });
          } else {
            this.setState({
              error: true,
            });
          }
        },
        () => {
          this.setState({
            error: true,
          });
        }
      )
      .then(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const {contract, history, handleDialogOpen} = this.props;
    const {assignees, error, loading, openMenu, removeAssignee} = this.state;

    if (!contract) {
      return null;
    }

    return (
      <List
        subheader={
          <ListSubheader disableSticky style={{position: 'relative'}}>
            Ответственные
            <ListItemSecondaryAction>
              {loading ? (
                <IconButton disabled>
                  <CircularProgress size={24} />
                </IconButton>
              ) : error ? (
                <IconButton disabled>
                  <icons.ErrorOutline />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() =>
                    history.push(`/contracts/${contract.id}/assignees`)
                  }
                >
                  <icons.Add />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListSubheader>
        }
      >
        {!error
          ? (assignees || contract.assignees).map(assignee => {
            return (
              <MemberListItem
                key={assignee.id}
                member={assignee}
                onSecondaryActionClick={
                  (assignees && assignees.length > 1) || (contract.assignees && contract.assignees.length > 1)
                    ? e => this.handleOpenMenu(e.currentTarget, assignee.id)
                    : null
                }
                handleDialogOpen={e => handleDialogOpen(e)}
              />
            );
          })
          : null}
        <Menu
          anchorEl={openMenu ? openMenu.target : null}
          open={Boolean(openMenu)}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleMenuItemClick}>
            Снять ответственного
          </MenuItem>
        </Menu>
        <RemoveAssignee
          open={Boolean(removeAssignee)}
          initialValues={removeAssignee}
          onSubmitted={this.handleRemoveSubmitted}
          onClose={this.handleRemoveCancel}
        />
      </List>
    );
  }
}

Assignees = connect(state => ({
  members: state.root.team.members,
}))(Assignees);

export default withRouter(Assignees);
