import React, {Component} from 'react';
import {Query, withApollo} from 'react-apollo';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import gql from 'graphql-tag';

import ContractEdit from './ContractEdit';
import ContractDetailsWrapper from './ContractDetailsWrapper';
import Row from '../../components/Row';
import Column from '../../components/Column';
import {contractQuery} from '../../queries/ContractsQuery';
import EditableComment from '../../components/EditableComment';
import Assignees from './relations/Assignees';
import Contact from './relations/Contact';
import Documents from './relations/Documents';
import Properties from './relations/Properties';

import ContactDialog from '../contacts/ContactDialog';
import AssigneeDialog from '../contacts/AssigneeDialog';
import PropertyDialog from '../property/PropertyDialog';
import ActivityEventsList from '../../components/ActivityEventsList';
import MiddleColumn from '../../components/MiddleColumn';
import {withStyles} from '@material-ui/core/styles';

class ContractView extends Component {
  state = {
    openEdit: false,
    openContactDialog: false,
    openAssigneeDialog: false,
    openPropertyDialog: false,
  };


  render() {
    const {history, match, classes} = this.props;
    const {
      openEdit,
      openAssigneeDialog,
      openContactDialog,
      openPropertyDialog,
    } = this.state;
    const contractId = this.props.match.params.contractId;

    return (
      <Query query={contractQuery} variables={{id: contractId}}>
        {({data, error, refetch}) => {
          const contract = data && data.contract;
          return (
            <>
              <Row>
                <Column
                  label="Информация"
                  icon={<icons.InfoOutlined />}
                  key="information"
                >
                  <ContractDetailsWrapper
                    contract={contract}
                    error={error}
                    onEdit={() => this.setState({openEdit: true})}
                    onRequirementsChange={requirements =>
                      this.saveRequirements(contract.id, requirements)
                    }
                  />
                </Column>
                <Column label="История" icon={<icons.History />} key="activity">
                  <div
                    className="column"
                    style={{background: colors.grey[200], zIndex: 0}}
                  >
                    <div className="content" style={{padding: 16}}>
                      {contract ? (
                        <MiddleColumn entityId={contract.id} entityName="Contract">
                          <EditableComment
                            comment={contract.comment}
                            onSave={comment =>
                              this.saveComment(contract.id, comment)
                            }
                          />
                          <div style={{marginTop: 20}}>
                            <ActivityEventsList
                              entityId={match.params.contractId}
                              onRef={ref => (this.activityEventsList = ref)}
                              aggregateType="contract"
                            />
                          </div>
                        </MiddleColumn>
                      ) : (
                        error ? (
                          <icons.ErrorOutline className={classes.progress}/>
                        ) : (
                          <CircularProgress size={50} thickness={4} className={classes.progress}/>
                        )
                      )}
                    </div>
                  </div>
                </Column>
                <Column
                  label="Связи"
                  icon={<icons.Share />}
                  key="relationships"
                >
                  <Paper elevation={1} square className="column">
                    <div className="content">
                      {contract ? (
                        <>
                          <Assignees
                            contract={contract}
                            handleDialogOpen={e =>
                              this.handleDialogOpen(e, 'openAssigneeDialog')
                            }
                            updateActivity={() => this.activityEventsList.updateActivity()}
                          />
                          <Divider />
                          <Contact
                            contract={contract}
                            handleDialogOpen={e =>
                              this.handleDialogOpen(e, 'openContactDialog')
                            }
                            updateActivity={() => this.activityEventsList.updateActivity()}
                          />

                          <Divider />
                          <Documents
                            contract={contract}
                            updateActivity={() => this.activityEventsList.updateActivity()}
                          />
                          <Divider />
                          <Properties
                            contract={contract}
                            handleDialogOpen={e =>
                              this.handleDialogOpen(e, 'openPropertyDialog')
                            }
                            updateActivity={() => this.activityEventsList.updateActivity()}
                          />
                          <Divider />
                        </>
                      ) : error ? (
                        <icons.ErrorOutline className={classes.progress}/>
                      ) : (
                        <IconButton disabled>
                          <CircularProgress size={24} />
                        </IconButton>
                      )}
                    </div>
                  </Paper>
                </Column>
              </Row>
              {openEdit ? (
                <ContractEdit
                  open={openEdit}
                  id={contract.id}
                  onSubmitted={() => {
                    refetch();
                    this.activityEventsList.updateActivity();
                    this.setState({openEdit: false});
                  }}
                  onClose={() => this.setState({openEdit: false})}
                />
              ) : null}
              {openContactDialog ? (
                <ContactDialog
                  history={history}
                  contact={openContactDialog}
                  open={Boolean(openContactDialog)}
                  handleClose={() =>
                    this.handleDialogClose('openContactDialog')
                  }
                />
              ) : null}
              {openAssigneeDialog ? (
                <AssigneeDialog
                  history={history}
                  member={openAssigneeDialog}
                  open={Boolean(openAssigneeDialog)}
                  handleClose={() =>
                    this.handleDialogClose('openAssigneeDialog')
                  }
                />
              ) : null}
              {openPropertyDialog ? (
                <PropertyDialog
                  history={history}
                  property={openPropertyDialog}
                  open={Boolean(openPropertyDialog)}
                  handleClose={() =>
                    this.handleDialogClose('openPropertyDialog')
                  }
                />
              ) : null}
            </>
          );
        }}
      </Query>
    );
  }

  handleDialogClose = name => {
    this.setState({[name]: false});
  };

  handleDialogOpen = (value, name) => {
    this.setState({[name]: value});
  };

  saveComment = (id, nextComment) => {
    const {client} = this.props;
    return axios
      .post('/api/v1/setContractComment', {id, comment: nextComment})
      .then(() => {
        client.writeFragment({
          id,
          fragment: gql`
            fragment contractComment on Contract {
              comment
            }
          `,
          data: {
            __typename: 'Contract',
            comment: nextComment,
          },
        });
        this.activityEventsList.updateActivity();
      })
      .catch(() => alert('Произошла ошибка'));
  };

  saveRequirements = (id, additional) => {
    const {client} = this.props;
    return axios
      .post('/api/v1/setContractAdditionalAgreements', {
        id,
        additionalAgreements: additional,
      })
      .then(() => {
        client.writeFragment({
          id,
          fragment: gql`
            fragment  contractAdditional on Contract {
              additionalAgreements
            }
          `,
          data: {
            __typename: 'Contract',
            additionalAgreements: additional,
          },
        });
        this.activityEventsList.updateActivity();
      })
      .catch(() => alert('Произошла ошибка'));
  };


}

const styles = () => ({
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
});

export default withStyles(styles)(withApollo(ContractView));
