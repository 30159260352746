import axios from 'axios';
import {set} from 'lodash';
import React, {Component} from 'react';
import {formValueSelector, reduxForm, SubmissionError} from 'redux-form';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import TeamForm from './form/TeamForm';
import {showResponseError} from '../../utils';

class TeamEdit extends Component {
  componentDidMount() {
    if (this.props.teamId) {
      this.load(this.props.teamId);
    } else {
      this.props.initialize({
        team: {
          avgCommissionMember: '43',
          avgCommissionRent: '100',
          avgCommissionSell: '4',
          disabled: false,
          blockPaidActions: true,
        },
      });
    }
  }

  load(teamId) {
    const {initialize} = this.props;

    axios.get(`/api/teams/${teamId}`)
      .then(resp => {
        initialize({team: resp.data});
      });
  }

  openIntegrationUrl(url) {
    window.location.href = url;
  }

  disableIntegration = (teamId, integrationId) => {
    axios.delete(`/api/teams/${teamId}/integrations/${integrationId}`)
      .then(() => {
        this.load(teamId);
      })
      .catch(error => {
        showResponseError(error.response, SubmissionError, set);
      });
  };

  render() {
    const {
      classes,
      handleSubmit,
      initialized,
      open,
      pristine,
      submitting,
      onClose,
      array,
      cities,
      teamId,
      integrations,
    } = this.props;

    if (!open || !initialized) {
      return null;
    }

    return (
      <Dialog fullScreen open={open} classes={{paper: classes.dialog}}>
        <DialogTitle>
          Команда
          <IconButton className={classes.closeButton} onClick={() => onClose()}>
            <icons.Close/>
          </IconButton>
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <TeamForm
            teamId={teamId}
            integrations={integrations}
            onOpenIntegrationUrl={this.openIntegrationUrl}
            onDisableIntegration={this.disableIntegration}
            cities={cities}
            array={array}
          />
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button type="button" disabled={submitting} onClick={() => onClose()}>
            Отменить
          </Button>
          <Button type="button" disabled={pristine || submitting} onClick={handleSubmit(this.submit)}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = values => {
    const {teamId, onSubmitted} = this.props;

    let req;
    if (teamId) {
      req = axios.put(`/api/teams/${teamId}`, {...values, id: teamId});
    } else {
      req = axios.post('/api/teams', values);
    }

    return req
      .then(resp => onSubmitted({...values.team, id: resp.data.id}))
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(error.response.data.error.errors.reduce((errors, {propertyPath, message}) => {
            return set(errors, propertyPath, message);
          }, {}));
        } else if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

const styles = () => ({
  dialog: {
    maxWidth: 960,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});


TeamEdit = withStyles(styles)(TeamEdit);
const selector = formValueSelector('team_edit');

TeamEdit = connect(state => ({
  cities: selector(state, 'team.cities'),
  integrations: selector(state, 'team.integrations'),
}))(TeamEdit);
export default reduxForm({
  form: 'team_edit',
})(TeamEdit);
