import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import PressReleaseListWrapper from './PressReleaseListWrapper';
import PressReleaseView from './PressReleaseView';
import PressReleaseAssignees from './PressReleaseAssignees';
import {setTitle} from '../../actions';

class Property extends Component {
  componentDidMount() {
    this.props.setTitle('Пресс-релизы');
  }

  render() {
    const {match} = this.props;
    return (
      <Switch>
        <Route path={`${match.url}/`} exact component={PressReleaseListWrapper}/>
        <Route path={`${match.url}/:type/:pressReleaseId/assignees`} exact component={PressReleaseAssignees}/>
        <Route path={`${match.url}/:type/:pressReleaseId`} component={PressReleaseView}/>
      </Switch>
    );
  }
}

export default connect(null, {setTitle})(Property);
