import React, {Component} from 'react';
import {connect} from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';

import {mapLabels, formatDate} from '../../utils';
import EditableRequirements from '../../components/EditableRequirements';

class DealDetails extends Component {
  render() {
    const {contract, formatAreaRange, onRequirementsChange} = this.props;

    return (
      <div className="content" style={{background: 'white'}}>
        <List>
          {contract.propertyArea ? (
            <ListItem dense>
              <ListItemText
                primary={formatAreaRange(contract.propertyArea)}
                secondary="Площадь"
              />
            </ListItem>
          ) : null}
          {/* <ListItem dense>
            <ListItemText
              primary={contractTypesLabels[contract.type]}
              secondary="Тип договора"
            />
          </ListItem> */}
          <ListItem dense>
            <ListItemText primary={contract.no} secondary="Номер договора" />
          </ListItem>
          <ListItem dense>
            <ListItemText
              primary={contract.commission}
              secondary="Комиссия, %"
            />
          </ListItem>
          <ListItem dense>
            <ListItemText
              primary={formatDate(contract.startAt)}
              secondary="Дата заключения"
            />
          </ListItem>
          <ListItem dense>
            {contract.endAt ?
              <ListItemText
                primary={formatDate(contract.endAt)}
                secondary="Дата окончания"
              />
              : <ListItemText
                primary={'Бессрочный'}
                secondary="Дата окончания"
              />
            }
          </ListItem>
          {contract.renewalTerms ? (
            <ListItem dense>
              <ListItemText
                primary={contract.renewalTerms}
                secondary="Условия пролонгации"
              />
            </ListItem>
          ) : null}
          <EditableRequirements
            requirements={contract.additionalAgreements}
            onChange={requirements => onRequirementsChange(requirements)}
          />
          <ListItem dense>
            <ListItemText
              primary={formatDate(contract.createdAt)}
              secondary="Создан"
            />
          </ListItem>
          {contract.updatedAt !== contract.createdAt ? (
            <ListItem dense>
              <ListItemText
                primary={formatDate(contract.updatedAt)}
                secondary="Изменен"
              />
            </ListItem>
          ) : null}
        </List>
      </div>
    );
  }
}

const styles = () => ({
  avatar: {
    background: colors.green[300],
  },
});

export default connect(({root}) => ({
  contractTypesLabels: mapLabels(
    root.classification.contractTypes,
    'value',
    'label'
  ),
}))(withStyles(styles)(DealDetails));
