import * as colors from '@material-ui/core/colors/index';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, FormSection} from 'redux-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import {Checkbox} from 'redux-form-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import PropertyFields from './PropertyFields';
import ContactField from '../../contacts/form/ContactField';
import TextField from '../../../components/Input/TextField';
import Assignees from '../../../components/relations/Assignees';

class Form extends Component {
  render() {
    const {
      id,
      address,
      array: {push, remove, move},
      change,
      classes,
      propertyStatuses,
      onAreaAmountChange,
      onAreaUnitChange,
      onOwnerCreate,
      onOwnerUnassign,
      onOwnerSelect,
      owner,
      propertyType,
      team,
      assignees,
      onAssigneeSelect,
      onAssigneeUnselect,
    } = this.props;

    return (
      <React.Fragment>
        {!id ? (
          <div style={{marginTop: 16, marginBottom: 8}}>
            <Typography variant="subtitle1" gutterBottom>
              Собственник <sup>*</sup>
            </Typography>
            <ContactField
              contact={owner}
              onContactCreate={onOwnerCreate}
              onContactUnassign={onOwnerUnassign}
              onContactSelect={onOwnerSelect}
            />
            <Field
              name="ownerId"
              component={({meta}) => {
                if (meta.error) {
                  return <FormHelperText error>{meta.error}</FormHelperText>;
                }
                return null;
              }}
            />
            <FormControl margin="normal">
              <FormControlLabel
                control={
                  <Field name="property.selfAssignee" component={Checkbox} />
                }
                label="Я ответственный"
                required
              />
            </FormControl>
            <Grid container spacing={24}>
              <Assignees
                team={team}
                assignees={assignees}
                onSelect={onAssigneeSelect}
                onUnselect={onAssigneeUnselect}
              />
            </Grid>
          </div>
        ) : null}
        <div
          style={{display: 'flex', alignItems: 'end', justifyContent: 'end'}}
        >
          <Field
            name="property.status"
            label="Статус"
            required
            component={TextField}
            select
            fullWidth
            margin="normal"
          >
            {propertyStatuses.map(({value, label}, i) => (
              <MenuItem key={i} value={value}>
                {label}
              </MenuItem>
            ))}
          </Field>
          <Tooltip
            enterTouchDelay={0}
            title={
              <div>
                <ul style={{padding: '0 10px'}}>
                  <li>Переговоры — ведётся согласование договора.</li>
                  <li>В работе — договор подписан, поиск клиента.</li>
                  <li>Отказ — отказ от сотрудничества.</li>
                  <li>Реализован — в работе, но не сдаётся и не продаётся.</li>
                  <li>Архив — удалённый объект.</li>
                </ul>
                <p>Указывайте причину изменения статуса в комментарии.</p>
              </div>
            }
          >
            <icons.Help className={classes.help} />
          </Tooltip>
        </div>
        <FormSection name="property">
          <PropertyFields
            address={address}
            array={{push, remove, move}}
            change={change}
            onAreaAmountChange={onAreaAmountChange}
            onAreaUnitChange={onAreaUnitChange}
            onTypeChange={e => {
              const value = e.target.value;

              if (value && value !== 'land') {
                change(`property.type.attributes.${value}.condition`, 'normal');
                change(`property.type.attributes.${value}.entrance`, 'street');
                change(`property.type.attributes.${value}.parking`, false);
              }
            }}
            propertyType={propertyType}
          />
        </FormSection>
        <Field
          name="property.comment"
          label="Комментарий"
          component={TextField}
          multiline
          rowsMax={15}
          fullWidth
          margin="normal"
        />
      </React.Fragment>
    );
  }
}

const styles = {
  help: {
    color: colors.grey[700],
    marginLeft: 12,
    marginBottom: 8,
  },
};

export default connect(state => ({
  propertyStatuses: state.root.classification.propertyStatuses,
}))(withStyles(styles)(Form));
