import React, {Component} from 'react';
import {connect} from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import {includes} from 'lodash';

import {
  createAreaRangeFormatter,
  createPriceFormatter,
  mapLabels,
  formatDate,
  formatDateWithoutTime,
  calculateAgentOfferCommission,
  calculateOfferCommissionFull,
} from '../../utils';
import EditableStatus from '../../components/EditableStatus';
import EditableInformation from './form/EditableInformation';
import PotentialDealEndField from './form/PotentialDealEndField';

class DealOfferDetails extends Component {
  render() {
    const {
      classes,
      dealOffer,
      propertyPurposeLabels,
      onStatusChange,
      dealOfferStatuses,
      selfUser,
      onAdditionalChange,
      onPotentialEndChange,
      dealOfferDeclineReasons,
      dealOfferStatusesAll,
    } = this.props;

    const dealOfferStatusLabels = mapLabels(dealOfferStatusesAll, 'value', 'label');

    return (
      <div className="content" style={{background: 'white'}}>
        {dealOfferStatuses.find(status => dealOffer.status === status.value) ?
          <List>
            {dealOffer.status ? (
              <EditableStatus
                status={dealOffer.status}
                onChange={status => onStatusChange(status)}
                statuses={dealOfferStatuses}
                statusLabels={dealOfferStatusLabels}
              />
            ) : (
              <ListItem dense>
                <ListItemText primary={dealOfferStatusLabels[dealOffer.status] || dealOffer.status} secondary="Статус" />
              </ListItem>
            )}
            {!dealOffer.dealEndAt ? (
              <PotentialDealEndField
                potentialEndAt={dealOffer.potentialEndAt}
                onPotentialEndChange={onPotentialEndChange}
              />
            ) : null}
            {dealOffer.dealEndAt ? (
              <ListItem dense>
                <ListItemText
                  primary={formatDateWithoutTime(dealOffer.dealEndAt)}
                  secondary={dealOffer.status === 'declined' ? 'Дата отказа' : 'Фактическая дата оплаты'}
                />
              </ListItem>
            ) : null}
            {dealOffer.commission ? (
              <ListItem dense>
                <ListItemText
                  primary={dealOffer.commission}
                  secondary="Фактическая комиссия"
                />
              </ListItem>
            ) : null}
            {dealOffer.area ? (
              <ListItem dense>
                <ListItemText
                  primary={dealOffer.area}
                  secondary="Реализованная площадь"
                />
              </ListItem>
            ) : null}
            {dealOffer.declineReason ? (
              <ListItem dense>
                <ListItemText
                  primary={dealOfferDeclineReasons[dealOffer.declineReason] || dealOffer.declineReason}
                  secondary="Причина отказа"
                />
              </ListItem>
            ) : null}
            {dealOffer.declineComment ? (
              <ListItem dense>
                <ListItemText
                  primary={dealOffer.declineComment}
                  secondary="Подробный комментарий"
                />
              </ListItem>
            ) : null}
            {dealOffer.deal.propertyPurpose ? (
              <ListItem dense>
                <ListItemText
                  primary={propertyPurposeLabels[dealOffer.deal.propertyPurpose] || dealOffer.deal.propertyPurpose}
                  secondary="Назначение"
                />
              </ListItem>
            ) : null}
            <ListItem dense>
              <ListItemText primary={formatDate(dealOffer.dealStartAt)} secondary="Создана"/>
            </ListItem>
            {dealOffer.updatedAt && dealOffer.updatedAt !== dealOffer.dealStartAt ? (
              <ListItem dense>
                <ListItemText primary={formatDate(dealOffer.updatedAt)} secondary="Изменена"/>
              </ListItem>
            ) : null}
            <Divider/>
            {dealOffer.offer.name ? (
              <ListItem dense>
                <ListItemText primary={dealOffer.offer.name} secondary="Название листинга"/>
              </ListItem>
            ) : null}
            {dealOffer.offer.property.address ? (
              <React.Fragment>
                <ListItem dense>
                  <ListItemText primary={dealOffer.offer.property.address.value} secondary="Адрес"/>
                </ListItem>
              </React.Fragment>
            ): null }
            {dealOffer.offer.revenue ? (
              <ListItem dense>
                <ListItemText primary={`${calculateOfferCommissionFull(dealOffer.offer.revenue, dealOffer.offer.price, dealOffer.offer.property.area)} ₽ / ${dealOffer.offer.revenue} %`} secondary="Комиссия / процент от сделки"/>
              </ListItem>
            ) : null}
            {selfUser.id ? (
              includes(dealOffer.assignees.map(assignee => assignee.id), selfUser.id) ? (
                <ListItem dense>
                  <ListItemText
                    primary={`${calculateAgentOfferCommission(dealOffer.offer, selfUser)} ₽`}
                    secondary="Ваша комиссия"
                  />
                </ListItem>
              ) : (
                dealOffer.offer.revenue ? (
                  <ListItem dense>
                    <ListItemText
                      primary={`${calculateAgentOfferCommission(dealOffer.offer, selfUser, 0.5)} ₽`}
                      secondary="Ваша комиссия"
                    />
                  </ListItem>
                ) : (
                  <ListItem dense>
                    <ListItemText
                      primary="Комиссия за объект не указана"
                      secondary="Ваша комиссия"
                      classes={{primary: classes.errorMessage}}
                    />
                  </ListItem>
                )
              )
            ) : null}
            <EditableInformation
              additional={dealOffer.additional}
              onChange={additional => onAdditionalChange(additional)}
            />
          </List>
          : (
            <List>
              <ListItem dense>
                <ListItemText
                  primary={formatDateWithoutTime(dealOffer.createdAt)}
                  secondary={'Дата прикрепления'}
                />
              </ListItem>
            </List>
          )}
      </div>
    );
  }
}

const styles = () => ({
  avatar: {
    background: colors.green[300],
  },
});

export default connect(
  ({root}) => ({
    dealStatusColors: mapLabels(root.classification.dealStatuses, 'value', 'color'),
    formatPrice: createPriceFormatter(root.classification.priceTypes, root.classification.currencyCodes),
    formatAreaRange: createAreaRangeFormatter(root.classification.areaUnits),
    propertyPurposeLabels: mapLabels(root.classification.propertyPurposes, 'value', 'label'),
    dealOfferDeclineReasons: mapLabels(root.classification.dealOfferDeclineReasons, 'value', 'label'),
    selfUser: root.selfUser,
    dealOfferStatusesAll: root.classification.dealOfferStatuses,
  })
)(withStyles(styles)(DealOfferDetails));
