import React, {Component} from 'react';
import PublicDealList from './PublicDealList';
import PublicDealListItems from './PublicDealListItems';
import {connect} from 'react-redux';

class PublicDealListWrapper extends Component {

  render() {
    return (
      <PublicDealList {...this.props}>
        <PublicDealListItems/>
      </PublicDealList>
    );
  }
}

export default PublicDealListWrapper = connect(
  state => ({
    publicDeals: state.root.publicDeals.publicDeals.publicDeals,
    status: state.root.publicDeals.publicDeals.status,
  }),
  {}
)(PublicDealListWrapper);
