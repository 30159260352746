import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {mapLabels} from '../../../utils';
import axios from 'axios';
import ContactListItem from '../../../components/relations/ContactListItem';
import {updateContact} from '../../../actions';

class Contact extends Component {
  state = {
    deal: this.props.deal,
    error: null,
    loading: false,
    anchorEl: null,
  };

  componentDidMount() {
    this.reload();
  }

  handleOpenMenu = target => {
    this.setState({anchorEl: target});
  };

  handleCloseMenu = () => {
    this.setState({anchorEl: null});
  };

  reload() {
    this.setState({
      loading: true,
    });
    return axios.get(`/api/contacts/${this.props.deal.contact.id}`)
      .then(resp => this.props.updateContact(resp.data))
      .catch(() => {
        this.setState({error: true});
      })
      .then(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const {error, anchorEl} = this.state;
    const {deal, contacts, history, handleDialogOpen} = this.props;

    const contact = contacts[deal.contact.id];

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Контакт
        </ListSubheader>
      }>
        {!error ? (
          contact ? (
            <ContactListItem 
              contact={contact}
              handleDialogOpen={e => handleDialogOpen(e)}
            />
          ) : null
        ) : null}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={() => history.push(`/netRenter/${deal.id}/contact`)}>
            Сменить контакт
          </MenuItem>
        </Menu>
      </List>
    );
  }
}

Contact = connect(
  state => ({
    contactTypeLabels: mapLabels(state.root.classification.contactTypes, 'value', 'label'),
    contacts: state.root.contact.contacts,
  }), {updateContact}
)(Contact);

export default withRouter(Contact);
