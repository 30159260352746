import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import InputBase from '@material-ui/core/InputBase';

import ContactEdit from './ContactEdit';
import FilterContacts from './form/FilterContacts';
import {
  addContact,
  loadContactList,
  setContactListScrollPosition,
} from '../../actions';
import {mapLabels} from '../../utils';
import {getSection, setSection} from '../../utils/lastSection';
import base64url from '../../utils/base64url';

class ContactList extends Component {
  state = {
    openAdd: false,
    openFilterDialog: false,
    searchInput: '',
    query: {},
  };

  componentDidMount() {
    this.initialize(this.props.location);

    if (this.$content) {
      setTimeout(() => {
        this.$content.scrollTop = this.props.contactList.scrollPosition;
      }, 0);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.location !== this.props.location) {
      this.initialize(newProps.location);
    }
  }

  componentWillUnmount() {
    if (this.$content) {
      this.props.setContactListScrollPosition(
        this.props.location.key,
        this.$content.scrollTop
      );
    }
  }

  initialize(location) {
    let filter = null;
    const query = location.search.substr(1);
    if (query.length > 0) {
      try {
        filter = JSON.parse(base64url.decode(query));
        this.setState({searchInput: filter.query});
      } catch (_) {}
    } else {
      filter = {
        order: {
          column: null,
          direction: null,
        },
      };
    }
    if(!filter._source){
      let source = getSection('contactSource');
      if(source)
        filter._source = source;
    }

    this.setState({query: filter});
    this.props.loadContactList(location.key, filter);
  }

  render() {
    const {
      addContact,
      classes,
      history,
      location,
      contactList,
      action,
      selectedItems,
    } = this.props;

    if (!contactList) {
      return null;
    }

    const {openAdd, openFilterDialog, searchInput, query} = this.state;

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        query: query,
        history: history,
        contactList: contactList,
        entityId: 'contactId',
        entityType: 'Contact',
        action: action ? action : null,
        selectedItems: selectedItems,
        handleRequestSort: e => this.handleRequestSort(e),
        handleOnChangePage: (e, page) => this.handleOnChangePage(e, page),
      })
    );

    return (
      <React.Fragment>
        <div className="row" key={0}>
          <div
            className="column"
            style={{background: colors.grey[200], flex: 2}}
          >
            <Toolbar className="toolbar">
              <Select
                value={
                  (contactList.filter ? contactList.filter._source : null) ||
                  'assigned'
                }
                input={<Input />}
                onChange={this.handleOnChangeSource}
              >
                <MenuItem value="assigned">Мои контакты</MenuItem>
                <MenuItem value="team">Контакты команды</MenuItem>
                <MenuItem value="all">Все контакты</MenuItem>
                <MenuItem value="open">Открытые контакты</MenuItem>
                <MenuItem value="blacklist">Черный список</MenuItem>
              </Select>
              <div className={classes.flex} />
              <Hidden xsDown>
                <InputBase
                  className={classes.input}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                  onKeyDown={this.handleSearchEnter}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  disabled={!searchInput}
                >
                  <icons.Search />
                </IconButton>
              </Hidden>
              <IconButton
                disabled={contactList.loading}
                onClick={this.handleReload}
              >
                <icons.Refresh />
              </IconButton>
              {contactList.filter ? (
                <IconButton
                  disabled={contactList.loading}
                  onClick={this.handleReset}
                >
                  <icons.Clear />
                </IconButton>
              ) : null}
              <IconButton
                className={classes.rightIcon}
                disabled={contactList.loading}
                color={contactList.filter ? 'primary' : 'default'}
                onClick={() => this.setState({openFilterDialog: true})}
              >
                <icons.FilterList />
              </IconButton>
            </Toolbar>
            <Hidden smUp>
              <Toolbar>
                <InputBase
                  classes={{
                    root: classes.searchInputRoot,
                    input: classes.searchInput,
                  }}
                  placeholder="Введите поисковый запрос"
                  value={searchInput}
                  onChange={this.handleSearchChange}
                />
                <IconButton
                  onClick={this.handleSearchInit}
                  className={classes.rightIcon}
                  disabled={!searchInput}
                >
                  <icons.Search />
                </IconButton>
              </Toolbar>
            </Hidden>
            <Divider />
            <div
              className="content"
              ref={$content => {
                this.$content = $content;
              }}
            >
              {contactList.error ? (
                <icons.ErrorOutline className={classes.progress} />
              ) : null}
              {contactList.loading ? (
                <CircularProgress
                  size={50}
                  thickness={4}
                  className={classes.progress}
                />
              ) : null}
              {contactList.items ? childrenWithProps : null}
            </div>
            <Fab
              color="secondary"
              className={classes.fab}
              onClick={() => this.setState({openAdd: true})}
            >
              <icons.Add />
            </Fab>
          </div>
        </div>
        {openFilterDialog ? (
          <FilterContacts
            open={openFilterDialog}
            initialValues={{
              createdAtFrom: null,
              createdAtTo: null,
              ...contactList.filter,
              _page: 0,
            }}
            onClose={() => this.setState({openFilterDialog: false})}
            onSubmit={this.handleFilter}
          />
        ) : null}
        {openAdd ? (
          <ContactEdit
            open={openAdd}
            onSubmitted={contact => {
              addContact(location.key, contact);
              this.setState({openAdd: false});
            }}
            onClose={() => this.setState({openAdd: false})}
          />
        ) : null}
      </React.Fragment>
    );
  }

  handleReload = () => {
    const {loadContactList, location, contactList} = this.props;

    loadContactList(location.key, contactList.filter, true);
  };

  handleOnChangePage = (_, page) => {
    const {match, contactList} = this.props;

    const qs = base64url.encode(
      JSON.stringify({...contactList.filter, _page: page})
    );
    this.props.history.push(match.url + '?' + qs);
  };

  handleOnChangeSource = event => {
    const {match, contactList} = this.props;

    const qs = base64url.encode(
      JSON.stringify({
        ...contactList.filter,
        _source: event.target.value,
        _page: 0,
      })
    );
    this.props.history.push(match.url + '?' + qs);
    setSection('contactSource', event.target.value);
  };

  handleReset = () => {
    const {match} = this.props;

    this.props.history.push(match.url);
    this.setState({searchInput: ''});
  };

  handleFilter = data => {
    const {match} = this.props;

    this.props.history.push(
      match.url + '?' + base64url.encode(JSON.stringify(data))
    );
    this.setState({openFilterDialog: false});
  };

  handleSearchChange = e => {
    this.setState({searchInput: e.target.value});
  };

  handleSearchEnter = e => {
    if (e.key === 'Enter') {
      this.handleSearchInit();
    }
  };

  handleSearchInit = () => {
    const {match, contactList, history} = this.props;

    const path =
      match.url +
      '?' +
      base64url.encode(
        JSON.stringify({...contactList.filter, query: this.state.searchInput})
      );
    history.push(path);
  };

  handleRequestSort = property => {
    const {order} = this.state.query;

    if (order && order.column === property && order.direction === 'DESC') {
      this.getSortParams();
    } else if (
      order &&
      order.column === property &&
      order.direction === 'ASC'
    ) {
      this.getSortParams(property, 'DESC');
    } else {
      this.getSortParams(property, 'ASC');
    }
  };

  getSortParams = (orderBy, order) => {
    const {contactList, match} = this.props;

    const qs = base64url.encode(
      JSON.stringify({
        ...contactList.filter,
        order: {column: orderBy, direction: order},
        _page: 0,
      })
    );
    this.props.history.push(match.url + '?' + qs);
  };
}

ContactList = connect(
  (state, ownProps) => ({
    contactRoleLabels: mapLabels(
      state.root.classification.contactRoles,
      'value',
      'label'
    ),
    contactTypeLabels: mapLabels(
      state.root.classification.contactTypes,
      'value',
      'label'
    ),
    contactList: state.root.contact.screens[ownProps.location.key],
    contacts: state.root.contact.contacts,
  }),
  {
    addContact,
    loadContactList,
    setContactListScrollPosition,
  }
)(ContactList);

const styles = theme => ({
  flex: {
    flex: 1,
  },
  rightIcon: {
    marginRight: -12,
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  searchInputRoot: {
    width: '100%',
  },
  searchInput: {
    width: '100%',
  },
  mobilePagination: {
    marginRight: 16,
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
});

export default withRouter(withStyles(styles)(ContactList));
