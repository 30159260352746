import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import debounce from 'lodash/debounce';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiTextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import {Field} from 'redux-form';
import {connect} from 'react-redux';

import TextField from '../../../components/Input/TextField';
import {
  queryDistricts,
  querySubDistricts,
} from '../../../queries/CityQuery';
import {
  _onBlur,
  setFilterListItems,
  onClickItem,
  getListItems,
  clearFiled,
} from '../../../utils/filter';
import {
  setDistrictHint,
  setSubDistrictHint,
} from '../../../actions';

const AddressTextField = ({input, meta, ...other}) => (
  <MuiTextField
    {...other}
    label="Адрес в формате ФИАС"
    placeholder="Поиск адреса (начните печатать)"
    required
    error={Boolean(meta.error)}
    helperText={meta.error}
    fullWidth
    margin="normal"
  />
);

function renderInput(inputProps) {
  const {InputProps, fieldName, ref, ...other} = inputProps;

  return (
    <Field
      {...other}
      name={`${fieldName}.value`}
      component={AddressTextField}
      InputProps={InputProps}
      inputRef={ref}
    />
  );
}

function renderSuggestion(params) {
  const {index, label, itemProps} = params;

  return (
    <MenuItem {...itemProps} key={index} component="div">
      {label}
    </MenuItem>
  );
}

class IntegrationAutosuggest extends Component {
  constructor(props){
    super(props);
    this.state={
      loading: false,
      suggestions: [],
      districts: [],
      hint: {
        fiasCityId: null,
        district: null,
      },
      selectedDistrict: false,
      listDistrict: [],
      subDistricts: [],
      selectedSubDistricts: false,
      listSubDistricts: [],
      isOpenListDistrict: false,
      isOpenListSubDistricts: false,
    };
    this.onBlur = _onBlur.bind(this);
    this.setFilterListItems = setFilterListItems.bind(this);
    this.onClickItem = onClickItem.bind(this);
    this.getListItems = getListItems.bind(this);
  }

  mapConfig = {
    center: {lat: 55.751574, lng: 37.573856},
    zoom: 4,
    controls: ['fullscreenControl', 'zoomControl'],
  };

  componentDidMount() {
    if (this.props.showMap) {
      this.initMap();
    }
    if (this.props.address.attributes && this.props.address.attributes.city_fias_id) {
      this.updateDistrict();

    }
  }

  initialValuesState = () =>{
    const {district} = this.props.address;
    let state = {};
    if(district) {
      state.hint = {...this.state.hint,district};
    }
    this.setState(state);
  };

  initMap() {
    const {fieldName} = this.props;

    if (this.props.address.latitude && this.props.address.longitude) {
      this.mapConfig.center = {
        lat: parseFloat(this.props.address.latitude),
        lng: parseFloat(this.props.address.longitude),
      };
      this.mapConfig.zoom = 17;
    }

    this.map = new window.google.maps.Map(this.mapEl, this.mapConfig);

    this.marker = new window.google.maps.Marker({
      position: this.mapConfig.center,
      map: this.map,
      draggable: true,
    });
    window.google.maps.event.addListener(this.marker, 'dragend', event => {
      this.props.change(`${fieldName}.latitude`, event.latLng.lat());
      this.props.change(`${fieldName}.longitude`, event.latLng.lng());
    });
  }

  updateDistrict = () => {
    const {city_fias_id} = this.props.address.attributes;
    getListItems(queryDistricts, 'city', city_fias_id)
      .then(res => {
        this.setState(
          {
            districts: res.data.data.districts.items,
            hint: {...this.state.hint, fiasCityId:city_fias_id},
          }, this.districtCallback);
      });

  };
  districtCallback = () => {
    const {district} = this.props.address;
    if (district) {
      this.initialValuesState();
      let item = this.state.districts.find(value => value.id === district);
      const {fieldName} = this.props;
      item && this.onClickItem(`${fieldName}.districtName`, `${fieldName}.district`, item, 'selectedDistrict', querySubDistricts, 'district', 'subDistricts', this.subDistrictCallback);
    }
  };
  subDistrictCallback=()=>{
    const {microDistrict} = this.props.address;
    if (microDistrict) {
      let item = this.state.subDistricts.find(value => (value.id = microDistrict));
      const {fieldName} = this.props;
      item && this.onClickItem(`${fieldName}.microDistrictName`, `${fieldName}.microDistrict`, item, 'selectedSubDistricts');
    }
  };

  getSuggestions = debounce(async value => {
    const res = await fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Token 5ed9d7e991878b2dcb474decc06cee04027a4f20',
      },
      body: JSON.stringify({query: value, count: 5}),
    });
    const data = await res.json();

    this.setState({
      loading: false,
      suggestions: data.suggestions.map(({unrestricted_value, data}) => ({
        value: unrestricted_value,
        attributes: data,
      })),
    });
  }, 500);

  handleSelect = async address => {
    const res = await fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Token 5ed9d7e991878b2dcb474decc06cee04027a4f20',
      },
      body: JSON.stringify({query: address.value, count: 1}),
    });
    const data = await res.json();
    const newAddress = {};

    if (data.suggestions && data.suggestions.length > 0) {
      newAddress.attributes = data.suggestions[0].data;

      if (newAddress.value !== address.value &&
        (newAddress.attributes.geo_lat && newAddress.attributes.geo_lon)
      ) {
        newAddress.latitude = newAddress.attributes.geo_lat;
        newAddress.longitude = newAddress.attributes.geo_lon;

        if (this.props.showMap) {
          const latLng = {
            lat: parseFloat(newAddress.latitude),
            lng: parseFloat(newAddress.longitude),
          };
          this.marker.setPosition(latLng);
          this.map.setCenter(latLng);
          this.map.setZoom(17);
        }
      }

      newAddress.value = data.suggestions[0].unrestricted_value;
      newAddress.street = '';
      if (newAddress.attributes.city && newAddress.attributes.settlement_with_type) {
        newAddress.street += (newAddress.street.length > 0 ? ', ' : '') + newAddress.attributes.settlement_with_type;
      }
      if (newAddress.attributes.street_with_type) {
        newAddress.street += (newAddress.street.length > 0 ? ', ' : '') + newAddress.attributes.street_with_type;
      }

      // Адрес может содержать несколько компонентов в одном атрибуте.
      // Компоненты адреса могут быть в разных атрибутах.
      newAddress.building = {};
      const chunks = [
        newAddress.attributes.house_type,
        newAddress.attributes.house,
        newAddress.attributes.block_type,
        newAddress.attributes.block,
      ]
        .filter(val => !!val)
        .join(' ')
        .split(' ')
        .map(val => val.trim())
        .filter(val => val.length > 0);

      for (let i = 0; i < chunks.length; i += 2) {
        const type = chunks[i];
        const value = chunks[i + 1];

        switch (type) {
          case 'д':
          case 'влд':
            newAddress.building.number = value;
            break;
          case 'к':
            newAddress.building.block = value;
            break;
          case 'стр':
          case 'сооружение':
            newAddress.building.letter = value;
            break;
          default:
            break;
        }
      }
    }

    this.props.change(this.props.fieldName, newAddress);
    if(newAddress.attributes.city_fias_id){
      this.setState({selectedCity:true,hint:{fiasCityId:newAddress.attributes.city_fias_id}});
      this.updateDistrict();
    }

  };


  onChangeDistrict = e => {
    const {fieldName} = this.props;
    const {hint, districts} = this.state;

    let fieldsForClear=[`${fieldName}.district`, `${fieldName}.microDistrict`, `${fieldName}.microDistrictName`];

    clearFiled(fieldsForClear, this.props.change);

    let listHint=this.props.districtHint[hint.fiasCityId] ? Object.values(this.props.districtHint[hint.fiasCityId]): [];
    this.setFilterListItems(
      e,
      'District',
      value => {
        this.setState({hint:{...hint, district:value.id}});
        this.props.setDistrictHint({cityId:hint.fiasCityId, district:{id:value.id,name:value.name}});
        this.onClickItem(`${fieldName}.districtName`, `${fieldName}.district`, value, 'selectedDistrict', querySubDistricts, 'district', 'subDistricts');
      },
      districts,
      listHint,
    );
  };
  onChangeSubDistrict = e => {
    const {fieldName} = this.props;
    const {hint, subDistricts} = this.state;
    let fieldsForClear = [`${fieldName}.microDistrict`];

    clearFiled(fieldsForClear, this.props.change);

    let listHint = this.props.subDistrictHint[hint.district] ? Object.values(this.props.subDistrictHint[hint.district]) : [];
    this.setFilterListItems(
      e,
      'SubDistricts',
      value => {
        this.props.setSubDistrictHint({districtId: hint.district, subDistrict: {id:value.id, name:value.name}});
        this.onClickItem(`${fieldName}.microDistrictName`, `${fieldName}.microDistrict`, value, 'selectedSubDistricts');
      },
      subDistricts,
      listHint
    );
  };


  render() {
    const {address, classes, editable, fieldName, showMap, readonlyAddress} = this.props;
    const {
      loading,
      suggestions,
      districts,
      selectedDistrict,
      listDistrict,
      hint,
      subDistricts,
      listSubDistricts,
      isOpenListDistrict,
      isOpenListSubDistricts,
    } = this.state;
    return (
      <React.Fragment>
        <Downshift
          defaultInputValue={address ? address.value : ''}
          onSelect={this.handleSelect}
          itemToString={item => item ? item.value : ''}
        >
          {({getInputProps, getItemProps, isOpen}) => (
            <div className={classes.container}>
              {renderInput({
                InputProps: getInputProps({
                  onChange: event => {
                    const value = event.target.value;
                    if (value.length < 3) {
                      this.setState({loading: false, suggestions: []});
                    } else {
                      this.setState({loading: true});
                      this.getSuggestions(value);
                    }
                  },
                }),
                fieldName,
              })}
              {loading ? <CircularProgress size={20} thickness={5} className={classes.loader}/> : null}
              {isOpen ? (
                <Paper square style={{position: 'absolute', zIndex: 1, background: 'white'}}>
                  {suggestions.map((suggestion, i) => renderSuggestion({
                    index: i,
                    label: suggestion.value,
                    itemProps: getItemProps({item: suggestion}),
                  }))}
                </Paper>
              ) : null}
            </div>
          )}
        </Downshift>
        {editable && address && address.value ? (
          <Grid container spacing={0}>
            {districts.length>0?
              <Grid container spacing={8}>
                <Grid item xs={12} sm={4}>
                  <Field
                    component={TextField}
                    name={`${fieldName}.district`}

                    className={classes.inputHide}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    component={TextField}
                    name={`${fieldName}.microDistrict`}
                    className={classes.inputHide}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.MenuItemWrapper}>
                    <Field
                      placeholder="Введите название района"
                      fullWidth
                      name={`${fieldName}.districtName`}
                      onBlur={() => this.onBlur('isOpenListDistrict')}
                      autoComplete="off"
                      component={TextField}
                      onFocus={e => {
                        if(e.target.value === '')
                          this.setState({isOpenListDistrict:true, listDistrict:this.props.districtHint[hint.fiasCityId] ? Object.values(this.props.districtHint[hint.fiasCityId]) : []});
                      }}
                      label="Район"
                      margin="normal"
                      disabled={readonlyAddress}
                      required
                      onChange={this.onChangeDistrict}
                    />
                    {listDistrict.length > 0  && isOpenListDistrict
                      ? <div className={classes.MenuItemContainer}> {
                        listDistrict.slice(0, 5).map((value, index) => (
                          <MenuItem
                            onClick={() => {
                              this.setState({hint: {...hint,district:value.id}});
                              this.props.setDistrictHint({cityId: hint.fiasCityId, district: {id:value.id, name:value.name}});
                              this.onClickItem(`${fieldName}.districtName`,`${fieldName}.district`, value, 'selectedDistrict', querySubDistricts, 'district', 'subDistricts');}} key={index}>
                            {value.name}
                          </MenuItem>
                        ))
                      }</div>: null}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.MenuItemWrapper}>
                    <Field
                      placeholder="Введите название микрорайона"
                      fullWidth
                      disabled={!(subDistricts.length > 0 && selectedDistrict) || readonlyAddress}
                      name={`${fieldName}.microDistrictName`}
                      onBlur={() => this.onBlur('isOpenListSubDistricts')}
                      autoComplete="off"
                      component={TextField}
                      label="Микрорайон"
                      onFocus={e => {
                        if(e.target.value === '')
                          this.setState({isOpenListSubDistricts:true,listSubDistricts:this.props.subDistrictHint[hint.district] ? Object.values(this.props.subDistrictHint[hint.district]) : []});
                      }}
                      margin="normal"
                      onChange={this.onChangeSubDistrict}
                    />
                    {listSubDistricts.length > 0  && isOpenListSubDistricts
                      ?<div className={classes.MenuItemContainer}>{
                        listSubDistricts.slice(0, 5).map((value, index) => (
                          <MenuItem
                            onClick={() => {
                              this.props.setSubDistrictHint({districtId: hint.district,subDistrict:{id: value.id, name: value.name}});
                              this.onClickItem(`${fieldName}.microDistrictName`,`${fieldName}.microDistrict`,value,'selectedSubDistricts');
                            }}
                            key={index}>
                            {value.name}
                          </MenuItem>
                        ))
                      }</div>: null}
                  </div>
                </Grid>
              </Grid>
              :null}
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  name={`${fieldName}.street`}
                  label="Улица/мкр./пос."
                  InputProps={{readOnly: false}}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={4} sm={2}>
                <Field
                  component={TextField}
                  name={`${fieldName}.building.number`}
                  label="Дом"
                  InputProps={{readOnly: false}}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={4} sm={2}>
                <Field
                  component={TextField}
                  name={`${fieldName}.building.block`}
                  label="Корпус"
                  InputProps={{readOnly: false}}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={4} sm={2}>
                <Field
                  component={TextField}
                  name={`${fieldName}.building.letter`}
                  label="Строение"
                  InputProps={{readOnly: false}}
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {showMap ? (
          <div
            className={classes.map}
            ref={el => {
              this.mapEl = el;
            }}
          />
        ) : null}

      </React.Fragment>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  showMap: PropTypes.bool,
  editable: PropTypes.bool,
  fieldName: PropTypes.string,
};

IntegrationAutosuggest.defaultProps = {
  showMap: false,
  editable: false,
  fieldName: 'address',
  readonlyAddress:false,
};

/*IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};*/

const styles = {
  inputHide: {
    display: 'none',
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    right: 0,
    bottom: 13,
  },
  textField: {
    width: '100%',
  },
  map: {
    width: '100%',
    height: 250,
    marginTop: 16,
  },
  MenuItem: {
    width:'100%',
  },
  MenuItemContainer: {
    zIndex:1,
    flexGrow: 1,
    position: 'absolute',
    backgroundColor:'#fff',
    width:'100%',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',

  },
  MenuItemWrapper: {
    position:'relative',
  },
};
IntegrationAutosuggest = connect(state => ({
  propertyStatuses: state.root.classification.propertyStatuses,
  propertyTypes: state.root.classification.propertyTypes,
  teamMembers: state.root.selfUser.teamMembers,
  districtHint:(state.root.hint.district),
  subDistrictHint:(state.root.hint.subDistrict),
}),
{
  setDistrictHint,
  setSubDistrictHint,
})(IntegrationAutosuggest);

export default withStyles(styles, {withTheme: true})(IntegrationAutosuggest);
