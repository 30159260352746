import {executeQuery} from '../utils';

export const queryReferenceItems = (ref, query) => {
  return executeQuery(
    `query Items($filter: ReferenceFilter, $offset: Int, $limit: Int) {
      ${ref}(filter: $filter, offset: $offset, limit: $limit) {
        items {
          id
          name
          archived
        }
        totalCount
      }
    }`,
    {...query}
  );
};
