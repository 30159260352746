import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';

import TextField from '../../../components/Input/TextField';
import Variant from '../../../components/Input/Variant';
import DatePicker from '../../../components/Input/DatePicker';
import {getLabelsForValue} from '../../../utils';
import TeamSelect from '../../team/TeamSelect';
import {queryCities, queryDistricts, querySubDistricts} from '../../../queries/CityQuery';
import {
  _onBlur,
  setFilterListItems,
  onClickItem,
  getListItems,
  clearFiled,
} from '../../../utils/filter';
import {
  setCityHint,
  setDistrictHint,
  setSubDistrictHint,
} from '../../../actions';

class FilterProperties extends Component {
  constructor(props){
    super(props);
    this.state = {
      listCity: [],
      hint: {
        fiasCityId: null,
        district: null,
      },
      selectedCity: false,
      district: [],
      selectedDistrict: false,
      listDistrict: [],
      subDistricts: [],
      selectedSubDistricts: false,
      listSubDistricts: [],
      isOpenListCity: false,
      isOpenListDistrict: false,
      isOpenListSubDistricts: false,
    };
    this.onBlur = _onBlur.bind(this);
    this.setFilterListItems = setFilterListItems.bind(this);
    this.onClickItem = onClickItem.bind(this);
    this.getListItems = getListItems.bind(this);
  }

  componentDidMount() {
    const {fiasCityId, cityName, districtName, district} = this.props.initialValues;
    this.initialValuesState();
    this.getListItems(queryCities, 'archived', 0).then(res => {
      const city = res.data.data.cities.items;
      this.setState({city}, () => {
        cityName && this.onClickItem('cityName', 'fiasCityId', {id: fiasCityId, name: cityName}, 'selectedCity', queryDistricts, 'city', 'district');
        districtName && this.onClickItem('districtName', 'district', {id: district, name: districtName}, 'selectedDistrict', querySubDistricts, 'district', 'subDistricts');
      });
    });
  }

  initialValuesState = () => {
    const {fiasCityId, district, microDistrict} = this.props.initialValues;
    let state = {};
    if(fiasCityId) {
      state.hint = {fiasCityId};
      if(district) {
        state.hint = {...state.hint, district};
        if(microDistrict){
          state.hint = {...state.hint, microDistrict};
        }
      }
    }
    this.setState(state);
  };

  onChangeCity = e => {
    const {city} = this.state;
    let fieldsForClear = ['microDistrict', 'district', 'fiasCityId', 'districtName', 'microDistrictName'];

    clearFiled(fieldsForClear, this.props.change);

    this.setFilterListItems(
      e,
      'City',
      value => {
        this.setState({hint: {fiasCityId: value.id}});
        this.props.setCityHint(value);
        this.onClickItem('cityName', 'fiasCityId', value,'selectedCity', queryDistricts, 'city', 'district');
      },
      city,
      this.props.cityHint
    );
  };

  onChangeDistrict = e => {
    const {district, hint} = this.state;

    let fieldsForClear = ['microDistrict', 'district', 'microDistrictName'];
    clearFiled(fieldsForClear, this.props.change);

    let listHint = this.props.districtHint[hint.fiasCityId] ? Object.values(this.props.districtHint[hint.fiasCityId]) : [];
    this.setFilterListItems(
      e,
      'District',
      value => {
        this.setState({hint: {...hint, district: value.id}});
        this.props.setDistrictHint({cityId: hint.fiasCityId, district: {id: value.id,name: value.name}});
        this.onClickItem('districtName', 'district', value, 'selectedDistrict', querySubDistricts, 'district', 'subDistricts');
      },
      district,
      listHint,
    );
  };

  onChangeSubDistrict = e => {
    const {subDistricts, hint} = this.state;

    let fieldsForClear = ['microDistrict'];
    clearFiled(fieldsForClear, this.props.change);

    let listHint = this.props.subDistrictHint[hint.district] ? Object.values(this.props.subDistrictHint[hint.district]): [];
    this.setFilterListItems(
      e,
      'SubDistricts',
      value => {
        this.props.setSubDistrictHint({districtId: hint.district, subDistrict: {id: value.id, name: value.name}});
        this.onClickItem('microDistrictName', 'microDistrict', value, 'selectedSubDistrict');
      },
      subDistricts,
      listHint,
    );
  };

  render() {
    const {
      handleSubmit,
      submitting,
      propertyStatuses,
      onClose,
      onSubmit,
      open,
      pristine,
      propertyTypes,
      teamMembers,
      classes,
    } = this.props;

    const {
      listCity,
      selectedCity,
      hint,
      district,
      selectedDistrict,
      listDistrict,
      subDistricts,
      listSubDistricts,
      isOpenListCity,
      isOpenListDistrict,
      isOpenListSubDistricts,
    } = this.state;
    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Настройки фильтра</DialogTitle>
        <Divider />
        <DialogContent>
          <Field name="searchQuery" label="Фраза для поиска" component={TextField} fullWidth margin="normal" />
          <Field
            name="types"
            label="Категории"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(propertyTypes, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {propertyTypes.map(({label, value}, i) => (
              <Variant value={value} label={label} key={i} />
            ))}
          </Field>
          <Field
            name="fiasCityId"
            component={TextField}
            className={classes.searchInputHide}
          >
          </Field>
          <div className={classes.MenuItemWrapper}>
            <Field
              placeholder="Введите название города"
              fullWidth
              name="cityName"
              onBlur={() => this.onBlur('isOpenListCity')}
              component={TextField}
              label="Город"
              margin="normal"
              autoComplete="off"
              onFocus={e=>{
                if(e.target.value === '')
                  this.setState({isOpenListCity: true, listCity: this.props.cityHint});
              }}
              onChange={this.onChangeCity}
            />

            {listCity.length > 0 && !selectedCity && isOpenListCity
              ? <div className={classes.MenuItemContainer}>
                { listCity.slice(0, 5).map((value, index) => (
                  <MenuItem
                    onClick={
                      () => {
                        this.setState({hint:{fiasCityId:value.id}});
                        this.props.setCityHint(value);
                        this.onClickItem('cityName', 'fiasCityId', value, 'selectedCity', queryDistricts, 'city', 'district');
                      }}
                    key={index}
                  >
                    {value.name}
                  </MenuItem>
                ))}</div>
              : null}

            {district.length > 0 && selectedCity ? (
              <div>
                <Field
                  name="district"
                  component={TextField}
                  className={classes.searchInputHide}
                />
                <Field
                  placeholder="Введите название района"
                  fullWidth
                  name="districtName"
                  onBlur={() => this.onBlur('isOpenListDistrict')}
                  autoComplete="off"
                  component={TextField}
                  label="Район"
                  margin="normal"
                  onFocus={e => {
                    if(e.target.value === '')
                      this.setState({isOpenListDistrict :true, listDistrict: this.props.districtHint[hint.fiasCityId] ? Object.values(this.props.districtHint[hint.fiasCityId]) : []});
                  }}
                  onChange={this.onChangeDistrict}
                />

                {listDistrict.length > 0  && isOpenListDistrict
                  ? <div className={classes.MenuItemContainer}>
                    {listDistrict.slice(0, 5).map((value, index) => (
                      <MenuItem
                        onClick={() => {
                          this.setState({hint:{...hint, district: value.id}});
                          this.props.setDistrictHint({cityId: hint.fiasCityId, district: {id: value.id, name: value.name}});
                          this.onClickItem('districtName', 'district', value,'selectedDistrict', querySubDistricts, 'district', 'subDistricts');
                        }}
                        key={index}
                      >
                        {value.name}
                      </MenuItem>

                    ))
                    }</div>: null}

                {subDistricts.length > 0 && selectedDistrict ? (
                  <div>
                    <Field name="microDistrict" component={TextField} className={classes.searchInputHide} />
                    <Field
                      placeholder="Введите название микрорайона"
                      fullWidth
                      name="microDistrictName"
                      onBlur={() => this.onBlur('isOpenListSubDistricts')}
                      autoComplete="off"
                      component={TextField}
                      label="Микрорайон"
                      margin="normal"
                      onFocus={e => {
                        if(e.target.value === '')
                          this.setState({isOpenListSubDistricts:true, listSubDistricts:this.props.subDistrictHint[hint.district] ? Object.values(this.props.subDistrictHint[hint.district]):[]});
                      }}
                      onChange={this.onChangeSubDistrict}
                    />
                    {listSubDistricts.length > 0  && isOpenListSubDistricts
                      ?<div margin="normal" className={classes.MenuItemContainer}>{ listSubDistricts.slice(0, 5).map((value, index) => (
                        <MenuItem
                          className={classes.MenuItem}
                          onClick={() => {
                            this.onClickItem('microDistrictName', 'microDistrict', value, 'selectedSubDistrict');
                            this.props.setSubDistrictHint({districtId:hint.district, subDistrict:{id:value.id, name:value.name}});
                          }}
                          key={index}>
                          {value.name}
                        </MenuItem>
                      ))}</div>: null}
                  </div>
                ) : null}
              </div>): null}
          </div>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="areaMin"
                  label="Площадь от"
                  component={TextField}
                  normalize={value => (value ? value.replace(',', '.') : value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="areaMax"
                  label="до (м²)"
                  component={TextField}
                  normalize={value => (value ? value.replace(',', '.') : value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Field
            name="statuses"
            label="Статусы"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(propertyStatuses, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {propertyStatuses.map(({label, value}, i) => (
              <Variant value={value} label={label} key={i} />
            ))}
          </Field>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="createdAtFrom"
                  label="Создан с"
                  format={null}
                  component={props => <DatePicker format="yyyy-MM-dd" clearable {...props} />}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="createdAtTo"
                  label="по"
                  format={null}
                  component={props => <DatePicker format="yyyy-MM-dd" clearable {...props} />}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Field
            name="assignees"
            label="Ответственные"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(teamMembers, value, 'id', 'name').join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {teamMembers.map(({id, name}, index) => (
              <Variant key={index} value={id} label={name} />
            ))}
          </Field>
          <Field name="team" label="Команда" component={TeamSelect} fullWidth margin="normal" />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button type="button" color="primary" disabled={submitting} onClick={onClose}>
            Отменить
          </Button>
          <Button type="button" color="primary" disabled={submitting || pristine} onClick={handleSubmit(onSubmit)}>
            Применить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

}

const styles = _theme => ({
  searchInput: {
    marginTop: 10,
  },
  searchInputHide: {
    marginTop: 10,
    display: 'none',
  },
  MenuItem: {

  },
  MenuItemContainer: {
    zIndex: 1,
    position: 'absolute',
    width:'100%',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  MenuItemWrapper: {
    position: 'relative',
  },
});
FilterProperties = connect(state => ({
  propertyStatuses: state.root.classification.propertyStatuses,
  propertyTypes: state.root.classification.propertyTypes,
  teamMembers: state.root.selfUser.teamMembers,
  cityHint: Object.values(state.root.hint.city),
  districtHint: state.root.hint.district,
  subDistrictHint: state.root.hint.subDistrict,
}),
{
  setCityHint,
  setDistrictHint,
  setSubDistrictHint,
}
)(FilterProperties);

export default reduxForm({
  form: 'filter_properties',
  enableReinitialize: true,
})(withStyles(styles)(FilterProperties));
