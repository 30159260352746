import React, {Component} from 'react';
import {Field} from 'redux-form';
import {connect} from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import {TextField as MuiTextField} from 'redux-form-material-ui';

import {
  _onBlur,
  getListItems,
  compare,
} from '../../utils/filter';

import TextField from './TextField';
import {queryCities} from '../../queries/CityQuery';
import {
  setCityHint,
} from '../../actions';

class CheckboxInput extends Component {
  state = {
    list: [],
    allList: [],
    selected: false,
    isOpen: false,
    hint: [],
    value: '',
  };

  onBlur = _onBlur.bind(this);
  getListItems = getListItems.bind(this);

  componentDidMount() {
    const {initializeValue} = this.props;
    if(initializeValue){
      let filter = {
        filter: {
          id: initializeValue,
        },
      };
      queryCities(filter).then(resp => {
        this.setState({value: resp.data.data.cities.items[0].name});
      });
    }
  }


  onClickItem = function(value){
    const {change, input: {name}, useObject} = this.props;
    change(name, useObject ? value : value.id);
    this.props.setCityHint(value);
    this.setState({selected: true, value: value.name.trim()});
  };

  onFocus = () => {
    const {hint} = this.props;
    if(hint.length > 0 && hint[0].name){
      this.setState({
        hint,
        isOpen: true,
        list: hint,
      });
      return;
    }

    let filter = {
      filter: {
        id: hint,
      },
    };
    queryCities(filter).then(resp =>{
      let hintTmp =  hint.map(item  => resp.data.data.cities.items.find(element => element.id === item));
      this.setState({
        hint: hintTmp,
        isOpen: true,
        list: hintTmp,
      });
    });
  };

  onChange = e => {
    const {hint} = this.state;
    const {change, input: {name}} = this.props;
    const {value} = e.target;
    change(name, null);
    this.setState({
      value,
    });
    let list = [];
    if(value === ''){
      list = hint;
      this.setState({list, selected: false, isOpen: true});
    }
    else{
      let filter = {
        filter: {
          searchQuery: value,
        },
      };
      queryCities(filter).then(resp =>{
        let list = resp.data.data.cities.items;
        let item = list.find(item => compare(item.name, value));
        this.setState({list, selected: false, isOpen: true},
          () => {
            item && this.onClickItem(item);
          });
      });
    }
  };

  render() {
    const {classes, input: {name}, label, placeholder, required, meta} = this.props;
    const {
      list,
      selected,
      isOpen,
      value,
    } = this.state;
    return (
      <React.Fragment>
        <Field
          name={name}
          required
          component={TextField}
          fullWidth
          style={{display: 'none'}}
          margin="normal"
        />
        <div className={classes.MenuItemWrapper}>
          <MuiTextField
            placeholder={placeholder}
            fullWidth
            onBlur={() => this.onBlur('isOpen')}
            label={label}
            required={required}
            meta={meta}
            margin="normal"
            autoComplete="off"
            value={value}
            onFocus={e => {
              if(e.target.value === '')
                this.onFocus();
            }}
            onChange={this.onChange}
          />
          {list.length > 0 && !selected && isOpen
            ? <div className={classes.MenuItemContainer}>
              {list.slice(0, 5).map((value, index) => (
                <MenuItem
                  onClick={() => {
                    this.onClickItem(value);
                  }}
                  key={index}
                >
                  {value.name}
                </MenuItem>
              ))}</div>
            : null}
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  MenuItemContainer: {
    zIndex: 1,
    position: 'absolute',
    width:'100%',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  MenuItemWrapper: {
    position: 'relative',
  },
};

export default connect(
  () => {},
  {setCityHint}
)(withStyles(styles)(CheckboxInput));
