import React, {Component} from 'react';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';

import MobilePaginationToolbar from '../../components/MobilePaginationToolbar';
import TablePaginationActions from '../../components/TablePaginationActions';
import AccessComponent from '../../components/AccessComponent';
import {mapLabels} from '../../utils';
import SemanticsItemEdit from './SemanticsItemEdit';

class SemanticsListItems extends Component {
  state = {
    anchorEl: null,
    popoverContent: null,
    loading: false,
    openEdit: null,
  };

  render() {
    const {
      classes,
      query,
      action,
      items,
      handleChangePage,
      totalCount,
      onSubmitted,
      section,
      propertyTypes,
      isAdmin,
      teams,
    } = this.props;

    const {anchorEl, popoverContent, openEdit} = this.state;

    const propertyTypesLabels = mapLabels(propertyTypes, 'value', 'label');
    const teamsLabels = mapLabels(teams, 'id', 'name');

    return (
      <React.Fragment>
        <Hidden xsDown>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Категория объекта</TableCell>
                <TableCell>&quot;Возможно вы искали&quot;</TableCell>
                <TableCell>&quot;Подойдет под&quot;</TableCell>
                <TableCell className={classes.teamTableCell}>{isAdmin ? 'Команда' : ''}</TableCell>
                <TableCell className={classes.archivedTableCell}>В архиве</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(item => {
                return (
                  <TableRow
                    key={item.id}
                    hover
                    onClick={() => this.setState({openEdit: item})}
                  >
                    <TableCell>{propertyTypesLabels[item.type] || '-'}</TableCell>
                    <TableCell className={classes.wideTableCell}>{item.lookingFor}</TableCell>
                    <TableCell className={classes.wideTableCell}>{item.purposes}</TableCell>
                    <TableCell className={classes.teamTableCell}>
                      {
                        isAdmin ?
                          (
                            item.team ? (teamsLabels[item.team] || '') : 'Для всех команд'
                          )
                          : (
                            item.team ? 'Ваша команда' : 'По-умолчанию'
                          )
                      }
                    </TableCell>
                    <TableCell className={classes.archivedTableCell}>{item.archived ? 'Да' : 'Нет'}</TableCell>
                    <TableCell
                      style={{
                        paddingRight: action ? 56 : 24,
                        textAlign: 'right',
                        width: 20,
                      }}
                    >
                      <AccessComponent admin>
                        <IconButton
                          onClick={e => {
                            e.stopPropagation();
                            this.setState({openEdit: item});
                          }}
                        >
                          <icons.Edit />
                        </IconButton>
                      </AccessComponent>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={5}
                  rowsPerPage={30}
                  rowsPerPageOptions={[30]}
                  count={totalCount}
                  page={query.offset ? Math.floor(query.offset / 30) : 0}
                  onChangePage={handleChangePage}
                  labelDisplayedRows={({from, to, count}) =>
                    `${from}-${to} из ${count}`
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Hidden>
        <Hidden smUp>
          {items.map(item => {
            return (
              <Paper
                key={item.id}
                className={classes.card}
                elevation={0}
                onClick={() => this.setState({openEdit: item})}
                square
              >
                <CardContent style={{position: 'relative'}}>
                  <Typography>{propertyTypesLabels[item.type] || '-'}</Typography>
                  <Typography>
                    {
                      isAdmin ?
                        (
                          item.team ? (teamsLabels[item.team] || '') : 'Для всех команд'
                        )
                        : (
                          item.team ? 'Ваша команда' : 'По-умолчанию'
                        )
                    }
                  </Typography>
                  {item.archived ? <Typography subheading color="textSecondary">В архиве</Typography> : null}
                </CardContent>
                <Divider />
              </Paper>
            );
          })}
          <MobilePaginationToolbar
            totalCount={totalCount}
            page={query.offset ? Math.ceil(query.offset / 30) : 0}
            handleOnChangePage={handleChangePage}
          />
        </Hidden>
        <Popover
          classes={{paper: classes.requirementsPopover}}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
        >
          <Typography>{popoverContent}</Typography>
        </Popover>
        {openEdit ? (
          <SemanticsItemEdit
            teams={teams}
            isAdmin={isAdmin}
            propertyTypes={propertyTypes}
            open={Boolean(openEdit)}
            item={openEdit}
            onClose={() => this.setState({openEdit: null})}
            onSubmitted={() => {
              onSubmitted();
              this.setState({openEdit: null});
            }}
            section={section}
          />
        ) : null}
      </React.Fragment>
    );
  }

  handlePopoverOpen = (e, text) => {
    e.stopPropagation();
    this.setState({anchorEl: e.currentTarget.parentNode, popoverContent: text});
  };

  handlePopoverClose = () => {
    this.setState({anchorEl: null, popoverContent: null});
  };
}

const styles = theme => ({
  wideTableCell: {
    whiteSpace: 'normal',
  },
  teamTableCell: {
    width: 100,
    whiteSpace: 'normal',
    textAlign: 'center',
  },
  archivedTableCell: {
    width: 100,
    paddingRight: 56,
    textAlign: 'center',
  },
  table: {
    background: 'white',
    marginBottom: 56 + 48,
  },
  action: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 8,
    },
    top: 0,
  },
  sortableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
  malformed: {
    color: colors.orange[400],
    width: 16,
    height: 16,
    marginLeft: 16,
    verticalAlign: 'middle',
  },
});

export default connect(
  null,
  {}
)(withStyles(styles)(SemanticsListItems));
