import React, {Component} from 'react';
import {connect} from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import * as icons from '@material-ui/icons';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MobilePaginationToolbar from '../../components/MobilePaginationToolbar';
import {mapLabels} from '../../utils';
import {withStyles} from '@material-ui/core/styles';
import AccessComponent from '../../components/AccessComponent';
import TablePaginationActions from '../../components/TablePaginationActions';
import {downloadCsv} from '../../utils/csvFunc';

import TableLink from '../../components/TableLink';

class ContactListItems extends Component {
  state = {
    anchorEl: null,
    popoverContent: null,
    openExportMenu: null,
    loading: false,
  };

  render() {
    const {
      classes,
      query,
      contactList,
      selectedItems,
      contactTypeLabels,
      action,
      contacts,
      contactRoleLabels,
      history,
      favList,
      handleOnChangePage,
      handleRequestSort,
    } = this.props;

    const {order} = query;

    const {anchorEl, popoverContent, openExportMenu, loading} = this.state;

    return (
      <React.Fragment>
        <Hidden xsDown>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={order && order.column === 'name'}
                    direction={order && order.direction ? order.direction.toLowerCase() : 'asc'}
                    onClick={() => handleRequestSort('name')}
                    classes={{root: classes.sortableCell}}
                  >
                    Название
                  </TableSortLabel>
                </TableCell>
                <TableCell>Тип</TableCell>
                <TableCell>Телефон</TableCell>
                <TableCell>Роль</TableCell>
                <TableCell>Ответственный</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {contactList.items.map(id => {
                const contact = contacts[id];
                const selected = selectedItems && selectedItems.indexOf(contact.id) >= 0;
                return (
                  <TableLink
                    className={classes.tableRow}
                    key={contact.id}
                    href={`/contacts/${contact.id}`}
                    hover
                    selected={selected}
                    onClick={e => {
                      e.preventDefault();
                      history.push(`/contacts/${contact.id}`);
                    }}
                  >
                    <TableCell>{contact.name}</TableCell>
                    <TableCell>{contactTypeLabels[contact.type] || contact.type}</TableCell>
                    <TableCell>{contact.phone}</TableCell>
                    <TableCell>{contact.roles ? contact.roles.map(role => contactRoleLabels[role] || role).join(', ') : ''}</TableCell>
                    <TableCell title={contact.assignees.map(assignee => assignee.name).join('\n')}>
                      <div className={classes.flexWrapper}>
                        <div>
                          {contact.assignees && contact.assignees.length > 0 ? contact.assignees[0].name : null}
                        </div>
                        {contact.assignees && contact.assignees.length > 1 ? (
                          <icons.MoreHoriz
                            onClick={event => this.handlePopoverOpen(event, contact.assignees.map(assignee => assignee.name).join('\n'))}
                            className={classes.popoverIcon}
                          />
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell style={{paddingRight: action ? 56 : 24}}>
                      {action ? (
                        <div className={classes.action}>
                          {action(contact, selected, 'contactId', favList)}
                        </div>
                      ) : null}
                    </TableCell>
                  </TableLink>
                );
              })}
            </TableBody>
          </Table>
          <div className={classes.pagination}>
            <AccessComponent manager>
              <Button
                size="small"
                className={classes.button}
                onClick={e =>
                  this.setState({openExportMenu: e.currentTarget})
                }
                disabled={loading}
              >
                <icons.ArrowDownward className={classes.leftIcon} />
                      Выгрузить
              </Button>
            </AccessComponent>
            <TablePagination
              rowsPerPage={30}
              component={'div'}
              rowsPerPageOptions={[30]}
              count={contactList.totalCount}
              page={contactList.page}
              onChangePage={handleOnChangePage}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} из ${count}`
              }
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </Hidden>
        <Hidden smUp>
          {contactList.items.map(id => {
            const contact = contacts[id];
            const selected = selectedItems && selectedItems.indexOf(contact.id) >= 0;
            return (
              <Paper
                key={id}
                className={classes.card}
                elevation={0}
                onClick={() => history.push(`/contacts/${contact.id}`)}
                square
              >
                <CardContent style={{position: 'relative'}}>
                  <Typography>{contactTypeLabels[contact.type] || contact.type}</Typography>
                  <Typography variant="subtitle1">{contact.name}</Typography>
                  {action ? (
                    <div className={classes.action}>
                      {action(contact, selected, 'contactId', favList)}
                    </div>
                  ) : null}
                </CardContent>
                <Divider/>
              </Paper>
            );
          })}
          <MobilePaginationToolbar
            totalCount={contactList.totalCount}
            page={contactList.page}
            handleOnChangePage={handleOnChangePage}
          />
        </Hidden>
        <Popover
          classes={{paper: classes.requirementsPopover}}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
        >
          <Typography>{popoverContent}</Typography>
        </Popover>
        {openExportMenu ? (
          <Menu
            anchorEl={openExportMenu}
            open={true}
            onClose={() => this.setState({openExportMenu: null})}
          >
            <MenuItem
              value="xlsx"
              onClick={() => this.onDownloadCsv('xlsx')}
            >
              Выгрузить в XLSX
            </MenuItem>
            <MenuItem
              value="csv"
              onClick={() => this.onDownloadCsv('csv')}
            >
              Выгрузить в CSV
            </MenuItem>
          </Menu>
        ) : null}
      </React.Fragment>
    );
  }

  onDownloadCsv = async exportFormat => {
    const {contactList} = this.props;
    try {
      this.setState({openExportMenu: null, loading: true});
      await downloadCsv('contacts', contactList.filter, exportFormat);
      this.setState({loading: false});
    } catch {
      this.setState({openExportMenu: null, loading: false});
      alert('Произошла ошибка');
    }
  };

  handlePopoverOpen = (e, text) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({anchorEl: e.currentTarget.parentNode, popoverContent: text});
  };

  handlePopoverClose = () => {
    this.setState({anchorEl: null, popoverContent: null});
  };
}

const styles = theme => ({
  table: {
    background: 'white',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 56,
    alignItems: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    backgroundColor: 'white',
    marginBottom: 56 + 48,
  },
  tableRow: {
    display:'table-row',
    color: 'inherit',
    height: 48,
    outline: 'none',
    verticalAlign: 'middle',
    textDecoration: 'none',
    cursor: 'default',
    '&:hover': {
      backgroundColor:'rgba(0,0,0,0.07)',
    },
  },
  action: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 8,
    },
    top: 0,
  },
  sortableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
});

ContactListItems = connect(
  state => ({
    contactRoleLabels: mapLabels(state.root.classification.contactRoles, 'value', 'label'),
    contactTypeLabels: mapLabels(state.root.classification.contactTypes, 'value', 'label'),
    // contactList: state.root.contact.screens[ownProps.location.key],
    contacts: state.root.contact.contacts,
  }),
  {}
)(ContactListItems);

export default connect(
  state => ({
    selfUser: state.root.selfUser,
  })
)(withStyles(styles)(ContactListItems));
