import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import React from 'react';

import TextArea from '../../../components/Input/TextArea';

class EditableTitle extends React.Component {
  state = {
    editing: false,
    saving: false,
    value: this.props.title,
  };

  handleClick = () => {
    const {editable} = this.props;
    if (editable) {
      this.setState({editing: true});
    }
  };

  render() {
    const {classes, title, editable, onSave} = this.props;
    const {editing, saving, value} = this.state;

    if ((!editing && this.props.title.length > 0) || !editable) {
      return (
        <div
          className={classes.listItem}
          onClick={this.handleClick}>
          <div style={{width:'100%'}}>{title}
            {editable ? <icons.Edit className={classes.editIcon}/> : null}
          </div>
        </div>
      );
    }

    const pristine = title === value;
    const emptyTitle = title.length === 0;
    const emptyValue = value.length === 0;

    return (
      <div>
        <TextField
          style={{marginBottom: 8}}
          disabled={saving}
          placeholder="Название..."
          InputProps={{
            inputComponent: TextArea,
            inputProps: {rowsMax: 4},
          }}

          multiline
          fullWidth
          margin="none"
          value={value}
          onChange={e => this.setState({value: e.target.value})}
        />
        {!pristine || !emptyTitle ? (
          <div>
            <Button
              size="small"
              disabled={pristine || emptyValue || saving}
              onClick={() => {
                this.setState({saving: true});
                onSave(value).then(() => this.setState({
                  editing: false,
                  saving: false,
                }));
              }}
            >
              Сохранить
            </Button>
            <IconButton
              style={{width: 32, height: 32}}
              disabled={(pristine && emptyTitle) || saving}
              onClick={() => {
                this.setState({
                  value: title,
                  editing: emptyTitle,
                });
              }}
            >
              <icons.Close style={{width: 16, height: 16}}/>
            </IconButton>
          </div>
        ) : null}
      </div>
    );
  }
}

const styles = () => ({
  editIcon: {
    fontSize: 18,
    color: colors.grey[600],
    visibility: 'hidden',
    marginLeft: 'auto',
    right: 0,
    position: 'absolute',
  },
  listItem: {
    position: 'relative',
    cursor: 'pointer',
    width: '100%',
    whiteSpace: 'pre-line',
    '&:hover $editIcon': {
      visibility: 'visible',
    },
  },
});

export default withStyles(styles)(EditableTitle);
