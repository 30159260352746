import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {Checkbox} from 'redux-form-material-ui';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';

import TextField from '../../../components/Input/TextField';
import TextFieldCount from '../../../components/Input/TextFieldWithCount';

class Form extends Component {

  setStartSection = (target, start) => {
    start++;
    target.setSelectionRange(start, start);
  };

  render() {
    const {id} = this.props;
    return (
      <div style={{marginTop: 24}}>
        <Grid container spacing={24}>
          {!id
            ? (
              <Grid item xs={12}>
                <FormControl margin="none">
                  <FormControlLabel
                    control={<Field name="nextMajor" component={Checkbox} />}
                    label="Следующая мажорная версия"
                    required
                  />
                </FormControl>
              </Grid>
            ) : null}
          <Grid item xs={12}>
            <Field
              name="video"
              label="Ссылка на видео YouTube"
              component={TextField}
              fullWidth
              margin="none"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="description"
              label="Описание"
              component={TextFieldCount}
              multiline
              onKeyDown={e => {
                if(e.keyCode === 9) {
                  e.preventDefault();
                  let start = e.target.selectionStart;
                  this.props.change('description', e.target.value.substring(0, start) + '\t' + e.target.value.substring(start, e.target.value.length));
                  setTimeout(this.setStartSection, 0, e.target, start);

                }
              }}
              min={100}
              width={'100%'}
              max={5000}
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = {
  help: {
    color: colors.grey[700],
    marginLeft: 12,
  },
};

export default connect(({root}) => ({
  dealSources: root.classification.dealSources,
  dealStatuses: root.classification.dealStatuses,
  priceTypes: root.classification.priceTypes,
  contractTypes: root.classification.contractTypes,
}))(withStyles(styles)(Form));
