import React, {Component} from 'react';
import {connect} from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import {withStyles} from '@material-ui/core/styles';
import axios from 'axios';
import OfferList from '../offer/OfferList';
import OfferListItems from '../offer/OfferListItems';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {queryRequestOffersIds} from '../../queries';
import {setTitle} from '../../actions';
import SnackbarError from '../../components/SnackbarError';
import {showResponseError} from '../../utils';

class RequestOffers extends Component {
  state = {
    selectedItems: [],
    attachedItems: [],
    selectItemIds: [],
    selectionOpen: false,
    contact: null,
    error: false,
    addOpen: false,
    errorMessage: '',
    storagePrefix: 'request_offers_selected_',
  };

  componentDidMount() {
    this.props.setTitle('Выбор листингов');
    this.load();
    let selectItemIds = localStorage.getItem(`${this.state.storagePrefix + this.props.match.params.dealId}`);
    if (selectItemIds) {
      this.setState({
        selectItemIds: JSON.parse(selectItemIds),
      });
    }
  }

  load() {
    const {match} = this.props;

    queryRequestOffersIds(match.params.dealId)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            attachedItems: resp.data.data.request.offers.map(item => item.id),
          });
        }
      })
      .catch(() => {
      });
  }

  addSelectionItem = item => {
    let {selectItemIds} = this.state;
    selectItemIds.push(item.id);
    localStorage.setItem(this.state.storagePrefix + this.props.match.params.dealId, JSON.stringify(selectItemIds));
    this.setState({
      selectItemIds: selectItemIds,
    });
  };

  deleteSelectionItem = item => {
    let {selectItemIds} = this.state;
    let newSelectItemIds = selectItemIds.filter(selectItem => selectItem !== item.id);
    localStorage.setItem(this.state.storagePrefix + this.props.match.params.dealId, JSON.stringify(newSelectItemIds));
    this.setState({
      selectItemIds: newSelectItemIds,
    });
  };

  resetSelectionItems = () => {
    this.setState({
      selectItemIds: [],
    });
    localStorage.removeItem(this.state.storagePrefix + this.props.match.params.dealId);
  };

  attachOffers = () => {
    return axios.post(
      '/api/v1/addOffersToRequest',
      {id: this.props.match.params.dealId, offers: this.state.selectItemIds})
      .then(() => {
        this.openAddSubmit(this.props.match.params.dealId);
      })
      .catch(error => {
        showResponseError(error.response);
      });
  };

  openAddSubmit = requestId => {
    localStorage.removeItem(this.state.storagePrefix + requestId);
    this.setState({selectionOpen: false});
    this.props.history.push(`/requests/${requestId}`);
  };

  render() {
    const {
      history,
      location,
      match,
      classes,
    } = this.props;

    const {
      error,
      errorMessage,
      selectItemIds,
      attachedItems,
    } = this.state;

    return (
      <React.Fragment>
        <OfferList
          history={history}
          match={match}
          location={location}
          actionRight
          actionReset={
            selectItemIds.length > 0 ?
              <React.Fragment>
                <Checkbox
                  className={classes.resetAll}
                  checked={true}
                  title={'Сбросить выбранные'}
                  onClick={e => {
                    this.resetSelectionItems();
                    e.stopPropagation();
                  }}
                />
              </React.Fragment>
              : null
          }
          actionResetMobile={
            selectItemIds.length > 0 ?
              <React.Fragment>
                <Checkbox
                  className={classes.resetAllMobile}
                  checked={true}
                  title={'Сбросить выбранные'}
                  onClick={e => {
                    this.resetSelectionItems();
                    e.stopPropagation();
                  }}
                />
              </React.Fragment>
              : null
          }
          action={(item, selected) => {
            const disabled = Boolean(attachedItems.find(selectItem => selectItem === item.id));
            selected = Boolean(selectItemIds.find(selectItem => selectItem === item.id)) || disabled;
            return (
              <React.Fragment>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Checkbox
                    style={{padding: 0, flex: 1}}
                    checked={selected}
                    disabled={disabled}
                    onChange={e => {
                      if (e.target.checked) {
                        if (selectItemIds.length < 15) {
                          this.addSelectionItem(item);
                        } else {
                          this.setState({
                            error: true,
                            errorMessage: 'Нельзя прикрепить больше 15 листингов',
                          });
                        }
                      } else
                        this.deleteSelectionItem(item);
                    }}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  />
                </div>
              </React.Fragment>
            );
          }}
        >
          <OfferListItems/>
        </OfferList>
        {selectItemIds.length > 0 ?
          <React.Fragment>
            <Paper
              className={classes.paper}
              elevation={1}
            >
              <Typography
                variant="subtitle2"
                style={{color: '#fff', padding: '16px 16px 16px 16px'}}
              >Выбрано {selectItemIds.length} из 15</Typography>
            </Paper>
            <div className={classes.fabContainer}>
              <Fab
                color="secondary"
                variant="extended"
                className={classes.fab}
                size="medium"
                onClick={this.attachOffers}
              >
                Прикрепить
              </Fab>
            </div>
          </React.Fragment>
          : null}
        <SnackbarError
          open={error}
          errorMessage={errorMessage}
          onClose={() => this.setState({error: false})}
        />
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  fabContainer: {
    zIndex: 50,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 6,
  },
  fab: {
    marginRight: 5,
  },
  paper: {
    zIndex: 50,
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    color: '#fff',
    backgroundColor: 'rgb(49, 49, 49)',
    left: theme.spacing.unit * 6,
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing.unit * 9,
      left: 'auto',
      right: theme.spacing.unit * 6,
      marginRight: 5,
    },
  },
  resetAll: {
    position: 'absolute',
    top: 10,
    zIndex: 100,
  },
  resetAllMobile: {
    zIndex: 100,
    top: 10,
    right: 10,
    padding: 0,
    position: 'absolute',
  },
});


export default withStyles(styles)(connect(
  () => ({}),
  {
    setTitle,
  }
)(RequestOffers));
