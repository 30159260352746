import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {connect} from 'react-redux';
import {mapLabels} from '../../utils';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';

class ContactRelationshipListItem extends Component {
  render() {
    const {contact, relationship, onSecondaryActionClick} = this.props;

    let listItemProps = {};

    if (this.props.handleDialogOpen) {
      listItemProps = {
        onClick: e => {
          e.preventDefault();
          this.props.handleDialogOpen(relationship.id);
        },
      };
    }

    return (
      <ListItem
        button
        dense
        component={Link}
        to={`/contacts/${relationship.id}`}
        {...listItemProps}
      >
        <Avatar style={{backgroundColor: colors.orange[300]}}>
          <icons.Contacts />
        </Avatar>
        {contact ? (
          <ListItemText
            disableTypography
            primary={<Typography variant="body1">{contact.name}</Typography>}
            secondary={
              <React.Fragment>
                <Typography variant="caption" noWrap>
                  {relationship.role}
                </Typography>
                {/*<Typography variant="body2">
                <span style={{background: (contactRelationshipStatusColors[contact.status] || {100: colors.white})[100]}}>
                  {contactRelationshipStatusLabels[contact.status] || contact.status}
                </span>
              </Typography>*/}
              </React.Fragment>
            }
          />
        ) : null}
        {onSecondaryActionClick ? (
          <ListItemSecondaryAction>
            <IconButton onClick={onSecondaryActionClick}>
              <icons.MoreVert />
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    );
  }
}

ContactRelationshipListItem = connect(state => ({
  contactRelationshipStatusColors: mapLabels(
    state.root.classification.offerStatuses,
    'value',
    'color'
  ),
  contactRelationshipStatusLabels: mapLabels(
    state.root.classification.offerStatuses,
    'value',
    'label'
  ),
}))(ContactRelationshipListItem);

export default ContactRelationshipListItem;
