import React from 'react';
import CityEdit from './CityEdit';
import DistrictEdit from './DistrictEdit';
import MicroDistrictEdit from './MicroDistrictEdit';

export default function EditModal({
  level,
  open,
  match,
  close,
  onSubmitted,
  city,
  district,
  microDistrict,
}) {
  if (level === 'city') {
    return (
      <CityEdit
        open={open}
        onSubmitted={() => onSubmitted()}
        onClose={() => close()}
        city={city}
      />
    );
  } else if (level === 'district') {
    return (
      <DistrictEdit
        open={open}
        city={match.params.cityId}
        onSubmitted={() => onSubmitted()}
        onClose={() => close()}
        district={district}
      />
    );
  } else {
    return (
      <MicroDistrictEdit
        open={open}
        district={match.params.districtId}
        onSubmitted={() => onSubmitted()}
        onClose={() => close()}
        microDistrict={microDistrict}
      />
    );
  }
}
