import React, {Component} from 'react';
import {connect} from 'react-redux';
import DealListWrapper from '../../containers/deals/DealListWrapper';

class DealOffer extends Component {
  render() {
    const {history, location, match} = this.props;

    return (
      <React.Fragment>
        <DealListWrapper
          history={history}
          match={match}
          location={location}
        />
      </React.Fragment>
    );
  }
}

export default connect(null)(DealOffer);
