import axios from 'axios';
import {withStyles} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography/Typography';
import Dropzone from 'react-dropzone';
import React, {Component} from 'react';
import {reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import {Field} from 'redux-form';

import TextField from '../../../components/Input/TextField';
import {formatFileSize} from '../../../utils';

class AddDocument extends Component {
  state = {
    uploading: false,
    document: null,
  };

  handleDrop = files => {
    const {change} = this.props;

    if (files.length < 1) {
      return;
    }

    this.setState({uploading: true});
    axios.post(`/api/files/${encodeURIComponent(files[0].name)}`, files[0], {
      headers: {
        'Content-Type': files[0].type,
      },
    })
      .then(resp => {
        change('document', {
          ...resp.data,
          name: resp.data.fileName,
        });
        this.setState({document: resp.data});
      })
      .catch(error => {
        if (error.response.status === 400) {
          alert(error.response.data.error.message);
        } else {
          alert('Ошибка при загрузке файла');
        }
      })
      .then(() => {
        this.setState({uploading: false});
      });
  };

  render() {
    const {
      classes,
      handleSubmit,
      submitting,
      onClose,
      open,
    } = this.props;
    const {document, uploading} = this.state;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Добавить документ</DialogTitle>
        <DialogContent>
          {document ? (
            <React.Fragment>
              <Typography variant="body1" className={classes.documentName}>{document.fileName}</Typography>
              <Typography variant="caption">{formatFileSize(document.fileSize)}</Typography>
              <Field
                component={TextField}
                name="document.name"
                label="Название"
                fullWidth
                margin="normal"
              />
            </React.Fragment>
          ) : (
            uploading ? (
              <CircularProgress size={20} thickness={5}/>
            ) : (
              <Dropzone
                className={classes.dropzone}
                onDrop={this.handleDrop}
              >
                <Typography>
                  Перетащите файлы в выделенную область или нажмите сюда для загрузки<br/>(до 10 МБ).
                </Typography>
              </Dropzone>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting || uploading}
            onClick={onClose}
          >
            Отмена
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || uploading}
            onClick={handleSubmit(this.submit)}
          >
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = data => {
    const {onSubmitted} = this.props;

    return axios.post(`/api/offers/${data.offerId}/documents`, {
      offerId: data.offerId,
      document: data.document,
    })
      .then(resp => {
        onSubmitted(resp.data);
      })
      .catch(error => {
        if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

const styles = {
  dropzone: {
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    border: '1px dashed silver',
    textAlign: 'center',
  },
  documentName: {
    overflow: 'hidden',
  },
};

export default reduxForm({
  form: 'add_document_to_offer',
  enableReinitialize: true,
})(withStyles(styles)(AddDocument));
