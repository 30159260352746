import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Select, Checkbox} from 'redux-form-material-ui';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import TextField from '../../../components/Input/TextField';
import Variant from '../../../components/Input/Variant';
import DatePicker from '../../../components/Input/DatePicker';
import {getLabelsForValue} from '../../../utils';
import TeamSelect from '../../team/TeamSelect';

class FilterDeals extends Component {
  render() {
    const {
      dealStatuses,
      handleSubmit,
      submitting,
      offerTypes,
      onClose,
      onSubmit,
      open,
      pristine,
      propertyPurposes,
      propertyTypes,
      teamMembers,
      contactRoles,
      contactTypes,
      classificationDeal,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Настройки фильтра</DialogTitle>
        <Divider/>
        <DialogContent>
          <Field
            name="searchQuery"
            label="Поисковый запрос"
            component={TextField}
            fullWidth
            margin="normal"
          />
          <Field
            name="statuses"
            label="Статусы"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(dealStatuses, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {dealStatuses.map(({label, value}, index) => (
              <Variant key={index} value={value} label={label}/>
            ))}
          </Field>
          <Field
            name="abcClass"
            label="Класс"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(classificationDeal, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {classificationDeal.map(({selectLabel, value}, index) => (
              <Variant key={index} value={value} label={selectLabel}/>
            ))}
          </Field>
          <FormControl margin="normal">
            <FormControlLabel
              control={<Field name="multiple" component={Checkbox} />}
              label="Клиенту требуется несколько объектов"
              required
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Вид сделки</InputLabel>
            <Field name="offerType" component={Select}>
              <MenuItem value=""/>
              {offerTypes.map(({value, label}, i) => (
                <MenuItem value={value} key={i}>{label}</MenuItem>
              ))}
            </Field>
          </FormControl>
          <Field
            name="propertyTypes"
            label="Категория"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(propertyTypes, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {propertyTypes.map(({label, value}, index) => (
              <Variant key={index} value={value} label={label}/>
            ))}
          </Field>
          <Field
            name="contactRoles"
            label="Роль контакта"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(contactRoles, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {contactRoles.map(({label, value}, index) => (
              <Variant key={index} value={value} label={label}/>
            ))}
          </Field>
          <Field
            name="contactTypes"
            label="Тип контакта"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(contactTypes, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {contactTypes.map(({label, value}, index) => (
              <Variant key={index} value={value} label={label}/>
            ))}
          </Field>

          <Field
            name="propertyPurposes"
            label="Назначение"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(propertyPurposes, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {propertyPurposes.map(({label, value}, index) => (
              <Variant key={index} value={value} label={label}/>
            ))}
          </Field>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="propertyAreaMin"
                  label="Площадь от"
                  component={TextField}
                  normalize={value => value ? value.replace(',', '.') : value}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="propertyAreaMax"
                  label="до (м²)"
                  component={TextField}
                  normalize={value => value ? value.replace(',', '.') : value}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="offerPriceMin"
                  label="Цена от"
                  component={TextField}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="offerPriceMax"
                  label="до (₽ за объект)"
                  component={TextField}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="createdAtFrom"
                  label="Создана с"
                  format={null}
                  component={props => (
                    <DatePicker
                      format="yyyy-MM-dd"
                      clearable
                      {...props}
                    />
                  )}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="createdAtTo"
                  label="по"
                  format={null}
                  component={props => (
                    <DatePicker
                      format="yyyy-MM-dd"
                      clearable
                      {...props}
                    />
                  )}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Field
            name="assignees"
            label="Ответственные"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(teamMembers, value, 'id', 'name').join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {teamMembers.map(({id, name}, index) => (
              <Variant key={index} value={id} label={name}/>
            ))}
          </Field>
          <Field
            name="team"
            label="Команда"
            component={TeamSelect}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(onSubmit)}
          >
            Применить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

FilterDeals = connect(
  state => ({
    dealStatuses: state.root.classification.dealStatuses,
    offerTypes: state.root.classification.offerTypes,
    propertyTypes: state.root.classification.propertyTypes,
    propertyPurposes: state.root.classification.propertyPurposes,
    teamMembers: state.root.selfUser.teamMembers,
    contactRoles: state.root.classification.contactRoles,
    contactTypes: state.root.classification.contactTypes,
    classificationDeal: state.root.classification.classificationDeal,
  })
)(FilterDeals);

export default reduxForm({
  form: 'filter_deals',
  enableReinitialize: true,
})(FilterDeals);
