import {executeQuery} from '../utils';

export const notificationsList = query => {
  return executeQuery(
    `query notificationsList($offset: Int, $limit: Int, $filter: NotificationFilter) {
      notificationsList(offset: $offset, limit: $limit, filter: $filter) {
        items {
          id
          content
          entityType
          entityId
          read
          createdAt
        }
        totalCount
        unread
      }
    }`,
    {...query}
  );
};

export const notificationsUnreadCount = () => {
  return executeQuery(
    `query notificationsList {
      notificationsList {
        unread
        totalCount
      }
    }`
  );
};


