import axios from 'axios';
import {CircularProgress, MenuItem} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select/Select';
import Toolbar from '@material-ui/core/Toolbar';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Query, withApollo} from 'react-apollo';
import gql from 'graphql-tag';

import TaskListQuery from '../../../queries/TaskListQuery';
import Sidebar from '../components/Sidebar';
import Task from '../components/Task';
import TaskAdd from './TaskAdd';
import AccessComponent from '../../../components/AccessComponent';

class TaskList extends Component {
  state = {
    filter: {
      source: 'OWN',
    },
    openAdd: false,
    openSidebar: false,
    showClosed: false,
  };
  currentDay = new Date();

  addTask = newTask => {
    const {client, match} = this.props;
    const {filter} = this.state;

    window.location.href = `${match.url}#/tasks/${newTask.id}`;

    return client.query({
      query: TaskListQuery,
      variables: {
        filter,
      },
      fetchPolicy: 'network-only',
    });
  };

  closeTask = id => {
    const {client} = this.props;

    axios.post('/api/v1/closeTask', {taskId: id})
      .then(() => {
        client.writeFragment({
          id,
          fragment: gql`
            fragment closedTask on Task {
              closed
            }
          `,
          data: {
            closed: true,
          },
        });
      });
  };

  reopenTask = id => {
    const {client} = this.props;

    axios.post('/api/v1/reopenTask', {taskId: id})
      .then(() => {
        client.writeFragment({
          id,
          fragment: gql`
            fragment reopenedTask on Task {
              closed
            }
          `,
          data: {
            closed: false,
          },
        });
      });
  };

  static getDerivedStateFromProps(props, state) {
    return {
      filter: {
        ...state.filter,
        ...TaskList.createQueryFilter(props.match.params),
      },
    };
  }

  static createQueryFilter(params) {
    return {
      role: params.role.toUpperCase(),
      interval: params.interval.toUpperCase(),
      day: params.day ? new Date(params.day) : null,
    };
  }

  render() {
    const {classes, match, selfUser} = this.props;
    const {filter, openAdd, openSidebar, showClosed} = this.state;
    const selectedDay = match.params.day ? new Date(match.params.day) : this.currentDay;

    return (
      <div className={classes.root}>
        {openAdd ? (
          <TaskAdd
            open={openAdd}
            onClose={() => this.setState({openAdd: false})}
            onSubmitted={newTask => {
              this.addTask(newTask);
              this.setState({openAdd: false});
            }}
          />
        ) : null}
        <Toolbar>
          <IconButton
            className={classes.addButton}
            onClick={() => this.setState({openSidebar: !openSidebar})}
          >
            {openSidebar ? <icons.Close/> : <icons.FilterList/>}
          </IconButton>
          <AccessComponent manager>
            <Select
              value={filter.source}
              input={<Input/>}
              onChange={event => {
                this.setState(prevState => ({
                  filter: {
                    ...prevState.filter,
                    source: event.target.value,
                  },
                }));
              }}
              className={classes.sourceSelect}
            >
              <MenuItem value="OWN">Мои задачи</MenuItem>
              <MenuItem value="TEAM" divider>Задачи команды</MenuItem>
              {selfUser.teamMembers.map((member, index) => (
                <MenuItem key={index} value={member.id}>{member.name}</MenuItem>
              ))}
            </Select>
          </AccessComponent>
        </Toolbar>
        <Divider/>
        <div className={classes.wrapper}>
          <Query query={TaskListQuery}>
            {({data, loading, error}) => {

              if (loading || error) {
                return (
                  <Sidebar
                    currentDay={this.currentDay}
                    open={openSidebar}
                    selectedDay={selectedDay}
                    weekSelected={Boolean(match.params.interval === 'week')}
                  />
                );
              }

              let taskDays = [];
              if (data.tasks) {
                data.tasks.items.map(item => {
                  return taskDays.push(new Date(new Date(item.deadline).setHours(0,0,0,0)));
                });
              }
              return (
                <Sidebar
                  currentDay={this.currentDay}
                  open={openSidebar}
                  selectedDay={selectedDay}
                  taskDays={taskDays}
                  weekSelected={Boolean(match.params.interval === 'week')}
                />
              );
            }}
          </Query>
          <div className={classes.taskList}>
            <Query query={TaskListQuery} variables={{filter}}>
              {({data, loading, error}) => {
                if (loading) {
                  return (
                    <div className={classes.status}>
                      <CircularProgress size={24} thickness={5}/>
                    </div>
                  );
                }

                if (error) {
                  return (
                    <div className={classes.status}>
                      <icons.Warning color="primary"/>
                    </div>
                  );
                }

                return (
                  <React.Fragment>
                    {data.tasks.items.map(task => (
                      <Task
                        key={task.id}
                        task={task}
                        onClick={() => {
                          window.location.href = `${match.url}#/tasks/${task.id}`;
                        }}
                        onClose={() => this.closeTask(task.id)}
                        onReopen={() => this.reopenTask(task.id)}
                      />
                    ))}
                    {data.tasks.permissions.canCreateTask ? (
                      <Fab
                        color="secondary"
                        className={classes.fab}
                        onClick={() => this.setState({openAdd: true})}
                      >
                        <icons.Add/>
                      </Fab>
                    ) : null}
                  </React.Fragment>
                );
              }}
            </Query>
            {showClosed ? (
              <React.Fragment>
                <Query query={TaskListQuery} variables={{filter: {...filter, closed: true}}} fetchPolicy="network-only">
                  {({data, loading, error}) => {
                    if (loading) {
                      return (
                        <div className={classes.status}>
                          <CircularProgress size={24} thickness={5}/>
                        </div>
                      );
                    }

                    if (error) {
                      return (
                        <div className={classes.status}>
                          <icons.Warning color="primary"/>
                        </div>
                      );
                    }

                    return data.tasks.items.map(task => (
                      <Task
                        key={task.id}
                        task={task}
                        onClick={() => {
                          window.location.href = `${match.url}#/tasks/${task.id}`;
                        }}
                        onClose={() => this.closeTask(task.id)}
                        onReopen={() => this.reopenTask(task.id)}
                      />
                    ));
                  }}
                </Query>
                <Button
                  size="small"
                  fullWidth
                  onClick={() => this.setState({showClosed: false})}
                >
                  скрыть закрытые
                </Button>
              </React.Fragment>
            ) : (
              <Button
                size="small"
                fullWidth
                onClick={() => this.setState({showClosed: true})}
              >
                показать закрытые
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  row: {
    display: 'flex',
    flex: 1,
  },
  root: {
    flex: 1,
    backgroundColor: colors.grey[200],
    zIndex: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    flex: 1,
  },
  taskList: {
    flex: 1,
    overflowY: 'auto',
  },
  addButton: {
    marginLeft: -12,
    marginRight: 12,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  status: {
    margin: 8,
    padding: 6,
    display: 'flex',
    justifyContent: 'center',
  },
  sourceSelect: {},
  fab: {
    position: 'absolute',
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
});

export default connect(
  state => ({
    selfUser: state.root.selfUser,
  }),
)(withApollo(withStyles(styles)(TaskList)));
