import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as icons from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import {queryContractProperties} from '../../../queries/ContractsQuery';
import {updateProperty} from '../../../actions';
import {mapLabels} from '../../../utils';
import PropertyListItem from '../../../components/relations/PropertyListItem';
import RemoveProperty from '../form/RemoveProperty';

class Properties extends Component {
  state = {
    contractProperties: [],
    loading: false,
    error: false,
    openMenu: false,
    removeProperty: null,
  };

  componentDidMount() {
    this.reload();
  }

  reload() {
    const {contract, updateProperty, properties} = this.props;

    queryContractProperties(contract.id)
      .then(res => {
        const contractProperties = res.data.data.contract.properties;
        this.setState({contractProperties});
        contractProperties.forEach(property => {
          if (!properties[property.id]) {
            updateProperty(property);
          }
        });
      })
      .catch(() => {
        alert('Произошла ошибка');
      });
  }

  handleRemoveSubmitted = () => {
    this.reload();
    this.setState({
      openMenu: null,
      removeProperty: null,
    });
    this.props.updateActivity();
  };

  handleRemoveCancel = () => {
    this.setState({
      removeProperty: null,
    });
  };

  handleOpenMenu = (target, id) => {
    this.setState({
      openMenu: {target, id},
    });
  };

  handleCloseMenu = () => {
    this.setState({
      openMenu: null,
    });
  };

  handleMenuItemClick = () => {
    this.setState({
      removeProperty: {
        id: this.state.openMenu.id,
        contractId: this.props.contract.id,
      },
    });
  };

  render() {
    const {
      contract,
      history,
      properties,
      handleDialogOpen,
    } = this.props;

    const {contractProperties, openMenu, removeProperty} = this.state;

    if (!contract) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Объекты
          <ListItemSecondaryAction>
            <IconButton onClick={() => history.push(`/contracts/${contract.id}/properties`)}>
              <icons.Add/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {contractProperties ? contractProperties.map(item => {
          const property = properties[item.id];
          return property ? (
            <PropertyListItem 
              key={item.id}
              property={property}
              handleDialogOpen={e => handleDialogOpen(e)}
              onSecondaryActionClick={e => this.handleOpenMenu(e.currentTarget, property.id)}
            />
          ) : null;
        }) : null}
        {openMenu ? (
          <Menu
            anchorEl={openMenu.target}
            open={Boolean(openMenu)}
            onClick={() => this.setState({openMenu: false})}
          >
            <MenuItem onClick={() => this.handleMenuItemClick()}>
              Убрать объект
            </MenuItem>
          </Menu>
        ) : null}
        <RemoveProperty
          open={Boolean(removeProperty)}
          initialValues={removeProperty}
          onSubmitted={this.handleRemoveSubmitted}
          onClose={this.handleRemoveCancel}
        />
      </List>
    );
  }
}

Properties = connect(
  state => ({
    properties: state.root.property.properties,
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
  }),
  {
    updateProperty,
  }
)(Properties);

export default withRouter(Properties);
