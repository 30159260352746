import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

const Variant = ({label, selected, ...props}) => (
  <MenuItem selected={selected} {...props}>
    <Checkbox checked={selected}/>
    <ListItemText primary={label}/>
  </MenuItem>
);

Variant.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.bool,
};

export default Variant;
