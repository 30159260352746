const defaultState = {
  show: false,
  images: [],
  currentIndex: -1,
};

export default (state = defaultState, {type, ...payload}) => {
  switch (type) {
    case 'OPEN_LIGHTBOX':
      return {
        show: true,
        images: payload.images,
        currentIndex: payload.currentIndex,
      };
    case 'CLOSE_LIGHTBOX':
      return {
        ...state,
        show: false,
      };
    case 'NEXT_IMAGE_IN_LIGHTBOX':
      return {
        ...state,
        currentIndex: (state.currentIndex + 1) % state.images.length,
      };
    case 'PREV_IMAGE_IN_LIGHTBOX':
      return {
        ...state,
        currentIndex: state.currentIndex <= 0
          ? (state.images.length - 1)
          : (state.currentIndex - 1),
      };
    default:
      return state;
  }
};
