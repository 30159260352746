import axios from 'axios';
import {set, clone} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {SubmissionError, formValueSelector, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import PublicDealForm from './form/PublicDeal';

class PublicDealEdit extends Component {
  componentDidMount() {
    const {publicDeal, initialize} = this.props;

    if(publicDeal) {
      return initialize({
        ...publicDeal,
      });
    }

    initialize({});
  }

  render() {
    const {
      array,
      classes,
      handleSubmit,
      open,
      pristine,
      submitting,
      onClose,
      teams,
      publicDeal,
      change,
      initialized,
      photos,
    } = this.props;

    if (!open || !initialized) {
      return null;
    }

    return (
      <Dialog fullScreen open={open} classes={{paper: classes.dialog}}>
        <DialogTitle>
          Новая сделка
          <IconButton className={classes.closeButton} onClick={onClose}>
            <icons.Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <PublicDealForm
            array={array}
            team={teams[0]}
            change={change}
            publicDeal={publicDeal}
            photos={photos}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button type="button" disabled={submitting} onClick={onClose}>
            Отменить
          </Button>
          <Button
            type="button"
            disabled={pristine || submitting}
            onClick={handleSubmit(this.submit)}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = data => {
    const {onSubmitted, publicDeal} = this.props;
    const endpoint = publicDeal ? '/api/v1/pressRelease/updatePublicDeal' : '/api/v1/pressRelease/createPublicDeal';

    if(data.photos){
      data.photo = data.photos[0];
    }

    let input = clone(data);
    if(input.city && input.city.id){
      input.city = input.city.id;
    }

    return axios
      .post(endpoint, input)
      .then(resp => {
        if(resp.data.id)
          data.id = resp.data.id;
        onSubmitted(data);
      })
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(
            error.response.data.error.errors.reduce(
              (errors, {propertyPath, message}) => {
                return set(errors, propertyPath, message);
              },
              {}
            )
          );
        } else if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

const styles = () => ({
  dialog: {
    maxWidth: 960,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

PublicDealEdit = withStyles(styles)(PublicDealEdit);

const selector = formValueSelector('publicDeal');
PublicDealEdit = connect(state => ({
  selfUser: state.root.selfUser,
  photos: selector(state, 'photos'),
  teams: state.root.selfUser.teams,
}))(PublicDealEdit);

export default reduxForm({
  form: 'publicDeal',
})(PublicDealEdit);
